import React from 'react';
import { compose } from 'redux';
import { useIntl } from 'react-intl-next';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import {
    HelpCentersManagementPage,
    HelpCentersManagementResource,
    authPageMessage,
    callToActionUrlText,
} from '@atlassian/help-centers-management';

const HelpCentersManagement = () => {
    const { formatMessage } = useIntl();
    const WrappedComponent = React.useMemo(
        () =>
            compose(
                withOnlyLoggedIn({ redirectUrl: '/' }),
                withOnlyJiraAdmin({
                    message: formatMessage(authPageMessage),
                    callToActionText: formatMessage(callToActionUrlText),
                    callToActionUrl: '',
                })
            )(HelpCentersManagementPage),
        [formatMessage]
    );
    return <WrappedComponent />;
};

export const viewHelpCentersManagementPageRoute = {
    name: ScreenName.HELP_CENTERS_MANAGEMENT,
    path: '/',
    exact: true,
    component: HelpCentersManagement,
    resources: [HelpCentersManagementResource],
};
