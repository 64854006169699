import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { UpdateUserModelAction, UpdateUserModelAnonymousAction } from 'state/actions/user';
import { updateUserModelAction, updateUserModelAnonymousAction } from 'state/actions/user';
import fetchModel from '../fetcher';
import type { FetchUserModelAction } from './actions';
import { FETCH_USER_MODEL } from './actions';

const epic: Epic<FetchUserModelAction, UpdateUserModelAction | UpdateUserModelAnonymousAction> = (action$) => {
    return action$.ofType(FETCH_USER_MODEL).mergeMap((action: FetchUserModelAction) =>
        fetchModel(action.payload)
            .map((response) => {
                // If we requested for a user model but never got one or got an empty user object we assume that the user is anonymous.
                if (!response.user || !response.user.accountId) {
                    return updateUserModelAnonymousAction();
                }
                return updateUserModelAction(response.user);
            })
            .catch(() => Observable.never() as Observable<UpdateUserModelAction>)
    );
};

export default epic;
