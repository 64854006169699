import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import type { State } from 'state';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import { useExternalLink } from 'view/external-links/use-external-link';
import { Box } from '@atlaskit/primitives';
import type { articleMetadataFragment$key } from './__generated__/articleMetadataFragment.graphql';
import messages from './messages';

export interface Props {
    isLoggedIn: boolean;
    result: articleMetadataFragment$key;
}

interface ArticleMetadata {
    lastModified: string;
}

export const ArticleMetadata = ({ isLoggedIn, result }: Props) => {
    di(useExternalLink);
    const { formatMessage } = useIntl();
    const data = useFragment<articleMetadataFragment$key>(
        graphql`
            fragment articleMetadataFragment on HelpObjectStoreSearchResult {
                id @required(action: THROW)
                displayLink @required(action: THROW)
                isExternal @required(action: THROW)
                containerDisplayName
            }
        `,
        result
    );

    const { id, displayLink, isExternal, containerDisplayName } = data;

    const {
        data: { source },
    } = useExternalLink({
        isLoggedIn,
        link: displayLink,
    });

    const confluencePageText = containerDisplayName
        ? formatMessage(messages.confluencePage, {
              value: containerDisplayName,
          })
        : '';
    const showSeparator = source && containerDisplayName;
    const externalResourceText = `${source ? source : ''} ${showSeparator ? `•` : ''} ${containerDisplayName ? containerDisplayName : ''}`;
    const metaData = !isExternal ? confluencePageText : externalResourceText;

    return <Box key={id}>{metaData}</Box>;
};

const ConnectedArticleMetadata = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
    };
})(ArticleMetadata);

export default ConnectedArticleMetadata;
