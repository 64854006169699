import * as React from 'react';
import { isMigrateToAnalyticsBridgeEnabled } from 'feature-flags';
import type { AnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics, fireAnalytics, UI_EVENT_TYPE, MountEvent } from '@atlassian/analytics-bridge';
import { MountEvent as MountEventLegacy, fireAnalyticsEvent, EventTypes } from '@atlassian/analytics-web-react';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { isPageLoadInstrumented, perfConfig } from '@atlassian/help-center-common-util/analytics/perf-config';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';
import { perfMark } from '../browser-metrics-v3';
import { AnalyticsTrackActions } from '../events';

interface FireScreenEventOnMountStatelessProps {
    screenName: ScreenName | undefined;
    screenId?: string | number;
    customData?: object;
}

export class FireScreenEventOnMountStateless extends React.Component<FireScreenEventOnMountStatelessProps> {
    endBrowserMetricsV3 = (screenName: ScreenName) => {
        const { customData } = this.props;

        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const metric = perfConfig[screenName];

        /**
         * This is used to support the previously created performance mark
         */
        perfMark('second.data.loaded');
        devLog('Browser Metrics v3: ending timer and firing event for screen:', screenName);

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        metric.stop({
            customData,
        });
    };

    onMount = (analyticsEvent: AnalyticsEvent) => {
        const { screenName } = this.props;

        if (isMigrateToAnalyticsBridgeEnabled()) {
            fireScreenAnalytics(analyticsEvent, AnalyticsTrackActions.VIEWED);
        } else {
            fireAnalyticsEvent({
                analyticsEvent,
                type: EventTypes.SCREEN,
            });
        }

        /**
         * Fire jsm-ui viewed event along with screen event
         * 1. This is done to for anti abuse spam work to detect human or bot spam
         * 2. We are firing this instead of `ui viewed` because that event is used for overall Atlassian MAU calculations.
         * 3. Event registration -> https://data-portal.internal.atlassian.com/analytics/registry/59264?tabIndex=Details
         */
        const uiAnalyticsEvent = analyticsEvent.clone() as UIAnalyticsEvent;
        if (isMigrateToAnalyticsBridgeEnabled()) {
            fireAnalytics(uiAnalyticsEvent, UI_EVENT_TYPE, {
                actionSubject: 'jsm-ui',
                action: AnalyticsTrackActions.VIEWED,
            });
        } else {
            // The componentName is used as actionSubject in the analytics event
            uiAnalyticsEvent.context.push({ componentName: 'jsm-ui' });
            fireAnalyticsEvent({
                analyticsEvent: uiAnalyticsEvent,
                type: EventTypes.UI,
                payload: {},
            });
        }

        /**
         * Trigger Stop timer for for Page load metric only if
         * 1. screenName exists
         * 2. metrics is instrumented for that screen
         * 3. BM3 feature flag is enabled
         */

        if (screenName && isPageLoadInstrumented(screenName)) {
            this.endBrowserMetricsV3(screenName);
        }
    };

    render() {
        if (isMigrateToAnalyticsBridgeEnabled()) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return <MountEvent onMount={this.onMount} key={`${this.props.screenName}-${this.props.screenId}`} />;
        }
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return <MountEventLegacy onMount={this.onMount} key={`${this.props.screenName}-${this.props.screenId}`} />;
    }
}
