export const intlErrorMatch = (
    intlError: string
): { errorKey: string | null; errorLocale: string | null; isNonFinal: boolean } => {
    const match = intlError.match(/Missing message: "(.*)" for locale "(.*)", using default message as fallback./);
    let isNonFinal = false;
    const errorKey = match ? match[1] : null;
    if (errorKey) {
        isNonFinal = ['.non-final', 'nonFinal'].some((checkString) => errorKey.includes(checkString));
    }
    return {
        errorKey,
        isNonFinal,
        errorLocale: match ? match[2] : null,
    };
};
