import AnalyticsClient, { originType, tenantType, userType } from '@atlassiansox/analytics-web-client';
import type {
    UIEventPayload,
    TrackEventPayload,
    OperationalEventPayload,
    ScreenEventPayload,
} from '@atlassiansox/analytics-web-client';
import { noop } from 'lodash';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ANALYTICS_BRIDGE_CHANNEL, extractAWCDataFromEvent } from '@atlassian/analytics-bridge';
import {
    getEvent,
    UI_EVENT_TYPE,
    TRACK_EVENT_TYPE,
    SCREEN_EVENT_TYPE,
    OPERATIONAL_EVENT_TYPE,
} from '@atlassian/analytics-web-react';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';
import type { EnvType } from '@atlassian/help-center-common-util/env';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import { getHelpCenterAnalyticsData } from '../help-center-analytics-data';
import { analyticsWebClientForSSR } from '../ssr-mock-client';

export interface Attrs {
    product: string;
    environment: EnvType;
    locale: string;
    tenantId?: string;
    userId?: string;
    subProduct?: string | (() => string);
    callback?: () => void;
    shouldTriggerUIViewed?: boolean;
}

type SendScreenEventInput = string | ScreenEventPayload;

export class AnalyticsWebClient {
    analyticsClient: AnalyticsClient;
    init({
        tenantId,
        userId,
        subProduct,
        product,
        environment,
        locale,
        callback,
        shouldTriggerUIViewed = true,
    }: Attrs) {
        this.analyticsClient = new AnalyticsClient(
            {
                product,
                locale,
                env: environment,
                version: '1.0.0',
                origin: originType.WEB,
            },
            { disableCookiePersistence: true }
        );

        this.bootstrapEventPayloadOverride();

        if (!this.analyticsClient) {
            return false;
        }

        if (tenantId) {
            this.analyticsClient.setTenantInfo(tenantType.CLOUD_ID, tenantId);
        }
        if (userId) {
            this.analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userId);
        }
        if (subProduct) {
            this.analyticsClient.setSubproduct(subProduct);
        }
        if (shouldTriggerUIViewed) {
            const callbackFn = typeof callback === 'function' ? callback : noop;
            this.analyticsClient.startUIViewedEvent(callbackFn);
        }

        return true;
    }

    private addCommonHelpCenterData(
        event: UIEventPayload | OperationalEventPayload | TrackEventPayload | SendScreenEventInput
    ) {
        if (typeof event === 'object') {
            event.attributes = {
                ...event.attributes,
                ...getHelpCenterAnalyticsData(),
            };
        }
        return event;
    }

    private bootstrapEventPayloadOverride() {
        const sendUIEvent = this.analyticsClient.sendUIEvent;
        const sendTrackEvent = this.analyticsClient.sendTrackEvent;
        const sendOperationalEvent = this.analyticsClient.sendOperationalEvent;
        const sendScreenEvent = this.analyticsClient.sendScreenEvent;

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.analyticsClient.sendUIEvent = (event: UIEventPayload, callback?: any) => {
            this.addCommonHelpCenterData(event);
            //eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return sendUIEvent.call(this.analyticsClient, event, callback);
        };

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.analyticsClient.sendTrackEvent = (event: TrackEventPayload, callback?: any) => {
            this.addCommonHelpCenterData(event);
            //eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return sendTrackEvent.call(this.analyticsClient, event, callback);
        };

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.analyticsClient.sendOperationalEvent = (event: OperationalEventPayload, callback?: any) => {
            this.addCommonHelpCenterData(event);
            //eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return sendOperationalEvent.call(this.analyticsClient, event, callback);
        };

        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.analyticsClient.sendScreenEvent = (event: SendScreenEventInput, callback?: any, attributes?: any) => {
            //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const existingAttrs =
                typeof attributes === 'object'
                    ? attributes
                    : {
                          //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          value: attributes, // non object vales are directly assigned as attributes accept `any`
                      };
            //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const updatedAttrs = {
                ...existingAttrs,
                ...getHelpCenterAnalyticsData(),
            };

            //eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return sendScreenEvent.call(this.analyticsClient, event, callback, updatedAttrs);
        };
    }

    private getDataFromEvent(event: UIAnalyticsEvent, channel?: string) {
        if (channel === ANALYTICS_BRIDGE_CHANNEL) {
            return extractAWCDataFromEvent(event);
        }
        return getEvent(event);
    }

    sendEvent(event: UIAnalyticsEvent, channel?: string) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { type, payload } = this.getDataFromEvent(event, channel);

        try {
            switch (type) {
                case UI_EVENT_TYPE:
                    // Disabling existing violations, should be fixed when revisited.
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.analyticsClient.sendUIEvent(payload);
                    break;
                case TRACK_EVENT_TYPE:
                    // Disabling existing violations, should be fixed when revisited.
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.analyticsClient.sendTrackEvent(payload);
                    break;
                case OPERATIONAL_EVENT_TYPE:
                    // Disabling existing violations, should be fixed when revisited.
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    this.analyticsClient.sendOperationalEvent(payload);
                    break;
                case SCREEN_EVENT_TYPE:
                    // Disabling existing violations, should be fixed when revisited.
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-floating-promises
                    this.analyticsClient.sendScreenEvent(payload.name, null, payload.attributes);
                    break;
                default:
                    devLog(
                        `An event was blackholed because it was of an invalid analyticsType: ${
                            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            event.payload.analyticsType
                        }.\nMake sure analyticsType is specified in the event's payload and is one of ${[
                            UI_EVENT_TYPE,
                            TRACK_EVENT_TYPE,
                            OPERATIONAL_EVENT_TYPE,
                            SCREEN_EVENT_TYPE,
                        ].join(', ')}.`
                    );
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('Failed to send analytic data. It may be malformed, or sessionStorage may be disabled.', err);
        }
    }

    getInstance(): AnalyticsClient {
        return this.analyticsClient;
    }

    updateUserInfo(accountId: string) {
        this.analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, accountId);
    }
}

let analyticsWebClient: AnalyticsWebClient;

export const getAnalyticsWebClient = () => {
    if (__SERVER__) {
        return analyticsWebClientForSSR;
    }

    if (analyticsWebClient) {
        return analyticsWebClient;
    }

    const locale = getMeta('ajs-user-locale') || getMeta('user-locale') || '';
    const tenantId = getMeta('tenant-id') || '';
    const accountId = getMeta('account-id') || '';
    const environment = getEnv().environment;
    analyticsWebClient = new AnalyticsWebClient();
    analyticsWebClient.init({
        tenantId,
        environment,
        locale,
        subProduct: 'serviceDesk',
        userId: accountId,
        product: 'jira',
        shouldTriggerUIViewed: false,
    });

    return analyticsWebClient;
};

export const updateUserInfo = (accountId: string) => {
    analyticsWebClient.updateUserInfo(accountId);
};

export const getAnalyticsWebClientPromise: () => Promise<AnalyticsWebClient> = () =>
    Promise.resolve(getAnalyticsWebClient());
