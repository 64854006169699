import { buildModelsActionCreator } from 'epics/model/actions';
import type { CompleteSignupResponse } from 'rest/models';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_COMPLETE_SIGNUP_MODEL = 'epic.model.FETCH_COMPLETE_SIGNUP_MODEL';
export const FETCH_COMPLETE_SIGNUP_SUCCESS = 'state.persisted.visit.portal.FETCH_COMPLETE_SIGNUP_SUCCESS';
export const FETCH_COMPLETE_SIGNUP_FAILURE = 'state.persisted.visit.portal.FETCH_COMPLETE_SIGNUP_FAILURE';
export const COMPLETE_SIGNUP = 'state.ui.invited.sign.up.COMPLETE_SIGNUP';
export const COMPLETE_SIGNUP_SUCCESS = 'state.ui.invited.sign.up.COMPLETE_SIGNUP_SUCCESS';
export const COMPLETE_SIGNUP_FAILURE = 'state.ui.invited.sign.up.COMPLETE_SIGNUP_FAILURE';
export const SEND_NEW_SIGNUP_LINK = 'state.ui.invited.sign.up.SEND_NEW_SIGNUP_LINK';
export const SEND_NEW_SIGNUP_LINK_SUCCESS = 'state.ui.invited.sign.up.SEND_NEW_SIGNUP_LINK_SUCCESS';
export const SEND_NEW_SIGNUP_LINK_FAILURE = 'state.ui.invited.sign.up.SEND_NEW_SIGNUP_LINK_FAILURE';

export type FetchCompleteSignupAction = ReturnType<typeof fetchCompleteSignupModel>;

export const fetchCompleteSignupModel = buildModelsActionCreator(FETCH_COMPLETE_SIGNUP_MODEL, 'completeSignup');

export interface FetchCompleteSignupSuccess {
    type: typeof FETCH_COMPLETE_SIGNUP_SUCCESS;
    payload?: CompleteSignupResponse;
}

export const fetchCompleteSignupSuccess = (
    completeSignupResponse?: CompleteSignupResponse
): FetchCompleteSignupSuccess => ({
    type: FETCH_COMPLETE_SIGNUP_SUCCESS,
    payload: completeSignupResponse,
});

export interface FetchCompleteSignupFailure {
    type: typeof FETCH_COMPLETE_SIGNUP_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchCompleteSignupFailure = (error: ErrorResponse): FetchCompleteSignupFailure => ({
    type: FETCH_COMPLETE_SIGNUP_FAILURE,
    payload: {
        error,
    },
});

export interface CompleteSignup {
    type: typeof COMPLETE_SIGNUP;
    payload: {
        jwtToken: string;
        fullName: string;
        password: string;
        email: string;
        contactAdminsFormEnabled: boolean;
    };
}

export const completeSignup = (
    jwtToken: string,
    fullName: string,
    password: string,
    email: string,
    contactAdminsFormEnabled: boolean
): CompleteSignup => ({
    type: COMPLETE_SIGNUP,
    payload: {
        jwtToken,
        fullName,
        password,
        email,
        contactAdminsFormEnabled,
    },
});

export interface CompleteSignupSuccess {
    type: typeof COMPLETE_SIGNUP_SUCCESS;
}

export const completeSignupSuccess = (): CompleteSignupSuccess => ({
    type: COMPLETE_SIGNUP_SUCCESS,
});

export interface CompleteSignupFailure {
    type: typeof COMPLETE_SIGNUP_FAILURE;
    payload: {
        error: DefaultError;
    };
}

export const completeSignupFailure = (error: DefaultError): CompleteSignupFailure => ({
    type: COMPLETE_SIGNUP_FAILURE,
    payload: {
        error,
    },
});

export interface SendNewSignupLink {
    type: typeof SEND_NEW_SIGNUP_LINK;
    payload: {
        email: string;
        portalId: number | undefined;
    };
}

export const sendNewSignupLink = (email: string, portalId?: number): SendNewSignupLink => ({
    type: SEND_NEW_SIGNUP_LINK,
    payload: {
        email,
        portalId,
    },
});

export interface SendNewSignupLinkSuccess {
    type: typeof SEND_NEW_SIGNUP_LINK_SUCCESS;
}

export const sendNewSignupLinkSuccess = (): SendNewSignupLinkSuccess => ({
    type: SEND_NEW_SIGNUP_LINK_SUCCESS,
});

export interface SendNewSignupLinkFailure {
    type: typeof SEND_NEW_SIGNUP_LINK_FAILURE;
}

export const sendNewSignupLinkFailure = (): SendNewSignupLinkFailure => ({
    type: SEND_NEW_SIGNUP_LINK_FAILURE,
});
