import type { State } from 'state';

export const isCustomizing = (state: State) => state.ui.helpCenterPreview.isCustomizing;

export const isSaveInProgress = (state: State) => state.ui.helpCenterPreview.isSaveInProgress;

export const isUploadInProgress = (state: State, fileCollection: string): boolean =>
    !!state.ui.helpCenterPreview.uploadsInProgress[fileCollection];

export const isAnyUploadInProgress = (state: State): boolean => {
    const { uploadsInProgress } = state.ui.helpCenterPreview;
    return Object.keys(uploadsInProgress).find((uploadKey) => uploadsInProgress[uploadKey]) !== undefined;
};
