import React from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import Flag, { FlagGroup } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/cross-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import messages from './messages';

export interface ApprovalErrorFlagProps {
    hasDecision: boolean;
    approvalError?: string;
    onDismissError: () => void;
}

export class ApprovalErrorFlag extends React.Component<ApprovalErrorFlagProps & WrappedComponentProps> {
    getFlagTitleAndDescription = (): { title: string; description: React.ReactNode } => {
        const { intl, hasDecision, approvalError } = this.props;

        switch (approvalError) {
            case '400':
                if (hasDecision) {
                    return {
                        title: intl.formatMessage(messages.approvalByEmailErrorFlagTitle400_2),
                        description: intl.formatMessage(messages.approvalByEmailErrorFlagDescription400_2),
                    };
                }
                return {
                    title: intl.formatMessage(messages.approvalByEmailErrorFlagTitle400_1),
                    description: intl.formatMessage(messages.approvalByEmailErrorFlagDescription400_1, {
                        b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                    }),
                };
            case '403':
                return {
                    title: intl.formatMessage(messages.approvalByEmailErrorFlagTitle403),
                    description: intl.formatMessage(messages.approvalByEmailErrorFlagDescription403),
                };
            case '404':
                return {
                    title: intl.formatMessage(messages.approvalByEmailErrorFlagTitle404),
                    description: intl.formatMessage(messages.approvalByEmailErrorFlagDescription404),
                };
            case '409':
                return {
                    title: intl.formatMessage(messages.approvalByEmailErrorFlagTitle409),
                    description: intl.formatMessage(messages.approvalByEmailErrorFlagDescription409),
                };
            default:
                return {
                    title: intl.formatMessage(messages.approvalByEmailErrorFlagTitleDefault, {}),
                    description: intl.formatMessage(messages.approvalByEmailErrorFlagDescriptionDefault, {
                        b: (chunks: React.ReactNode) => <b>{chunks}</b>,
                    }),
                };
        }
    };

    render() {
        const { approvalError, onDismissError, intl } = this.props;
        const { title, description } = this.getFlagTitleAndDescription();

        return (
            <FlagGroup onDismissed={onDismissError}>
                {approvalError ? (
                    <Flag
                        id="approve-by-email-fail-approve-error-flag"
                        icon={<ErrorIcon primaryColor={token('color.icon.danger', colors.R500)} label="" />}
                        title={title}
                        description={description}
                        actions={[
                            {
                                content: intl.formatMessage(messages.dismiss),
                                onClick: () => onDismissError(),
                            },
                        ]}
                    />
                ) : (
                    []
                )}
            </FlagGroup>
        );
    }
}

export default injectIntl(ApprovalErrorFlag);
