import type {
    FetchRequestTypeSuggestionAction,
    FetchRequestTypeSuggestionSuccess,
} from 'state/actions/request-type-suggestion';
import {
    FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL,
    FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS,
} from 'state/actions/request-type-suggestion';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface RequestTypeSuggestion {
    id: number;
    descriptionHtml: string;
    portalId: number;
    name: string;
    portalName: string;
    iconUrl: string;
}

export interface RequestTypeSuggestionState {
    suggestions: RequestTypeSuggestion[];
    isLoaded: boolean;
}

const defaultState: RequestTypeSuggestionState = {
    suggestions: [],
    isLoaded: false,
};

export type HandledActions = FetchRequestTypeSuggestionSuccess | FetchRequestTypeSuggestionAction;

export default function reducer(
    state: RequestTypeSuggestionState = defaultState,
    action: HandledActions
): RequestTypeSuggestionState {
    switch (action.type) {
        case FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL: {
            // When we are making a new request for the request type model we will reset the state
            return {
                suggestions: [],
                isLoaded: false,
            };
        }

        case FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS: {
            const requestTypeSuggestions: RequestTypeSuggestion[] = action.payload.map((suggestion) => {
                const requestTypeId = parseInt(String(suggestion.id), 10);
                return {
                    id: requestTypeId,
                    portalId: suggestion.cvId,
                    portalName: suggestion.portalName,
                    name: suggestion.name,
                    descriptionHtml: suggestion.descriptionHtml,
                    iconUrl:
                        suggestion.iconUrl ||
                        `${contextPath}/servicedesk/customershim/secure/viewavatar?avatarType=SD_REQTYPE&avatarId=${suggestion.icon}`,
                };
            });

            return {
                suggestions: requestTypeSuggestions,
                isLoaded: true,
            };
        }

        default:
            return state;
    }
}
