import { isNil } from 'lodash';
import { Observable } from 'relay-runtime';

import type { GraphQLResponse, CacheConfig, RequestParameters, Variables, UploadableMap } from 'relay-runtime';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';
import { QueryResponseCache } from '../query-responses';

export const aggPromiseCache = new Set();

export type SimplifiedFetchFunction = (
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables: UploadableMap
) => Observable<GraphQLResponse>;

const fetchQuery: SimplifiedFetchFunction = (request, variables) => {
    return Observable.create((sink) => {
        const requestID = 'cacheID' in request && request.cacheID != null ? request.cacheID : request.id;
        const cachedResponse = requestID ? QueryResponseCache.get(requestID, variables) : undefined;

        /**
         * If we're on the client and we have a cached response, return it immediately.
         */
        if (!__SERVER__ && !isNil(cachedResponse)) {
            if (requestID != null) {
                QueryResponseCache.delete(requestID, variables);
            }
            sink.next(cachedResponse);
            sink.complete();

            return;
        }

        const { id, operationKind, name: queryName } = request;
        const metaData = {
            queryName,
            operationKind,
        };

        const AGG_BASE_PATH = id ? `/gateway/api/graphql/pq/${id}` : '/gateway/api/graphql';
        const AGG_PATH = process.env.AGG_BRANCH_SLUG
            ? `${AGG_BASE_PATH}/${process.env.AGG_BRANCH_SLUG}/graphql`
            : AGG_BASE_PATH;

        const body = JSON.stringify({
            variables,
        });

        const promise = fetchJson<GraphQLResponse>(`${AGG_PATH}`, {
            body,
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                ...(operationKind === 'mutation' && {
                    'X-ExperimentalApi': 'help-object-store-agg-beta, HelpCenterReorderTopics',
                }),
            },
        });

        promise
            .then((response) => {
                if (__SERVER__ && requestID != null) {
                    QueryResponseCache.setWithMetadata(requestID, variables, response, metaData);
                }
                sink.next(response);
                sink.complete();
                return response;
            })
            .catch((error) => {
                sink.error(error);
            });

        /**
         * If we're on the server, add the promise to the cache so we can await it later.
         */
        if (__SERVER__) {
            aggPromiseCache.add(promise);
        }
    });
};

export default fetchQuery;
