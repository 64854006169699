export const ConversationProvider = {
    VIRTUAL_AGENT: 'VIRTUAL_AGENT',
    AI_ANSWERS: 'AI_ANSWERS',
} as const;

export type ConversationProvider = (typeof ConversationProvider)[keyof typeof ConversationProvider];

// eslint-disable-next-line no-shadow
export enum ConversationChannelType {
    SLACK = 'SLACK',
    EMAIL = 'EMAIL',
    PORTAL = 'PORTAL',
    HELP_CENTER = 'HELP_CENTER',
    WIDGET = 'WIDGET',
}

// eslint-disable-next-line no-shadow
export enum ConversationAnalyticsEvent {
    VA_RESPONDED_WITH_KNOWLEDGE_ANSWER = 'VA_RESPONDED_WITH_KNOWLEDGE_ANSWER',
    VA_RESPONDED_WITH_NON_KNOWLEDGE_ANSWER = 'VA_RESPONDED_WITH_NON_KNOWLEDGE_ANSWER',
    USER_MARKED_AS_RESOLVED = 'USER_MARKED_AS_RESOLVED',
    USER_MARKED_AS_NOT_RESOLVED = 'USER_MARKED_AS_NOT_RESOLVED',
    USER_SHARED_CSAT = 'USER_SHARED_CSAT',
    USER_CLEARED_CHAT = 'USER_CLEARED_CHAT',
}

export interface ConversationAnalyticsInputArgs {
    workspaceAri: string;
    channelType?: ConversationChannelType;
    projectId?: string;
    csatScore?: number;
    conversationAnalyticsEvent: ConversationAnalyticsEvent;
    conversationId: string;
    messageId: string;
}
