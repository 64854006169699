import { defineMessages } from 'react-intl';

export default defineMessages({
    expand: {
        defaultMessage: 'Expand announcement',
        description: 'Label for shrinking the announcement.',
        id: 'sd.common.announcement.expand',
    },
    shrink: {
        defaultMessage: 'Shrink announcement',
        description: 'Label for shrinking the announcement.',
        id: 'sd.common.announcement.shrink',
    },
    view: {
        defaultMessage: 'View announcement',
        description: 'Label for viewing the announcement.',
        id: 'sd.common.announcement.view',
    },
});
