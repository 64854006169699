import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { UpdateUserRequestsCountAction } from 'state/actions/user/user';
import { updateUserRequestsCountsAction } from 'state/actions/user/user';
import fetchModel from '../fetcher';
import type { FetchUserRequestsCountsAction } from './actions';
import { FETCH_USER_REQUESTS_COUNTS_MODEL } from './actions';

const fetchUserRequestsCountsEpic: Epic<FetchUserRequestsCountsAction, UpdateUserRequestsCountAction> = (action$) => {
    return action$.ofType(FETCH_USER_REQUESTS_COUNTS_MODEL).mergeMap((action: FetchUserRequestsCountsAction) =>
        fetchModel(action.payload, { type: 'secondary' })
            .filter((response) => !!response.userRequestsCounts)
            .map((response) => updateUserRequestsCountsAction(response.userRequestsCounts!))
            .catch(() => Observable.never() as Observable<UpdateUserRequestsCountAction>)
    );
};

export default fetchUserRequestsCountsEpic;
