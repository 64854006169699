import React from 'react';
import type { fetchHelpCenterBrandingAction } from 'epics/model/help-center';
import type { fetchOrganisationsModelAction } from 'epics/model/organisations';
import type { fetchUserModelAction } from 'epics/model/user';
import type { fetchUserRequestsCountsAction } from 'epics/model/user-requests-counts';
import { isHelpCentersManagement } from '@atlassian/help-center-common-util/advanced-help-center';

interface Props {
    fetchBranding: typeof fetchHelpCenterBrandingAction;
    fetchUser: typeof fetchUserModelAction;
    fetchUserRequestsCounts: typeof fetchUserRequestsCountsAction;
    fetchOrganisations: typeof fetchOrganisationsModelAction;
    isBrandingLoaded?: boolean;
    isUserLoaded: boolean;
    isAnonymous?: boolean;
}

export class PrefetchBrandingModel extends React.Component<Props> {
    componentDidMount() {
        if (!isHelpCentersManagement() && !this.props.isBrandingLoaded) {
            this.props.fetchBranding();
        }
        if (!this.props.isAnonymous) {
            this.props.fetchUser();
            this.props.fetchOrganisations();
            this.props.fetchUserRequestsCounts();
        }
    }

    componentDidUpdate(prevProps: Props) {
        // If we haven't loaded the user yet and we've moved from an
        // anonymous route to a normal route, go fetch the user.
        if (!this.props.isUserLoaded && prevProps.isAnonymous && !this.props.isAnonymous) {
            this.props.fetchUser();
            this.props.fetchUserRequestsCounts();
        }
    }

    render() {
        return null;
    }
}
