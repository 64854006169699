import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { resendValidationEmail } from 'rest/sign-up';
import type { HandleAjaxError, ShowSuccessFlag } from 'state/actions/flags';
import { handleAjaxError, showSuccessFlag } from 'state/actions/flags';
import type {
    EnableResendValidationEmailLink,
    ResendValidationEmail,
    ResendValidationEmailSuccess,
    ResendValidationEmailFailure,
} from 'state/actions/sign-up';
import {
    enableResendValidationEmailLink,
    resendValidationEmailSuccess,
    resendValidationEmailFailure,
    RESEND_VALIDATION_EMAIL,
} from 'state/actions/sign-up';

import messages from 'view/sign-up-check-email/sign-up-check-email-stateless/messages';

const ENABLE_RESEND_VALIDATION_LINK_DELAY_IN_MS = 5000;

export const resendValidationEmailEpic: Epic<
    ResendValidationEmail,
    | ResendValidationEmailSuccess
    | ShowSuccessFlag
    | EnableResendValidationEmailLink
    | ResendValidationEmailFailure
    | HandleAjaxError
> = (action$) => {
    return action$.ofType(RESEND_VALIDATION_EMAIL).mergeMap((transitionAction) => {
        const { email, portalId, redirectUrl } = transitionAction.payload;
        return resendValidationEmail(email, portalId, redirectUrl)
            .flatMap(() =>
                concat(
                    Observable.of(showSuccessFlag(messages.emailSentSuccessMessage, { emailAddress: email })),
                    Observable.of(resendValidationEmailSuccess()),
                    Observable.of(enableResendValidationEmailLink()).delay(ENABLE_RESEND_VALIDATION_LINK_DELAY_IN_MS)
                )
            )
            .catch((error: AjaxError) => {
                return concat(
                    Observable.of(resendValidationEmailFailure()),
                    Observable.of(handleAjaxError(error)),
                    Observable.of(enableResendValidationEmailLink())
                );
            });
    });
};
