import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import { isDefaultHCUsingGqlBrandingCalls } from 'feature-flags';
import { commitLocalUpdate } from 'relay-runtime';
import type {
    UpdateHCBrandingLoaderAction,
    UpdateHCBrandingModelAction,
    UpdateHelpCenterModelAction,
} from 'state/actions/help-center';
import {
    updateHCBrandingLoaderAction,
    updateHCBrandingModelAction,
    updateHelpCenterModelAction,
} from 'state/actions/help-center';
import { isKoshEnabledForDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center/advanced-help-center';
import {
    convertToHCBrandingResponse,
    fetchBrandingData,
} from '@atlassian/help-center-common-util/graphql-relay-connector';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import environment from '@atlassian/help-center-common-util/relay/environment';
import fetchModel from '../fetcher';
import type { FetchHelpCenterBrandingAction, FetchHelpCenterAction, InvalidateHelpCenterStoreAction } from './actions';
import {
    FETCH_HELP_CENTER_BRANDING_MODEL,
    FETCH_HELP_CENTER_MODEL,
    INVALIDATE_HELP_CENTER_RELAY_STORE,
} from './actions';

export const helpCenterEpic: Epic<FetchHelpCenterAction, UpdateHelpCenterModelAction> = (action$) => {
    return action$.ofType(FETCH_HELP_CENTER_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.sharedPortal)
            .map((response) => {
                return updateHelpCenterModelAction(response.sharedPortal!);
            })
            // we're casting to success here as we swallow any errors for now
            .catch((_) => Observable.never() as Observable<UpdateHelpCenterModelAction>)
    );
};

export const invalidateHelpCenterBrandingEpic: Epic<InvalidateHelpCenterStoreAction> = (action$) => {
    return action$.ofType(INVALIDATE_HELP_CENTER_RELAY_STORE).mergeMap(() => {
        // invalidate local cache of relay store for help center branding
        commitLocalUpdate(environment, (relayStore) => {
            const root = relayStore.getRoot();
            const query = root.getLinkedRecord('helpCenter', {
                cloudId: getCloudId(),
            });
            const helpCenter = query?.getLinkedRecord('helpCenterById', {
                helpCenterAri: getHelpCenterAri(),
            });
            helpCenter?.invalidateRecord();
        });
        const noAction = Observable.never() as Observable<InvalidateHelpCenterStoreAction>;
        return noAction;
    });
};

export const helpCenterBrandingEpic: Epic<
    FetchHelpCenterBrandingAction,
    UpdateHCBrandingModelAction | UpdateHCBrandingLoaderAction
> = (action$) => {
    return action$.ofType(FETCH_HELP_CENTER_BRANDING_MODEL).mergeMap((action) => {
        const noAction = Observable.never() as Observable<UpdateHCBrandingModelAction>;

        if (isDefaultHCUsingGqlBrandingCalls() || isKoshEnabledForDefaultHelpCenter()) {
            return Observable.from(
                fetchBrandingData()
                    .toPromise()
                    .then((response) => convertToHCBrandingResponse(response))
            )
                .filter((data) => !!data)
                .flatMap((data) => {
                    if (data) {
                        return concat(
                            Observable.of(updateHCBrandingModelAction(data)),
                            Observable.of(updateHCBrandingLoaderAction(true))
                        );
                    }
                    throw new Error('No data');
                })
                .catch(() => Observable.of(updateHCBrandingLoaderAction(true)));
        }

        return (
            fetchModel(action.payload)
                .filter((response) => !!response.helpCenterBranding)
                .map((response) => {
                    return updateHCBrandingModelAction(response.helpCenterBranding!);
                })
                // we're casting to success here as we swallow any errors for now
                .catch((_) => noAction)
        );
    });
};
