import type { Observable } from 'rxjs';
import type { Request, ModelType, ModelsResponse, FetchOptions } from 'rest/models';
import { fetchModel } from 'rest/models';

export default function fetcher<TModelType extends ModelType>(
    request: Request<TModelType>,
    options?: FetchOptions
): Observable<ModelsResponse> {
    return fetchModel(request, options);
}
