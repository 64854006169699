import * as React from 'react';
import { AppProviders } from 'providers';
import { AppErrorBoundary } from 'view/error-boundary';
import { GenericErrorPage } from 'view/error-boundary/generic-error-page';
import type { AtlassianHelpConfig } from './types';
import { App } from './view/app';
import { ThemingContainer } from './view/theming-container';

const renderError = () => <GenericErrorPage />;
interface AtlassianHelpProps {
    config: AtlassianHelpConfig;
}

export const AtlassianHelp = ({ config }: AtlassianHelpProps) => {
    return (
        <AppProviders config={config}>
            <AppErrorBoundary renderError={renderError}>
                <ThemingContainer>
                    <App />
                </ThemingContainer>
            </AppErrorBoundary>
        </AppProviders>
    );
};
