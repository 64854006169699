import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

const intentAnswerExperienceId = 'service-management.help-center.virtual-agent.intent-message';
const initialIntentMessageExperienceId = 'service-management.help-center.virtual-agent.intent-initial-message-reply';

export const intentAnswerExperience = new UFOExperience(intentAnswerExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const initialIntentMessageExperience = new UFOExperience(initialIntentMessageExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
