import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { MediaApiUploadToken } from 'rest/portal';
import { getUploadCredentialsObservable } from 'rest/portal-media';
import type {
    FetchPortalAction,
    FetchPortalSuccess,
    FetchPortalFailure,
    FetchPortalUploadMediaTokenAction,
    FetchPortalUploadMediaTokenFailure,
    FetchPortalUploadMediaTokenSuccess,
} from 'state/actions/portal';
import {
    FETCH_PORTAL_MODEL,
    fetchPortalSuccess,
    fetchPortalFailure,
    FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION,
    FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS,
    fetchPortalUploadMediaTokenAction,
    fetchPortalUploadMediaTokenFailure,
    fetchPortalUploadMediaTokenSuccess,
    FETCH_PORTAL_SUCCESS,
} from 'state/actions/portal';

import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import fetchModel from '../fetcher';
import type { ErrorResponse } from '../types';

export const portalEpic: Epic<FetchPortalAction, FetchPortalSuccess | FetchPortalFailure> = (action$) => {
    return action$.ofType(FETCH_PORTAL_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.portal && !!response.branding)
            .map((response) => {
                if (action.meta?.analyticsSuccessEvent) {
                    sendEvent(action.meta.analyticsSuccessEvent);
                }
                return fetchPortalSuccess(response.portal!, response.branding!);
            })
            .catch((error: ErrorResponse) => {
                if (action.meta?.analyticsFailureEvent && error.status !== 0) {
                    sendEvent(action.meta.analyticsFailureEvent);
                }
                const params = action.payload.params;
                return Observable.of(fetchPortalFailure(params.id, error));
            })
    );
};

export const portalUploadMediaTokenEpic: Epic<
    FetchPortalUploadMediaTokenAction,
    FetchPortalUploadMediaTokenSuccess | FetchPortalUploadMediaTokenFailure
> = (action$) => {
    return action$.ofType(FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_ACTION).mergeMap((action) => {
        const portalId = action.payload.portalId;

        return getUploadCredentialsObservable(portalId)
            .map((response) =>
                fetchPortalUploadMediaTokenSuccess(
                    { ...response, tokenDurationInMins: (response.expiryEpoch - Date.now()) / (60 * 1000) },
                    portalId
                )
            )
            .catch(() => {
                return Observable.of(fetchPortalUploadMediaTokenFailure());
            });
    });
};

// Whenever the upload context is fetched, we need to schedule a subsequent fetch
// to occur just before the current token is due to expire. This is implemented as
// an infinite loop - fetch success actions result in a new (delayed)
// "fetch request" being scheduled.
export const refreshPortalUploadMediaTokenBeforeExpiryEpic: Epic<
    FetchPortalUploadMediaTokenSuccess | FetchPortalSuccess,
    FetchPortalUploadMediaTokenAction
> = (action$) => {
    return action$.ofType(FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS, FETCH_PORTAL_SUCCESS).switchMap((action) => {
        const uploadContext = action.payload.mediaApiUploadInformation;

        if (uploadContext && 'token' in uploadContext) {
            const mediaApiUploadToken = uploadContext as MediaApiUploadToken;

            // gets the token duration in milliseconds minus 5 minute buffer for network calls/inactive tab throttling
            const wantsRefreshAt = mediaApiUploadToken.tokenDurationInMins * 60 * 1000 - 300000;
            const timeUntilRefresh = Math.max(1, wantsRefreshAt);

            const portalId =
                action.type === FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS ? action.payload.portalId : action.payload.id;

            if (portalId) {
                return Observable.of(fetchPortalUploadMediaTokenAction(Number(portalId))).delay(timeUntilRefresh);
            }
        }
        return Observable.empty<never>();
    });
};
