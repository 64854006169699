import type { topicsEditQuery } from 'topics/src';
import TopicsEditQuery from 'topics/src/ui/help-edit-topics/topics/__generated__/topicsEditQuery.graphql';
import type { hiddenLayoutSectionWarningQuery } from 'view/common/hidden-layout-section-warning/__generated__/hiddenLayoutSectionWarningQuery.graphql';
import HiddenLayoutSectionWarningQuery from 'view/common/hidden-layout-section-warning/__generated__/hiddenLayoutSectionWarningQuery.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';

export const helpEditTopicsResource = createRelayResource<topicsEditQuery>({
    type: 'EDIT_TOPICS',
    getQuery: (_, { cloudId, helpCenterAri }) => ({
        parameters: TopicsEditQuery,
        variables: { cloudId, helpCenterAri },
        options: {
            fetchPolicy: 'store-or-network',
        },
    }),
});

export const hiddenSectionWarningResource = createRelayResource<hiddenLayoutSectionWarningQuery>({
    type: 'HIDDEN_LAYOUT_SECTION_WARNING',
    getQuery: (_, { cloudId, layoutAri }) => ({
        parameters: HiddenLayoutSectionWarningQuery,
        variables: { cloudId, layoutAri },
        options: {
            fetchPolicy: 'store-or-network',
        },
    }),
});
