import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterReportingPageTitle: {
        defaultMessage: 'Performance',
        description: 'Title message for Help Center Reporting',
        id: 'helpcenter.reporting.title',
    },
    helpCenterReportingPageSubtitleV2: {
        defaultMessage: 'See the performance of the virtual service agent on your help center from the last 7 days. ',
        description: 'Subtitle message for Help Center Reporting',
        id: 'helpcenter.reporting.subtitle-v-2',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    IphLinkTextV2: {
        defaultMessage: 'Read more about using the virtual service agent on your help center.',
        description: 'Text for Link that should open in in-product help',
        id: 'helpcenter.reporting.iph-link-text-v-2',
    },
    aiConfigNotEnabledError: {
        defaultMessage: 'AI is not enabled for this portal',
        description: 'Error message when AI is not enabled for the tenant',
        id: 'helpcenter.reporting.ai.config.not.enabled.error.non-final',
    },
});
