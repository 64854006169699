import { defineMessages } from 'react-intl-next';

export default defineMessages({
    showMore: {
        defaultMessage: 'Show more',
        description: 'Text on show more results button',
        id: 'cv.search.articles.show-more',
    },
    showMoreIcon: {
        defaultMessage: 'Show more icon',
        description: 'Text for label on show more results icon',
        id: 'cv.search.articles.show-more-icon',
    },
    shortcutIcon: {
        defaultMessage: 'Shortcut icon',
        description: 'Text for label on shortcut icon',
        id: 'cv.search.articles.shortcut-icon',
    },
    articles: {
        defaultMessage: 'Self-help resources',
        description: 'Text for section resource type in search results',
        id: 'cv.search.articles.articles',
    },
});
