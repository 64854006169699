import type { Option, SelectProps } from '@atlaskit/select';
// This rule is disabled as it's telling consumers to instead use this file's component!
// eslint-disable-next-line no-restricted-imports
import { CreatableSelect as AKCreatableSelect } from '@atlaskit/select';
import { withCreateFireUIAnalytics } from '../../with-create-fire-ui-analytics';

// The default select has on-change analytics OOTB but this select (CreatableSelect) does not, so we patch it in for our own use.
export const CreatableSelect = withCreateFireUIAnalytics<SelectProps<Option<string>, boolean>>('select', {
    onChange: () => ({ action: 'changed' }),
    // Unfortunately we need to do this cast because the `defaultProps` of these
    // Select types has `spacing` set as type `string` while `props.spacing` is of type `"compact" | "normal"`.
})(AKCreatableSelect as React.ComponentType<SelectProps<Option<string>, boolean>>);
