export const PIPELINE_LOCAL_STORAGE_KEY = 'pipeline';

export const DEFAULT_PIPELINE = 'DEFAULT_PIPELINE';

export const MOBILE_BREAK_POINT = 480;
export const TAB_BREAK_POINT = 744;
export const DESKTOP_BREAK_POINT = 1440;

export const DESKTOP_MIN_WIDTH = 1025;
export const XL_MIN_WIDTH = 1200;

export const TEST_HARNESS_INPUT_OPTIONS = [
    {
        value: 'queries',
        label: 'Queries',
    },
    {
        value: 'googleSheet',
        label: 'Google Sheet',
    },
    {
        value: 'csvFile',
        label: 'CSV File URL',
    },
];

export const LINK_CLICKED_EVENT = 'link clicked';

export const MAX_CHARS = 1000;
