import { buildModelsActionCreator } from 'epics/model/actions';
import type { CreateCommentResponse } from 'rest/comment';
import type { AddParticipantsResponse, RequestDetailsResponse, ReadFileMediaCredentials } from 'rest/request-details';
import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { Error } from '@atlassian/help-center-common-util/errors';
import type { ErrorResponse } from 'epics/model/types';

export const CHANGE_SUBSCRIPTION = 'state.persisted.request.details.CHANGE_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'state.persisted.request.details.CHANGE_SUBSCRIPTION_SUCCESS';

export const CREATE_COMMENT = 'state.persisted.request.details.CREATE_COMMENT';
export const CREATE_COMMENT_SUCCESS = 'state.persisted.request.details.CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'state.persisted.request.details.CREATE_COMMENT_FAILURE';

export const GET_MEDIA_READ_CREDENTIALS = 'state.persisted.request.details.GET_MEDIA_READ_CREDENTIALS';
export const GET_MEDIA_READ_CREDENTIALS_SUCCESS = 'state.persisted.request.details.GET_MEDIA_READ_CREDENTIALS_SUCCESS';
export const GET_MEDIA_READ_CREDENTIALS_FAILURE = 'state.persisted.request.details.GET_MEDIA_READ_CREDENTIALS_FAILURE';

export const DECIDE_APPROVAL = 'state.persisted.request.details.DECIDE_APPROVAL';
export const DECIDE_APPROVAL_FAILURE = 'state.persisted.request.details.DECIDE_APPROVAL_FAILURE';

export const FETCH_REQUEST_DETAILS_MODEL = 'epic.model.FETCH_REQUEST_DETAILS_MODEL';
export const FETCH_REQUEST_DETAILS_SUCCESS = 'state.persisted.request.details.FETCH_REQUEST_DETAILS_SUCCESS';
export const FETCH_REQUEST_DETAILS_FAILURE = 'state.persisted.request.details.FETCH_REQUEST_DETAILS_FAILURE';

export const REMOVE_ORGANISATION = 'state.persisted.request.details.REMOVE_ORGANISATION';
export const REMOVE_ORGANISATION_SUCCESS = 'state.persisted.request.details.REMOVE_ORGANISATION_SUCCESS';

export const ADD_PARTICIPANTS_SUCCESS = 'state.persisted.request.details.ADD_PARTICIPANTS_SUCCESS';
export const REMOVE_PARTICIPANT = 'state.persisted.request.details.REMOVE_PARTICIPANT';
export const REMOVE_PARTICIPANT_SUCCESS = 'state.persisted.request.details.REMOVE_PARTICIPANT_SUCCESS';
export const SHARE_REQUEST = 'state.persisted.request.details.SHARE_REQUEST';

export const SHOW_TRANSITION_DIALOG = 'state.ui.request.details.SHOW_TRANSITION_DIALOG';
export const HIDE_TRANSITION_DIALOG = 'state.ui.request.details.HIDE_TRANSITION_DIALOG';
export const TRANSITION_REQUEST = 'state.persisted.request.details.TRANSITION_REQUEST';
export const TRANSITION_REQUEST_FAILURE = 'state.persisted.request.details.TRANSITION_REQUEST_FAILURE';
export const CLEAR_TRANSITION_REQUEST_FAILURE = 'state.persisted.request.details.CLEAR_TRANSITION_REQUEST';

export type FetchRequestDetailsAction = ReturnType<typeof fetchRequestDetailsModel>;

export const fetchRequestDetailsModel = buildModelsActionCreator(FETCH_REQUEST_DETAILS_MODEL, 'reqDetails');

export interface FetchRequestDetailsSuccess {
    type: typeof FETCH_REQUEST_DETAILS_SUCCESS;
    payload: RequestDetailsResponse;
}

export const fetchRequestDetailsSuccess = (
    requestDetailsModel: RequestDetailsResponse
): FetchRequestDetailsSuccess => ({
    type: FETCH_REQUEST_DETAILS_SUCCESS,
    payload: requestDetailsModel,
});

export interface AddParticipantsSuccessPayload {
    requestKey: string;
    userMentionedDuringRequestCreation?: boolean;
    addParticipantsSuccessResponse: AddParticipantsResponse;
}

export interface AddParticipantsSuccess {
    type: typeof ADD_PARTICIPANTS_SUCCESS;
    payload: AddParticipantsSuccessPayload;
}

export const addParticipantsSuccess = (
    requestKey: string,
    addParticipantsSuccessResponse: AddParticipantsResponse,
    userMentionedDuringRequestCreation = false
): AddParticipantsSuccess => ({
    type: ADD_PARTICIPANTS_SUCCESS,
    payload: { requestKey, userMentionedDuringRequestCreation, addParticipantsSuccessResponse },
});

export interface FetchRequestDetailsFailure {
    type: typeof FETCH_REQUEST_DETAILS_FAILURE;
    payload: {
        requestKey: string;
        error: ErrorResponse;
    };
}

export const fetchRequestDetailsFailure = (requestKey: string, error: ErrorResponse): FetchRequestDetailsFailure => ({
    type: FETCH_REQUEST_DETAILS_FAILURE,
    payload: {
        requestKey,
        error,
    },
});

export interface ChangeSubscription {
    type: typeof CHANGE_SUBSCRIPTION;
    payload: {
        requestKey: string;
        requestId: number;
        shouldSubscribe: boolean;
    };
}

export const changeSubscription = (
    requestKey: string,
    requestId: number,
    shouldSubscribe: boolean
): ChangeSubscription => ({
    type: CHANGE_SUBSCRIPTION,
    payload: {
        requestKey,
        requestId,
        shouldSubscribe,
    },
});

export interface ChangeSubscriptionSuccess {
    type: typeof CHANGE_SUBSCRIPTION_SUCCESS;
    payload: {
        requestKey: string;
        shouldSubscribe: boolean;
    };
}

export const changeSubscriptionSuccess = (requestKey: string, shouldSubscribe: boolean): ChangeSubscriptionSuccess => ({
    type: CHANGE_SUBSCRIPTION_SUCCESS,
    payload: {
        requestKey,
        shouldSubscribe,
    },
});

export interface DecideApprovalFailure {
    type: typeof DECIDE_APPROVAL_FAILURE;
}

export const decideApprovalFailure = (): DecideApprovalFailure => ({
    type: DECIDE_APPROVAL_FAILURE,
});

export interface DecideApproval {
    type: typeof DECIDE_APPROVAL;
    payload: {
        portalId: number;
        requestKey: string;
        approvalId: number;
        approve: boolean;
        createAnalyticsEvent?: CreateUIAnalyticsEvent;
    };
}

export const decideApproval = (
    portalId: number,
    requestKey: string,
    approvalId: number,
    approve: boolean,
    createAnalyticsEvent?: CreateUIAnalyticsEvent
): DecideApproval => ({
    type: DECIDE_APPROVAL,
    payload: {
        portalId,
        requestKey,
        approvalId,
        approve,
        createAnalyticsEvent,
    },
});

export interface RemoveOrganisation {
    type: typeof REMOVE_ORGANISATION;
    payload: {
        requestKey: string;
        organisationId: number;
    };
}

export const removeOrganisation = (requestKey: string, organisationId: number): RemoveOrganisation => ({
    type: REMOVE_ORGANISATION,
    payload: {
        requestKey,
        organisationId,
    },
});

export interface RemoveOrganisationSuccess {
    type: typeof REMOVE_ORGANISATION_SUCCESS;
    payload: {
        requestKey: string;
        organisationId: number;
    };
}

export const removeOrganisationSuccess = (requestKey: string, organisationId: number): RemoveOrganisationSuccess => ({
    type: REMOVE_ORGANISATION_SUCCESS,
    payload: {
        requestKey,
        organisationId,
    },
});

export interface RemoveParticipant {
    type: typeof REMOVE_PARTICIPANT;
    payload: {
        requestKey: string;
        accountId: string;
    };
}

export const removeParticipant = (requestKey: string, accountId: string): RemoveParticipant => ({
    type: REMOVE_PARTICIPANT,
    payload: {
        requestKey,
        accountId,
    },
});

export interface RemoveParticipantSuccess {
    type: typeof REMOVE_PARTICIPANT_SUCCESS;
    payload: {
        requestKey: string;
        accountId: string;
    };
}

export const removeParticipantSuccess = (requestKey: string, accountId: string): RemoveParticipantSuccess => ({
    type: REMOVE_PARTICIPANT_SUCCESS,
    payload: {
        requestKey,
        accountId,
    },
});

export interface ShareRequest {
    type: typeof SHARE_REQUEST;
    payload: {
        requestKey: string;
        organizations: number[];
        emailAddresses: string[];
        accountIds: string[];
        isUserMentioned?: boolean;
        /* user_name is passed only if single new user is mentioned*/
        mentionedUserName?: string;
        userMentionedDuringRequestCreation?: boolean;
    };
}

export const shareRequest = (
    requestKey: string,
    organizations: number[],
    emailAddresses: string[],
    accountIds: string[],
    isUserMentioned?: boolean,
    mentionedUserName?: string,
    userMentionedDuringRequestCreation?: boolean
): ShareRequest => ({
    type: SHARE_REQUEST,
    payload: {
        requestKey,
        organizations,
        emailAddresses,
        accountIds,
        isUserMentioned,
        mentionedUserName,
        userMentionedDuringRequestCreation,
    },
});

export interface ShowTransitionDialog {
    type: typeof SHOW_TRANSITION_DIALOG;
    payload: {
        id: number;
    };
}

export const showTransitionDialog = (id: number): ShowTransitionDialog => ({
    type: SHOW_TRANSITION_DIALOG,
    payload: {
        id,
    },
});

export interface HideTransitionDialog {
    type: typeof HIDE_TRANSITION_DIALOG;
}

export const hideTransitionDialog = (): HideTransitionDialog => ({
    type: HIDE_TRANSITION_DIALOG,
});

export interface TransitionRequest {
    type: typeof TRANSITION_REQUEST;
    payload: {
        portalId: number;
        requestKey: string;
        transitionId: number;
        comment: string;
        createAnalyticsEvent?: CreateUIAnalyticsEvent;
    };
}

export const transitionRequest = (
    portalId: number,
    requestKey: string,
    transitionId: number,
    comment: string,
    createAnalyticsEvent?: CreateUIAnalyticsEvent
): TransitionRequest => ({
    type: TRANSITION_REQUEST,
    payload: {
        portalId,
        requestKey,
        transitionId,
        comment,
        createAnalyticsEvent,
    },
});

export interface TransitionRequestFailure {
    type: typeof TRANSITION_REQUEST_FAILURE;
    payload: {
        error: Error;
    };
}

export const transitionRequestFailure = (error: Error): TransitionRequestFailure => ({
    type: TRANSITION_REQUEST_FAILURE,
    payload: {
        error,
    },
});

export interface ClearTransitionRequestFailure {
    type: typeof CLEAR_TRANSITION_REQUEST_FAILURE;
}

export const clearTransitionRequestFailure = (): ClearTransitionRequestFailure => ({
    type: CLEAR_TRANSITION_REQUEST_FAILURE,
});

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface CreateComment {
    type: typeof CREATE_COMMENT;
    payload: {
        portalId: number;
        requestId: number;
        requestKey: string;
        comment: string;
        fileIds: string[];
        xsrftoken: string;
        numberOfMentionedUsers?: number;
        meta?: AnalyticsMetaData;
    };
}

export const createComment = (
    portalId: number,
    requestId: number,
    requestKey: string,
    comment: string,
    fileIds: string[],
    xsrftoken: string,
    numberOfMentionedUsers?: number,
    meta?: AnalyticsMetaData
): CreateComment => ({
    type: CREATE_COMMENT,
    payload: {
        portalId,
        requestId,
        requestKey,
        comment,
        fileIds,
        xsrftoken,
        numberOfMentionedUsers,
        meta,
    },
});

export interface CreateCommentSuccess {
    type: typeof CREATE_COMMENT_SUCCESS;
    payload: {
        requestKey: string;
        response: CreateCommentResponse;
    };
}

export const createCommentSuccess = (requestKey: string, response: CreateCommentResponse): CreateCommentSuccess => ({
    type: CREATE_COMMENT_SUCCESS,
    payload: {
        requestKey,
        response,
    },
});

export interface CreateCommentFailure {
    type: typeof CREATE_COMMENT_FAILURE;
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    payload: {};
}

export const createCommentFailure = (): CreateCommentFailure => ({
    type: CREATE_COMMENT_FAILURE,
    payload: {},
});

export interface GetReadCredentials {
    type: typeof GET_MEDIA_READ_CREDENTIALS;
    payload: {
        portalId: number;
        requestId: number;
        requestKey: string;
    };
}

export const getReadCredentials = (portalId: number, requestId: number, requestKey: string): GetReadCredentials => ({
    type: GET_MEDIA_READ_CREDENTIALS,
    payload: {
        portalId,
        requestId,
        requestKey,
    },
});

export interface GetReadCredentialsSuccess {
    type: typeof GET_MEDIA_READ_CREDENTIALS_SUCCESS;
    payload: {
        requestKey: string;
        response: ReadFileMediaCredentials;
    };
}

export const getReadCredentialsSuccess = (
    requestKey: string,
    response: ReadFileMediaCredentials
): GetReadCredentialsSuccess => ({
    type: GET_MEDIA_READ_CREDENTIALS_SUCCESS,
    payload: {
        requestKey,
        response,
    },
});

export interface GetReadCredentialsFailure {
    type: typeof GET_MEDIA_READ_CREDENTIALS_FAILURE;
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    payload: {};
}

export const getReadCredentialsFailure = (): GetReadCredentialsFailure => ({
    type: GET_MEDIA_READ_CREDENTIALS_FAILURE,
    payload: {},
});
