import type { RequestTypeField } from './types';

export const SHORT_TEXT_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:textfield';
export const PARAGRAPH_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:textarea';
export const SINGLE_SELECT_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:select';
export const DATE_PICKER_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:datepicker';

export const getFieldType = (fieldId: string, data: Map<string, RequestTypeField>): string | undefined => {
    const field = data.get(fieldId);
    if (!field) {
        return undefined;
    }
    if ('custom' in field.jiraSchema) {
        return field.jiraSchema.custom;
    }
    return undefined;
};

export const isTextField = (fieldId: string, data: Map<string, RequestTypeField>): boolean => {
    const fieldType = getFieldType(fieldId, data);
    return fieldType === SHORT_TEXT_FIELD_TYPE || fieldType === PARAGRAPH_FIELD_TYPE;
};
