import type { AjaxError } from 'rxjs';
import { Observable } from 'epics/rxjs';
import type { PortalSearchResponse, RequestTypeSearchResponse } from 'rest/search';
import { searchRequestTypes, searchPortals, searchKbs } from 'rest/search';
import type { State } from 'state';
import type { AnalyticsMetaData } from 'state/actions/search';
import { getPortalId } from 'state/selectors/context';
import {
    getIsKbEnabled as isHelpCenterKbEnabled,
    getIsLoaded as isHelpCenterLoaded,
} from 'state/selectors/help-center';
import { getIsPortalLoaded, getIsKbEnabled as isPortalKbEnabled, getProjectId } from 'state/selectors/portal';
import type { KnowledgeBaseSearchResult as FormattedKnowledgeBaseSearchResult } from 'state/ui/search';
import type { AnalyticsEventPayload } from '@atlaskit/media-picker/dist/types/types';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
// @ts-ignore TS(2305) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Module '"@atlaskit/media-picker/dist/types/types"'... Remove this comment to see the full error message
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';

export interface SearchProviderResponse {
    [resultKey: string]: object[] | FormattedKnowledgeBaseSearchResult;
}

export interface SearchProvider {
    // executes the relevant search request, it is assumed that all errors and transformations are handled by this
    execute: (
        query: string,
        state: State,
        meta?: AnalyticsMetaData,
        resultLimit?: number,
        expandPortal?: boolean,
        categoryIds?: string[],
        portalIds?: string[]
    ) => Observable<SearchProviderResponse>;
}

export const DEFAULT_MAX_KB_RESULTS_IN_DETAILS_PAGE = 30;
export const KbSearchProvider: SearchProvider = {
    execute: (
        query: string,
        state: State,
        meta?: AnalyticsMetaData,
        resultLimit?: number,
        expandPortal?: boolean,
        categoryIds?: string[],
        portalIds?: string[]
    ) => {
        const DEFAULT_MAX_KB_RESULTS_IN_SEARCH_MODAL = 8;
        return searchKbSuppressError(
            query.trim(),
            state,
            resultLimit || DEFAULT_MAX_KB_RESULTS_IN_SEARCH_MODAL,
            meta,
            expandPortal,
            categoryIds,
            portalIds
        );
    },
};

const searchKbSuppressError = (
    query: string,
    state: State,
    resultLimit: number,
    meta?: AnalyticsMetaData,
    expandPortal?: boolean,
    categoryIds?: string[],
    portalIds?: string[]
) => {
    const portalId: number | undefined = getPortalId(state);
    const projectId: number | undefined = getProjectId(state, portalId);

    if (portalId) {
        // If the portal model is available and the kb is disabled then we will not make the kb search request.
        // i.e. if there is no portal model we will still make a kb request regardless
        if (getIsPortalLoaded(state, portalId) && !isPortalKbEnabled(state, portalId)) {
            return Observable.of({
                kbs: {
                    articles: [],
                    totalSize: 0,
                },
            });
        }
    } else if (isHelpCenterLoaded(state) && !isHelpCenterKbEnabled(state)) {
        // We will only NOT make a KB search request if the help center is loaded and kb is disabled
        return Observable.of({
            kbs: {
                articles: [],
                totalSize: 0,
            },
        });
    }

    const kbsQuery = {
        categoryIds,
        portalIds,
        query,
        projectId,
        resultLimit,
        expandPortalName: expandPortal,
    };

    return searchKbs(kbsQuery)
        .map((response: FormattedKnowledgeBaseSearchResult) => {
            if (meta?.analyticsSuccessEvent?.kb) {
                sendEvent(meta.analyticsSuccessEvent.kb);
            }
            return { kbs: response };
        })
        .catch((error: AjaxError) => {
            if (meta?.analyticsFailureEvent?.kb && !isNetworkOrClientErrorCode(error.status)) {
                sendEvent(meta.analyticsFailureEvent.kb);
            }
            return Observable.of({
                kbs: {
                    articles: [],
                    totalSize: 0,
                },
            });
        });
};

export const RequestTypeSearchProvider: SearchProvider = {
    execute: (query: string, state: State, meta?: AnalyticsMetaData) => {
        const DEFAULT_MAX_RT_RESULTS = 5;

        const requestTypeQuery = {
            query,
            resultLimit: DEFAULT_MAX_RT_RESULTS,
            includeRequestTypesFromOtherPortals: true,
            serviceDeskId: getPortalId(state),
        };
        const start = performance?.now();
        return searchRequestTypes(requestTypeQuery)
            .map((response: RequestTypeSearchResponse[]) => {
                const uiAnalyticsEvent = meta?.analyticsSuccessEvent?.requestType;
                uiAnalyticsEvent?.update((payload: AnalyticsEventPayload) => ({
                    ...payload,
                    timeTaken: performance?.now() - start,
                }));
                if (uiAnalyticsEvent) {
                    sendEvent(uiAnalyticsEvent);
                }
                return { requestTypes: response };
            })
            .catch((error: AjaxError) => {
                if (meta?.analyticsFailureEvent?.requestType && !isNetworkOrClientErrorCode(error.status)) {
                    sendEvent(meta.analyticsFailureEvent.requestType);
                }
                return Observable.of({
                    requestTypes: [],
                });
            });
    },
};

export const PortalSearchProvider: SearchProvider = {
    execute: (query: string, state: State, meta?: AnalyticsMetaData) => {
        const DEFAULT_MAX_PORTAL_RESULTS = 3;

        const portalQuery = {
            query,
            limit: DEFAULT_MAX_PORTAL_RESULTS,
            excludedPortalId: getPortalId(state),
        };

        return searchPortals(portalQuery)
            .map((response: PortalSearchResponse[]) => {
                if (meta?.analyticsSuccessEvent?.portal) {
                    sendEvent(meta.analyticsSuccessEvent.portal);
                }
                return { portals: response };
            })
            .catch((error: AjaxError) => {
                if (meta?.analyticsFailureEvent?.portal && !isNetworkOrClientErrorCode(error.status)) {
                    sendEvent(meta.analyticsFailureEvent.portal);
                }
                return Observable.of({
                    portals: [],
                });
            });
    },
};
