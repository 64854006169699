import * as React from 'react';
import type { ConnectPageName } from 'rest/connect-addon';
import { fetchIsAnyConnectAppBlocked, isConnectBlockedAppsRelevantPage } from 'rest/connect-addon';
import type { fetchConnectFragmentsAction } from 'state/actions/connect-addon';
import type { mapConnectDispatchToPropsBanner } from 'state/persisted/blocked-apps-banner/reducer';
import type { WebFragment } from 'state/persisted/connect-addon';
import type { ConnectFragmentLocation } from 'state/selectors/connect-addon';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';

import { getUniqueAppKeysFromConnectFragments } from '@atlassian/help-center-common-util/connect';

import { ConnectFragmentsDumb } from './connect-fragments-dumb';
import type { ErrorResponse } from 'epics/model/types';

export const HELP_CENTER: ConnectPageName = 'HELP_CENTER';
export const PORTAL_HOME: ConnectPageName = 'PORTAL';
export const REQUEST_CREATE: ConnectPageName = 'CREATE_REQUEST';
export const REQUEST_DETAILS: ConnectPageName = 'VIEW_REQUEST';
export const REQUEST_LIST: ConnectPageName = 'MY_REQUESTS';
export const PROFILE: ConnectPageName = 'PROFILE';
export const PROFILE_PANEL: ConnectFragmentLocation = 'profilePagePanel';
export const REQUEST_CREATE_PANEL: ConnectFragmentLocation = 'requestCreatePanel';
export const HEADER: ConnectFragmentLocation = 'headerPanels';
export const SUB_HEADER: ConnectFragmentLocation = 'subheaderPanels';
export const FOOTER: ConnectFragmentLocation = 'footerPanels';
export const DETAILS: ConnectFragmentLocation = 'detailsPanels';
export const DETAILS_SIDE_SECTION: ConnectFragmentLocation = 'optionPanels';

export interface Props {
    page: ConnectPageName;
    location: ConnectFragmentLocation;
    className?: string;
    portalId?: number;
    requestTypeId?: number;
    requestKey?: string;
}

interface StateProps {
    connectFragments: WebFragment[] | { error: ErrorResponse } | undefined;
    projectId?: number;
}

type MapConnectDispatchToPropsBanner = typeof mapConnectDispatchToPropsBanner;

interface DispatchProps extends MapConnectDispatchToPropsBanner {
    fetchConnectFragments: typeof fetchConnectFragmentsAction;
}

export class ConnectFragment extends React.Component<Props & StateProps & DispatchProps & WithAnalyticsEventsProps> {
    componentDidMount() {
        const { fetchConnectFragments, page, connectFragments, portalId, requestTypeId, requestKey } = this.props;
        if (!connectFragments) {
            fetchConnectFragments({ portalId, requestTypeId, requestKey, portalPage: page });
        } else {
            this.fireConnectFragmentAnalytics();
        }
        this.fireShowConnectBlockedAppsBanner();
    }

    componentDidUpdate(prevProps: Props & StateProps) {
        const { fetchConnectFragments, connectFragments, page, portalId, requestTypeId, requestKey } = this.props;
        const requestTypeUpdated = prevProps.requestTypeId !== requestTypeId || prevProps.portalId !== portalId;

        if (requestTypeUpdated) {
            fetchConnectFragments({ portalId, requestTypeId, requestKey, portalPage: page });
        }

        if (!prevProps.connectFragments && connectFragments) {
            this.fireConnectFragmentAnalytics();
        } else if (prevProps.connectFragments && connectFragments) {
            const previouslyErrored = 'error' in prevProps.connectFragments;
            const currentlyErrored = 'error' in connectFragments;
            if (previouslyErrored !== currentlyErrored) {
                this.fireConnectFragmentAnalytics();
            }
        }
        if (prevProps.projectId !== this.props.projectId) {
            this.fireShowConnectBlockedAppsBanner();
        }
    }

    fireShowConnectBlockedAppsBanner = () => {
        const { page, projectId } = this.props;
        if (isConnectBlockedAppsRelevantPage(page)) {
            this.props.resetBannerState(page);
            if (projectId) {
                fetchIsAnyConnectAppBlocked({
                    projectId,
                    page,
                })
                    .then((isBlocked) => {
                        if (isBlocked) {
                            this.props.showBanner(page);
                        }
                    })
                    .catch(() => {
                        // The error is already logged in the fetchIsAnyConnectAppBlocked function
                    });
            }
        }
    };

    fireConnectFragmentAnalytics = () => {
        const { createAnalyticsEvent, connectFragments, location, portalId, requestTypeId } = this.props;

        if (!createAnalyticsEvent || !connectFragments) return;

        const isError = 'error' in connectFragments;
        if (!isError && (connectFragments as WebFragment[]).length === 0) return;

        const uniqueAppKeys = !isError ? getUniqueAppKeysFromConnectFragments(connectFragments as WebFragment[]) : [];
        const analyticsEvent = createAnalyticsEvent({
            portalId,
            requestTypeId,
            location,
            analyticsType: OPERATIONAL_EVENT_TYPE,
            action: isError ? 'failed' : 'loaded',
            appKeys: uniqueAppKeys,
        });

        analyticsEvent.fire();
    };

    render() {
        const { connectFragments, className, location } = this.props;
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        return <ConnectFragmentsDumb location={location} className={className} connectFragments={connectFragments} />;
    }
}
