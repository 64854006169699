import { isSSRUFOConfigEnabled } from 'feature-flags';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { init } from '@atlaskit/react-ufo/interaction-metrics-init';
import { configure as configureResourceTimings } from '@atlaskit/react-ufo/resource-timing';
import { configure as configureSsrTiming } from '@atlaskit/react-ufo/ssr';
import traceUFOPageLoad from '@atlaskit/react-ufo/trace-pageload';
import { getAnalyticsWebClientPromise } from '@atlassian/help-center-common-util/analytics/client';
import { getEnv, EnvType, getIsPageServerSideRendered } from '@atlassian/help-center-common-util/env';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import { getMark } from '@atlassian/help-center-common-util/perf-markers';
import { retrieveAndSetPageLoadTrace } from '../retrieve-and-set-page-load-trace';

function runConfigureSsrTiming() {
    configureSsrTiming({
        getDoneMark: () => getMark('ssr.spa.rendered')?.startTime ?? null,
        getTimings: () => null,
        getFeatureFlags: () => null,
    });
}

export const initUFO = () => {
    if (isSSRUFOConfigEnabled()) {
        runConfigureSsrTiming();
    }

    retrieveAndSetPageLoadTrace();

    const isStagingEnv = getEnv().environment === EnvType.STAGING;
    //We are only interested in tracking the cache hit ratio of the vendor-react chunk, on the perf-portal.
    //If this works, we can generalise this to track all chunks.
    if (FeatureGates.checkGate('help-center-vendor-react-cache-hit-ratio')) {
        configureResourceTimings({
            sanitiseEndpoints(url) {
                return url;
            },
            mapResources(url) {
                const parts = url.split('/');
                const chunkName = parts[parts.length - 1];
                if (chunkName.includes('vendor-react')) return chunkName;
                return url;
            },
        });
    }
    init(
        Promise.resolve({
            getAnalyticsWebClientPromise,
        }),
        {
            product: 'jira',
            region: getMeta('ajs-region') || 'unknown', //TODO:SSR and FF data
            namePrefix: 'help-center-ufo-',
            ssr: {
                getSSRDoneTime: () => getMark('ssr.spa.rendered')?.startTime,
            },
            capability: {
                /*
                 * Enabling feature flag data to be captured in UFO.
                 */
                feature_flag_access: 1,
                /*
                 * https://atlassian.slack.com/archives/C01AY8132S3/p1723030532244489?thread_ts=1722580624.223629&cid=C01AY8132S3
                 * react_profiler is added since it is required by the type,
                 * will be cleaned up in the future
                 */
                react_profiler: 0,
            },
            vc: {
                /*
                 * https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/129126/diff#platform%2F.changeset%2Fred-lemons-mate.md
                 */
                stopVCAtInteractionFinish: true,
                enabled: true,
                selectorConfig: {
                    className: FeatureGates.checkGate('send_class_name_in_vc_configuration') ? true : isStagingEnv,
                    role: isStagingEnv,
                    id: isStagingEnv,
                    testId: isStagingEnv,
                },
                ssr: getIsPageServerSideRendered(),
                devToolsEnabled: isStagingEnv,
            },
            kind: {
                /*
                 * Sampling works in 1 in the rate specified.
                 * If rate is 1, it captures all events - 1/1
                 */
                page_load: 1,
                transition: 1,
                press: 1,
                typing: 1,
                legacy: 0, //For some Jira events that will be cleaned up in UFO
                hover: 1,
            },
            postInteractionLog: {
                enabled: true,
                rates: {
                    'servicedesk-queues-custom-view': 1,
                    'request-details': 1,
                    'request-create': 1,
                },
            },
        }
    );

    /*
     * Start instrumenting the page load event.
     * We invoke `traceUFOPageLoad` once more
     * when we have the route context.
     * It attaches the route name to the event.
     * */
    traceUFOPageLoad();
};
