import { createRelayResource } from '@atlassian/help-center-common-util/resource-router/resource-router';

import type { viewCustomPageQuery } from '@atlassian/help-center-custom-pages/src/ui/view-custom-page/view-custom-page-query/__generated__/viewCustomPageQuery.graphql';

import ViewCustomPageQuery from '@atlassian/help-center-custom-pages/src/ui/view-custom-page/view-custom-page-query/__generated__/viewCustomPageQuery.graphql';

export const ViewCustomPageResource = createRelayResource<viewCustomPageQuery>({
    type: 'VIEW_CUSTOM_PAGE',
    getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
        return {
            parameters: ViewCustomPageQuery,
            variables: {
                cloudId,
                helpCenterAri,
                layoutAri,
                filter: { isEditMode: false },
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
