import { pick, includes } from 'lodash';
import type { EventProperties } from '@atlassian/help-center-common-util/analytics/types';
import * as whitelist from './whitelist.json';

const WHITELIST_KEYS = Object.keys(whitelist);

export const isWhiteListEvent = (eventName: string | undefined | null) => includes(WHITELIST_KEYS, eventName);

export const sanitizeEventProperties = (
    eventName: string,
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    eventProperties: { [key: string]: string | {} } | EventProperties | undefined | null
) => {
    return pick(eventProperties, whitelist[eventName]);
};
