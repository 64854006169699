import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getWacLink } from 'state/selectors/env';
import FooterDumb from './footer';

const selector = createSelector(getWacLink, (linkTo) => ({
    linkTo,
}));

export const Footer = connect(selector)(FooterDumb);
