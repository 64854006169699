import { getMeta } from '@atlassian/help-center-common-util/ajs';

declare global {
    interface Window {
        JSD_CUSTOMER_PORTAL_BUILD_VERSION?: PortalBuildVersion;
        __IS_PAGE_SERVER_SIDE_RENDERED__?: boolean;
        JSM_EXCEPTION_QUEUE?: Error[];
    }
}

interface PortalBuildVersion {
    buildKey?: string;
    commit?: string;
}

// These version properties get set at build time, see the plugin pom.
export const getPluginVersion = (): string | undefined => {
    return getMeta && getMeta('sdPluginVersion');
};

export const getCustomerPortalFrontendBuildKey = () => {
    if (window.JSD_CUSTOMER_PORTAL_BUILD_VERSION) {
        return window.JSD_CUSTOMER_PORTAL_BUILD_VERSION.buildKey;
    }
    return undefined;
};
