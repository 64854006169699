/**
 * @generated SignedSource<<367b69357c8fe616ec4319ead788ef4a>>
 * @relayHash 89b5ac4560edc5779e88acaa2f693808
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4d0682ac128b1814b5e2ef5bed041589a8703b625dc2193d4eaf2b90ded3e360

import type { ConcreteRequest, Query } from 'relay-runtime';
export type logoQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  skip: boolean;
};
export type logoQuery$data = {
  readonly helpCenter?: {
    readonly helpCenterById: {
      readonly helpCenterBranding?: {
        readonly logo: {
          readonly url: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly name?: {
        readonly default: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type logoQuery = {
  response: logoQuery$data;
  variables: logoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "HelpCenterName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "default"
        }
      ]
    },
    {
      "concreteType": "HelpCenterBranding",
      "kind": "LinkedField",
      "name": "helpCenterBranding",
      "plural": false,
      "selections": [
        {
          "concreteType": "HelpCenterLogo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "url"
            }
          ]
        }
      ]
    }
  ],
  "type": "HelpCenter"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "logoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "logoQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "__typename"
                  },
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4d0682ac128b1814b5e2ef5bed041589a8703b625dc2193d4eaf2b90ded3e360",
    "metadata": {},
    "name": "logoQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "50f3d8eb5808e1ee69db4f8e639d0b3e";

export default node;
