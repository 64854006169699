export const SIDEBAR_WIDTH = 240;
export const CANT_UNLINK_ALL_DIALOG = 'cant-unlink-all-dialog';
export const UNLINK_CONFIRMATION_DIALOG = 'unlink-confirmation-dialog';
export const MANAGE_PROJECT_HEAD_WIDTH = {
    'select-all': 5,
    'project-name': 35,
    'lead-name': 35,
    status: 15,
    'action-button': 15,
};
export const MANAGE_PAGE_HEAD_WIDTH = {
    'page-name': 40,
    'last-updated': 40,
    'action-button': 20,
};
