import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchAiAnswerFeedbackAction,
    FetchAiAnswerFeedbackSuccess,
    FetchAiAnswerFeedbackFailure,
} from 'state/actions/ai-answer';
import {
    FETCH_AI_ANSWER_FEEDBACK_MODEL,
    fetchAiAnswerFeedbackSuccess,
    fetchAiAnswerFeedbackFailure,
} from 'state/actions/ai-answer';
import type { ErrorResponse } from 'epics/model/types';

export const aiAnswerFeedbackPageEpic: Epic<
    FetchAiAnswerFeedbackAction,
    FetchAiAnswerFeedbackSuccess | FetchAiAnswerFeedbackFailure
> = (action$) => {
    return action$.ofType(FETCH_AI_ANSWER_FEEDBACK_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.aianswer)
            .map((response) => {
                return fetchAiAnswerFeedbackSuccess(response.aianswer);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchAiAnswerFeedbackFailure(error));
            })
    );
};
