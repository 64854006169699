import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { requestGroupReorder } from 'rest/request-group-reorder';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import { REMOVE_REQUEST_GROUP, removeRequestGroupFailedAction } from 'state/actions/remove-request-group';
import type { RemoveRequestGroupAction, RemoveRequestGroupFailedAction } from 'state/actions/remove-request-group';
import { getProjectId, getPortalRequestGroups } from 'state/selectors/portal';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

const REQUEST_ID = 'com.atlassian.servicedesk.portal-ui:customize-portal-sidebar';

export const removeRequestGroupEpic: Epic<
    RemoveRequestGroupAction,
    FetchPortalAction | RemoveRequestGroupFailedAction
> = (action$, store) => {
    return action$
        .ofType(REMOVE_REQUEST_GROUP)
        .mergeMap(
            ({
                payload: {
                    portalId,
                    deletedId,
                    onRemoveGroupSuccess,
                    onRemoveGroupError,
                    analyticsEvent,
                    analyticsFailureEvent,
                },
            }: RemoveRequestGroupAction) => {
                const state = store.getState();
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const projectId = getProjectId(state, portalId)!;

                const requestGroups = getPortalRequestGroups(state, portalId);
                const newOrder = requestGroups.map(({ id }) => id).filter((id) => id !== deletedId);

                // Perform the rest call to update groups
                return (
                    requestGroupReorder({
                        projectId,
                        order: newOrder,
                        id: REQUEST_ID,
                        deleted: [deletedId],
                        inline: true,
                    })
                        // On success, refresh the model
                        .map(() => {
                            analyticsEvent && sendEvent(analyticsEvent);
                            if (onRemoveGroupSuccess) {
                                onRemoveGroupSuccess();
                            }
                            return fetchPortalAction({
                                id: portalId,
                                expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'],
                            });
                        })
                        // On fail, clear the ui state to reverse optimistic update
                        .catch(() => {
                            if (onRemoveGroupError) {
                                onRemoveGroupError();
                            }
                            if (analyticsFailureEvent) {
                                sendEvent(analyticsFailureEvent);
                            }
                            return Observable.of(removeRequestGroupFailedAction());
                        })
                );
            }
        );
};
