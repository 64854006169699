/**
 * @generated SignedSource<<1a92ae1a3b763d22cdf22d34005771ab>>
 * @relayHash 8f1826e2b5128402f612ebed9cd3ead7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cef3402a4dba2853f871dc4054dde3db258a73cfca1f1548f7ba65bb695e7773

import type { ConcreteRequest, Query } from 'relay-runtime';
export type searchLoaderVirtualAgentEnabledQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  isVaOnHcGAEnabled: boolean;
};
export type searchLoaderVirtualAgentEnabledQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly virtualAgentEnabled: boolean | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
  readonly virtualAgent?: {
    readonly virtualAgentAvailability: boolean | null | undefined;
  } | null | undefined;
};
export type searchLoaderVirtualAgentEnabledQuery = {
  response: searchLoaderVirtualAgentEnabledQuery$data;
  variables: searchLoaderVirtualAgentEnabledQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isVaOnHcGAEnabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "virtualAgentEnabled"
},
v5 = {
  "condition": "isVaOnHcGAEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "VirtualAgentQueryApi",
      "kind": "LinkedField",
      "name": "virtualAgent",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "containerId",
              "variableName": "helpCenterAri"
            }
          ],
          "kind": "ScalarField",
          "name": "virtualAgentAvailability"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "searchLoaderVirtualAgentEnabledQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "type": "HelpCenter"
              }
            ]
          }
        ]
      },
      (v5/*: any*/)
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "searchLoaderVirtualAgentEnabledQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "id": "cef3402a4dba2853f871dc4054dde3db258a73cfca1f1548f7ba65bb695e7773",
    "metadata": {},
    "name": "searchLoaderVirtualAgentEnabledQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e02588c503555d8e364b2427c266c0f8";

export default node;
