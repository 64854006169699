import type { AjaxError } from 'rxjs';

import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import { createComment } from 'rest/comment';
import type { HandleAjaxError, ShowErrorFlag } from 'state/actions/flags';
import { handleAjaxError, showErrorFlag } from 'state/actions/flags';
import type {
    CreateComment,
    CreateCommentSuccess,
    CreateCommentFailure,
    GetReadCredentials,
} from 'state/actions/request-details';
import {
    CREATE_COMMENT,
    createCommentSuccess,
    createCommentFailure,
    getReadCredentials,
} from 'state/actions/request-details';
import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkErrorCode } from '@atlassian/help-center-common-util/error-codes';
import messages from './messages';

export const createCommentEpic: Epic<
    CreateComment,
    CreateCommentSuccess | CreateCommentFailure | GetReadCredentials | HandleAjaxError | ShowErrorFlag
> = (action$) => {
    return action$.ofType(CREATE_COMMENT).mergeMap((createCommentAction) => {
        const payload = createCommentAction.payload;
        return createComment(payload.requestId, payload.comment, payload.fileIds, payload.xsrftoken)
            .flatMap((createCommentResponse) => {
                const { meta, numberOfMentionedUsers } = payload;
                if (meta?.analyticsSuccessEvent) {
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                    // eslint-disable-next-line no-shadow
                    meta?.analyticsSuccessEvent.update((payload: AnalyticsEventPayload) => ({
                        ...payload,
                        count: numberOfMentionedUsers || 0,
                    }));
                    sendEvent(meta?.analyticsSuccessEvent);
                }
                return concat(
                    Observable.of(createCommentSuccess(payload.requestKey, createCommentResponse)),
                    Observable.of(getReadCredentials(payload.portalId, payload.requestId, payload.requestKey))
                );
            })
            .catch((error: AjaxError) => {
                const meta = payload.meta;
                if (meta?.analyticsFailureEvent) {
                    sendEvent(meta.analyticsFailureEvent);
                }

                const isShowErrorFlag =
                    payload.numberOfMentionedUsers &&
                    payload.numberOfMentionedUsers > 0 &&
                    !isNetworkErrorCode(error.status);

                return concat(
                    Observable.of(createCommentFailure()),
                    Observable.of(
                        isShowErrorFlag
                            ? showErrorFlag(
                                  messages.commentCreateWithMentionsFailedTitle,
                                  messages.commentCreateWithMentionsFailedDescription
                              )
                            : handleAjaxError(error)
                    )
                );
            });
    });
};
