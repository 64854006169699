// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { newElevationsFromDesignSystems } from 'view/styles/elevation';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const linkCardBase = css`
    ${mixins.raiseUpOnHoverOrFocus(newElevationsFromDesignSystems.e100, newElevationsFromDesignSystems.e200)};
    ${mixins.borderRadius};
    ${mixins.focusRing.default};
    display: block;
    background-color: ${token('elevation.surface.raised', colors.N0)};

    :hover,
    :focus {
        text-decoration: none;
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const LinkCard = styled(Link)`
    ${linkCardBase};
`;
