import type { RequestListEntryV2 } from 'rest/request-list';

import type { FetchRequestListSuccess, MarkInitialPageLoadRendered, InvalidatePages } from 'state/actions/request-list';
import {
    FETCH_REQUEST_LIST_SUCCESS,
    MARK_INITIAL_PAGE_LOAD_RENDERED,
    INVALIDATE_PAGES,
} from 'state/actions/request-list';
import type { SortOrderType } from '@atlaskit/dynamic-table/types';
import { initialModel } from '@atlassian/help-center-common-util/model';
export interface PagesV2 {
    isInitialPageLoad: boolean;
    [page: number]: RequestListEntryV2[];
}
export interface RequestListState {
    resultsPerPage: number;
    totalResults: number;
    sortBy?: string;
    sortOrder?: SortOrderType;
    pages: PagesV2;
}

const emptyState: RequestListState = {
    totalResults: 0,
    resultsPerPage: 20,
    pages: { isInitialPageLoad: false },
};

export const getInitialRequestListState = (): RequestListState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.allReqFilter) {
        const { resultsPerPage, totalResults, sortBy, sortOrder } = initialModelState.allReqFilter;
        return {
            resultsPerPage,
            totalResults,
            sortBy,
            sortOrder,
            pages: {
                isInitialPageLoad: true,
                [initialModelState.allReqFilter.selectedPage]: initialModelState.allReqFilter.requestList,
            },
        };
    }
    return emptyState;
};

export type HandledActions = FetchRequestListSuccess | MarkInitialPageLoadRendered | InvalidatePages;

const defaultState: RequestListState = getInitialRequestListState();

export function requestListReducer(state: RequestListState = defaultState, action: HandledActions): RequestListState {
    switch (action.type) {
        case FETCH_REQUEST_LIST_SUCCESS:
            return handleFetchRequestListSuccess(state, action);
        case MARK_INITIAL_PAGE_LOAD_RENDERED:
            return handleMarkInitialPageLoadRendered(state);
        case INVALIDATE_PAGES:
            return handleInvalidatePages(state, action);
        default:
            return state;
    }
}

function handleInvalidatePages(state: RequestListState, action: InvalidatePages): RequestListState {
    const currentPage = action.payload;

    return {
        ...state,
        pages: {
            isInitialPageLoad: false,
            [currentPage]: state.pages[currentPage],
        },
    };
}

function handleFetchRequestListSuccess(state: RequestListState, action: FetchRequestListSuccess): RequestListState {
    const response = action.payload;
    if (response) {
        if (response.totalResults === state.totalResults) {
            if (response.sortBy !== state.sortBy || response.sortOrder !== state.sortOrder) {
                if (response.selectedPage === 1) {
                    // sorting order or sorting column has been changed by user
                    // this would take user to first page
                    // active page is not tracked in redux store
                    // but we should clean up data for other pages if in memory
                    return {
                        ...state,
                        sortOrder: response.sortOrder,
                        sortBy: response.sortBy,
                        pages: {
                            isInitialPageLoad: false,
                            [response.selectedPage]: response.requestList,
                        },
                    };
                }
            }
            return {
                ...state,
                pages: {
                    ...state.pages,
                    isInitialPageLoad: false,
                    [response.selectedPage]: response.requestList,
                },
            };
        }
        return {
            resultsPerPage: response.resultsPerPage,
            totalResults: response.totalResults,
            sortBy: response.sortBy,
            sortOrder: response.sortOrder,
            pages: {
                isInitialPageLoad: false,
                [response.selectedPage]: response.requestList,
            },
        };
    }
    return state;
}

const handleMarkInitialPageLoadRendered = (state: RequestListState): RequestListState => ({
    ...state,
    pages: {
        ...state.pages,
        isInitialPageLoad: false,
    },
});
