import { get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';
import { getLocalStorageItem, setLocalStorageItem } from '@atlassian/help-center-common-util/localStorage';

export interface HelpSeekerTypeResponse {
    isUserDomainSameAsAdmin: string;
    isUserDomainInApprovedDomains: string;
}

export const helpSeekerTypeAttributeValue = Object.freeze({
    YES: 'Yes',
    NO: 'No',
    UNIDENTIFIED: 'Unidentified',
});

export const getHelpSeekerTypeAttributes = (basePath: string = contextPath): Observable<HelpSeekerTypeResponse> => {
    const uri = `${basePath}/rest/servicedesk/1/customer/analytics/help-seeker/type`;

    const localStorageHelpSeekerTypeResponse = getLocalStorageItem('help-seeker-type');
    if (localStorageHelpSeekerTypeResponse) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Observable.of(JSON.parse(localStorageHelpSeekerTypeResponse));
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(uri, { 'Content-Type': 'application/json' })
        .map(({ response }) => {
            setLocalStorageItem('help-seeker-type', JSON.stringify(response));
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return response;
        })
        .catch(() => {
            return Observable.of({
                isUserDomainSameAsAdmin: helpSeekerTypeAttributeValue.UNIDENTIFIED,
                isUserDomainInApprovedDomains: helpSeekerTypeAttributeValue.UNIDENTIFIED,
            });
        });
};
