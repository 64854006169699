import { has } from 'lodash';
import * as querystring from 'query-string';
import { history } from '@atlassian/help-center-common-util/history';

export function getAll(): { [key: string]: string } {
    if (!history.location.search) {
        return {};
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return { ...querystring.parse(history.location.search) };
}

export function get(key: string): string | undefined {
    const values = getAll();
    return values && values[key];
}

export const stringify = (queryParams: { [key: string]: string | undefined }) =>
    querystring.stringify(queryParams).replace(/%20/g, '+');

export function set(newValues: { [key: string]: string | undefined }, replace: boolean = true) {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const values = querystring.parse(history.location.search);
    const search = stringify({
        ...values,
        ...newValues,
    });

    const newLocation = {
        ...history.location,
        search,
    };

    if (replace) {
        history.replace(newLocation);
    } else {
        history.push(newLocation);
    }
}

interface Params {
    [key: string]: string | number | undefined;
}

export const addParamsToUrl = (url: string, addParams: Params) => {
    const qs = querystring.extract(url);
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const params = {
        ...querystring.parse(qs),
        ...addParams,
    };
    const newQueryString = stringify(params);

    if (newQueryString) {
        return `${url.split('?')[0]}?${newQueryString}`;
    }

    return url;
};

export const removeParamsFromUrl = (url: string, paramsToRemove: string[]) => {
    const qs = querystring.extract(url);
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const params = querystring.parse(qs);

    let isParamsRemoved = false;
    paramsToRemove.forEach((paramToRemove) => {
        if (has(params, paramToRemove)) {
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            delete params[paramToRemove];
            isParamsRemoved = true;
        }
    });

    // don't modify url if there is nothing to remove
    if (!isParamsRemoved) {
        return url;
    }

    const newQueryString = stringify(params);
    const urlWithoutQuery = url.split('?')[0];

    if (newQueryString) {
        return `${urlWithoutQuery}?${newQueryString}`;
    }
    return urlWithoutQuery;
};
