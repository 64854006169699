// 800px is normal width
const GRID_NORMAL_WIDTH = '800px';

// 1000px is wide width
const GRID_WIDE_WIDTH = '1000px';

// 1100px is extra wide width
const GRID_XWIDE_WIDTH = '1100px';

// 1200px is extra wide width
const GRID_XXWIDE_WIDTH = '1200px';

const GRID_NOTE_WIDTH = '480px';

export type GridAppearanceTypes = 'xxwide' | 'xwide' | 'wide' | 'normal' | 'note' | 'none';

export const getContentWidth = (appearance: GridAppearanceTypes) => {
    switch (appearance) {
        case 'xxwide':
            return GRID_XXWIDE_WIDTH;
        case 'xwide':
            return GRID_XWIDE_WIDTH;
        case 'wide':
            return GRID_WIDE_WIDTH;
        case 'note':
            return GRID_NOTE_WIDTH;
        default:
            return GRID_NORMAL_WIDTH;
    }
};
