import React from 'react';

let isMounted: boolean = false;
function setIsMounted(value: boolean) {
    isMounted = value;
}

export const isAppMounted = {
    /** Readonly value and is set only from MarkAppMounted */
    get value() {
        return isMounted;
    },
};

/** used to mark the app as mounted, which sets a readOnly value isAppMounted.value  */
export const MarkAppMounted: React.FC = () => {
    React.useEffect(() => {
        setIsMounted(true);
    }, []);
    return null;
};
