import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isLoggedIn } from 'state/selectors/user';
import ErrorBoundary from 'view/error-boundary/error-boundary';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { AsyncAdvertiseRequestServiceDeskFooter } from '@atlassian/help-center-common-component/advertise-request-service-desk-footer';
import { useAdDismissalPersistence } from '@atlassian/help-center-common-component/advertise-request-service-desk-footer/use-ad-dismissal-persistence';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { requestServiceDeskFromPortalExperience } from './experiences';

const JSM_END_USER_CAN_REQUEST_SERVICE_DESK_EXPERIMENT = 'jsm_end_user_can_request_service_desk';

/**
 * We're using Feature Gate and Feature Experiment together to control the overall experiment here
 *
 * The gate named `jsm_end_user_can_request_service_desk_fg` to control the rollout, for example we
 * can turn on the gate for 10% for production users
 *
 * While we have an experiment named `jsm_end_user_can_request_service_desk` defined as well, and we use
 * gate `jsm_end_user_can_request_service_desk_fg` (notice the `_fg` suffix here) to control who will be
 * part of the experiment (either in test group or control group).
 * (It's called Targeting Gate: https://docs.statsig.com/guides/featuregates-or-experiments#using-feature-gates)
 *
 * For example, we can enable 10% production users into 2 groups, one can `canRequestServiceDesk` while the other
 * __cannot__.
 *
 */
const canEndUserRequestNewServiceDesk = () =>
    FeatureGates.getExperimentValue<boolean>(
        JSM_END_USER_CAN_REQUEST_SERVICE_DESK_EXPERIMENT,
        'canRequestServiceDesk',
        false,
        {
            fireExperimentExposure: false,
        }
    );

const isFeatureGateOpen = () => FeatureGates.checkGate('jsm_end_user_can_request_service_desk_fg');

const CTAContainer = ({ isUserLoggedIn }: { isUserLoggedIn: boolean }) => {
    useEffect(() => {
        if (isFeatureGateOpen() && isUserLoggedIn) {
            FeatureGates.manuallyLogExperimentExposure(JSM_END_USER_CAN_REQUEST_SERVICE_DESK_EXPERIMENT);
        }
    }, [isUserLoggedIn]);

    const [isAdDismissed] = useAdDismissalPersistence();
    useUFOComponentExperience(requestServiceDeskFromPortalExperience);

    const shouldShowCTAOnFooter = useCallback(
        () => !isAdDismissed && canEndUserRequestNewServiceDesk() && isUserLoggedIn,
        [isAdDismissed, isUserLoggedIn]
    );

    if (!shouldShowCTAOnFooter()) {
        return null;
    }

    return (
        <div data-testid="async-advertise-request-service-desk-footer">
            <AnalyticsContext
                data={{
                    source: 'requestServiceDeskFromPortalFooter',
                }}
            >
                <ErrorBoundary id="container" packageName="requestServiceDeskFromPortalFooter">
                    <AsyncAdvertiseRequestServiceDeskFooter />
                </ErrorBoundary>
            </AnalyticsContext>
        </div>
    );
};

const selector = createSelector(isLoggedIn, (isUserLoggedIn) => ({
    isUserLoggedIn,
}));

export default connect(selector)(CTAContainer);
