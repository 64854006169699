// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { css } from 'styled-components';
import { colors, elevation } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import * as zIndex from './z-index';

/**
 * Cards
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const e100 = css`
    ${elevation.e100};
    z-index: ${zIndex.card};
`;

/**
 * Inline dialogs
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const e200 = css`
    ${elevation.e200};
    z-index: ${zIndex.dialog};
`;

/**
 * Modals
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const e300 = css`
    ${elevation.e300};
    z-index: ${zIndex.modal};
`;

/**
 * (Side)Panels
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const e400 = css`
    ${elevation.e400};
    z-index: ${zIndex.panel};
`;

/**
 * Flag messages
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const e500 = css`
    ${elevation.e500};
    z-index: ${zIndex.flag};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const newElevationsFromDesignSystems = {
    // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    e100: css`
        z-index: ${zIndex.card};
        box-shadow: ${token('elevation.shadow.raised', `0 1px 1px ${colors.N50A}, 0 0 1px 1px ${colors.N40A}`)};
    `,
    // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    e200: css`
        z-index: ${zIndex.dialog};
        box-shadow: ${token('elevation.shadow.overlay', `0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`)};
    `,
};
