import * as React from 'react';
import type { InferableComponentEnhancerWithProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getJsmAIConfigEnabled } from 'state/selectors/env';
import { BannerWithPaperLayoutRoute } from 'view/layout/banner-with-paper-layout';
import { HTTP } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import type { PersistedError } from 'state/persisted/types';

const selector = createSelector(getJsmAIConfigEnabled, (jsmAIConfigEnabled) => ({
    jsmAIConfigEnabled,
}));

type ConnectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;

const connector = connect(selector, null);

type JsmAiConfig = Omit<PersistedError['error'], 'status'>;

const withJsmAiConfigEnabled =
    (options: JsmAiConfig) =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const ReturnComponent: React.FC<TProps & ConnectedProps<typeof connector>> = (props) => {
            const { jsmAIConfigEnabled } = props;

            if (jsmAIConfigEnabled) {
                //@ts-ignore TypeScript upgrade 4.7.4
                return <WrappedComponent {...props} />;
            }

            const { message, callToActionUrl, callToActionText } = options;

            return (
                <BannerWithPaperLayoutRoute
                    component={() => (
                        <ErrorPage
                            error={{
                                message,
                                callToActionUrl,
                                callToActionText,
                                status: HTTP.FORBIDDEN,
                            }}
                        />
                    )}
                    layoutProps={{
                        headerless: false,
                        paperAppearance: 'normal',
                        fadeInSearchModal: true,
                    }}
                />
            );
        };

        const ConnectedComponent = connector(ReturnComponent);
        ConnectedComponent.displayName = 'WithJsmAiConfigEnabled';

        return ConnectedComponent;
    };

export default withJsmAiConfigEnabled;
