import { isEqual as deepEquals } from 'lodash';
import NodeCache from 'node-cache';
import type { Request } from 'rest/models';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { RequestCacheEntry } from 'rest/models/models-cache/types';
import type { ModelsResponse, ModelType } from 'rest/models/types';

type CacheEventType = 'cache-hit' | 'cache-missed' | 'cache-hit-unequal';

const TEN_MINUTE_TIMEOUT = 600;

const createCacheOperationalPayload = <TModelType extends ModelType>(
    modelType: TModelType,
    cacheEventType: CacheEventType
) => {
    return {
        action: cacheEventType,
        actionSubject: 'model-caches',
        source: 'unknownSource',
        attributes: {
            modelType,
        },
    };
};

const fireCacheMissOperationalEvent = <TModelType extends ModelType>(modelType: TModelType) => {
    operationalEvent(createCacheOperationalPayload(modelType, 'cache-missed'));
};

const fireCacheHitOperationalEvent = <TModelType extends ModelType>(modelType: TModelType) => {
    operationalEvent(createCacheOperationalPayload(modelType, 'cache-hit'));
};

/**
 * Support client-side caching of models responses, which are expected to
 * change infrequently, and can therefore be safely expired with a timestamp
 * and TTL.
 */
export class ModelsCache {
    private static instance: ModelsCache;

    /**
     * We will cache the user model later after splitting it into two; a static component and one for dynamic data such
     * as counts.
     */
    private toCacheModels: ModelType[] = [
        'helpCenterBranding',
        'suggestedRequestTypes',
        'organisations',
        'portalsAndRequestTypes',
    ];

    private cache: NodeCache;

    constructor() {
        const cacheTTL: number = TEN_MINUTE_TIMEOUT;
        this.cache = new NodeCache({
            stdTTL: cacheTTL,
            checkperiod: cacheTTL * 0.5,
            useClones: false,
        });
    }

    static getInstance() {
        if (!ModelsCache.instance) {
            ModelsCache.instance = new ModelsCache();
        }
        return ModelsCache.instance;
    }

    shouldCache(modelType: ModelType): boolean {
        return this.toCacheModels.includes(modelType);
    }

    getFromCache<TModelType extends ModelType>(request: Request<TModelType>): RequestCacheEntry | undefined {
        const cachedResult = this.getFromCacheInternal(request);
        if (cachedResult !== undefined) {
            fireCacheHitOperationalEvent(request.type);
        } else {
            fireCacheMissOperationalEvent(request.type);
        }
        return cachedResult;
    }

    private getFromCacheInternal<TModelType extends ModelType>(
        request: Request<TModelType>
    ): RequestCacheEntry | undefined {
        const requestCacheEntries = (this.cache.get(request.type) as RequestCacheEntry[]) || [];
        return requestCacheEntries.find((cacheEntry) => deepEquals(cacheEntry.request, request));
    }

    putToCache<TModelType extends ModelType>(request: Request<TModelType>, modelsResponse: ModelsResponse): void {
        const fromCache = this.getFromCacheInternal(request);
        if (fromCache === undefined) {
            const requests: RequestCacheEntry[] = this.cache.get(request.type) || [];
            requests.push({
                request,
                response: modelsResponse,
            });
            this.cache.set(request.type, requests);
        }
    }

    del(modelType: ModelType): void {
        this.cache.del(modelType);
    }

    /**
     * Initialise model cache with models contained in jsonPayload div.
     * This avoids loading them again during app init.
     *
     * Note that this implementation assumes model requests during initial page loads don't contain any parameters
     * other than the modelType.
     */
    initFromInitialJsonPayload(): void {
        const initialModelState = initialModel();
        for (const modelTypeStr of Object.keys(initialModelState)) {
            const modelType: ModelType = modelTypeStr as ModelType;
            if (ModelsCache.getInstance().shouldCache(modelType)) {
                ModelsCache.getInstance().putToCache(
                    { type: modelType, params: {} },
                    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    // Suppressing existing violation. Please fix this.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    { [modelType]: initialModelState[modelType] }
                );
            }
        }
    }
}
