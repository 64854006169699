import * as React from 'react';
import { styled } from '@compiled/react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { bp } from 'view/styles/compiled/breakpoints';
import * as grid from 'view/styles/grid';
import Heading from '@atlaskit/heading';
import { SpotlightTarget } from '@atlaskit/onboarding';
import type { Option } from '@atlaskit/select';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Select } from '@atlassian/help-center-common-component/analytics/select';
import messages from './messages';
import type { SortBy } from 'rest/featured-and-sorted-portals/types';

export interface SortByFilterProps {
    value: SortBy;
    isDisabled?: boolean;
    shouldHideLabel?: boolean;
    onChange: (sortBy: SortBy) => void;
}

const selectCustomStyle = {
    container: (provided: object) => ({
        ...provided,
        marginLeft: token('space.200', '16px'),
        width: grid.multiple(18).px,
    }),
};

export const SortByFilter: React.FC<SortByFilterProps & WrappedComponentProps> = ({
    intl,
    isDisabled,
    value,
    shouldHideLabel,
    onChange,
}) => {
    const sortBySelectOptions = {
        ALPHABETICALLY: intl.formatMessage(messages.alphabeticOption),
        POPULARITY: intl.formatMessage(messages.popularityOption),
    };

    const options = Object.entries(sortBySelectOptions).map(([key, label]) => ({
        label,
        value: key,
    }));

    const sortByChange = React.useCallback(
        (option: Option<SortBy>) => {
            if (option && option.value !== value) {
                onChange(option.value);
            }
        },
        [onChange, value]
    );

    return (
        <SpotlightTarget name="sort-by-filter">
            <FilterWrapper>
                <FilterContent>
                    {!shouldHideLabel && (
                        <Heading level="h300" as="div">
                            <label htmlFor="sort-by-filter">{intl.formatMessage(messages.sortByFilterLabel)}</label>
                        </Heading>
                    )}
                    <Select
                        classNamePrefix="portal-reorder-experience-sort-by-filter"
                        actionSubjectId="sortByFilter"
                        value={{ value, label: sortBySelectOptions[value] }}
                        options={options}
                        styles={selectCustomStyle}
                        isSearchable={false}
                        isDisabled={isDisabled}
                        onChange={sortByChange}
                        inputId="sort-by-filter"
                    />
                </FilterContent>
            </FilterWrapper>
        </SpotlightTarget>
    );
};

export default React.memo(injectIntl(SortByFilter));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FilterWrapper = styled.div({
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    width: grid.multiple(28).px,
    padding: token('space.200', '16px'),
    marginLeft: token('space.negative.200', '-16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FilterContent = styled.div({
    display: 'flex',
    alignItems: 'center',
    marginLeft: token('space.500', '40px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromXSmall]: {
        marginLeft: 0,
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    label: {
        textTransform: 'uppercase',
        margin: 0,
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        color: token('color.text.subtlest', colors.N400),
    },
});
