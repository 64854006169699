/**
 * @generated SignedSource<<41bfff0157d6018b992bff1a091cde69>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type projectsListFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly avatar: {
        readonly small: string | null | undefined;
      } | null | undefined;
      readonly lead: {
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
      readonly name: string;
      readonly projectId: string | null | undefined;
      readonly webUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "projectsListFragment";
};
export type projectsListFragment$key = {
  readonly " $data"?: projectsListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectsListFragment",
  "selections": [
    {
      "concreteType": "JiraProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "projectId"
            },
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "small"
                }
              ]
            },
            {
              "kind": "LinkedField",
              "name": "lead",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "picture"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "webUrl"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraProjectConnection"
};
})();

(node as any).hash = "3eeb7301918e0096af7f6ae5ad2df33f";

export default node;
