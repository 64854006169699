/**
 * AI Mate response types
 */

import type { DocNode } from '@atlaskit/adf-schema';
import type { Message } from '@atlassian/conversation-assistant';
import type { HelpSeekerMessage, MessageMetadata } from '../../rest/converse';
import type { StoreAbortController } from '../../utils/ai-mate/store-abort-controller';

export type { HelpSeekerMessage, MessageMetadata };

export { FeedbackType } from '../../rest/converse';

interface AiMateConversationResponse {
    message: ConversationMessage;
    [key: string]: unknown;
}

export interface AssistantMessage {
    readonly userType: 'assistant';
    readonly aiMateConversationResponse: AiMateConversationResponse;
    readonly messageMetadata: MessageMetadata;
}

/**
 *  Converse api response types
 */

export interface FeedbackMessage {
    readonly userType: 'feedback';
    readonly messageContentAdf: string;
    readonly confidenceScore?: number;
}

export interface ErrorMessage {
    readonly userType: 'error';
}

// eslint-disable-next-line no-shadow
export enum ChannelMessageTypes {
    CHANNEL_FAILURE = 'CHANNEL_FAILURE',
    CHANNEL_EXPIRY = 'CHANNEL_EXPIRY',
    CHANNEL_CONVERSATION_OVER = 'CHANNEL_CONVERSATION_OVER',
}

export interface ChannelMessage {
    readonly userType: 'channelMessage';
    readonly messageType: ChannelMessageTypes;
}

export interface FollowUpMessage {
    readonly userType: 'followUp';
    readonly queries: string[];
}

export interface FeedbackResponse {
    readonly userType: 'feedbackResponse';
    readonly messageContentAdf: DocNode;
}

export interface CsatDropdownMessage {
    readonly userType: 'csatDropdown';
    readonly messageMetadata: MessageMetadata;
}
export interface RetryMessage {
    readonly userType: 'retry';
    readonly messageMetadata: MessageMetadata;
}

export type ChatMessage =
    | HelpSeekerMessage
    | ErrorMessage
    | AssistantMessage
    | ChannelMessage
    | FollowUpMessage
    | FeedbackResponse
    | RetryMessage
    | CsatDropdownMessage;

export interface FeedbackCollectorConfig {
    isVisible: boolean;
    messageId: string | undefined;
}
export interface Conversation {
    readonly conversationId: string | null;
    readonly history: readonly ChatMessage[];
    readonly storeAbortController?: StoreAbortController;
    readonly externalConversationId?: string;
}

export interface ConversationExperienceUIState {
    conversationScrollBarWidth: string;
}

export interface State {
    readonly isLoading: boolean;
    readonly conversation: Conversation | null;
    readonly searchAll: boolean;
    readonly pipeline: string;
    readonly isTesting: boolean;
    feedbackCollectorConfig: FeedbackCollectorConfig;
    activeTimer: NodeJS.Timeout | null;
    conversationStartTime?: number;
    ui: ConversationExperienceUIState;
}

export interface ConversationMessageMetadata {
    bm25Variant: string;
    confidenceScore: number;
    isFallbackMessage: boolean;
    isConfluenceLicensedUser: boolean;
    searchResultCount: number;
    totalSearchResultCount: number;
    isVagueQuery: boolean;
    originalQuery: string;
    semanticSearchLocationVariant: string;
    semanticSearchVariant: string;
}

export type ConversationMessage = Omit<Message, 'message_metadata'> & {
    message_metadata?: ConversationMessageMetadata;
};
