import { defineMessages } from 'react-intl-next';

export default defineMessages({
    fullNameMissingError: {
        defaultMessage: 'You must enter a full name',
        description: 'Error message when the full name field is blank',
        id: 'cv.sign.up.full.name.empty.error',
    },
    fullNameTooLongError: {
        defaultMessage: 'Full name must be less than 255 characters',
        description: 'Error message when the full name field value is too long',
        id: 'cv.sign.up.full.name.too.long.error',
    },
    passwordMissingError: {
        defaultMessage: 'You must enter a password',
        description: 'Error message when the password field is blank',
        id: 'cv.sign.up.password.empty.error',
    },
    passwordTooShortError: {
        defaultMessage: 'Password is too short',
        description: 'Error message that will show under the password field if it is shorter than 8 characters.',
        id: 'cv.sign.up.password.too.short.error',
    },
    passwordTooLongError: {
        defaultMessage: 'Password may not contain more than 100 characters',
        description: 'Error message when the password field value is too long',
        id: 'cv.sign.up.password.too.long.error.old',
    },
});
