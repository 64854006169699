/** Utility functions to assist managing drop events in React Beautiful DnD */
import { isNil } from 'lodash';

// reorderList(): Precondition: fromIndex and toIndex are valid indices of list
export const reorderList = (list: number[], fromIndex: number, toIndex: number) => {
    const newList = Array.from(list);

    if (!isNil(toIndex) && !isNil(fromIndex)) {
        newList.splice(fromIndex, 1);
        newList.splice(toIndex, 0, list[fromIndex]);
    }

    return newList;
};

// deleteFromList(): Precondition: index is valid within list
export const deleteFromList = (list: number[], index: number) => {
    const newList = Array.from(list);

    if (!isNil(index)) {
        newList.splice(index, 1);
    }

    return newList;
};
