import { mergeWith } from 'lodash';
import type { State } from 'state';
import { getLocale } from 'state/selectors/user';

export const getHelpCenterState = (state: State) =>
    mergeWith(
        {},
        state.persisted.helpCenter,
        state.ui.helpCenterPreview,
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/ban-types
        (_: {} | undefined, srcValue: {} | undefined) => {
            // If the value is undefined we will instead change it to null, the reason for this is to ensure that the resulting
            // property gets replaced with null, by default mergeWith (and merge) does not replace properties if the value is undefined
            if (srcValue === undefined) {
                return null;
            }

            // If the value is not specifically 'undefined' we leave it up to the default customizer to handle
            return undefined;
        }
    );
const getTranslation = (state: State, language: string) => getHelpCenterState(state).translations[language];

export const getLogoUrl = (state: State) => getHelpCenterState(state).logoUrl;
export const getSharedPortalName = (state: State) => {
    const userLocale = getLocale(state);
    const translation = userLocale && getTranslation(state, userLocale);

    if (translation && translation.sharedPortalName) {
        return translation.sharedPortalName;
    }

    return getHelpCenterState(state).sharedPortalName;
};

export const getThemeColor = (state: State) => getHelpCenterState(state).themeColor;
export const getIsLoaded = (state: State) => state.persisted.helpCenter.isLoaded;

/**
 * Fetch whether the kb is enabled on the help center. This will return false if the help center model is not available
 */
export const getIsKbEnabled = (state: State) => {
    return !!state.persisted.helpCenter.knowledgeBase;
};

export const getRawBrandingResponse = (state: State) => {
    return state.persisted.helpCenter.rawBrandingResponse;
};
export const getDefaultSiteLanguageTag = (state: State) => {
    const rawBrandingResponse = getRawBrandingResponse(state);
    return rawBrandingResponse !== undefined ? rawBrandingResponse.siteDefaultLanguageTag : undefined;
};

export const getHelpCenterBanner = (state: State) => getHelpCenterState(state).helpCenterBannerUrl;

export const getCanCustomize = (state: State) => getHelpCenterState(state).canCustomizeHelpCenter;

export const getHelpCenterTitle = (state: State): string => {
    const userLocale = getLocale(state);
    const translation = userLocale && getTranslation(state, userLocale);
    const helpCenterTitle =
        translation && translation.helpCenterTitle
            ? translation.helpCenterTitle
            : getHelpCenterState(state).helpCenterTitle;

    return helpCenterTitle;
};

export const getHelpCenterTitleColor = (state: State) => {
    return getHelpCenterState(state).helpCenterTitleColor;
};
export const getHelpCenterBrandingLoader = (state: State) => {
    return getHelpCenterState(state).isBrandingLoaded;
};
export const getHelpCenterTopBarSplitFlag = (state: State) => {
    return getHelpCenterState(state).hasTopBarBeenSplit;
};
export const getHelpCenterTopBarColor = (state: State) => {
    return getHelpCenterState(state).topBarColor;
};
export const getHelpCenterTopBarTextColor = (state: State) => {
    return getHelpCenterState(state).topBarTextColor;
};

export const getHelpCenterAnnoucement = (state: State) => {
    const helpCenterState = getHelpCenterState(state);

    const announcementHeader = helpCenterState.announcementHeader ? helpCenterState.announcementHeader : undefined;
    const announcementMessage = helpCenterState.announcementMessage ? helpCenterState.announcementMessage : undefined;

    return {
        announcementHeader,
        announcementMessage,
    };
};

export const getKbHelpCenterLinkDomainURLs: (state: State) => string[] = (state) => {
    return state.persisted.helpCenter?.knowledgeBase?.kbLinkDomainURLs || [];
};

export const getHelpCenterStateTranslationPersisted = (state: State) => {
    return state.persisted.helpCenter.translations ?? {};
};
