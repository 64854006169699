import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

const subscriptionReplyExperienceId = 'service-management.help-center.omnichannel.subscription-reply';
const initialHelpCenterMessageExperienceId = 'service-management.help-center.omnichannel.initial-message';
const subscriptionReplyForIntentExperienceId = 'service-management.help-center.omnichannel.intent-reply';
const subscriptionReplyForAIAnswersExperienceId = 'service-management.help-center.omnichannel.ai-reply';
const sendUserMessageFromSearchExperienceId = 'service-management.help-center.omnichannel.message-from-search-input';

export const subscriptionReplyExperience = new UFOExperience(subscriptionReplyExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const initialHelpCenterMessageExperience = new UFOExperience(initialHelpCenterMessageExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const subscriptionReplyForIntentExperience = new UFOExperience(subscriptionReplyForIntentExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const subscriptionReplyForAIAnswersExperience = new UFOExperience(subscriptionReplyForAIAnswersExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const sendUserMessageFromSearchExperience = new UFOExperience(sendUserMessageFromSearchExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
