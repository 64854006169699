import type { DocNode, HardBreakDefinition } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { JSONDocNode, JSONNode } from '@atlaskit/editor-json-transformer';
import { trackLegacyEvent } from '@atlassian/help-center-common-util/legacy-tracking';
import { parseIssueKeyFromUrl } from '@atlassian/help-center-common-util/url';

interface InlineCardAttribute {
    url: string;
}

export interface ExtensionNodeParameters {
    localId?: string;
    extensionId?: string;
    extensionTitle?: string;
    guestParams?: Record<string, string>;
}

const hardBreakNodeType: HardBreakDefinition['type'] = 'hardBreak';

export const isOnlyWhitespaceAdf = (adf: JSONDocNode | null | undefined): boolean => {
    if (adf && adf.content) {
        return adf.content.every((content) => {
            return isOnlyWhitespaceParagraphNode(content);
        });
    }
    return false;
};

const isOnlyWhitespaceParagraphNode = (content: JSONNode) => {
    if (content.type === 'paragraph') {
        return (
            content.content === undefined ||
            content.content.length === 0 ||
            content.content.every(
                (innerContent) => isOnlyWhitespaceTextNode(innerContent) || isHardBreakNode(innerContent)
            )
        );
    }
    return false;
};

const isOnlyWhitespaceTextNode = (content: JSONNode | undefined) => {
    if (content?.type === 'text') {
        return content.text === undefined || content.text.match(/^\s*$/) !== null;
    }
    return false;
};

const isHardBreakNode = (content: JSONNode | undefined) => {
    return content?.type === hardBreakNodeType;
};

export const parseAdf = (content: string): DocNode => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const node: DocNode = JSON.parse(content);
    /*
    With Editor and Media upgrade, we have a strongly typed renderer, which is causing some types
    to break. This (typecasting to DocNode['content']) should be fixed in a follow-up.
    */
    node.content = removeSuffixFromNodes(node.content) as DocNode['content'];
    return node;
};

const removeSuffixFromNodes = (nodes: JSONNode['content']): JSONNode['content'] => {
    if (!nodes) {
        return nodes;
    }

    return nodes.map((node) => {
        if (!node) {
            return node;
        }
        if (node.type === 'inlineCard' && node.attrs) {
            node.attrs = updateInlineCardAttributes(node.attrs as InlineCardAttribute);
        }

        if (node.content) {
            node.content = removeSuffixFromNodes(node.content);
        }
        return node;
    });
};

// Utility to support plaintext as default values in <RichTextArea /> that are overridden by url parameters
export const plainTextToAdfParagraph = (plainText: string): JSONDocNode => ({
    version: 1, // Using static version number as this doesn't seem to change
    type: 'doc',
    content: [
        {
            type: 'paragraph',
            content: [{ type: 'text', text: plainText }],
        },
    ],
});

const removedIcftFromUrlCache: Record<string, boolean> = {};

const updateInlineCardAttributes = (attributes: InlineCardAttribute) => {
    if (attributes.url && parseIssueKeyFromUrl(attributes.url)) {
        if (!removedIcftFromUrlCache[attributes.url]) {
            trackLegacyEvent('servicedesk.customerview.util.adf.remove.icft.suffix', {});
            removedIcftFromUrlCache[attributes.url] = true;
        }
        attributes.url = removeICFTURLSuffix(attributes.url);
    }
    return attributes;
};

const removeICFTURLSuffix = (content: string) => {
    return content.replace(/#icft=[A-Z][A-Z0-9_]+-\d+/gi, '');
};

/* Traverse through the ADF comment and add all content of type mention inside a Map */
export const getAccountIdsFromComment = (adfText: JSONDocNode): Map<string, string> => {
    const accountIds = new Map<string, string>();

    traverse(adfText, {
        mention: (node) => {
            /* text can be of form @user_name or user_name */
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            let displayName: string = node.attrs?.text;
            if (displayName) {
                displayName = displayName.charAt(0) === '@' ? displayName.substring(1) : displayName;
            }
            accountIds.set(node.attrs?.id, displayName);
        },
    });

    return accountIds;
};
