import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterLabel: {
        defaultMessage: 'Help Center',
        description: 'Label for help center',
        id: 'sidebar.help-center.header.helpcenter.label',
    },
    helpCenterImageAltLabel: {
        defaultMessage: 'help center icon',
        description: 'Label for help center icon in sidebar',
        id: 'sidebar.help-center.header.helpcenter.image.alt.label',
    },
});
