import { buildModelsActionCreator } from 'epics/model/actions';
import type { LanguageResponse } from 'rest/models';

export const FETCH_LANGUAGES_MODEL = 'epic.model.FETCH_LANGUAGES_MODEL';
export const FETCH_LANGUAGES_SUCCESS = 'state.persisted.languages.FETCH_LANGUAGES_SUCCESS';

export type FetchLanguagesAction = ReturnType<typeof fetchLanguagesModel>;

export const fetchLanguagesModel = buildModelsActionCreator(FETCH_LANGUAGES_MODEL, 'availableLanguages');

export interface FetchLanguagesSuccess {
    type: typeof FETCH_LANGUAGES_SUCCESS;
    payload: {
        languages: LanguageResponse[];
    };
}

export const fetchLanguagesSuccess = (languages: LanguageResponse[]): FetchLanguagesSuccess => ({
    type: FETCH_LANGUAGES_SUCCESS,
    payload: {
        languages,
    },
});
