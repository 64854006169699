import { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs';

import { post } from 'epics/ajax';

export interface ORSResponse {
    [key: string]: {
        body: {
            data: {
                generator: {
                    icon?: {
                        url?: string;
                    };
                    name?: string;
                };
                name?: string;
            };
        };
    };
}

export const fetchObjectsFromUrls = (urls: string[]) => {
    return post(
        '/gateway/api/object-resolver/resolve/batch',
        urls.map((url) => ({
            resourceUrl: url,
        })),
        { 'Content-Type': 'application/json' }
    )
        .map(({ response }: AjaxResponse) => response as unknown as ORSResponse)
        .catch((e) => Observable.throw(e));
};
