import { defineMessages } from 'react-intl-next';

export default defineMessages({
    featuredArticlesHeading: {
        defaultMessage: 'Featured articles',
        description: 'Heading for the featured articles section.',
        id: 'cv.categories.page.featured-articles.heading',
    },
    moreArticlesHeading: {
        defaultMessage: 'More articles',
        description: 'Heading for the featured articles section.',
        id: 'cv.categories.page.more-articles.heading',
    },
    articlesEmptyStateText: {
        defaultMessage: 'There are no articles in this category yet. Try another category, or raise a request below.',
        description: 'Articles empty state message',
        id: 'cv.categories.page.no.articles.message',
    },
    categoriesEmptyStateLink: {
        defaultMessage: 'Back to {portalName}',
        description: 'Articles empty state back link text',
        id: 'cv.categories.page.no.articles.back.link',
    },
});
