import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { getSharedPortalName } from 'state/selectors/help-center';
import { getArticleTitle } from 'state/selectors/kb-article';
import { getName as getPortalName, getCategory } from 'state/selectors/portal';
import { withPathParams } from '@atlassian/help-center-common-component/with-path-params';
import { toNumber } from '@atlassian/help-center-common-util/number';
import type { ExternalProps } from './bread-crumbs-dumb';
import { BreadCrumbsDumb } from './bread-crumbs-dumb';

const selector = createSelector(
    getSharedPortalName,
    (state: State, props: ExternalProps) => getPortalName(state, toNumber(props.portalId)),
    (state: State, props: ExternalProps) => {
        const category = getCategory(state, toNumber(props.portalId), props.categoryId);
        return !!category && 'name' in category ? category.name : undefined;
    },
    (state: State, props: ExternalProps) => {
        return getArticleTitle(state, props.articleId);
    },
    (sharedPortalName, portalName, categoryName, articleTitle) => ({
        sharedPortalName,
        portalName,
        categoryName,
        articleTitle,
    })
);

export const BreadCrumbs = connect(selector)(BreadCrumbsDumb);

export default withPathParams(BreadCrumbs);
