import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type {
    FetchUnsubscribedConfirmationSuccess,
    FetchUnsubscribedConfirmationFailure,
} from 'state/actions/unsubscribed-confirmation';
import {
    fetchUnsubscribedConfirmationSuccess,
    fetchUnsubscribedConfirmationFailure,
} from 'state/actions/unsubscribed-confirmation';

import type { FetchUnsubscribedConfirmationAction } from './actions';
import { FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL } from './actions';
import type { ErrorResponse } from 'epics/model/types';

export const unsubscribedConfirmationEpic: Epic<
    FetchUnsubscribedConfirmationAction,
    FetchUnsubscribedConfirmationSuccess | FetchUnsubscribedConfirmationFailure
> = (action$) => {
    return action$.ofType(FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.unsubscribedConfirmation && !!response.unsubscribedConfirmation.requestKey)
            .map((response) => {
                return fetchUnsubscribedConfirmationSuccess(response.unsubscribedConfirmation!);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchUnsubscribedConfirmationFailure(error));
            })
    );
};
