import type { RouteResource } from 'react-resource-router';
import { Login } from 'view/login';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { anonymousScreenLayout, PORTAL_ROUTE_PREFIX, LOGIN_ROUTE } from '../common/constants';

const resources: RouteResource[] = [];

const portalLogin = {
    resources,
    name: 'portal-login',
    path: `${PORTAL_ROUTE_PREFIX}/user/login`,
    exact: true,
    component: Login,
};

const login = {
    resources,
    name: 'login',
    path: LOGIN_ROUTE,
    exact: true,
    component: Login,
};

const screenName = ScreenName.LOGIN;

const layoutProps = anonymousScreenLayout;

export const loginRoute = createRouteWithLayout(login, screenName, layoutProps);
export const portalLoginRoute = createRouteWithLayout(portalLogin, screenName, layoutProps);
