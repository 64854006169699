import { defineMessages } from 'react-intl-next';

export default defineMessages({
    contactUs: {
        id: 'csm-help-center.select-form-heading',
        defaultMessage: 'Contact us',
        description: 'Heading for the contact us form in the CSM help center',
    },
    contactUsDescription: {
        id: 'csm-help-center.select-form-description',
        defaultMessage: 'Welcome! You can raise a request for the team using the options provided.',
        description: 'Description for the contact us form in the CSM help center',
    },
    selectPlaceholder: {
        id: 'csm-help-center.select-placeholder',
        defaultMessage: 'Select an option',
        description: 'Placeholder for the request type select',
    },
    selectLabel: {
        id: 'csm-help-center.select-label',
        defaultMessage: 'What do you need help with?',
        description: 'Label for the request type select',
    },
    errorHeading: {
        id: 'csm-help-center.error-heading',
        defaultMessage: 'An error occurred',
        description: 'Heading for the error message when portal or portal id does not exist',
    },
    errorDescription: {
        id: 'csm-help-center.error-description',
        defaultMessage: "We couldn't load the contact form. Refresh the page and try again.",
        description: 'Description for the error message when portal or portal id does not exist',
    },
});
