import type { UpdateEnvAction, UpdateXsrfAction } from 'state/actions/env';
import { UPDATE_ENV, UPDATE_XSRF } from 'state/actions/env';
import { getEnv } from '@atlassian/help-center-common-util/env';
import type { Env } from '@atlassian/help-center-common-util/env';

export interface EnvState {
    headless: boolean;
    wacLink: string;
    xsrfToken?: string;
    baseUrl: string;
    serviceDeskVersion: string;
    mediaApiAppClientId?: string;
    mediaApiExternalUrl?: string;
    contactLink?: string;
    featureFlags: {
        [key: string]: boolean;
    };
    anonymousEnabled: boolean;
    fabricEmojiUrl?: string;
    jiraDisplayUrl: string;
    helpCenterType: string;
    featuredValues?: {
        [key: string]: boolean;
    };
    hoistedProjectKey?: string;
}

const getStateFromEnvObject = (envObject: Env) => ({
    headless: envObject.headless,
    wacLink: envObject.wacLink,
    xsrfToken: envObject.xsrfToken,
    baseUrl: envObject.baseUrl,
    mediaApiAppClientId: envObject.mediaApiAppClientId,
    mediaApiExternalUrl: envObject.mediaApiExternalUrl,
    serviceDeskVersion: envObject.serviceDeskVersion,
    featureFlags: envObject.featureFlags,
    contactLink: envObject.contactLink,
    anonymousEnabled: envObject.anonymousEnabled,
    fabricEmojiUrl: envObject.fabricEmojiUrl,
    jiraDisplayUrl: envObject.jiraDisplayUrl,
    helpCenterType: envObject.helpCenterType,
    featuredValues: envObject.featuredValues,
    hoistedProjectKey: envObject.hoistedProjectKey,
});

const defaultState: EnvState = getStateFromEnvObject(getEnv());

type Action = UpdateEnvAction | UpdateXsrfAction;

export default function reducer(state: EnvState = defaultState, action: Action) {
    switch (action.type) {
        case UPDATE_ENV: {
            const newState = getStateFromEnvObject(action.payload);
            return { ...state, ...newState };
        }
        case UPDATE_XSRF:
            return { ...state, xsrfToken: action.payload };
        default:
            return state;
    }
}
