import { post } from 'epics/ajax';
import type { Event } from '@atlassian/help-center-common-util/analytics/types';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const fireLegacyEvent = (event: Event, context = contextPath): Promise<void> => {
    const uri = `${context}/rest/analytics/1.0/publish/bulk`;
    const body = [event];
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(uri, body, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .toPromise()
    );
};
