import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { layer } from 'view/styles/z-index';
import type { GlyphProps } from '@atlaskit/icon/types';
import Tooltip from '@atlaskit/tooltip';
import { Button } from '@atlassian/help-center-common-component/analytics/button';

export interface HighlighterProps {
    isHighlighted?: boolean;
    highlightHandler: () => void;
    actionSubjectId: string;
    toolTip: {
        highlightedMessage: string;
        unHighlightedMessage: string;
    };
    ariaLabel: {
        highlightedLabel: string;
        unHighlightedLabel: string;
    };
    icon: {
        HighlightedIcon: React.ComponentClass<GlyphProps>;
        UnHighlightedIcon: React.ComponentClass<GlyphProps>;
    };
    color?: {
        highlightedColor?: string;
        unHighlightedColor?: string;
    };
}

export const Highlighter: React.FC<HighlighterProps> = ({
    isHighlighted,
    highlightHandler,
    toolTip,
    ariaLabel,
    actionSubjectId,
    icon,
    color,
}) => {
    const toolTipMessage = isHighlighted ? toolTip.highlightedMessage : toolTip.unHighlightedMessage;
    const ariaLabelContent = isHighlighted ? ariaLabel.highlightedLabel : ariaLabel.unHighlightedLabel;
    const { HighlightedIcon, UnHighlightedIcon } = icon;

    return (
        <HighlighterButtonContainer>
            <Tooltip content={toolTipMessage} position="top" aria-hidden="true">
                <Button
                    type="button"
                    appearance="subtle"
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    className="project-card-action-button"
                    onClick={highlightHandler}
                    actionSubjectId={actionSubjectId}
                    iconBefore={
                        isHighlighted ? (
                            <HighlightedIcon label={ariaLabelContent} primaryColor={color?.highlightedColor} />
                        ) : (
                            <UnHighlightedIcon label={ariaLabelContent} primaryColor={color?.unHighlightedColor} />
                        )
                    }
                ></Button>
            </Tooltip>
        </HighlighterButtonContainer>
    );
};

export default React.memo(Highlighter);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HighlighterButtonContainer = styled.div`
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: ${layer};
`;
