// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { css } from 'styled-components';
import { fontSize, codeFontFamily as codeFontFamilyProvider, typography } from '@atlaskit/theme';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const small = css`
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    font-size: 12px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const regular = css`
    font-size: ${fontSize()}px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const medium = css`
    font-size: 16px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const mediumLarge = css`
    font-size: 18px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const xsmall = css`
    font-size: 11px;
`;

export const codeFontFamily = codeFontFamilyProvider();

export const h800 = typography.h800;
export const h700 = typography.h700;
export const h600 = typography.h600;
export const h500 = typography.h500;
export const h400 = typography.h400;
export const h300 = typography.h300;
export const h200 = typography.h200;
export const h100 = typography.h100;
