import * as React from 'react';
import { styled as styled2 } from '@compiled/react';
import { withFadeIn } from '@atlassian/help-center-common-component/with-fade-in';
import { MainContentSkipLinkWrapper } from '../../common/skip-links';
import type { PaperAppearance } from './styled';
import { Content, PaperBackground, StyledFooter, paperTransitionTime, NoteBackground, NoteContent } from './styled';

interface SkipToMainContentProps {
    children: React.ReactNode;
}

const SkipToMainContent = ({ children }: SkipToMainContentProps) => (
    <MainContentSkipLinkWrapper>
        <MainContentSkipLink>{children}</MainContentSkipLink>
    </MainContentSkipLinkWrapper>
);

interface Props {
    children: React.ReactNode;
    // Used as a key to determine whether or not we should add transitions between pages
    pageKey?: string | undefined;
    appearance?: PaperAppearance;
    isCSM?: boolean;
}

// We want to delay fade in until after the paper transition is complete
const FadeContent = withFadeIn({
    fadeInTime: paperTransitionTime,
    delay: paperTransitionTime,
})(Content);

class Paper extends React.Component<Props> {
    render() {
        const { children, pageKey, isCSM } = this.props;
        const hide = this.props.appearance === 'none';
        const appearance = this.props.appearance || 'normal';

        if (appearance === 'note') {
            return (
                <>
                    {/* render the paper background off-screen so that when we transition to a page with paper it displays correctly */}
                    <PaperBackground appearance="none" hide />
                    <NoteContent appearance={appearance} hide={hide}>
                        <NoteBackground>{children}</NoteBackground>
                        <StyledFooter />
                    </NoteContent>
                </>
            );
        }

        return (
            <>
                <PaperBackground appearance={appearance} hide={hide} />
                <FadeContent appearance={appearance} hide={hide} key={pageKey}>
                    <SkipToMainContent>
                        {children}
                        <StyledFooter isCSM={isCSM} />
                    </SkipToMainContent>
                </FadeContent>
            </>
        );
    }
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const MainContentSkipLink = styled2.div({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: '100%',
});

export default Paper;
