/**
 * @generated SignedSource<<91de8744f0acd05f63fbcf59ea0b365e>>
 * @relayHash 4e115c619e2ee9c99f51b6134b9860d8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 942f3a67d134a1390280a74c9533c368d9c4fa7cd34444ab0041ecb1acae9df8

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchResultArticlesAndPortalsQuery$variables = {
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cloudId: string;
  helpCenterAri?: string | null | undefined;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
  resultLimit?: number | null | undefined;
};
export type searchResultArticlesAndPortalsQuery$data = {
  readonly helpObjectStore: {
    readonly __typename: "HelpObjectStoreQueryApi";
    readonly " $fragmentSpreads": FragmentRefs<"articlesFragment" | "portalsFragment">;
  };
};
export type searchResultArticlesAndPortalsQuery = {
  response: searchResultArticlesAndPortalsQuery$data;
  variables: searchResultArticlesAndPortalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resultLimit"
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "Variable",
  "name": "helpCenterAri",
  "variableName": "helpCenterAri"
},
v10 = {
  "kind": "Variable",
  "name": "portalIds",
  "variableName": "portalIds"
},
v11 = {
  "kind": "Variable",
  "name": "queryTerm",
  "variableName": "queryTerm"
},
v12 = {
  "kind": "Variable",
  "name": "resultLimit",
  "variableName": "resultLimit"
},
v13 = {
  "kind": "ScalarField",
  "name": "id"
},
v14 = {
  "kind": "ScalarField",
  "name": "isExternal"
},
v15 = {
  "kind": "ScalarField",
  "name": "searchBackend"
},
v16 = {
  "kind": "ScalarField",
  "name": "title"
},
v17 = {
  "kind": "ScalarField",
  "name": "description"
},
v18 = {
  "kind": "ScalarField",
  "name": "displayLink"
},
v19 = {
  "kind": "ScalarField",
  "name": "searchAlgorithm"
},
v20 = {
  "kind": "ScalarField",
  "name": "entityType"
},
v21 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "HelpObjectStoreQueryError"
},
v23 = {
  "kind": "InlineFragment",
  "selections": [
    (v13/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "searchResultArticlesAndPortalsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v7/*: any*/),
          "concreteType": "HelpObjectStoreQueryApi",
          "kind": "LinkedField",
          "name": "helpObjectStore",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "articlesFragment"
            },
            {
              "kind": "FragmentSpread",
              "name": "portalsFragment"
            }
          ]
        },
        "action": "THROW",
        "path": "helpObjectStore"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchResultArticlesAndPortalsQuery",
    "selections": [
      {
        "args": (v7/*: any*/),
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": "articles",
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "categoryIds",
                    "variableName": "categoryIds"
                  },
                  (v6/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "ARTICLE"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "containerDisplayName"
                  }
                ],
                "type": "HelpObjectStoreSearchResult"
              },
              (v22/*: any*/),
              (v23/*: any*/)
            ]
          },
          {
            "alias": "portals",
            "args": [
              {
                "fields": [
                  (v6/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "PORTAL"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "type": "HelpObjectStoreSearchResult"
              },
              (v22/*: any*/),
              (v23/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "942f3a67d134a1390280a74c9533c368d9c4fa7cd34444ab0041ecb1acae9df8",
    "metadata": {},
    "name": "searchResultArticlesAndPortalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dcb6be9a06f88e55999bb3fc69693157";

export default node;
