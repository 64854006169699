/**
 * @generated SignedSource<<53a375cc91280b2207d7cc1aa3d59cac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ClientRequest, ClientQuery } from 'relay-runtime';
export type omnichannelMetaQuery$variables = Record<PropertyKey, never>;
export type omnichannelMetaQuery$data = {
  readonly jsmChatConversationMeta: {
    readonly aiAnswerStatus: string | null | undefined;
    readonly connectionId: string | null | undefined;
    readonly conversationContextAri: string | null | undefined;
    readonly conversationId: string | null | undefined;
    readonly disableInput: boolean | null | undefined;
    readonly draftMessage: string | null | undefined;
    readonly isConversationClosed: boolean | null | undefined;
    readonly isConversationExpired: boolean | null | undefined;
    readonly isReplyPending: boolean | null | undefined;
    readonly subscriptionId: string | null | undefined;
    readonly workspaceAri: string | null | undefined;
  } | null | undefined;
};
export type omnichannelMetaQuery = {
  response: omnichannelMetaQuery$data;
  variables: omnichannelMetaQuery$variables;
};

const node: ClientRequest = (function(){
var v0 = [
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "concreteType": "JsmChatConversationMeta",
        "kind": "LinkedField",
        "name": "jsmChatConversationMeta",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "conversationId"
          },
          {
            "kind": "ScalarField",
            "name": "conversationContextAri"
          },
          {
            "kind": "ScalarField",
            "name": "workspaceAri"
          },
          {
            "kind": "ScalarField",
            "name": "connectionId"
          },
          {
            "kind": "ScalarField",
            "name": "isReplyPending"
          },
          {
            "kind": "ScalarField",
            "name": "isConversationExpired"
          },
          {
            "kind": "ScalarField",
            "name": "aiAnswerStatus"
          },
          {
            "kind": "ScalarField",
            "name": "isConversationClosed"
          },
          {
            "kind": "ScalarField",
            "name": "draftMessage"
          },
          {
            "kind": "ScalarField",
            "name": "disableInput"
          },
          {
            "kind": "ScalarField",
            "name": "subscriptionId"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "omnichannelMetaQuery",
    "selections": (v0/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "omnichannelMetaQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9615b9f4fbe1d3c4564d22bfc3ed4951",
    "id": null,
    "metadata": {},
    "name": "omnichannelMetaQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "564f38d88e2b183c21198b3358664a2d";

export default node;
