import { buildModelsActionCreator } from 'epics/model/actions';
import type { ErrorResponse } from 'epics/model/types';
import type { AiAnswerFeedbackResponse } from 'rest/models/types';

export const FETCH_AI_ANSWER_FEEDBACK_MODEL = 'epic.model.FETCH_AI_ANSWER_FEEDBACK_MODEL';
export const FETCH_AI_ANSWER_FEEDBACK_SUCCESS = 'state.persisted.ai.answer.feedback.FETCH_AI_ANSWER_FEEDBACK_SUCCESS';
export const FETCH_AI_ANSWER_FEEDBACK_FAILURE = 'state.persisted.ai.answer.feedback.FETCH_AI_ANSWER_FEEDBACK_FAILURE';

export type FetchAiAnswerFeedbackAction = ReturnType<typeof fetchAiAnswerFeedbackModel>;

export const fetchAiAnswerFeedbackModel = buildModelsActionCreator(FETCH_AI_ANSWER_FEEDBACK_MODEL, 'aianswer');

export interface FetchAiAnswerFeedbackSuccess {
    type: typeof FETCH_AI_ANSWER_FEEDBACK_SUCCESS;
    payload?: AiAnswerFeedbackResponse;
}

export const fetchAiAnswerFeedbackSuccess = (
    aiAnswerFeedbackResponse?: AiAnswerFeedbackResponse
): FetchAiAnswerFeedbackSuccess => ({
    type: FETCH_AI_ANSWER_FEEDBACK_SUCCESS,
    payload: aiAnswerFeedbackResponse,
});

export interface FetchAiAnswerFeedbackFailure {
    type: typeof FETCH_AI_ANSWER_FEEDBACK_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchAiAnswerFeedbackFailure = (error: ErrorResponse): FetchAiAnswerFeedbackFailure => ({
    type: FETCH_AI_ANSWER_FEEDBACK_FAILURE,
    payload: {
        error,
    },
});
