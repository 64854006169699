import { isWrmOptimizationsEnabled } from 'feature-flags';
import { fireLegacyEvent } from 'rest/legacy-event';
import { getEventQueue } from '@atlassian/help-center-common-util/ajs';
import { sessionId } from '@atlassian/help-center-common-util/analytics/session-id';
import type { Event, EventProperties } from '@atlassian/help-center-common-util/analytics/types';
import { isWhiteListEvent, sanitizeEventProperties } from '@atlassian/help-center-common-util/analytics/whitelist';
import { getPluginVersion, getCustomerPortalFrontendBuildKey } from '@atlassian/help-center-common-util/build-info';

const sanitizeAnalyticEvent = (eventName: string, eventProperties: EventProperties): Event => {
    // for any new events which have been added to portal frontend whitelist
    // we will add a prefix to the eventname and sanitize its properties
    if (isWhiteListEvent(eventName)) {
        return {
            name: `jsd.portal.frontend.${eventName}`,
            properties: sanitizeEventProperties(eventName, eventProperties),
        };
    }

    // if events not in whitelist, we will let cloud analytic plugin handle it instead
    return {
        name: eventName,
        properties: eventProperties,
    };
};

/**
 * This is legacy tracking code. We should not use this anymore as we should move over to Gas V3 pipeline.
 *
 * @param name Event name
 * @param data (optional) Key-value object with additional properties for storing in analytics
 * @deprecated Use tracking functions from util/analytics instead
 */
export const trackLegacyEvent = (name: string, data: object) => {
    const props: EventProperties = { ...data, sessionId };

    props.sdVersion = getPluginVersion() || props.sdVersion || 'unknown';
    props.portalFrontendBuildKey = getCustomerPortalFrontendBuildKey() || props.portalFrontendBuildKey || 'unknown';

    const safeEventObject = sanitizeAnalyticEvent(name, props);
    // TODO: replace with fireLegacyEvent(safeEventObject) from src/rest/legacy-event
    // the reason now here is to cater for the https://jdog.jira-dev.com/browse/JSDKB-1468 task which
    // still consumes the GASv2 events, this following code should be removed once the above task is fixed
    if (isWrmOptimizationsEnabled()) {
        // Disabling existing violations, should be fixed when revisited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fireLegacyEvent(safeEventObject);
        return;
    }
    const eventQueue = getEventQueue();
    eventQueue && eventQueue.push(safeEventObject);
};
