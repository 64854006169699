import type { PaperAppearance } from 'view/layout/paper';
import { RequestList } from 'view/request-list';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const route = {
    name: 'requests-list',
    path: '/user/requests',
    exact: true,
    component: RequestList,
};

const screeName = ScreenName.REQUEST_LIST;

const paperAppearance: PaperAppearance = 'xxwide';

const layoutProps = {
    paperAppearance,
    fadeInSearchModal: true,
};

export const requestsListRoute = createRouteWithLayout(route, screeName, layoutProps);
