// eslint-disable-next-line no-shadow
export enum OUTCOME {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export interface IThemingContainerProps {
    children: React.ReactNode;
    themeColor: string;
}
