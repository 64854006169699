import type { StoreActionApi } from 'react-sweet-state';

import type { LayoutPath, MediaConfig, State } from '../types';

export const clearAllSelections =
    () =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            events: {
                hovered: undefined,
                selected: undefined,
                sectionPickerId: undefined,
            },
        });
    };

export const markSelected =
    (dataId: string, path: LayoutPath[]) =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const currentState = getState();

        setState({
            ...currentState,
            events: {
                ...currentState.events,
                sectionPickerId: undefined,
                selected: { dataId, path },
            },
        });
    };

export const markHovered =
    (dataId: string | undefined) =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const currentState = getState();

        setState({
            ...currentState,
            events: {
                ...currentState.events,
                hovered: dataId,
            },
        });
    };

export const markDragged =
    (dataId: string | undefined) =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const currentState = getState();

        setState({
            ...currentState,
            events: {
                ...currentState.events,
                sectionPickerId: undefined,
                dragged: dataId,
            },
        });
    };

export const markSectionDialogOpen =
    (sectionId: string | undefined) =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const currentState = getState();

        setState({
            ...currentState,
            events: {
                hovered: undefined,
                selected: undefined,
                sectionPickerId: sectionId,
            },
        });
    };

export const markIsPublishing =
    (isPublishing: boolean) =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            isPublishing,
        });
    };

export const markIsPublishDisabled =
    (isPublishDisabled: boolean) =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            isPublishDisabled,
        });
    };

export const markIsDirty =
    (isDirty: boolean) =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            isDirty,
        });
    };

export const markBrandingPreviewMode =
    (isPageInBrandingPreviewMode: boolean) =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();
        setState({
            ...currentState,
            isPageInBrandingPreviewMode,
        });
    };

export const setLayoutId =
    (sectionId: string, elementId: string, typename: string) =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();

        if (typename === 'HelpLayoutForgeElement' || typename === 'HelpLayoutConnectElement') {
            const updatedLayoutIds = { ...currentState.layoutIds };
            updatedLayoutIds[typename] = [...(updatedLayoutIds[typename] || []), { sectionId, elementId }];
            setState({
                ...currentState,
                layoutIds: updatedLayoutIds,
            });
            return;
        }
        setState({
            ...currentState,
            layoutIds: {
                ...currentState.layoutIds,
                [typename]: { sectionId, elementId },
            },
        });
    };

export const togglePreviewMode =
    () =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();
        const currentPreviewMode = currentState.isPageInPreviewMode;
        setState({
            ...currentState,
            isPageInPreviewMode: !currentPreviewMode,
        });
    };

export const setDeletedModulesCount =
    (deletedModulesCount: number) =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();
        setState({
            ...currentState,
            deletedModulesCount,
        });
    };

export const setReorderedModulesCount =
    (reorderedModulesCount: number) =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();
        setState({
            ...currentState,
            reorderedModulesCount,
        });
    };

export const setMediaConfig =
    (mediaConfig: MediaConfig) =>
    ({ setState, getState }: StoreActionApi<State>) => {
        const currentState = getState();
        setState({
            ...currentState,
            mediaConfig,
        });
    };

export const actions = {
    clearAllSelections,
    markSelected,
    markHovered,
    markDragged,
    markSectionDialogOpen,
    markIsPublishing,
    markIsPublishDisabled,
    markIsDirty,
    markBrandingPreviewMode,
    setLayoutId,
    togglePreviewMode,
    setDeletedModulesCount,
    setReorderedModulesCount,
    setMediaConfig,
};

export type Actions = typeof actions;
