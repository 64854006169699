import type { InvitedSignUp, InvitedSignUpFailure, InvitedSignUpSuccess } from 'state/actions/invited-sign-up';
import { INVITED_SIGN_UP, INVITED_SIGN_UP_FAILURE } from 'state/actions/invited-sign-up';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export interface InvitedSignUpSubmitState {
    isSubmitting: boolean;
    error?: DefaultError;
}

export type HandledActions = InvitedSignUp | InvitedSignUpFailure | InvitedSignUpSuccess;

export const defaultState: InvitedSignUpSubmitState = {
    isSubmitting: false,
    error: undefined,
};

export function invitedSignUpUiReducer(
    state: InvitedSignUpSubmitState = defaultState,
    action: HandledActions
): InvitedSignUpSubmitState {
    switch (action.type) {
        case INVITED_SIGN_UP:
            return handleInvitedSignUp(state);
        case INVITED_SIGN_UP_FAILURE:
            return handleInvitedSignUpFailure(state, action);
        default:
            return state;
    }
}

const handleInvitedSignUp = (state: InvitedSignUpSubmitState): InvitedSignUpSubmitState => ({
    ...state,
    isSubmitting: true,
    error: undefined,
});

const handleInvitedSignUpFailure = (
    state: InvitedSignUpSubmitState,
    action: InvitedSignUpFailure
): InvitedSignUpSubmitState => ({
    ...state,
    isSubmitting: false,
    error: action.payload.error,
});
