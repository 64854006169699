import { gridSize } from '@atlaskit/theme';

export const unitless = gridSize();
export const px = `${gridSize()}px`;
export const multiple = (multiplier: number) => ({
    px: `${multiplier * gridSize()}px`,
    unitless: multiplier * gridSize(),
    multiple: (additionalMultiplier: number) => multiple(multiplier * additionalMultiplier),
});
// height of a single lined paragraph tag
export const SINGLE_LINE_PARAGRAPH_HEIGHT = 20;
