import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    getBlockedAppsBannerStatePortal,
    getBlockedAppsBannerStateCreateRequest,
    getBlockedAppsBannerStateRequestView,
} from 'state/selectors/blocked-apps-banner';
import { getAccountId } from 'state/selectors/user';
import BlockedAppsBannerComponent from 'view/blocked-apps-banner/blocked-apps-banner';
import { withAnalyticsContext } from '@atlaskit/analytics-next';

const blockedAppsBannerSelector = createSelector(
    getBlockedAppsBannerStatePortal,
    getBlockedAppsBannerStateRequestView,
    getBlockedAppsBannerStateCreateRequest,
    getAccountId,
    (portal, viewRequest, createRequest, accountId) => {
        return {
            accountId,
            blockedAppsBannerState: {
                portal,
                view_request: viewRequest,
                create_request: createRequest,
            },
        };
    }
);

export const BlockedAppsBanner = connect(blockedAppsBannerSelector)(
    withAnalyticsContext({ componentName: 'blockedAppBanner' })(BlockedAppsBannerComponent)
);
