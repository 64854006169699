import * as React from 'react';
import { useIntl } from 'react-intl-next';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Button } from '@atlassian/help-center-common-component/analytics';
import messages from './messages';

interface BackButtonProps {
    text?: string;
    actionSubjectId: string;
    onClick: () => void;
}

const BackButton = (props: BackButtonProps) => {
    const { formatMessage } = useIntl();
    const { onClick, actionSubjectId, text } = props;
    const backButtonText = text !== null && text !== undefined ? text : formatMessage(messages.backButtonText);

    return (
        <Button
            appearance="subtle"
            actionSubjectId={actionSubjectId}
            onClick={onClick}
            iconBefore={<ArrowLeftCircleIcon label="" size="small" primaryColor={token('color.icon', colors.N500)} />}
            data-test-id="back-button"
        >
            {backButtonText}
        </Button>
    );
};

export default BackButton;
