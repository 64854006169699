import type { LanguageResponse } from 'rest/models';

// Make sure that the locale keys are all consistent
export const ensureConsistentKey = (localeKey: string): string => localeKey.replace('_', '-');

const ensureConsistentResponse = (response: LanguageResponse) => ({
    key: ensureConsistentKey(response.key),
    originalKey: response.key,
    displayName: response.displayName,
});

export const ensureConsistentResponses = (responses: LanguageResponse[]) =>
    responses.map((response) => ensureConsistentResponse(response));
