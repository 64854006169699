import React from 'react';

import { isUiModificationsSupportEnabled } from '@helpCenter/feature-flags';
import { di } from 'react-magnetic-di';
import type { ContainerComponent } from 'react-sweet-state';

import { REQUEST_CREATE_VIEW_TYPE } from '../../common/constants';
import { FakeUIMStoreContainer } from '../../common/fake-uim/store';
import { RequestCreateViewConfiguration } from '../../common/utils/configuration';

// TODO: Replace with real BaseUiModificationsContainer once core migration ready
export const BaseUiModificationsContainer: ContainerComponent<{
    scope: string;
    viewType: string;
    viewConfiguration: new () => RequestCreateViewConfiguration;
}> = ({ children }) => {
    return <FakeUIMStoreContainer>{children}</FakeUIMStoreContainer>;
};

export const UiModificationsContainer: ContainerComponent<unknown> = ({ children }) => {
    di(BaseUiModificationsContainer, isUiModificationsSupportEnabled);

    return isUiModificationsSupportEnabled() ? (
        <BaseUiModificationsContainer
            scope={REQUEST_CREATE_VIEW_TYPE}
            viewType={REQUEST_CREATE_VIEW_TYPE}
            viewConfiguration={RequestCreateViewConfiguration}
        >
            {children}
        </BaseUiModificationsContainer>
    ) : (
        children
    );
};
