import React, { useCallback } from 'react';
import { isEmpty, memoize } from 'lodash';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { PaginatedFilter } from '@atlassian/help-center-common-component/paginated-filter';
import type { SelectValues, AsyncLoadOptions } from '@atlassian/paginated-picker';
import type { PickerProps } from './types';

export const doesMatch = (str: string, toMatch?: string) => {
    if (!str) return false;
    return toMatch ? str.toLowerCase().includes(toMatch.toLowerCase()) : true;
};

export const filterOptions = memoize(
    (options: SelectValues, searchText: string): SelectValues => {
        if (isEmpty(searchText)) {
            return options;
        }

        const filteredOptions = [];
        for (const option of options) {
            if (doesMatch(option.label, searchText)) {
                filteredOptions.push(option);
            }
        }
        return filteredOptions;
    },
    (...args) => JSON.stringify(args)
);

const Picker: React.FunctionComponent<PickerProps> = ({
    options,
    preSelectedOptions,
    onChange,
    fieldId,
    testId,
    fieldLabel,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState<SelectValues>(preSelectedOptions ?? []);

    const onPickerTriggerClick = React.useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const onOpen = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    const onClose = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    const onFilterChange = useCallback(
        (selectedOptions: SelectValues) => {
            onChange(selectedOptions);
            setSelectedValues(selectedOptions);
        },
        [onChange]
    );

    const onFilterClearValues = useCallback(() => {
        onChange([]);
        setSelectedValues([]);
    }, [onChange]);

    const onLoadOptions: AsyncLoadOptions = React.useCallback(
        (search, _enableFullList, _showFullList, _showMore, _lastOption, cb) => {
            const filteredOptions = filterOptions(options, search);
            cb({
                options: filteredOptions,
                totalCount: filteredOptions.length,
            });
        },
        [options]
    );

    return (
        <AnalyticsContext data={{ actionSubjectId: fieldId }}>
            <PaginatedFilter
                fieldId={fieldId}
                fieldLabel={fieldLabel}
                isOpen={isOpen}
                value={selectedValues}
                enablePagination={false}
                onOpen={onOpen}
                onClose={onClose}
                onChange={onFilterChange}
                onLoadOptions={onLoadOptions}
                onClearValues={onFilterClearValues}
                onPickerTriggerClick={onPickerTriggerClick}
                testId={testId}
            />
        </AnalyticsContext>
    );
};

export default Picker;
