import React, { useEffect, useMemo } from 'react';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { useIntl } from 'react-intl-next';
import { useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import AppProvider from '@atlaskit/app-provider';
import { LinkButton } from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import CheckMarkIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { Flex, Box, xcss, media, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import vector from '../assets/vector.svg';
import messages from './messages';

// eslint-disable-next-line no-shadow
export enum StepNumber {
    STEP1 = 0,
    STEP2 = 1,
    STEP3 = 2,
}

interface LearnByDoingProps {
    projectRedirectionLink?: string;
    screenName?: ScreenName;
}

interface Step {
    stepNumber: StepNumber;
    title: string;
    description: string;
}

const LearnByDoing = ({ projectRedirectionLink, screenName }: LearnByDoingProps) => {
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();

    const getStepNumber = () => {
        let step;
        if (screenName === ScreenName.PORTAL_HOME || screenName === ScreenName.REQUEST_GROUP) {
            step = StepNumber.STEP1;
        } else if (screenName === ScreenName.REQUEST_CREATE) {
            step = StepNumber.STEP2;
        } else if (screenName === ScreenName.REQUEST_DETAILS) {
            step = StepNumber.STEP3;
        } else {
            step = -1;
        }
        return step;
    };

    useEffect(() => {
        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'viewed',
            actionSubject: 'learnByDoing',
            attributes: {
                screenName,
            },
        }).fire();
    }, [createAnalyticsEvent, screenName]);

    const stepNumber = getStepNumber();
    const steps: Step[] = useMemo(
        () => [
            {
                stepNumber: StepNumber.STEP1,
                title: formatMessage(messages.chooseRequestTypeHeading),
                description: formatMessage(messages.chooseRequestTypeDesc),
            },
            {
                stepNumber: StepNumber.STEP2,
                title: formatMessage(messages.sendExampleRequestHeading),
                description: formatMessage(messages.sendExampleRequestDesc),
            },
            {
                stepNumber: StepNumber.STEP3,
                title: formatMessage(messages.requestCreatedHeading),
                description: formatMessage(messages.requestCreatedDesc),
            },
        ],
        [formatMessage]
    );

    return (
        <AppProvider>
            {stepNumber >= 0 && (
                <Flex xcss={LearnByDoingStyles} direction="column">
                    <Heading size="medium">{formatMessage(messages.learnByDoingDesc)}</Heading>
                    <Flex xcss={LearnByDoingFlowStyle}>
                        {steps.map((step, index) => {
                            return (
                                <Flex
                                    xcss={[
                                        LearnByDoingStepDefaultStyle,
                                        index === stepNumber && LearnByDoingStepActiveStyle,
                                        index < stepNumber && LearnByDoingStepDisableStyle,
                                    ]}
                                    key={`step-${index}`}
                                >
                                    <Flex
                                        xcss={[
                                            StepNumberContainerDefaultStyle,
                                            index === stepNumber && StepNumberContainerActiveStyle,
                                            index < stepNumber && StepNumberContainerDisableStyle,
                                        ]}
                                    >
                                        {index >= stepNumber ? (
                                            <Flex
                                                alignItems="center"
                                                xcss={[
                                                    headingContainerStepNumberDefaultStyles,
                                                    index === stepNumber && headingContainerStepNumberActiveStyles,
                                                ]}
                                            >
                                                <Box css={stepNumberStyles}>{step.stepNumber + 1}</Box>
                                            </Flex>
                                        ) : (
                                            <CheckMarkIcon
                                                label="success"
                                                color={token('color.icon.accent.green', '#189864')}
                                                spacing="spacious"
                                                LEGACY_size="medium"
                                                LEGACY_primaryColor={token('color.icon.accent.green', '#189864')}
                                            />
                                        )}
                                    </Flex>
                                    <Flex xcss={ContentStyle} direction="column">
                                        <Heading size="small">{step.title}</Heading>
                                        <Box xcss={descriptionStyles}>{step.description}</Box>
                                        {index === steps.length - 1 && (
                                            <Flex xcss={QueuesRedirectButtonStyles}>
                                                <LinkButton
                                                    href={projectRedirectionLink ?? '#'}
                                                    appearance="discovery"
                                                    isDisabled={!projectRedirectionLink}
                                                    testId="project-redirect-link"
                                                    analyticsContext={{
                                                        actionSubjectId: 'learnByDoingRedirect',
                                                    }}
                                                >
                                                    <Text
                                                        weight="medium"
                                                        color={
                                                            !projectRedirectionLink
                                                                ? 'color.text.disabled'
                                                                : 'color.text.inverse'
                                                        }
                                                    >
                                                        {formatMessage(messages.queuesRedirectLabel)}
                                                    </Text>
                                                </LinkButton>
                                            </Flex>
                                        )}
                                    </Flex>
                                    <Flex xcss={nextStepImageStyle}>
                                        {index !== steps.length - 1 && <img src={vector} alt=""></img>}
                                    </Flex>
                                </Flex>
                            );
                        })}
                    </Flex>
                </Flex>
            )}
        </AppProvider>
    );
};

const LearnByDoingWithErrorBoundary = (props: LearnByDoingProps) => {
    return (
        <ErrorBoundary packageName="learnByDoing" id="create-request" renderError={() => null}>
            <LearnByDoing {...props} />
        </ErrorBoundary>
    );
};

export default withAnalyticsContext({ component: 'LearnByDoing' })(LearnByDoingWithErrorBoundary);

const LearnByDoingStyles = xcss({
    display: 'none',
    width: '100%',
    gap: 'space.200',
    paddingBlock: 'space.300',
    paddingInline: 'space.300',
    backgroundColor: 'color.background.discovery',
    borderRadius: 'border.radius.100',
    [media.above.md]: {
        display: 'flex',
    },
    [media.above.lg]: {
        paddingInline: 'space.800',
    },
});

const LearnByDoingFlowStyle = xcss({
    width: '100%',
    gap: 'space.500',
    [media.above.lg]: {
        gap: 'space.600',
    },
});

const LearnByDoingStepDefaultStyle = xcss({
    gap: 'space.150',
    padding: 'space.200',
    backgroundColor: 'color.background.input',
    borderRadius: 'border.radius.100',
    border: '1px solid',
    borderColor: 'color.border',
    minHeight: '136px',
    position: 'relative',
    flex: '1 1 0px',
    [media.above.lg]: {
        gap: 'space.200',
    },
});

const LearnByDoingStepActiveStyle = xcss({
    backgroundColor: 'color.background.input',
    border: '2px solid',
    borderColor: 'color.border.discovery',
});

const LearnByDoingStepDisableStyle = xcss({
    backgroundColor: 'color.background.discovery',
    border: `1px solid`,
    borderColor: 'color.border',
});

const StepNumberContainerDefaultStyle = xcss({
    paddingBlock: 'space.150',
    paddingInline: 'space.200',
    width: '40px',
    height: '40px',
    backgroundColor: 'color.background.discovery',
    borderRadius: 'border.radius.circle',
    gap: 'space.100',
    alignItems: 'center',
    justifyContent: 'center',
});

const StepNumberContainerActiveStyle = xcss({
    backgroundColor: 'color.background.discovery.bold',
});

const StepNumberContainerDisableStyle = xcss({
    backgroundColor: 'color.background.success',
});

const headingContainerStepNumberDefaultStyles = xcss({
    height: token('space.250', '20px'),
    color: 'color.text.discovery',
});

const headingContainerStepNumberActiveStyles = xcss({
    color: 'color.text.inverse',
});

const stepNumberStyles = xcss({
    font: 'font.heading.small',
});

const nextStepImageStyle = xcss({
    zIndex: 'card',
    position: 'absolute',
    top: 'space.300',
    right: '-52px',
    [media.above.lg]: {
        right: '-60px',
    },
});

const ContentStyle = xcss({
    width: '100%',
    gap: 'space.050',
});

const descriptionStyles = xcss({
    color: 'color.text.subtle',
    font: 'font.body',
});

const QueuesRedirectButtonStyles = xcss({
    width: 'fit-content',
    marginTop: 'space.050',
});
