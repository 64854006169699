import { defineMessages } from 'react-intl-next';

export default defineMessages({
    projectsListLinkButtonText: {
        defaultMessage: 'Link',
        description: 'Projects list link button text',
        id: 'projects.link.unlink.button.link.button.text',
    },
    projectsListUnlinkButtonText: {
        defaultMessage: 'Unlink',
        description: 'Projects list unlink button text',
        id: 'projects.link.unlink.button.unlink.button.text',
    },
    unlinkSuccessTitle: {
        defaultMessage: '’{projectName}’ is no longer linked to this help center',
        description: 'Unlink project flag success title',
        id: 'projects.link.unlink.button.unlink.flag.success.title',
    },
    linkSuccessTitle: {
        defaultMessage: '’{projectName}’ is now linked to this help center',
        description: 'Link project flag success title',
        id: 'projects.link.unlink.button.link.flag.success.title',
    },
    bulkLinkSuccessTitle: {
        defaultMessage: '{number} projects are now linked to this help center',
        description: 'Bulk link project flag success title',
        id: 'projects.link.unlink.button.bulk.link.flag.success.title',
    },
    bulkLinkErrorTitle: {
        defaultMessage: 'We couldn’t link the selected projects',
        description: 'Bulk link project flag error title',
        id: 'projects.link.unlink.button.bulk.link.flag.error.title',
    },
    bulkUnlinkSuccessTitle: {
        defaultMessage: '{number} projects have been unlinked from this help center',
        description: 'Bulk unlink projects flag success title',
        id: 'projects.link.unlink.button.bulk.unlink.flag.success.title',
    },
    bulkUnlinkErrorTitle: {
        defaultMessage: 'We couldn’t unlink the selected projects',
        description: 'Bulk unlink projects flag error title',
        id: 'projects.link.unlink.button.bulk.unlink.flag.error.title',
    },
    unlinkErrorTitle: {
        defaultMessage: 'We couldn’t unlink the projects ‘{projectName}’',
        description: 'Unlink project flag error title',
        id: 'projects.link.unlink.button.unlink.flag.error.title',
    },
    linkErrorTitle: {
        defaultMessage: 'We couldn’t link the project ’{projectName}’',
        description: 'Link project flag error title',
        id: 'projects.link.unlink.button.link.flag.error.title',
    },
    genericErrorDescription: {
        defaultMessage: 'Refresh the page and try again.',
        description: 'Unlink project flag error description',
        id: 'projects.link.unlink.button.flag.generic.error.description',
    },
    lastProjectUnlink: {
        defaultMessage:
            'Your help center needs at least one linked project to ensure that your customers can access relevant information and resources.',
        description: 'Last project unlink project dialog message',
        id: 'projects.link.unlink.button.unlink.last.project.dialog.content',
    },
});
