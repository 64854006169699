import * as React from 'react';

import AtlassianLogo from 'assets/images/atlassian-icon.png';
import { isCustomThemingEnabled, shouldAddUFOHoldsToRequestDetailPage } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { graphql, useLazyLoadQuery } from 'react-relay';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { isHelpCentersManagement } from '@atlassian/help-center-common-util/advanced-help-center';
import { getObjectURLFromMediaURLWithCaching } from '@atlassian/help-center-common-util/media-services';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import { LogoStateless } from '../logo-stateless';
import type { logoQuery } from './__generated__/logoQuery.graphql';

export interface LogoProps {
    url?: string | null;
    name: string;
    topBarTextColor?: string;
}

export const Logo: React.FC<LogoProps> = ({ url, name, topBarTextColor }) => {
    di(isHelpCentersManagement);
    const isFetchingData = isCustomThemingEnabled();
    const [stateUrl, setStateUrl] = React.useState<string>('');
    const [shouldHoldUFO, setShouldHoldUFO] = React.useState(true);
    const { helpCenter } = useLazyLoadQuery<logoQuery>(
        graphql`
            query logoQuery($cloudId: ID!, $helpCenterAri: ID!, $skip: Boolean!) {
                helpCenter(cloudId: $cloudId) @skip(if: $skip) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            name {
                                default
                            }
                            helpCenterBranding @optIn(to: ["HelpCenterBrandingTest"]) {
                                ... on HelpCenterBranding {
                                    logo {
                                        ... on HelpCenterLogo {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        {
            cloudId: getCloudId(),
            helpCenterAri: getHelpCenterAri(),
            skip: !isFetchingData,
        }
    );
    const advancedName = helpCenter?.helpCenterById?.name?.default || name;
    const advancedUrl = helpCenter?.helpCenterById?.helpCenterBranding?.logo?.url;

    React.useEffect(() => {
        if (!isFetchingData && url && !url.startsWith('blob:')) {
            if (shouldAddUFOHoldsToRequestDetailPage()) {
                getObjectURLFromMediaURLWithCaching(url)
                    .then(setStateUrl)
                    .catch(() => setStateUrl(''))
                    .finally(() => setShouldHoldUFO(false));
            } else {
                getObjectURLFromMediaURLWithCaching(url)
                    .then(setStateUrl)
                    .catch(() => setStateUrl(''));
            }
        } else {
            setStateUrl(url || '');
            if (shouldAddUFOHoldsToRequestDetailPage()) {
                setShouldHoldUFO(false);
            }
        }
    }, [url, isFetchingData]);

    const isHelpCentersManagementPage = isHelpCentersManagement();

    const getUrl = React.useCallback(() => {
        if (isFetchingData) {
            return advancedUrl;
        }
        if (isHelpCentersManagementPage) {
            return AtlassianLogo;
        }
        return stateUrl;
    }, [advancedUrl, isFetchingData, isHelpCentersManagementPage, stateUrl]);

    return (
        <>
            {shouldAddUFOHoldsToRequestDetailPage() && shouldHoldUFO && <UFOLoadHold name="help-center-common-logo" />}
            <LogoStateless url={getUrl()} name={advancedName} topBarTextColor={topBarTextColor} />
        </>
    );
};
