import { post } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

interface RequestBodyArgs {
    webResources?: string[];
    contexts?: string[];
    excludedContexts?: string[];
    excludedWebResources?: string[];
}

export interface ResourceDescriptor {
    batchType: string;
    isOnly: boolean;
    key: string;
    url: string;
    resourceType: string;
}

interface WebResourceRestResponse {
    resources: ResourceDescriptor[];
    unparsedData: { [key: string]: string };
    unparsedErrors: object;
}

const getRequestBody = ({
    webResources = [],
    contexts = [],
    excludedContexts = [],
    excludedWebResources = [],
}: RequestBodyArgs) => ({
    r: webResources,
    c: contexts,
    xc: excludedContexts,
    xr: excludedWebResources,
});

export const fetchWrmResourceDescriptors = (resources: string[]): Promise<WebResourceRestResponse> => {
    const uri = `${contextPath}/rest/webResources/1.0/resources`;
    const body = getRequestBody({ contexts: resources });
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(uri, body, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .toPromise()
            .catch((e) => Promise.reject(e))
    );
};
