import * as React from 'react';
import { PaperContent } from 'view/layout/paper';
import { SkeletonLoadingWrapper } from '@atlassian/help-center-common-component/loading';
import type { LoadingContainerProps } from '@atlassian/help-center-common-component/loading-container';

/**
 * Displays children when isLoading is false, otherwise shows loading spinner.
 */
export class SkeletonLoadingContainer extends React.Component<LoadingContainerProps> {
    static defaultProps = {
        loadingPosition: 'fill-container',
    };

    render() {
        const { children, loadingPosition, isLoading } = this.props;

        return isLoading ? (
            <PaperContent>
                <SkeletonLoadingWrapper isLoading loadingPosition={loadingPosition} />
            </PaperContent>
        ) : (
            children
        );
    }
}
