import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { serviceProjectRequestPermissions } from 'rest/service-project-request-permissions';
import type { ServiceProjectRequestPermissionsResponseType } from 'rest/service-project-request-permissions/service-project-request-permissions';
import type {
    FetchServiceProjectRequestPermissionFailure,
    FetchServiceProjectRequestPermission,
    FetchServiceProjectRequestPermissionSuccess,
} from 'state/actions/user';

import {
    FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS,
    fetchServiceProjectRequestPermissionSuccess,
    fetchServiceProjectRequestPermissionFailure,
} from 'state/actions/user';

import { sendEvent, trackError } from '@atlassian/help-center-common-util/analytics';
import { isNetworkErrorCode } from '@atlassian/help-center-common-util/error-codes';

export const fetchServiceProjectPermissionsEpic: Epic<
    FetchServiceProjectRequestPermission,
    FetchServiceProjectRequestPermissionSuccess | FetchServiceProjectRequestPermissionFailure
> = (action$) => {
    return action$
        .ofType(FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS)
        .mergeMap((action: FetchServiceProjectRequestPermission) => {
            const { meta } = action;
            return serviceProjectRequestPermissions()
                .map((response: ServiceProjectRequestPermissionsResponseType) => {
                    const { isEnabled, canCreateProject } = response;
                    return fetchServiceProjectRequestPermissionSuccess(isEnabled, canCreateProject);
                })
                .catch((e: AjaxError) => {
                    if (meta?.analyticsFailureEvent && !isNetworkErrorCode(e.status)) {
                        sendEvent(meta.analyticsFailureEvent);
                    }

                    trackError('new-service-project-request.permissions.fetch.failure', {}, e);
                    return Observable.of(fetchServiceProjectRequestPermissionFailure());
                });
        });
};
