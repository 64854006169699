import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { concat, Observable } from 'epics/rxjs';

import { login } from 'rest/login';
import type { LoginAttempt, LoginAttemptFailure, LoginAttemptSuccess } from 'state/actions/login';
import { LOGIN_ATTEMPT, loginAttemptFailure, loginAttemptSuccess } from 'state/actions/login';
import type { SuccessfullyLoggedIn } from 'state/actions/user';
import { successfullyLoggedIn } from 'state/actions/user';
import { getDefaultError } from '@atlassian/help-center-common-util/errors';

export const loginEpic: Epic<LoginAttempt, LoginAttemptSuccess | LoginAttemptFailure | SuccessfullyLoggedIn> = (
    action$: ActionsObservable<LoginAttempt>
) => {
    return action$.ofType(LOGIN_ATTEMPT).mergeMap((loginAction) => {
        const { email, password } = loginAction.payload;
        return login(email, password)
            .flatMap((response) => {
                if (response.loginSucceeded) {
                    return concat(Observable.of(loginAttemptSuccess(response)), Observable.of(successfullyLoggedIn()));
                }
                return Observable.of(loginAttemptSuccess(response));
            })
            .catch((error: AjaxError) => {
                const parsedError = getDefaultError(error);
                return Observable.of(loginAttemptFailure(parsedError));
            });
    });
};
