import type { StoreActionApi } from 'react-sweet-state';
import { v4 } from 'uuid';
import type { ErrorMessage } from '../../../common/types';
import { sendTrackEvent } from '../../../common/utils';
import type { State } from '../types';

export const sendMessageErrorHandler =
    () =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const { messages, conversationId, portalId, projectId, contextId } = getState();

        // https://data-portal.internal.atlassian.com/analytics/registry/65971
        sendTrackEvent({
            action: 'failed',
            actionSubject: 'conversationSendMessage',
            actionSubjectId: conversationId || '',
            attributes: {
                conversationId,
                contextId,
                portalId: portalId?.toString(),
                projectId: projectId?.toString(),
            },
        });

        const errorMessage: ErrorMessage = {
            id: `client::${v4()}`,
            timeCreated: new Date().toISOString(),
            conversationChannelId: conversationId || '',
            message: {
                authorType: 'ERROR',
            },
        };

        setState({
            messages: [...messages, errorMessage],
        });
    };
