/**
 * @generated SignedSource<<cabc17dd3ad01e18e7969eab9a0f737a>>
 * @relayHash 2e675e4a9a7e31699fc3d3584bbbdd7a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 064574971d1affd67cafa7379f6c0eb7409b6107ae91142724242ea806be9e78

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editHomepageQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  layoutAri: string;
};
export type editHomepageQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelFragment">;
    } | null | undefined;
    readonly mediaConfig: {
      readonly " $fragmentSpreads": FragmentRefs<"globalBrandingSettingsPanelMediaConfigFragment">;
    };
  } | null | undefined;
  readonly helpLayout: {
    readonly elementTypes: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"elementsCatalogueFragment">;
    }> | null | undefined;
    readonly layout: {
      readonly " $fragmentSpreads": FragmentRefs<"layoutBuilderFragment" | "useLayoutServiceFragment">;
    };
    readonly mediaConfig: {
      readonly asapIssuer: string | null | undefined;
      readonly mediaCollectionName: string | null | undefined;
      readonly mediaToken: string | null | undefined;
      readonly mediaUrl: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type editHomepageQuery = {
  response: editHomepageQuery$data;
  variables: editHomepageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layoutAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "layoutAri"
  }
],
v7 = {
  "alias": "layoutId",
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "concreteType": "HelpLayoutSubsectionConfig",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "span"
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "adf"
},
v10 = {
  "kind": "ScalarField",
  "name": "horizontalAlignment"
},
v11 = {
  "kind": "ScalarField",
  "name": "verticalAlignment"
},
v12 = {
  "concreteType": "HelpLayoutAlignmentSettings",
  "kind": "LinkedField",
  "name": "alignment",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ]
},
v13 = {
  "kind": "ScalarField",
  "name": "fileId"
},
v14 = {
  "kind": "ScalarField",
  "name": "url"
},
v15 = {
  "concreteType": "HelpLayoutBackgroundImage",
  "kind": "LinkedField",
  "name": "backgroundImage",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/)
  ]
},
v16 = {
  "kind": "ScalarField",
  "name": "backgroundColor"
},
v17 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v18 = [
  (v12/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useLayoutServiceVisualConfigData",
      "selections": (v18/*: any*/),
      "argumentDefinitions": ([]/*: any*/)
    }
  ]
},
v20 = [
  (v19/*: any*/)
],
v21 = [
  {
    "kind": "InlineFragment",
    "selections": (v20/*: any*/),
    "type": "HelpLayoutPortalsListElement"
  }
],
v22 = {
  "kind": "ScalarField",
  "name": "forgeElementPage"
},
v23 = {
  "kind": "ScalarField",
  "name": "forgeElementType"
},
v24 = {
  "kind": "ScalarField",
  "name": "connectElementPage"
},
v25 = {
  "kind": "ScalarField",
  "name": "connectElementType"
},
v26 = {
  "kind": "ScalarField",
  "name": "__id"
},
v27 = {
  "kind": "ClientExtension",
  "selections": [
    (v26/*: any*/)
  ]
},
v28 = [
  {
    "kind": "Variable",
    "name": "parentAri",
    "variableName": "helpCenterAri"
  }
],
v29 = {
  "kind": "ScalarField",
  "name": "mediaUrl"
},
v30 = {
  "kind": "ScalarField",
  "name": "mediaToken"
},
v31 = {
  "kind": "ScalarField",
  "name": "mediaCollectionName"
},
v32 = {
  "kind": "ScalarField",
  "name": "asapIssuer"
},
v33 = [
  (v29/*: any*/),
  (v30/*: any*/),
  (v31/*: any*/),
  (v32/*: any*/)
],
v34 = {
  "args": (v28/*: any*/),
  "concreteType": "HelpLayoutMediaConfig",
  "kind": "LinkedField",
  "name": "mediaConfig",
  "plural": false,
  "selections": (v33/*: any*/)
},
v35 = [
  (v14/*: any*/),
  (v13/*: any*/)
],
v36 = {
  "kind": "ScalarField",
  "name": "id"
},
v37 = {
  "kind": "InlineFragment",
  "selections": [
    (v36/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v38 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v39 = {
  "kind": "ScalarField",
  "name": "key"
},
v40 = {
  "kind": "ScalarField",
  "name": "name"
},
v41 = {
  "kind": "ScalarField",
  "name": "logo"
},
v42 = {
  "kind": "ScalarField",
  "name": "portalId"
},
v43 = {
  "concreteType": "HelpLayoutAtomicElementType",
  "kind": "LinkedField",
  "name": "elementType",
  "plural": false,
  "selections": [
    (v38/*: any*/),
    (v39/*: any*/)
  ]
},
v44 = {
  "concreteType": "HelpLayoutVisualConfig",
  "kind": "LinkedField",
  "name": "visualConfig",
  "plural": false,
  "selections": (v18/*: any*/)
},
v45 = {
  "kind": "ScalarField",
  "name": "isInstalled"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editHomepageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "globalBrandingSettingsPanelFragment"
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          },
          {
            "kind": "RequiredField",
            "field": {
              "args": (v4/*: any*/),
              "concreteType": "HelpCenterMediaConfig",
              "kind": "LinkedField",
              "name": "mediaConfig",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "globalBrandingSettingsPanelMediaConfigFragment"
                }
              ]
            },
            "action": "THROW",
            "path": "helpCenter.mediaConfig"
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": (v6/*: any*/),
              "kind": "LinkedField",
              "name": "layout",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "FragmentSpread",
                      "name": "layoutBuilderFragment"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "useLayoutServiceFragment",
                      "selections": [
                        (v7/*: any*/),
                        {
                          "concreteType": "HelpLayoutSectionConnection",
                          "kind": "LinkedField",
                          "name": "sections",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "HelpLayoutSectionEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "concreteType": "HelpLayoutSection",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "concreteType": "HelpLayoutSubsection",
                                      "kind": "LinkedField",
                                      "name": "subsections",
                                      "plural": true,
                                      "selections": [
                                        (v8/*: any*/),
                                        {
                                          "kind": "LinkedField",
                                          "name": "elements",
                                          "plural": true,
                                          "selections": [
                                            (v5/*: any*/),
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceEditorElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v9/*: any*/),
                                                    (v19/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutEditorElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceHeroElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": (v20/*: any*/),
                                                  "type": "HelpLayoutHeroElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceAnnouncementElementData",
                                              "selections": (v21/*: any*/),
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceTopicsElementData",
                                              "selections": (v21/*: any*/),
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceListOfPortalsElementData",
                                              "selections": (v21/*: any*/),
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceSuggestedRequestFormsElementData",
                                              "selections": (v21/*: any*/),
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceForgeElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v22/*: any*/),
                                                    (v23/*: any*/),
                                                    (v19/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutForgeElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            },
                                            {
                                              "kind": "InlineDataFragmentSpread",
                                              "name": "useLayoutServiceConnectElementData",
                                              "selections": [
                                                {
                                                  "kind": "InlineFragment",
                                                  "selections": [
                                                    (v24/*: any*/),
                                                    (v25/*: any*/),
                                                    (v19/*: any*/)
                                                  ],
                                                  "type": "HelpLayoutConnectElement"
                                                }
                                              ],
                                              "argumentDefinitions": []
                                            }
                                          ]
                                        },
                                        (v19/*: any*/)
                                      ]
                                    },
                                    (v19/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        (v27/*: any*/)
                      ],
                      "argumentDefinitions": []
                    }
                  ],
                  "type": "HelpLayout"
                }
              ]
            },
            "action": "THROW",
            "path": "helpLayout.layout"
          },
          (v34/*: any*/),
          {
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "elementsCatalogueFragment"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editHomepageQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterBranding",
                    "kind": "LinkedField",
                    "name": "helpCenterBranding",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpCenterBrandingColors",
                        "kind": "LinkedField",
                        "name": "colors",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "primary"
                          }
                        ]
                      },
                      {
                        "concreteType": "HelpCenterLogo",
                        "kind": "LinkedField",
                        "name": "logo",
                        "plural": false,
                        "selections": (v35/*: any*/)
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              (v37/*: any*/)
            ]
          },
          {
            "args": (v4/*: any*/),
            "concreteType": "HelpCenterMediaConfig",
            "kind": "LinkedField",
            "name": "mediaConfig",
            "plural": false,
            "selections": (v33/*: any*/)
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "HelpLayoutQueryApi",
        "kind": "LinkedField",
        "name": "helpLayout",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "kind": "LinkedField",
            "name": "layout",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v36/*: any*/),
                  {
                    "concreteType": "HelpLayoutSectionConnection",
                    "kind": "LinkedField",
                    "name": "sections",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpLayoutSectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpLayoutSection",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "HelpLayoutSubsection",
                                "kind": "LinkedField",
                                "name": "subsections",
                                "plural": true,
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "name": "elements",
                                    "plural": true,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpLayoutElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v9/*: any*/),
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "args": (v28/*: any*/),
                                                "concreteType": "HelpLayoutMediaConfig",
                                                "kind": "LinkedField",
                                                "name": "mediaConfig",
                                                "plural": false,
                                                "selections": [
                                                  (v31/*: any*/),
                                                  (v30/*: any*/),
                                                  (v29/*: any*/),
                                                  (v32/*: any*/)
                                                ]
                                              },
                                              (v38/*: any*/),
                                              (v39/*: any*/)
                                            ]
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "HelpLayoutAlignmentSettings",
                                                "kind": "LinkedField",
                                                "name": "alignment",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v11/*: any*/),
                                                  (v27/*: any*/)
                                                ]
                                              },
                                              (v15/*: any*/),
                                              (v16/*: any*/),
                                              (v17/*: any*/)
                                            ]
                                          },
                                          (v27/*: any*/)
                                        ],
                                        "type": "HelpLayoutEditorElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutPortalCard",
                                            "kind": "LinkedField",
                                            "name": "portals",
                                            "plural": true,
                                            "selections": [
                                              (v40/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "description"
                                              },
                                              (v41/*: any*/),
                                              (v42/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "isFeatured"
                                              },
                                              (v5/*: any*/)
                                            ]
                                          },
                                          (v43/*: any*/),
                                          (v44/*: any*/)
                                        ],
                                        "type": "HelpLayoutPortalsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutRequestForm",
                                            "kind": "LinkedField",
                                            "name": "suggestedRequestTypes",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "descriptionHtml"
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "portalName"
                                              },
                                              (v36/*: any*/),
                                              (v42/*: any*/),
                                              (v40/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrl"
                                              },
                                              (v5/*: any*/)
                                            ]
                                          },
                                          (v43/*: any*/)
                                        ],
                                        "type": "HelpLayoutSuggestedRequestFormsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "header"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "message"
                                          },
                                          (v43/*: any*/)
                                        ],
                                        "type": "HelpLayoutAnnouncementElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutTopic",
                                            "kind": "LinkedField",
                                            "name": "topics",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicId"
                                              },
                                              (v17/*: any*/),
                                              {
                                                "concreteType": "HelpLayoutTopicItem",
                                                "kind": "LinkedField",
                                                "name": "items",
                                                "plural": true,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "title"
                                                  },
                                                  (v41/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "displayLink"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "entityKey"
                                                  },
                                                  {
                                                    "kind": "ScalarField",
                                                    "name": "helpObjectType"
                                                  },
                                                  (v27/*: any*/)
                                                ]
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "name": "topicName"
                                              }
                                            ]
                                          },
                                          (v43/*: any*/)
                                        ],
                                        "type": "HelpLayoutTopicsListElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "homePageTitle"
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "name": "userLanguageTag"
                                          },
                                          {
                                            "concreteType": "HelpLayoutVisualConfig",
                                            "kind": "LinkedField",
                                            "name": "visualConfig",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "foregroundColor"
                                              },
                                              (v12/*: any*/),
                                              (v15/*: any*/),
                                              (v16/*: any*/),
                                              (v17/*: any*/)
                                            ]
                                          },
                                          (v43/*: any*/)
                                        ],
                                        "type": "HelpLayoutHeroElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v23/*: any*/),
                                          (v22/*: any*/),
                                          (v43/*: any*/),
                                          (v45/*: any*/),
                                          (v44/*: any*/)
                                        ],
                                        "type": "HelpLayoutForgeElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v24/*: any*/),
                                          (v25/*: any*/),
                                          (v43/*: any*/),
                                          (v45/*: any*/),
                                          (v44/*: any*/)
                                        ],
                                        "type": "HelpLayoutConnectElement"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "identifier"
                                          }
                                        ],
                                        "type": "QueryError"
                                      },
                                      (v27/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAtomicElementType",
                                            "kind": "LinkedField",
                                            "name": "elementType",
                                            "plural": false,
                                            "selections": [
                                              (v39/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "HelpLayoutNoContentElement"
                                      },
                                      (v37/*: any*/)
                                    ]
                                  },
                                  (v36/*: any*/),
                                  (v44/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "concreteType": "HelpLayoutVisualConfig",
                                        "kind": "LinkedField",
                                        "name": "visualConfig",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "HelpLayoutAlignmentSettings",
                                            "kind": "LinkedField",
                                            "name": "alignment",
                                            "plural": false,
                                            "selections": [
                                              (v27/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ],
                                    "type": "HelpLayoutVisualEntity",
                                    "abstractKey": "__isHelpLayoutVisualEntity"
                                  },
                                  (v27/*: any*/)
                                ]
                              },
                              {
                                "concreteType": "HelpLayoutVisualConfig",
                                "kind": "LinkedField",
                                "name": "visualConfig",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  {
                                    "concreteType": "HelpLayoutBackgroundImage",
                                    "kind": "LinkedField",
                                    "name": "backgroundImage",
                                    "plural": false,
                                    "selections": (v35/*: any*/)
                                  },
                                  (v17/*: any*/),
                                  (v12/*: any*/)
                                ]
                              },
                              (v36/*: any*/),
                              {
                                "kind": "ClientExtension",
                                "selections": [
                                  {
                                    "alias": "dataId",
                                    "kind": "ScalarField",
                                    "name": "__id"
                                  },
                                  (v26/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v7/*: any*/),
                  (v27/*: any*/)
                ],
                "type": "HelpLayout"
              },
              (v37/*: any*/)
            ]
          },
          (v34/*: any*/),
          {
            "kind": "LinkedField",
            "name": "elementTypes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isHelpLayoutElementType"
              },
              (v38/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "atomicKey",
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "args": (v28/*: any*/),
                    "concreteType": "HelpLayoutMediaConfig",
                    "kind": "LinkedField",
                    "name": "mediaConfig",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v31/*: any*/),
                      (v30/*: any*/),
                      (v29/*: any*/),
                      (v32/*: any*/)
                    ]
                  }
                ],
                "type": "HelpLayoutAtomicElementType"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "compositeKey",
                    "kind": "ScalarField",
                    "name": "key"
                  }
                ],
                "type": "HelpLayoutCompositeElementType"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "064574971d1affd67cafa7379f6c0eb7409b6107ae91142724242ea806be9e78",
    "metadata": {},
    "name": "editHomepageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "826e1f151f2c931c855bc4dd14afdcd5";

export default node;
