import type { RequestGroup } from 'rest/request-group-reorder/request-group-reorder';
import type { AddRequestGroupAction, AddRequestGroupFailedAction } from 'state/actions/add-request-group';
import { ADD_REQUEST_GROUP, ADD_REQUEST_GROUP_FAILED } from 'state/actions/add-request-group';
import {
    ADD_REQUEST_TYPE_TO_REQUEST_GROUP,
    ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED,
} from 'state/actions/add-request-type-to-group';
import type {
    AddRequestTypeToRequestGroupAction,
    AddRequestTypeToRequestGroupSucceededAction,
    AddRequestTypeToRequestGroupFailedAction,
} from 'state/actions/add-request-type-to-group';
import type {
    CreateGroupWithRequestTypeAction,
    CreateGroupWithRequestTypeSucceededAction,
    CreateGroupWithRequestTypeFailedAction,
} from 'state/actions/create-group-with-request-type';
import {
    CREATE_GROUP_WITH_REQUEST_TYPE,
    CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED,
    CREATE_GROUP_WITH_REQUEST_TYPE_FAILED,
} from 'state/actions/create-group-with-request-type';
import { DELETE_REQUEST_TYPE_FROM_GROUP } from 'state/actions/delete-request-type-from-group';
import type { DeleteRequestTypeFromGroupAction } from 'state/actions/delete-request-type-from-group';
import { FETCH_PORTAL_SUCCESS } from 'state/actions/portal';
import type { FetchPortalSuccess } from 'state/actions/portal';
import {
    UPDATE_REQUEST_GROUPS_ORDER,
    CLEAR_REQUEST_GROUPS_ORDER,
    CLEAR_REQUEST_TYPES_ORDER,
    CLEAR_ERROR_MESSAGE,
} from 'state/actions/portal-customisation';
import type {
    UpdateRequestGroupsOrderAction,
    ClearRequestGroupsOrderAction,
    ClearErrorMessageAction,
    ClearRequestTypeOrderAction,
} from 'state/actions/portal-customisation';
import { REORDER_REQUEST_TYPE } from 'state/actions/reorder-request-types';
import type { ReorderRequestTypeAction } from 'state/actions/reorder-request-types';
import { GET_ALL_REQUEST_GROUPS_SUCCESS } from 'state/actions/request-groups';
import type { RequestGroupResponse, GetAllRequestGroupsSuccess } from 'state/actions/request-groups';
import { SET_REQUEST_TYPE_GROUPS, SET_REQUEST_TYPE_GROUPS_FAILED } from 'state/actions/set-request-type-groups';
import type {
    SetRequestTypeGroupsAction,
    SetRequestTypeGroupsFailedAction,
} from 'state/actions/set-request-type-groups';
import type { ReqTypesState } from 'state/persisted/portal';
import { deleteFromList, reorderList } from 'view/portal-settings-sidebar/draggable-list-utils';
export const PLACEHOLDER_REQUEST_GROUP_ID = -1;

type HandledActions =
    | FetchPortalSuccess
    | UpdateRequestGroupsOrderAction
    | ClearRequestGroupsOrderAction
    | AddRequestGroupAction
    | AddRequestGroupFailedAction
    | CreateGroupWithRequestTypeAction
    | CreateGroupWithRequestTypeSucceededAction
    | CreateGroupWithRequestTypeFailedAction
    | ReorderRequestTypeAction
    | DeleteRequestTypeFromGroupAction
    | SetRequestTypeGroupsAction
    | SetRequestTypeGroupsFailedAction
    | UpdateRequestGroupsOrderAction
    | ClearRequestGroupsOrderAction
    | ClearErrorMessageAction
    | ClearRequestTypeOrderAction
    | FetchPortalSuccess
    | ClearErrorMessageAction
    | AddRequestTypeToRequestGroupAction
    | AddRequestTypeToRequestGroupSucceededAction
    | AddRequestTypeToRequestGroupFailedAction
    | GetAllRequestGroupsSuccess;

export interface PortalCustomisationUIState {
    // Request group settings
    requestGroups: RequestGroupResponse[] | undefined;
    requestGroupsOrder: number[] | undefined;
    error: string | undefined;
    groupNameThatCausedError: string | undefined;
    // Request group detail settings
    requestTypesOrder: number[] | undefined;
    requestTypesInRequestGroup: ReqTypesState[] | undefined;
    requestTypesNotInRequestGroup: ReqTypesState[] | undefined;
    // Request type detail settings
    parentRequestGroups: RequestGroup[] | undefined;
    isParentRequestGroupsLoading: boolean;
}

const getDefaultState = (): PortalCustomisationUIState => ({
    requestGroups: undefined,
    requestGroupsOrder: undefined,
    requestTypesOrder: undefined,
    requestTypesInRequestGroup: undefined,
    requestTypesNotInRequestGroup: undefined,
    parentRequestGroups: undefined,
    error: undefined,
    groupNameThatCausedError: undefined,
    isParentRequestGroupsLoading: false,
});

export default function reducer(
    state: PortalCustomisationUIState = getDefaultState(),
    action: HandledActions
): PortalCustomisationUIState {
    switch (action.type) {
        case UPDATE_REQUEST_GROUPS_ORDER: {
            return {
                ...state,
                requestGroupsOrder: action.payload.order,
            };
        }

        case ADD_REQUEST_GROUP: {
            const newGroup: RequestGroupResponse = {
                name: action.payload.groupName,
                id: PLACEHOLDER_REQUEST_GROUP_ID, // We don't have an id yet because the request hasn't completed, we put in a fake number temporarily. the groups here will get cleared when the persisted model is fetched
            };

            return {
                ...state,
                requestGroups: [...action.payload.existingGroups, newGroup],
                error: undefined,
                groupNameThatCausedError: undefined,
            };
        }

        case ADD_REQUEST_GROUP_FAILED: {
            return {
                ...state,
                requestGroups: undefined,
                error: action.payload.message,
                groupNameThatCausedError: action.payload.groupNameThatCausedError,
            };
        }

        case CREATE_GROUP_WITH_REQUEST_TYPE: {
            return {
                ...state,
                isParentRequestGroupsLoading: true,
            };
        }

        case CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED: {
            return {
                ...state,
                isParentRequestGroupsLoading: false,
            };
        }

        case CREATE_GROUP_WITH_REQUEST_TYPE_FAILED: {
            return {
                ...state,
                isParentRequestGroupsLoading: false,
            };
        }

        case DELETE_REQUEST_TYPE_FROM_GROUP: {
            const { requestTypes, index } = action.payload;
            const requestTypeIds = requestTypes.map(({ id: requestTypeId }: ReqTypesState) => Number(requestTypeId));
            const udpatedRequestTypeIds = deleteFromList(requestTypeIds, index);

            return {
                ...state,
                requestTypesInRequestGroup: undefined,
                requestTypesNotInRequestGroup: undefined,
                requestTypesOrder: udpatedRequestTypeIds,
            };
        }

        case SET_REQUEST_TYPE_GROUPS: {
            const { requestGroups } = action.payload;

            return {
                ...state,
                parentRequestGroups: requestGroups,
            };
        }

        case SET_REQUEST_TYPE_GROUPS_FAILED: {
            return {
                ...state,
                parentRequestGroups: undefined,
            };
        }

        case REORDER_REQUEST_TYPE: {
            const { requestTypes, fromIndex, toIndex } = action.payload;
            const requestTypeIds = requestTypes.map(({ id: requestTypeId }: ReqTypesState) => Number(requestTypeId));
            const updatedRequestTypeIds = reorderList(requestTypeIds, fromIndex, toIndex);

            return {
                ...state,
                requestTypesOrder: updatedRequestTypeIds,
            };
        }

        case FETCH_PORTAL_SUCCESS: {
            return getDefaultState();
        }

        case ADD_REQUEST_TYPE_TO_REQUEST_GROUP: {
            const { requestType, requestTypesNotInRequestGroup, requestTypesInRequestGroup } = action.payload;

            // Remove request type from requestTypesNotInRequestGroup used for request type search options
            const optimisticRequestTypesNotInRequestGroup = requestTypesNotInRequestGroup.filter(
                (requestTypeNotInRequestGroup) => requestTypeNotInRequestGroup.id !== requestType.id
            );

            // Add request type as the last element of requestTypesInRequestGroup used to render request types in group
            const optimisticRequestTypesInRequestGroup = [...requestTypesInRequestGroup, requestType];

            // This will have the new request type as the last index (the order will then be persisted)
            const requestTypeIds = optimisticRequestTypesInRequestGroup.map(({ id: requestTypeId }: ReqTypesState) =>
                Number(requestTypeId)
            );

            return {
                ...state,
                requestTypesOrder: requestTypeIds,
                requestTypesInRequestGroup: [...optimisticRequestTypesInRequestGroup],
                requestTypesNotInRequestGroup: [...optimisticRequestTypesNotInRequestGroup],
                error: undefined,
            };
        }

        case ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED: {
            return {
                ...state,
                requestTypesOrder: undefined,
                requestTypesInRequestGroup: undefined,
                requestTypesNotInRequestGroup: undefined,
                error: action.payload.message,
            };
        }

        case CLEAR_REQUEST_TYPES_ORDER: {
            return {
                ...state,
                requestGroups: undefined,
                requestTypesInRequestGroup: undefined,
                requestTypesNotInRequestGroup: undefined,
                error: undefined,
                requestTypesOrder: undefined,
            };
        }

        case CLEAR_ERROR_MESSAGE: {
            return {
                ...state,
                error: undefined,
                groupNameThatCausedError: undefined,
                requestTypesOrder: undefined,
            };
        }

        // Reset any errors and clear the UI state version of request groups so it can be populated with the newly persisted list as the new source of truth
        case CLEAR_REQUEST_GROUPS_ORDER: {
            return getDefaultState();
        }

        // On successful edit clear UI state order, and use the newly persisted request groups state as the source of truth for the request groups list.
        case GET_ALL_REQUEST_GROUPS_SUCCESS: {
            const { requestGroups } = action.payload;
            return { ...state, requestGroups, requestGroupsOrder: undefined };
        }

        default:
            return state;
    }
}
