import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { LinkItem, Section } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';

interface AccountDetailProps {
    email: string;
    displayName: string;
    avatarSrc?: string;
}

export const AccountDetails = ({ avatarSrc, displayName, email }: AccountDetailProps) => {
    return (
        <Section>
            <NonInteractiveLinkItem>
                <ProfileWrapper>
                    <AvatarWrapper>
                        <Avatar appearance="circle" size="medium" src={avatarSrc} />
                    </AvatarWrapper>
                    <DetailsWrapper>
                        <DisplayName>{displayName}</DisplayName>
                        <small>{email}</small>
                    </DetailsWrapper>
                </ProfileWrapper>
            </NonInteractiveLinkItem>
        </Section>
    );
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const NonInteractiveLinkItem = styled(LinkItem)({
    pointerEvents: 'none',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: 'unset !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ProfileWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AvatarWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const DisplayName = styled.div({
    lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const DetailsWrapper = styled.div({
    flexDirection: "'row'",
});
