import type { Middleware, Reducer } from 'redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from '../epics';
import type { AsyncPersistedReducers } from './persisted/reducer';
import { getCombinedPersistedReducer } from './persisted/reducer';
import type { State } from './reducer';
import rootReducer from './reducer';
import type { AsyncUIReducers } from './ui/reducer';
import { getCombinedUIReducer } from './ui/reducer';

interface Options {
    middleware?: Middleware[];
    initialState?: State;
    initializeEpics?: boolean;
}

const createReducer = (
    asyncPersistedReducers: AsyncPersistedReducers,
    asyncUIReducers: AsyncUIReducers
): Reducer<State> => {
    const persisted = getCombinedPersistedReducer(asyncPersistedReducers);
    const ui = getCombinedUIReducer(asyncUIReducers);

    return combineReducers({
        persisted,
        ui,
    });
};

/**
 * Sometimes we want to run storybooks as standalone apps so we can embed them. In that case they
 * behave a little differently to when they are embedded inside the Storybook application frame.
 * This checks a query parameter to make sure we don't run into CORS issues.
 *
 * @returns True if we're in a storybook and it's being embedded outside the usual storybook frame.
 */
const isStandaloneStory = () => {
    const params = new URLSearchParams(window.location.search);
    return (params.get('singleStory') || params.get('singlestory')) === 'true';
};

export default function create({ initialState, middleware = [], initializeEpics = true }: Options = {}) {
    // Enables redux for storybook
    // trueWindow will hold the value of the window in which portal is loaded
    let trueWindow;

    // if portal is loaded inside an iframe, window.self will not be equal to window.top
    if (isStandaloneStory() || window.self !== window.top) {
        trueWindow = window.self || window;
    } else {
        trueWindow = window.parent || window;
    }

    const composeEnhancers = trueWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const epicMiddlewares = initializeEpics ? [createEpicMiddleware(rootEpic)] : [];

    const store = createStore<State>(
        rootReducer,
        initialState || ({} as State),
        composeEnhancers(applyMiddleware(...epicMiddlewares, ...middleware))
    );

    let asyncPersistedReducers: AsyncPersistedReducers = {};
    let asyncUIReducers: AsyncUIReducers = {};

    const injectReducer = <T extends keyof State, K extends OptionalKeys<State[T]>>(
        storeType: T,
        key: K,
        asyncReducer: Reducer<State[T][K]>
    ) => {
        if (storeType === 'ui') {
            asyncUIReducers = {
                ...asyncUIReducers,
                [key]: asyncReducer,
            };
        }

        if (storeType === 'persisted') {
            asyncPersistedReducers = {
                ...asyncPersistedReducers,
                [key]: asyncReducer,
            };
        }

        store.replaceReducer(createReducer(asyncPersistedReducers, asyncUIReducers));
    };

    return { store, injectReducer };
}
