import { post, put, doDelete } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import type { ReqTypesState } from 'state/persisted/portal';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface RequestTypeReorderRequest {
    projectId: number;
    requestTypeId: number;
    requestGroupId: number;
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    body: {};
}

export interface RequestTypeDeleteFromGroup {
    isProjectSimplified: boolean;
    projectId: number;
    requestGroupId: number;
    requestType: ReqTypesState;
}

export const requestTypeReorder = ({ projectId, requestTypeId, requestGroupId, body }: RequestTypeReorderRequest) => {
    const uri = `${contextPath}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups/${requestGroupId}/request-types/${requestTypeId}/move`;

    return post(uri, body, { 'Content-Type': 'application/json' })
        .map(({ response }) => response as ReqTypesState)
        .catch((e) => Observable.throw(e));
};

export const deleteRequestTypeFromGroup = ({
    isProjectSimplified,
    projectId,
    requestGroupId,
    requestType: toUpdate,
}: RequestTypeDeleteFromGroup) => {
    const { id: requestTypeId } = toUpdate;
    if (isProjectSimplified) {
        const nextGenUri = `${contextPath}/rest/servicedesk/1/simplified/${projectId}/request-type-groups/${requestGroupId}/${requestTypeId}`;
        return doDelete(nextGenUri, undefined)
            .map(({ response }) => response as ReqTypesState)
            .catch((e) => Observable.throw(e));
    }

    const classicUri = `${contextPath}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups/${requestGroupId}/request-types/${requestTypeId}`;
    return put(classicUri, toUpdate, { 'Content-Type': 'application/json' })
        .map(({ response }) => response as ReqTypesState)
        .catch((e) => Observable.throw(e));
};
