import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchVisitPortalAction,
    FetchVisitPortalSuccess,
    FetchVisitPortalFailure,
} from 'state/actions/invited-sign-up';
import {
    FETCH_VISIT_PORTAL_MODEL,
    fetchVisitPortalSuccess,
    fetchVisitPortalFailure,
} from 'state/actions/invited-sign-up';
import type { ErrorResponse } from 'epics/model/types';

export const visitPortalEpic: Epic<FetchVisitPortalAction, FetchVisitPortalSuccess | FetchVisitPortalFailure> = (
    action$
) => {
    return action$.ofType(FETCH_VISIT_PORTAL_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.visitPortal && !!response.visitPortal.email)
            .map((response) => {
                return fetchVisitPortalSuccess(response.visitPortal);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchVisitPortalFailure(error));
            })
    );
};
