import { UPDATE_USER_REQUESTS_COUNTS_MODEL } from 'state/actions/user';
import type { UpdateUserRequestsCountAction } from 'state/actions/user/user';

export interface UserRequestsCounts {
    myRequestCount?: number;
    pendingApprovalCount?: number;
    approvalCount?: number;
}

export type Action = UpdateUserRequestsCountAction;

export default function reducer(state: UserRequestsCounts = {}, action: Action): UserRequestsCounts {
    switch (action.type) {
        case UPDATE_USER_REQUESTS_COUNTS_MODEL: {
            const userRequestCountResponse = action.payload;
            return {
                myRequestCount: userRequestCountResponse.allOpenRequestsCount,
                pendingApprovalCount: userRequestCountResponse.pendingApprovalsCount,
                approvalCount: userRequestCountResponse.approvalsCount,
            };
        }
        default:
            return state;
    }
}
