import { layers } from '@atlaskit/theme';

export const modal = layers.modal();
export const card = layers.card();
export const dialog = layers.dialog();
export const panel = layers.navigation();
export const flag = layers.flag();
export const layer = layers.layer();

export const searchBar = 502; // This has to be > atlaskit blanket which has a z-index of 500 and smaller than the modal layer which is at 510

// this has to be above the atlaskit blanket when animating out, but below the blanket when animations are off
export const searchTextboxBackground = searchBar;
export const searchTextbox = searchTextboxBackground + 1;

// prevent textbox from being above the sidebar at small widths
export const pushingCustomizeSidebarSmall = searchTextbox + 1;

// on larger widths, this needs to be < atlaskit modal z-index (400) to allow the search component to overlay over the sidebar
export const pushingCustomizeSidebarLarge = modal - 50;

export const expanderButtonContainer = 1;
export const expanderBackground = 2;
export const expanderButton = 3;

// Place paper behind everything so the search overlay can animate over it.
export const pageInBannerLayout = -1;

// Place banner behind paper
export const banner = -2;

export const portalCardLayout = 0;

export const forgeLoadingComponent = 500;
