import { buildModelsActionCreator } from 'epics/model/actions';
import type { RequestListResponse } from 'rest/request-list';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_REQUEST_LIST_MODEL = 'epic.model.FETCH_REQUEST_LIST_MODEL';
export const FETCH_REQUEST_LIST_SUCCESS = 'state.persisted.request.List.FETCH_REQUEST_LIST_SUCCESS';
export const FETCH_REQUEST_LIST_FAILURE = 'state.persisted.request.List.FETCH_REQUEST_LIST_FAILURE';
export const MARK_INITIAL_PAGE_LOAD_RENDERED = 'state.persisted.request.List.MARK_INITIAL_PAGE_LOAD_RENDERED';
export const INVALIDATE_PAGES = 'state.persisted.request.List.INVALIDATE_PAGES';

export type FetchRequestListAction = ReturnType<typeof fetchRequestListModel>;

export const fetchRequestListModel = buildModelsActionCreator(FETCH_REQUEST_LIST_MODEL, 'allReqFilter');

export interface InvalidatePages {
    type: typeof INVALIDATE_PAGES;
    payload: number;
}

export const invalidatePages = (currentPage: number): InvalidatePages => ({
    type: INVALIDATE_PAGES,
    payload: currentPage,
});

export interface FetchRequestListSuccess {
    type: typeof FETCH_REQUEST_LIST_SUCCESS;
    payload?: RequestListResponse;
}

export const fetchRequestListSuccess = (requestListModel?: RequestListResponse): FetchRequestListSuccess => ({
    type: FETCH_REQUEST_LIST_SUCCESS,
    payload: requestListModel,
});

export interface FetchRequestListFailure {
    type: typeof FETCH_REQUEST_LIST_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchRequestListFailure = (error: ErrorResponse): FetchRequestListFailure => ({
    type: FETCH_REQUEST_LIST_FAILURE,
    payload: {
        error,
    },
});

export interface MarkInitialPageLoadRendered {
    type: typeof MARK_INITIAL_PAGE_LOAD_RENDERED;
}

export const markInitialPageLoadRendered = (): MarkInitialPageLoadRendered => ({
    type: MARK_INITIAL_PAGE_LOAD_RENDERED,
});
