/**
 This hook will only work if the helpcenterId exists in the path param and the activation-id exists in the meta tag.
 @returns {string} The Help Center ARI.
 */

import { getHelpCenterAri, getMeta } from '@helpCenter/util/meta';
import { isMoveToManagementRouterEnabled } from 'feature-flags';
import { usePathParam } from 'react-resource-router';
import { HelpHelpCenterAri } from '@atlassian/ari';

export const useHelpCenterAri = () => {
    const [helpCenterId = ''] = usePathParam('helpcenterId');
    const activationId = getMeta('activation-id') || '';

    if (!isMoveToManagementRouterEnabled()) {
        return getHelpCenterAri();
    }
    const helpCenterAri = HelpHelpCenterAri.create({ activationId, helpCenterId }).toString();
    return helpCenterAri;
};
