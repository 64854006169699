import type { StoreActionApi } from 'react-sweet-state';
import type { State, ConversationStateContainerProps } from '../types';

export const resetState =
    () =>
    (
        { setState }: StoreActionApi<State>,
        {
            conversationProvider,
            portalId,
            projectId,
            contextId,
            baseUrl,
            isTestConversation,
            handleEscalateFromVirtualAgent,
        }: ConversationStateContainerProps
    ) => {
        setState({
            conversationProvider,
            portalId,
            projectId,
            contextId,
            baseUrl,
            isTestConversation,
            handleEscalateFromVirtualAgent,
        });
    };
