/**
 * @generated SignedSource<<a69f15551c1a26d9f359c1eb7aae8f2a>>
 * @relayHash 17202fdefa5771d94451455498f88255
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 25b4979a1eef644ed8fc66226336f3386794e64ca0874fa20f038f1cffb29679

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type srcHelpCenterVirtualAgentSettingsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  isJsmAIConfigEnabled: boolean;
  isVAOnHelpCenterGAEnabled: boolean;
};
export type srcHelpCenterVirtualAgentSettingsQuery$data = {
  readonly helpCenter?: {
    readonly helpCenterById: {
      readonly __typename: string;
      readonly message?: string | null | undefined;
      readonly virtualAgentEnabled?: boolean | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"settingsBreadcrumbsFragment" | "settingsVirtualAgentSettingsTabFragment">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"settingsVirtualAgentSettingsTabMappedProjectsFragment">;
};
export type srcHelpCenterVirtualAgentSettingsQuery = {
  response: srcHelpCenterVirtualAgentSettingsQuery$data;
  variables: srcHelpCenterVirtualAgentSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isJsmAIConfigEnabled"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isVAOnHelpCenterGAEnabled"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "Variable",
  "name": "helpCenterAri",
  "variableName": "helpCenterAri"
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = {
  "kind": "ScalarField",
  "name": "virtualAgentEnabled"
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "QueryError"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = [
  (v1/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterARI",
        "variableName": "helpCenterAri"
      },
      {
        "kind": "Literal",
        "name": "helpCenterId",
        "value": ""
      },
      {
        "kind": "Literal",
        "name": "helpCenterMappingStatus",
        "value": "LINKED"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "settingsVirtualAgentSettingsTabFragment"
                      },
                      (v6/*: any*/)
                    ],
                    "type": "HelpCenter"
                  },
                  (v7/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "settingsBreadcrumbsFragment"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "isVAOnHelpCenterGAEnabled",
                "variableName": "isVAOnHelpCenterGAEnabled"
              }
            ],
            "kind": "FragmentSpread",
            "name": "settingsVirtualAgentSettingsTabMappedProjectsFragment"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "selections": [
      {
        "condition": "isJsmAIConfigEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterQueryApi",
            "kind": "LinkedField",
            "name": "helpCenter",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "LinkedField",
                "name": "helpCenterById",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/),
                      {
                        "concreteType": "HelpCenterName",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "default"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "slug"
                      }
                    ],
                    "type": "HelpCenter"
                  },
                  (v7/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "isVAOnHelpCenterGAEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "condition": "isVAOnHelpCenterGAEnabled",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "concreteType": "JiraQuery",
                "kind": "LinkedField",
                "name": "jira",
                "plural": false,
                "selections": [
                  {
                    "args": (v11/*: any*/),
                    "concreteType": "JiraProjectConnection",
                    "kind": "LinkedField",
                    "name": "jiraProjectsMappedToHelpCenter",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProjectEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraProject",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "concreteType": "JiraAvatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "small"
                                  }
                                ]
                              },
                              {
                                "args": (v2/*: any*/),
                                "kind": "LinkedField",
                                "name": "knowledgeBaseArticlesCount",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "count"
                                      }
                                    ],
                                    "type": "KnowledgeBaseArticleCountSource"
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "name": "virtualAgentConfiguration",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "isAiResponsesEnabled"
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "name": "liveIntentsCount"
                                      }
                                    ],
                                    "type": "VirtualAgentConfiguration"
                                  },
                                  (v10/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v9/*: any*/),
                                    "type": "VirtualAgentQueryError"
                                  }
                                ]
                              },
                              (v5/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "args": (v11/*: any*/),
                    "filters": [
                      "cloudId",
                      "filter"
                    ],
                    "handle": "connection",
                    "key": "projectBreakdownTableMappedProjectsFragment_jiraProjectsMappedToHelpCenter",
                    "kind": "LinkedHandle",
                    "name": "jiraProjectsMappedToHelpCenter"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "25b4979a1eef644ed8fc66226336f3386794e64ca0874fa20f038f1cffb29679",
    "metadata": {},
    "name": "srcHelpCenterVirtualAgentSettingsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "01ffd7ee87a8da9de236610e5c288c99";

export default node;
