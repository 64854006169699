import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
    getDefaultViewPortals,
    getSortBy,
    getTotal,
    getExpandedViewLoading,
    getVisibleFeaturedPortalsCount,
    getExpandedViewPortals,
} from 'state/selectors/featured-and-sorted-portals';
import { isCustomizing } from 'state/selectors/help-center-preview';
import FeaturedAndSortedPortalsComponent from './featured-and-sorted-portals';

const selector = createSelector(
    getDefaultViewPortals,
    getExpandedViewPortals,
    getSortBy,
    getTotal,
    getExpandedViewLoading,
    getVisibleFeaturedPortalsCount,
    isCustomizing,
    (
        defaultViewPortals,
        expandedViewPortals,
        sortBy,
        total,
        isExpandedViewLoading,
        visibleFeaturedPortalsCount,
        customizing
    ) => ({
        defaultViewPortals,
        expandedViewPortals,
        sortBy,
        total,
        isExpandedViewLoading,
        featuredPortalsCount: visibleFeaturedPortalsCount,
        isCustomizing: customizing,
    })
);

export default connect(selector, {})(FeaturedAndSortedPortalsComponent);
