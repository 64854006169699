import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import messages from './messages';

export const HelpCenterConversationPage = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "async-unified-help-conversation-page" */ 'unified-help-center-alpha/src/pages/conversation-page/ConversationPage'
            )
    ),
});

export const PortalConversationPage = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "async-unified-help-portal-conversation-page" */ 'unified-help-center-alpha/src/pages/portal-conversation-page/PortalConversationPage'
            )
    ),
});

export const callToActionUrlText = messages.callToActionUrlText;
export const authPageMessage = messages.authPageMessage;
