import type {
    EnableResendValidationEmailLink,
    ResendValidationEmail,
    ResendValidationEmailSuccess,
    ResendValidationEmailFailure,
} from 'state/actions/sign-up';
import {
    ENABLE_RESEND_VALIDATION_EMAIL_LINK,
    RESEND_VALIDATION_EMAIL,
    RESEND_VALIDATION_EMAIL_SUCCESS,
    RESEND_VALIDATION_EMAIL_FAILURE,
} from 'state/actions/sign-up';

export interface CheckEmailUIState {
    isSubmitting: boolean;
    resendValidationLinkDisabled: boolean;
}

export type HandledActions =
    | EnableResendValidationEmailLink
    | ResendValidationEmail
    | ResendValidationEmailFailure
    | ResendValidationEmailSuccess;

export const defaultState: CheckEmailUIState = {
    isSubmitting: false,
    resendValidationLinkDisabled: false,
};

export function checkEmailUiReducer(
    state: CheckEmailUIState = defaultState,
    action: HandledActions
): CheckEmailUIState {
    switch (action.type) {
        case ENABLE_RESEND_VALIDATION_EMAIL_LINK:
            return handleEnableResendValidationLink(state);
        case RESEND_VALIDATION_EMAIL:
            return handleResendValidationEmail(state);
        case RESEND_VALIDATION_EMAIL_SUCCESS:
            return handleResendValidationEmailSuccess(state);
        case RESEND_VALIDATION_EMAIL_FAILURE:
            return handleResendValidationEmailFailure(state);
        default:
            return state;
    }
}

const handleEnableResendValidationLink = (state: CheckEmailUIState): CheckEmailUIState => ({
    ...state,
    resendValidationLinkDisabled: false,
});

const handleResendValidationEmail = (state: CheckEmailUIState): CheckEmailUIState => ({
    ...state,
    isSubmitting: true,
    resendValidationLinkDisabled: true,
});

const handleResendValidationEmailSuccess = (state: CheckEmailUIState): CheckEmailUIState => ({
    ...state,
    isSubmitting: false,
});

const handleResendValidationEmailFailure = (state: CheckEmailUIState): CheckEmailUIState => ({
    ...state,
    isSubmitting: false,
});
