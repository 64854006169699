import React from 'react';
import errorImg from 'assets/images/search-modal-error.svg';
import { useIntl } from 'react-intl-next';
import Heading from '@atlaskit/heading';
import { Stack } from '@atlaskit/primitives';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';
import { Img } from '../common';
import messages from './messages';

const SearchResultError = () => {
    const { formatMessage } = useIntl();

    return (
        <Stack alignInline="center">
            <Img src={errorImg} />
            <Heading level="h400">{formatMessage(messages.searchErrorTitle)}</Heading>
            {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
            <p style={{ textAlign: 'center' }}>
                {formatMessage(messages.searchErrorText, {
                    helpCenterLink: (
                        <ThemedLink to={getHelpCenterUrl()} actionSubjectId="helpCenterLink">
                            {formatMessage(messages.helpCenter)}
                        </ThemedLink>
                    ),
                })}
            </p>
        </Stack>
    );
};

export default SearchResultError;
