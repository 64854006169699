import type { ReqTypesState, ReqGroupsState } from 'state/persisted/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const ADD_REQUEST_TYPE_TO_REQUEST_GROUP = 'state.ui.request-types.ADD_REQUEST_TYPE_TO_REQUEST_GROUP';

export interface AddRequestTypeToRequestGroupPayload {
    isProjectSimplified: boolean;
    portalId: number;
    projectId: number;
    requestType: ReqTypesState;
    requestGroup: ReqGroupsState;
    requestTypesInRequestGroup: ReqTypesState[];
    requestTypesNotInRequestGroup: ReqTypesState[];
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface AddRequestTypeToRequestGroupAction {
    payload: AddRequestTypeToRequestGroupPayload;
    type: typeof ADD_REQUEST_TYPE_TO_REQUEST_GROUP;
}

export const addRequestTypeToRequestGroupAction = (
    payload: AddRequestTypeToRequestGroupPayload
): AddRequestTypeToRequestGroupAction => {
    return { payload, type: ADD_REQUEST_TYPE_TO_REQUEST_GROUP };
};

const ADD_REQUEST_TYPE_TO_REQUEST_GROUP_SUCCEEDED =
    'state.ui.request-types.ADD_REQUEST_TYPE_TO_REQUEST_GROUP_SUCCEEDED';

export interface AddRequestTypeToRequestGroupSucceededAction {
    type: typeof ADD_REQUEST_TYPE_TO_REQUEST_GROUP_SUCCEEDED;
}

interface AddRequestTypeToRequestGroupFailedPayload {
    message: string;
}

export const ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED =
    'state.ui.request-types.ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED';

export interface AddRequestTypeToRequestGroupFailedAction {
    payload: AddRequestTypeToRequestGroupFailedPayload;
    type: typeof ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED;
}

export const addRequestTypeToRequestGroupFailedAction = (
    message: string
): AddRequestTypeToRequestGroupFailedAction => ({
    payload: {
        message,
    },
    type: ADD_REQUEST_TYPE_TO_REQUEST_GROUP_FAILED,
});
