import type { ArticlesResponse } from 'rest/kb-article';
import type { ApolloErrorResponse } from 'epics/model/types';

export const FETCH_ARTICLES_SUCCESS = 'state.persisted.kb.articles.FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'state.persisted.kb.articles.FETCH_ARTICLES_FAILURE';

export interface FetchArticlesSuccess {
    type: typeof FETCH_ARTICLES_SUCCESS;
    payload: {
        response: ArticlesResponse;
        categoryId: string;
    };
}

export interface FetchArticlesFailure {
    type: typeof FETCH_ARTICLES_FAILURE;
    payload: {
        error: ApolloErrorResponse;
        portalId: number;
        categoryId: string;
    };
}

export const fetchArticlesSuccess = (response: ArticlesResponse, categoryId: string): FetchArticlesSuccess => ({
    type: FETCH_ARTICLES_SUCCESS,
    payload: {
        response,
        categoryId,
    },
});

export const fetchArticlesFailure = (
    error: ApolloErrorResponse,
    portalId: number,
    categoryId: string
): FetchArticlesFailure => ({
    type: FETCH_ARTICLES_FAILURE,
    payload: { error, portalId, categoryId },
});
