import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import * as bp from 'view/styles/breakpoints';
import * as mixins from 'view/styles/mixins';
import * as transitions from 'view/styles/transitions';
import { SIDEBAR_WIDTH_LARGE_VIEWPORT, SIDEBAR_HEIGHT_SMALL_VIEWPORT, EXPAND_TIME_MS } from '../styled';

export interface PusherProvided {
    onOpen: () => void;
    onClose: () => void;
}

const PusherContext = React.createContext<PusherProvided>({ onOpen: noop, onClose: noop });
export const PusherConsumer = PusherContext.Consumer;

interface SidebarPusherState {
    isOpen: boolean;
}

interface SidebarPusherProps {
    children: React.ReactNode;
    className?: string;
}

export default class SidebarPusher extends React.Component<SidebarPusherProps, SidebarPusherState> {
    state = {
        isOpen: false,
    };

    handleOpen = () => {
        this.setState({
            isOpen: true,
        });
    };

    handleClose = () => {
        this.setState({
            isOpen: false,
        });
    };

    render() {
        const { children, className } = this.props;
        const { isOpen } = this.state;

        return (
            <PusherContext.Provider value={{ onOpen: this.handleOpen, onClose: this.handleClose }}>
                {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                <ContainerPusher className={className} isOpen={isOpen}>
                    {children}
                </ContainerPusher>
            </PusherContext.Provider>
        );
    }
}

interface ContainerPusherProps {
    isOpen: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ContainerPusher = styled.div<ContainerPusherProps>`
    will-change: padding-right;
    transition: padding-right
        ${(props) => (props.isOpen ? transitions.timingFunctions.easeOut : transitions.timingFunctions.easeIn)}
        ${EXPAND_TIME_MS}ms;

    ${(props) =>
        mixins.applyIf(
            props.isOpen,
            // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            css`
                padding: ${SIDEBAR_HEIGHT_SMALL_VIEWPORT} 0 0 0;
            `
        )};

    ${bp.fromMedium.css<ContainerPusherProps>`
        ${(props) =>
            mixins.applyIf(
                props.isOpen,
                // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                css`
                    padding: 0 ${SIDEBAR_WIDTH_LARGE_VIEWPORT} 0 0;
                `
            )};
    `};
`;
