import FeatureGates, { FeatureGateEnvironment, PerimeterType } from '@atlaskit/feature-gate-js-client';
import { isFedRamp } from '@atlassian/atl-context';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import type { WindowFlagVars } from '../types';
import { getStatsigValuesFromMeta } from '../utils';

export function initialiseFeatureGates(vars: Partial<Pick<WindowFlagVars, 'statsigValues'>>) {
    if (!__SERVER__ && FeatureGates.initializeCalled()) {
        return Promise.resolve();
    }
    try {
        const cloudId = getMeta('tenant-id') ?? undefined;
        const atlassianAccountId = getMeta('ajs-atlassian-account-id');
        const region = getMeta('ajs-region');
        const environment =
            region !== undefined && region?.startsWith('prod')
                ? FeatureGateEnvironment.Production
                : FeatureGateEnvironment.Staging;

        return FeatureGates.initializeFromValues(
            {
                environment,
                perimeter: isFedRamp() ? PerimeterType.FEDRAMP_MODERATE : PerimeterType.COMMERCIAL,
                targetApp: 'jsm-portal_web',
                sdkKey:
                    environment === FeatureGateEnvironment.Production
                        ? 'client-NKfwskfcfrondp65Ar9Mfv9GNLywVcUdkBvK1mLpNK6'
                        : 'client-rRwj0kwrHSPVA9p8Gy6pMfyBouOlfD2DX6pMXJtx9y1',
            },
            {
                atlassianAccountId: atlassianAccountId !== null ? atlassianAccountId : undefined,
                tenantId: cloudId,
            },
            {},
            vars.statsigValues || getStatsigValuesFromMeta()
        );
    } catch (error) {
        return Promise.reject(error);
    }
}
