import * as React from 'react';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withCreateFireUIAnalytics } from '@atlassian/help-center-common-component/analytics/with-create-fire-ui-analytics';

export type Props = {
    actionSubjectId: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const InstrumentedLink: React.FunctionComponent<Props & WithAnalyticsEventsProps> = ({
    actionSubjectId,
    createAnalyticsEvent,
    ...anchorProps
}) => {
    // Disabling existing violations, should be fixed when revisited.
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...anchorProps} />;
};

export const HtmlAnchor = withCreateFireUIAnalytics<Props>('link', {
    onClick: () => ({ action: 'clicked' }),
})(InstrumentedLink);
