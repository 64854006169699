import { ASSISTANT_MESSAGE_STATUS } from 'unified-help-center-alpha/src/rest/converse';
import type { MessageStatus } from './types';
import { HelpSeekerMessageStatus } from './types';

export const parseJson = <T,>(value: unknown): T | undefined => {
    try {
        return typeof value === 'string' ? (JSON.parse(value) as T) : undefined;
    } catch (e) {
        return undefined;
    }
};

export const isMessageGenerated = (status: MessageStatus) => {
    if (!status) return true;
    if (status === HelpSeekerMessageStatus.IDLE) return true;
    return [ASSISTANT_MESSAGE_STATUS.IDLE, ASSISTANT_MESSAGE_STATUS.ABORTED_RESPONSE].indexOf(status) !== -1;
};
