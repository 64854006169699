import * as Sentry from '@sentry/browser';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
    UI_EVENT_TYPE,
    TRACK_EVENT_TYPE,
    SCREEN_EVENT_TYPE,
    OPERATIONAL_EVENT_TYPE,
} from '@atlassian/analytics-web-react';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';

export const reportAnalyticsEvent = (event: UIAnalyticsEvent) => {
    try {
        const { analyticsType, action, componentName, actionSubjectId, source } = [
            ...(event.context || []),
            event.payload,
        ].reduce((acc, curr) => ({ ...acc, ...curr }), {
            eventType: undefined,
            action: undefined,
            componentName: undefined,
            actionSubjectId: undefined,
            source: undefined,
        });

        // for screen events, source has the name of the screen

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-template-expressions
        const actionSubject = analyticsType === SCREEN_EVENT_TYPE ? `screen<${source}>` : componentName;

        if (analyticsType && action && actionSubject) {
            if (
                analyticsType !== UI_EVENT_TYPE &&
                analyticsType !== TRACK_EVENT_TYPE &&
                analyticsType !== OPERATIONAL_EVENT_TYPE &&
                analyticsType !== SCREEN_EVENT_TYPE
            ) {
                devLog(
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `An event was left out of Sentry breadcrumbs because it had an invalid analyticsType: ${analyticsType}.\nAllowed types: ${[
                        UI_EVENT_TYPE,
                        TRACK_EVENT_TYPE,
                        OPERATIONAL_EVENT_TYPE,
                        SCREEN_EVENT_TYPE,
                    ].join(', ')}`
                );

                return;
            }

            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const eventDescription = `${analyticsType} ${action} ${actionSubject}`;
            const eventDescriptionWithId = actionSubjectId
                ? // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  `${eventDescription}<${actionSubjectId}>`
                : eventDescription;

            // omit source for screen events because it's already about them!
            const eventMessage =
                source && analyticsType !== SCREEN_EVENT_TYPE
                    ? // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      `${eventDescriptionWithId} from source<${source}>`
                    : eventDescriptionWithId;

            // Scenario: a user clicks on a button with ID myButton on a screen with ID myScreen
            // Here's what's sent as a breadcrumb: ui clicked button<myButton> from source<myScreen>
            // When some data is missing, it's omitted, so we still get a good idea of what was going on.
            Sentry.addBreadcrumb({
                category: 'analytics',
                message: eventMessage,
                level: Sentry.Severity.Info,
            });
        }
    } catch (exception) {
        // eslint-disable-next-line no-console
        console.error('Error capturing analytics event as Sentry breadcrumb', exception);
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        Sentry.captureEvent({ exception, message: 'Error capturing analytics event as Sentry breadcrumb' });
    }
};
