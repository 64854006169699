import * as React from 'react';
import { isSnapvmRuntimeEnabled } from 'feature-flags';
import clientServerBridge from '@atlassian/help-center-common-util/client-server-bridge/client-server-bridge';

interface Props {
    className?: string;
}

export default class BodyClassName extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
        if (this.props.className) {
            const classes = this.props.className.split(' ');
            // IE 11 doesn't allow you to add multiple classes at once.
            classes.forEach((className) => {
                if (isSnapvmRuntimeEnabled()) {
                    clientServerBridge.addClassName('body', className);
                } else {
                    document.body.classList.add(className);
                }
            });
        }
    }

    componentWillUnmount() {
        if (this.props.className) {
            const classes = this.props.className.split(' ');
            // IE 11 doesn't allow you to add multiple classes at once.
            classes.forEach((className) => document.body.classList.remove(className));
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.className !== this.props.className) {
            if (prevProps.className) {
                const prevClasses = prevProps.className.split(' ');
                document.body.classList.remove(...prevClasses);
            }
            if (this.props.className) {
                const classes = this.props.className.split(' ');
                document.body.classList.add(...classes);
            }
        }
    }

    render() {
        return null;
    }
}
