// #reference /confluence/packages/confluence-frontend-server/src/google-translate-issue-workaround.js
import { trackError } from '@atlassian/help-center-common-util/analytics';

const ErrorType = Object.freeze({
    REMOVE_CHILD_ERROR: 'removeChildError',
    INSERT_BEFORE_ERROR: 'insertBeforeError',
});

export function handleDOMGoogleTranslateIssues() {
    if (typeof Node === 'function' && Node.prototype) {
        const originalRemoveChild = Node.prototype.removeChild;
        Node.prototype.removeChild = function (child) {
            if (child.parentNode !== this) {
                if (console) {
                    // eslint-disable-next-line no-console
                    console.error('Cannot remove a child from a different parent', child, this);
                    sendRemoveChildErrorAnalyticsEvent(child);
                }
                return child;
            }
            // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-return
            return originalRemoveChild.apply(this, arguments);
        };

        const originalInsertBefore = Node.prototype.insertBefore;
        Node.prototype.insertBefore = function (newNode, referenceNode) {
            if (referenceNode && referenceNode.parentNode !== this) {
                if (console) {
                    // eslint-disable-next-line no-console
                    console.error('Cannot insert before a reference node from a different parent', referenceNode, this);

                    sendInsertBeforeErrorAnalyticsEvent(referenceNode, newNode);
                }
                return newNode;
            }
            // eslint-disable-next-line prefer-rest-params,@typescript-eslint/no-unsafe-return
            return originalInsertBefore.apply(this, arguments);
        };
    }
}

/**
 * Send an analytics event when a 'removeChild' error occurs. This function helps to track the occurrence of this suppressed error.
 * @param {Node} childNode - The node that is attempted to be removed.
 */
function sendRemoveChildErrorAnalyticsEvent(childNode: Node) {
    const nodeData = {
        nodeToBeRemoved: extractNodeData(childNode),
    };
    sendAnalyticsEvent(ErrorType.REMOVE_CHILD_ERROR, nodeData);
}

/**
 * Send an analytics event when an 'insertBefore' error occurs.  This function helps to track the occurrence of this suppressed error.
 * @param {Node} referenceNode - The existing node in the DOM tree. The new node is intended to be inserted before this node.
 * @param {Node} newNode - The new node that is attempted to be inserted.
 */
function sendInsertBeforeErrorAnalyticsEvent(referenceNode: Node, newNode: Node) {
    const nodeData = {
        newNode: extractNodeData(newNode),
        referenceNode: extractNodeData(referenceNode),
    };
    sendAnalyticsEvent(ErrorType.INSERT_BEFORE_ERROR, nodeData);
}

function sendAnalyticsEvent(action: (typeof ErrorType)[keyof typeof ErrorType], nodeData: object) {
    trackError(
        'google.translate.issue.error',
        {
            ...nodeData,
            errorBoundaryId: 'root',
            suppressedError: action,
            errorBoundaryPackageName: 'unknown',
        },
        new Error(action)
    );
}

function extractNodeData(node: Node) {
    if (!node) return {};

    return {
        nodeName: node.nodeName,
        parentElementNodeName: node.parentElement?.nodeName,
        isConnected: node.isConnected,
    };
}
