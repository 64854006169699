import type { AjaxResponse } from 'rxjs';
import { put, get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import * as qs from 'query-string';
import type { AddParticipantsResponse } from 'rest/request-details';
import { contextPath } from '@atlassian/help-center-common-util/history';

import type { ParticipantsSearchUserResponse, ParticipantsSearchOrganizationResponse } from './types';

export const removeUsingAccountId = (requestKey: string, accountId: string, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/participants/${requestKey}/removeParticipant`;

    const body = {
        accountId,
    };

    const headers = {
        'Content-Type': 'application/json',
    };

    return (
        put(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => Observable.throw(e))
    );
};

export const removeOrganisation = (requestKey: string, organisationId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/participants/${requestKey}/removeOrganisation`;
    const body = {
        organisationId,
    };
    const headers = {
        'Content-Type': 'application/json',
    };

    return (
        put(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => Observable.throw(e))
    );
};

export const search = (
    requestKey: string,
    query: string = '',
    context: string = contextPath
): Promise<(ParticipantsSearchUserResponse | ParticipantsSearchOrganizationResponse)[]> => {
    const queryString: string = qs.stringify({
        q: query,
    });
    const uri = `${context}/rest/servicedesk/1/customer/participants/${requestKey}/search?${queryString}`;

    const headers = {
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        get(uri, headers)
            .toPromise()
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .then((ajaxResponse) => ajaxResponse.response)
            .catch((e) => Promise.reject(e))
    );
};

export const addParticipants = (
    requestKey: string,
    accountIds: string[],
    organizationIds: number[],
    emailAddresses: string[],
    context: string = contextPath
): Observable<AddParticipantsResponse> => {
    const uri = `${context}/rest/servicedesk/1/customer/participants/${requestKey}/add`;
    const body = {
        accountIds,
        organisationIds: organizationIds,
        emails: emailAddresses,
    };
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return put(uri, body, headers).map((ajaxResponse) => ajaxResponse.response);
};
