import * as React from 'react';
import { connect } from 'react-redux';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import { promiseWithRetries } from '@atlassian/help-center-common-util/retry';
import { reportError } from '../sentry';
import type { InjectedCoordinationClientProps } from './types';

export const CoordinationClientContext = React.createContext<InjectedCoordinationClientProps>({
    coordinationClient: undefined,
});

const CoordinationClientProvider: React.FC<React.PropsWithChildren<{ cloudId: string }>> = ({ cloudId, children }) => {
    const [coordinationClient, setCoordinationClient] =
        React.useState<InjectedCoordinationClientProps['coordinationClient']>(undefined);

    React.useEffect(() => {
        promiseWithRetries(() => import(/* webpackChunkName: "async-engagekit-ts" */ '@atlassiansox/engagekit-ts'))
            .then((module) => {
                setCoordinationClient(new module.CoordinationClient(cloudId, '/gateway/api'));
            })
            .catch((error) => {
                reportError(error);
            });
    }, [cloudId]);

    return (
        <CoordinationClientContext.Provider
            value={{
                coordinationClient,
            }}
        >
            {children}
        </CoordinationClientContext.Provider>
    );
};

export const withCoordinationClient = <TProps extends object>(
    InnerComponent: React.ComponentType<TProps>
): React.FC<Pick<TProps, Exclude<keyof TProps, 'coordinationClient'>>> => {
    const Component: React.FC<TProps> = (props) => {
        const { coordinationClient } = React.useContext(CoordinationClientContext);

        return <InnerComponent {...props} coordinationClient={coordinationClient} />;
    };

    return Component;
};

const mapStateToProps = () => {
    return {
        cloudId: getMeta('tenant-id'),
    };
};

export default connect(mapStateToProps)(CoordinationClientProvider);
