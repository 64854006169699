import * as React from 'react';
import { di } from 'react-magnetic-di';
import { useQueryParams } from 'view/search/common';

type EmptyFunction = () => void;

interface SidebarToggleProps {
    openSidebar?: EmptyFunction;
    closeSidebar?: EmptyFunction;
}

export const SidebarToggle: React.FunctionComponent<SidebarToggleProps> = ({ openSidebar, closeSidebar }) => {
    di(useQueryParams);
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { customize, experience } = useQueryParams();
    React.useEffect(() => {
        if (customize === 'true' && !!experience) {
            openSidebar?.();
        } else {
            closeSidebar?.();
        }
    }, [customize, openSidebar, closeSidebar, experience]);

    return null;
};
