import { fetchNothing } from './fetch-nothing';

const commonHeaders = {
    'X-Experience-Id': 'unified-help',
    'X-Product': 'jsm',
};

export const storeFeedback = async ({
    channelId,
    messageId,
    feedback,
    feedbackLabel,
}: {
    readonly channelId: string;
    readonly messageId?: string;
    readonly feedback: 'LIKE' | 'DISLIKE';
    readonly feedbackLabel?: string;
}) => {
    if (!messageId) {
        return;
    }
    await fetchNothing(`/gateway/api/assist/chat/v1/channel/${channelId}/message/${messageId}/feedback`, 'PUT', {
        headers: {
            ...commonHeaders,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback_value: feedback, feedback_message: feedbackLabel }),
    });
};
