import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl-next';
import type { BlockedAppsBannerState } from 'state/persisted/blocked-apps-banner/reducer';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { FORGE_REQUEST_DETAILS } from '@atlassian/help-center-common-component/forge-ui/constants';
import type { ForgePageName } from '@atlassian/help-center-common-component/forge-ui/types';
import { getLocalStorageItem, setLocalStorageItem } from '@atlassian/help-center-common-util/localStorage';
import { getLearnMoreUrl } from '@atlassian/help-center-common-util/url/url';
import i18n from './messages';

interface BlockedAppsBannerProps {
    isAtlassianManagedAccount: boolean;
    projectId: number;
    blockedAppsBannerState?: BlockedAppsBannerState;
    page: ForgePageName;
    accountId: string | undefined;
}

export const learnMoreUrltestId: string = 'sd.portal.blocked.apps.banner.learnMore';
export const doNotShow: string = 'sd.portal.blocked.apps.banner.doNotShow';

const getStyles = (page: ForgePageName) => {
    if (page === FORGE_REQUEST_DETAILS) {
        return {
            marginBottom: `${token('space.300', '24px')}`,
        };
    }

    return {
        marginTop: `${token('space.300', '24px')}`,
    };
};

export const getBlockedAppsBannerCacheKey = (accountId: string, projectId: number): string => {
    return `showBlockedAppsBanner-${projectId}-${accountId}`;
};

const BlockedAppsBannerComponent: React.FC<BlockedAppsBannerProps & WithAnalyticsEventsProps> = ({
    blockedAppsBannerState,
    page,
    projectId,
    isAtlassianManagedAccount,
    accountId,
}) => {
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const [showBannerForProject, setShowBannerForProject] = useState(false);
    const doNotShowClickHandler = useCallback(() => {
        if (accountId) {
            setLocalStorageItem(getBlockedAppsBannerCacheKey(accountId, projectId), 'false');
        }
        setShowBannerForProject(false);
    }, [accountId, projectId]);

    const learnMoreUrl = useMemo(() => {
        return getLearnMoreUrl();
    }, []);

    const isBannerCondtionsSatisfied: boolean =
        // @ts-ignore
        !!blockedAppsBannerState && !!blockedAppsBannerState[page] && isAtlassianManagedAccount && showBannerForProject;

    useEffect(() => {
        if (accountId) {
            const showBannerStatus = getLocalStorageItem(getBlockedAppsBannerCacheKey(accountId, projectId));
            if (showBannerStatus && showBannerStatus === 'false') {
                setShowBannerForProject(false);
            } else {
                setShowBannerForProject(true);
            }
        }
    }, [accountId, projectId]);

    useEffect(() => {
        if (isBannerCondtionsSatisfied) {
            const event = createAnalyticsEvent({
                analyticsType: UI_EVENT_TYPE,
                action: 'viewed',
                actionSubject: 'blockedAppBanner',
                attributes: {
                    page,
                    userType: isAtlassianManagedAccount ? 'isAtlassianManagedAccount' : 'customer',
                },
            });
            event.fire();
        }
    }, [createAnalyticsEvent, isBannerCondtionsSatisfied, accountId, projectId, isAtlassianManagedAccount, page]);

    if (isBannerCondtionsSatisfied) {
        return (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <div style={getStyles(page)}>
                <SectionMessage
                    actions={[
                        <SectionMessageAction testId={learnMoreUrltestId} href={learnMoreUrl} key={learnMoreUrltestId}>
                            {formatMessage(i18n.learnMore)}
                        </SectionMessageAction>,
                        <SectionMessageAction testId={doNotShow} onClick={doNotShowClickHandler} key={doNotShow}>
                            {formatMessage(i18n.doNotShow)}
                        </SectionMessageAction>,
                    ]}
                >
                    <p>{formatMessage(i18n.bannerDescription)}</p>
                </SectionMessage>
            </div>
        );
    }

    return <></>;
};

export default BlockedAppsBannerComponent;
