import type { PaperAppearance } from 'view/layout/paper';
import { RequestDetails } from 'view/request-details';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { PORTAL_ROUTE_PREFIX } from '../common/constants';

const route = {
    name: 'request-details',
    path: `${PORTAL_ROUTE_PREFIX}/:requestKey`,
    exact: true,
    component: RequestDetails,
};

const screenName = ScreenName.REQUEST_DETAILS;

const paperAppearance: PaperAppearance = 'xxwide';

const layoutProps = {
    paperAppearance,
    hideSearchButton: false,
    fadeInSearchModal: true,
};
export const requestDetailsRoute = createRouteWithLayout(route, screenName, layoutProps);
