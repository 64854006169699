import type { LazyComponent } from 'react-loosely-lazy';
import type { LoadingPosition } from '@atlassian/help-center-common-component/loading';
import { LooselyLoadableWrapper } from '@atlassian/help-center-common-component/loosely-loadable-wrapper';
import type { LooselyLoadableLoadingComponentProps } from '@atlassian/help-center-common-component/loosely-loadable-wrapper';

interface Options<TProps> {
    preload?: boolean;
    loadingPosition?: LoadingPosition;
    LoadingSkeleton?: React.ComponentType<LooselyLoadableLoadingComponentProps>;
    loader: LazyComponent<React.ComponentType<TProps>>;
}

/**
 * This will generically handle async loading of components with a
 * loading, timeout, and errored states. If you need more specific
 * handling use react-loosely-lazy directly.
 *
 * Use the preload option if you want the component to start loading
 * immediately.
 */
const withAsync = <TProps extends object>(options: Options<TProps>) => {
    const LoadableComponent = LooselyLoadableWrapper({
        loader: options.loader,
        LoadingSkeleton: options.LoadingSkeleton,
        loadingPosition: options.loadingPosition,
        timeout: 5000,
    });

    if (options.preload) {
        LoadableComponent.preload();
    }

    return LoadableComponent;
};

export default withAsync;
