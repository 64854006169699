// eslint-disable-next-line no-shadow
export enum ElementKeyMap {
    IMAGE = 'HelpLayoutImageAtomicElement',
    HEADING = 'HelpLayoutHeadingAtomicElement',
    PARAGRAPH = 'HelpLayoutParagraphAtomicElement',
    EDITOR = 'HelpLayoutEditorElement',
    SEARCH = 'HelpLayoutSearchAtomicElement',
    LINK_CARD = 'HelpLayoutLinkCardCompositeElement',
}

export const isMacOs = () => navigator.userAgent.includes('Mac');
