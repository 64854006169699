import * as React from 'react';
import { Coordination } from '@atlassiansox/engagekit-ts';
import type { InjectedCoordinationClientProps } from '@atlassian/help-center-common-util/coordination-client-provider';
import { withCoordinationClient } from '@atlassian/help-center-common-util/coordination-client-provider';
import type { Props } from './types';

export const EngagementCoordination = ({
    coordinationClient,
    messageId,
    children,
}: Props & InjectedCoordinationClientProps) => {
    if (!coordinationClient) {
        return null;
    }
    return (
        <Coordination client={coordinationClient} messageId={messageId}>
            {children}
        </Coordination>
    );
};

export default withCoordinationClient(EngagementCoordination);
