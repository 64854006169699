import type { FetchArticlesAction } from 'epics/model/kb-article';
import { FETCH_ARTICLES } from 'epics/model/kb-article';
import type {
    FetchArticleFailureAction,
    FetchArticleSuccessAction,
    FetchArticleAction,
    FetchCrossSiteArticleAction,
} from 'state/actions/article';
import {
    FETCH_ARTICLE,
    FETCH_ARTICLE_FAILURE,
    FETCH_ARTICLE_SUCCESS,
    FETCH_CROSS_SITE_ARTICLE,
} from 'state/actions/article';
import { FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILURE } from 'state/actions/fetch-kb-articles/fetch-kb-articles';
import type { FetchArticlesSuccess, FetchArticlesFailure } from 'state/actions/fetch-kb-articles/fetch-kb-articles';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { Article } from './types';
import type { ArticleResponse } from 'rest/models/types';
import type { PersistedError } from 'state/persisted/types';

export interface ArticleState {
    articles: {
        [categoryId: string]: {
            articles: Article[];
            error?: PersistedError;
        };
    };
    legacyArticles: {
        // FSD-3476 - TODO: Consolidate data + loading to apollo API.
        [articleId: string]: Article | PersistedError;
    };
}

const reduceArticle = (article: ArticleResponse): Article => ({
    editUrl: '',
    id: `${article.id}`,
    isFeatured: false,
    friendlyLastModified: '',
    lastModified: '',
    shareUrl: '',
    summaryExcerpt: '',
    title: article.title,
    titleExcerpt: article.title,
    viewCount: -1,
    viewUrl: article.url,
    projects: article.projects,
    space: article.space,
    hostname: article.hostname,
});

const getArticleId = (article: ArticleResponse): string => article.idInString;

const getInitialState = (): ArticleState => {
    const models = initialModel();

    return {
        articles: {},
        legacyArticles: models.kbPage
            ? {
                  [getArticleId(models.kbPage)]: reduceArticle(models.kbPage),
              }
            : {},
    };
};

const defaultState: ArticleState = getInitialState();

type Actions =
    | FetchArticleFailureAction
    | FetchArticleSuccessAction
    | FetchArticlesSuccess
    | FetchArticlesFailure
    | FetchArticlesAction
    | FetchArticleAction
    | FetchCrossSiteArticleAction;

export default function reducer(state: ArticleState = defaultState, action: Actions): ArticleState {
    switch (action.type) {
        case FETCH_ARTICLES: {
            const { categoryId } = action.payload;

            if (state.articles[categoryId] && 'error' in state.articles[categoryId]) {
                const newState = {
                    ...state,
                    articles: {
                        ...state.articles,
                    },
                };

                delete newState.articles[categoryId];

                return newState;
            }

            return state;
        }

        case FETCH_ARTICLES_SUCCESS: {
            const {
                categoryId,
                response: { results: articles },
            } = action.payload;

            return {
                ...state,
                articles: {
                    ...state.articles,
                    [categoryId]: {
                        articles: articles.map(({ featuredInCategories, ...article }) => ({
                            ...article,
                            isFeatured: !!featuredInCategories && featuredInCategories.includes(categoryId),
                        })),
                    },
                },
            };
        }

        case FETCH_ARTICLES_FAILURE: {
            const { portalId, categoryId, error: apolloError } = action.payload;

            return {
                ...state,
                articles: {
                    ...state.articles,
                    [categoryId]: {
                        articles: [],
                        error: {
                            error: {
                                status: apolloError.statusCode,
                                message: apolloError.errorMessage,
                                callToActionText: 'Go back',
                                callToActionUrl: `/portal/${portalId}`,
                            },
                        },
                    },
                },
            };
        }

        case FETCH_ARTICLE: {
            const { id } = action.payload.params;

            if (state.legacyArticles[id] && 'error' in state.legacyArticles[id]) {
                const newState = {
                    ...state,
                    legacyArticles: {
                        ...state.legacyArticles,
                    },
                };

                delete newState.legacyArticles[id];

                return newState;
            }

            return state;
        }

        case FETCH_ARTICLE_SUCCESS: {
            const articleId = action.payload.idInString;
            return {
                ...state,
                legacyArticles: {
                    ...state.legacyArticles,
                    [articleId]: reduceArticle(action.payload),
                },
            };
        }

        case FETCH_ARTICLE_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                legacyArticles: {
                    ...state.legacyArticles,
                    [action.payload.id]: {
                        error: {
                            // We really only care about the first error message
                            // grab it and throw the rest away
                            status: error.status,
                            message: (error.errorMessages || [])[0],
                            // Remove basename from the URL, we don't need it as the basename
                            // is already set inside react router.
                            // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                            callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                            callToActionText: error.nextActionDisplayText || '',
                        },
                    },
                },
            };
        }

        case FETCH_CROSS_SITE_ARTICLE: {
            const { articleId: id } = action.payload;

            if (state.legacyArticles[id] && 'error' in state.legacyArticles[id]) {
                const newState = {
                    ...state,
                    legacyArticles: {
                        ...state.legacyArticles,
                    },
                };

                delete newState.legacyArticles[id];

                return newState;
            }

            return state;
        }

        default:
            return state;
    }
}
