import type { AjaxResponse } from 'rxjs';
import { Observable } from 'rxjs';
import { put, doDelete } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const subscribe = (requestId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/notification/subscription/${requestId}`;

    return (
        put(uri)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};

export const unsubscribe = (requestId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/notification/subscription/${requestId}`;

    return (
        doDelete(uri)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};
