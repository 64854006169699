import * as React from 'react';
import { connect } from 'react-redux';
import type { Match, Location, Route } from 'react-resource-router';
import type { Context } from 'state/actions/context';
import { clearContextAction, updateContextAction } from 'state/actions/context';

interface RouteProps {
    match: Match;
    location: Location;
    route: Route;
}

type ContextAwareProps = RouteProps & {
    updateContextAction: AnyFunction1<Context>;
    clearContextAction: AnyFunction;
};

export class ContextSetter extends React.Component<ContextAwareProps> {
    updateContextAction(props: ContextAwareProps) {
        const { location, match, route } = props;
        const pathParams = (match && match.params) || {};
        const pathname = location && location.pathname;
        const routeName = route.name;
        props.updateContextAction({ pathParams, pathname, routeName });
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillUpdate(nextProps: ContextAwareProps) {
        this.updateContextAction(nextProps);
    }

    // We use componentDidMount (instead of componentWillMount) here because we need the mount method to be
    // called after all the previous ContextSetters have been unmounted.
    componentDidMount() {
        this.updateContextAction(this.props);
    }

    componentWillUnmount() {
        this.props.clearContextAction();
    }

    render() {
        return null;
    }
}

export default connect(null, { updateContextAction, clearContextAction })(ContextSetter);
