import { defineMessages } from 'react-intl-next';

export default defineMessages({
    authPageMessage: {
        defaultMessage: `You don't have permission to view this page.`,
        description: 'Message text for unauthorized page of portal',
        id: 'conversation.home.page.un.auth.action.message.nonFinal',
    },
    callToActionUrlText: {
        defaultMessage: 'Go to portal',
        description: 'Call to action text for unauthorized page of portal',
        id: 'conversation.home.page.un.auth.action.text.nonFinal',
    },
});
