import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const REMOVE_REQUEST_GROUP = 'epic.request-groups.REMOVE_REQUEST_GROUP' as const;
const REMOVE_REQUEST_GROUP_FAILED = 'epic.request-groups.REMOVE_REQUEST_GROUP_FAILED' as const;

interface RemoveRequestGroupPayload {
    portalId: number;
    deletedId: number;
    onRemoveGroupSuccess?: () => void;
    onRemoveGroupError?: () => void;
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface RemoveRequestGroupAction {
    payload: RemoveRequestGroupPayload;
    type: typeof REMOVE_REQUEST_GROUP;
}

export interface RemoveRequestGroupFailedAction {
    type: typeof REMOVE_REQUEST_GROUP_FAILED;
}

export const removeRequestGroupAction = (payload: RemoveRequestGroupPayload): RemoveRequestGroupAction => {
    return { payload, type: REMOVE_REQUEST_GROUP };
};

export const removeRequestGroupFailedAction = (): RemoveRequestGroupFailedAction => {
    return { type: REMOVE_REQUEST_GROUP_FAILED };
};
