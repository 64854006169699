import { isAdvancedHelpCenter, isHelpCentersManagement } from '@atlassian/help-center-common-util/advanced-help-center';
import { getHelpCenterAri } from '@atlassian/help-center-common-util/meta';

export const getHelpCenterAnalyticsData = () => {
    const helpCenterAnalyticsData = !isHelpCentersManagement()
        ? {
              helpCenterType: isAdvancedHelpCenter() ? 'custom' : 'default',
              helpCenterAri: getHelpCenterAri(),
          }
        : undefined;
    return helpCenterAnalyticsData;
};
