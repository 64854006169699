import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import SmoothCollapse from 'react-smooth-collapse';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { IframeWidthObserverFallbackWrapper, WidthObserver } from '@atlaskit/width-detector';
import { FakeButton } from '@atlassian/help-center-common-component/fake-button';
import { getElementHeight } from '@atlassian/help-center-common-util/dom';
import messages from './messages';

interface FieldDescriptionProps extends WrappedComponentProps {
    children: string | React.ReactNode;
    id: string;
}

interface FieldDescriptionState {
    shouldCollapse: boolean;
    isOpen: boolean;
}

/**
 * Will collapse a description if over two lines and show a "show more/less" button.
 */
export class FieldDescription extends React.PureComponent<FieldDescriptionProps, FieldDescriptionState> {
    state: FieldDescriptionState = {
        shouldCollapse: false,
        isOpen: false,
    };

    helpTextRef = React.createRef<HTMLElement>();

    componentDidMount() {
        this.checkIfShouldCollapse();
    }

    componentDidUpdate(prevProps: FieldDescriptionProps) {
        if (prevProps.children !== this.props.children) {
            this.checkIfShouldCollapse();
        }
    }

    checkIfShouldCollapse = () => {
        if (this.helpTextRef.current && getElementHeight(this.helpTextRef.current) > minDescriptionHeight) {
            this.setState({
                shouldCollapse: true,
            });
        } else if (this.state.shouldCollapse) {
            this.setState({
                shouldCollapse: false,
            });
        }
    };

    onToggle = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault();
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    renderHelpText = () => {
        const { children, id } = this.props;
        if (typeof children === 'string') {
            return <HelpText id={id} innerRef={this.helpTextRef} dangerouslySetInnerHTML={{ __html: children }} />;
        }
        return (
            <HelpText id={id} innerRef={this.helpTextRef}>
                {children}
            </HelpText>
        );
    };

    render() {
        const { intl } = this.props;
        const { shouldCollapse, isOpen } = this.state;

        return (
            <>
                {/* IframeWidthObserverFallbackWrapper is needed for Edge 18. It can be removed when we no longer support Edge 18, as it does nothing in modern browsers. */}
                <IframeWidthObserverFallbackWrapper>
                    <WidthObserver setWidth={this.checkIfShouldCollapse} />
                </IframeWidthObserverFallbackWrapper>
                {shouldCollapse ? (
                    <StyledFakeButton onClick={this.onToggle}>
                        <SmoothCollapse expanded={isOpen} collapsedHeight={`${minDescriptionHeight}px`}>
                            {this.renderHelpText()}
                        </SmoothCollapse>

                        <ButtonText>{intl.formatMessage(isOpen ? messages.showLess : messages.showMore)}</ButtonText>
                    </StyledFakeButton>
                ) : (
                    this.renderHelpText()
                )}
            </>
        );
    }
}

export default injectIntl(FieldDescription);

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const HelpText = styled.div`
    ${fonts.xsmall};
    margin-top: ${token('space.050', '4px')};
    color: ${token('color.text.subtlest', colors.N200)};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ButtonText = styled.span`
    ${fonts.xsmall};
    color: ${token('color.text.subtlest', colors.N200)};
    font-weight: 500;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledFakeButton = styled(FakeButton)`
    display: block;
`;

export const minDescriptionHeight = grid.multiple(4.5).unitless;
