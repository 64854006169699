import type { ComponentType } from 'react';
import React from 'react';
import { usePathParam, useQueryParam, useRouter } from 'react-resource-router';
import { useClearConversation, useHandOffConversation } from 'unified-help-center-alpha/src/controllers/routing';
import { useActions as useRoutingActions } from 'unified-help-center-alpha/src/store/routing';
import { withRoutingStateContainer } from 'unified-help-center-alpha/src/store/routing/container-wrapper';
import { useIsoMorphicLayoutEffect } from '@atlassian/help-center-common-util/hooks';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useVirtualAgentConversationV2Provider } from '../../services/conversation-service';
import { ChannelType } from '../../services/conversation-service/types';
import { ConversationStateContainer } from './container';
import { useActions as useVirtualAgentActions } from '.';

export const withHelpCenterConversationStateContainer = <TProps extends object>(
    WrappedComponent: ComponentType<TProps>
) => {
    const WithConversationStateContainer: React.FC<TProps & { contextId: string }> = (props) => {
        const { contextId } = props;
        const [isTestConversationParam] = useQueryParam('isTestConversation');
        const [portalId] = usePathParam('portalId');
        const isTestConversation = isTestConversationParam === 'true';

        const { handleHandOff } = useHandOffConversation();
        const conversationProvider = useVirtualAgentConversationV2Provider({
            isTestConversation,
            contextId,
            cloudId: getCloudId(),
            channelType: portalId ? ChannelType.Portal : ChannelType.HelpCenter,
        });

        return (
            <ConversationStateContainer
                conversationProvider={conversationProvider}
                contextId={contextId}
                handleHandOff={handleHandOff}
                isTestConversation={isTestConversation}
                isGlobal
            >
                <GlobalConversationStateManager />
                <WrappedComponent {...props} />
            </ConversationStateContainer>
        );
    };

    return withRoutingStateContainer(WithConversationStateContainer);
};

/*
 * With react-sweet-state, we can not persistence the state of the store when there is no consumer, in this case, we have to
 * use a global state to preserve the state of the store when the consumer is unmounted, and use this component to reset
 * the state of the store when the user navigates away from the conversation page.
 */
const GlobalConversationStateManager = () => {
    const [routerState] = useRouter();
    const { resetState } = useRoutingActions();
    const { clearConversation } = useClearConversation();
    const { resetState: resetVAState } = useVirtualAgentActions();

    useIsoMorphicLayoutEffect(() => {
        if (routerState.location.pathname !== '/conversation') {
            clearConversation();
            resetState();
            resetVAState();
        }
    }, [routerState.location.pathname, resetState, resetVAState, clearConversation]);

    return null;
};
