import * as React from 'react';

export const FeatureFlagsContext = React.createContext<FeatureFlags>({});

export interface FeatureFlags {
    [key: string]: boolean;
}

interface Props {
    featureFlags: FeatureFlags;
    children: React.ReactNode;
}

const FeatureFlagProvider: React.FunctionComponent<Props> = ({ featureFlags, children }) => (
    <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>
);

export default FeatureFlagProvider;
