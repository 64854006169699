import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { removeUsingAccountId } from 'rest/participants';
import type { HandleAjaxError, ShowErrorFlag } from 'state/actions/flags';
import { handleAjaxError, showErrorFlag } from 'state/actions/flags';
import { REMOVE_PARTICIPANT, removeParticipantSuccess } from 'state/actions/request-details';
import type { RemoveParticipant, RemoveParticipantSuccess } from 'state/actions/request-details';
import messages from './messages';

export const removeParticipantEpic: Epic<
    RemoveParticipant,
    RemoveParticipantSuccess | HandleAjaxError | ShowErrorFlag
> = (action$) => {
    return action$.ofType(REMOVE_PARTICIPANT).mergeMap((removeParticipantAction) => {
        const { requestKey, accountId } = removeParticipantAction.payload;
        return removeUsingAccountId(requestKey, accountId)
            .map(() => {
                return removeParticipantSuccess(requestKey, accountId);
            })
            .catch((error: AjaxError) => {
                const isPermissionDenied = error.status === 403;
                return Observable.of(
                    isPermissionDenied
                        ? showErrorFlag(messages.permissionDeniedErrorTitle, messages.permissionDeniedErrorDescription)
                        : handleAjaxError(error)
                );
            });
    });
};
