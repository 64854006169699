import React from 'react';
import { getHelpSeekerTypeAttributes } from 'rest/help-seeker-type';
import type { HelpSeekerTypeResponse } from 'rest/help-seeker-type/help-seeker-type';
import { AtlassianHelpConfigContext } from 'view/meta-data-provider/meta-data-provider';
import { AnalyticsContext } from '@atlassian/analytics-web-react';
import { helpSeekerTypeAttributeValue } from '../../../rest/help-seeker-type';

export const HelpSeekerTypeProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [helpSeekerTypeAttributes, setHelpSeekerTypeAttributes] = React.useState<HelpSeekerTypeResponse>({
        isUserDomainSameAsAdmin: helpSeekerTypeAttributeValue.UNIDENTIFIED,
        isUserDomainInApprovedDomains: helpSeekerTypeAttributeValue.UNIDENTIFIED,
    });
    const config = React.useContext(AtlassianHelpConfigContext);

    const constructHelpSeekerTypeResponse = (
        isUserDomainSameAsAdmin: string,
        isUserDomainInApprovedDomains: string
    ) => ({
        isUserDomainSameAsAdmin,
        isUserDomainInApprovedDomains,
    });

    const evaluateHelpSeekerTypeAttributes = React.useCallback(() => {
        if (config.accountType === 'customer') {
            getHelpSeekerTypeAttributes().subscribe((response) => {
                setHelpSeekerTypeAttributes(response);
            });
        } else if (config.accountType === 'atlassian') {
            setHelpSeekerTypeAttributes(
                constructHelpSeekerTypeResponse(helpSeekerTypeAttributeValue.YES, helpSeekerTypeAttributeValue.YES)
            );
        } else {
            setHelpSeekerTypeAttributes(
                constructHelpSeekerTypeResponse(helpSeekerTypeAttributeValue.NO, helpSeekerTypeAttributeValue.NO)
            );
        }
    }, [config]);

    React.useEffect(() => {
        evaluateHelpSeekerTypeAttributes();
    }, [evaluateHelpSeekerTypeAttributes]);

    return <AnalyticsContext attributes={helpSeekerTypeAttributes}>{children}</AnalyticsContext>;
};
