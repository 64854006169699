import { NotFoundPage } from 'view/app/routes';
import { RouteName, ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { ViewCustomPage } from '@atlassian/help-center-custom-pages';

export const getViewCustomPageRoute = createRouteWithLayout(
    {
        name: RouteName.VIEW_CUSTOM_PAGE,
        path: '/page/:id/:title?',
        exact: true,
        component: ViewCustomPage,
    },
    ScreenName.VIEW_CUSTOM_PAGE,
    {
        paperAppearance: 'none',
        hideSearchButton: false,
        fadeInSearchModal: true,
        hideBanner: true,
    }
);

export const getEditCustomPageRoute = createRouteWithLayout(
    {
        name: RouteName.EDIT_CUSTOM_PAGE,
        path: '/page/edit/:id',
        exact: true,
        component: NotFoundPage,
    },
    ScreenName.EDIT_CUSTOM_PAGE,
    {
        paperAppearance: 'none',
        hideSearchButton: false,
        fadeInSearchModal: true,
        hideBanner: true,
        removeHeader: true,
    }
);
