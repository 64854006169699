import * as React from 'react';
import * as querystring from 'query-string';
import type { RouteContext } from 'react-resource-router';
import { RouterSubscriber } from 'react-resource-router';
import { OnboardingProvider } from 'view/onboarding';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { ContextSetter } from '@atlassian/help-center-common-component/context';
import { PathParamsProvider } from '@atlassian/help-center-common-component/with-path-params';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { ScreenErrorBoundary } from '../screen-error-boundary';
import { withIgnoreQueryParamUpdates } from '../with-ignore-query-param-updates';

interface CommonProps {
    children: JSX.Element;
    path: string | undefined;
}

interface Props<TProps extends CommonProps> {
    // We don't care what props the input component takes.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentClass<any> | React.FunctionComponent<any>;
    layoutProps?: Omit<TProps, 'children' | 'path'>;
    screenName?: ScreenName;
    reRenderOnQueryParameterChanges?: string[];
    path?: string;
    renderError?: () => React.ReactNode;
}

/**
 * This will return a component which will pass the Layout and children into a <Route />.
 * It will capture all path params and set them into redux, as well as providing them to withPathParams() higher order component.
 * @param Layout Layout component we're wrapping.
 */
function withLayoutRoute<TProps extends CommonProps>(Layout: React.ComponentType<TProps>) {
    type InnerProps = Readonly<Props<TProps>>;

    return class LayoutRoute extends React.Component<InnerProps> {
        // Create an instance Component to pass down react router props to ChildrenComponent as well block
        // any extraneous rendering from query params updating.
        LayoutWithChildren = withIgnoreQueryParamUpdates(
            (innerRouteProps: RouteContext) => {
                const { renderError, layoutProps, path, screenName, component: ChildrenComponent } = this.props;
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const query = querystring.parse(innerRouteProps.location.search);
                const component = (
                    <PathParamsProvider params={innerRouteProps.match.params}>
                        {/* Suppressing existing violation. Please fix this. */}
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                        <OnboardingProvider query={query}>
                            <Layout {...(layoutProps as TProps)} path={path}>
                                <ScreenErrorBoundary screenName={screenName} renderError={renderError}>
                                    <ChildrenComponent {...innerRouteProps} />
                                </ScreenErrorBoundary>
                            </Layout>
                        </OnboardingProvider>
                        <ContextSetter {...innerRouteProps} />
                    </PathParamsProvider>
                );

                return screenName ? (
                    <AnalyticsScreen screenName={screenName} screenId={innerRouteProps.match.url}>
                        {component}
                    </AnalyticsScreen>
                ) : (
                    component
                );
            },
            () => this.props.reRenderOnQueryParameterChanges || []
        );

        render() {
            const LayoutWithChildren = this.LayoutWithChildren;
            return (
                <RouterSubscriber>
                    {(routerState) => {
                        return <LayoutWithChildren {...routerState} />;
                    }}
                </RouterSubscriber>
            );
        }
    };
}

export default withLayoutRoute;
