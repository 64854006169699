import type { Reducer, ReducersMapObject } from 'redux';
import { combineReducers } from 'redux';
import type { ApprovalListUIState } from './approval-list';
import { approvalistReducer } from './approval-list';
import type { CompleteSignupUiState } from './complete-signup';
import { completeSignupUiReducer } from './complete-signup';
import type { ContextState } from './context';
import { contextReducer } from './context';
import type { FeaturedAndSortedPortalsUIState } from './featured-and-sorted-portals';
import { featuredAndSortedPortalsReducer } from './featured-and-sorted-portals';
import type { FeedbackCollectorUIState } from './feedback-collector';
import { feedbackCollectorReducer } from './feedback-collector';
import type { FlagsUIState } from './flags';
import { flagsReducer } from './flags';
import type { HelpCenterPreviewState } from './help-center-preview';
import { helpcenterPreviewReducer } from './help-center-preview';
import type { InvitedSignUpSubmitState } from './invited-sign-up';
import { invitedSignUpUiReducer } from './invited-sign-up';
import type { LoginUIState } from './login';
import { loginUiReducer } from './login';
import type { LoginAnnouncementsUIState } from './login-announcements';
import { loginAnnouncementsReducer } from './login-announcements';
import type { PortalUIState } from './portal';
import { portalReducer } from './portal';
import type { PortalCustomisationUIState } from './portal-customisation';
import { portalCustomisationReducer } from './portal-customisation';
import type { RequestDetailsUIState } from './request-details';
import { requestDetailsReducer } from './request-details';
import type { RequestListUIState } from './request-list';
import { requestListReducer } from './request-list';
import type { RequestListFilterUIState } from './request-list-filters';
import type { RequestsColumnSettingsUIState } from './requests-column-settings/types';
import { resendVerifyReducer, type ResendVerifyEmailState } from './resend-verify-email';
import type { ResetPasswordUIState } from './reset-password';
import { resetPasswordReducer } from './reset-password';
import { searchReducer } from './search';
import type { SearchState } from './search';
import type { CheckEmailUIState } from './sign-up-check-email';
import { checkEmailUiReducer } from './sign-up-check-email';
import { topicsReducer } from './topics';
import type { topicsUIState } from './topics/reducer';
import type { UserProfileUIState } from './user-profile';
import { userProfileReducer } from './user-profile';

export interface StaticUIState {
    context: ContextState;
    search: SearchState;
    helpCenterPreview: HelpCenterPreviewState;
    portalCustomisation: PortalCustomisationUIState;
    requestDetails: RequestDetailsUIState;
    requestList: RequestListUIState;
    approvalList: ApprovalListUIState;
    flags: FlagsUIState;
    userProfile: UserProfileUIState;
    login: LoginUIState;
    loginAnnouncements: LoginAnnouncementsUIState;
    invitedSignUp: InvitedSignUpSubmitState;
    completeSignup: CompleteSignupUiState;
    resetPassword: ResetPasswordUIState;
    signUpCheckEmail: CheckEmailUIState;
    featuredAndSortedPortals: FeaturedAndSortedPortalsUIState;
    feedbackCollector: FeedbackCollectorUIState;
    portals: PortalUIState;
    resendVerifyEmail: ResendVerifyEmailState;
    topics: topicsUIState;
}

export interface UIState extends StaticUIState {
    requestsColumnSettings?: RequestsColumnSettingsUIState;
    requestListFilters?: RequestListFilterUIState;
}

const reducerCollection = {
    context: contextReducer,
    search: searchReducer,
    helpCenterPreview: helpcenterPreviewReducer,
    portalCustomisation: portalCustomisationReducer,
    requestDetails: requestDetailsReducer,
    requestList: requestListReducer,
    approvalList: approvalistReducer,
    flags: flagsReducer,
    userProfile: userProfileReducer,
    login: loginUiReducer,
    loginAnnouncements: loginAnnouncementsReducer,
    invitedSignUp: invitedSignUpUiReducer,
    resetPassword: resetPasswordReducer,
    completeSignup: completeSignupUiReducer,
    signUpCheckEmail: checkEmailUiReducer,
    featuredAndSortedPortals: featuredAndSortedPortalsReducer,
    feedbackCollector: feedbackCollectorReducer,
    portals: portalReducer,
    resendVerifyEmail: resendVerifyReducer,
    topics: topicsReducer,
};

export type AsyncUIReducers = {
    [property in OptionalKeys<UIState>]?: Reducer<NonNullable<UIState[property]>>;
};

export const getCombinedUIReducer = (asyncReducers: AsyncUIReducers) =>
    combineReducers({
        ...reducerCollection,
        ...asyncReducers,
    } as ReducersMapObject);

export default combineReducers<UIState>(reducerCollection);
