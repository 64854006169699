import * as React from 'react';
import type { ExperienceName } from '@atlassian/help-center-common-component/constants';
import Experience from './experience';

export { Experience };

export const withExperience =
    (experienceName: ExperienceName) =>
    <TComponent extends React.ComponentType<ExtractProps<TComponent>>>(WrappedComponent: TComponent) =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: ExtractProps<TComponent>) => {
        const Component = WrappedComponent as React.ComponentType<ExtractProps<TComponent>>;
        return (
            <Experience name={experienceName}>
                {/* @ts-ignore TypeScript upgrade 4.7.4 */}
                <Component {...props} />
            </Experience>
        );
    };
