import type { Client } from 'graphql-ws';
import { createClient } from 'graphql-ws';
import {
    isSSR,
    getAggSubscriptionEndpoint,
    WS_CLIENT_KEEP_ALIVE,
    WS_CLIENT_RETRIES,
} from '../subscription-constants/subscription-constants';
let client: Client | null = null;
export const getClient = () => {
    if (isSSR()) return null;
    if (client == null) {
        client = createClient({
            url: getAggSubscriptionEndpoint(),
            lazy: true,
            keepAlive: WS_CLIENT_KEEP_ALIVE,
            retryAttempts: WS_CLIENT_RETRIES,
            connectionParams: {
                'atl-client-name': 'jira-frontend',
            },
        });
    }
    return client;
};
export const cleanUpClient = () => {
    client = null;
};
