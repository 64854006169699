import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { fetchConnectFragmentsAction } from 'state/actions/connect-addon';
import { mapConnectDispatchToPropsBanner } from 'state/persisted/blocked-apps-banner/reducer';
import { getConnectAddonPanels } from 'state/selectors/connect-addon';
import { getProjectId } from 'state/selectors/portal';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';

import type { Props } from './connect-fragment';
import { ConnectFragment } from './connect-fragment';

const selector = createSelector(
    (state: State, componentProps: Props) => {
        const { page, location, portalId, requestTypeId, requestKey } = componentProps;

        const projectId = state ? getProjectId(state, portalId) : undefined;
        const connectFragments = getConnectAddonPanels({
            state,
            location,
            portalId,
            requestTypeId,
            requestKey,
            pageName: page,
        });

        return {
            connectFragments,
            projectId,
        };
    },
    (props) => props
);

const ConnectFragmentWithAnalytics = withAnalyticsContext({ componentName: 'ConnectFragment' })(
    withAnalyticsEvents({})(ConnectFragment)
);

export default connect(selector, {
    fetchConnectFragments: fetchConnectFragmentsAction,
    ...mapConnectDispatchToPropsBanner,
})(ConnectFragmentWithAnalytics);
