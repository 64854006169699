import {
    updateAnnouncementSideBarExperienceId,
    updateLoginAnnouncementSideBarExperienceId,
    updateThemeSideBarExperienceId,
} from '@atlassian/help-center-common-util/analytics/ufo';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const updateAnnouncementSideBarExperience = new UFOExperience(updateAnnouncementSideBarExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const updateLoginAnnouncementSideBarExperience = new UFOExperience(updateLoginAnnouncementSideBarExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const updateThemeSideBarExperience = new UFOExperience(updateThemeSideBarExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
