import type { Epic } from 'epics/rxjs';
import { Observable } from 'rxjs/Observable';
import * as actions from 'state/actions/search';
import { historyObservable } from '@atlassian/help-center-common-util/history';
import * as qs from '@atlassian/help-center-common-util/history-qs';

export const context = 'from-location';

const epic: Epic = (_, store) => {
    return historyObservable.mergeMap(({ action }) => {
        const queryParams = qs.getAll();
        const hasQueryParams = queryParams && queryParams.hasOwnProperty && queryParams.hasOwnProperty('q');

        if (action === 'POP') {
            if (hasQueryParams) {
                return Observable.of(
                    actions.search(queryParams.q, {
                        context,
                        time: queryParams.time ? +queryParams.time : null,
                    })
                );
            }
        }

        if (action === 'PUSH') {
            if (hasQueryParams) {
                return Observable.of(
                    actions.updateSearch(queryParams.q, {
                        context,
                        time: queryParams.time ? +queryParams.time : null,
                    })
                );
            }
        }

        if (!hasQueryParams) {
            const state = store.getState();
            if (state.ui.search.shown) {
                return Observable.of(
                    actions.showSearch(false, {
                        context,
                    })
                );
            }

            return Observable.empty();
        }

        return Observable.empty();
    });
};

export default epic;
