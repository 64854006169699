import type { AjaxError } from 'rxjs';
import type {
    FetchResendVerifyEmailAction,
    FetchResendVerificationSuccess,
    FetchResendVerificationFailure,
} from 'state/actions/resend-verify-email';

export const ResendStatus = {
    NONE: 'NONE',
    RESENDING: 'RESENDING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED',
} as const;

export type ResendStatus = (typeof ResendStatus)[keyof typeof ResendStatus];

export interface ResendVerifyEmailState {
    errorStatus?: Pick<AjaxError, 'status'>;
    value: ResendStatus;
}

export type HandledActions =
    | FetchResendVerifyEmailAction
    | FetchResendVerificationSuccess
    | FetchResendVerificationFailure;
