import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { di } from 'react-magnetic-di';
import type { PortalSummary } from 'rest/featured-and-sorted-portals';
import type { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import { RecentForms } from 'view/help-center/recent-forms';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import * as mixins from 'view/styles/mixins';
import { sizes } from 'view/styles/sizes-viewport';
import { token } from '@atlaskit/tokens';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { FeaturedAndSortedPortalsCardList } from '../../featured-and-sorted-portals-card-list';
import type { SortBy } from 'rest/featured-and-sorted-portals/types';

export interface Props {
    defaultViewPortals: PortalSummary[];
    expandedViewPortals: PortalSummary[];
    sortBy: SortBy;
    total: number;
    requestTypeSuggestions: RequestTypeSuggestion[];
    isExpandedViewLoading: boolean;
    featuredPortalsCount: number;
    isCustomizing: boolean;
    fetchFeaturedAndSortedPortals: typeof fetchFeaturedAndSortedPortalsModel;
}

export default function FeaturedAndSortedPortals(props: Props) {
    di(useIsPageInEditMode);
    const {
        defaultViewPortals,
        expandedViewPortals,
        sortBy,
        total,
        requestTypeSuggestions,
        isExpandedViewLoading,
        featuredPortalsCount,
        isCustomizing,
        fetchFeaturedAndSortedPortals,
    } = props;
    const showRecentForms = requestTypeSuggestions.length > 0;
    const [isPageInEditMode] = useIsPageInEditMode();

    return (
        <>
            <FireScreenEventOnMount />
            <FeaturedAndSortedPortalsCardList
                defaultViewPortals={defaultViewPortals}
                expandedViewPortals={expandedViewPortals}
                sortBy={sortBy}
                total={total}
                fetchFeaturedAndSortedPortals={fetchFeaturedAndSortedPortals}
                isExpandedViewLoading={isExpandedViewLoading}
                featuredPortalsCount={featuredPortalsCount}
                isSidebarOpen={isCustomizing}
                isPageInEditMode={isPageInEditMode}
            />
            {showRecentForms && (
                <RecentFormContainer>
                    <RecentForms suggestedRequestTypes={requestTypeSuggestions} />
                </RecentFormContainer>
            )}
        </>
    );
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RecentFormContainer = styled.div`
    ${mixins.borderBox};
    max-width: ${sizes.small}px;
    padding: 0 ${token('space.600', '48px')};
    margin: 0 auto;
    width: 100%;
`;
