import { isEmpty } from 'lodash';

import type { PortalAnnouncementTranslation, PortalAnnouncementTranslations, PortalAnnouncement } from 'rest/portal';
import {
    FETCH_PORTAL_MODEL,
    FETCH_PORTAL_SUCCESS,
    FETCH_PORTAL_FAILURE,
    UPDATE_PORTAL_ANNOUNCEMENT_MODEL,
    UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS,
    UPDATE_PORTAL_ANNOUNCEMENT_FAILURE,
    UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE,
} from 'state/actions/portal';

import { initialModel } from '@atlassian/help-center-common-util/model';

import type { HandledActions, PortalUIState } from './types';

export const getInitialPortalAnnouncementUIState = (): PortalUIState => {
    const initialModelState = initialModel();

    return {
        portalAnnouncementsResponse: initialModelState?.portal?.portalAnnouncement
            ? initialModelState?.portal?.portalAnnouncement
            : {
                  canAdministerProject: false,
                  canEditAnnouncement: false,
                  defaultLanguageDisplayName: '',
                  defaultLanguageHeader: '',
                  defaultLanguageMessage: '',
                  isUsingLanguageSupport: false,
                  portalId: 0,
                  portalProjectKey: '',
                  userLanguageHeader: '',
                  userLanguageMessageWiki: '',
                  translations: {},
              },
        isLoading: false,
        isSaveInProgress: false,
        isSaveFailed: false,
    };
};

const doesContainLocaleDisplayName = (
    translatedPortalAnnouncement:
        | PortalAnnouncementTranslation
        | Pick<PortalAnnouncementTranslation, 'header' | 'message'>
): translatedPortalAnnouncement is PortalAnnouncementTranslation =>
    'localeDisplayName' in translatedPortalAnnouncement && !!translatedPortalAnnouncement.localeDisplayName;

export const defaultState: PortalUIState = getInitialPortalAnnouncementUIState();

export const reduceTranslatedPortalAnnouncements = (
    payload:
        | Record<string, Pick<PortalAnnouncementTranslation, 'header' | 'message'> | PortalAnnouncementTranslation>
        | undefined,
    state: PortalUIState
): PortalAnnouncementTranslations => {
    if (payload && !isEmpty(payload)) {
        return Object.keys(payload).reduce((acc, languageKey: string) => {
            const payloadForLanguageKey = payload[languageKey];

            return {
                ...acc,
                [languageKey]: {
                    header: payloadForLanguageKey.header,
                    message: payloadForLanguageKey.message,
                    localeDisplayName: doesContainLocaleDisplayName(payloadForLanguageKey)
                        ? payloadForLanguageKey.localeDisplayName
                        : state.portalAnnouncementsResponse.translations?.[languageKey]?.localeDisplayName,
                },
            };
        }, {});
    }

    return {};
};

const handleFetchPortalSuccess = (
    state: PortalUIState,
    payload: PortalAnnouncement,
    isLoading: boolean
): PortalUIState => ({
    ...state,
    isLoading,
    portalAnnouncementsResponse: {
        ...payload,
        defaultLanguageDisplayName: payload.defaultLanguageDisplayName,
        translations: reduceTranslatedPortalAnnouncements(payload.translations, state),
    },
    isSaveFailed: false,
});

const handleFetchPortal = (state: PortalUIState, isLoading: boolean): PortalUIState => ({
    ...state,
    isLoading,
    isSaveFailed: false,
});

export function portalReducer(state: PortalUIState = defaultState, action: HandledActions): PortalUIState {
    switch (action.type) {
        case FETCH_PORTAL_MODEL:
            return handleFetchPortal(state, true);

        case FETCH_PORTAL_SUCCESS:
            return handleFetchPortalSuccess(state, action.payload.portalAnnouncement, false);

        case FETCH_PORTAL_FAILURE:
            return handleFetchPortal(state, false);

        case UPDATE_PORTAL_ANNOUNCEMENT_MODEL: {
            const newState: PortalUIState = {
                ...state,
                portalAnnouncementsResponse: {
                    ...state.portalAnnouncementsResponse,
                    translations: reduceTranslatedPortalAnnouncements(action.payload.translations, state),
                },
                isSaveInProgress: true,
                isSaveFailed: false,
            };
            return newState;
        }

        case UPDATE_PORTAL_ANNOUNCEMENT_FAILURE:
            return {
                ...state,
                isSaveFailed: true,
            };

        case UPDATE_PORTAL_ANNOUNCEMENTS_UI_STATE: {
            return {
                ...state,
                portalAnnouncementsResponse: {
                    ...state.portalAnnouncementsResponse,
                    ...action.payload,
                    translations: reduceTranslatedPortalAnnouncements(action.payload.translations, state),
                },
            };
        }

        case UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS:
        default:
            return {
                ...state,
                isSaveInProgress: false,
            };
    }
}
