import fetchModel from 'epics/model/fetcher';
import type { FetchOrganisationsModelAction } from 'epics/model/organisations/actions';
import { FETCH_ORGANISATIONS_MODEL } from 'epics/model/organisations/actions';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { isEmpty } from 'lodash';
import type { UpdateOrganisationsModelAction } from 'state/actions/organisations';
import { updateOrganisationsModelAction } from 'state/actions/organisations';

export const organisationsEpic: Epic<FetchOrganisationsModelAction, UpdateOrganisationsModelAction> = (action$) => {
    return action$.ofType(FETCH_ORGANISATIONS_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.organisations && !isEmpty(response.organisations))
            .map((response) => {
                return updateOrganisationsModelAction(response.organisations!);
            })
            // we're casting to success here as we swallow any errors for now
            .catch((_) => Observable.never() as Observable<UpdateOrganisationsModelAction>)
    );
};

export default organisationsEpic;
