import { defineMessages } from 'react-intl-next';

export default defineMessages({
    unsupportedBrowser: {
        defaultMessage:
            "We don't support your browser version anymore and this may cause problems when you raise requests. Upgrade to a newer version to ensure a smooth request creation experience.",
        description: 'Message on top screen banner to inform user that they are using unsupported browser version',
        id: 'cv.app.banner.unsupported.browser.version',
    },
});
