import { Observable } from 'epics/rxjs';
import { mergeWith, uniq } from 'lodash';
import { requestModels } from 'rest/models/models';
import { ModelsCache } from 'rest/models/models-cache/models-cache';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import batch from '@atlassian/help-center-common-util/batch-function';
import { getBasePath } from '@atlassian/help-center-common-util/history';
import { getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import type { Request } from './types';
import type { FetchOptions, ModelsResponse, ModelType, ModelTypeOptions } from 'rest/models/types';

type BatchingType = 'model-replaced' | 'model-merged';

const createBatchOperationalPayload = <TModelType extends ModelType>(
    modelType: TModelType,
    batchingType: BatchingType
) => {
    return {
        action: batchingType,
        actionSubject: 'model-batched',
        source: 'unknownSource',
        attributes: {
            modelType,
        },
    };
};

const mergeOrReplaceOptions = <TModelType extends ModelType>(
    obj1: ModelTypeOptions[TModelType] | undefined,
    obj2: ModelTypeOptions[TModelType],
    modelType: TModelType
) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
    if (!obj1 || (obj1 as any).id !== (obj2 as any).id) {
        operationalEvent(createBatchOperationalPayload(modelType, 'model-replaced'));
        return obj2;
    }

    return mergeWith(obj1, obj2, (value, srcValue, key) => {
        if (key === 'expand' && value) {
            operationalEvent(createBatchOperationalPayload(modelType, 'model-merged'));
            // We merge expand arrays if it is from the same type and they have the same id.

            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            return uniq(srcValue.concat(value));
        }

        return undefined;
    });
};

function fetchModels<TModelType extends ModelType>(requests: Request<TModelType>[]): Promise<ModelsResponse> {
    const requestTypes: ModelType[] = uniq(requests.map((request) => request.type));
    // This will build up the request params. If the request type is the same and the id is different it will
    // replace the type params, else it will merge them together.
    // If it finds two expand arrays to merge together it will concat them together and ensure only unique values.
    const requestOptions: ModelTypeOptions = requests.reduce(
        (obj: ModelTypeOptions, request) =>
            Object.keys(request.params || {}).length
                ? {
                      ...obj,
                      [request.type]: mergeOrReplaceOptions(obj[request.type], request.params, request.type),
                  }
                : obj,
        {} as ModelTypeOptions
    );
    const modelContext = { helpCenterAri: getHelpCenterAri(), clientBasePath: getBasePath() };

    return requestModels(requestTypes, requestOptions, modelContext).toPromise();
}

/**
 * A TS limitation results in this losing its arg type.
 * See: https://github.com/Microsoft/TypeScript/issues/29638
 *
 * As a work around we create another wrapping function and re-type it.
 */
const secondaryBatchedFetchModels = batch(fetchModels, 0);

const primaryBatchedFetchModels = batch(fetchModels, 0);

/**
 * Will fetch models after one tick of the event loop.
 * All calls to this inside a tick will be batched and then requested at the same time.
 */
export function fetchModel<TModelType extends ModelType>(
    request: Request<TModelType>,
    { type, disableBatching }: FetchOptions = {
        type: 'primary',
        disableBatching: false,
    }
): Observable<ModelsResponse> {
    const modelContext = { helpCenterAri: getHelpCenterAri(), clientBasePath: getBasePath() };

    if (ModelsCache.getInstance().shouldCache(request.type)) {
        const cachedData = ModelsCache.getInstance().getFromCache(request);
        if (cachedData !== undefined) {
            return Observable.from(Promise.resolve(cachedData.response));
        }
    }
    if (disableBatching) {
        const options = {
            [request.type]: request.params,
        } as unknown as ModelTypeOptions;

        return requestModels([request.type], options, modelContext);
    }

    const promise =
        type === 'secondary'
            ? secondaryBatchedFetchModels(request).then((successResult) =>
                  addSuccessResultToCache(request, successResult)
              )
            : primaryBatchedFetchModels(request).then((successResult) =>
                  addSuccessResultToCache(request, successResult)
              );
    return Observable.from(promise);
}

function addSuccessResultToCache(request: Request<ModelType>, modelsResponse: ModelsResponse) {
    if (ModelsCache.getInstance().shouldCache(request.type)) {
        ModelsCache.getInstance().putToCache(request, {
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            [request.type]: modelsResponse[request.type],
        });
    }
    return modelsResponse;
}
