import type { AjaxError } from 'rxjs';

import type { Epic } from 'epics/rxjs';
import { concat, Observable } from 'epics/rxjs';
import { addParticipants } from 'rest/participants';
import type { HandleAjaxError, ShowErrorFlag, ShowSuccessFlag } from 'state/actions/flags';
import { handleAjaxError, showErrorFlag, showSuccessFlag } from 'state/actions/flags';
import type { ShareRequest, AddParticipantsSuccess } from 'state/actions/request-details';
import { SHARE_REQUEST, addParticipantsSuccess } from 'state/actions/request-details';
import messages from './messages';

// Note: this shares participants in a PII Safe manner, calling a new endpoint that has been optimised for performance
export const shareRequestEpic: Epic<
    ShareRequest,
    AddParticipantsSuccess | HandleAjaxError | ShowSuccessFlag | ShowErrorFlag
> = (action$) =>
    action$.ofType(SHARE_REQUEST).mergeMap((shareRequestAction) => {
        const {
            requestKey,
            organizations,
            emailAddresses,
            accountIds,
            isUserMentioned,
            mentionedUserName,
            userMentionedDuringRequestCreation,
        } = shareRequestAction.payload;
        return addParticipants(requestKey, accountIds, organizations, emailAddresses)
            .flatMap((response) => {
                if (isUserMentioned) {
                    const messageTitle = mentionedUserName
                        ? messages.requestSharedWithSingleMentionedUserSuccessTitle
                        : messages.requestSharedWithMentionedUsersSuccessTitle;
                    const messageDescription = mentionedUserName
                        ? messages.requestSharedWithSingleMentionedUserSuccessDescription
                        : messages.requestSharedWithMentionedUsersSuccessDescription;
                    const descriptionValues = mentionedUserName ? { userName: mentionedUserName } : undefined;

                    return concat(
                        Observable.of(addParticipantsSuccess(requestKey, response, userMentionedDuringRequestCreation)),
                        Observable.of(
                            showSuccessFlag(messageTitle, undefined, messageDescription, undefined, descriptionValues)
                        )
                    );
                }
                return Observable.of(addParticipantsSuccess(requestKey, response));
            })
            .catch((error: AjaxError) => {
                const isShowErrorFlag = isUserMentioned;

                const messageTitle = mentionedUserName
                    ? messages.requestSharedWithSingleMentionedUserFailureTitle
                    : messages.requestSharedWithMentionedUsersFailureTitle;
                const messageDescription = mentionedUserName
                    ? messages.requestSharedWithSingleMentionedUserFailureDescription
                    : messages.requestSharedWithMentionedUsersFailureDescription;
                const descriptionValues = mentionedUserName ? { userName: mentionedUserName } : undefined;

                return Observable.of(
                    isShowErrorFlag
                        ? showErrorFlag(messageTitle, messageDescription, undefined, undefined, descriptionValues)
                        : handleAjaxError(error)
                );
            });
    });
