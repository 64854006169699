import React, { useCallback, useState } from 'react';
import type { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button, { LoadingButton } from '@atlaskit/button';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import Toggle from '@atlaskit/toggle';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { isFetchNetworkOrClientError, type FetchError } from '@atlassian/help-center-common-util/fetch';
import { getCloudId } from '@atlassian/help-center-common-util/meta';
import { useHelpCenterAri } from '../../../hooks/use-help-center-ari';
import type { syncProjectsToggleFragment$key } from './__generated__/syncProjectsToggleFragment.graphql';
import type {
    syncProjectsToggleMutation,
    syncProjectsToggleMutation$data,
} from './__generated__/syncProjectsToggleMutation.graphql';
import messages from './messages';

export interface Props {
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
    syncProjectsToggleFragmentKey: syncProjectsToggleFragment$key;
}

const SyncProjectsToggle = ({
    syncProjectsToggleFragmentKey,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}: Props) => {
    di(useMutation, getCloudId, useFragment, useHelpCenterAri);
    const helpCenterData = useFragment(
        graphql`
            fragment syncProjectsToggleFragment on HelpCenter {
                projectMappingData {
                    syncNewProjects
                }
            }
        `,
        syncProjectsToggleFragmentKey
    );
    const [isChecked, setIsChecked] = useState(!!helpCenterData?.projectMappingData?.syncNewProjects);
    const [isRemoveMappingDialogOpen, setRemoveMappingDialogOpen] = useState(false);
    const [isUnSyncing, setIsUnSyncing] = useState(false);
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const { formatMessage } = useIntl();
    const helpCenterAri = useHelpCenterAri();
    const [toggleDefaultSync] = useMutation<syncProjectsToggleMutation>(graphql`
        mutation syncProjectsToggleMutation($cloudId: ID!, $input: HelpCenterProjectMappingUpdateInput!) {
            helpCenter(cloudId: $cloudId) {
                updateProjectMapping(input: $input) {
                    success
                    errors {
                        message
                    }
                }
            }
        }
    `);
    const onToggleDefault = useCallback(
        (syncNewProjects: boolean) => {
            return new Promise<syncProjectsToggleMutation$data>((resolve, reject) =>
                toggleDefaultSync({
                    variables: {
                        cloudId: getCloudId(),
                        input: {
                            syncNewProjects,
                            helpCenterAri,
                        },
                    },
                    onCompleted: (response) => {
                        if (response.helpCenter?.updateProjectMapping?.success) {
                            resolve(response);
                        }
                        const errors = response.helpCenter?.updateProjectMapping?.errors;
                        const error = errors && errors.length > 0 ? errors[0] : new Error('Unknown error');
                        return reject(error);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                })
            );
        },
        [toggleDefaultSync, helpCenterAri]
    );

    const onToggleChange = useCallback(() => {
        if (isChecked) {
            setRemoveMappingDialogOpen(true);
        } else {
            setIsChecked(true);
            onToggleDefault(true)
                .then(() => {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'map',
                        actionSubject: 'newProjects',
                        toggleState: true,
                    }).fire();
                })
                .catch((error: FetchError) => {
                    const { message, status } = error;
                    setIsChecked(false);
                    showErrorFlagAction(messages.syncErrorTitle, messages.syncErrorDescription);
                    if (!isFetchNetworkOrClientError(error)) {
                        createAnalyticsEvent({
                            analyticsType: TRACK_EVENT_TYPE,
                            action: 'map_failed',
                            actionSubject: 'newProjects',
                            errorMessage: message,
                            errorStatus: status,
                        }).fire();
                    }
                });
        }
    }, [isChecked, onToggleDefault, showErrorFlagAction, createAnalyticsEvent]);

    const onUnMapNewProjects = useCallback(() => {
        setIsUnSyncing(true);
        onToggleDefault(false)
            .then(() => {
                setIsChecked(false);
                showSuccessFlagAction(messages.unSyncSuccessTitle);
                createAnalyticsEvent({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'map',
                    actionSubject: 'newProjects',
                    toggleState: false,
                }).fire();
            })
            .catch((error: FetchError) => {
                const { message, status } = error;
                showErrorFlagAction(messages.syncErrorTitle, messages.syncErrorDescription);
                if (!isFetchNetworkOrClientError(error)) {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'map_failed',
                        actionSubject: 'newProjects',
                        errorMessage: message,
                        errorStatus: status,
                    }).fire();
                }
            })
            .finally(() => {
                setIsUnSyncing(false);
                setRemoveMappingDialogOpen(false);
            });
    }, [onToggleDefault, showErrorFlagAction, showSuccessFlagAction, createAnalyticsEvent]);

    return (
        <>
            <Toggle
                label={formatMessage(messages.defaultMappingToggleLabel)}
                isChecked={isChecked}
                onChange={onToggleChange}
            />
            <ModalTransition>
                {isRemoveMappingDialogOpen && (
                    <Modal width="small" onClose={() => setRemoveMappingDialogOpen(false)}>
                        <ModalHeader>
                            <ModalTitle testId="help-center-UnSync-modal-title">
                                {formatMessage(messages.removeMappingModalTitle)}
                            </ModalTitle>
                        </ModalHeader>
                        <ModalBody>{formatMessage(messages.removeMappingModalDesc)}</ModalBody>
                        <ModalFooter>
                            <LoadingButton
                                onClick={onUnMapNewProjects}
                                isLoading={isUnSyncing}
                                appearance="primary"
                                testId="help-center-modal-unSync-button"
                            >
                                {formatMessage(messages.removeMappingModalConfirmButton)}
                            </LoadingButton>
                            <Button onClick={() => setRemoveMappingDialogOpen(false)} appearance="subtle">
                                {formatMessage(messages.removeMappingModalCancelButton)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </ModalTransition>
        </>
    );
};

export default SyncProjectsToggle;
