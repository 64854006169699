import { di } from 'react-magnetic-di';
import { useMessages as useVAMessages } from '@atlassian/help-center-virtual-agent/src/controllers/conversation-store';

import { ConversationProvider } from '../../../common/types';
import { useConversation as useAIAnswersConversation } from '../../../store/converse';
import { useProvider } from '../../../store/routing';

export const useHasMessages = () => {
    di(useProvider, useAIAnswersConversation, useVAMessages);

    const provider = useProvider();
    const [AIAnswersConversation] = useAIAnswersConversation();
    const VAMessages = useVAMessages();

    if (provider === ConversationProvider.VIRTUAL_AGENT) {
        return VAMessages.length > 0;
    }

    return !!AIAnswersConversation;
};
