//import AnalyticsClient, { envType, originType, userType, tenantType } from '@atlassiansox/analytics-web-client';
//@ts-ignore
//import Cookie from 'js-cookie';
//import { getMeta } from '@atlassian/help-center-common-util/meta';
import { getAnalyticsWebClient as helpCenterAnalyticsClient } from '@helpCenter/util/analytics';

// const init = () => {
//     const analyticsClient = new AnalyticsClient({
//         env:
//             process.env.NODE_ENV === 'production'
//                 ? envType.PROD
//                 : process.env.NODE_ENV === 'staging'
//                 ? envType.STAGING
//                 : process.env.NODE_ENV === 'development'
//                 ? envType.DEV
//                 : envType.LOCAL,
//         product: 'unifiedHelp',
//         version: '1.0.0',
//         origin: originType.WEB,
//     });

//     const aaid = Cookie.get('aaid');
//     const tenantId = getMeta('tenant-id') || '';

//     if (aaid) {
//         analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, aaid);
//     }

//     if (tenantId) {
//         analyticsClient.setTenantInfo(tenantType.CLOUD_ID, tenantId);
//     }

//     return analyticsClient;
// };

//const analyticsClient: AnalyticsClient | null = null;

// export const getAnalyticsWebClient = (): AnalyticsClient => {
//     if (analyticsClient === null) {
//         analyticsClient = init();
//     }
//     return analyticsClient;
// };

export const getAnalyticsWebClient = () => helpCenterAnalyticsClient().getInstance();
