import type { PropsWithChildren } from 'react';
import React from 'react';
import { getJsmAIConfigEnabled } from '@helpCenter/util/env';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { NewGlobalBGColor } from '@helpCenter/view/styles/global';
import ErrorScreen from 'assets/images/project-mapping-error.svg';
import {
    isProjectMappingInHelpCenterEnabled,
    isMoveToManagementRouterEnabled,
    isCustomPagesUiEnabled,
} from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { usePathParam, useRouter } from 'react-resource-router';
import { useMediaQuery } from 'view/help-center/use-screen-size/use-screen-size';
import { sizes } from 'view/styles/sizes-viewport';
import EmptyState from '@atlaskit/empty-state';
import AiChatIcon from '@atlaskit/icon/core/ai-chat';
import LinkIcon from '@atlaskit/icon/core/link';
import PageIcon from '@atlaskit/icon/core/page';
import PagesIcon from '@atlaskit/icon/core/pages';
import { Main, Content, PageLayout, TopNavigation, TOP_NAVIGATION_HEIGHT, BANNER_HEIGHT } from '@atlaskit/page-layout';
import { Box, media, xcss } from '@atlaskit/primitives';
import { SIDEBAR_WIDTH } from '../constants';
import { Header } from '../header';
import type { NavigationContentItem } from '../sidebar';
import { Sidebar } from '../sidebar';
import messages from './messages';

interface Props {
    featureName: string;
}

export const AdminManagementLayout: React.FC<PropsWithChildren<Props>> = ({ children, featureName }) => {
    di(
        useRouter,
        getJsmAIConfigEnabled,
        isProjectMappingInHelpCenterEnabled,
        isCustomPagesUiEnabled,
        useLazyLoadQuery,
        Sidebar
    );
    const [{ route }, { push }] = useRouter();
    const { formatMessage } = useIntl();
    const [helpCenterId = ''] = usePathParam('helpcenterId');
    const { name: routeName } = route;
    const virtualAgentRouteName = 'virtualAgentSettings';

    const virtualAgentUrl = isMoveToManagementRouterEnabled() ? `/${helpCenterId}/virtual-agent` : '/virtual-agent';
    const mapProjectsScreenUrl = isMoveToManagementRouterEnabled() ? `/${helpCenterId}/projects` : '/manage-projects';
    const mapPagesScreenUrl = isMoveToManagementRouterEnabled() ? `/${helpCenterId}/pages` : '/manage-pages';
    const editProjectDetailsScreenUrl = isMoveToManagementRouterEnabled()
        ? `/${helpCenterId}/details`
        : '/edit-details';

    const navigationContentItems: NavigationContentItem[] = [
        {
            onClick: () => push(editProjectDetailsScreenUrl) as void,
            text: formatMessage(messages.helpcenterDetailsItem),
            isVisible: isProjectMappingInHelpCenterEnabled(),
            isSelected: routeName === 'edit-details',
            iconBefore: <PageIcon label={formatMessage(messages.helpcenterDetailsItem)} />,
        },
        {
            onClick: () => push(mapProjectsScreenUrl) as void,
            text: formatMessage(messages.helpcenterManageProjectsItem),
            isSelected: routeName === 'manage-projects',
            isVisible: isProjectMappingInHelpCenterEnabled(),
            iconBefore: <LinkIcon label={formatMessage(messages.helpcenterManageProjectsItem)} />,
        },
        {
            onClick: () => push(mapPagesScreenUrl) as void,
            text: formatMessage(messages.helpcenterManagePagesItem),
            isSelected: routeName === 'manage-pages',
            isVisible: isCustomPagesUiEnabled(),
            iconBefore: <PagesIcon label={formatMessage(messages.helpcenterManagePagesItem)} />,
        },
        {
            onClick: () => push(virtualAgentUrl) as void,
            text: formatMessage(messages.helpcenterVsaReportingItem),
            isSelected: routeName === virtualAgentRouteName,
            isVisible: getJsmAIConfigEnabled(),
            iconBefore: <AiChatIcon label={formatMessage(messages.helpcenterVsaReportingItem)} />,
        },
    ];
    const renderError = () => {
        return (
            <EmptyState
                header={formatMessage(messages.errorTitle)}
                description={formatMessage(messages.errorDescription)}
                imageUrl={ErrorScreen}
            />
        );
    };

    const packageName = (): string => {
        switch (routeName) {
            case 'edit-details':
                return 'editDetails';
            case 'manage-projects':
                return 'manageProjects';
            case 'manage-pages':
                return 'managePages';
            case 'virtualAgentSettings':
                return 'virtualAgentSettings';
            case 'reporting':
                return 'reporting';
            default:
                return 'unknown';
        }
    };

    const isTablet = !useMediaQuery(`(max-width: ${sizes.xsmall}px)`);
    return (
        <Box xcss={pageLayoutWrapper} testId="admin-layout-management-page-wrapper">
            <PageLayout>
                <TopNavigation isFixed={false}>
                    <Header />
                </TopNavigation>
                <Content>
                    {isTablet && (
                        <Sidebar
                            width={SIDEBAR_WIDTH}
                            navigationContentItems={navigationContentItems}
                            featureName={featureName}
                        />
                    )}
                    <Box xcss={bodyContainer}>
                        <Main>
                            <ErrorBoundary
                                packageName={packageName()}
                                id={'admin-layout-management-page'}
                                renderError={renderError}
                            >
                                <Box xcss={container}>{children}</Box>
                            </ErrorBoundary>
                        </Main>
                    </Box>
                    <NewGlobalBGColor />
                </Content>
            </PageLayout>
        </Box>
    );
};

export default AdminManagementLayout;

const container = xcss({
    maxWidth: '1160px',
    margin: '0 auto',
    paddingBottom: 'space.050',
    paddingInline: 'space.600',
    [media.above.lg]: {
        paddingInline: 'space.0',
    },
});

const pageLayoutWrapper = xcss({
    overflowAnchor: 'none',
    height: '100%',
});

const bodyContainer = xcss({
    overflow: 'auto',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
    height: `calc(100vh - (${TOP_NAVIGATION_HEIGHT} + ${BANNER_HEIGHT}))`,
    width: '100%',
});
