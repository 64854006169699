import React from 'react';
import { compose } from 'redux';
import { useIntl } from 'react-intl-next';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';

interface Props {
    children: React.ReactNode;
}

export const JiraAdminPermissionWrapper = ({ children }: Props) => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: '/',
        }),
        withOnlyJiraAdmin({
            message: formatMessage(authPageMessage),
            callToActionText: formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        })
    )(() => React.Children.only(children));
    return <WrappedComponent />;
};
