/* eslint-disable @typescript-eslint/require-await */
import { isVAFollowupQueryOnlyForPositiveResponseEnabled, isAiAnswer24HourTimeoutEnabled } from 'feature-flags';
import type { Action } from 'react-sweet-state';
import { doc, p } from '@atlaskit/adf-utils/builders';
import { type Sources, getUHUpdatedMessage, ExperienceType } from '@atlassian/conversation-assistant';
import type { UHStreamMessage } from '@atlassian/conversation-assistant/dist/types/services/unified-help/types';
import { AIAnswerExperience, initialAIAnswerExperience } from '../../controllers/experience';
import { getChannelId, sendMessageStream } from '../../rest/ai-mate-converse';
import { storeFeedback } from '../../rest/store-feedback';
import { genIntId } from '../../utils/ai-mate/gen-id';
import { initialAssistantMessage } from '../../utils/ai-mate/get-initial-assistant-message';
import { getTotalHelpDesks } from '../../utils/ai-mate/get-total-helpdesks';
import { getTotalRequestForms } from '../../utils/ai-mate/get-total-requestforms';
import { getTotalSources } from '../../utils/ai-mate/get-total-sources';
import {
    updateAssistantMessageStatusToLoadingResponse,
    updateAssistantMessageStatusToTypingResponse,
    updateFinalMessage,
    updateKeywords,
    replaceLastAssistantMessage,
    addAssistantMessage,
    updateMessage,
    updateSources,
    updateAbortedAssistantMessage,
    updateFollowUpMessage,
} from '../../utils/ai-mate/process-assistant-message';
import { createStoreAbortController } from '../../utils/ai-mate/store-abort-controller';
import { getAnalyticsWebClient } from '../../utils/analytics';
import {
    sendTrackEventForAnswerStreamingStarted,
    sendTrackEventForFailure,
    sendTrackEventForFetchedResponse,
    sendUIEventForAskedQuestion,
    sendUIEventForClearConversation,
} from '../../utils/analytics/conversation-analytics';
import { getProductIdentifierAttributes } from '../../utils/analytics/get-product-identifier-attributes';
import { initialState, ASSISTANT_USER_TYPE, HELP_SEEKER_USER_TYPE } from './constants';
import type {
    AssistantMessage,
    ChatMessage,
    ChannelMessage,
    Conversation,
    ConversationMessage,
    ConversationMessageMetadata,
    HelpSeekerMessage,
    State,
    FeedbackResponse,
    RetryMessage,
    CsatDropdownMessage,
} from './types';
import { ChannelMessageTypes, FeedbackType } from './types';

const CHANNEL_EXPIRY_TIME = 1000 * 60 * 5; // 5mins
const ONE_DAY = 1000 * 60 * 60 * 24; // 24 Hours

const getChannelExpiryTime = () => {
    return isAiAnswer24HourTimeoutEnabled() ? ONE_DAY : CHANNEL_EXPIRY_TIME;
};

interface Option {
    id: number;
    label: string;
}

export const fetchChannelId =
    (vaConversationId?: string): Action<State, void, void> =>
    async ({ getState, setState, dispatch }) => {
        try {
            const channelId = await getChannelId();
            const history = getState().conversation?.history || [];

            dispatch(resetTimer(channelId));

            if (channelId) {
                setState({
                    isLoading: false,
                    conversation: {
                        conversationId: channelId,
                        externalConversationId: vaConversationId,
                        history: [...history],
                    },
                });
            } else {
                throw new Error('No channel id!');
            }
        } catch (e) {
            await dispatch(addChannelMessage(ChannelMessageTypes.CHANNEL_EXPIRY));
        }
    };

const resetTimer =
    (channelId: string | null | undefined, timer: number = getChannelExpiryTime()): Action<State, void, void> =>
    async ({ getState, setState, dispatch }) => {
        const currentTimer = getState().activeTimer;
        const analyticsClient = getAnalyticsWebClient();

        if (currentTimer) {
            clearTimeout(currentTimer);
        }

        const activeTimer = setTimeout(() => {
            dispatch(cleanFollowUps());

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            dispatch(addChannelMessage(ChannelMessageTypes.CHANNEL_EXPIRY));

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsClient.sendTrackEvent({
                source: 'uhConversationScreen',
                action: 'expired',
                actionSubject: 'conversation',
                attributes: {
                    conversationId: channelId,
                    ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
                },
            });
        }, timer);

        setState({
            activeTimer,
        });
    };

export const startNewConversation =
    (term: string): Action<State, void, void> =>
    async ({ getState, setState, dispatch }) => {
        setState({
            isLoading: true,
            conversation: {
                conversationId: null,
                history: [],
            },
        });
        await dispatch<Action<State>>(fetchChannelId());

        const conversation = getState().conversation;

        if (conversation && conversation.conversationId) {
            dispatch(addHelpseekerMessageOnlyForUI(term));
            /**
             * Mark conversation start when the first conversation message is sent
             */
            setState({
                conversationStartTime: Date.now(),
            });

            dispatch(fetchConversation(term));
        }
    };

export const onPipelineChange =
    (pipeline: string): Action<State> =>
    async ({ setState }) => {
        setState({
            pipeline,
        });
    };

export const setTesting =
    (isTesting: boolean): Action<State> =>
    async ({ setState }) => {
        setState({
            isTesting,
        });
    };

export const setConversationScrollBarWidth =
    (conversationScrollBarWidth: string): Action<State, void, void> =>
    ({ setState }) => {
        setState({
            ui: {
                conversationScrollBarWidth,
            },
        });
    };

const addChannelMessage =
    (type: ChannelMessage['messageType']): Action<State, void> =>
    ({ getState, setState }) => {
        let conversation = getState().conversation;
        if (!conversation) {
            throw new Error('No conversation!');
        }

        conversation = {
            conversationId: null,
            history: [
                ...conversation.history.map((message) => {
                    if (
                        message.userType === ASSISTANT_USER_TYPE &&
                        !message.messageMetadata.isFeedbackSkipped &&
                        !message.messageMetadata.messageFeedback
                    ) {
                        return {
                            ...message,
                            messageMetadata: {
                                ...message.messageMetadata,
                                isFeedbackSkipped: true,
                            },
                        };
                    }
                    if (
                        message.userType === ASSISTANT_USER_TYPE &&
                        !message.messageMetadata.isCsatSkipped &&
                        !message.messageMetadata.csatScore
                    ) {
                        return {
                            ...message,
                            messageMetadata: {
                                ...message.messageMetadata,
                                isCsatSkipped: true,
                            },
                        };
                    }
                    return message;
                }),
                {
                    userType: 'channelMessage',
                    messageType: type,
                },
            ],
        };
        setState({ conversation, isLoading: false });
    };

export const recordFeedbackForConversation =
    (
        id: string | undefined,
        {
            feedbackLabel,
            feedbackType,
            detailedFeedback,
        }: { feedbackLabel: string; feedbackType: FeedbackType; detailedFeedback?: string }
    ): Action<State, void, void> =>
    async ({ getState, setState }) => {
        const analyticsClient = getAnalyticsWebClient();
        const conversation = getState().conversation;
        if (!conversation || !conversation.conversationId) {
            throw new Error('No conversation!');
        }

        storeFeedback({
            feedbackLabel,
            channelId: conversation.conversationId,
            messageId: id,
            feedback: feedbackType === FeedbackType.POSITIVE ? 'LIKE' : 'DISLIKE',
        }).catch(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsClient.sendTrackEvent({
                source: 'uhAiMateFeedbackRequest',
                action: 'feedbackRequestFailed',
                actionSubject: 'feedback',
                actionSubjectId: conversation.conversationId || '',
                attributes: {
                    isAiMate: true,
                    ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
                },
            });
        });

        const updatedConversationHistory = conversation.history.map((message) => {
            if (message.userType === ASSISTANT_USER_TYPE) {
                const { messageId } = message.messageMetadata;
                if (messageId && messageId === id) {
                    return {
                        ...message,
                        messageMetadata: {
                            ...message.messageMetadata,
                            messageFeedback: {
                                feedbackType,
                                feedbackLabel,
                                detailedFeedback,
                            },
                        },
                    };
                }
            }
            return message;
        });

        const updatedConversation = {
            ...conversation,
            history: updatedConversationHistory,
        };
        setState({ conversation: updatedConversation });
    };

const getDefaultErrorState = (conversation: NonNullable<State['conversation']>): State['conversation'] => {
    let updatedConversationHistory = conversation.history;
    const lastMessageUserType = conversation.history[conversation.history.length - 1].userType;

    if (lastMessageUserType === ASSISTANT_USER_TYPE) {
        updatedConversationHistory = updatedConversationHistory.slice(0, -1);
    }

    return {
        ...conversation,
        history: [
            ...updatedConversationHistory,
            {
                userType: 'error',
            },
        ],
    };
};

export const refetchConversation =
    (): Action<State, void, void> =>
    async ({ getState, dispatch }) => {
        const conversation = getState().conversation;
        if (!conversation || !conversation.conversationId) {
            return;
        }
        const history = conversation.history;

        const helpSeekerMessages = history.filter(
            (chatMessage) => chatMessage.userType === HELP_SEEKER_USER_TYPE
        ) as HelpSeekerMessage[];

        const term = helpSeekerMessages[helpSeekerMessages.length - 1].messageMetadata?.originalQuery as
            | string
            | undefined;

        if (term) {
            dispatch(fetchConversation(term));
        }
    };

const fetchConversation =
    (message: string): Action<State, void, void> =>
    async ({ getState, setState }) => {
        const { conversation } = getState();
        const analyticsClient = getAnalyticsWebClient();

        if (!conversation || !conversation.conversationId) {
            throw new Error('No conversation!');
        }

        if (conversation.storeAbortController) {
            // Silently aborting if there is another operation in progress
            return;
        }

        try {
            const { storeAbortController } = createStoreAbortController();

            setState({ isLoading: true });

            sendUIEventForAskedQuestion(
                analyticsClient,
                conversation.conversationId,
                conversation.history.length,
                message.length,
                conversation.externalConversationId
            );

            const answerId = genIntId();

            let assistantMessage: AssistantMessage = initialAssistantMessage(answerId);

            let generator: AsyncGenerator<UHStreamMessage> | undefined;
            let isAnswerPartAnalyticsFired = false;
            let isFallbackMessage = false;

            const startTime = performance.now();
            let finishTime;
            try {
                generator = await sendMessageStream(message, conversation.conversationId, storeAbortController);
            } catch (e) {
                setState({
                    conversation: getDefaultErrorState(conversation),
                });
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                analyticsClient.sendTrackEvent({
                    source: 'uhAiMateRequest',
                    action: 'streaming_fetch_failed',
                    actionSubject: 'conversation',
                    actionSubjectId: conversation.conversationId,
                    attributes: {
                        isAiMate: true,
                        ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
                    },
                });
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                initialAIAnswerExperience.failure();
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                AIAnswerExperience.failure();
                return;
            }
            if (!generator) {
                return;
            }

            setState({
                conversation: { ...addAssistantMessage(assistantMessage, conversation), storeAbortController },
            });

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            initialAIAnswerExperience.success();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            AIAnswerExperience.success();

            for await (const streamMessage of generator) {
                if (storeAbortController.signal.aborted) {
                    break;
                }

                const { conversation: recentConversation } = getState();

                if (!recentConversation || !recentConversation.conversationId) {
                    return;
                }

                const { type, content } = getUHUpdatedMessage(
                    streamMessage,
                    assistantMessage.aiMateConversationResponse.message
                ) ?? { type: '' };
                let metadata: ConversationMessageMetadata | undefined;
                let updatedConversation: Conversation;
                let totalSources: number;
                let totalRequestForms: number;
                let totalHelpDesks: number;

                switch (type) {
                    case 'FOLLOW_UP_QUERIES':
                        setState({
                            conversation: updateFollowUpMessage(recentConversation, content as string[]),
                        });
                        break;
                    case 'CONTENT_SEARCH':
                        assistantMessage = updateKeywords(assistantMessage, content as string);
                        assistantMessage = updateAssistantMessageStatusToLoadingResponse(assistantMessage);
                        setState({
                            conversation: replaceLastAssistantMessage(assistantMessage, recentConversation),
                        });
                        break;
                    case 'ANALYSING_STATE':
                        assistantMessage = updateSources(assistantMessage, content as Sources);
                        setState({
                            conversation: replaceLastAssistantMessage(assistantMessage, recentConversation),
                        });
                        break;
                    case 'ANSWER_PART':
                        assistantMessage = updateMessage(assistantMessage, content as ConversationMessage);
                        assistantMessage = updateAssistantMessageStatusToTypingResponse(assistantMessage);

                        setState({
                            conversation: replaceLastAssistantMessage(assistantMessage, recentConversation),
                        });
                        setState({
                            isLoading: false,
                        });
                        !isAnswerPartAnalyticsFired &&
                            sendTrackEventForAnswerStreamingStarted(
                                analyticsClient,
                                conversation.conversationId,
                                performance.now() - startTime,
                                conversation.externalConversationId
                            );
                        isAnswerPartAnalyticsFired = true;
                        break;
                    case 'PLUGIN_INVOCATION':
                        assistantMessage = updateMessage(assistantMessage, content as ConversationMessage);
                        setState({
                            conversation: replaceLastAssistantMessage(assistantMessage, recentConversation),
                        });
                        break;
                    case 'FINAL_RESPONSE':
                        metadata = (content as ConversationMessage)?.message_metadata;
                        isFallbackMessage = !!metadata?.isFallbackMessage;

                        assistantMessage = updateFinalMessage(
                            assistantMessage,
                            content as ConversationMessage,
                            isFallbackMessage
                        );

                        if (isFallbackMessage) {
                            const retryMessage = getRetryMessage(
                                assistantMessage.aiMateConversationResponse?.message?.message_metadata?.originalQuery,
                                assistantMessage.messageMetadata?.messageId,
                                isFallbackMessage
                            );
                            updatedConversation = replaceLastAssistantMessage(retryMessage, recentConversation);
                        } else {
                            updatedConversation = replaceLastAssistantMessage(assistantMessage, recentConversation);
                        }

                        setState({
                            conversation: updatedConversation,
                        });
                        finishTime = performance.now();

                        totalSources = getTotalSources(content as ConversationMessage);

                        totalHelpDesks = getTotalHelpDesks(content as ConversationMessage);

                        totalRequestForms = getTotalRequestForms(content as ConversationMessage);

                        sendTrackEventForFetchedResponse(
                            analyticsClient,
                            finishTime - startTime,
                            metadata?.confidenceScore,
                            metadata?.isFallbackMessage,
                            metadata?.isConfluenceLicensedUser,
                            metadata?.isVagueQuery,
                            metadata?.searchResultCount,
                            metadata?.totalSearchResultCount,
                            recentConversation.conversationId,
                            totalSources,
                            totalHelpDesks,
                            totalRequestForms,
                            assistantMessage.messageMetadata.messageId,
                            recentConversation.externalConversationId
                        );
                        break;
                    case 'ERROR':
                        setState({
                            conversation: getDefaultErrorState(recentConversation),
                        });
                        sendTrackEventForFailure(
                            analyticsClient,
                            recentConversation.conversationId,
                            false,
                            recentConversation.externalConversationId
                        );
                        break;
                    default:
                        break;
                }
            }
        } finally {
            const { conversation: currentConversation } = getState();
            setState({
                conversation: currentConversation ? { ...currentConversation, storeAbortController: undefined } : null,
                isLoading: false,
            });
        }
    };

export const addHelpseekerMessage =
    (term: string): Action<State, void, void> =>
    async ({ getState, dispatch }) => {
        dispatch(resetTimer(getState().conversation?.conversationId));
        dispatch(cleanFollowUps());
        dispatch(addHelpseekerMessageOnlyForUI(term));
        dispatch(fetchConversation(term));
    };

const cleanFollowUps =
    (): Action<State, void, void> =>
    ({ getState, setState }) => {
        let conversation = getState().conversation;
        if (!conversation) {
            throw new Error('No conversation!');
        }
        conversation = {
            ...conversation,
            history: conversation.history.filter((item) => item.userType !== 'followUp'),
        };
        setState({ conversation });
    };

export const addHelpseekerMessageOnlyForUI =
    (term: string): Action<State, void, void> =>
    async ({ getState, setState }) => {
        let conversation = getState().conversation;

        if (!conversation) {
            throw new Error('No conversation!');
        }

        conversation = {
            ...conversation,
            history: [
                ...conversation.history.map((message) => {
                    if (
                        message.userType === ASSISTANT_USER_TYPE &&
                        !message.messageMetadata.isFeedbackSkipped &&
                        !message.messageMetadata.messageFeedback
                    ) {
                        return {
                            ...message,
                            messageMetadata: {
                                ...message.messageMetadata,
                                isFeedbackSkipped: true,
                            },
                        };
                    }
                    if (
                        message.userType === ASSISTANT_USER_TYPE &&
                        !message.messageMetadata.isCsatSkipped &&
                        !message.messageMetadata.csatScore
                    ) {
                        return {
                            ...message,
                            messageMetadata: {
                                ...message.messageMetadata,
                                isCsatSkipped: true,
                            },
                        };
                    }
                    return message;
                }),
                {
                    userType: 'helpseeker',
                    messageContentAdf: doc(p(term)),
                    messageMetadata: {
                        originalQuery: term,
                    },
                },
            ],
        };
        setState({ conversation });
    };

export const addFeedbackResponseOnlyForUI =
    (response: string, id: string, isResolved: boolean): Action<State, void, void> =>
    async ({ getState, setState, dispatch }) => {
        let conversation = getState().conversation;

        if (!conversation) {
            throw new Error('No conversation!');
        }
        const indexOfAssistantMessage = conversation.history.findIndex(
            (message) => message.userType === 'assistant' && message.messageMetadata.messageId === id
        );
        if (indexOfAssistantMessage === -1) return;

        const responseObject: FeedbackResponse = {
            userType: 'feedbackResponse',
            messageContentAdf: doc(p(response)),
        };

        const newHistory = [...conversation.history];
        newHistory.splice(indexOfAssistantMessage + 1, 0, responseObject);
        conversation = { ...conversation, history: newHistory };
        setState({ conversation });

        if (isResolved) {
            dispatch(addCsatDropdownMessageOnlyForUI(indexOfAssistantMessage, id));
            if (isVAFollowupQueryOnlyForPositiveResponseEnabled()) {
                dispatch(cleanFollowUps());
                dispatch(resetTimer(getState().conversation?.conversationId, ONE_DAY));
            }
        }
    };

export const addCsatResponseOnlyForUI =
    (response: Option, id: string): Action<State, void, void> =>
    async ({ getState, setState }) => {
        let conversation = getState().conversation;

        if (!conversation) {
            throw new Error('No conversation!');
        }
        const indexOfAssistantMessage = conversation.history.findIndex(
            (message) => message.userType === 'assistant' && message.messageMetadata.messageId === id
        );
        if (indexOfAssistantMessage === -1) return;

        (conversation.history[indexOfAssistantMessage] as AssistantMessage).messageMetadata.csatScore = response.id;

        const helpseekerResponseObject: FeedbackResponse = {
            userType: 'feedbackResponse',
            messageContentAdf: doc(p(response.label)),
        };

        // the CSAT response will be shown after, Feedback Response: 'Yes' and CSAT Dropdown Message, so we have to hop 3 indices
        const targetIndex = indexOfAssistantMessage + 3;
        const newHistory = [...conversation.history];
        if (isVAFollowupQueryOnlyForPositiveResponseEnabled()) {
            const closeConversationObject: ChannelMessage = {
                userType: 'channelMessage',
                messageType: ChannelMessageTypes.CHANNEL_CONVERSATION_OVER,
            };
            newHistory.splice(targetIndex, 0, helpseekerResponseObject, closeConversationObject);
        } else {
            newHistory.splice(targetIndex, 0, helpseekerResponseObject);
        }
        conversation = { ...conversation, history: newHistory };

        setState({ conversation });
    };

const addCsatDropdownMessageOnlyForUI =
    (indexOfAssistantMessage: number, messageId: string): Action<State, void, void> =>
    async ({ getState, setState }) => {
        let conversation = getState().conversation;
        if (!conversation) {
            throw new Error('No conversation!');
        }

        const csatDropdownMessageObject: CsatDropdownMessage = {
            userType: 'csatDropdown',
            messageMetadata: {
                messageId,
            },
        };

        const newHistory = [...conversation.history];

        // the CSAT Dropdown will be shown after, Feedback Response: 'Yes', so we have to hop 2 indices
        newHistory.splice(indexOfAssistantMessage + 2, 0, csatDropdownMessageObject);
        conversation = { ...conversation, history: newHistory };

        setState({ conversation });
    };

const getRetryMessage = (originalQuery?: string, messageId?: string, isFallbackMessage?: boolean): RetryMessage => ({
    userType: 'retry',
    messageMetadata: {
        originalQuery,
        messageId,
        isFallbackMessage,
    },
});

export const addRetryMessageOnlyForUI =
    (id: string): Action<State, void, void> =>
    async ({ getState, setState }) => {
        let conversation = getState().conversation;

        if (!conversation) {
            throw new Error('No conversation!');
        }

        const indexOfAssistantMessage = conversation.history.findIndex(
            (message) => message.userType === 'assistant' && message.messageMetadata.messageId === id
        );
        if (indexOfAssistantMessage === -1) return;

        const relativeFeedbackResponseIndex = conversation.history
            .slice(indexOfAssistantMessage)
            .findIndex((message) => message.userType === 'feedbackResponse');

        const feedbackResponseIndex =
            relativeFeedbackResponseIndex === -1 ? -1 : relativeFeedbackResponseIndex + indexOfAssistantMessage;

        if (feedbackResponseIndex === -1) return;

        const assistantMessage = conversation.history[indexOfAssistantMessage] as AssistantMessage;

        const messageObject = getRetryMessage(
            assistantMessage?.aiMateConversationResponse?.message?.message_metadata?.originalQuery,
            id,
            assistantMessage?.aiMateConversationResponse?.message?.message_metadata?.isFallbackMessage
        );

        const newHistory = [...conversation.history];
        newHistory.splice(feedbackResponseIndex + 1, 0, messageObject);
        conversation = { ...conversation, history: newHistory };

        setState({ conversation });
    };

export const setFeedbackCollectorConfig =
    (isVisible: boolean, messageId?: string | undefined): Action<State, void, void> =>
    async ({ getState, setState }) => {
        const { messageId: existingMessageId } = getState().feedbackCollectorConfig;
        setState({
            feedbackCollectorConfig: {
                isVisible,
                messageId: messageId || existingMessageId,
            },
        });
    };

export const clearConversation =
    (): Action<State, void, void> =>
    async ({ setState, getState, dispatch }) => {
        const { activeTimer, conversation } = getState();
        const analyticsClient = getAnalyticsWebClient();

        const { conversationId, externalConversationId } = conversation || {};

        if (activeTimer) {
            clearTimeout(activeTimer);
        }

        if (conversationId) {
            dispatch(abortConversation());
            sendUIEventForClearConversation(analyticsClient, conversationId, externalConversationId);
        }

        setState({
            ...initialState,
        });
    };

export const abortConversation =
    (): Action<State, void, void> =>
    async ({ setState, getState }) => {
        const { conversation } = getState();
        if (!conversation || !conversation.conversationId || !conversation.storeAbortController) {
            return;
        }
        const lastAssistantMessage = conversation.history.slice(-1);

        if (lastAssistantMessage?.length && lastAssistantMessage[0].userType === ASSISTANT_USER_TYPE) {
            const abortedMessage = updateAbortedAssistantMessage(lastAssistantMessage[0]);
            setState({
                conversation: replaceLastAssistantMessage(abortedMessage, conversation),
                isLoading: false,
            });
            conversation.storeAbortController.abort();
        }
    };

export const takeOverConversationFromVA =
    (vaConversationId: string, history: ChatMessage[]): Action<State, void, Promise<void>> =>
    async ({ getState, setState, dispatch }) => {
        setState({
            isLoading: true,
        });
        await dispatch<Action<State>>(fetchChannelId(vaConversationId));

        const { conversation } = getState();

        if (!conversation) {
            throw new Error('No conversation!');
        }

        const originalQuery =
            history.find((message) => message.userType === 'helpseeker')?.messageMetadata?.originalQuery || '';
        setState({
            conversationStartTime: Date.now(),
            conversation: {
                ...conversation,
                history,
            },
        });

        dispatch(fetchConversation(originalQuery));
    };
