import type { FeaturedAndSortedPortalsResponse, SortBy } from 'rest/featured-and-sorted-portals';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { InjectedExperienceNotifierProps } from '@atlassian/help-center-common-component/analytics';
import type { ErrorResponse } from 'epics/model/types';
import type { ModelTypeOptions, ModelType } from 'rest/models/types';

export const FETCH_FEATURED_AND_SORTED_PORTALS_MODEL = 'epic.model.FETCH_FEATURED_AND_SORTED_PORTALS_MODEL';
export const FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS =
    'state.persisted.featuredAndSortedPortals.FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS';
export const FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE =
    'state.persisted.featuredAndSortedPortals.FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE';
export const MARK_PORTAL_FEATURED = 'state.ui.featuredAndSortedPortals.MARK_PORTAL_FEATURED';
export const REORDER_FEATURED_PORTAL = 'state.ui.featuredAndSortedPortals.REORDER_FEATURED_PORTAL';
export const TOGGLE_PORTAL_VISIBILITY = 'state.ui.featuredAndSortedPortals.TOGGLE_PORTAL_VISIBILITY';

export const UPDATE_PORTAL_REORDER_SETTINGS_MODEL = 'epic.portal-reorder-settings.UPDATE_PORTAL_REORDER_SETTINGS_MODEL';
export const UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS =
    'epic.portal-reorder-settings.UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS';
export const UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE =
    'epic.portal-reorder-settings.UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE';
export const UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS =
    'state.persisted.featuredAndSortedPortals.UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS';
export const UPDATE_PORTAL_REORDER_SETTINGS_FAILURE =
    'state.ui.featuredAndSortedPortals.UPDATE_PORTAL_REORDER_SETTINGS_FAILURE';

export const RESET_PORTAL_REORDER_SETTINGS = 'epic.portal-reorder-settings.RESET_PORTAL_REORDER_SETTINGS';
export const RESET_PORTAL_REORDER_SETTINGS_SUCCESS =
    'state.ui.featuredAndSortedPortals.RESET_PORTAL_REORDER_SETTINGS_SUCCESS';
export const MARK_PORTAL_REORDER_SETTINGS_DIRTY =
    'state.ui.featuredAndSortedPortals.MARK_PORTAL_REORDER_SETTINGS_DIRTY';

interface RequestParams {
    isPortalReorderingSelected: boolean;
    sortBy?: SortBy;
    exploreAll?: boolean;
}

/* isPortalReorderingSelected is used to distinguish between the calls made to featuredAndSortedPortals model in case of help-seeker view from that of portal reordering screen view, inside the state reducers */
const buildModelsActionCreatorForFeaturedAndSortedPortals =
    <TActionType extends string, TModelType extends ModelType>(actionType: TActionType, type: TModelType) =>
    (
        params: ModelTypeOptions[TModelType] = {} as ModelTypeOptions[TModelType],
        isPortalReorderingSelected: boolean
    ) => ({
        type: actionType,
        payload: {
            type,
            params: {
                ...params,
                isPortalReorderingSelected,
            },
        },
    });

export type FetchFeaturedAndSortedPortalsAction = ReturnType<typeof fetchFeaturedAndSortedPortalsModel>;

export const fetchFeaturedAndSortedPortalsModel = buildModelsActionCreatorForFeaturedAndSortedPortals(
    FETCH_FEATURED_AND_SORTED_PORTALS_MODEL,
    'featuredAndSortedPortals'
);

export interface FetchFeaturedAndSortedPortalsSuccess {
    type: typeof FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS;
    payload: FeaturedAndSortedPortalsResponse;
    requestParams: RequestParams;
}

export const fetchFeaturedAndSortedPortalsSuccess = (
    featuredAndSortedPortalsModel: FeaturedAndSortedPortalsResponse,
    requestParams: RequestParams
): FetchFeaturedAndSortedPortalsSuccess => ({
    requestParams,
    type: FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS,
    payload: featuredAndSortedPortalsModel,
});

export interface FetchFeaturedAndSortedPortalsFailure {
    type: typeof FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
    requestParams: RequestParams;
}

export const fetchFeaturedAndSortedPortalsFailure = (
    error: ErrorResponse,
    requestParams: RequestParams
): FetchFeaturedAndSortedPortalsFailure => ({
    requestParams,
    type: FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE,
    payload: {
        error,
    },
});

export interface MarkPortalFeatured {
    type: typeof MARK_PORTAL_FEATURED;
    payload: {
        id: number;
        isFeatured: boolean;
    };
}

/**
 *  This action is used whenever a card is featured or unfeatured by admin
 */
export const markPortalFeatured = (id: number, isFeatured: boolean): MarkPortalFeatured => ({
    type: MARK_PORTAL_FEATURED,
    payload: {
        isFeatured,
        id,
    },
});

export interface TogglePortalVisibility {
    type: typeof TOGGLE_PORTAL_VISIBILITY;
    payload: {
        id: number;
        hiddenFromHomeScreen: boolean;
    };
}
export const togglePortalVisibility = (id: number, hiddenFromHomeScreen: boolean): TogglePortalVisibility => ({
    type: TOGGLE_PORTAL_VISIBILITY,
    payload: {
        id,
        hiddenFromHomeScreen,
    },
});

export interface ReorderFeaturedPortal {
    type: typeof REORDER_FEATURED_PORTAL;
    payload: {
        oldIndex: number;
        newIndex: number;
    };
}

/**
 *  This action is used whenever the featured portals list is reordered using  drag and drop
 */
export const reorderFeaturedPortal = (oldIndex: number, newIndex: number): ReorderFeaturedPortal => ({
    type: REORDER_FEATURED_PORTAL,
    payload: {
        oldIndex,
        newIndex,
    },
});

export interface AnalyticsAttributes {
    topicsCreated: number;
    topicsUpdated: number;
    topicsDeleted: number;
    topicsReordered: boolean;
}
export interface AnalyticsMetaData {
    experienceManagerPayload: {
        analyticsSuccessEvent: InjectedExperienceNotifierProps['notifySuccess'];
        analyticsFailureEvent: InjectedExperienceNotifierProps['notifyFailure'];
    };
    analyticsPayload: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
    };
    analyticsAttributes?: AnalyticsAttributes;
}
export interface UpdatePortalReorderSettingsModel {
    type: typeof UPDATE_PORTAL_REORDER_SETTINGS_MODEL;
    meta?: AnalyticsMetaData;
}

export const updatePortalReorderSettingsModel = (meta?: AnalyticsMetaData): UpdatePortalReorderSettingsModel => ({
    meta,
    type: UPDATE_PORTAL_REORDER_SETTINGS_MODEL,
});

export interface UpdatePortalReorderSettingsModelSuccess {
    type: typeof UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS;
    meta?: AnalyticsMetaData;
}

export const updatePortalReorderSettingsModelSuccess = (
    meta?: AnalyticsMetaData
): UpdatePortalReorderSettingsModelSuccess => ({
    meta,
    type: UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS,
});

export interface UpdatePortalReorderSettingsModelFailure {
    type: typeof UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE;
    meta?: AnalyticsMetaData;
    error?: Error;
}

export const updatePortalReorderSettingsModelFailure = (
    meta?: AnalyticsMetaData,
    error?: Error
): UpdatePortalReorderSettingsModelFailure => ({
    meta,
    error,
    type: UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE,
});

export interface UpdatePortalReorderSettingsSuccess {
    type: typeof UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS;
    payload: {
        featuredPortalIds: number[];
        sortBy: SortBy;
        orderedPortalIds: number[];
        hiddenPortalIds: number[];
    };
}

export const updatePortalReorderSettingsSuccess = (
    featuredPortalIds: number[],
    sortBy: SortBy,
    orderedPortalIds: number[],
    hiddenPortalIds: number[]
): UpdatePortalReorderSettingsSuccess => ({
    type: UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS,
    payload: {
        featuredPortalIds,
        sortBy,
        orderedPortalIds,
        hiddenPortalIds,
    },
});

export interface UpdatePortalReorderSettingsFailure {
    type: typeof UPDATE_PORTAL_REORDER_SETTINGS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const updatePortalReorderSettingsFailure = (error: ErrorResponse): UpdatePortalReorderSettingsFailure => ({
    type: UPDATE_PORTAL_REORDER_SETTINGS_FAILURE,
    payload: {
        error,
    },
});

export interface ResetPortalReorderSettings {
    type: typeof RESET_PORTAL_REORDER_SETTINGS;
    payload: {
        shouldRedirect: boolean;
    };
}

export const resetPortalReorderSettings = (shouldRedirect: boolean): ResetPortalReorderSettings => ({
    type: RESET_PORTAL_REORDER_SETTINGS,
    payload: {
        shouldRedirect,
    },
});

export interface MarkPortalReorderSettingsDirty {
    type: typeof MARK_PORTAL_REORDER_SETTINGS_DIRTY;
}

export const markPortalReorderSettingsDirty = (): MarkPortalReorderSettingsDirty => ({
    type: MARK_PORTAL_REORDER_SETTINGS_DIRTY,
});

export interface ResetPortalReorderSettingsSuccess {
    type: typeof RESET_PORTAL_REORDER_SETTINGS_SUCCESS;
    payload: {
        featuredPortalIds: number[];
        orderedPortalIds: number[];
        sortBy: SortBy;
    };
}

export const resetPortalReorderSettingsSuccess = (
    featuredPortalIds: number[],
    orderedPortalIds: number[],
    sortBy: SortBy
): ResetPortalReorderSettingsSuccess => ({
    type: RESET_PORTAL_REORDER_SETTINGS_SUCCESS,
    payload: {
        featuredPortalIds,
        orderedPortalIds,
        sortBy,
    },
});
