import { get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { APOLLO_STARGATE_CONTEXT_ROOT } from 'rest/constants';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { contextPath as historyContextPath } from '@atlassian/help-center-common-util/history';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import type { ApolloErrorResponse } from 'epics/model/types';
export interface FetchCategoryRequest {
    projectId: number;
    contextPath?: string;
    apolloPath?: string;
}
export interface Category {
    id: string;
    name: string;
    description: string;
}

export interface NewCategoriesResponse {
    categories: Category[];
    categoriesSupported: boolean;
}

export type CategoriesResponse = Category[] | NewCategoriesResponse;

const mapApolloErrorResponse = (error: AjaxError): ApolloErrorResponse => {
    return { statusCode: error.status, errorKey: '', errorMessage: error.message || '' };
};

export const fetchCategories = ({ projectId, contextPath = historyContextPath }: FetchCategoryRequest) => {
    const activationId = getMeta('activation-id') as string;
    const uri = `${contextPath}${APOLLO_STARGATE_CONTEXT_ROOT}/${activationId}/api/project/${projectId}/category`;
    return (
        get(uri, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e: AjaxError) => {
                trackError('categories.fetch.all.failed', {}, e);
                return Observable.throw(mapApolloErrorResponse(e));
            })
    );
};
