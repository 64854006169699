import { defineMessages } from 'react-intl-next';

export default defineMessages({
    breadcrumbsLabel: {
        id: 'cv.portal.breadcrumbs.container',
        defaultMessage: 'Breadcrumbs',
    },
    raiseRequestBreadcrumb: {
        defaultMessage: 'Raise a request',
        description: 'Breadcrumb item to go to select a request group of a portal',
        id: 'cv.portal.breadcrumbs.raise.request',
    },
    breadcrumbsHome: {
        defaultMessage: 'Home',
        description: 'Generic label for the first entry of the breadcrumbs for Service hub',
        id: 'cv.portal.breadcrumbs.home',
    },
});
