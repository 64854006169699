import type { State } from 'state';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';

export const getRequestTypeSuggestions = (state: State): RequestTypeSuggestion[] => {
    return state.persisted.requestTypeSuggestion.suggestions;
};

export const getIsLoaded = (state: State): boolean => {
    return state.persisted.requestTypeSuggestion.isLoaded;
};
