import { ApprovalList } from 'view/approval-list';
import type { PaperAppearance } from 'view/layout/paper';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const route = {
    name: 'user-approvals',
    path: `/user/approvals`,
    exact: true,
    component: ApprovalList,
};

const screenName = ScreenName.APPROVAL_LIST;

const paperAppearance: PaperAppearance = 'wide';

const layoutProps = {
    paperAppearance,
    fadeInSearchModal: true,
};
export const approvalsRoute = createRouteWithLayout(route, screenName, layoutProps);
