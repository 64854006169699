import { di } from 'react-magnetic-di';
import { useGetJiraFieldMaxChars } from '@atlassian/help-center-virtual-agent/src/controllers/get-jira-field-max-chars';
import { MAX_CHARS } from '../../../common/constants';
import { ConversationProvider } from '../../../common/types';
import { useProvider } from '../../../store/routing';

export const useGetMaxChars = () => {
    di(useProvider, useGetJiraFieldMaxChars);
    const provider = useProvider();
    const { getJiraFieldMaxChars } = useGetJiraFieldMaxChars();

    const getMaxChars = () => {
        if (provider === ConversationProvider.VIRTUAL_AGENT) {
            const maxJiraFieldChars = getJiraFieldMaxChars();
            if (maxJiraFieldChars) {
                return maxJiraFieldChars;
            }
        }

        return MAX_CHARS;
    };

    return { getMaxChars };
};
