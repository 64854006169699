export type Process = (url: string, match: string[]) => string;

export interface URLProcessorOptions {
    /**
     * The name of the processor
     */
    name: string;
    /**
     * The processing to perform when the URL matches the pattern
     */
    onMatch: Process;
    /**
     * The regular expression to check the URL against
     */
    pattern: RegExp;
}

/**
 * Processes URLs that match a given pattern.
 */
export class URLProcessor {
    private hasMatched = false;
    private name: string;
    private onMatch: Process;
    private pattern: RegExp;

    /**
     * Processes URLs that match a given pattern.
     *
     * @param pattern The pattern to match the URL against
     * @param onMatch The action to perform on the URL
     */
    constructor(options: URLProcessorOptions) {
        this.name = options.name;
        this.onMatch = options.onMatch;
        this.pattern = options.pattern;
    }

    /**
     * True if the last URL processed matched the processor's pattern.
     */
    getHasMatched(): boolean {
        return this.hasMatched;
    }

    /**
     * The name of the processor.
     */
    getName(): string {
        return this.name;
    }

    /**
     * Attempt to process the URL.
     *
     * If the URL matches the processor's pattern, then the URL is processed
     * and `hasMatched` is set to true. Otherwise, return the URL as is, and
     * set `hasMatched` to false.
     *
     * @param url The URL to process
     * @returns The URL (processed if the pattern matched, otherwise as-is)
     */
    process(url: string): string {
        const match = url.match(this.pattern);

        if (match) {
            this.hasMatched = true;
            const processedUrl = this.onMatch(url, match);
            return processedUrl;
        }

        this.hasMatched = false;

        return url;
    }
}

export const processUrl = (url: string, processors: URLProcessor[]): { url: string; isProcessed: boolean } => {
    for (const processor of processors) {
        const processedUrl = processor.process(url);

        if (processor.getHasMatched()) {
            return {
                url: processedUrl,
                isProcessed: true,
            };
        }
    }

    return {
        url,
        isProcessed: false,
    };
};
