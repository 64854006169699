import type { UpdateTopicsFetchKey } from 'state/actions/topics';
import { UPDATE_TOPICS_FETCH_KEY } from 'state/actions/topics';

export interface topicsUIState {
    fetchKey: number;
}

export const topicsDefaultState: topicsUIState = {
    fetchKey: 0,
};

export default function reducer(
    state: topicsUIState = topicsDefaultState,
    action: UpdateTopicsFetchKey
): topicsUIState {
    if (action.type === UPDATE_TOPICS_FETCH_KEY) {
        return {
            ...state,
            fetchKey: state.fetchKey + 1,
        };
    }
    return state;
}
