import type { State } from 'state';
import type { RequestCreateFormState } from 'state/persisted/request-create';
import type { TemplateForm } from '@atlassian/proforma-common-core/form-system-models';

export const getForm = (state: State, portalId: number | undefined, requestTypeId: number | undefined) => {
    if (!portalId || !requestTypeId) {
        return undefined;
    }

    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.form;
};

export const getFormName = (state: State, portalId: number | undefined, requestTypeId: number | undefined) => {
    const form = getForm(state, portalId, requestTypeId);
    return form && form.name;
};

export const getFormIntro = (state: State, portalId: number, requestTypeId: number) => {
    const form = getForm(state, portalId, requestTypeId);
    return form && form.intro;
};

export const getIconUrl = (state: State, portalId: number, requestTypeId: number) => {
    const form = getForm(state, portalId, requestTypeId);
    return form && form.iconUrl;
};

export const getFormInstructions = (state: State, portalId: number, requestTypeId: number) => {
    const form = getForm(state, portalId, requestTypeId);
    return form && form.instructions;
};

export const getFields = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.fields;
};

/**
 * @deprecated mediaApiUploadToken is moved to portal state
 */
export const getMediaApiUploadToken = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.mediaApiUploadToken;
};

/**
 * @deprecated mediaApiError is moved to portal state
 */
export const getMediaApiError = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.mediaApiError;
};

export const getRequestCreate = (
    state: State,
    portalId: number,
    requestTypeId: number
): RequestCreateFormState | undefined => {
    const data = getRequestCreateOrError(state, portalId, requestTypeId);
    return data && 'error' in data ? undefined : data;
};

export const getRequestCreateOrError = (state: State, portalId: number | string, requestTypeId: number | string) => {
    const id = `${portalId}/${requestTypeId}`;
    return state.persisted.requestCreate[id];
};

export const getProjectId = (state: State, portalId: number, requestTypeId: number): number | undefined => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.projectId;
};

export const isCaptchaEnabled = (state: State, portalId: number, requestTypeId: number) => {
    const form = getForm(state, portalId, requestTypeId);
    return !!form && form.captchaEnabled && !!form.sitekey;
};

export const getCaptchaKey = (state: State, portalId: number, requestTypeId: number) => {
    const form = getForm(state, portalId, requestTypeId);
    return form && form.captchaEnabled ? form.sitekey : undefined;
};

export const getCanCreateUsers = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return !!requestCreate && !!requestCreate.canCreateUsers;
};

export const getDateFormat = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.dateFormat;
};

export const getDateTimeFormat = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.dateTimeFormat;
};

export const getFirstDay = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.firstDay;
};

export const getUseISO8601WeekNumbers = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return requestCreate && requestCreate.useISO8601WeekNumbers;
};

export const getOrganisationsForCurrentUser = (state: State, portalId: number, requestTypeId: number) => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return !!requestCreate ? requestCreate.organisationsForCurrentUser : [];
};

export const getProjectHasProformaForm = (state: State, portalId: number, requestTypeId: number): boolean => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return !!requestCreate?.projectHasProformaForms;
};

export const getRequestTypeHasProformaForm = (state: State, portalId: number, requestTypeId: number): boolean => {
    const requestCreate = getRequestCreate(state, portalId, requestTypeId);
    return !!requestCreate?.proforma.formExists;
};

export const getProformaTemplateForm = (
    state: State,
    portalId: number,
    requestTypeId: number
): TemplateForm | undefined => {
    const proformaTemplateForm = getRequestCreate(state, portalId, requestTypeId)?.proformaTemplateForm;
    if (!proformaTemplateForm || Object.keys(proformaTemplateForm).length === 0) {
        return undefined;
    }
    return proformaTemplateForm as TemplateForm;
};

export const getIsProformaFormDirty = (state: State, portalId: number, requestTypeId: number): boolean => {
    return !!getRequestCreate(state, portalId, requestTypeId)?.proformaFormIsDirty;
};
