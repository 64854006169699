import { safeAccessWithErrorHandler } from '../safe-access-with-error-handler';

const getLocalStorageItem = (key: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(() => localStorage.getItem(key), errorHandler);
};

const setLocalStorageItem = (key: string, value: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(() => localStorage.setItem(key, value), errorHandler);
};

const removeLocalStorageItem = (key: string, errorHandler?: () => void) => {
    return safeAccessWithErrorHandler(() => localStorage.removeItem(key), errorHandler);
};

export { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem };
