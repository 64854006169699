import * as React from 'react';

interface State {
    render: boolean;
}

export interface RenderNextAnimationFrameProps {
    delay?: number;
    children: () => React.ReactNode;
}

export default class RenderNextAnimationFrame extends React.Component<RenderNextAnimationFrameProps, State> {
    state = {
        render: false,
    };

    componentDidMount() {
        const renderNextAnimationFrame = () => {
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    this.setState({
                        render: true,
                    });
                });
            });
        };

        if (this.props.delay) {
            window.setTimeout(renderNextAnimationFrame, this.props.delay);
        } else {
            renderNextAnimationFrame();
        }
    }

    render() {
        return this.state.render ? this.props.children() : null;
    }
}
