import {
    FETCH_RESEND_VERIFICATION_FAILURE,
    FETCH_RESEND_VERIFICATION_SUCCESS,
    FETCH_RESEND_VERIFY_EMAIL,
} from 'state/actions/resend-verify-email';
import { ResendStatus, type HandledActions, type ResendVerifyEmailState } from './types';

export const defaultState: ResendVerifyEmailState = {
    value: ResendStatus.NONE,
};

export function resendVerifyReducer(
    state: ResendVerifyEmailState = defaultState,
    action: HandledActions
): ResendVerifyEmailState {
    switch (action.type) {
        case FETCH_RESEND_VERIFY_EMAIL:
            return {
                value: ResendStatus.RESENDING,
            };
        case FETCH_RESEND_VERIFICATION_SUCCESS:
            return {
                value: ResendStatus.SUCCEEDED,
            };
        case FETCH_RESEND_VERIFICATION_FAILURE:
            return {
                errorStatus: action.payload.error,
                value: ResendStatus.FAILED,
            };
        default:
            return state;
    }
}
