/**
 * @generated SignedSource<<e9a2845fdb0ebf85b6c1c8816fcdfced>>
 * @relayHash 71f2f66044bc8f8bd3ab026ad2505549
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 00910c359e88e27b753f51cb8a569097d0d751c8ced7cfea7f2763072ff56f4b

import type { ConcreteRequest, Query } from 'relay-runtime';
export type loginAnnouncementsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type loginAnnouncementsQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly announcements: {
        readonly loginAnnouncements: ReadonlyArray<{
          readonly descriptionTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
          readonly nameTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly siteDefaultLanguageTag: string | null | undefined;
    } | {
      readonly __typename: "QueryError";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type loginAnnouncementsQuery = {
  response: loginAnnouncementsQuery$data;
  variables: loginAnnouncementsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "siteDefaultLanguageTag"
},
v5 = [
  {
    "kind": "ScalarField",
    "name": "locale"
  },
  {
    "kind": "ScalarField",
    "name": "localeDisplayName"
  },
  {
    "kind": "ScalarField",
    "name": "value"
  }
],
v6 = {
  "concreteType": "HelpCenterAnnouncements",
  "kind": "LinkedField",
  "name": "announcements",
  "plural": false,
  "selections": [
    {
      "concreteType": "HelpCenterAnnouncement",
      "kind": "LinkedField",
      "name": "loginAnnouncements",
      "plural": true,
      "selections": [
        {
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "nameTranslationsRaw",
          "plural": true,
          "selections": (v5/*: any*/)
        },
        {
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "descriptionTranslationsRaw",
          "plural": true,
          "selections": (v5/*: any*/)
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "loginAnnouncementsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "loginAnnouncementsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "00910c359e88e27b753f51cb8a569097d0d751c8ced7cfea7f2763072ff56f4b",
    "metadata": {},
    "name": "loginAnnouncementsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "76319886fc90231eee48f51d80798d47";

export default node;
