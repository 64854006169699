import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as grid from 'view/styles/grid';
import { token } from '@atlaskit/tokens';

interface LogoStatelessProps {
    url?: string | null;
    name: string;
    topBarTextColor?: string;
}

const LogoStateless: React.FunctionComponent<LogoStatelessProps> = ({ url, name, topBarTextColor }) => {
    const content = url ? <Image src={url} alt={name} /> : <span>{name}</span>;

    return <Root topBarTextColor={topBarTextColor}>{content}</Root>;
};

export default LogoStateless;

const imageHeight = grid.unitless * 5;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.div`
    height: ${imageHeight}px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: ${token('space.075', '6px')};
    font-weight: 600;
    color: ${(props: { topBarTextColor?: string }) => props.topBarTextColor || 'inherit'};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Image = styled.img`
    height: ${imageHeight}px;
`;
