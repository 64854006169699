import type { LoginAttemptResponse } from 'rest/login';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';
import type { AuthInfo, LoginType } from '@atlassian/help-center-common-util/login';
import type { ErrorResponse } from 'epics/model/types';
export const FETCH_LOGIN_SUCCESS = 'state.ui.login.FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'state.ui.login.FETCH_LOGIN_FAILURE';

export const CHECK_LOGIN_ACTION = 'state.ui.login.CHECK_LOGIN_ACTION';
export const CHECK_LOGIN_ACTION_SUCCESS = 'state.ui.login.CHECK_LOGIN_ACTION_SUCCESS';
export const CHECK_LOGIN_ACTION_FAILURE = 'state.ui.login.CHECK_LOGIN_ACTION_FAILURE';

export const FORGOT_PASSWORD = 'state.ui.login.FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'state.ui.login.FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'state.ui.login.FORGOT_PASSWORD_FAILURE';
export const LOGIN_ATTEMPT = 'state.ui.login.LOGIN_ATTEMPT';
export const LOGIN_ATTEMPT_SUCCESS = 'state.ui.login.LOGIN_ATTEMPT_SUCCESS';
export const LOGIN_ATTEMPT_FAILURE = 'state.ui.login.LOGIN_ATTEMPT_FAILURE';
export const RESET_LOGIN = 'state.ui.login.RESET_LOGIN';
export const EXIT_TEST_MODE_ACTION = 'state.ui.login.EXIT_TEST_MODE_ACTION';
export const CHANGE_EMAIL_INPUT = 'state.ui.login.CHANGE_EMAIL_INPUT';
export const CA_SSO_TEST_MODE_FAILURE = 'state.ui.login.CA_SSO_TEST_MODE_FAILURE';
export const LOGIN_CONSENT_FAILURE = 'state.ui.login.LOGIN_CONSENT_FAILURE';

export interface LoginResponse {
    allowCookies: boolean;
    contactAdminLink: string;
    canReset: boolean;
    sdUserSignUpEnabled: boolean;
    redirectUrl: string;
    aidEnabled: boolean;
    /**
     * These two fields would always be available
     * If announcement is absent
     * Either because FF is off
     * Or admins have not configured it
     * It'd be empty strings for both
     */
    loginAnnouncementSubject: string;
    loginAnnouncementMessage: string;
    loginAnnouncementLocale: string;
}

export interface FetchLoginSuccess {
    type: typeof FETCH_LOGIN_SUCCESS;
    payload: LoginResponse;
}

export const fetchLoginSuccess = (loginModel: LoginResponse): FetchLoginSuccess => ({
    payload: loginModel,
    type: FETCH_LOGIN_SUCCESS,
});

export interface FetchLoginFailure {
    type: typeof FETCH_LOGIN_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchLoginFailure = (error: ErrorResponse): FetchLoginFailure => ({
    type: FETCH_LOGIN_FAILURE,
    payload: {
        error,
    },
});

export interface AuthData {
    ssoTest: boolean;
    redirectTo: string;
    absoluteRedirectUrl: boolean;
}

export interface CheckLoginAction {
    type: typeof CHECK_LOGIN_ACTION;
    payload: {
        email: string;
        portalId: string | undefined;
        authData: AuthData;
        createAnalyticsEvent?: CreateUIAnalyticsEvent;
    };
}

export const checkLoginAction = (
    email: string,
    portalId: string | undefined,
    authData: AuthData,
    createAnalyticsEvent?: CreateUIAnalyticsEvent
): CheckLoginAction => ({
    type: CHECK_LOGIN_ACTION,
    payload: {
        email,
        portalId,
        authData,
        createAnalyticsEvent,
    },
});

export interface CheckLoginActionSuccess {
    type: typeof CHECK_LOGIN_ACTION_SUCCESS;
    payload: {
        action: LoginType;
        authInfo: AuthInfo;
    };
}

export const checkLoginActionSuccess = (action: LoginType, authInfo: AuthInfo): CheckLoginActionSuccess => ({
    type: CHECK_LOGIN_ACTION_SUCCESS,
    payload: {
        action,
        authInfo,
    },
});

export interface CheckLoginActionFailure {
    type: typeof CHECK_LOGIN_ACTION_FAILURE;
    payload: {
        error: DefaultError;
    };
}

export const checkLoginActionFailure = (error: DefaultError): CheckLoginActionFailure => ({
    type: CHECK_LOGIN_ACTION_FAILURE,
    payload: {
        error,
    },
});

export interface LoginAttempt {
    type: typeof LOGIN_ATTEMPT;
    payload: {
        email: string;
        password: string;
    };
}

export const loginAttempt = (email: string, password: string): LoginAttempt => ({
    type: LOGIN_ATTEMPT,
    payload: {
        email,
        password,
    },
});

export interface LoginAttemptSuccess {
    type: typeof LOGIN_ATTEMPT_SUCCESS;
    payload: {
        loginAttemptResponse: LoginAttemptResponse;
    };
}

export const loginAttemptSuccess = (loginAttemptResponse: LoginAttemptResponse): LoginAttemptSuccess => ({
    type: LOGIN_ATTEMPT_SUCCESS,
    payload: {
        loginAttemptResponse,
    },
});

export interface LoginAttemptFailure {
    type: typeof LOGIN_ATTEMPT_FAILURE;
    payload: {
        error: DefaultError;
    };
}

export const loginAttemptFailure = (error: DefaultError): LoginAttemptFailure => ({
    type: LOGIN_ATTEMPT_FAILURE,
    payload: {
        error,
    },
});

export interface ForgotPassword {
    type: typeof FORGOT_PASSWORD;
    payload: {
        resetUrl: string;
        email: string;
    };
}

export const forgotPassword = (resetUrl: string, email: string): ForgotPassword => ({
    type: FORGOT_PASSWORD,
    payload: { resetUrl, email },
});

export interface ForgotPasswordSuccess {
    type: typeof FORGOT_PASSWORD_SUCCESS;
}

export const forgotPasswordSuccess = (): ForgotPasswordSuccess => ({
    type: FORGOT_PASSWORD_SUCCESS,
});

export interface ForgotPasswordFailure {
    type: typeof FORGOT_PASSWORD_FAILURE;
    payload: {
        error: DefaultError;
    };
}

export const forgotPasswordFailure = (error: DefaultError): ForgotPasswordFailure => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: {
        error,
    },
});

export interface ResetLogin {
    type: typeof RESET_LOGIN;
    payload: {
        email: string;
    };
}

export const resetLogin = (email: string = ''): ResetLogin => ({
    type: RESET_LOGIN,
    payload: {
        email,
    },
});

export interface ExitTestModeAction {
    type: typeof EXIT_TEST_MODE_ACTION;
    payload: {
        email: string;
    };
}

export const exitTestModeAction = (email: string = ''): ExitTestModeAction => ({
    type: EXIT_TEST_MODE_ACTION,
    payload: {
        email,
    },
});

export interface ChangeEmailInput {
    type: typeof CHANGE_EMAIL_INPUT;
    payload: {
        email: string;
    };
}

export const changeEmailInput = (email: string): ChangeEmailInput => ({
    type: CHANGE_EMAIL_INPUT,
    payload: {
        email,
    },
});

export interface CaSsoTestModeFailure {
    type: typeof CA_SSO_TEST_MODE_FAILURE;
}

export const caSsoTestModeFailure = (): CaSsoTestModeFailure => ({
    type: CA_SSO_TEST_MODE_FAILURE,
});

export interface LoginConsentFailure {
    type: typeof LOGIN_CONSENT_FAILURE;
}

export const loginConsentFailure = (): LoginConsentFailure => ({
    type: LOGIN_CONSENT_FAILURE,
});
