import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { subscribe, unsubscribe } from 'rest/notification';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import type { ChangeSubscription, ChangeSubscriptionSuccess } from 'state/actions/request-details';
import { CHANGE_SUBSCRIPTION, changeSubscriptionSuccess } from 'state/actions/request-details';

export const changeRequestSubscriptionEpic: Epic<ChangeSubscription, ChangeSubscriptionSuccess | HandleAjaxError> = (
    action$: ActionsObservable<ChangeSubscription>
) => {
    return action$.ofType(CHANGE_SUBSCRIPTION).mergeMap((changeSubscriptionAction) => {
        const { shouldSubscribe, requestId, requestKey } = changeSubscriptionAction.payload;
        const restCall = shouldSubscribe ? subscribe : unsubscribe;
        return restCall(requestId)
            .map(() => {
                return changeSubscriptionSuccess(requestKey, shouldSubscribe);
            })
            .catch((error: AjaxError) => {
                return Observable.of(handleAjaxError(error));
            });
    });
};
