import { transparentize, getLuminance } from 'polished';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

/**
 * This will prefer light over dark - only the brightest of colors will get dark text.
 */

export const readableTextColor = (color: string): string => {
    return getLuminance(color) > 0.5 ? token('color.text', colors.N800) : token('color.text.inverse', colors.N0);
};

export const lightenColor = (color: string) => {
    return transparentize(0.8, color);
};
