import { addLocaleData } from 'react-intl';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import et from 'react-intl/locale-data/et';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import hu from 'react-intl/locale-data/hu';
import is from 'react-intl/locale-data/is';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import nl from 'react-intl/locale-data/nl';
import no from 'react-intl/locale-data/no';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import ru from 'react-intl/locale-data/ru';
import sk from 'react-intl/locale-data/sk';
import sv from 'react-intl/locale-data/sv';
import tr from 'react-intl/locale-data/tr';
import uk from 'react-intl/locale-data/uk';
import vi from 'react-intl/locale-data/vi';
import zh from 'react-intl/locale-data/zh';

let hasAlreadyAdded = false;

// initiated supported locale data for usage with react-intl. This should be done only once
export const addSuportedLocaleData = () => {
    if (hasAlreadyAdded) {
        return;
    }
    addLocaleData([
        ...en,
        ...de,
        ...es,
        ...fr,
        ...ja,
        ...ko,
        ...pt,
        ...ru,
        ...cs,
        ...fi,
        ...hu,
        ...it,
        ...no,
        ...ro,
        ...sk,
        ...zh,
        ...da,
        ...et,
        ...is,
        ...nl,
        ...pl,
        ...sv,
        ...vi,
        ...uk,
        ...tr,
    ]);
    hasAlreadyAdded = true;
};

// get language from locale for usage with react-intl. For eg: en_US => en
export const getLanguageFromLocale = (locale: string | undefined | null) => {
    if (locale && locale.trim()) {
        if (locale === 'pt_BR' || locale === 'pt-BR') {
            return 'pt-BR';
        }
        return locale.split('_')[0].split('-')[0].trim();
    }

    // default to 'en'
    return 'en';
};
