import type { AjaxResponse } from 'rxjs';
import { Observable } from 'rxjs';
import { post } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const invitedSignUp = (
    portalId: number,
    email: string,
    fullName: string,
    password: string,
    token: string,
    username: string,
    context: string = contextPath
) => {
    const uri = `${context}/rest/servicedesk/1/customer/pages/portal/${portalId}/user/setpassword`;
    const body = createBody(email, fullName, password, token, username);
    const headers = {
        'Content-Type': 'application/json',
    };

    return (
        post(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};

const createBody = (email: string, fullName: string, password: string, token: string, username: string) => {
    return JSON.stringify({
        fullName,
        password,
        username,
        email,
        token,
    });
};
