import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

const subscriptionReplyExperienceId = 'service-management.help-center.omnichannel-v2.subscription-connection';
const firstIntentReplyExperienceId = 'service-management.help-center.omnichannel-v2.firs-intent-reply';
const initialMessageExperienceId = 'service-management.help-center.omnichannel-v2.initial-message';
const OmnichannelpageLoadExperienceId = 'service-management.help-center.omnichannel-v2.page-load';

export const subscriptionReplyExperience = new UFOExperience(subscriptionReplyExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const firstIntentReply = new UFOExperience(firstIntentReplyExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const initialMessageOmnichannelV2Experience = new UFOExperience(initialMessageExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const omnichannelPageLoadExperience = new UFOExperience(OmnichannelpageLoadExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
