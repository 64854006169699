import { isWrmOptimizationsEnabled } from 'feature-flags';
import { once } from 'lodash';
import { trackError } from '@atlassian/help-center-common-util/analytics';

import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { awaitGlobal } from '@atlassian/help-center-common-util/connect/await-global';
import { initialise } from '@atlassian/help-center-common-util/connect/register-api';
import { withDeferredWrmLoaded } from '@atlassian/help-center-common-util/wrm';
import { ConnectFragmentDumb as ConnectFragmentDumbOld } from './connect-fragment-dumb';
import { ConnectFragmentsDumb as ConnectFragmentsDumbOld } from './connect-fragments-dumb';
import ConnectFragmentOld from './hocs';

export {
    HELP_CENTER,
    PORTAL_HOME,
    REQUEST_CREATE,
    REQUEST_DETAILS,
    REQUEST_LIST,
    PROFILE,
    PROFILE_PANEL,
    REQUEST_CREATE_PANEL,
    HEADER,
    SUB_HEADER,
    FOOTER,
    DETAILS,
    DETAILS_SIDE_SECTION,
} from './connect-fragment';

export const initializeConnect = once(() => {
    try {
        awaitGlobal('connectHost', initialise);
    } catch (e) {
        trackError('connect.fragment.init.failed', {}, e);

        operationalEvent({
            action: 'failed',
            actionSubject: 'connectFragmentInit',
            source: 'unknownSource',
            attributes: {
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                errorMessage: e.message,
            },
        });
    }
});

export const ConnectFragment = isWrmOptimizationsEnabled()
    ? withDeferredWrmLoaded(ConnectFragmentOld, initializeConnect)
    : ConnectFragmentOld;

export const ConnectFragmentDumb = isWrmOptimizationsEnabled()
    ? withDeferredWrmLoaded(ConnectFragmentDumbOld, initializeConnect)
    : ConnectFragmentDumbOld;

export const ConnectFragmentsDumb = isWrmOptimizationsEnabled()
    ? withDeferredWrmLoaded(ConnectFragmentsDumbOld, initializeConnect)
    : ConnectFragmentsDumbOld;
