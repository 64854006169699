import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { isSearchUIRedesignEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { Box, Grid, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { urlRefsAndTarget } from '@atlassian/help-center-common-util/is-external-resource';
import { getUpdatedDisplayLinkForArticleSearch } from '@atlassian/help-center-common-util/url';
import { ArticleMetadata } from '../article-metadata';
import messages from '../articles/messages';
import {
    Title,
    LinkContainer,
    Snippet,
    formatText,
    isSinglePortalSearch,
    getPortalIdAsNumber,
    sendSearchUIAnalyticEvents,
    useVisibilityState,
} from '../common';
import { ResourceIcon } from '../resource-icon';
import type { articleItemFragment$key } from './__generated__/articleItemFragment.graphql';

export interface Props {
    isLoggedIn: boolean;
    itemOrder: number;
    term: string;
    showAllResults?: boolean;
    result: articleItemFragment$key;
    projectId?: number;
    themeColor: string;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const ArticleItem = ({ itemOrder, result, term, showAllResults, themeColor, createAnalyticsEvent }: Props) => {
    di(isSearchUIRedesignEnabled);
    const { formatMessage } = useIntl();
    const data = useFragment<articleItemFragment$key>(
        graphql`
            fragment articleItemFragment on HelpObjectStoreSearchResult {
                __typename @required(action: THROW)
                id @required(action: THROW)
                title @required(action: THROW)
                description @required(action: THROW)
                displayLink @required(action: THROW)
                isExternal @required(action: THROW)
                searchBackend
                searchAlgorithm
                ...resourceIconFragment
                ...articleMetadataFragment
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');
    const portalIdAsNumber = getPortalIdAsNumber(portalId);
    const { title, description, displayLink, isExternal, id, searchBackend, searchAlgorithm } = data;
    const shortcutIcon = isExternal ? <ShortcutIcon label={formatMessage(messages.shortcutIcon)} size="small" /> : null;
    const highlightedTitle = title ? formatText(title, term) : '';
    const highlightedDescription = description ? formatText(description, term) : '';
    const resourceDisplayLink =
        isSinglePortalSearch(portalIdAsNumber) && showAllResults
            ? `/portal/${portalIdAsNumber}/article/${id}`
            : getUpdatedDisplayLinkForArticleSearch(displayLink);
    const linkProps = urlRefsAndTarget({
        displayLink: resourceDisplayLink,
        isAnExternalResource: isExternal,
    });

    useEffect(() => {
        sendSearchUIAnalyticEvents(
            {
                searchBackend,
                searchAlgorithm,
                action: 'list-item-rendered',
                resourceType: 'article',
            },
            createAnalyticsEvent,
            portalId
        );
    }, [createAnalyticsEvent, portalId, searchAlgorithm, searchBackend]);

    const { isShortcutIconVisible, setIsHovered, setIsFocused } = useVisibilityState();

    if (!isSearchUIRedesignEnabled()) {
        return (
            <LinkContainer
                key={id}
                actionSubjectId={isExternal ? 'external-article-link' : 'article-link'}
                data-test-id="result-link-container"
                attributes={{
                    itemOrder,
                    searchBackend,
                    searchAlgorithm,
                }}
                {...linkProps}
            >
                <ResourceIcon result={data} />
                <Box
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    xcss={xcss({
                        overflow: 'hidden',
                        width: '100%',
                        paddingLeft: token('space.200', '16px'),
                        paddingRight: token('space.200', '16px'),
                    })}
                >
                    <Title dangerouslySetInnerHTML={{ __html: sanitize(highlightedTitle) }} />
                    <Snippet dangerouslySetInnerHTML={{ __html: sanitize(highlightedDescription) }} />
                </Box>
                {shortcutIcon}
            </LinkContainer>
        );
    }

    return (
        <Box
            key={id}
            role="listitem"
            xcss={containerStyles}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocusCapture={() => setIsFocused(true)}
            onBlurCapture={() => setIsFocused(false)}
            testId="search-page-result"
        >
            <Grid
                columnGap="space.150"
                templateAreas={['icon heading header-right', 'icon subheading-left header-right', '. body body']}
                templateColumns="auto 1fr auto"
            >
                <Box xcss={iconAreaStyles}>
                    <ResourceIcon result={data} />
                </Box>
                <Box xcss={headingAreaStyles}>
                    <Link
                        key={id}
                        href={resourceDisplayLink}
                        actionSubjectId={isExternal ? 'external-article-link' : 'article-link'}
                        data-test-id="result-link-container"
                        attributes={{
                            itemOrder,
                            searchBackend,
                            searchAlgorithm,
                        }}
                        {...linkProps}
                    >
                        <Text as="p">
                            <span
                                style={{
                                    color: themeColor,
                                }}
                                dangerouslySetInnerHTML={{ __html: sanitize(highlightedTitle) }}
                            />
                        </Text>
                    </Link>
                </Box>
                <Box xcss={[subheadingLeftAreaStyles, subheadingStyles]} testId="search-page-result-metadata">
                    <ArticleMetadata result={data} />
                </Box>
                <Box
                    xcss={isShortcutIconVisible ? headerRightAreaStylesVisible : headerRightAreaStylesInVisible}
                    testId="search-result-shortcut-icon"
                >
                    {shortcutIcon}
                </Box>
                <Box xcss={bodyAreaStyles}>
                    <Text>
                        <p dangerouslySetInnerHTML={{ __html: sanitize(highlightedDescription) }} />
                    </Text>
                </Box>
            </Grid>
        </Box>
    );
};

const ConnectedArticleItem = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
        themeColor: getThemeColor(state),
    };
})(ArticleItem);

export default withAnalyticsEvents()(ConnectedArticleItem);

const containerStyles = xcss({
    padding: 'space.100',
    marginLeft: 'space.negative.100',
    marginRight: 'space.negative.100',
    borderRadius: 'border.radius.100',
});
const iconAreaStyles = xcss({
    gridArea: 'icon',
    width: 'size.300',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    textDecoration: 'none',
});
const headingAreaStyles = xcss({
    gridArea: 'heading',
    lineHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    font: token('font.body.large', 'normal'),
});
const subheadingStyles = xcss({
    color: 'color.text.subtlest',
    marginTop: 'space.050',
    fontSize: '12px',
    lineHeight: '16px',
    overflow: 'clip',
    overflowClipMargin: '5px',
});
const subheadingLeftAreaStyles = xcss({
    gridArea: 'subheading-left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '16px',
});
const headerRightAreaStylesInVisible = xcss({
    gridArea: 'header-right',
    display: 'flex',
    opacity: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const headerRightAreaStylesVisible = xcss({
    gridArea: 'header-right',
    display: 'flex',
    opacity: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
});
const bodyAreaStyles = xcss({
    gridArea: 'body',
    marginTop: 'space.100',
    overflowWrap: 'break-word',
});
