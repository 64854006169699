import React from 'react';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@helpCenter/view/common/constants';
import { isMoveToManagementRouterEnabled, isProjectMappingP1BugsEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { graphql, useFragment } from 'react-relay';
import Breadcrumbs from '@atlaskit/breadcrumbs';
import { Box } from '@atlaskit/primitives';
import { BreadCrumb } from '@atlassian/help-center-common-component/bread-crumbs/bread-crumb-dumb';
import type { settingsBreadcrumbsFragment$key } from './__generated__/settingsBreadcrumbsFragment.graphql';
import messages from './messages';

interface SettingsBreadcrumbsProps {
    helpCenterData: settingsBreadcrumbsFragment$key;
}

const SettingsBreadcrumbs = ({ helpCenterData }: SettingsBreadcrumbsProps) => {
    const data = useFragment(
        graphql`
            fragment settingsBreadcrumbsFragment on HelpCenter {
                name {
                    default
                }
                slug @optIn(to: "HelpCenterSlugTest")
            }
        `,
        helpCenterData
    );
    const helpCenterName = data?.name?.default || '';
    const slug = data?.slug;
    const { formatMessage } = useIntl();

    const rootBreadcrumbUrl: string = isMoveToManagementRouterEnabled()
        ? '/'
        : `${window.location.origin}${HELP_CENTERS_MANAGEMENT_BASE_PATH}`;

    const getHelpcenterBreadcrumbUrl = () => {
        if (isMoveToManagementRouterEnabled()) {
            return !slug
                ? `${window.location.origin}/servicedesk/customer`
                : `${window.location.origin}/helpcenter/${slug}`;
        }
        return '/';
    };

    return (
        <Box paddingBlockStart="space.400">
            <Breadcrumbs label={formatMessage(messages.breadcrumbsLabel)}>
                <BreadCrumb
                    isFirstCrumb
                    to={rootBreadcrumbUrl}
                    name={formatMessage(messages.settingsPageBreadcrumbItem)}
                    actionSubjectId="helpCenterBreadcrumb"
                    disableTheme={isProjectMappingP1BugsEnabled()}
                >
                    <BreadCrumb
                        to={getHelpcenterBreadcrumbUrl()}
                        name={helpCenterName}
                        actionSubjectId="helpDeskBreadcrumb"
                        disableTheme={isProjectMappingP1BugsEnabled()}
                    ></BreadCrumb>
                </BreadCrumb>
            </Breadcrumbs>
        </Box>
    );
};

export default SettingsBreadcrumbs;
