import * as React from 'react';
import { useMediaQuery } from './use-screen-size';
export interface WithScreenSizeProps {
    isMediaQueryMatch: boolean;
}

export const withMatchScreenSize =
    <P extends object>(query: string | number) =>
    (WrappedComponent: React.ComponentType<P>) => {
        const Component: React.FC<P & WithScreenSizeProps> = (props) => {
            const match = useMediaQuery(`(max-width: ${query}px)`);
            return <WrappedComponent {...props} isMediaQueryMatch={match} />;
        };
        Component.displayName = `withMatchScreenSize(${WrappedComponent.name})`;
        return Component;
    };
