import type { AjaxResponse } from 'rxjs';
import { get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import * as qs from 'query-string';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { ConfluencePageAri, JiraProjectAri } from '@atlassian/ari';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { getMeta } from '@atlassian/help-center-common-util/meta';

interface FetchCrossSiteArticleParams {
    articleId: string;
    projectId: number | undefined;
    crossSiteTenantId: string;
}

export interface FetchCrossSiteArticleResponse {
    pageAri: string;
    title: string;
    space: {
        id: string;
        key: string;
    };
    projects: [
        {
            projectId: string;
            projectAri: string;
            portalId: string;
        },
    ];
    hostname: string;
}

interface FetchCrossSiteArticleAjaxResponse extends AjaxResponse {
    response: FetchCrossSiteArticleResponse;
}

export const fetchCrossSiteArticles = ({
    articleId,
    projectId,
    crossSiteTenantId,
}: FetchCrossSiteArticleParams): Observable<FetchCrossSiteArticleResponse> => {
    const cloudId = getMeta('tenant-id') || '';
    const pageAri = ConfluencePageAri.create({ siteId: crossSiteTenantId, pageId: articleId }).toString();
    const projectAri =
        projectId && JiraProjectAri.create({ projectId: projectId.toString(), siteId: cloudId }).toString();

    const queryString = qs.stringify({
        pageAri,
        projectAri,
    });

    const uri = `/gateway/api/jira/help-sandook/cloudid/${cloudId}/api/v1/help/knowledge-base/page-metadata?${queryString}`;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(uri, { 'Content-Type': 'application/json' })
        .map(({ response }: FetchCrossSiteArticleAjaxResponse) => response)
        .catch((e: AjaxError) => {
            trackError('categories.fetch.all.failed', {}, e);
            return Observable.throw(e);
        });
};
