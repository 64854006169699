import type { StoreActionApi } from 'react-sweet-state';
import { sendUIEvent } from '../../../common/utils';
import type { State } from '../types';

export const clearConversation =
    () =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const { messages, conversationId, abortController } = getState();

        if (!messages.length && !conversationId) {
            return;
        }

        // https://data-portal.internal.atlassian.com/analytics/registry/69030
        sendUIEvent({
            action: 'clicked',
            actionSubject: 'clearConversationButton',
        });

        if (abortController) {
            abortController.abort();
        }
        setState({ messages: [], conversationId: undefined, isLoading: false, abortController: undefined });
    };
