import * as React from 'react';
import { di } from 'react-magnetic-di';
import { Link as ReactResourceRouterLink } from 'react-resource-router';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { BaseUrlRespectLink } from '@atlassian/help-center-common-component/analytics/link/base-url-respected-link';
import { withCreateFireUIAnalytics } from '@atlassian/help-center-common-component/analytics/with-create-fire-ui-analytics';

type ReactResourceRouterLinkProps = React.ComponentPropsWithRef<typeof ReactResourceRouterLink> &
    WithAnalyticsEventsProps & {
        actionSubjectId: string;
        attributes?: Record<string, unknown>;
    };

export type Props = ReactResourceRouterLinkProps;

const InstrumentedLink: React.FunctionComponent<Props> = ({ actionSubjectId, createAnalyticsEvent, ...props }) => {
    di(ReactResourceRouterLink);
    const { to, target, ...rest } = props;
    if (typeof to === 'string') {
        return <BaseUrlRespectLink to={to} target={target} {...rest} />;
    }
    return <ReactResourceRouterLink {...props} />;
};

export const Link = withCreateFireUIAnalytics<Props>('link', {
    onClick: () => ({ action: 'clicked' }),
})(InstrumentedLink);
