/**
 * @generated SignedSource<<d849493470a62f53c7a36f89d8810125>>
 * @relayHash 25e6269361cd6efc34c09587ad76c352
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f39ee54689dc5a6ad3d842c0a754bc231a105713b5b09a391d0a41c535fcb10e

import type { ConcreteRequest, Query } from 'relay-runtime';
export type HelpCenterPortalsSortOrder = "NAME_ASCENDING" | "POPULARITY" | "%future added value";
export type HelpCenterPortalsType = "FEATURED" | "HIDDEN" | "VISIBLE" | "%future added value";
export type HelpCenterPortalFilter = {
  typeFilter?: ReadonlyArray<HelpCenterPortalsType> | null | undefined;
};
export type featuredAndSortedPortalsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  portalsFilter?: HelpCenterPortalFilter | null | undefined;
  sortOrder?: HelpCenterPortalsSortOrder | null | undefined;
};
export type featuredAndSortedPortalsQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly portals: {
        readonly portalsList: ReadonlyArray<{
          readonly description: string | null | undefined;
          readonly id: string;
          readonly isFeatured: boolean | null | undefined;
          readonly isHidden: boolean | null | undefined;
          readonly key: string | null | undefined;
          readonly logoUrl: string | null | undefined;
          readonly name: string | null | undefined;
          readonly portalBaseUrl: string | null | undefined;
          readonly rank: number | null | undefined;
        }> | null | undefined;
        readonly sortOrder: HelpCenterPortalsSortOrder | null | undefined;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly extensions: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type featuredAndSortedPortalsQuery = {
  response: featuredAndSortedPortalsQuery$data;
  variables: featuredAndSortedPortalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalsFilter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortOrder"
},
v4 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "args": [
    {
      "kind": "Variable",
      "name": "portalsFilter",
      "variableName": "portalsFilter"
    },
    {
      "kind": "Variable",
      "name": "sortOrder",
      "variableName": "sortOrder"
    }
  ],
  "concreteType": "HelpCenterPortals",
  "kind": "LinkedField",
  "name": "portals",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "sortOrder"
    },
    {
      "concreteType": "HelpCenterPortal",
      "kind": "LinkedField",
      "name": "portalsList",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "description"
        },
        {
          "kind": "ScalarField",
          "name": "logoUrl"
        },
        {
          "kind": "ScalarField",
          "name": "portalBaseUrl"
        },
        {
          "kind": "ScalarField",
          "name": "isFeatured"
        },
        {
          "kind": "ScalarField",
          "name": "isHidden"
        },
        {
          "kind": "ScalarField",
          "name": "rank"
        }
      ]
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "message"
},
v10 = {
  "kind": "ScalarField",
  "name": "statusCode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "featuredAndSortedPortalsQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "featuredAndSortedPortalsQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v10/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f39ee54689dc5a6ad3d842c0a754bc231a105713b5b09a391d0a41c535fcb10e",
    "metadata": {},
    "name": "featuredAndSortedPortalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "db239181519d00ccb6f4d7cba5dc3acd";

export default node;
