// eslint-disable-next-line no-shadow
export const enum AccountStageType {
    LOGIN = 'LOGIN',
    SIGNUP = 'SIGNUP',
}

// eslint-disable-next-line no-shadow
export const enum AuthMethodType {
    CUSTOMER_ACCOUNT_PASSWORD = 'CUSTOMER_ACCOUNT_PASSWORD',
    CUSTOMER_ACCOUNT_SSO = 'CUSTOMER_ACCOUNT_SSO',
    ATLASSIAN_ACCOUNT_SSO = 'ATLASSIAN_ACCOUNT_SSO',
}
interface AuthMethodData {
    redirectLink?: string;
}
export interface AuthInfo {
    accountStage: AccountStageType;
    authMethods: Map<AuthMethodType, AuthMethodData>;
}

// eslint-disable-next-line no-shadow
export const enum LoginActionType {
    LOGIN = 'LOGIN',
    SINGLE_SIGN_ON = 'SINGLE_SIGN_ON',
    JIT_SIGN_UP = 'JIT_SIGN_UP',
}

// eslint-disable-next-line no-shadow
export const enum LoginType {
    ATLASSIAN_ACCOUNT_LOGIN = 'atlassian.account.login',
    ATLASSIAN_ACCOUNT_SIGNUP = 'atlassian.account.signup',
    CUSTOMER_ACCOUNT_LOGIN = 'customer.account.login',
    CUSTOMER_ACCOUNT_SIGNUP = 'customer.account.signup',
}

export const QUERY_PARAM_SSO_TEST = 'sso_test';
