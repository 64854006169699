import type { FetchCategoriesAction } from 'epics/model/kb-category';
import type { FetchKBSuggestionsAction } from 'epics/model/kb-suggestion';
import { FETCH_KB_SUGGESTIONS_SUCCESS } from 'epics/model/kb-suggestion';
import { isKbRelatedSentryAlertsFixEnabled } from 'feature-flags';
import type { Category } from 'rest/category';
import type { MediaApiUploadInformation, MediaApiUploadToken, PortalAnnouncement } from 'rest/portal';
import type { FetchCategoriesSuccess, FetchCategoriesFailure, NOT_SUPPORTED } from 'state/actions/kb-category';
import { FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE } from 'state/actions/kb-category';
import type {
    FetchPortalAction,
    FetchPortalSuccess,
    FetchPortalFailure,
    PortalWithThemeResponse,
    UpdatePortalAnnouncementSuccess,
    FetchPortalUploadMediaTokenSuccess,
} from 'state/actions/portal';
import {
    FETCH_PORTAL_MODEL,
    FETCH_PORTAL_SUCCESS,
    FETCH_PORTAL_FAILURE,
    UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS,
    FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS,
} from 'state/actions/portal';
import type { ProformaFormLoaded } from 'state/actions/portal-and-request-types';
import { PROFORMA_FORM_LOADED } from 'state/actions/portal-and-request-types';
import { FETCH_REQUEST_CREATE_FAILURE } from 'state/actions/request-create';
import type { FetchRequestCreateFailure } from 'state/actions/request-create';
import type { KnowledgeArticle } from 'state/ui/search';
import { contextPath, getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface AppLink {
    appLinkId: string;
    appLinkName: string;
    spaceKey: string;
    spaceName: string;
    isServer?: boolean;
}

/*
 * The convention for kbCategories is:
 *   undefined -> not loaded / is loading
 *   Category[] -> loaded and supported
 *   PersistedError -> loaded with error
 *   NOT_SUPPORTED -> loaded with { categorySupported: false }
 */
export interface PortalKnowledgeBaseState {
    kbCategories?: Category[] | PersistedError | typeof NOT_SUPPORTED;
    kbLink: AppLink;
    kbArticleSuggestions?: KnowledgeArticle[];
    kbLinkDomainURLs?: string[];
}

export interface ReqGroupsState {
    id: number;
    name: string;
    reqTypes: string[];
}

export interface ReqTypesState {
    id: string;
    name: string;
    descriptionHtml: string;
    helpTextHtml: string;
    iconUrl: string;
}

export interface AnalyticAttributes {
    jsdRole: string;
    initialProjectTemplate: string;
    openAccess: boolean;
    publicSignup: boolean;
    nextGenProject: boolean;
    demoProject: boolean;
    eapOptedIn: string;
    jsdDwCohort: string;
    previousSupportedProjects: string;
    supportedProjects: string;
}

export interface Portal {
    id: number;
    name: string;
    serviceDeskId: number;
    description?: string;
    projectKey?: string;
    projectId?: number;
    reqGroups: ReqGroupsState[];
    reqTypes: ReqTypesState[];
    analyticAttributes?: AnalyticAttributes;
    knowledgeBase?: PortalKnowledgeBaseState;
    portalAnnouncement?: PortalAnnouncement;
    logoUrl?: string;
    isProjectSimplified: boolean;
    mediaApiUploadToken?: MediaApiUploadToken;
    mediaApiError?: string;
}

export interface PortalState {
    [portalId: string]: Portal | PersistedError | undefined;
}

const getMediaUploadToken = (mediaApiUploadInformation: MediaApiUploadInformation) => {
    if ('token' in mediaApiUploadInformation) {
        return {
            clientId: mediaApiUploadInformation.clientId,
            token: mediaApiUploadInformation.token,
            endpointUrl: mediaApiUploadInformation.endpointUrl,
            targetCollection: mediaApiUploadInformation.targetCollection,
            tokenDurationInMins: mediaApiUploadInformation.tokenDurationInMins,
        };
    }
    return undefined;
};
const getMediaError = (mediaApiUploadInformation: MediaApiUploadInformation) => {
    if ('error' in mediaApiUploadInformation) {
        return mediaApiUploadInformation.error;
    }
    return undefined;
};

function reducePortalResponseToState(portalResponse: PortalWithThemeResponse, oldState = {}) {
    const portalId = parseInt(portalResponse.id, 10);
    const mediaApiUploadInformation = portalResponse.mediaApiUploadInformation;
    const mediaApiUploadToken = mediaApiUploadInformation && getMediaUploadToken(mediaApiUploadInformation);
    const mediaApiError = mediaApiUploadInformation && getMediaError(mediaApiUploadInformation);
    const newSinglePortalState: Portal = {
        mediaApiUploadToken,
        mediaApiError,
        id: portalId,
        name: portalResponse.name,
        logoUrl: portalResponse.logoUrl,
        description: portalResponse.description,
        projectKey: portalResponse.key,
        projectId: portalResponse.projectId,
        portalAnnouncement: portalResponse.portalAnnouncement,
        isProjectSimplified: portalResponse.isProjectSimplified,
        serviceDeskId: portalResponse.serviceDeskId,
        reqGroups: [],
        reqTypes: [],
    };

    const kbState =
        portalResponse.kbs && portalResponse.kbs.kbEnabled
            ? {
                  knowledgeBase: {
                      kbLink: portalResponse.kbs.kbLink,
                      kbLinkDomainURLs: portalResponse.kbs.kbLinkDomainURLs,
                  } as PortalKnowledgeBaseState,
              }
            : {};

    // If there is KB we try to maintain the previous categories
    if (!!kbState.knowledgeBase) {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const existingPortal = oldState[portalId];
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const existingCategories =
            existingPortal &&
            !('error' in existingPortal) &&
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            existingPortal.knowledgeBase &&
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            existingPortal.knowledgeBase.kbCategories;

        if (existingCategories) {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            kbState.knowledgeBase = { ...kbState.knowledgeBase, kbCategories: existingCategories };
        }
    }

    const reqTypesState = portalResponse.reqTypes
        ? {
              reqTypes: portalResponse.reqTypes.map((reqtype) => ({
                  id: reqtype.id,
                  name: reqtype.name,
                  // This is actually the description, not the intro.
                  // The backend is wrecked.
                  descriptionHtml: reqtype.introHtml,
                  // This is actually the help text, not the description.
                  // The backend is wrecked.
                  helpTextHtml: reqtype.descriptionHtml,
                  // Icon id here is required to pass backend validation of req type when removing from group
                  // can remove once backend change JDW-292 is complete
                  icon: reqtype.icon,
                  iconUrl:
                      reqtype.iconUrl ||
                      `${contextPath}/servicedesk/customershim/secure/viewavatar?avatarType=SD_REQTYPE&avatarId=${reqtype.icon}`,
              })) as ReqTypesState[],
          }
        : {};

    const orderMapping = portalResponse.orderMapping || {};
    const reqGroupsState: ReqGroupsState[] = (portalResponse.reqGroups || []).map((reqGroup) => ({
        id: reqGroup.id,
        name: reqGroup.name,
        reqTypes: (orderMapping[reqGroup.id] || []).map(String),
    }));

    const { analyticsContext } = portalResponse;
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const oldPortalState = oldState[portalId];
    const analyticAttributesState = analyticsContext
        ? {
              analyticAttributes: {
                  jsdRole: analyticsContext.jsdRole,
                  initialProjectTemplate: analyticsContext.initialProjectTemplate,
                  openAccess: analyticsContext.openAccess,
                  publicSignup: analyticsContext.publicSignup,
                  nextGenProject: analyticsContext.nextGenProject,
                  demoProject: analyticsContext.demoProject,
                  eapOptedIn: analyticsContext.eapOptedIn,
                  jsdDwCohort: analyticsContext.jsdDwCohort,
                  previousSupportedProjects: analyticsContext.previousSupportedProjects,
                  supportedProjects: analyticsContext.supportedProjects,
              },
          }
        : {
              // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              ...(oldPortalState && oldPortalState.analyticAttributes
                  ? // Suppressing existing violation. Please fix this.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    { analyticAttributes: oldPortalState.analyticAttributes }
                  : {}),
          };

    return {
        [portalId]: {
            ...newSinglePortalState,
            ...kbState,
            ...reqTypesState,
            ...analyticAttributesState,
            reqGroups: reqGroupsState,
        },
    };
}

export const getInitialPortalState = () => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.portal) {
        if (initialModelState.branding) {
            return reducePortalResponseToState({ ...initialModelState.branding, ...initialModelState.portal });
        }
        return reducePortalResponseToState({ ...initialModelState.portal, logoUrl: undefined, theme: {} });
    }
    return {};
};

const defaultState: PortalState = getInitialPortalState();

export type HandledActions =
    | FetchPortalSuccess
    | FetchPortalFailure
    | FetchPortalAction
    | FetchCategoriesAction
    | FetchCategoriesSuccess
    | FetchCategoriesFailure
    | FetchRequestCreateFailure
    | UpdatePortalAnnouncementSuccess
    | FetchKBSuggestionsAction
    | ProformaFormLoaded
    | FetchPortalUploadMediaTokenSuccess;

export default function reducer(state: PortalState = defaultState, action: HandledActions): PortalState {
    switch (action.type) {
        case FETCH_PORTAL_MODEL: {
            const fetchPortalAction = action as FetchPortalAction;
            const portalId = fetchPortalAction.payload.params && fetchPortalAction.payload.params.id;

            if (portalId) {
                const portal = state[portalId];
                if (portal && 'error' in portal) {
                    const newState = {
                        ...state,
                    };

                    delete newState[portalId];

                    return newState;
                }
            }

            return state;
        }

        // We want to override the portal data if request create fails as well.
        case FETCH_REQUEST_CREATE_FAILURE:
        case FETCH_PORTAL_FAILURE: {
            const fetchPortalFailureAction = action as FetchPortalFailure;
            const { error, portalId } = fetchPortalFailureAction.payload;

            return {
                ...state,
                [portalId]: {
                    error: {
                        // We really only care about the first error message
                        // grab it and throw the rest away
                        status: error.status,
                        message: (error.errorMessages || [])[0],
                        // Remove basename from the URL, we don't need it as the basename
                        // is already set inside react router.
                        // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                        callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                        callToActionText: error.nextActionDisplayText || '',
                        traceId: error.traceId,
                        responseHeaders: error.responseHeaders,
                    },
                },
            };
        }

        case FETCH_PORTAL_SUCCESS: {
            const fetchPortalSuccessAction = action as FetchPortalSuccess;
            return {
                ...state,
                ...reducePortalResponseToState(fetchPortalSuccessAction.payload, state),
            };
        }

        case FETCH_CATEGORIES_SUCCESS: {
            const categoriesSuccessAction = action as FetchCategoriesSuccess;
            const { portalId, categories } = categoriesSuccessAction.payload;
            let portal;
            if (isKbRelatedSentryAlertsFixEnabled()) {
                portal = state[portalId];
                if (!portal || !('knowledgeBase' in portal)) {
                    return state;
                }
            } else {
                portal = state[portalId] as Portal;
            }
            const kbState = portal.knowledgeBase;

            return {
                ...state,
                [portalId]: {
                    ...portal,
                    knowledgeBase: {
                        ...kbState,
                        kbCategories: categories,
                    },
                },
            } as PortalState;
        }

        case FETCH_CATEGORIES_FAILURE: {
            const categoriesFailureAction = action as FetchCategoriesFailure;
            const { portalId, error: categoryError } = categoriesFailureAction.payload;
            let portal;
            if (isKbRelatedSentryAlertsFixEnabled()) {
                portal = state[portalId];
                if (!portal || !('knowledgeBase' in portal)) {
                    return state;
                }
            } else {
                portal = state[portalId] as Portal;
            }
            const kbState = portal.knowledgeBase;
            return {
                ...state,
                [portalId]: {
                    ...portal,
                    knowledgeBase: {
                        ...kbState,
                        kbCategories: {
                            error: {
                                status: categoryError.statusCode,
                                message: categoryError.errorMessage,
                                callToActionUrl: '/portal/',
                                callToActionText: 'Go back',
                            },
                        },
                    },
                },
            } as PortalState;
        }

        case UPDATE_PORTAL_ANNOUNCEMENT_SUCCESS: {
            const { payload } = action as UpdatePortalAnnouncementSuccess;

            if (!payload) {
                return state;
            }

            const { portalId } = payload;
            const portal = state[portalId] as Portal;
            const portalAnnouncement = payload ?? portal.portalAnnouncement;

            return {
                ...state,
                [portalId]: {
                    ...portal,
                    portalAnnouncement,
                },
            };
        }

        case FETCH_KB_SUGGESTIONS_SUCCESS: {
            const { kbArticleSuggestions, portalId } = action.payload;
            if (portalId === undefined) {
                return state;
            }
            let portal;
            if (isKbRelatedSentryAlertsFixEnabled()) {
                portal = state[portalId];
                if (!portal || !('knowledgeBase' in portal)) {
                    return state;
                }
            } else {
                portal = state[portalId] as Portal;
            }
            const kbState = portal.knowledgeBase;
            if (!isKbRelatedSentryAlertsFixEnabled()) {
                if (!kbState) return state;
            }
            return {
                ...state,
                [portalId]: {
                    ...portal,
                    knowledgeBase: {
                        ...kbState,
                        kbArticleSuggestions,
                    },
                },
            };
        }

        case PROFORMA_FORM_LOADED: {
            const { portalId, proformaFormIsLoaded } = action.payload;
            if (portalId === undefined) {
                return state;
            }
            const portal = state[portalId] as Portal;
            return {
                ...state,
                [portalId]: {
                    ...portal,
                    proformaFormIsLoaded,
                },
            };
        }

        case FETCH_PORTAL_UPLOAD_MEDIA_TOKEN_SUCCESS: {
            const { portalId, mediaApiUploadInformation } = action.payload;
            if (portalId === undefined) {
                return state;
            }
            const portal = state[portalId];
            if (portal && !('error' in portal)) {
                return {
                    ...state,
                    [portalId]: {
                        ...portal,
                        mediaApiUploadToken: getMediaUploadToken(mediaApiUploadInformation),
                    },
                };
            }
            return state;
        }

        default:
            return state;
    }
}
