import type { PaperAppearance } from 'view/layout/paper';
import { RequestApproval } from 'view/request-approval';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { anonymousScreenLayout, PORTAL_ROUTE_PREFIX } from '../common/constants';

const route = {
    name: 'request-approval',
    path: `${PORTAL_ROUTE_PREFIX}/:requestKey/approval/:approvalId/:decision`,
    exact: true,
    component: RequestApproval,
};

const screenName = ScreenName.REQUEST_APPROVAL;

const paperAppearance: PaperAppearance = 'normal';

const layoutProps = {
    ...anonymousScreenLayout,
    paperAppearance,
};

export const requestApprovalRoute = createRouteWithLayout(route, screenName, layoutProps);
