// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import type { FlattenInterpolation, PortalTheme, ThemedStyledProps } from 'styled-components';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { css, injectGlobal } from 'styled-components';
import type { ThemeProps } from '@atlaskit/theme';
import { borderRadius as akBorderRadius, colors, gridSize } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import 'focus-visible';

import * as elevation from './elevation';
import * as grid from './grid';
import * as transitions from './transitions';

/**
 * TODO: Replace with `import { focusRing, noFocusRing } from '@atlaskit/theme' when updating
 * to @atlaskit/theme@^7.x.x :-).
 */
const atlaskitFocusRing = {
    focusRing: (color = token('color.border.focused', colors.B100), outlineWidth = gridSize() / 4) => `
        &:focus {
          outline: none;
          box-shadow: 0px 0px 0px ${outlineWidth}px ${color};
        }
      `,

    noFocusRing: () => `
        box-shadow: none;
      `,
};

const defaultFocusRing = atlaskitFocusRing.focusRing();
const noFocusRing = atlaskitFocusRing.noFocusRing();

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const focusRing = {
    // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    default: css`
        &.focus-visible {
            ${defaultFocusRing};
        }

        /* only set outline to 0 when the focus-visible */
        &:focus:not(.focus-visible) {
            outline: 0;
        }
    `,
    none: noFocusRing,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const hideVisually = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0;
    position: absolute;
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    height: 1px;
    margin: ${token('space.negative.025', '-2px')};
    width: 1px;
    /* stylelint-enable scale-unlimited/declaration-strict-value */
    white-space: nowrap;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const borderBox = css`
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const flexCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const center = (position: 'relative' | 'absolute') => css`
    position: ${position};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const breakWord = css`
    white-space: pre-wrap;
    word-break: break-word;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const borderRadius = css`
    border-radius: ${token('border.radius.100', '3px')};
`;

export const borderRadiusValue = `${akBorderRadius()}px`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const clamp = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const multiLineClamp = (lines: number, lineHeight: number = grid.multiple(2.5).unitless) => css`
    /* multi-line clamp for Chrome, Safari, Edge */
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
    /* Fallback to only show certain number of lines of text for Firefox and IE */
    max-height: ${lineHeight * lines}px;
    overflow: hidden;
`;

type Mixin = FlattenInterpolation<ThemedStyledProps<ThemeProps | undefined, PortalTheme>>[] | string | number;

export const applyIf = (
    predicateResult: boolean,
    mixin: Mixin
): FlattenInterpolation<ThemedStyledProps<ThemeProps | undefined, PortalTheme>>[] =>
    // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    css`
        ${predicateResult ? mixin : ''};
    `;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const placeholder = (color: string) => css`
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: ${color};
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: ${color};
        opacity: 1;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${color};
    }

    &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: ${color};
        opacity: 1;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${color};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const raiseUpOnHoverPsuedoElement = css`
    ${borderRadius};
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
`;

/**
 * This will set elevation and transition on hover and focus.
 * This also sets default transitions for opacity and transform.
 * If you want custom ones, or other transforms, you'll have to define them yourself.
 *
 * E.g: transition: transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
 */
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const raiseUpOnHoverOrFocus = (fromElevation = elevation.e100, toElevation = elevation.e200) => css`
    position: relative;
    transition: transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};

    &::before {
        ${raiseUpOnHoverPsuedoElement};
        ${fromElevation};
        /* Reset zindex from elevation mixin */
        z-index: inherit;
    }

    &::after {
        ${raiseUpOnHoverPsuedoElement};
        ${toElevation};
        /* We want to hide this initially and show it only on hover/focus. */
        opacity: 0;
        /* Reset zindex from elevation mixin */
        z-index: inherit;
    }

    &:focus,
    &:hover {
        transform: translate3d(0, -2px, 0);

        &::after {
            opacity: 1;
        }

        &::before {
            opacity: 0;
        }
    }
`;

/**
 * This sets the background of an element to have the theme color at 0.15 opacity, while allowing full opacity of
 * text inside the element.
 * We're utilising linear-gradient background-image to have two colors on top of each other, as follows:
 * ---white 0.85 opacity---
 * ---branding color--------
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const subtleThemeColorBackground = css`
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),
        linear-gradient(to right, ${(props) => props.theme.color}, ${(props) => props.theme.color});
`;

// Overrides the @atlaskit/css-reset focus ring with our own
// wrapper that only shows it if you interact by keyboard.
injectGlobal`
    a {
        ${focusRing.default};
    }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const toolTipPrimitiveStyle = css`
    color: ${token('color.text.inverse', colors.N0)};
    background-color: ${token('color.text', colors.N800)};
    padding: ${token('space.025', '2px')} ${token('space.075', '6px')};
    border-radius: ${borderRadius};
`;

/**
 * Mixin panelExpansionAnimationFix has been added
 * to fix issues related to jitter when Panel is expanded in sidebar translation forms,
 * identified in issue [https://jdog.jira-dev.com/browse/PORTAL-1182],
 * this mixin has been added in files
 * (src/view/help-center-customize-sidebar/translation-panel-stateless/styled.tsx) and
 * (src/view/portal-settings-sidebar/customize-portal-announcement-sidebar-stateless/styled.tsx),
 * please update with caution!
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const panelExpansionAnimationFix = css`
    > div > div {
        box-sizing: content-box;
    }

    .rah-animating {
        overflow: visible !important;
        filter: opacity(0.05);
    }
`;
