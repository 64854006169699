// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';

import { Footer } from 'view/layout/footer';
import { Link } from '@atlassian/help-center-common-component/analytics';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const AutoHeightBody = styled(BodyClassName)`
    min-height: 100%;
    /* This overrides @atlaskit/onboarding (and then @atlaskit/layer) from setting
    height: 100% as inline styles onto the body and breaking our app layout. */
    height: auto !important;
    position: relative;
`;

interface FooterProps {
    sticky: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const stickyBottom = css`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledFooter = styled(Footer)`
    ${(props: FooterProps) => (props.sticky ? stickyBottom : '')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NoUnderlineLink = styled(Link)`
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;
