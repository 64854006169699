import { useEffect } from 'react';
import { avatarMenuExperienceId } from '@atlassian/help-center-common-util/analytics/ufo';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const avatarMenuExperience = new UFOExperience(avatarMenuExperienceId, {
    type: ExperienceTypes.Load,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const AvatarMenuExperienceFailure = (props: { message: string }) => {
    useEffect(() => {
        // Since experience failure function is returning a promise, just executing it without waiting for it to resolve
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        avatarMenuExperience.failure({
            metadata: props,
        });
    }, [props]);
    return null;
};
