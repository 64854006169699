import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchRequestListAction,
    FetchRequestListSuccess,
    FetchRequestListFailure,
} from 'state/actions/request-list';
import { FETCH_REQUEST_LIST_MODEL, fetchRequestListSuccess, fetchRequestListFailure } from 'state/actions/request-list';

import type { ErrorResponse } from 'epics/model/types';

export const requestListEpic: Epic<FetchRequestListAction, FetchRequestListSuccess | FetchRequestListFailure> = (
    action$
) => {
    return action$.ofType(FETCH_REQUEST_LIST_MODEL).switchMap((action) => {
        return fetchModel(action.payload)
            .filter(
                (response) =>
                    response.allReqFilter !== undefined &&
                    'requestList' in response.allReqFilter &&
                    Array.isArray(response.allReqFilter.requestList)
            )
            .flatMap((response) => {
                return Observable.of(fetchRequestListSuccess(response.allReqFilter));
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchRequestListFailure(error));
            });
    });
};
