import { defineMessages } from 'react-intl-next';

export default defineMessages({
    conflictErrorTitle: {
        defaultMessage: 'Error',
        description: 'Conflict error when user clicking a stale approval panel decision button.',
        id: 'sd.stale.approval.panel.conflict.title',
    },
    conflictErrorDescription: {
        defaultMessage: 'An approval response has already been submitted for this issue.',
        description: 'Conflict error when user clicking a stale approval panel decision button description',
        id: 'sd.stale.approval.panel.conflict.description',
    },
});
