import React from 'react';
import { styled } from '@compiled/react';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import messages from './messages';

export const BrandingPreviewBanner: React.FC = () => {
    di(useIntl);
    const { formatMessage } = useIntl();

    return (
        <Wrapper>
            <IconWrapper>
                <WatchIcon label="watch-icon" size="small" />
            </IconWrapper>
            <span>{formatMessage(messages.brandingPreviewText)}</span>
        </Wrapper>
    );
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    padding: token('space.075', '6px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    color: token('color.text.inverse', colors.N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const IconWrapper = styled.div({
    display: 'inline-block',
    padding: `0 ${token('space.050', '4px')}`,
});
