import type {
    CustomerOrganisationResponse,
    SimpleUserResponse,
    RequestDetailsResponse,
    ReadFileMediaCredentials,
} from 'rest/request-details';
import type { State } from 'state';
import type { ApprovalDecision } from 'state/ui/request-details';
import type { Error } from '@atlassian/help-center-common-util/errors';
import type { PersistedError } from 'state/persisted/types';

const getRequestDetailsOrError = (
    state: State,
    requestKey: string | undefined
): RequestDetailsResponse | PersistedError | undefined => {
    if (requestKey && state.persisted.requestDetails) {
        return state.persisted.requestDetails[requestKey];
    }
    return undefined;
};

export const getRequestDetails = (state: State, requestKey: string | undefined): RequestDetailsResponse | undefined => {
    const data = getRequestDetailsOrError(state, requestKey);
    return isError(data) ? undefined : data;
};

const isError = (data: RequestDetailsResponse | PersistedError | undefined): data is PersistedError =>
    data !== undefined && 'error' in data;

export const getError = (state: State, requestKey: string | undefined): PersistedError | undefined => {
    const data = getRequestDetailsOrError(state, requestKey);
    return isError(data) ? data : undefined;
};

export const isTransactionInProgress = (state: State, transactionId: number): boolean => {
    const { inProgressTransitionId } = state.ui.requestDetails;
    return inProgressTransitionId !== undefined && inProgressTransitionId === transactionId;
};

export const getTransitionFailure = (state: State): Error | undefined => {
    return state.ui.requestDetails.transitionFailure;
};

export const getReadMediaApiCredentials = (state: State, requestKey: string): ReadFileMediaCredentials | undefined => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.readFileMediaCredentials : undefined;
};

export const getCommentIsSubmitting = (state: State): boolean => {
    return state.ui.requestDetails.commentSubmitting;
};

export const getCommentBeingSubmitted = (state: State): string | undefined => {
    return state.ui.requestDetails.commentBeingSubmitted;
};

export const getReporter = (state: State, requestKey: string): SimpleUserResponse | undefined => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.reporter : undefined;
};

export const getParticipants = (state: State, requestKey: string): SimpleUserResponse[] | undefined => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.participants : undefined;
};

export const getOrganisations = (state: State, requestKey: string): CustomerOrganisationResponse[] | undefined => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.organisations : undefined;
};

export const getCanSubmitWithEmailAddress = (state: State, requestKey: string): boolean => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.canSubmitWithEmailAddress : false;
};

export const getCanSearchParticipants = (state: State, requestKey: string): boolean => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.canSearchParticipants : false;
};

export const getCanShareRequest = (state: State, requestKey: string): boolean => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.canShareRequest : false;
};

export const getCanRemoveParticipants = (state: State, requestKey: string): boolean => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.canRemoveParticipants : false;
};

export const getCanAddComment = (state: State, requestKey: string): boolean => {
    const requestDetails = getRequestDetails(state, requestKey);
    return requestDetails ? requestDetails.canAddComment : false;
};

export const isTransitionDialogVisible = (state: State, id: number): boolean => {
    return state.ui.requestDetails.visibleTransitionDialog === id;
};

export const getInProgressApprovalAction = (state: State): ApprovalDecision | undefined => {
    return state.ui.requestDetails.inProgressApprovalAction;
};

export const getProjectHasProformaForms = (state: State, requestKey: string) => {
    const requestDetails = getRequestDetails(state, requestKey);
    return !!requestDetails?.hasProformaForm;
};
