import { defineMessages } from 'react-intl-next';

export default defineMessages({
    documentTitle: {
        defaultMessage: 'Verify your email',
        description: 'Title of the check your email page',
        id: 'cv.sign.up.check.email.document.title',
    },
    emailSentSuccessMessage: {
        defaultMessage: 'Email sent to {emailAddress}',
        description: 'email sent success message',
        id: 'cv.sign.up.check.email.resend.success.message',
    },
    checkEmailToFinishSignup: {
        defaultMessage: 'Check your email to finish signup',
        description: 'check your email page title',
        id: 'cv.sign.up.check.your.email.to.signup',
    },
    emailSentHint: {
        defaultMessage: "We've sent a private signup link to {emailAddress}.",
        description: 'help text to explain that and email has been sent and that the user has to check his inbox',
        id: 'cv.sign.up.check.email.sub.title',
    },
    resendSignupLink: {
        defaultMessage: 'Resend signup link',
        description: 'resend button lable',
        id: 'cv.sign.up.resend.signup.link.button',
    },
});
