import * as React from 'react';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import type { ButtonProps } from '@atlaskit/button';
import AtlaskitButton from '@atlaskit/button';
import AtlaskitCustomThemeButton from '@atlaskit/button/custom-theme-button';
import type { CustomThemeButtonProps } from '@atlaskit/button/custom-theme-button';
import AtlaskitLoadingButton from '@atlaskit/button/loading-button';
import type { LoadingButtonProps } from '@atlaskit/button/loading-button';
import { IconButton as AtlaskitIconButton } from '@atlaskit/button/new';
import type { IconButtonProps } from '@atlaskit/button/new';

interface Props {
    /**
     * actionSubjectId should look like 'feedbackCollectorHelpfulButton'
     * read more here: https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/134329336/UI+Events
     */
    actionSubjectId: string;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

// this component is designed to handle analytics event firing for you.
//   as such, it does not surface the raw analytics event to the onClick handler
//   given to it as a prop - only the click event is passed up
export type EffectiveProps = Props & Omit<ButtonProps, 'onClick'>;

export const Button = React.memo(
    React.forwardRef<HTMLButtonElement, EffectiveProps>(function Button({ actionSubjectId, ...props }, ref) {
        return (
            <AnalyticsContext data={{ actionSubjectId }}>
                <AtlaskitButton {...props} ref={ref} />
            </AnalyticsContext>
        );
    })
);

export type EffectiveIconButtonProps = Props & Omit<IconButtonProps, 'onClick'>;
export const IconButton = React.memo(
    React.forwardRef<HTMLButtonElement, EffectiveIconButtonProps>(function IconButton(
        { actionSubjectId, ...props },
        ref
    ) {
        return (
            <AnalyticsContext data={{ actionSubjectId }}>
                <AtlaskitIconButton {...props} ref={ref} />
            </AnalyticsContext>
        );
    })
);

export type EffectiveLoadingButtonProps = Props & Omit<LoadingButtonProps, 'onClick'>;
export const LoadingButton = React.memo(
    React.forwardRef<HTMLButtonElement, EffectiveLoadingButtonProps>(function LoadingButton(
        { actionSubjectId, ...props },
        ref
    ) {
        return (
            <AnalyticsContext data={{ actionSubjectId }}>
                <AtlaskitLoadingButton {...props} ref={ref} />
            </AnalyticsContext>
        );
    })
);

export type EffectiveThemedButtonProps = Props & Omit<CustomThemeButtonProps, 'onClick'>;
export const ThemedButton = React.memo(
    React.forwardRef<HTMLButtonElement, EffectiveThemedButtonProps>(function ThemedButton(
        { actionSubjectId, ...props },
        ref
    ) {
        return (
            <AnalyticsContext data={{ actionSubjectId }}>
                <AtlaskitCustomThemeButton {...props} ref={ref} />
            </AnalyticsContext>
        );
    })
);
