import React, { useCallback } from 'react';
import { isSearchFAMASLOUpdateEnabled } from 'feature-flags';
import { noop } from 'lodash';
import { di } from 'react-magnetic-di';
import ErrorBoundary from 'view/error-boundary/error-boundary';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { withScreenName } from '@atlassian/help-center-common-util/analytics/screen-name-context';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { isFetchNetworkOrClientError } from '@atlassian/help-center-common-util/fetch';
import type { FetchError } from '@atlassian/help-center-common-util/fetch';
import { SearchResultError } from '../error';
import { searchExperience, csmSearchExperience, inlineSearchExperience } from '../experiences';

interface SearchErrorBoundaryProps {
    renderError?: () => React.ReactNode;
    id: string;
    children: React.ReactNode;
    screenName: ScreenName.HELP_CENTER_SEARCH;
}

const SearchErrorBoundary = ({ screenName, id, children, renderError }: SearchErrorBoundaryProps) => {
    di(getEnv);
    /**
     * If any error unrecoverable occurs inside search
     * 1. It should fail the entire experience - UFO
     * 2. Return null by default
     * 3. Send error payload to sentry and analytics - through ErrorBoundary
     * 4. Pass reason for the failure using UFO
     */
    const markExperienceFailed = useCallback(
        (error: Error | FetchError) => {
            if (!isFetchNetworkOrClientError(error as FetchError)) {
                const metadata = {
                    id,
                    error: error?.message,
                    failureEvent: screenName,
                };

                searchExperience
                    .failure({
                        metadata,
                    })
                    .then(noop)
                    .catch(noop);

                if (isCSMHelpCenter(getEnv().helpCenterType)) {
                    csmSearchExperience
                        .failure({
                            metadata,
                        })
                        .then(noop)
                        .catch(noop);
                }
                if (isSearchFAMASLOUpdateEnabled()) {
                    inlineSearchExperience
                        .failure({
                            metadata,
                        })
                        .then(noop)
                        .catch(noop);
                }
            }
        },
        [id, screenName]
    );

    const defaultRenderError = useCallback(() => {
        return <SearchResultError />;
    }, []);

    return (
        <ErrorBoundary
            packageName={screenName}
            id={id}
            renderError={renderError ? renderError : defaultRenderError}
            onError={markExperienceFailed}
        >
            {React.Children.only(children)}
        </ErrorBoundary>
    );
};

export default withScreenName(SearchErrorBoundary);
