import { isEmpty } from 'lodash';
import type { LoginAnnouncementsResponse as LoginAnnouncementsState } from 'rest/login-announcements';

import type {
    FetchLoginAnnouncementsSuccess,
    UpdateLoginAnnouncementsSuccess,
} from 'state/actions/login-announcements';
import {
    FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS,
    UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS,
} from 'state/actions/login-announcements';
import { initialModel } from '@atlassian/help-center-common-util/model';

export const getInitialLoginAnnouncementState = (): LoginAnnouncementsState => {
    const initialModelState = initialModel();
    if (initialModelState?.loginAnnouncements) {
        return initialModelState.loginAnnouncements;
    }

    return { defaultLocale: '', translatedLoginAnnouncements: {} };
};

const defaultState: LoginAnnouncementsState = getInitialLoginAnnouncementState();
export type HandledActions = FetchLoginAnnouncementsSuccess | UpdateLoginAnnouncementsSuccess;

export function loginAnnouncementsReducer(
    state: LoginAnnouncementsState = defaultState,
    action: HandledActions
): LoginAnnouncementsState {
    switch (action.type) {
        case FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS:
            return action.payload;
        case UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS: {
            const { payload } = action;
            if (isEmpty(payload)) {
                return state;
            }
            return {
                defaultLocale: state.defaultLocale,
                translatedLoginAnnouncements: Object.keys(payload.translatedLogInAnnouncements).reduce(
                    (acc, languageKey: string) => ({
                        ...acc,
                        [languageKey]: {
                            header: payload.translatedLogInAnnouncements[languageKey].header,
                            message: payload.translatedLogInAnnouncements[languageKey].message,
                            localeDisplayName: state.translatedLoginAnnouncements[languageKey]?.localeDisplayName,
                        },
                    }),
                    {}
                ),
            };
        }
        default:
            return state;
    }
}
