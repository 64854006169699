import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { getPortalId } from 'state/selectors/context';
import { getName, getDescription, getLogoUrl } from 'state/selectors/portal';
import { PaperHeader } from '@atlassian/help-center-common-component/paper-header';

const getPortalName = (state: State): string | undefined => {
    const portalId = getPortalId(state);
    return portalId ? getName(state, portalId) : undefined;
};

const getPortalDescription = (state: State): string | undefined => {
    const portalId = getPortalId(state);
    return portalId ? getDescription(state, portalId) : undefined;
};

const getPortalLogoUrl = (state: State): string | undefined => {
    const portalId = getPortalId(state);
    return portalId ? getLogoUrl(state, portalId) : undefined;
};

const selector = createSelector(
    getPortalName,
    getPortalDescription,
    getPortalLogoUrl,
    (portalName, portalDescription, portalLogoUrl) => ({
        subHeading: portalName,
        description: portalDescription,
        logoUrl: portalLogoUrl,
        dangerouslyAllowHtmlInDescription: true,
    })
);

export default connect(selector)(PaperHeader);
