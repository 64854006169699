import { defineMessages } from 'react-intl';

export default defineMessages({
    exitTestModeSuccessFlagTitle: {
        defaultMessage: 'You’re out of single sign-on test mode',
        description: 'Message title for toast flag when the exit testMode action is successful.',
        id: 'cv.login.exit.test.mode.success.flag.title',
    },
    exitTestModeSuccessFlagDescription: {
        defaultMessage: 'Go to the Authentication page in your Jira settings to enable single sign-on for customers.',
        description: 'Message description for toast flag when the exit testMode action is successful.',
        id: 'cv.login.exit.test.mode.success.flag.description',
    },
});
