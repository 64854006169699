import { type ComponentProps } from 'react';
import React from 'react';
import { LazySuspense, LazyWait, lazy } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import { type SearchPromptPopup as SearchPromptPopupType } from './search-prompt-popup';

export const LazySearchPromptPopup = lazy(() =>
    import(/* webpackChunkName: "search-prompt-popup" */ './search-prompt-popup').then(
        (module) => module.SearchPromptPopup
    )
);

export const SearchPromptPopup: React.FC<ComponentProps<typeof SearchPromptPopupType>> = (
    props: ComponentProps<typeof SearchPromptPopupType>
) => {
    di(LazySuspense, LazyWait, LazySearchPromptPopup);

    return __SERVER__ ? (
        <>
            {props.trigger({
                ref: null,
                'aria-expanded': false,
                'aria-haspopup': false,
            })}
        </>
    ) : (
        <LazySuspense
            fallback={
                <>
                    {props.trigger({
                        ref: null,
                        'aria-expanded': false,
                        'aria-haspopup': false,
                    })}
                </>
            }
        >
            <LazyWait until={props.isOpen}>
                <LazySearchPromptPopup {...props} />
            </LazyWait>
        </LazySuspense>
    );
};
