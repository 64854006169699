import { ScreenName } from 'view/common/constants';
import { VerifyEmail } from 'view/verify-email';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { anonymousScreenLayout } from '../common/constants';

const userVerifyEmail = {
    resources: [],
    name: 'verify-email',
    path: `/user/verify-email`,
    exact: true,
    component: VerifyEmail,
};

export const userVerifyEmailRoute = createRouteWithLayout(
    userVerifyEmail,
    ScreenName.VERIFY_EMAIL,
    anonymousScreenLayout
);
