import { di } from 'react-magnetic-di';
import { ConversationProvider } from '../../../common/types';
import { useIsLastMessageFromHelpSeeker, useIsLastAssistantMessageGenerated } from '../../../store/converse';
import { useIsRouting, useProvider } from '../../../store/routing';

export const useIsInputEnabled = () => {
    di(useProvider, useIsLastAssistantMessageGenerated, useIsLastMessageFromHelpSeeker, useIsRouting);
    const provider = useProvider();
    const isRouting = useIsRouting();
    const [isLastMessageFromHelpSeeker] = useIsLastMessageFromHelpSeeker();
    const [isLastAssistantMessageGenerated] = useIsLastAssistantMessageGenerated();

    if (isRouting) {
        return false;
    }

    if (!provider || provider === ConversationProvider.VIRTUAL_AGENT) {
        return true;
    }

    return isLastAssistantMessageGenerated && !isLastMessageFromHelpSeeker;
};
