import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { getEnv } from '@atlassian/help-center-common-util/env';

export const fireAnalyticsForEmptyHelpCenterAri = () => {
    const traceId = getEnv().traceId;
    operationalEvent({
        action: 'emptyHelpCenterAriReceived',
        actionSubject: 'help-center-ari',
        attributes: {
            traceId,
        },
        source: 'HelpCenter',
    });
};
