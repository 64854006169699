import type { State } from 'state';
import type { Article } from 'state/persisted/kb-article/types';
import type { PersistedError } from 'state/persisted/types';

export const getArticles = (state: State, categoryId: string | undefined): Article[] | PersistedError | undefined => {
    if (!categoryId) return undefined;

    const articleState = state.persisted.kbArticles;

    if (articleState.articles[categoryId] && articleState.articles[categoryId].error) {
        return articleState.articles[categoryId].error;
    }

    return articleState.articles[categoryId] ? articleState.articles[categoryId].articles : undefined;
};

export const getArticle = (state: State, id: string | undefined): Article | PersistedError | undefined =>
    !!id ? state.persisted.kbArticles.legacyArticles[id] : undefined;

export const getArticleTitle = (state: State, id: string | undefined): string | undefined => {
    const article = getArticle(state, id);
    if (!article || 'error' in article) return undefined;
    return article.title;
};
