import type { ConnectPageName } from 'rest/connect-addon';
import type { FetchConnectFragmentsSuccess, FetchConnectFragmentsFailure } from 'state/actions/connect-addon';
import { FETCH_CONNECT_FRAGMENTS_SUCCESS, FETCH_CONNECT_FRAGMENTS_FAILURE } from 'state/actions/connect-addon';
import type { ErrorResponse } from 'epics/model/types';

export interface ConnectFragmentsState<T extends ConnectAddonFragments | ConnectAddonFragmentsFailure> {
    [key: string]: T;
}

export interface ConnectAddonFragmentsFailure {
    error: ErrorResponse;
}

export interface WebFragment {
    html: string;
    key: string;
    fragmentHtml?: string;
}

export interface ConnectAddonFragments {
    headerPanels?: WebFragment[];
    subheaderPanels?: WebFragment[];
    footerPanels?: WebFragment[];
    propertyPanels?: WebFragment[];
}

export const defaultState = {
    HELP_CENTER_none_none: {},
    PORTAL_none_none: {},
    CREATE_REQUEST_none_none: {},
    REQUEST_DETAILS_none_none: {},
    PROFILE_none_none: {},
};

export type HandledActions = FetchConnectFragmentsSuccess | FetchConnectFragmentsFailure;

// Generates unique keys associated with connect fragments for each request create form.
// Allows connect fragments to update their context parameters and caches the result
export const generateKey = (
    portalPageName: ConnectPageName,
    portalId: number | undefined,
    requestTypeId: number | undefined,
    requestKey: string | undefined
) => `${portalPageName}_${portalId || 'none'}_${requestTypeId || 'none'}${`_${requestKey || 'none'}`}`;

export default function reducer(
    state: ConnectFragmentsState<ConnectAddonFragments | ConnectAddonFragmentsFailure> = defaultState,
    action: HandledActions
): ConnectFragmentsState<ConnectAddonFragments | ConnectAddonFragmentsFailure> {
    switch (action.type) {
        case FETCH_CONNECT_FRAGMENTS_SUCCESS: {
            const { portalPageName, portalId, requestTypeId, connectFragments, requestKey } = action.payload;
            const { headerPanels, subheaderPanels, footerPanels, pagePanels } = connectFragments;
            const propertyPanels = pagePanels ? pagePanels.propertyPanels : [];

            return {
                ...state,
                [generateKey(portalPageName, portalId, requestTypeId, requestKey)]: {
                    headerPanels,
                    subheaderPanels,
                    footerPanels,
                    propertyPanels,
                },
            };
        }
        case FETCH_CONNECT_FRAGMENTS_FAILURE: {
            const { portalPageName, portalId, requestTypeId, error, requestKey } = action.payload;

            return {
                ...state,
                [generateKey(portalPageName, portalId, requestTypeId, requestKey)]: {
                    error,
                },
            };
        }
        default:
            return state;
    }
}
