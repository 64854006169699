import type { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { UpdateXsrfAction } from 'state/actions/env';
import { updateXsrfAction } from 'state/actions/env';

// This epic listens to ALL model actions. All model actions starts with epic.model so we listen to those.
export const updateXsrfEpic: Epic<Action, UpdateXsrfAction> = (action$) => {
    return (
        action$

            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            .filter((action) => action.type.startsWith('epic.model'))
            .switchMap(() => fetchModel({ type: 'xsrfToken', params: {} }))
            // jsd-customer-portal-models-without-backbone
            // When cleaning up remove all instances of "env" here. Just use xsrfToken directly.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
            .filter((response) => !!response && (!!response.xsrfToken || !!(response as any).env.xsrfToken))
            // jsd-customer-portal-models-without-backbone
            // When cleaning up remove all instances of "env" here. Just use xsrfToken directly.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-explicit-any
            .map((response) => updateXsrfAction(response.xsrfToken || (response as any).env.xsrfToken))
            .catch((_) => Observable.never() as Observable<UpdateXsrfAction>)
    );
};

export default combineEpics(updateXsrfEpic);
