import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { invitedSignUp } from 'rest/invited-sign-up';
import type { InvitedSignUp, InvitedSignUpSuccess, InvitedSignUpFailure } from 'state/actions/invited-sign-up';
import { INVITED_SIGN_UP, invitedSignUpSuccess, invitedSignUpFailure } from 'state/actions/invited-sign-up';
import type { LoginAttempt } from 'state/actions/login';
import { loginAttempt } from 'state/actions/login';
import { getDefaultSignUpError } from '@atlassian/help-center-common-util/errors';

export const invitedSignUpEpic: Epic<InvitedSignUp, InvitedSignUpSuccess | LoginAttempt | InvitedSignUpFailure> = (
    action$
) => {
    return action$.ofType(INVITED_SIGN_UP).mergeMap((invitedSignUpAction) => {
        const { portalId, email, fullName, password, token, username, contactAdminsFormEnabled } =
            invitedSignUpAction.payload;
        return invitedSignUp(portalId, email, fullName, password, token, username)
            .flatMap(() => {
                return concat(Observable.of(invitedSignUpSuccess()), Observable.of(loginAttempt(email, password)));
            })
            .catch((error: AjaxError) => {
                const parsedError = getDefaultSignUpError(error, contactAdminsFormEnabled);
                return Observable.of(invitedSignUpFailure(parsedError));
            });
    });
};
