/**
 * @generated SignedSource<<0fff553a05a6474d38459a5f783fe29d>>
 * @relayHash c39ffef39d091e344d77f10f76720b3d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 43cfbf151f39e5746cb83275969c024702202a1fc44972200ae5cce2f2fd3089

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpCenterSortOrder = "CREATED_DATE_ASCENDING" | "CREATED_DATE_DESCENDING" | "%future added value";
export type multiHcDropdownContentComponentQuery$variables = {
  NUMBER_OF_HELP_CENTERS_PER_FETCH: number;
  SORT_ORDER: HelpCenterSortOrder;
  cloudId: string;
  workspaceAri: string;
};
export type multiHcDropdownContentComponentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"multiHcDropdownPaginatedFragment">;
};
export type multiHcDropdownContentComponentQuery = {
  response: multiHcDropdownContentComponentQuery$data;
  variables: multiHcDropdownContentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SORT_ORDER"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceAri",
  "variableName": "workspaceAri"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "SORT_ORDER"
  },
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "multiHcDropdownContentComponentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "NUMBER_OF_HELP_CENTERS_PER_FETCH",
            "variableName": "NUMBER_OF_HELP_CENTERS_PER_FETCH"
          },
          {
            "kind": "Variable",
            "name": "SORT_ORDER",
            "variableName": "SORT_ORDER"
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "multiHcDropdownPaginatedFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "multiHcDropdownContentComponentQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "helpCenters",
            "plural": false,
            "selections": [
              {
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "HelpCenterName",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "default"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "slug"
                          }
                        ],
                        "type": "HelpCenter"
                      },
                      (v8/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "args": (v6/*: any*/),
            "filters": [
              "workspaceAri",
              "sortOrder"
            ],
            "handle": "connection",
            "key": "multiHcDropdownContentFragment_helpCenters",
            "kind": "LinkedHandle",
            "name": "helpCenters"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "43cfbf151f39e5746cb83275969c024702202a1fc44972200ae5cce2f2fd3089",
    "metadata": {},
    "name": "multiHcDropdownContentComponentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "29fda4db4acf34a38aedc1fa8b96de91";

export default node;
