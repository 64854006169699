import { defineMessages } from 'react-intl-next';

export default defineMessages({
    searchErrorTitle: {
        defaultMessage: 'We couldn\u2019t load your search results',
        description: 'Text to tell the user that there was an error while conducting a search',
        id: 'cv.search.error.title',
    },
    searchErrorText: {
        defaultMessage: 'Refresh the page and try again. Or go back to the { helpCenterLink }.',
        description: 'Text to tell the user how to proceed after there is an error while searching',
        id: 'cv.search.error.message',
    },
    helpCenter: {
        defaultMessage: 'help center',
        description: 'Text to render help center link',
        id: 'cv.search.error.help-center',
    },
});
