export const UPDATE_HELP_CENTER_MODEL = 'state.persisted.help-center.UPDATE_HELP_CENTER_MODEL';
export const UPDATE_HELP_CENTER_BRANDING_MODEL = 'state.persisted.help-center.UPDATE_HELP_CENTER_BRANDING_MODEL';
export const UPDATE_HELP_CENTER_THEME_COLOR = 'state.persisted.help-center.UPDATE_HELP_CENTER_THEME_COLOR';
export const UPDATE_HELP_CENTER_BRANDING_LOADER = 'state.persisted.help-center.UPDATE_HELP_CENTER_BRANDING_LOADER';

import type { HCBrandingResponse } from 'rest/help-center-branding';

// Base model
export interface HelpCenterkbsResponse {
    kbEnabled: boolean;
    appLinkUrl: string;
    kbLinkDomainURLs: string[];
}

export interface HelpCenterResponse {
    name: string;
    description: string;
    kbs?: HelpCenterkbsResponse;
}
export interface HCThemeColorResponse {
    themeColor: string;
}
export interface UpdateHelpCenterModelAction {
    payload: HelpCenterResponse;
    type: typeof UPDATE_HELP_CENTER_MODEL;
}

export const updateHelpCenterModelAction = (modelResponse: HelpCenterResponse): UpdateHelpCenterModelAction => ({
    payload: modelResponse,
    type: UPDATE_HELP_CENTER_MODEL,
});

export interface UpdateHCBrandingModelAction {
    payload: HCBrandingResponse;
    type: typeof UPDATE_HELP_CENTER_BRANDING_MODEL;
}

export const updateHCBrandingModelAction = (brandingResponse: HCBrandingResponse): UpdateHCBrandingModelAction => ({
    payload: brandingResponse,
    type: UPDATE_HELP_CENTER_BRANDING_MODEL,
});

export interface UpdateHCThemeColorAction {
    payload: HCThemeColorResponse;
    type: typeof UPDATE_HELP_CENTER_THEME_COLOR;
}

export const updateHCThemeColorAction = (themeColorResponse: HCThemeColorResponse): UpdateHCThemeColorAction => ({
    payload: themeColorResponse,
    type: UPDATE_HELP_CENTER_THEME_COLOR,
});

export interface UpdateHCBrandingLoaderAction {
    payload: { loaded: boolean };
    type: typeof UPDATE_HELP_CENTER_BRANDING_LOADER;
}

export const updateHCBrandingLoaderAction = (loaded: boolean): UpdateHCBrandingLoaderAction => ({
    payload: { loaded },
    type: UPDATE_HELP_CENTER_BRANDING_LOADER,
});
