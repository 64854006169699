import { connect } from 'react-redux';
import { fetchArticlesAction } from 'epics/model/kb-article';
import type { State } from 'state';
import { fetchPortalAction } from 'state/actions/portal';
import { getArticles } from 'state/selectors/kb-article';
import {
    getCategory,
    getProjectId,
    getPortalOrError,
    getName,
    getPortalLink,
    canCreateRequest,
} from 'state/selectors/portal';
import KnowledgeCategoryComponent from './knowledge-category';

const mapStateToProps = (
    state: State,
    props: { match: { params?: { portalId?: string; categoryId?: string } } }
): Omit<ExtractProps<typeof KnowledgeCategoryComponent>, 'fetchPortal' | 'fetchArticles'> => {
    let portalId: number | undefined;
    let categoryId: string | undefined;

    if (props.match.params) {
        portalId = Number(props.match.params.portalId);
        categoryId = props.match.params.categoryId;
    }

    const showRequestCreateButton = canCreateRequest(state, portalId);
    const portal = getPortalOrError(state, portalId);
    const portalName = getName(state, portalId);
    const projectId = getProjectId(state, portalId);
    const shouldFetchPortalOnMount = !portal;
    const portalLink = getPortalLink(state, portalId);
    const categoryOrError = getCategory(state, portalId, categoryId);
    const articlesOrError = getArticles(state, categoryId);
    const category = !!categoryOrError && 'name' in categoryOrError ? categoryOrError : undefined;
    // we show a different loading spinner specifically for articles,
    // this way we can still display the user the category information and the bread-crumbs as soon as we have that data
    const isArticlesLoading = !articlesOrError;
    const portalError = !!portal && 'error' in portal ? portal : undefined;
    const articleFetchError = !!articlesOrError && 'error' in articlesOrError ? articlesOrError : undefined;

    let error;
    if (!!portalError) error = portalError;
    // any errors that occur when fetching categories will be available in articleFetchError
    // as the two calls are combined on initial page load
    if (!!articleFetchError && !isArticlesLoading) {
        error = articleFetchError;
    }

    const isLoading = !portal || (!categoryOrError && !error);
    const articles = Array.isArray(articlesOrError) ? articlesOrError : undefined;

    return {
        portalId,
        categoryId,
        projectId,
        category,
        articles,
        portalName,
        isLoading,
        isArticlesLoading,
        shouldFetchPortalOnMount,
        portalLink,
        showRequestCreateButton,
        error: error ? error.error : undefined,
    };
};

export const KnowledgeCategory = connect(mapStateToProps, {
    fetchPortal: fetchPortalAction,
    fetchArticles: fetchArticlesAction,
})(KnowledgeCategoryComponent);
