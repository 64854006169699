import { put, doDelete } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface RequestGroup {
    id: number;
    name: string;
}

export interface RequestTypePayload {
    id: string;
    icon: string;
    iconUrl: string;
    name: string;
    description: string;
    groups: RequestGroup[];
}

export const setRequestGroupsForRequestType = ({
    isProjectSimplified,
    requestType,
    updatedRequestTypeGroupId,
    requestGroupId,
    projectId,
    context = contextPath,
}: {
    isProjectSimplified: boolean;
    projectId: number;
    updatedRequestTypeGroupId: number;
    requestGroupId: number | 'hidden';
    requestType: RequestTypePayload;
    context?: string;
}) => {
    if (isProjectSimplified) {
        const nextGenUri = (groupId: number) =>
            `${context}/rest/servicedesk/1/simplified/${projectId}/request-type-groups/${groupId}/${requestType.id}`;
        const isAdded = requestType.groups.some(({ id }) => id === updatedRequestTypeGroupId);
        const httpMethod = isAdded ? put : doDelete;

        return (
            httpMethod(nextGenUri(updatedRequestTypeGroupId))
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .map(({ response }) => response)
                .catch((e) => {
                    // Rethrow error
                    return Observable.throw(e);
                })
        );
    }

    // In Classic:
    // The request type <-> group association is stored on a request type in the backend
    // We call the update request type endpoint with the request type in the body with the new group associsation
    const classicUri = `${context}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups/${requestGroupId}/request-types/${requestType.id}`;

    return (
        put(classicUri, requestType, {
            'Content-Type': 'application/json',
        })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e) => {
                // Rethrow error
                return Observable.throw(e);
            })
    );
};
