import { Observable } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { fetchAllRequestGroups } from 'rest/request-groups';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import type {
    GetAllRequestGroupsAction,
    GetAllRequestGroupsSuccess,
    GetAllRequestGroupsFailure,
    RequestGroupResponse,
} from 'state/actions/request-groups';
import { GET_ALL_REQUEST_GROUPS, getRequestGroupsSuccess, getRequestGroupsFailure } from 'state/actions/request-groups';
import { getFirstMessageFromJiraError } from '@atlassian/help-center-common-util/errors/errors';

export const getAllRequestGroupsEpic: Epic<
    GetAllRequestGroupsAction,
    GetAllRequestGroupsSuccess | GetAllRequestGroupsFailure
> = (action$) =>
    action$.ofType(GET_ALL_REQUEST_GROUPS).mergeMap((action: GetAllRequestGroupsAction) => {
        const { projectId, portalId } = action.payload;
        return fetchAllRequestGroups(projectId)
            .map((requestGroups: RequestGroupResponse[]) => getRequestGroupsSuccess({ portalId, requestGroups }))
            .catch((e: AjaxError) => {
                const errorMessage = getFirstMessageFromJiraError(e.response);
                return Observable.of(getRequestGroupsFailure({ errorMessage, projectId }));
            });
    });
