import * as React from 'react';
import type { UFOExperience } from '@atlassian/ufo';
import type { CustomData } from '@atlassian/ufo/dist/types/types';
import { ExperienceContext } from '../experience/experience';

export type NotifierType = 'IMMEDIATE_SUCCESS' | 'IMMEDIATE_FAILURE' | 'CAN_SUCCEED_OR_FAIL' | 'CAN_FAIL';

export interface NotifyProps {
    notifySuccess: () => void;
    notifyFailure: (reason: string, traceId?: string) => void;
}

type ChildrenFunction = (opts: NotifyProps) => React.ReactNode;

export interface ExperienceNotifierProps {
    type: NotifierType;
    /**
     * Used to specify _where_ inside the experience this notifier is.
     */
    location: string;
    children?: ChildrenFunction | JSX.Element;
    errorMessage?: string;
    traceId?: string;
    responseHeaders?: string;
    status?: number;
}

export default class ExperienceNotifier extends React.Component<ExperienceNotifierProps> {
    static contextType = ExperienceContext;
    context!: React.ContextType<typeof ExperienceContext>;
    hasSignalledSuccess: boolean = false;

    componentDidMount() {
        const { type, location, errorMessage, traceId, responseHeaders, status } = this.props;
        if (type !== 'CAN_FAIL') {
            this.context.onPending();
        }

        if (type === 'IMMEDIATE_FAILURE') {
            this.onFailure(errorMessage || location, traceId, responseHeaders, status);
        } else if (type === 'IMMEDIATE_SUCCESS') {
            this.onSuccess();
        }
        // Otherwise we wait for the child to either throw an error or call `onExperienceSuccess`.
    }

    componentWillUnmount() {
        // If this component is being unmounted and still hasn't notified - assume it was successful.
        if (this.props.type !== 'CAN_FAIL') {
            this.onSuccess();
        }
    }

    onSuccess = () => {
        // This guards against the possibility of the child calling `onExperienceSuccess` repeatedly.
        if (!this.hasSignalledSuccess) {
            this.hasSignalledSuccess = true;
            this.context.onSuccess();
        }
    };

    onFailure = (errorMessage: string, traceId?: string, responseHeaders?: string, status?: number) => {
        this.context.onFailure({
            errorMessage,
            traceId,
            responseHeaders,
            status,
            errorName: 'NotifyFailure',
            unhandledError: false,
            errorLocation: this.props.location,
        });
    };

    componentDidCatch(error: Error) {
        const { name, message } = error;
        this.context.onFailure({
            errorMessage: message,
            errorName: name,
            traceId: this.props.traceId,
            unhandledError: true,
            errorLocation: this.props.location,
        });
        // We're deliberately rethrow the error here because we don't want to interfere with
        // any error handling the container app may have in place. This app exists only to track
        // error/success metrics; we don't want to interfere with the app that's actually rendering UI.
        throw error;
    }

    render() {
        const { children } = this.props;
        const markup =
            typeof children === 'function'
                ? children({ notifySuccess: this.onSuccess, notifyFailure: this.onFailure })
                : children;

        return markup || null;
    }
}

export const ExperienceFailure = ({
    experience,
    config,
}: {
    experience: UFOExperience;
    config?: {
        metadata: CustomData;
    };
}) => {
    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        experience.failure(config);
    }, [experience, config]);

    return <></>;
};
