import { debounce } from 'lodash';

interface Options {
    leading?: boolean;
    maxWait?: number;
    trailing?: boolean;
}

const defer = <TReturnValue extends object | null>() => {
    let resolve: (value?: TReturnValue | PromiseLike<TReturnValue> | undefined) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: (reason?: any) => void;

    const promise = new Promise<TReturnValue>((rslv, rjct) => {
        resolve = rslv;
        reject = rjct;
    });

    return {
        promise,
        // @ts-ignore
        resolve,
        // @ts-ignore
        reject,
    };
};

const proxyFunction = <TReturnValue extends object | null, TArg>(
    func: (arg?: TArg[]) => Promise<TReturnValue>,
    wait: number,
    options: Options = {}
) => {
    let arrArg: TArg[] = [];
    let deferred = defer<TReturnValue>();

    const callThenResetArgs = (args: TArg[]) => {
        try {
            const result = func(args);
            deferred.resolve(result);
        } catch (err) {
            deferred.reject(err);
        }

        arrArg = [];
        deferred = defer<TReturnValue>();
    };

    // Suppressing existing violations for the testing-library/react plugin.
    // eslint-disable-next-line testing-library/await-async-utils
    const debouncedFunc = debounce(callThenResetArgs, wait, options);

    return (arg?: TArg): Promise<TReturnValue> => {
        if (arg) {
            arrArg = arrArg.concat(arg);
        }
        debouncedFunc(arrArg);
        return deferred.promise;
    };
};

export default proxyFunction;
