import * as React from 'react';
import { get } from 'epics/ajax';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';

import { contextPath } from '@atlassian/help-center-common-util/history';

import messages from './messages';

interface Props {
    origin: string | null;
}

interface State {
    value?: string;
    isErrored?: boolean;
}

export class AssetFieldValue extends React.Component<Props & WrappedComponentProps, State> {
    state: State = {};

    componentDidMount() {
        const { origin } = this.props;
        if (origin === null) {
            return;
        }
        get(`${contextPath}/rest/assetapi/asset/${origin}`)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)

            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            .map((asset) => this.setState({ value: asset.label.value }))
            .subscribe({
                error: () => this.setState({ isErrored: true }),
            });
    }

    render() {
        const { intl, origin } = this.props;
        const { value, isErrored } = this.state;

        if (isErrored || origin === null) {
            return intl.formatMessage(messages.unknown);
        }

        if (value === undefined) {
            return intl.formatMessage(messages.loading);
        }

        return value;
    }
}

export default injectIntl(AssetFieldValue);
