import type { ErrorResponse } from 'epics/model/types';

export const FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS =
    'state.persisted.unsibscribedconfirmation.FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS';
export const FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE =
    'state.persisted.unsibscribedconfirmation.FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE';

export interface UnsubscribedConfirmationResponse {
    requestKey: string;
    requestUrl: string;
    summary: string;
}

export interface FetchUnsubscribedConfirmationSuccess {
    type: typeof FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS;
    payload: UnsubscribedConfirmationResponse;
}

export const fetchUnsubscribedConfirmationSuccess = (
    unsubscribedConfirmationModel: UnsubscribedConfirmationResponse
): FetchUnsubscribedConfirmationSuccess => ({
    payload: unsubscribedConfirmationModel,
    type: FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS,
});

export interface FetchUnsubscribedConfirmationFailure {
    type: typeof FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchUnsubscribedConfirmationFailure = (error: ErrorResponse): FetchUnsubscribedConfirmationFailure => ({
    type: FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE,
    payload: {
        error,
    },
});
