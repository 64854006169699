import { isSearchUIRedesignEnabled } from 'feature-flags';

const isNewSearchUI = isSearchUIRedesignEnabled();

export const DEFAULT_RESULTS_COUNT = isNewSearchUI ? 31 : 5;
export const INITIAL_ARTICLES_COUNT = isNewSearchUI ? 11 : 5;
export const INITIAL_REQUEST_FORMS_COUNT = 5;
export const INITIAL_PORTALS_COUNT = 3;
export const SHOW_MORE_RESULTS_COUNT = isNewSearchUI ? 30 : 8;
export const ALL_RESULTS_COUNT = 30;
export const SEARCH_QUERY_DEBOUNCE_TIME = 300;
