import type { ActionsObservable } from 'redux-observable';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import { addRequestGroup } from 'rest/request-groups';
import type { RequestGroup } from 'rest/request-types';
import type {
    CreateGroupWithRequestTypeAction,
    CreateGroupWithRequestTypeFailedAction,
} from 'state/actions/create-group-with-request-type';
import {
    CREATE_GROUP_WITH_REQUEST_TYPE,
    createGroupWithRequestTypeFailedAction,
} from 'state/actions/create-group-with-request-type';
import type { GetAllRequestGroupsAction } from 'state/actions/request-groups';
import { getRequestGroupsAction } from 'state/actions/request-groups';
import type { SetRequestTypeGroupsAction } from 'state/actions/set-request-type-groups';
import { setRequestTypeGroupsAction } from 'state/actions/set-request-type-groups';
import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getFirstMessageFromJiraError } from '@atlassian/help-center-common-util/errors/index';

export const createGroupWithRequestTypeEpic: Epic<
    CreateGroupWithRequestTypeAction,
    GetAllRequestGroupsAction | SetRequestTypeGroupsAction | CreateGroupWithRequestTypeFailedAction
> = (action$: ActionsObservable<CreateGroupWithRequestTypeAction>) => {
    return action$.ofType(CREATE_GROUP_WITH_REQUEST_TYPE).mergeMap((action: CreateGroupWithRequestTypeAction) => {
        const {
            isProjectSimplified,
            projectId,
            portalId,
            groupName,
            analyticsEventRequestGroupCreated,
            analyticsEventRequestGroupUpdated,
            requestType,
            requestGroups,
            analyticsEventRequestGroupCreatedFailure,
            analyticsEventRequestGroupUpdatedFailure,
        } = action.payload;
        return addRequestGroup(groupName, projectId)
            .mergeMap((response: RequestGroup) => {
                if (analyticsEventRequestGroupCreated) {
                    // Update the analytics event with newly created Request type group's ID as the actionSubjectId
                    analyticsEventRequestGroupCreated.update((payload: AnalyticsEventPayload) => ({
                        ...payload,
                        actionSubjectId: response.id,
                    }));
                    sendEvent(analyticsEventRequestGroupCreated);
                }
                if (analyticsEventRequestGroupUpdated) {
                    // Update the analytics event with newly created Request type group's ID as the actionSubjectId
                    analyticsEventRequestGroupUpdated.update((payload: AnalyticsEventPayload) => ({
                        ...payload,
                        actionSubjectId: response.id,
                    }));
                }
                return concat(
                    Observable.of(getRequestGroupsAction(projectId, portalId)),
                    Observable.of(
                        setRequestTypeGroupsAction({
                            isProjectSimplified,
                            portalId,
                            projectId,
                            requestType,
                            updatedRequestTypeGroupId: response.id,
                            analyticsEvent: analyticsEventRequestGroupUpdated,
                            requestGroups: [...requestGroups, response],
                        })
                    )
                );
            })
            .catch((error) => {
                [analyticsEventRequestGroupCreatedFailure, analyticsEventRequestGroupUpdatedFailure]
                    .filter((x) => x)
                    .forEach((event: UIAnalyticsEvent) => {
                        sendEvent(event);
                    });
                return Observable.of(
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    createGroupWithRequestTypeFailedAction(getFirstMessageFromJiraError(error.response))
                );
            });
    });
};
