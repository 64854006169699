import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { resendVerifyEmail } from 'rest/resend-verify-email';
import type { HandleAjaxError } from 'state/actions/flags';

import type {
    FetchResendVerificationSuccess,
    FetchResendVerificationFailure,
    FetchResendVerifyEmailAction,
} from 'state/actions/resend-verify-email';
import {
    fetchResendVerificationSuccess,
    fetchResendVerificationFailure,
    FETCH_RESEND_VERIFY_EMAIL,
} from 'state/actions/resend-verify-email';

export const resendVerifyEmailEpic: Epic<
    FetchResendVerifyEmailAction,
    FetchResendVerificationSuccess | FetchResendVerificationFailure | HandleAjaxError
> = (action$) => {
    return action$.ofType(FETCH_RESEND_VERIFY_EMAIL).mergeMap((action) => {
        const { emailAddress, portalId } = action.payload.params;
        const errorResponse = {
            status: 503,
        } as AjaxError;
        return resendVerifyEmail(emailAddress, portalId)
            .map((response) => {
                if (response.status === 204) {
                    return fetchResendVerificationSuccess();
                }
                return fetchResendVerificationFailure(errorResponse);
            })
            .catch((error) => Observable.of(fetchResendVerificationFailure(error)));
    });
};
