import { connectApi } from '../connect-api';

function init() {
    const { connectHost } = window as Window;
    const api: ServiceDeskConnectApi = connectApi(connectHost);

    function createRequestPropertiesModule() {
        class RequestProperties {
            validate(isValid: boolean, context: ConnectMetadata) {
                api.validate(isValid, context._context);
            }
            serialize(data: object, context: ConnectMetadata) {
                api.serialize(data, context._context);
            }
        }

        return {
            constructor: RequestProperties,
            validate: RequestProperties.prototype.validate,
            serialize: RequestProperties.prototype.serialize,
        };
    }

    const jiraServiceDesk = {
        RequestProperties: createRequestPropertiesModule(),
    };

    connectHost.defineModule('jiraServiceDesk', jiraServiceDesk);
}

export default init;
