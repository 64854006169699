import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { withLayoutRoute } from 'view/layout/with-layout-route';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withAsync } from '@atlassian/help-center-common-component/with-async';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentClass<any> | React.FunctionComponent<any>;
    screenName?: ScreenName;
    renderError?: () => React.ReactNode;
    headerless?: boolean;
    footerless?: boolean;
}
const LoadAppLazily = withAsync({
    loader: lazyForPaint(() =>
        import(/* webpackChunkName: "async-unified-help-layout" */ 'unified-help-center-alpha/src/app/App').then(
            ({ App }) => withLayoutRoute(App)
        )
    ),
});

const Layout: React.VFC<Props> = ({ component, screenName, renderError, headerless = false, footerless = false }) => {
    return (
        <LoadAppLazily
            component={component}
            screenName={screenName}
            renderError={renderError}
            layoutProps={{
                headerless,
                footerless,
            }}
        />
    );
};
export default Layout;
