import type { AjaxError } from 'rxjs';

import { post } from 'epics/ajax';
import { RESOURCE_TYPE_ISSUE, RESOURCE_TYPE_PROJECT } from '@atlassian/help-center-common-component/forge-ui/constants';
import type { ExtensionPointModule, ResourceContext } from '@atlassian/help-center-common-component/forge-ui/types';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { contextPath } from '@atlassian/help-center-common-util/history';

import type { FetchTFExtensionsAjaxResponse, FetchTFExtensionsData } from './types';

export const fetchTFExtensions = (
    types: ExtensionPointModule[],
    resourceContext?: ResourceContext
): Promise<FetchTFExtensionsData> => {
    const uri = `${contextPath}/rest/servicedesk/1/customer/servicedesk-data/forge/fetch-extensions`;
    let body;
    const { resourceType } = resourceContext || {};
    if (resourceType === RESOURCE_TYPE_ISSUE || resourceType === RESOURCE_TYPE_PROJECT) {
        body = { resourceContext, extensionTypes: types };
    } else {
        body = { extensionTypes: types };
    }
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };
    return post(uri, body, headers)
        .map(({ response }: FetchTFExtensionsAjaxResponse) => response.extensions)
        .catch((e: AjaxError) => {
            trackError('forge-tfe.fetch.failed', {}, e);
            return Promise.reject(e);
        })
        .toPromise();
};
