import { defineMessages } from 'react-intl-next';

export default defineMessages({
    learnByDoingDesc: {
        id: 'help-center.learn-by-doing.create-request.description',
        defaultMessage: 'Welcome to the portal! Try raising a request like your customers.',
        description: 'Description for Learn By Doing create Request component',
    },
    chooseRequestTypeHeading: {
        id: 'help-center.learn-by-doing.choose-request-type.heading',
        defaultMessage: 'Choose a request type',
        description: 'Heading for choosing a request type in Learn By Doing component',
    },
    chooseRequestTypeDesc: {
        id: 'help-center.learn-by-doing.choose-request-type.description',
        defaultMessage: 'Request types represent what your team can help customers with.',
        description: 'Description about request types',
    },
    sendExampleRequestHeading: {
        id: 'help-center.learn-by-doing.send-example-request.heading',
        defaultMessage: 'Send an example request',
        description: 'Heading for sending an example request in Learn By Doing component',
    },
    sendExampleRequestDesc: {
        id: 'help-center.learn-by-doing.send-example-request.description',
        defaultMessage: 'Fill out the request details, then send it off!',
        description: 'Description for sending a request',
    },
    requestCreatedHeading: {
        id: 'help-center.learn-by-doing.request-created.heading',
        defaultMessage: 'Manage request as an agent',
        description: 'Heading after request is created in Learn By Doing component',
    },
    requestCreatedDesc: {
        id: 'help-center.learn-by-doing.request-created.description',
        defaultMessage: 'Assign, add comments and resolve this request in your project.',
        description: 'Description after request is created',
    },
    queuesRedirectLabel: {
        id: 'help-center.learn-by-doing.queues-redirect.label',
        defaultMessage: 'Go to agent view',
        description: 'Title for queues redirect button',
    },
});
