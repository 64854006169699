import type { GraphQLError } from 'graphql';
import type { ExecutionResult, Sink, SubscribePayload } from 'graphql-ws';
import type { GraphQLResponse, RequestParameters, SubscribeFunction, Variables } from 'relay-runtime';
import { Observable } from 'relay-runtime';
import { getAnalyticsWebClientPromise } from '@atlassian/help-center-common-util/analytics/client';
import { getClient } from '../subscription-client/subscription-client';
/*
    This method is used to handle the subscriptions in relay network layer.
    ref: https://relay.dev/docs/guided-tour/updating-data/graphql-subscriptions/
*/
export const subscribe: SubscribeFunction = ({ id, name: operationName }: RequestParameters, variables: Variables) =>
    Observable.create((sink) => {
        const client = getClient();
        if (!client) {
            return sink.error(new Error('Unable to create subscription client'));
        }
        const payload: SubscribePayload = {
            operationName,
            variables,
            query: '',
            extensions: {
                pq: id,
                ...(typeof process.env.AGG_BRANCH_SLUG === 'string' && process.env.AGG_BRANCH_SLUG !== ''
                    ? { schema: process.env.AGG_BRANCH_SLUG }
                    : {}),
            },
        };
        let requestId: string | null;
        return client.subscribe(payload, {
            ...sink,
            next: (value: { extensions?: { agg?: { request_id?: string } } }) => {
                const aggRequestId = value?.extensions?.agg?.request_id as string | null;
                requestId = aggRequestId ?? requestId;
                sink.next(value as GraphQLResponse);
            },
            error: (sinkError: SinkError) => {
                const errorInfo = getSinkError(sinkError);
                sink.error(errorInfo);
                const attributes = { operationName, requestId };
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                getAnalyticsWebClientPromise().then((analyticsClient) => {
                    const analyticsPayload = {
                        action: 'failed',
                        actionSubject: 'relaySubscription',
                        source: 'portal-frontend',
                        ...attributes,
                    };
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    analyticsClient.getInstance().sendOperationalEvent(analyticsPayload);
                });
            },
        } as Sink<ExecutionResult<Record<string, unknown>, unknown>>);
    });
export type SinkError = Error | readonly GraphQLError[] | CloseEvent;
export const getSinkError = (error: SinkError): Error => {
    let errorInfo: Error;
    if (Array.isArray(error)) {
        [errorInfo] = error as readonly GraphQLError[];
    } else if (error instanceof Error) {
        errorInfo = error;
    } else if (typeof error === 'object' && 'type' in error && error.type === 'close') {
        errorInfo = new Error(`CloseEvent: ${(error as CloseEvent).reason}`);
    } else if (error instanceof Event) {
        errorInfo = new Error(`Event: ${(error as Event).type}`);
    } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        errorInfo = new Error(`Unknown sink error: ${error}`);
    }
    return errorInfo;
};
