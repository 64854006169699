import * as React from 'react';
import ErrorBoundary from 'view/error-boundary/error-boundary';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';

const ScreenErrorBoundary = ({
    screenName,
    children,
    renderError,
}: {
    screenName?: ScreenName;
    children: JSX.Element;
    renderError?: () => React.ReactNode;
}) => (
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <ErrorBoundary packageName={screenName} id={`${screenName}Screen`} renderError={renderError}>
        {children}
    </ErrorBoundary>
);

export default ScreenErrorBoundary;
