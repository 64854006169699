/**
 * @generated SignedSource<<af1f790f792cf6343067785706f20947>>
 * @relayHash 6337212810c49a971e575c2b12da2d45
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 03c90fb33e97de1111ac72fc2f5f2d0015bf74b51ff66581f9c6c1ae79817956

import type { ConcreteRequest, Query } from 'relay-runtime';
export type sidebarHelpCenterHeaderQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type sidebarHelpCenterHeaderQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly helpCenterBranding: {
        readonly banner: {
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly colors: {
          readonly primary: string | null | undefined;
        } | null | undefined;
        readonly useDefaultBanner: boolean | null | undefined;
      };
      readonly name: {
        readonly default: string;
      } | null | undefined;
    } | {
      readonly __typename: "QueryError";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type sidebarHelpCenterHeaderQuery = {
  response: sidebarHelpCenterHeaderQuery$data;
  variables: sidebarHelpCenterHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "concreteType": "HelpCenterName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "default"
    }
  ]
},
v5 = {
  "concreteType": "HelpCenterBranding",
  "kind": "LinkedField",
  "name": "helpCenterBranding",
  "plural": false,
  "selections": [
    {
      "concreteType": "HelpCenterBanner",
      "kind": "LinkedField",
      "name": "banner",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "url"
        }
      ]
    },
    {
      "concreteType": "HelpCenterBrandingColors",
      "kind": "LinkedField",
      "name": "colors",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "primary"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "useDefaultBanner"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "sidebarHelpCenterHeaderQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "helpCenter.helpCenterById.helpCenterBranding"
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sidebarHelpCenterHeaderQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "03c90fb33e97de1111ac72fc2f5f2d0015bf74b51ff66581f9c6c1ae79817956",
    "metadata": {},
    "name": "sidebarHelpCenterHeaderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b1b17113e136ecbdd8bfb74c4910cc78";

export default node;
