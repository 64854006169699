import type { FetchUnsubscribedConfirmationAction } from 'epics/model/unsubscribed-confirmation';
import { FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL } from 'epics/model/unsubscribed-confirmation';

import type {
    FetchUnsubscribedConfirmationSuccess,
    FetchUnsubscribedConfirmationFailure,
} from 'state/actions/unsubscribed-confirmation';
import {
    FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS,
    FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE,
} from 'state/actions/unsubscribed-confirmation';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface UnsubscribedConfirmation {
    requestKey: string;
    requestUrl: string;
    summary: string;
}

export interface UnsubscribedConfirmationState {
    unsubscribedConfirmation?: UnsubscribedConfirmation | PersistedError;
}

export const getInitialUnsubscribedConfirmationState = (): UnsubscribedConfirmationState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.unsubscribedConfirmation) {
        return {
            unsubscribedConfirmation: initialModelState.unsubscribedConfirmation,
        };
    }
    return {};
};

const defaultState: UnsubscribedConfirmationState = getInitialUnsubscribedConfirmationState();

export type HandledActions =
    | FetchUnsubscribedConfirmationAction
    | FetchUnsubscribedConfirmationSuccess
    | FetchUnsubscribedConfirmationFailure;

export function unsubscribedConfirmationReducer(
    state: UnsubscribedConfirmationState = defaultState,
    action: HandledActions
): UnsubscribedConfirmationState {
    switch (action.type) {
        case FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL: {
            const unsubscribedConfirmation = state.unsubscribedConfirmation;
            if (unsubscribedConfirmation && 'error' in unsubscribedConfirmation) {
                return {};
            }

            return state;
        }

        // We want to override the portal data if request create fails as well.
        case FETCH_UNSUBSCRIBED_CONFIRMATION_FAILURE: {
            const fetchPortalFailureAction = action as FetchUnsubscribedConfirmationFailure;
            const { error } = fetchPortalFailureAction.payload;
            const errorObject = {
                // We really only care about the first error message
                // grab it and throw the rest away
                status: error.status,
                message: (error.errorMessages || [])[0],
                // Remove basename from the URL, we don't need it as the basename
                // is already set inside react router.
                // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            };

            return {
                ...state,
                unsubscribedConfirmation: {
                    error: errorObject,
                },
            };
        }

        case FETCH_UNSUBSCRIBED_CONFIRMATION_SUCCESS: {
            const fetchPortalSuccessAction = action as FetchUnsubscribedConfirmationSuccess;
            const requestKey = fetchPortalSuccessAction.payload.requestKey;

            const unsubscribedConfirmation = {
                requestKey,
                requestUrl: fetchPortalSuccessAction.payload.requestUrl,
                summary: fetchPortalSuccessAction.payload.summary,
            };

            return {
                ...state,
                unsubscribedConfirmation,
            };
        }

        default:
            return state;
    }
}
