import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { logOutAction } from '@helpCenter/epics/logout/actions';
import type { State } from '@helpCenter/state';
import { editingHelpCenter } from '@helpCenter/state/actions/help-center-preview';
import { customizingRequestListColumn } from '@helpCenter/state/actions/requests-column-settings';
import { getPortalId, getRoutename } from '@helpCenter/state/selectors/context';
import {
    getJsmAIConfigEnabled,
    getHoistedProjectKey,
    getHelpCenterType,
    getJiraDisplayUrl,
} from '@helpCenter/state/selectors/env';
import { getCanCustomize } from '@helpCenter/state/selectors/help-center';
import {
    getName,
    getPortalAnnouncement,
    getCanAdministerProject,
    getIsProjectSimplified,
} from '@helpCenter/state/selectors/portal';
import { getRequestsColumnSettings } from '@helpCenter/state/selectors/requests-column-settings';
import {
    getAvatarSrc,
    getCanAdministerJIRA,
    getCustomMenuLinks,
    getDisplayName,
    getEmail,
} from '@helpCenter/state/selectors/user';
import { getUserRequestsCounts } from '@helpCenter/state/selectors/user-requests-counts';
import { CUSTOMER_SERVICE_HELP_CENTER_TYPE } from '@atlassian/help-center-common-util/help-center';
import { AvatarMenuDumbV2 } from '../avatar-menu-dumb-v2';
const getCustomMenuLinksProps = (state: State) => {
    const customMenuLinks = getCustomMenuLinks(state);
    return customMenuLinks.map((menuLink) => {
        return {
            id: menuLink.id,
            key: menuLink.key,
            label: menuLink.label,
            href: menuLink.href,
            styleClass: menuLink.styleClass,
        };
    });
};

const selector = createSelector(
    (state: State) => {
        const portalId = getPortalId(state);
        const routeName = getRoutename(state);
        const portal = {
            id: portalId,
            name: getName(state, portalId),
            canEditAnnouncement: Boolean(getPortalAnnouncement(state, portalId)?.canEditAnnouncement),
            isProjectAdmin: Boolean(getCanAdministerProject(state, portalId)),
            isProjectSimplified: Boolean(getIsProjectSimplified(state, portalId)),
        };
        const areCustomerServiceSettingsLinksAvailable =
            getHelpCenterType(state) === CUSTOMER_SERVICE_HELP_CENTER_TYPE && !!getHoistedProjectKey(state);
        return {
            portal,
            routeName,
            areCustomerServiceSettingsLinksAvailable,
            avatarSrc: getAvatarSrc(state),
            displayName: getDisplayName(state),
            email: getEmail(state),
            customMenuLinks: getCustomMenuLinksProps(state),
            canAdministerJIRA: getCanAdministerJIRA(state),
            canCustomiseHelpCenter: getCanCustomize(state),
            pendingApprovalCount: getUserRequestsCounts(state).pendingApprovalCount,
            myRequestCount: getUserRequestsCounts(state).myRequestCount,
            defaultColumnSettings: getRequestsColumnSettings(state),
            approvalCount: getUserRequestsCounts(state).approvalCount,
            jsmAIConfigEnabled: getJsmAIConfigEnabled(state),
            hoistedProjectKey: getHoistedProjectKey(state),
            jiraDisplayUrl: getJiraDisplayUrl(state),
        };
    },
    ({
        portal,
        areCustomerServiceSettingsLinksAvailable,
        avatarSrc,
        displayName,
        email,
        customMenuLinks,
        canAdministerJIRA,
        canCustomiseHelpCenter,
        routeName,
        defaultColumnSettings,
        pendingApprovalCount,
        myRequestCount,
        approvalCount,
        jsmAIConfigEnabled,
        hoistedProjectKey,
        jiraDisplayUrl,
    }) => ({
        portal,
        routeName,
        areCustomerServiceSettingsLinksAvailable,
        avatarSrc,
        displayName,
        email,
        customMenuLinks,
        canAdministerJIRA,
        canCustomiseHelpCenter,
        pendingApprovalCount,
        approvalCount,
        defaultColumnSettings,
        myRequestCount,
        jsmAIConfigEnabled,
        hoistedProjectKey,
        jiraDisplayUrl,
    })
);

export default connect(selector, {
    onLogoutClick: logOutAction,
    openHelpCenterCustomizationSidebar: () => editingHelpCenter(true),
    openRequestListCustomizationSidebar: () => customizingRequestListColumn(true),
})(AvatarMenuDumbV2);
