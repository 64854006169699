import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { FetchArticleAction, FetchArticleSuccessAction, FetchArticleFailureAction } from 'state/actions/article';
import { FETCH_ARTICLE, fetchArticleSuccess, fetchArticleFailure } from 'state/actions/article';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import fetchModel from '../fetcher';
import type { ErrorResponse } from '../types';

export const fetchArticleEpic: Epic<FetchArticleAction, FetchArticleSuccessAction | FetchArticleFailureAction> = (
    action$
) =>
    action$.ofType(FETCH_ARTICLE).mergeMap((action) => {
        const { meta } = action;
        return fetchModel(action.payload)
            .filter((response) => !!response.kbPage)
            .map((response) => {
                if (meta?.analyticsSuccessEvent) {
                    sendEvent(meta.analyticsSuccessEvent);
                }
                return fetchArticleSuccess(response.kbPage!);
            })
            .catch((error: ErrorResponse) => {
                if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.status)) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                return Observable.of(fetchArticleFailure(action.payload.params.id, error));
            });
    });
