import { put } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const transition = (issueKey: string, transitionId: number, comment: string, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/requests/transition`;
    const body = {
        issueKey,
        transitionId,
        comment,
    };
    const headers = {
        'Content-Type': 'application/json',
    };

    return put(uri, body, headers);
};
