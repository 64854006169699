import type { ModelTypeOptions } from 'rest/models';
import { buildModelsActionCreator } from '../actions';

export const FETCH_USER_MODEL = 'epic.model.FETCH_USER_MODEL';

export type FetchUserModelAction = ReturnType<typeof fetchUserModelAction>;
type UserOptionsType = ModelTypeOptions['user'];

export const fetchUserModelAction = (params: UserOptionsType = {}) => {
    const excludeRequestCountsParam: UserOptionsType = {
        ...params,
        fetchRequestCounts: false,
    };
    return buildModelsActionCreator(FETCH_USER_MODEL, 'user')(excludeRequestCountsParam);
};
