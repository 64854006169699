// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { FOOTER_HEIGHT } from 'view/layout/footer/styled';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const topicContainerWidth = '70%';
const topicContainerMinHeight = `calc(100vh - ${FOOTER_HEIGHT}px)`;
const helpObjectContainerWidth = '30%';
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid ${token('color.border', colors.N30)};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const TopicContainer = styled.div`
    width: ${topicContainerWidth};
    padding: ${token('space.400', '32px')} ${token('space.1000', '80px')};
    background-color: ${token('elevation.surface', colors.N0)};
    border-right: 1px solid ${token('color.border', colors.N30)};
    box-sizing: border-box;
    min-height: ${topicContainerMinHeight};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const HelpObjectContainer = styled.div`
    width: ${helpObjectContainerWidth};
    box-sizing: border-box;
    padding: ${token('space.150', '12px')} ${token('space.300', '24px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SpinnerWrapper = styled.div`
    ${mixins.flexCenter};
    margin-top: ${token('space.400', '32px')};
`;
