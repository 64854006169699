export {
    getFeaturedPortalsInSortedOrderByRank,
    isFeaturedPortal,
    isHiddenPortal,
    getPortals,
    getSortBy,
    getLimit,
    getIsDirty,
    getIsReorderingExperienceLoading,
    getIsSortedDataLoading,
    getTotal,
    getfeaturedAndSortedPortalsError,
    getUIFeaturedPortalIds,
    getUISortBy,
    getUIOrderedPortalIds,
    getPersistedOrderedPortalIds,
    getIsSaveInProgress,
    getDefaultViewLoading,
    getExpandedViewLoading,
    getIsPortalReorderingDataLoaded,
    getUIHiddenPortalIds,
    getIsPortalHidingEnabled,
    getTotalVisiblePortalCount,
    getAllVisiblePortals,
    getAllVisiblePortalsInHelpCenter,
    getDefaultViewPortals,
    getExpandedViewPortals,
    getVisibleFeaturedPortalsCount,
    getFeaturedPortalsUI,
    getUnFeaturedPortalsUI,
} from './featured-and-sorted-portals';
