import { remove, uniq } from 'lodash';
import type {
    FetchFeaturedAndSortedPortalsAction,
    FetchFeaturedAndSortedPortalsFailure,
    FetchFeaturedAndSortedPortalsSuccess,
    ReorderFeaturedPortal,
    MarkPortalFeatured,
    UpdatePortalReorderSettingsModel,
    UpdatePortalReorderSettingsModelSuccess,
    UpdatePortalReorderSettingsModelFailure,
    UpdatePortalReorderSettingsSuccess,
    UpdatePortalReorderSettingsFailure,
    ResetPortalReorderSettingsSuccess,
    MarkPortalReorderSettingsDirty,
    TogglePortalVisibility,
} from 'state/actions/featured-and-sorted-portals';
import {
    FETCH_FEATURED_AND_SORTED_PORTALS_MODEL,
    FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS,
    FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE,
    REORDER_FEATURED_PORTAL,
    MARK_PORTAL_FEATURED,
    UPDATE_PORTAL_REORDER_SETTINGS_MODEL,
    UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS,
    UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE,
    UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS,
    UPDATE_PORTAL_REORDER_SETTINGS_FAILURE,
    RESET_PORTAL_REORDER_SETTINGS_SUCCESS,
    MARK_PORTAL_REORDER_SETTINGS_DIRTY,
    TOGGLE_PORTAL_VISIBILITY,
} from 'state/actions/featured-and-sorted-portals';
import { isFeaturedPortal, isHiddenPortal } from 'state/selectors/featured-and-sorted-portals';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { SortBy } from 'rest/featured-and-sorted-portals/types';

export interface FeaturedAndSortedPortalsUIState {
    featuredPortalIds: number[];
    orderedPortalIds: number[];
    hiddenPortalIds: number[];
    sortBy: SortBy;
    isDefaultViewLoading: boolean;
    isExpandedViewLoading: boolean;
    isReorderingExperienceLoading: boolean;
    isSortedDataLoading: boolean;
    isDirty: boolean;
    isSaveInProgress: boolean;
    isPortalReorderingDataLoaded: boolean;
}

export type HandledActions =
    | FetchFeaturedAndSortedPortalsAction
    | FetchFeaturedAndSortedPortalsSuccess
    | FetchFeaturedAndSortedPortalsFailure
    | ReorderFeaturedPortal
    | MarkPortalFeatured
    | TogglePortalVisibility
    | MarkPortalReorderSettingsDirty
    | UpdatePortalReorderSettingsModel
    | UpdatePortalReorderSettingsModelSuccess
    | UpdatePortalReorderSettingsModelFailure
    | UpdatePortalReorderSettingsSuccess
    | UpdatePortalReorderSettingsFailure
    | ResetPortalReorderSettingsSuccess;

export const getEmptyFeaturedAndSortedPortalsUIState = (): FeaturedAndSortedPortalsUIState => {
    const initialModelState = initialModel();
    return {
        featuredPortalIds: [],
        orderedPortalIds: [],
        hiddenPortalIds: [],
        sortBy: 'POPULARITY',
        isDefaultViewLoading: !initialModelState?.featuredAndSortedPortals,
        isExpandedViewLoading: false,
        isReorderingExperienceLoading: false,
        isSortedDataLoading: false,
        isDirty: false,
        isSaveInProgress: false,
        isPortalReorderingDataLoaded: false,
    };
};

export const nanToZero = (rank: number | undefined) => rank || 0;

const defaultState: FeaturedAndSortedPortalsUIState = getEmptyFeaturedAndSortedPortalsUIState();

export function featuredAndSortedPortalsReducer(
    state: FeaturedAndSortedPortalsUIState = defaultState,
    action: HandledActions
): FeaturedAndSortedPortalsUIState {
    switch (action.type) {
        case FETCH_FEATURED_AND_SORTED_PORTALS_MODEL:
            return handleFetchFeaturedAndSortedPortalsAction(state, action);
        case FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS:
            return handleFetchFeaturedAndSortedPortalsSuccess(state, action);
        case FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE:
            return handleFetchFeaturedAndSortedPortalsFailure(state, action);
        case MARK_PORTAL_FEATURED:
            return handleMarkPortalFeatured(state, action);
        case TOGGLE_PORTAL_VISIBILITY:
            return handleTogglePortalVisibility(state, action);
        case REORDER_FEATURED_PORTAL:
            return handleReorderFeaturedPortal(state, action);
        case UPDATE_PORTAL_REORDER_SETTINGS_MODEL:
            return handleUpdatePortalReorderSettings(state);
        case UPDATE_PORTAL_REORDER_SETTINGS_MODEL_SUCCESS:
            return handleUpdatePortalReorderSettingsModelSuccess(state);
        case UPDATE_PORTAL_REORDER_SETTINGS_MODEL_FAILURE:
            return handleUpdatePortalReorderSettingsModelFailure(state);
        case UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS:
            return handleUpdatePortalReorderSettingsSuccess(state);
        case UPDATE_PORTAL_REORDER_SETTINGS_FAILURE:
            return handleUpdatePortalReorderSettingsFailure(state);
        case RESET_PORTAL_REORDER_SETTINGS_SUCCESS:
            return handleResetPortalReorderSettingsSuccess(state, action);
        case MARK_PORTAL_REORDER_SETTINGS_DIRTY:
            return handleMarkPortalReorderSettingsDirty(state);
        default:
            return state;
    }
}

const handleFetchFeaturedAndSortedPortalsAction = (
    state: FeaturedAndSortedPortalsUIState,
    action: FetchFeaturedAndSortedPortalsAction
): FeaturedAndSortedPortalsUIState => {
    if (action.payload.params.exploreAll) {
        /* On help seekers view, and clicked on exploreAll */
        if (!action.payload.params.isPortalReorderingSelected) {
            return {
                ...state,
                isExpandedViewLoading: true,
            };
        }
        /* on changing sorted preference on portal reordering screen */
        if (action.payload.params.sortBy) {
            return {
                ...state,
                isSortedDataLoading: true,
                sortBy: action.payload.params.sortBy ? action.payload.params.sortBy : 'POPULARITY',
            };
        }
        /* on initial page load for portal reordering screen */
        return {
            ...state,
            isReorderingExperienceLoading: true,
        };
    }
    /* On help seekers view, initial loading for featured portals or top 6 sorted portals */
    return {
        ...state,
        isDefaultViewLoading: true,
    };
};

const handleFetchFeaturedAndSortedPortalsSuccess = (
    state: FeaturedAndSortedPortalsUIState,
    action: FetchFeaturedAndSortedPortalsSuccess
): FeaturedAndSortedPortalsUIState => {
    if (!action.requestParams.isPortalReorderingSelected) {
        /* on Help seeker view - on clicking exploreAll */
        if (action.requestParams.exploreAll) {
            return {
                ...state,
                isExpandedViewLoading: false,
                isPortalReorderingDataLoaded: false,
            };
        }
        /* on Help seeker view - initial loading */
        return {
            ...state,
            isDefaultViewLoading: false,
            isPortalReorderingDataLoaded: false,
        };
    }
    /* on portal reordering screen - on changing sortBy option */
    if (action.requestParams.sortBy) {
        return {
            ...state,
            orderedPortalIds: action.payload.portals.map((portal) => portal.id),
            isSortedDataLoading: false,
            sortBy: action.requestParams.sortBy || state.sortBy,
        };
    }
    /* on portal reordering screen - initial load */
    return {
        ...state,
        featuredPortalIds: action.payload.portals
            .filter(isFeaturedPortal)
            .sort((portal1, portal2) => nanToZero(portal1.rank) - nanToZero(portal2.rank))
            .map((portal) => portal.id),
        hiddenPortalIds: action.payload.portals.filter(isHiddenPortal).map((portal) => portal.id),
        orderedPortalIds: action.payload.portals.map((portal) => portal.id),
        isReorderingExperienceLoading: false,
        sortBy: action.payload.sortBy || state.sortBy,
        isPortalReorderingDataLoaded: true,
    };
};

const handleFetchFeaturedAndSortedPortalsFailure = (
    state: FeaturedAndSortedPortalsUIState,
    action: FetchFeaturedAndSortedPortalsFailure
): FeaturedAndSortedPortalsUIState => {
    /* Error in  help seekers view*/
    if (!action.requestParams.isPortalReorderingSelected) {
        return {
            ...state,
            isExpandedViewLoading: false,
            isDefaultViewLoading: false,
        };
    }
    /* Error in portal reordering view */
    return {
        ...state,
        isReorderingExperienceLoading: false,
        isSortedDataLoading: false,
    };
};

const handleMarkPortalFeatured = (
    state: FeaturedAndSortedPortalsUIState,
    action: MarkPortalFeatured
): FeaturedAndSortedPortalsUIState => {
    const { id, isFeatured } = action.payload;
    const { featuredPortalIds } = state;
    const updateFeaturedPortalIds = [...featuredPortalIds];

    if (isFeatured) {
        updateFeaturedPortalIds.push(id);
    } else {
        remove(updateFeaturedPortalIds, (item: number) => item === id);
    }

    return {
        ...state,
        featuredPortalIds: updateFeaturedPortalIds,
        isDirty: true,
    };
};

const handleTogglePortalVisibility = (
    state: FeaturedAndSortedPortalsUIState,
    action: TogglePortalVisibility
): FeaturedAndSortedPortalsUIState => {
    const { id, hiddenFromHomeScreen } = action.payload;
    const { hiddenPortalIds } = state;
    const updatedHiddenPortalIds = [...hiddenPortalIds];

    if (hiddenFromHomeScreen) {
        updatedHiddenPortalIds.push(id);
    } else {
        remove(updatedHiddenPortalIds, (item: number) => item === id);
    }

    return {
        ...state,
        hiddenPortalIds: uniq(updatedHiddenPortalIds),
        isDirty: true,
    };
};

const reOrder = (list: FeaturedAndSortedPortalsUIState['featuredPortalIds'], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const handleReorderFeaturedPortal = (
    state: FeaturedAndSortedPortalsUIState,
    action: ReorderFeaturedPortal
): FeaturedAndSortedPortalsUIState => {
    const { oldIndex, newIndex } = action.payload;

    return {
        ...state,
        featuredPortalIds: reOrder(state.featuredPortalIds, oldIndex, newIndex),
        isDirty: true,
    };
};

const handleUpdatePortalReorderSettings = (state: FeaturedAndSortedPortalsUIState): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: true,
    };
};

const handleUpdatePortalReorderSettingsModelSuccess = (
    state: FeaturedAndSortedPortalsUIState
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: true,
    };
};

const handleUpdatePortalReorderSettingsModelFailure = (
    state: FeaturedAndSortedPortalsUIState
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: true,
    };
};

const handleUpdatePortalReorderSettingsSuccess = (
    state: FeaturedAndSortedPortalsUIState
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: false,
        isDirty: false,
    };
};

const handleUpdatePortalReorderSettingsFailure = (
    state: FeaturedAndSortedPortalsUIState
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: false,
        isDirty: true,
    };
};

// Common logic for both Reset and Cancel action
const handleResetPortalReorderSettingsSuccess = (
    state: FeaturedAndSortedPortalsUIState,
    action: ResetPortalReorderSettingsSuccess
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isSaveInProgress: false,
        isDirty: false,
        sortBy: action.payload.sortBy,
        featuredPortalIds: action.payload.featuredPortalIds,
        orderedPortalIds: action.payload.orderedPortalIds,
    };
};

const handleMarkPortalReorderSettingsDirty = (
    state: FeaturedAndSortedPortalsUIState
): FeaturedAndSortedPortalsUIState => {
    return {
        ...state,
        isDirty: true,
    };
};
