import { useState, useEffect } from 'react';

/**
 * If the 'value' changes multiple times in quick succession, within the specified 'delay' time frame,
 * the 'debouncedValue' only changes once and equals the latest 'value'
 */
const useDebouncedValue = <T,>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    // useEffect schedules an update to 'debouncedValue' after the delay
    // if the 'value' changes before the delay, the previous timeout is cleared, and a new one is started
    useEffect(() => {
        const timeoutID = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebouncedValue;
