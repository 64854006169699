import { getRelativeBasePath } from '@atlassian/help-center-common-util/history';
export const typeMap: Record<string, string> = {
    REQUEST_FORM: 'REQUEST_FORM',
    ARTICLE: 'ARTICLE',
    EXTERNAL_RESOURCE: 'ati:cloud:jira-servicedesk:external-resource',
};

export const isExternalResource = (entityKey: string): boolean => {
    return entityKey === typeMap.EXTERNAL_RESOURCE;
};

const getUrlForRouter = (displayLink: string) => {
    return displayLink.startsWith('/')
        ? displayLink.replace(getRelativeBasePath(), '')
        : displayLink.replace(`${location.origin}${getRelativeBasePath()}`, '');
};

export const urlRefsAndTarget = ({
    displayLink,
    isAnExternalResource,
}: {
    displayLink: string;
    isAnExternalResource: boolean;
}): { href: string; target: '_blank' } | { to: string; target: '_self' } =>
    isAnExternalResource
        ? { href: displayLink, target: '_blank' }
        : { to: getUrlForRouter(displayLink), target: '_self' };
