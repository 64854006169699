import type { FetchLanguagesSuccess } from 'state/actions/languages';
import { FETCH_LANGUAGES_SUCCESS } from 'state/actions/languages';
import { ensureConsistentResponses } from '@atlassian/help-center-common-util/languages';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface LanguageState {
    key: string;
    originalKey: string;
    displayName: string;
}

export interface LanguagesState {
    languages: LanguageState[];
}

export const getInitialLanguagesState = (): LanguagesState => {
    const initialModelState = initialModel();
    if (initialModelState) {
        const languages =
            initialModelState.availableLanguages === undefined
                ? []
                : ensureConsistentResponses(initialModelState.availableLanguages);
        return {
            languages,
        };
    }
    return {
        languages: [],
    };
};

export type HandledActions = FetchLanguagesSuccess;

const defaultState: LanguagesState = getInitialLanguagesState();

export function languagesReducer(state: LanguagesState = defaultState, action: HandledActions): LanguagesState {
    switch (action.type) {
        case FETCH_LANGUAGES_SUCCESS:
            return handleFetchLanguagesSuccess(state, action);
        default:
            return state;
    }
}

const handleFetchLanguagesSuccess = (state: LanguagesState, action: FetchLanguagesSuccess): LanguagesState => ({
    ...state,
    languages: ensureConsistentResponses(action.payload.languages),
});
