import { isRichTextAreaEnabled } from 'feature-flags';
import { parse } from 'query-string';
import type {
    DefaultLabel,
    FieldValues,
    FieldState,
    SelectState,
    MultiSelectState,
    CascadingSelectState,
    CheckboxGroupState,
    TextAreaState,
    CheckboxItem,
} from 'state/persisted/request-create';
import type { Option } from '@atlaskit/select';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import type { NestedOption } from '@atlassian/help-center-common-component/fields/cascading-select';
import type { NestedFieldValueType } from '@atlassian/help-center-common-component/fields/types';

import { plainTextToAdfParagraph } from '@atlassian/help-center-common-util/adf';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { search } from '@atlassian/help-center-common-util/location';

const CASCADE_SELECT_ID_SUFFIX = ':1';

export function parseFieldUrlParams(fields: FieldState[]): FieldValues {
    if (!fields || fields.length === 0) {
        return {};
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const urlParams = parse(search());
    if (!urlParams) {
        return {};
    }

    return fields.reduce((values, field: FieldState) => {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        const urlParamValue = urlParams[field.id];
        if (urlParamValue !== undefined) {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            const cascadeNestedSelectUrlParamValue = urlParams[`${field.id}${CASCADE_SELECT_ID_SUFFIX}`];

            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            values[field.id] = parseUrlParamValue(field, urlParamValue, cascadeNestedSelectUrlParamValue);

            if (cascadeNestedSelectUrlParamValue) {
                // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                values[`${field.id}${CASCADE_SELECT_ID_SUFFIX}`] = cascadeNestedSelectUrlParamValue;
            }
        }

        return values;
    }, {});
}

function parseUrlParamValue(
    field: FieldState,
    urlParamValue: string | string[],
    cascadeNestedSelectUrlParamValue?: string
) {
    firePrePopAnalyticsEvent(field);

    switch (field.type) {
        case 'labelpicker':
            return parseLabelsUrlParam(urlParamValue);
        case 'checkbox': {
            const checkboxGroup = field as CheckboxGroupState;
            return parseCheckboxUrlParam(urlParamValue as string, checkboxGroup.items);
        }
        case 'select': {
            const select = field as SelectState;
            return parseSelectUrlParam(urlParamValue as string, select.options);
        }
        case 'multiselect': {
            const multiSelect = field as MultiSelectState;
            return parseMultiSelectUrlParam(urlParamValue, multiSelect.options);
        }
        case 'cascadingselect': {
            const cascadingSelectState = field as CascadingSelectState;
            return parseCascadingSelectUrlParam(
                cascadingSelectState.options,
                urlParamValue as string,
                cascadeNestedSelectUrlParamValue
            );
        }
        case 'textarea': {
            if (isRichTextAreaEnabled() && (field as TextAreaState).rendererType === 'atlassian-wiki-renderer') {
                return parseRichTextAreaUrlParam(urlParamValue as string);
            }
            return urlParamValue;
        }
        default:
            return urlParamValue;
    }
}

function firePrePopAnalyticsEvent(field: FieldState) {
    const actionSubjectId = field.id.startsWith('customfield') ? 'customfield' : field.id;
    operationalEvent({
        actionSubjectId,
        action: 'prePopulated',
        actionSubject: 'field',
        source: ScreenName.REQUEST_CREATE,
        attributes: {
            fieldType: field.type,
        },
    });
}

function parseLabelsUrlParam(labels: string | string[]): DefaultLabel[] {
    if (isEmptyStringOrArray(labels)) {
        return [];
    }

    if (typeof labels === 'string') {
        const singleLabel = labels as string;
        return [
            {
                label: singleLabel,
                value: singleLabel,
            },
        ];
    }

    const multipleLabels = labels as string[];
    return multipleLabels.map((toConvert) => ({
        label: toConvert,
        value: toConvert,
    }));
}

const isEmptyStringOrArray = (value: string | string[]) => value.length === 0;

const parseSelectUrlParam = (urlParam: string, options: Option<string>[]) =>
    options.find((option) => option.value === urlParam);

function parseCheckboxUrlParam(checkedUrlParam: string | string[], items: CheckboxItem[]): string[] {
    if (isEmptyStringOrArray(checkedUrlParam)) {
        return [];
    }

    if (typeof checkedUrlParam === 'string') {
        const checkedBoxValue = checkedUrlParam as string;
        const checkedBox = items.find((item) => item.value === checkedBoxValue);
        return checkedBox ? [checkedBox.value] : [];
    }

    const checkedBoxValues = checkedUrlParam as string[];
    return items.filter((item) => checkedBoxValues.includes(item.value)).map((item) => item.value);
}

function parseMultiSelectUrlParam(selected: string | string[], options: Option<string>[]): Option<string>[] {
    if (isEmptyStringOrArray(selected)) {
        return [];
    }

    if (typeof selected === 'string') {
        const selection = selected as string;
        return options.filter((option) => option.value === selection);
    }

    const selections = selected as string[];
    return options.filter((option) => selections.includes(option.value));
}

function parseCascadingSelectUrlParam(
    options: NestedOption[],
    parentUrlParam: string,
    childUrlParam?: string
): NestedFieldValueType | null {
    const parentOption = options.find((option) => option.value === parentUrlParam);
    if (!parentOption) {
        return null;
    }

    const childOption = parentOption.children.find((option) => option.value === childUrlParam);
    return {
        primaryValue: parentOption.value,
        children: childOption && childOption.value,
    };
}

const parseRichTextAreaUrlParam = (urlParam: string): string => JSON.stringify(plainTextToAdfParagraph(urlParam));
