import { isFollowupEnabledForPortal } from 'feature-flags';
import { commitLocalUpdate } from 'react-relay';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import type { RecordProxy } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ASSISTANT_MESSAGE_STATUS } from 'unified-help-center-alpha/src/rest/converse';
import { getUHUpdatedMessage, type Message as AIMessage } from '@atlassian/conversation-assistant';
import type { UHStreamMessage } from '@atlassian/conversation-assistant/dist/types/services/unified-help/types';
import { HelpSeekerMessageStatus } from '../../common/types';
import { parseJson } from '../../common/utils';
import { addNodeToConnection } from '../add-message-to-connection';
import { createFollowupMessageNode } from '../store-actions';
import type { AssistantMessage } from 'unified-help-center-alpha/src/store/converse/types';

interface UpdateStreamingMessageInStoreArgs {
    environment: RelayModernEnvironment;
    recordId: string;
    streamingMessage: UHStreamMessage;
    connectionId?: string;
}

const getMessageMetaFromStore = (assistantMessage: RecordProxy | undefined, recordId: string) => {
    const DEFAULT_ASSISNTANT_MESSAGE: AssistantMessageMetaData = {
        status: ASSISTANT_MESSAGE_STATUS.AWAITING_RESPONSE,
        messageId: recordId,
    };
    if (!assistantMessage) return DEFAULT_ASSISNTANT_MESSAGE;
    const messageMeta = assistantMessage?.getValue('message_metaData') as string;
    try {
        return (parseJson(messageMeta) ?? DEFAULT_ASSISNTANT_MESSAGE) as AssistantMessageMetaData;
    } catch {
        return DEFAULT_ASSISNTANT_MESSAGE;
    }
};

type AssistantMessageMetaData = AssistantMessage['messageMetadata'];
export const updateStreamingMessageInStore = ({
    environment,
    recordId,
    streamingMessage,
}: UpdateStreamingMessageInStoreArgs) => {
    commitLocalUpdate(environment, (store) => {
        const node = store.get(recordId);
        const assistantMessageNode = node?.getOrCreateLinkedRecord('assistantMessage', 'JsmChatAssistantMessage');
        const messageContent = (assistantMessageNode?.getValue('content') ?? '') as string;
        const root = store.getRoot();
        const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
        const connectionId = jsmChatQuery.getValue('connectionId') as string;
        const { type, content } = getUHUpdatedMessage(streamingMessage, {
            content: messageContent,
            role: '',
            id: 0,
            time_created: '',
            user_ari: '',
            plugin_invocations: [],
        }) ?? { type: '' };
        const metaData = getMessageMetaFromStore(assistantMessageNode, recordId);

        // AI Conversation can be aborted from `stopGenerating` CTA in Input Box
        const abortStatus = assistantMessageNode?.getValue('isAborted');
        if (abortStatus) {
            metaData.status = ASSISTANT_MESSAGE_STATUS.ABORTED_RESPONSE;
            jsmChatQuery.setValue(ASSISTANT_MESSAGE_STATUS.ABORTED_RESPONSE, 'aiAnswerStatus');
            assistantMessageNode?.setValue(JSON.stringify(metaData), 'message_metaData');
            return;
        }

        switch (type) {
            case 'FOLLOW_UP_QUERIES': {
                if (isFollowupEnabledForPortal()) {
                    const nodeId = createFollowupMessageNode(content as string[], store);
                    addNodeToConnection({
                        store,
                        connectionId,
                        recordId: nodeId,
                        edgeName: 'JsmChatMessageEdge',
                    });
                }
                break;
            }
            case 'CONTENT_SEARCH':
                metaData.keywords = (content as string).split('|');
                metaData.status = ASSISTANT_MESSAGE_STATUS.LOADING_RESPONSE;
                assistantMessageNode?.setValue(JSON.stringify(metaData), 'message_metaData');
                jsmChatQuery.setValue(ASSISTANT_MESSAGE_STATUS.LOADING_RESPONSE, 'aiAnswerStatus');
                break;
            case 'ANALYSING_STATE':
                metaData.sources = content;
                assistantMessageNode?.setValue(JSON.stringify(metaData), 'message_metaData');
                break;

            case 'ANSWER_PART':
                metaData.status = ASSISTANT_MESSAGE_STATUS.TYPING_RESPONSE;
                assistantMessageNode?.setValue(JSON.stringify(metaData), 'message_metaData');
                assistantMessageNode?.setValue((content as AIMessage).content as string, 'content');
                jsmChatQuery.setValue(ASSISTANT_MESSAGE_STATUS.TYPING_RESPONSE, 'aiAnswerStatus');
                break;

            case 'FINAL_RESPONSE': {
                const aiMetaData = content as AIMessage;
                metaData.status = ASSISTANT_MESSAGE_STATUS.IDLE;
                metaData.messageId = aiMetaData.id.toString();
                const isFallbackMessage = !!aiMetaData.message_metadata?.isFallbackMessage;
                metaData.showFeedback = !isFallbackMessage;
                metaData.isFallbackMessage = isFallbackMessage;

                assistantMessageNode?.setValue(JSON.stringify(metaData), 'message_metaData');
                assistantMessageNode?.setValue(JSON.stringify(aiMetaData), 'aiMateConversationResponse');
                jsmChatQuery.setValue(ASSISTANT_MESSAGE_STATUS.IDLE, 'aiAnswerStatus');

                if (isFallbackMessage) {
                    node?.setValue('Init', 'authorType');
                    jsmChatQuery.setValue(HelpSeekerMessageStatus.IDLE, 'aiAnswerStatus');
                }
                break;
            }
            case 'PLUGIN_INVOCATION':
                assistantMessageNode?.setValue(
                    JSON.stringify((content as AIMessage).plugin_invocations),
                    'pluginInvocation'
                );
                break;
            case 'ERROR':
                node?.setValue('Assistant_Error', 'authorType');
                jsmChatQuery.setValue(ASSISTANT_MESSAGE_STATUS.IDLE, 'aiAnswerStatus');
                node?.setValue(null, 'content');
                break;
            default:
                break;
        }
    });
};
