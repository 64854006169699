import { di } from 'react-magnetic-di';
import { useActions as useAIAnswersActions } from '../../../store/converse';
import { useProvider } from '../../../store/routing';

export const useStopGenerating = () => {
    di(useProvider, useAIAnswersActions);
    const provider = useProvider();
    const { abortConversation } = useAIAnswersActions();

    const stopGenerating = () => {
        if (provider === 'AI_ANSWERS') {
            abortConversation();
        }
    };

    return { stopGenerating };
};
