import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchHelpCenterBrandingAction } from 'epics/model/help-center';
import { fetchOrganisationsModelAction } from 'epics/model/organisations';
import { fetchUserModelAction } from 'epics/model/user';
import { fetchUserRequestsCountsAction } from 'epics/model/user-requests-counts';
import { getHelpCenterBrandingLoader } from 'state/selectors/help-center';
import { isUserLoaded } from 'state/selectors/user';
import { PrefetchBrandingModel } from './prefetch-branding-model';

const selector = createSelector(getHelpCenterBrandingLoader, isUserLoaded, (isBrandingLoaded, userLoaded) => ({
    isBrandingLoaded,
    isUserLoaded: userLoaded,
}));

export default connect(selector, {
    fetchBranding: fetchHelpCenterBrandingAction,
    fetchUser: fetchUserModelAction,
    fetchOrganisations: fetchOrganisationsModelAction,
    fetchUserRequestsCounts: fetchUserRequestsCountsAction,
})(PrefetchBrandingModel);
