import React from 'react';
import { styled } from '@compiled/react';
import { di } from 'react-magnetic-di';
import { HEADER_HEIGHT } from 'view/common/styled';
import { ActionBarStateless } from 'view/layout-next/action-bar-stateless';
import { bp } from 'view/styles/compiled/breakpoints';
import { sizes } from 'view/styles/sizes-viewport';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LoadingSkeleton } from '@atlassian/help-center-common-component/loading';

const SECTION_HEIGHT = 232;

export interface FlexibleHomepageLoaderProps {
    showActionBar?: boolean;
    removeHeader?: boolean;
    sectionCount?: number;
}

const FlexibleHomepageLoader: React.FC<FlexibleHomepageLoaderProps> = ({
    showActionBar,
    removeHeader,
    sectionCount = 5,
}) => {
    di(UFOLoadHold);
    return (
        <UFOLoadHold name="flexible-homepage-loader">
            <Box xcss={skeletonContainer}>
                {showActionBar && <ActionBarStateless previewButtonDisabled publishButtonDisabled />}
                {!removeHeader && (
                    <Box xcss={headerContainer}>
                        <LoadingSkeleton
                            data-testid="flexible-hc-header-skeleton"
                            height={HEADER_HEIGHT}
                            backgroundColor={token('color.skeleton.subtle', colors.N20)}
                        />
                    </Box>
                )}
                {Array(sectionCount)
                    .fill(1)
                    .map((_, index) => {
                        return (
                            <SectionLoader
                                key={`flexibleHCLoader_${index}`}
                                data-testid="flexible-hc-section-skeleton"
                                height={SECTION_HEIGHT}
                                backgroundColor={token('color.skeleton.subtle', colors.N20)}
                            />
                        );
                    })}
            </Box>
        </UFOLoadHold>
    );
};

const skeletonContainer = xcss({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const headerContainer = xcss({
    width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SectionLoader = styled(LoadingSkeleton)({
    width: '100%',
    marginTop: token('space.300', '24px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromSmall]: {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        maxWidth: `${sizes.large}px`,
        marginRight: token('space.300', '24px'),
        marginLeft: token('space.300', '24px'),
    },
    borderRadius: token('border.radius.100', '4px'),
});

export default FlexibleHomepageLoader;
