/**
 * @generated SignedSource<<0d0a8408e55422a3f7e48682aa417692>>
 * @relayHash bc210f0fbe988c79fde091a561d08224
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 73e7bc2ccf5a80141d9d3113455fc6fcace13f1d53f66dc1b16481d93dc29392

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectsHelpCenterMappingStatus = "ALL" | "LINKED" | "UNLINKED" | "%future added value";
export type manageProjects_GetProjectsQuery$variables = {
  cloudId: string;
  helpCenterARI: string;
  helpCenterId: string;
  helpCenterMappingStatus: JiraProjectsHelpCenterMappingStatus;
};
export type manageProjects_GetProjectsQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly slug?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"settingsBreadcrumbsFragment" | "syncProjectsToggleFragment">;
    } | null | undefined;
  } | null | undefined;
  readonly jira: {
    readonly jiraProjectsMappedToHelpCenter: {
      readonly " $fragmentSpreads": FragmentRefs<"projectsListFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type manageProjects_GetProjectsQuery = {
  response: manageProjects_GetProjectsQuery$data;
  variables: manageProjects_GetProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterARI"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterMappingStatus"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = [
  (v4/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "helpCenterARI",
        "variableName": "helpCenterARI"
      },
      {
        "kind": "Variable",
        "name": "helpCenterId",
        "variableName": "helpCenterId"
      },
      {
        "kind": "Variable",
        "name": "helpCenterMappingStatus",
        "variableName": "helpCenterMappingStatus"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v6 = [
  (v4/*: any*/)
],
v7 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterARI"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "slug"
},
v9 = {
  "kind": "ScalarField",
  "name": "name"
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "manageProjects_GetProjectsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "projectsListFragment"
              }
            ]
          }
        ]
      },
      {
        "args": (v6/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "syncProjectsToggleFragment"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "FragmentSpread",
                "name": "settingsBreadcrumbsFragment"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "manageProjects_GetProjectsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraProjectConnection",
            "kind": "LinkedField",
            "name": "jiraProjectsMappedToHelpCenter",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "projectId"
                      },
                      {
                        "concreteType": "JiraAvatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "small"
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "lead",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          (v11/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "webUrl"
                      },
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "args": (v6/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterProjectMappingData",
                    "kind": "LinkedField",
                    "name": "projectMappingData",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "syncNewProjects"
                      }
                    ]
                  },
                  (v8/*: any*/),
                  {
                    "concreteType": "HelpCenterName",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "default"
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v11/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "73e7bc2ccf5a80141d9d3113455fc6fcace13f1d53f66dc1b16481d93dc29392",
    "metadata": {},
    "name": "manageProjects_GetProjectsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c194eb4ffd8c3c2e4203bc59d85fc9eb";

export default node;
