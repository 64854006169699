import type { Observer } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { Action, Location } from 'history';
import { createBrowserHistory } from 'react-resource-router';
import * as AJS from '@atlassian/help-center-common-util/ajs';
import { getEnv } from '@atlassian/help-center-common-util/env';

export const contextPath = AJS.getMeta('context-path') || '';

export const getRelativeBasePath = () => {
    if (getEnv().hasOwnProperty('relativeBaseUrl')) {
        return getEnv().relativeBaseUrl;
    }
    return '/servicedesk/customer';
};

export const getBasePath = () => {
    if (getEnv().hasOwnProperty('baseUrl')) {
        return getEnv().baseUrl;
    }
    return `${contextPath}${getRelativeBasePath()}`;
};

export const replaceBasicRouteWithAdvancedRoute = (url: string) => {
    return url.replace('/servicedesk/customer', getEnv().relativeBaseUrl);
};

export const replaceAdvancedRouteWithBasicRoute = (url: string) => {
    return url.replace(getEnv().relativeBaseUrl, '/servicedesk/customer');
};

export const getBaseName = () => `${contextPath}${getRelativeBasePath()}`;

const history = createBrowserHistory({
    basename: getBaseName(),
});

export interface HistoryData {
    location: Location;
    action: Action;
}

// Suppressing existing violation. Please fix this.
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const historyObservable: Observable<HistoryData> = Observable.create((observer: Observer<HistoryData>) => {
    history.listen((location, action) => observer.next({ location, action }));
});

export default history;
