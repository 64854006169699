import { di } from 'react-magnetic-di';
import { useIsChannelErrorPresent } from '../../../store/converse';
import { useProvider } from '../../../store/routing';

export const useIsInputShown = () => {
    di(useProvider, useIsChannelErrorPresent);
    const provider = useProvider();
    const [isChannelErrorPresent] = useIsChannelErrorPresent();

    if (provider === 'AI_ANSWERS') {
        return !isChannelErrorPresent;
    }

    return true;
};
