import { styled } from '@compiled/react';
import { bp } from 'view/styles/compiled/breakpoints';
import * as grid from 'view/styles/grid';
import { token } from '@atlaskit/tokens';
import { FOOTER_HEIGHT } from '../layout/footer/styled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HEADER_HEIGHT = `${grid.multiple(10).px}`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const WRAPPER_MIN_HEIGHT = `calc(100vh - ${FOOTER_HEIGHT}px - ${HEADER_HEIGHT})`;
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Wrapper = styled.div({
    minHeight: WRAPPER_MIN_HEIGHT,
    padding: `${token('space.1000', '80px')} ${token('space.500', '40px')} 0 ${token('space.500', '40px')}`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.only.onlyMobile]: {
        marginTop: token('space.500', '40px'),
    },
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ExperienceContainer = styled.div({
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    marginTop: token('space.300', '24px'),
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    maxWidth: grid.multiple(42).px,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.between.betweenMediumAndLarge]: {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        maxWidth: grid.multiple(90).px,
    },
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    [bp.from.fromLarge]: {
        // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        maxWidth: grid.multiple(145).px,
    },
});

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const CardsContainer = styled.ul({
    //TODO: Migrate margin to tokens https://bitbucket.org/jira-service-management/jsd-portal-frontend/pull-requests/4034
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    margin: `0 ${grid.multiple(-5).px} ${token('space.200', '16px')}`,
    display: 'flex',
    flexWrap: 'wrap',
    border: '1px transparent',
    position: 'relative',
    justifyContent: 'center',
    listStyle: 'none',
    padding: 0,
});
