import { reportError, isSentryEnabled } from '../sentry/sentry';
export const sentryQueue = {
    pushError(err: Error) {
        if (typeof window !== undefined && isSentryEnabled()) {
            if (!window.JSM_EXCEPTION_QUEUE) {
                window.JSM_EXCEPTION_QUEUE = [err];
            } else {
                window.JSM_EXCEPTION_QUEUE.push(err);
            }
        }
    },
    flushErrors() {
        if (typeof window !== undefined) {
            window.JSM_EXCEPTION_QUEUE?.forEach((err) => reportError(err));
            delete window.JSM_EXCEPTION_QUEUE;
        }
    },
};
