// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { IconCardLayout } from 'view/layout/icon-card-layout';
import * as fonts from 'view/styles/fonts';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';

export type ContainerPadding = 'tight' | 'default';

interface StyledIconCardLayoutProps {
    containerPadding?: ContainerPadding;
}

interface StyledLinkProps {
    selected: boolean;
}

const GROUP_BOX_PADDING_LEFT_RIGHT_TIGHT = token('space.200', '16px');
export const GROUP_BOX_PADDING_LEFT_RIGHT = token('space.300', '24px');
export const GROUP_BOX_PADDING_TOP_BOTTOM = token('space.300', '24px');

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledIconCardLayout = styled(IconCardLayout)<StyledIconCardLayoutProps>`
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    padding: ${GROUP_BOX_PADDING_TOP_BOTTOM}
        ${(props) =>
            props.containerPadding === 'tight' ? GROUP_BOX_PADDING_LEFT_RIGHT_TIGHT : GROUP_BOX_PADDING_LEFT_RIGHT};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Heading = styled.div<{ lineClamp: boolean }>`
    ${fonts.h400};
    ${(props) => mixins.applyIf(props.lineClamp, mixins.clamp)};
    color: ${(props) => token('color.text.brand', props.theme.color)};
    margin: 0;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const HeadingContent = styled.div<{ lineClamp: boolean }>`
    ${(props) => mixins.applyIf(props.lineClamp, mixins.clamp)};
    color: ${(props) => token('color.text.brand', props.theme.color)};
    margin: 0;
`;
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Description = styled.div<{ lineClamp: number }>`
    ${(props) =>
        mixins.applyIf(
            props.lineClamp > 0,
            /*
                We use multiline clamp so we avoid descriptions that have multi paragraphs looking like this:
                Clamped...
                Clamped...
                Clamped...
            */
            mixins.multiLineClamp(props.lineClamp)
        )};
    color: ${colors.text};
    margin-top: ${token('space.050', '4px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledLink = styled(Link)<StyledLinkProps>`
    ${mixins.borderRadius};
    display: block;
    background-color: ${(props) => (props.selected ? token('elevation.surface.raised', colors.N20) : 'inherit')};

    &:hover,
    /* This is embracing the focus-visible polyfill we're using */
    /* See: https://github.com/WICG/focus-visible */
    &.focus-visible {
        text-decoration: none;
        background-color: ${token('elevation.surface.raised.hovered', colors.N20)};
    }

    &:focus {
        text-decoration: none;
    }
`;
