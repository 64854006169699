import { defineMessages } from 'react-intl-next';

export default defineMessages({
    permissionDeniedErrorTitle: {
        defaultMessage: "You don't have permission to remove this request participant",
        description: 'Permission Denied while removing participants title',
        id: 'sd.remove.participants.permission.denied.title',
    },
    permissionDeniedErrorDescription: {
        defaultMessage: 'Ask your admin to remove this request participant.',
        description: 'Permission Denied while removing participants description',
        id: 'sd.remove.participants.permission.denied.description',
    },
});
