// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import type { CSSObject, SimpleInterpolation } from 'styled-components';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { css } from 'styled-components';
import { sizes } from './sizes-viewport';

const generateBreakpoint = (minSize: number, maxSize?: number) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emMinSize = minSize / 16;
    const emMaxSize = maxSize && maxSize / 16;
    let mediaQuery: string;

    if (emMinSize && emMaxSize) {
        mediaQuery = `(min-width: ${emMinSize}em) and (max-width: ${emMaxSize})`;
    } else if (emMinSize) {
        mediaQuery = `(min-width: ${emMinSize}em)`;
    } else if (emMaxSize) {
        mediaQuery = `(max-width: ${emMaxSize}em)`;
    }

    const func: typeof css = (strings: TemplateStringsArray | CSSObject, ...interpolations: SimpleInterpolation[]) =>
        // eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        css`
            @media ${mediaQuery} {
                ${css(
                    // @ts-expect-error -- css overloads doesn't like this, but this is the exact root type we allow, confirmed to work via Storybook
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    strings,
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    ...interpolations
                )};
            }
        `;

    return {
        // So we get syntax highlighting easily.
        css: func,
    };
};

export const fromCustom = (minSize: number, maxSize?: number) => generateBreakpoint(minSize, maxSize);

export const onlyMobile = generateBreakpoint(0, sizes.xxsmall);

export const onlyXXSmall = generateBreakpoint(0, sizes.xxsmall);
export const fromXXSmall = generateBreakpoint(sizes.xxsmall);

export const fromXSmall = generateBreakpoint(sizes.xsmall);

export const fromSmall = generateBreakpoint(sizes.small);

export const fromMedium = generateBreakpoint(sizes.medium);

export const fromLarge = generateBreakpoint(sizes.large);

export const fromXLarge = generateBreakpoint(sizes.xlarge);

export const fromXXLarge = generateBreakpoint(sizes.xxlarge);
