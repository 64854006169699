import * as React from 'react';
import type { FormattedMessage, InjectedIntlProps } from 'react-intl';
import type { showFeedbackCollector as showFeedbackCollectorType } from 'state/actions/feedback-collector';
import type { FeedbackCollectorPayload } from 'state/actions/feedback-collector/feedback-collector';
import type { dismissFlag as dismissFlagType } from 'state/actions/flags';
import type { Flag } from 'state/ui/flags';
import type { Appearance, ButtonProps } from '@atlaskit/button';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import { ButtonItem } from '@atlaskit/side-navigation';
import { Button } from '@atlassian/help-center-common-component/analytics';
import { ThemedButton } from '@atlassian/help-center-common-component/themed-button';
import type { EnvType } from '@atlassian/help-center-common-util/env';

interface FeedbackCollectorButtonProps {
    env: EnvType;
    flags: Flag[];
    showIcon: boolean;
    featureName: string;
    showTypeField: boolean;
    buttonLabel: FormattedMessage.MessageDescriptor;
    analyticsSubjectId: string;
    dismissFlagMessage?: FormattedMessage.MessageDescriptor;
    appearance?: Appearance;
    dismissFlag: typeof dismissFlagType;
    showFeedbackCollector: typeof showFeedbackCollectorType;
    spacing?: ButtonProps['spacing'];
    useTheme?: boolean;
    useSideNavButton?: boolean;
}

export const FeedbackCollectorButton: React.FC<FeedbackCollectorButtonProps & InjectedIntlProps> = ({
    intl,
    env,
    flags,
    showIcon,
    featureName,
    showTypeField,
    buttonLabel,
    analyticsSubjectId,
    dismissFlagMessage,
    dismissFlag,
    showFeedbackCollector,
    appearance = 'subtle',
    spacing = 'default',
    useTheme = false,
    useSideNavButton = false,
}) => {
    const openFeedbackCollector = React.useCallback(() => {
        const showFeedbackCollectorPayload: FeedbackCollectorPayload = {
            env,
            showTypeField,
            featureName,
            shouldOpenFeedbackWidget: true,
        };

        if (dismissFlagMessage) {
            const successFlag = flags.filter(
                // @ts-ignore TS(2532) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Object is possibly 'undefined'.
                (flag) => flag.titleMessage.defaultMessage.localeCompare(dismissFlagMessage.defaultMessage) === 0
            )[0];

            successFlag && dismissFlag(successFlag.id);
        }

        showFeedbackCollector(showFeedbackCollectorPayload);
    }, [env, showTypeField, featureName, dismissFlagMessage, flags, dismissFlag, showFeedbackCollector]);

    if (useSideNavButton) {
        return (
            <ButtonItem iconBefore={showIcon ? <FeedbackIcon label="" /> : undefined} onClick={openFeedbackCollector}>
                {intl.formatMessage(buttonLabel)}
            </ButtonItem>
        );
    }
    const ButtonComponent = useTheme ? ThemedButton : Button;
    return (
        <ButtonComponent
            iconBefore={showIcon ? <FeedbackIcon label="feedback" /> : undefined}
            appearance={appearance}
            onClick={openFeedbackCollector}
            actionSubjectId={analyticsSubjectId}
            spacing={spacing}
        >
            {intl.formatMessage(buttonLabel)}
        </ButtonComponent>
    );
};
