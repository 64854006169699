/**
 * @generated SignedSource<<ff437f32ed0ac99bf8de54bee8451139>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type articleMetadataFragment$data = {
  readonly containerDisplayName: string | null | undefined;
  readonly displayLink: string;
  readonly id: string;
  readonly isExternal: boolean;
  readonly " $fragmentType": "articleMetadataFragment";
};
export type articleMetadataFragment$key = {
  readonly " $data"?: articleMetadataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleMetadataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "articleMetadataFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "displayLink"
      },
      "action": "THROW",
      "path": "displayLink"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isExternal"
      },
      "action": "THROW",
      "path": "isExternal"
    },
    {
      "kind": "ScalarField",
      "name": "containerDisplayName"
    }
  ],
  "type": "HelpObjectStoreSearchResult"
};

(node as any).hash = "243125c226275a9fa5bff8db86192050";

export default node;
