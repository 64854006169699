import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterNameTextLabel: {
        defaultMessage: 'Help center name',
        description: 'Help center name text field label',
        id: 'help.center.name.text.field.label.title',
    },
    helpCenterNameTextFieldPlaceholder: {
        defaultMessage: 'Enter a help center name',
        description: 'Help center name text field placeholder',
        id: 'help.center.name.text.field.placeholder.title',
    },
    helpCenterSlugTextLabel: {
        defaultMessage: 'Help center URL',
        description: 'Help center slug text field label',
        id: 'help.center.slug.text.field.label.title',
    },
    helpCenterSlugInfoTooltip: {
        defaultMessage: 'Share your new help center URL with your team members and customers',
        description: 'Help center slug text change warning tooltip',
        id: 'help.center.slug.text.field.info.tooltip',
    },
    helpCenterSlugInfoLabel: {
        defaultMessage: 'Slug change info',
        description: 'Help center slug change info',
        id: 'help.center.slug.text.field.change.info.label',
    },
    helpCenterSlugTextFieldPlaceholder: {
        defaultMessage: 'Enter a slug',
        description: 'Help center slug text field placeholder',
        id: 'help.center.name.slug.field.placeholder.title',
    },
});
