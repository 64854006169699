import { defineMessages } from 'react-intl-next';

export default defineMessages({
    errorTitle: {
        defaultMessage: "We're on it",
        description: 'Error title to show on the generic error page.',
        id: 'cv.portal.generic.error.page.error.title',
    },

    errorMessage: {
        defaultMessage:
            'An unexpected error has occured when processing your request. Our team is working to get things back up and running.',
        description: 'Error message to show on the generic error page.',
        id: 'cv.portal.generic.error.page.error.message',
    },
});
