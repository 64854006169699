export const removeHyperlinks = (message: string) => {
    const parser = new DOMParser();
    const parsedMessage = parser.parseFromString(message, 'text/html');
    parsedMessage.querySelectorAll('a').forEach((a) => {
        // Removing href attribute from anchor tag and setting tabindex to -1 to disable keyboard navigation
        // eslint-disable-next-line no-script-url
        a.setAttribute('href', 'javascript:void(0)');
        a.setAttribute('tabindex', '-1');
        a.setAttribute('draggable', 'false');
        a.setAttribute('style', 'text-decoration: none; color: inherit; cursor: inherit;');
    });
    return parsedMessage.body.innerHTML;
};
