import React from 'react';
import { isMoveToManagementRouterEnabled } from 'feature-flags';
import { lazyForPaint } from 'react-loosely-lazy';
import type { Route } from 'react-resource-router';
import { withRouter } from 'react-resource-router';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { JiraAdminPermissionWrapper } from '@atlassian/help-center-common-util/routes';

const AsyncHelpCenterEditDetailsPageOld = withAsync({
    loader: lazyForPaint(() =>
        import(/* webpackChunkName: "async-help-center-edit-details-page" */ '@atlassian/admin-layout').then(
            ({ HelpCenterEditDetails }) => HelpCenterEditDetails
        )
    ),
    LoadingSkeleton: () => null,
});

const AsyncHelpCenterManageProjectsPageOld = withAsync({
    loader: lazyForPaint(() =>
        import(/* webpackChunkName: "async-help-center-manage-projects-page" */ '@atlassian/admin-layout').then(
            ({ HelpCenterManageProjects }) => HelpCenterManageProjects
        )
    ),
    LoadingSkeleton: () => null,
});

const AsyncHelpCenterEditDetailsPage = withAsync({
    loader: lazyForPaint(() =>
        import(
            /* webpackChunkName: "async-help-center-edit-details-page" */ '@atlassian/admin-layout/src/ui/edit-helpcenter/index'
        ).then(({ HelpCenterEditDetails }) => HelpCenterEditDetails)
    ),
    LoadingSkeleton: () => null,
});

const AsyncHelpCenterManageProjectsPage = withAsync({
    loader: lazyForPaint(() =>
        import(
            /* webpackChunkName: "async-help-center-manage-projects-page" */ '@atlassian/admin-layout/src/ui/manage-projects/index'
        ).then(({ HelpCenterManageProjects }) => HelpCenterManageProjects)
    ),
    LoadingSkeleton: () => null,
});

export const helpcenterEditRoute: Route = {
    name: 'edit-details',
    path: isMoveToManagementRouterEnabled() ? '/:helpcenterId/details' : '/edit-details',
    exact: true,
    component: withRouter(() => (
        <JiraAdminPermissionWrapper>
            {isMoveToManagementRouterEnabled() ? (
                <AsyncHelpCenterEditDetailsPage />
            ) : (
                <AsyncHelpCenterEditDetailsPageOld />
            )}
        </JiraAdminPermissionWrapper>
    )),
};

export const helpcenterManageProjectsRoute: Route = {
    name: 'manage-projects',
    path: isMoveToManagementRouterEnabled() ? '/:helpcenterId/projects' : '/manage-projects',
    exact: true,
    component: withRouter(() => (
        <JiraAdminPermissionWrapper>
            {isMoveToManagementRouterEnabled() ? (
                <AsyncHelpCenterManageProjectsPage />
            ) : (
                <AsyncHelpCenterManageProjectsPageOld />
            )}
        </JiraAdminPermissionWrapper>
    )),
};
