/**
 * Utility to convert a subset of the unix date time format to data-fns.
 * Date-fns format: https://date-fns.org/v2.30.0/docs/format
 * Unix date time format: http://old-www.cs.dartmouth.edu/~cs50/data/tse/wikipedia/wiki/Date_(Unix).html#Formatting
 */

/**
 * This is a mapping of all equivalent time formats between unix and date-fns.
 *
 * There are a number of commented out unix time formats, these are the unix time formats that we don't support. There are no
 * direct equivalent for any of these between unix time format and date fns. I have left the closest matching date fns time format
 * for each of these keys if there are any, or I marked it as INCOMPATIBLE if there are no date fns that even remotely matches the
 * unix time format.
 */
const UNIX_TO_DATE_FNS_MAPPING = {
    '%a': 'iii',
    '%A': 'iiii',
    '%d': 'dd',
    '%e': 'd',
    '%j': 'DDD',
    '%u': 'i',
    // '%w': 'd',
    // '%U': 'WW',
    // '%W': 'WW',
    '%V': 'II',
    '%m': 'MM',
    '%h': 'MMM',
    '%b': 'MMM',
    '%B': 'MMMM',
    '%y': 'yy',
    '%Y': 'yyyy',
    // '%g': 'YY',
    // '%G': 'YYYY',
    // '%C': 'INCOMPATIBLE',
    '%D': 'MM/dd/yy',
    // '%x': "INCOMPATIBLE",
    '%F': 'yyyy-MM-dd',
    '%l': 'h',
    '%I': 'hh',
    '%k': 'H',
    '%H': 'HH',
    '%p': 'a',
    '%P': 'aaaa',
    '%M': 'mm',
    '%s': 't',
    '%S': 'ss',
    // '%N': 'INCOMPATIBLE',
    '%r': 'hh:mm:ss',
    '%R': 'HH:mm',
    '%T': 'HH:mm:ss',
    // '%X': 'INCOMPATIBLE',
    // '%c': 'INCOMPATIBLE',
    '%z': 'xx',
    // '%Z': 'INCOMPATIBLE',
};

/**
 * These are the date-fns characters that are associated with 'time', time in this case refers to hour, minute, second and timezone.
 * This is used by splitDateTimeFormat to determine which sections of a date format is associated with time and which are associated  * with day.
 * This list IS case sensitive as capital M is month and not minute, see https://date-fns.org/v2.30.0/docs/format for full list.
 */
const LIST_OF_TIME_CHARS = [
    'h', // Hour
    'H', // Hour
    'm', // Minute (NOTE: capital M is month not minute)
    's', // Second
    'S', // Part of a second
    'a', // AM PM
    'xxx', // Timezone
    't', // Timestamp
    'T', // Timestamp
];

// eslint-disable-next-line no-useless-escape
const unixTimeFormatTokenRegex = /\%\w/gi;

const escapeRegExp = (literalString: string) => literalString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string;

/**
 * Converts a unix date time format to its closest equivalent in date-fns format
 * @param unixDateTimeFormat The unix date time format you want to convert
 */
function convertToDateFnsFormat(unixDateTimeFormat: string) {
    let transformedString = unixDateTimeFormat;
    const incompatibleFormatString: string[] = [];

    const matches = unixDateTimeFormat.match(unixTimeFormatTokenRegex);

    if (matches) {
        matches.forEach((match) => {
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const equivalentDateFnsFunctionString = UNIX_TO_DATE_FNS_MAPPING[match];
            if (equivalentDateFnsFunctionString) {
                // Replaces all cases of the match with it's equivalent in date-fns time format
                transformedString = transformedString.replace(
                    new RegExp(escapeRegExp(match), 'g'),
                    equivalentDateFnsFunctionString
                );
            } else {
                incompatibleFormatString.push(match);
            }
        });
    }

    return transformedString;
}

/**
 * Naively splits a date fns time format between date and time components. It is split by matching each individual character
 * Naively in this case means that there is a distinct separate between date and time, i.e. they're not mixed in between each other.
 */
function splitDateTimeFormat(dateFnsTimeFormat: string) {
    const matches = dateFnsTimeFormat.match(/\w/g);
    let lastMatchType: 'time' | 'date' | undefined;
    let indexOfMatchTypeChange = dateFnsTimeFormat.length;

    if (matches) {
        for (const match of matches) {
            // We assume that any keys that are not time keys are used to represent date, date in this case refers to years, months, weeks and days
            const isTime = LIST_OF_TIME_CHARS.includes(match);
            const matchType = isTime ? 'time' : 'date';

            if (!lastMatchType) {
                lastMatchType = matchType;
            } else if (matchType !== lastMatchType) {
                // If the match type has changed, i.e. from date -> time or from time -> date we will stop and use this match to
                // split the date time to date and time.
                indexOfMatchTypeChange = dateFnsTimeFormat.indexOf(match);
                break;
            }
        }

        if (lastMatchType === 'date') {
            // If we went from date -> time
            return {
                dateFormat: dateFnsTimeFormat.slice(0, indexOfMatchTypeChange).trim(),
                timeFormat: dateFnsTimeFormat.slice(indexOfMatchTypeChange).trim(),
            };
        }

        // splitDateTimeFormat went from time -> date
        return {
            timeFormat: dateFnsTimeFormat.slice(0, indexOfMatchTypeChange).trim(),
            dateFormat: dateFnsTimeFormat.slice(indexOfMatchTypeChange).trim(),
        };
    }

    return {
        timeFormat: 'hh:mm a',
        dateFormat: 'yyyy-MM-dd',
    };
}

export { convertToDateFnsFormat, splitDateTimeFormat };
