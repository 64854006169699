import type { FetchForgotPasswordAction } from 'epics/model/forgot-password';
import { FETCH_FORGOT_PASSWORD_MODEL } from 'epics/model/forgot-password/actions';

import type {
    ForgotPasswordResponse,
    FetchForgotPasswordModelSuccess,
    FetchForgotPasswordModelFailure,
} from 'state/actions/forgot-password';
import { FETCH_FORGOT_PASSWORD_SUCCESS, FETCH_FORGOT_PASSWORD_FAILURE } from 'state/actions/forgot-password';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface ForgotPassword {
    forgotPassword?: ForgotPasswordResponse;
}

export type ForgotPasswordState = ForgotPassword | PersistedError | undefined;

export const getInitialForgotPasswordState = (): ForgotPasswordState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.forgotPassword) {
        return {
            forgotPassword: initialModelState.forgotPassword,
        };
    }
    return {};
};

const defaultState: ForgotPasswordState = getInitialForgotPasswordState();

export type HandledActions =
    | FetchForgotPasswordAction
    | FetchForgotPasswordModelSuccess
    | FetchForgotPasswordModelFailure;

export function forgotPasswordReducer(
    state: ForgotPasswordState = defaultState,
    action: HandledActions
): ForgotPasswordState {
    switch (action.type) {
        case FETCH_FORGOT_PASSWORD_MODEL: {
            if (state && 'error' in state) {
                return {};
            }
            return state;
        }

        // We want to override the portal data if request create fails as well.
        case FETCH_FORGOT_PASSWORD_FAILURE: {
            const fetchForgotPasswordFailureAction = action as FetchForgotPasswordModelFailure;
            const { error } = fetchForgotPasswordFailureAction.payload;
            const errorObject = {
                // We really only care about the first error message
                // grab it and throw the rest away
                status: error.status,
                message: (error.errorMessages || [])[0],
                // Remove basename from the URL, we don't need it as the basename
                // is already set inside react router.
                // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            };

            return {
                error: errorObject,
            };
        }

        case FETCH_FORGOT_PASSWORD_SUCCESS: {
            const fetchPForgotPasswordSuccessAction = action as FetchForgotPasswordModelSuccess;
            const response = fetchPForgotPasswordSuccessAction.payload;

            return {
                forgotPassword: {
                    ...response,
                },
            };
        }

        default:
            return state;
    }
}
