import * as React from 'react';
import { once } from 'lodash';
import type { ResourceDescriptor } from 'rest/wrm-require';
import { fetchWrmResourceDescriptors } from 'rest/wrm-require';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';

interface Props {
    onResourcesLoaded: () => void;
    resourceKeys: string[];
}

type WrmResource = HTMLScriptElement | HTMLLinkElement;

const createScriptResource = (url: string): HTMLScriptElement =>
    Object.assign(document.createElement('script'), {
        type: 'text/javascript',
        charset: 'utf-8',
        async: true,
        src: url,
    });

const createCssResource = (url: string): HTMLLinkElement =>
    Object.assign(document.createElement('link'), {
        rel: 'stylesheet',
        type: 'text/css',
        href: url,
    });

const createResource = (resourceDescriptor: ResourceDescriptor): WrmResource => {
    const { resourceType, url } = resourceDescriptor;
    if (resourceType === 'CSS') {
        return createCssResource(url);
    }
    return createScriptResource(url);
};

const loadResources = once((wrmResources: WrmResource[], unparsedData: object): Promise<void[]> => {
    window.WRM = {
        _unparsedData: { ...unparsedData },
    };
    const resources = wrmResources.map(
        (resource) =>
            new Promise<void>((resolve) => {
                resource.onload = () => {
                    resolve();
                };
                resource.onerror = (error: ErrorEvent) => {
                    trackError(`deferred.wrm.resource.load.failed`, {}, error.message);
                    operationalEvent({
                        action: 'failed',
                        actionSubject: 'loadWrmResources',
                        source: 'unknownSource',
                        attributes: {
                            errorMessage: error.message,
                        },
                    });
                };
                document.head.appendChild(resource);
            })
    );

    return Promise.all(resources);
});

const WrmModuleLoader = ({ onResourcesLoaded, resourceKeys }: Props) => {
    const asyncWrmRequire = React.useCallback(async () => {
        try {
            const { unparsedData, resources: resourceDescriptors } = await fetchWrmResourceDescriptors(resourceKeys);
            const wrmResources = resourceDescriptors.map((resourceDescriptor) => createResource(resourceDescriptor));
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            loadResources(wrmResources, unparsedData).then(() => {
                onResourcesLoaded();
            });
        } catch (e) {
            trackError('fetchWrmResourceDescriptors.fetch.failed', {}, e);
            operationalEvent({
                action: 'failed',
                actionSubject: 'fetchWrmResourceDescriptors',
                source: 'unknownSource',
                attributes: {
                    resourceKeys,

                    // Suppressing existing violation. Please fix this.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    errorMessage: e.message,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchWrmResourceDescriptors]);

    React.useEffect(() => {
        // Disabling existing violations, should be fixed when revisited.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        asyncWrmRequire();
    }, [asyncWrmRequire]);

    return null;
};

export default WrmModuleLoader;
