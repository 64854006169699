import { getJsmAIConfigEnabled } from '@helpCenter/util/env';
import { getMeta } from '@helpCenter/util/meta';
import { isMoveToManagementRouterEnabled, isVAOnHelpCenterGAEnabled } from 'feature-flags';
import type { RouterContext } from 'react-resource-router';
import { HelpHelpCenterAri } from '@atlassian/ari';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import type { srcHelpCenterVirtualAgentSettingsQuery } from './__generated__/srcHelpCenterVirtualAgentSettingsQuery.graphql';
import HelpCenterVirtualAgentSettingsQuery from './__generated__/srcHelpCenterVirtualAgentSettingsQuery.graphql';

export const HelpCenterVirtualAgentResource = createRelayResource<srcHelpCenterVirtualAgentSettingsQuery>({
    type: 'HELP_REPORTING_PAGE',
    getQuery: ({ match }: RouterContext, { cloudId, helpCenterAri }: { cloudId: string; helpCenterAri: string }) => {
        let computedHelpCenterAri = helpCenterAri;

        if (isMoveToManagementRouterEnabled()) {
            const activationId = getMeta('activation-id') || '';
            const helpCenterId = match.params.helpcenterId || '';
            computedHelpCenterAri = HelpHelpCenterAri.create({ activationId, helpCenterId }).toString();
        }
        return {
            parameters: HelpCenterVirtualAgentSettingsQuery,
            variables: {
                cloudId,
                helpCenterAri: computedHelpCenterAri,
                isJsmAIConfigEnabled: getJsmAIConfigEnabled(),
                isVAOnHelpCenterGAEnabled: isVAOnHelpCenterGAEnabled(),
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
