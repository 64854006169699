import * as React from 'react';
import { AnalyticsContext, ScreenTypes } from '@atlassian/analytics-web-react';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { startPageLoad } from '@atlassian/help-center-common-util/analytics/browser-metrics-v3';
import type { InjectedProps } from '@atlassian/help-center-common-util/analytics/browsermetrics-screen-transition-status-context';
import { BrowserMetricsScreenTransitionStatusContext } from '@atlassian/help-center-common-util/analytics/browsermetrics-screen-transition-status-context';
import { ScreenNameProvider } from '@atlassian/help-center-common-util/analytics/screen-name-context';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';

interface PortalScreenWithBrowserMetricsProps extends AnalyticsScreenProps {
    /** Injected from react context. Determines whether or not we should restart the browsermetrics timer */
    preventBrowserMetricsTimerResetOnScreenMount: boolean;
    /** Injected from react context. Called when we are no longer measuring a transition between two screens */
    stopBrowserMetricsTransitionToNewScreen: () => void;
}

interface PortalScreenWithBrowserMetricsState {
    screenWasHidden: boolean;
}

class PortalScreenWithBrowserMetrics extends React.Component<
    PortalScreenWithBrowserMetricsProps,
    PortalScreenWithBrowserMetricsState
> {
    state = {
        screenWasHidden: false,
    };

    constructor(props: PortalScreenWithBrowserMetricsProps) {
        super(props);
        this.startTimer(props.screenName);
    }

    // this must be called before post-mount and post-update lifecycle methods
    // to ensure it is called before `endTimer` is called.
    UNSAFE_componentWillReceiveProps(nextProps: PortalScreenWithBrowserMetricsProps) {
        const { screenName, screenId } = this.props;

        if (nextProps.screenName !== screenName || nextProps.screenId !== screenId) {
            this.setState({ screenWasHidden: false });
            this.startTimer(nextProps.screenName);
        }
    }

    startTimer = (screenName: ScreenName) => {
        const { preventBrowserMetricsTimerResetOnScreenMount, stopBrowserMetricsTransitionToNewScreen } = this.props;
        if (!preventBrowserMetricsTimerResetOnScreenMount) {
            devLog('Browser metrics: starting timer for screen:', screenName);
            /**
             * Browser metrics v3 start page load
             */
            startPageLoad(screenName);
        } else {
            stopBrowserMetricsTransitionToNewScreen();
        }
    };

    handleVisibilityChange = () => {
        this.setState({
            screenWasHidden: true,
        });
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
    }

    render() {
        const { screenName, children, screenId } = this.props;

        // We need to set the screen name to undefined if the window visibility changed
        // so the browser metrics event never gets fired.
        // If the user changes tabs while the page is loading, the time to load is calculated incorrectly.
        // So we're discarding these events as a workaround. See FSD-3537.
        const screenNameForBrowserMetrics = this.state.screenWasHidden ? undefined : screenName;
        return (
            <ScreenNameProvider params={{ screenId, screenName: screenNameForBrowserMetrics }}>
                <AnalyticsContext sourceName={screenName} sourceType={ScreenTypes.SCREEN}>
                    {children}
                </AnalyticsContext>
            </ScreenNameProvider>
        );
    }
}

export interface AnalyticsScreenProps {
    screenName: ScreenName;
    screenId?: number | string;
    children: React.ReactNode;
}

/**
 * Establishes a new screen for analytics and browsermetrics purposes.
 * Will set analytics context, and start a browsermetrics timer for the screen.
 * @param screenName the name of the screen, fired in the event
 * @param screenId an optional further identifier for cases when a user can navigate between two
 * different screens with the same name, e.g. two request create screens for different request types
 */
const AnalyticsScreen: React.FunctionComponent<AnalyticsScreenProps> = (props) => {
    const { screenName, children, screenId } = props;

    return (
        <BrowserMetricsScreenTransitionStatusContext.Consumer>
            {(value: InjectedProps) => (
                <PortalScreenWithBrowserMetrics
                    screenName={screenName}
                    screenId={screenId}
                    preventBrowserMetricsTimerResetOnScreenMount={value.preventBrowserMetricsTimerResetOnScreenMount}
                    stopBrowserMetricsTransitionToNewScreen={value.stopBrowserMetricsTransitionToNewScreen}
                >
                    {children}
                </PortalScreenWithBrowserMetrics>
            )}
        </BrowserMetricsScreenTransitionStatusContext.Consumer>
    );
};

export default AnalyticsScreen;
