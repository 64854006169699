import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { EditTopicLoader } from './edit-topic-loader';

export const EditTopicPage = withAsync({
    loader: lazyForPaint(() =>
        import(/* webpackChunkName: 'edit-topic' */ './main').then((module) => {
            const { registerHelpObjectEpicsAndReducers } = module;
            registerHelpObjectEpicsAndReducers();
            return module.default;
        })
    ),
    LoadingSkeleton: EditTopicLoader,
});
