import { defineMessages } from 'react-intl-next';

export default defineMessages({
    dismiss: {
        defaultMessage: 'Dismiss',
        description: 'Dismiss error flag message',
        id: 'cv.request.details.approve.by.email.approval.error.dismiss.flag.message',
    },

    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    approvalByEmailErrorFlagTitle400_1: {
        defaultMessage: "We couldn't record your selection",
        description: 'Title for the flag when there is a 400 error, type 1 (misspelling in url)',
        id: 'cv.request.details.approve.by.email.approval.error.title400_1',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    approvalByEmailErrorFlagDescription400_1: {
        defaultMessage: 'Select <b>Approve</b> or <b>Decline</b> on this page.',
        description: 'Description for the flag when there is a 400 error, type 1 (misspelling in url)',
        id: 'cv.request.details.approve.by.email.approval.error.description400_1',
    },

    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    approvalByEmailErrorFlagTitle400_2: {
        defaultMessage: 'You no longer need to approve or decline',
        description: 'Title for the flag when there is a 400 error, type 2 (approval already completed)',
        id: 'cv.request.details.approve.by.email.approval.error.title400_2',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    approvalByEmailErrorFlagDescription400_2: {
        defaultMessage: 'This request has already been approved or declined.',
        description: 'Description for the flag when there is a 400 error, type 2 (approval already completed)',
        id: 'cv.request.details.approve.by.email.approval.error.description400_2',
    },

    approvalByEmailErrorFlagTitle403: {
        defaultMessage: 'You need permission to approve this request',
        description: 'Title for the flag when there is a 403 error',
        id: 'cv.request.details.approve.by.email.approval.error.title401',
    },
    approvalByEmailErrorFlagDescription403: {
        defaultMessage: `Ask your admin if you’re on the approvers list, or check that you’re logged in with the right account.`,
        description: 'Description for the flag when there is a 403 error',
        id: 'cv.request.details.approve.by.email.approval.error.description401',
    },
    approvalByEmailErrorFlagTitle404: {
        defaultMessage: `We couldn’t update the request`,
        description: 'Title for the flag when there is a 404 error',
        id: 'cv.request.details.approve.by.email.approval.error.title404',
    },
    approvalByEmailErrorFlagDescription404: {
        defaultMessage: 'This request no longer needs approval. It may have been updated or deleted.',
        description: 'Description for the flag when there is a 404 error',
        id: 'cv.request.details.approve.by.email.approval.error.description404',
    },
    approvalByEmailErrorFlagTitle409: {
        defaultMessage: 'Approval not required',
        description: 'Title for the flag when there is a 409 error',
        id: 'cv.request.details.approve.by.email.approval.error.title409',
    },
    approvalByEmailErrorFlagDescription409: {
        defaultMessage: 'This request has already been approved or declined.',
        description: 'Description for the flag when there is a 409 error',
        id: 'cv.request.details.approve.by.email.approval.error.description409',
    },

    approvalByEmailErrorFlagTitleDefault: {
        defaultMessage: "We couldn't record your selection",
        description: 'Title for the flag when there is a an error not already accounted for',
        id: 'cv.request.details.approve.by.email.approval.error.title.default',
    },
    approvalByEmailErrorFlagDescriptionDefault: {
        defaultMessage: 'Select <b>Approve</b> or <b>Decline</b> on this page if available, or try again later.',
        description: 'Description for the flag when there is a an error not already accounted for',
        id: 'cv.request.details.approve.by.email.approval.error.description.default',
    },
});
