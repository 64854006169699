import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type {
    FetchRequestsColumnSettingsModel,
    FetchRequestsColumnSettingsSuccess,
    FetchRequestsColumnSettingsFailure,
} from 'state/actions/requests-column-settings';
import {
    FETCH_REQUESTS_COLUMN_SETTINGS_MODEL,
    fetchRequestsColumnSettingsSuccess,
    fetchRequestsColumnSettingsFailure,
} from 'state/actions/requests-column-settings';
import type { ErrorResponse } from 'epics/model/types';

export const requestsColumnSettingsEpic: Epic<
    FetchRequestsColumnSettingsModel,
    FetchRequestsColumnSettingsSuccess | FetchRequestsColumnSettingsFailure
> = (action$) => {
    return action$.ofType(FETCH_REQUESTS_COLUMN_SETTINGS_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.requestsColumnSettings)
            .map((response) => {
                return fetchRequestsColumnSettingsSuccess(response.requestsColumnSettings || []);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchRequestsColumnSettingsFailure(error));
            })
    );
};
