import React from 'react';
import { islooselyLazyJsErrorsToSentryEnabled } from 'feature-flags';
import { LazySuspense } from 'react-loosely-lazy';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { Loading } from '@atlassian/help-center-common-component/loading';
import type { LoadingPosition } from '@atlassian/help-center-common-component/loading';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import type { LooselyLoadableLoadingComponentProps, Options } from './types';

interface LoadingComponentProps {
    error?: Error;
    timeout?: number;
    loadingPosition?: LoadingPosition;
    LoadingSkeleton?: React.ComponentType<LooselyLoadableLoadingComponentProps>;
}

interface ErrorBoundaryProps {
    onError?: (error: Error) => void;
    renderError: (error: Error) => JSX.Element;
}

interface State {
    error: Error | undefined;
}
class ErrorBoundary extends React.Component<
    React.PropsWithChildren<ErrorBoundaryProps> & ErrorBoundaryProps & WithAnalyticsEventsProps,
    State
> {
    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    state = {
        error: undefined,
    };

    componentDidCatch(error: Error): void {
        if (error.name !== 'ChunkLoadError') {
            const { createAnalyticsEvent } = this.props;
            if (createAnalyticsEvent !== undefined) {
                // This analytics will help us to note any errors which were previously marked as chunk errors. This might get triggered multiple times if islooselyLazyJsErrorsToSentryEnabled is true.
                const analyticsEvent = createAnalyticsEvent({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    errorMessage: error.message,
                    errorName: error.name,
                    stack: error.stack,
                });
                const finalId = 'looselyLazyErrorBoundary';
                const finalPackage = 'looselyLazyPackage';
                const actionSubject = `${finalPackage}.${finalId}`;
                analyticsEvent.context.push({ componentName: actionSubject });
                analyticsEvent.fire();
            }
        }
        if (islooselyLazyJsErrorsToSentryEnabled()) {
            // Differentiate between a chunkLoadError and other JS error.
            if (error.name === 'ChunkLoadError') {
                trackError('async.loader.failed', {}, error);
                this.props.onError?.(error);
            } else {
                this.props.onError?.(error);
                // this error will be caught by ScreenErrorBoundary at route level which will add it to sentry with packageName derived from route ScreenName
                throw error;
            }
        } else {
            trackError('async.loader.failed', {}, error);
            this.props.onError?.(error);
        }
    }

    render() {
        const error = this.state.error;

        return error ? this.props.renderError(error) : this.props.children;
    }
}
const ErrorBoundaryAnalytics = withAnalyticsContext()(withAnalyticsEvents()(ErrorBoundary));

const LoadingComponent: React.FC<LoadingComponentProps> = React.memo(function LoadingComponent({
    error,
    LoadingSkeleton,
    timeout,
    loadingPosition,
}) {
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (timeout) {
            timer = setTimeout(() => {
                setIsTimedOut(true);
            }, timeout);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timeout]);

    if (LoadingSkeleton) {
        return <LoadingSkeleton isLoading={error ? false : true} error={error} loadingPosition={loadingPosition} />;
    }

    return (
        <Loading
            isLoading={error ? false : true}
            error={error}
            loadingPosition={loadingPosition}
            timedOut={isTimedOut}
        />
    );
});

const LooselyLoadableWrapper = <TProps extends object>(options: Options<TProps>) => {
    const Component = options.loader;
    const LazyComponent: React.FC<TProps> & { preload: typeof Component.preload } = (props) => {
        const renderError = React.useCallback((error: Error) => {
            return <LoadingComponent error={error} LoadingSkeleton={options.LoadingSkeleton} />;
        }, []);

        return (
            <ErrorBoundaryAnalytics renderError={renderError}>
                <LazySuspense
                    fallback={
                        !__SERVER__ ? (
                            <UFOLoadHold name={Component.displayName || 'unknown'}>
                                <LoadingComponent LoadingSkeleton={options.LoadingSkeleton} timeout={options.timeout} />
                            </UFOLoadHold>
                        ) : (
                            <LoadingComponent LoadingSkeleton={options.LoadingSkeleton} timeout={options.timeout} />
                        )
                    }
                >
                    <Component {...props} />
                </LazySuspense>
            </ErrorBoundaryAnalytics>
        );
    };

    LazyComponent.preload = Component.preload;

    return LazyComponent;
};

export default LooselyLoadableWrapper;
