import type { AjaxError } from 'rxjs';
import type { MessageDescriptor } from 'react-intl-next';
import type { AppearanceTypes, ActionsType } from '@atlaskit/flag';

type MessageValue = string | number | boolean | Date | null | undefined;
export const HANDLE_AJAX_ERROR = 'state.ui.flags.HANDLE_AJAX_ERROR';
export const SHOW_ERROR_FLAG = 'state.ui.flags.SHOW_ERROR_FLAG';
export const SHOW_SUCCESS_FLAG = 'state.ui.flags.SHOW_SUCCESS_FLAG';
export const SHOW_BOLD_APPEARANCE_FLAG = 'state.ui.flags.SHOW_BOLD_APPEARANCE_FLAG';
export const DISMISS_FLAG = 'state.ui.flags.DISMISS_FLAG';

export interface HandleAjaxError {
    type: typeof HANDLE_AJAX_ERROR;
    payload: {
        error: AjaxError;
    };
}

export const handleAjaxError = (error: AjaxError): HandleAjaxError => ({
    type: HANDLE_AJAX_ERROR,
    payload: {
        error,
    },
});

export interface ErrorFlagAction {
    content: MessageDescriptor;
    href: string;
}

export interface SuccessFlagAction {
    contentNode: React.ReactNode;
    href?: string;
}

export interface ShowErrorFlag {
    type: typeof SHOW_ERROR_FLAG;
    payload: {
        titleMessage: MessageDescriptor;
        titleMessageValues?: { [key: string]: MessageValue };
        descriptionMessage: MessageDescriptor;
        descriptionMessageValues?: { [key: string]: MessageValue };
        actions: ErrorFlagAction[];
    };
}

export const showErrorFlag = (
    titleMessage: MessageDescriptor,
    descriptionMessage: MessageDescriptor,
    actions: ErrorFlagAction[] = [],
    titleMessageValues?: { [key: string]: MessageValue },
    descriptionMessageValues?: { [key: string]: MessageValue }
): ShowErrorFlag => ({
    type: SHOW_ERROR_FLAG,
    payload: {
        titleMessage,
        descriptionMessage,
        actions,
        titleMessageValues,
        descriptionMessageValues,
    },
});

export interface ShowSuccessFlag {
    type: typeof SHOW_SUCCESS_FLAG;
    payload: {
        titleMessage: MessageDescriptor;
        titleMessageValues?: { [key: string]: MessageValue };
        descriptionMessage?: MessageDescriptor;
        descriptionMessageValues?: { [key: string]: MessageValue };
        actions?: SuccessFlagAction[];
    };
}

export const showSuccessFlag = (
    titleMessage: MessageDescriptor,
    titleMessageValues?: { [key: string]: MessageValue },
    descriptionMessage?: MessageDescriptor,
    actions?: SuccessFlagAction[],
    descriptionMessageValues?: { [key: string]: MessageValue }
): ShowSuccessFlag => ({
    type: SHOW_SUCCESS_FLAG,
    payload: {
        titleMessage,
        titleMessageValues,
        descriptionMessage,
        descriptionMessageValues,
        actions,
    },
});

export interface ShowBoldAppearanceFlag {
    type: typeof SHOW_BOLD_APPEARANCE_FLAG;
    payload: {
        appearance: AppearanceTypes;
        titleMessage: MessageDescriptor;
        titleMessageValues?: { [key: string]: MessageValue };
        descriptionMessage?: MessageDescriptor;
        descriptionMessageValues?: { [key: string]: MessageValue };
        dismissActionValue?: MessageDescriptor;
        actions?: ActionsType;
    };
}

export const showBoldAppearanceFlag = (
    appearance: AppearanceTypes,
    titleMessage: MessageDescriptor,
    titleMessageValues?: { [key: string]: MessageValue },
    descriptionMessage?: MessageDescriptor,
    descriptionMessageValues?: { [key: string]: MessageValue },
    actions?: ActionsType,
    dismissActionValue?: MessageDescriptor
): ShowBoldAppearanceFlag => ({
    type: SHOW_BOLD_APPEARANCE_FLAG,
    payload: {
        appearance,
        titleMessage,
        titleMessageValues,
        descriptionMessage,
        descriptionMessageValues,
        dismissActionValue,
        actions,
    },
});

export interface DismissFlag {
    type: typeof DISMISS_FLAG;
    payload: {
        flagId: number;
    };
}

export const dismissFlag = (flagId: number): DismissFlag => ({
    type: DISMISS_FLAG,
    payload: {
        flagId,
    },
});
