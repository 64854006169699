import { UPDATE_META } from 'state/actions/meta';
import type { UpdateMetaAction } from 'state/actions/meta';
export interface MetaState {
    accountId: string;
    userLocale: string;
    accountType: string;
    sdPluginVersion: string;
    contextPath: string;
    environment: string;
    tenantId: string;
    activationId: string;
}

const defaultState: MetaState = {} as MetaState;

type Action = UpdateMetaAction;

export default function reducer(state: MetaState = defaultState, action: Action): MetaState {
    if (action.type === UPDATE_META) {
        const newState = action.payload;
        return { ...state, ...newState };
    }
    return state;
}
