import React from 'react';
import { isUiModificationsSupportEnabled } from '@helpCenter/feature-flags';
import { UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE } from '@helpCenter/routes/portal-home/resources';
import type { RouteContext } from 'react-resource-router';
import { useRouter } from 'react-resource-router';
import { ScreenErrorBoundary } from 'view/layout/screen-error-boundary';
import { RequestCreateAnonymousSuccess } from 'view/request-create-anonymous-success';
import { RequestCreatePage } from 'view/request-create-page';
import { RequestFeedback } from 'view/request-feedback';
import { Unsubscribe } from 'view/unsubscribe';
import { UserProfile } from 'view/user-profile';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';

import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { HTTP, ScreenName } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { UiModificationsContainer } from '@atlassian/jsm-ui-modifications-view-request-create/src/ui/container';
export { portalReorderRoute } from 'routes/portal-reorder';

const portalRoutePrefix = '/portal/:portalId(\\d+)';

const RenderRequestCreate = () => {
    const [routerState] = useRouter();

    return (
        <AnalyticsScreen screenName={ScreenName.REQUEST_CREATE} screenId={routerState.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.REQUEST_CREATE}>
                <UiModificationsContainer>
                    <RequestCreatePage {...routerState} />
                </UiModificationsContainer>
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

const RenderCreateAnonymousSuccess = () => {
    const [routerState] = useRouter();
    const { basePath, ...routeContext } = routerState;
    return (
        <AnalyticsScreen screenName={ScreenName.REQUEST_CREATE_ANONYMOUS_SUCCESS} screenId={routeContext.match.url}>
            <ScreenErrorBoundary screenName={ScreenName.REQUEST_CREATE_ANONYMOUS_SUCCESS}>
                <RequestCreateAnonymousSuccess {...routeContext} />
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

export const userProfileRoute = createRouteWithLayout(
    { name: 'user-profile', path: '/user/profile', exact: true, resources: [], component: UserProfile },
    ScreenName.USER_PROFILE,
    {
        hideSearchButton: false,
        paperAppearance: 'normal',
        fadeInSearchModal: true,
    }
);
export const NotFoundPage: React.FC<WithAnalyticsEventsProps & RouteContext> = ({ createAnalyticsEvent }) => {
    createAnalyticsEvent &&
        createAnalyticsEvent({
            action: 'rendered',
            analyticsType: UI_EVENT_TYPE,
        }).fire();

    return (
        <ErrorPage
            error={{
                status: HTTP.NOT_FOUND,
                message: '',
                callToActionUrl: '',
                callToActionText: '',
            }}
        >
            {/*This is needed because the ErrorPage requires children props.*/}
            {''}
        </ErrorPage>
    );
};

export const getRequestCreateRoute = () =>
    createRouteWithLayout(
        {
            name: 'request-create',
            path: '/portal/:portalId/create/:requestTypeId',
            exact: true,
            component: RenderRequestCreate,
            resources: isUiModificationsSupportEnabled() ? [UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE] : [],
        },
        ScreenName.REQUEST_CREATE,
        {
            fadeInSearchModal: true,
            paperAppearance: 'xxwide',
        }
    );

export const anonymousRequestSuccessRoute = createRouteWithLayout(
    {
        name: 'request-create-anonymous-success',
        path: '/portal/:portalId/create/:requestTypeId/confirm',
        exact: true,
        component: RenderCreateAnonymousSuccess,
        resources: [],
    },
    ScreenName.KNOWLEDGE_CATEGORY,
    {
        fadeInSearchModal: true,
    }
);

export const requestFeedbackRoute = createRouteWithLayout(
    {
        name: 'request-feedback',
        path: `${portalRoutePrefix}/:requestKey/feedback`,
        exact: true,
        component: RequestFeedback,
        resources: [],
    },
    ScreenName.FEEDBACK,
    {
        hideSearchButton: true,
        paperAppearance: 'note',
        fadeInSearchModal: true,
    }
);

export const unsubscribeRoute = createRouteWithLayout(
    {
        name: 'unsubscribeRoute',
        path: `${portalRoutePrefix}/:requestKey/unsubscribe`,
        exact: true,
        component: Unsubscribe,
        resources: [],
    },
    ScreenName.UNSUBSCRIBE,
    {
        hideSearchButton: true,
        paperAppearance: 'note',
        fadeInSearchModal: true,
    }
);

export const notFoundErrorRoute = createRouteWithLayout(
    {
        name: 'not-found-error-page',
        path: '(.*)',
        exact: true,
        component: withAnalyticsContext({ componentName: 'notFoundPage' })(withAnalyticsEvents({})(NotFoundPage)),
        resources: [],
    },
    ScreenName.ERROR_NOT_FOUND,
    {
        paperAppearance: 'normal',
        fadeInSearchModal: true,
    }
);
