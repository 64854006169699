import type { RequestGroup } from 'rest/request-group-reorder/request-group-reorder';
import type { State } from 'state';
import type { RequestGroupResponse } from 'state/actions/request-groups';
import type { ReqTypesState, ReqGroupsState } from 'state/persisted/portal';
import {
    getPortalRequestTypesForRequestGroup,
    getPortalRequestTypesNotInRequestGroup,
    getRequestGroupsForRequestType,
    getPortalRequestGroups,
} from 'state/selectors/portal';

/**
 *
 *  Persisted Portal Customisation state selectors
 *
 */

const withRequestTypesPopulated =
    (groupsWithTypes: ReqGroupsState[]) =>
    (reqGroup: RequestGroupResponse): ReqGroupsState => {
        const existingReqTypes = groupsWithTypes.find((rg) => rg.id === reqGroup.id);
        return {
            ...reqGroup,
            reqTypes: !!existingReqTypes ? existingReqTypes.reqTypes : [],
        };
    };

/** getAllRequestGroups() => all request groups created - regardless of whether they have request types in them */
export const getAllRequestGroups = (state: State) => state.persisted.portalCustomisation.allRequestGroups;

/** getAllRequestGroupsForPortal() => all request groups created for a given portal */
export const getAllRequestGroupsForPortal: (
    state: State,
    portalId: number | undefined
) => RequestGroupResponse[] | undefined = (state, portalId) => {
    const allRequestGroups = getAllRequestGroups(state);
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!(portalId && allRequestGroups && allRequestGroups[portalId])) {
        return undefined;
    }

    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return allRequestGroups[portalId];
};

/** getAllRequestGroupsWithRequestTypesForPortal() => all request groups (including request types data) created for a given portal with */
export const getAllRequestGroupsWithRequestTypesForPortal = (
    state: State,
    portalId: number | undefined
): ReqGroupsState[] | undefined => {
    const allRequestGroups = getAllRequestGroups(state);
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!(portalId && allRequestGroups && allRequestGroups[portalId])) {
        return undefined;
    }

    const groupsWithExistingRequestTypes = getPortalRequestGroups(state, portalId);
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const requestGroupsWithRequestTypes: ReqGroupsState[] = allRequestGroups[portalId].map(
        withRequestTypesPopulated(groupsWithExistingRequestTypes)
    );
    return requestGroupsWithRequestTypes;
};

/** getErrorMessage() => error message provided from backend - i.e when fetching all request group fails */
export const getErrorMessage = (state: State) => state.persisted.portalCustomisation.errorMessage;

/** getRequestGroupById() => individual request group from the all request group list */
export const getRequestGroupById = (state: State, portalId: number | undefined, requestGroupId: number) => {
    const requestGroups = getAllRequestGroupsWithRequestTypesForPortal(state, portalId);
    return !!requestGroups ? requestGroups.find((group: ReqGroupsState) => group.id === requestGroupId) : undefined;
};

/**
 *
 *  UI Portal Customisation state selectors
 *
 */

/** getUIRequestGroupsOrder() => an array of request group ids used to determine order in the drag and drop list */
export const getUIRequestGroupsOrder = (state: State) => state.ui.portalCustomisation.requestGroupsOrder;

/** getUIRequestTypesOrder() => an array of request type ids used to determine order in the drag and drop list */
export const getUIRequestTypesOrder = (state: State) => state.ui.portalCustomisation.requestTypesOrder;

/** getUIRequestGroups() => an array of request groups used to render items in the drag and drop list. Falls back to persisted state if non populated in UI state */
export const getUIRequestGroups = (state: State, portalId: number | undefined) =>
    state.ui.portalCustomisation.requestGroups || getAllRequestGroupsForPortal(state, portalId);

/** getUIRequestGroupsWithRequestTypes() => an array of request groups (with request types) used to render items in the drag and drop list. Falls back to persisted state if non populated in UI state */
export const getUIRequestGroupsWithRequestTypes = (state: State, portalId: number | undefined) =>
    state.ui.portalCustomisation.requestGroups
        ? state.ui.portalCustomisation.requestGroups.map(
              withRequestTypesPopulated(getPortalRequestGroups(state, portalId))
          )
        : getAllRequestGroupsWithRequestTypesForPortal(state, portalId);

/** getUIRequestTypesInRequestGroupById() =>  a list of request types with default order provided by the server, or by the UI state requestTypesOrder if it exists */
export const getUIRequestTypesInRequestGroupById = (
    state: State,
    portalId: number | undefined,
    requestGroupId: number
) => {
    let requestTypesInRequestGroup =
        state.ui.portalCustomisation.requestTypesInRequestGroup ||
        getPortalRequestTypesForRequestGroup(state, portalId, requestGroupId);

    const requestTypesOrder = getUIRequestTypesOrder(state);
    if (requestTypesOrder) {
        requestTypesInRequestGroup = requestTypesOrder
            .map((requestTypeId: number) => requestTypesInRequestGroup.find((rt) => Number(rt.id) === requestTypeId))
            .filter(Boolean) as ReqTypesState[];
    }

    return requestTypesInRequestGroup;
};

export const getUIParentRequestGroupsForRequestType = (
    state: State,
    portalId: number | undefined,
    requestTypeId: number
): RequestGroup[] => {
    const parentRequestGroups =
        state.ui.portalCustomisation.parentRequestGroups ||
        getRequestGroupsForRequestType({ state, portalId, requestTypeId });

    return [...parentRequestGroups].sort((first, second) =>
        first.name.toLowerCase().localeCompare(second.name.toLowerCase())
    );
};

export const getUIIsParentRequestGroupsLoading = (state: State) => {
    return state.ui.portalCustomisation.isParentRequestGroupsLoading;
};

/** getPortalCustomisationError() =>  inline UI validation error messages */
export const getPortalCustomisationError = (state: State) => state.ui.portalCustomisation.error;

/** getGroupNameThatCausedError() => request group name used to display inline UI validation error messages */
export const getGroupNameThatCausedError = (state: State) => state.ui.portalCustomisation.groupNameThatCausedError;

/** getUIRequestTypesNotInRequestGroupById() => a list of request types not in the currently selected request group */
export const getUIRequestTypesNotInRequestGroupById = (
    state: State,
    portalId: number | undefined,
    requestGroupId: number
) => {
    return (
        state.ui.portalCustomisation.requestTypesNotInRequestGroup ||
        getPortalRequestTypesNotInRequestGroup(state, portalId, requestGroupId)
    );
};
