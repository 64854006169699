import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { forgotPassword } from 'rest/login';
import type { ShowErrorFlag, ShowSuccessFlag } from 'state/actions/flags';
import { showErrorFlag, showSuccessFlag } from 'state/actions/flags';
import type { ForgotPassword, ForgotPasswordFailure, ForgotPasswordSuccess } from 'state/actions/user';
import { USER_PROFILE_FORGOT_PASSWORD, forgotPasswordSuccess, forgotPasswordFailure } from 'state/actions/user';
import { TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getUserProfileForgotPasswordFailedError } from '@atlassian/help-center-common-util/errors';
import messages from './messages';

export const forgotPasswordEpic: Epic<
    ForgotPassword,
    ForgotPasswordSuccess | ShowSuccessFlag | ForgotPasswordFailure | ShowErrorFlag
> = (action$: ActionsObservable<ForgotPassword>) => {
    return action$.ofType(USER_PROFILE_FORGOT_PASSWORD).mergeMap((forgotPasswordAction) => {
        const { resetUrl, email, createAnalyticsEvent } = forgotPasswordAction.payload;
        return forgotPassword(resetUrl, email)
            .flatMap(() => {
                const successEvent = createAnalyticsEvent?.({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'sent',
                    actionSubject: 'userProfileForgotPasswordLink',
                });
                successEvent && sendEvent(successEvent);
                return concat(
                    Observable.of(forgotPasswordSuccess()),
                    Observable.of(
                        showSuccessFlag(
                            messages.forgotPasswordSuccessFlagTitle,
                            undefined,
                            messages.forgotPasswordSuccessFlagDescription,
                            undefined,
                            { emailAddress: email }
                        )
                    )
                );
            })
            .catch((error: AjaxError) => {
                const failEvent = createAnalyticsEvent?.({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    actionSubject: 'userProfileForgotPasswordLink',
                });
                failEvent && sendEvent(failEvent);
                const parsedError = getUserProfileForgotPasswordFailedError(error);
                const errorAction = parsedError.action === undefined ? undefined : [parsedError.action];
                return concat(
                    Observable.of(forgotPasswordFailure()),
                    Observable.of(showErrorFlag(parsedError.title, parsedError.description, errorAction))
                );
            });
    });
};
