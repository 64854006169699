import type { AjaxError } from 'rxjs';

import { post } from 'epics/ajax';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { contextPath } from '@atlassian/help-center-common-util/history';

import type { ConnectBlockedAppsResponse, ConnectPageName } from '../types';

interface BlockedAppContext {
    projectId: number;
    page: ConnectPageName;
}

const perProjectRequestCache = new Map<string, Promise<boolean>>();

export const isConnectBlockedAppsRelevantPage = (page: ConnectPageName) => {
    const relevantConnectPages: ConnectPageName[] = ['PORTAL', 'CREATE_REQUEST', 'VIEW_REQUEST'];
    return relevantConnectPages.includes(page);
};

export const fetchIsAnyConnectAppBlocked = (context: BlockedAppContext): Promise<boolean> => {
    const uri = `${contextPath}/rest/atlassian-connect/latest/addon-access`;
    const body = {
        context,
    };
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    const cacheKey = context.projectId.toString();
    let cachedRequest = perProjectRequestCache.get(cacheKey);
    if (!cachedRequest) {
        cachedRequest = post(uri, body, headers)
            .map(({ response }: { response: ConnectBlockedAppsResponse }) => response.isAnyAppBlocked)
            .catch((e: AjaxError) => {
                trackError('connect.blocked-apps.fetch.failed', {}, e);
                return Promise.reject(e);
            })
            .toPromise();
        perProjectRequestCache.set(cacheKey, cachedRequest);
    }
    return cachedRequest;
};
