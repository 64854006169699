import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { deleteRequestTypeFromGroup } from 'rest/request-type-reorder';
import type { DeleteRequestTypeFromGroupAction } from 'state/actions/delete-request-type-from-group';
import { DELETE_REQUEST_TYPE_FROM_GROUP } from 'state/actions/delete-request-type-from-group';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import type { ClearRequestTypeOrderAction } from 'state/actions/portal-customisation';
import { clearRequestTypesOrderAction } from 'state/actions/portal-customisation';
import type { ReqGroupsState } from 'state/persisted/portal/reducer';
import { getRequestGroupsForRequestType } from 'state/selectors/portal';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

export const deleteRequestTypeFromGroupEpic: Epic<
    DeleteRequestTypeFromGroupAction,
    FetchPortalAction | ClearRequestTypeOrderAction
> = (action$, store) => {
    return action$.ofType(DELETE_REQUEST_TYPE_FROM_GROUP).mergeMap((action) => {
        const state = store.getState();
        const {
            isProjectSimplified,
            portalId,
            projectId,
            requestType,
            requestGroupId,
            analyticsEvent,
            analyticsFailureEvent,
        } = action.payload;

        const updatedRequestGroups = getRequestGroupsForRequestType({
            state,
            portalId,
            requestTypeId: Number(requestType.id),
        }).filter((requestGroup: ReqGroupsState) => requestGroup.id !== requestGroupId);

        const body = {
            ...requestType,
            groups: updatedRequestGroups,
        };

        return deleteRequestTypeFromGroup({
            isProjectSimplified,
            projectId,
            requestGroupId,
            requestType: body,
        })
            .map(() => {
                analyticsEvent && sendEvent(analyticsEvent);
                return fetchPortalAction({ id: portalId, expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] });
            })
            .catch(() => {
                if (analyticsFailureEvent) {
                    sendEvent(analyticsFailureEvent);
                }
                return Observable.of(clearRequestTypesOrderAction());
            });
    });
};
