export interface StoreAbortController {
    abort: () => void;
    signal: AbortSignal;
}

export interface StoreAbortControllerSettings {
    storeAbortController: StoreAbortController;
}

export const createStoreAbortController = (): StoreAbortControllerSettings => {
    const abortController = new AbortController();

    const abort = () => {
        abortController.abort();
    };

    const signal = abortController.signal;

    return {
        storeAbortController: { abort, signal },
    };
};
