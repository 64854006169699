import { createActionsHook, createStateHook, createStore } from 'react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

const initialState: State = {
    loading: false,
    cacheIds: undefined,
    requestTypeFieldsMap: new Map(),
};

export const RequestTypeFieldsStore = createStore({
    initialState,
    actions,
    name: 'requestTypeFields',
});

export const useStore = createStateHook(RequestTypeFieldsStore);
export const useActions = createActionsHook(RequestTypeFieldsStore);

export const useRequestTypeFieldPromise = createStateHook(RequestTypeFieldsStore, {
    selector: (state: State) => (state.inFlightRequest ? state.inFlightRequest : state.requestTypeFieldsMap),
});
