// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';

import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { LinkCard } from '@atlassian/help-center-common-component/card';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const IconContainer = styled.div`
    margin-right: ${token('space.100', '8px')};
`;

export type Appearance = 'white' | 'gray';

interface CardProps {
    appearance?: Appearance;
    padding?: number;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Card = styled(LinkCard)<CardProps>`
    ${mixins.borderRadius};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    background: ${(props) =>
        props.appearance === 'gray'
            ? token('elevation.surface.raised', colors.N10)
            : token('elevation.surface.raised', colors.N0)};
    padding: ${(props) => (props.padding !== undefined ? `${props.padding}px` : token('space.200', '16px'))};

    span {
        color: ${token('color.text', colors.N800)};
    }
    &:hover {
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        background: ${(props) =>
            props.appearance === 'gray'
                ? token('elevation.surface.raised.hovered', colors.N10)
                : token('elevation.surface.raised.hovered', colors.N0)};
    }
`;
