import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { FormattedMessage } from 'react-intl-next';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import { IconCardLayout } from 'view/layout/icon-card-layout';
import * as fonts from 'view/styles/fonts';
import { token } from '@atlaskit/tokens';
import { AutoScaledImage } from '@atlassian/help-center-common-component/auto-scaled-image';
import messages from './messages';
import { RecentFormsListItem } from './recent-forms-list-item';

interface RecentFormsProps {
    isUserLoggedIn: boolean;
    suggestedRequestTypes: RequestTypeSuggestion[];
    onClick?: (index: number) => void;
    isEmbeddedRoute?: boolean;
}

const ConditionalErrorBoundaryWrapper = ({ children }: { children: React.ReactNode }) => (
    <ErrorBoundary id="recent-forms" packageName="featured-and-sorted-portals">
        {children}
    </ErrorBoundary>
);

export default class RecentForms extends React.Component<RecentFormsProps> {
    onClick = (index: number) => () => {
        const { onClick } = this.props;
        onClick && onClick(index);
    };

    render() {
        const { suggestedRequestTypes, isUserLoggedIn, isEmbeddedRoute } = this.props;
        const headerTitle = isUserLoggedIn ? messages.headerTitle : messages.headerTitleAnon;
        return (
            <ConditionalErrorBoundaryWrapper>
                <RecentFormsHeader>
                    <FormattedMessage {...headerTitle} />
                </RecentFormsHeader>

                <RecentFormsContainer>
                    {suggestedRequestTypes.map((recentRequestSuggestion: RequestTypeSuggestion, recentFormIndex) => {
                        const Icon = <AutoScaledImage src={recentRequestSuggestion.iconUrl} alt="" draggable="false" />;

                        const LinkAndDescription = (
                            <RecentFormsListItem
                                {...recentRequestSuggestion}
                                onClick={this.onClick(recentFormIndex)}
                                isEmbeddedRoute={isEmbeddedRoute}
                            />
                        );

                        return (
                            <RecentFormItem key={`recentRequestSuggestion_${recentRequestSuggestion.id}`}>
                                <IconCardLayout padding={0} leftIcon={Icon} content={LinkAndDescription} />
                            </RecentFormItem>
                        );
                    })}
                </RecentFormsContainer>
            </ConditionalErrorBoundaryWrapper>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RecentFormsContainer = styled.ul`
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RecentFormsHeader = styled.h2`
    ${fonts.h500};
    padding-bottom: ${token('space.200', '16px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RecentFormItem = styled.li`
    display: block;
    word-wrap: break-word;
    position: relative;
    padding-bottom: ${token('space.200', '16px')};
`;
