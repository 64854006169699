// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';

interface AutoScaledImageProps {
    objectFit?: 'contain' | 'cover';
    inheritWidth?: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const AutoScaledImage = styled.img<AutoScaledImageProps>`
    height: 100%;
    width: ${({ inheritWidth }) => (inheritWidth ? 'inherit' : '100%')};
    object-fit: ${(props) => props.objectFit || 'contain'};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export default AutoScaledImage;
