import type { RequestGroupResponse } from 'state/actions/request-groups';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const ADD_REQUEST_GROUP = 'epic.request-groups.ADD_REQUEST_GROUP' as const;
export const ADD_REQUEST_GROUP_FAILED = 'epic.request-groups.ADD_REQUEST_GROUP_FAILED';

export interface AddRequestGroupPayload {
    groupName: string;
    projectId: number;
    portalId: number;
    existingGroups: RequestGroupResponse[];
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface AddRequestGroupAction {
    payload: AddRequestGroupPayload;
    type: typeof ADD_REQUEST_GROUP;
}

interface AddRequestGroupFailedPayload {
    groupNameThatCausedError: string;
    message: string;
}

export interface AddRequestGroupFailedAction {
    payload: AddRequestGroupFailedPayload;
    type: typeof ADD_REQUEST_GROUP_FAILED;
}

export const addRequestGroupAction = (payload: AddRequestGroupPayload) => ({
    payload,
    type: ADD_REQUEST_GROUP,
});

export const addRequestGroupFailedAction = (
    message: string,
    groupNameThatCausedError: string
): AddRequestGroupFailedAction => ({
    payload: {
        message,
        groupNameThatCausedError,
    },
    type: ADD_REQUEST_GROUP_FAILED,
});
