import { defineMessages } from 'react-intl-next';

export default defineMessages({
    permissionDenied: {
        defaultMessage: 'No Access',
        description: 'Message to show when user does not have the permission to access the portal.',
        id: 'cv.portal.permission.denied.error',
    },
    permissionDeniedMessage: {
        defaultMessage: "Sorry you're not allowed to see this.",
        description: 'Message to show when user does not have the permission to access the portal.',
        id: 'cv.portal.permission.denied.message',
    },

    notFound: {
        defaultMessage: 'Not Found',
        description: 'Heading to show when user try to access portal or request create page that does not exist.',
        id: 'cv.portal.request.create.not.found.error',
    },
    notFoundMessage: {
        defaultMessage: 'We are having trouble finding that. Check the details and try again.',
        description: 'Message to show when user try to access portal or request create page that does not exist.',
        id: 'cv.portal.request.create.not.found.message',
    },

    genericHeading: {
        defaultMessage: 'Something bad happened',
        description: 'Generic error when something could not be loaded',
        id: 'cv.portal.error-page.generic.error.heading',
    },
    genericMessage: {
        defaultMessage: 'Looks like we’re having trouble retrieving some information, please refresh the page.',
        description: 'Generic error when something could not be loaded',
        id: 'cv.portal.error-page.generic.error.message',
    },

    offlineHeading: {
        defaultMessage: "You're disconnected from the internet",
        description: 'Title for the error message when the user was offline when trying to view a page',
        id: 'cv.portal.error-page.offline.heading',
    },
    offlineMessage: {
        defaultMessage: 'The internet may be out to lunch. Check your network connection and try again.',
        description: 'Title for the error message when the user was offline when trying to view a page',
        id: 'cv.portal.error-page.offline.message',
    },
});
