import { useCallback, useEffect, useState } from 'react';

export const useAdDismissalPersistence = () => {
    const DISMISSED_STORAGE_KEY = 'helpcenter.requestservicedesk.cta.dismissed';
    // Start state with ad hidden to avoid a potential ad flash before persisted value is checked
    const [isAdDismissed, setIsDismissed] = useState<boolean>(true);

    const dismissAd = useCallback(() => {
        // Always set dismissed state to true so that the ad is immediately hidden
        // even if the persistence eventually fails (due to full storage, etc)
        setIsDismissed(true);
        try {
            localStorage.setItem(DISMISSED_STORAGE_KEY, 'true');
        } finally {
            // Nothing to do
        }
    }, [setIsDismissed]);

    useEffect(() => {
        try {
            // If the key doesn't exist we get null which coerces to false
            const dismissed = localStorage.getItem(DISMISSED_STORAGE_KEY);
            setIsDismissed(Boolean(dismissed));
        } catch (e) {
            // If local storage is unavailable, default to visible
            setIsDismissed(false);
        }
    }, []);

    return [isAdDismissed, dismissAd] as const;
};
