import { buildModelsActionCreator } from 'epics/model/actions';
import type { RequestTypeSuggestionResponse } from 'rest/models';

export const FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL = 'epic.model.FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL';
export const FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS =
    'state.persisted.request-type-suggestion.FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS';

export type FetchRequestTypeSuggestionAction = ReturnType<typeof fetchRequestTypeSuggestionAction>;

export const fetchRequestTypeSuggestionAction = buildModelsActionCreator(
    FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL,
    'suggestedRequestTypes'
);

export interface FetchRequestTypeSuggestionSuccess {
    type: typeof FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS;
    payload: RequestTypeSuggestionResponse;
}

export const fetchRequestTypeSuggestionSuccess = (
    requestTypeSuggestions: RequestTypeSuggestionResponse
): FetchRequestTypeSuggestionSuccess => ({
    payload: requestTypeSuggestions,
    type: FETCH_REQUEST_TYPE_SUGGESTION_SUCCESS,
});
