import React, { Suspense, useCallback } from 'react';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import { isMoveToManagementRouterEnabled, isProjectMappingInHelpCenterEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { useRouterActions } from 'react-resource-router';
import { useAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { LeftSidebarWithoutResize } from '@atlaskit/page-layout';
import { Box, xcss } from '@atlaskit/primitives';
import {
    GoBackItem,
    ButtonItem,
    HeadingItem,
    Section,
    SideNavigation,
    NavigationHeader,
    NavigationContent,
} from '@atlaskit/side-navigation';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { HELP_CENTERS_MANAGEMENT_BASE_PATH } from '@atlassian/help-center-common-component/constants';
import FeedbackCollectorButton from '@atlassian/help-center-common-component/feedback-collector/feedback-collector-button';
import { getEnv } from '@atlassian/help-center-common-util/env';
import messages from './messages';
import { SidebarHelpCenterHeader } from './sidebar-help-center-header';
import { SidebarSkeleton } from './sidebar-skeleton';
export interface NavigationContentItem {
    onClick: () => void;
    iconBefore?: React.ReactElement;
    text: string;
    isVisible: boolean;
    isSelected?: boolean;
}

interface SidebarProps {
    width: number;
    navigationContentItems: NavigationContentItem[];
    featureName: string;
}

const Sidebar: React.FC<SidebarProps> = ({ width, navigationContentItems, featureName }) => {
    di(isProjectMappingInHelpCenterEnabled, getEnv, useLazyLoadQuery);
    const { push } = useRouterActions();
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onBackButtonClick = useCallback(() => {
        createAnalyticsEvent({
            analyticsType: UI_EVENT_TYPE,
            action: 'click',
            actionSubjectId: `SidebarBackButton`,
        }).fire();
        if (!isProjectMappingInHelpCenterEnabled()) {
            window.location.href = `${getEnv().baseUrl}`;
        } else if (isProjectMappingInHelpCenterEnabled() && isMoveToManagementRouterEnabled()) {
            push('/') as void;
        } else {
            window.location.href = HELP_CENTERS_MANAGEMENT_BASE_PATH;
        }
    }, [createAnalyticsEvent, push]);
    return (
        <LeftSidebarWithoutResize width={width}>
            <Box xcss={sidebarContainerStyles}>
                <SideNavigation label={formatMessage(messages.sidebarNavigationLabel)}>
                    <Suspense fallback={<SidebarSkeleton />}>
                        <NavigationHeader>
                            <ErrorBoundary>
                                <SidebarHelpCenterHeader />
                            </ErrorBoundary>
                        </NavigationHeader>
                        <Box padding="space.100">
                            <GoBackItem onClick={onBackButtonClick}>
                                {isProjectMappingInHelpCenterEnabled()
                                    ? formatMessage(messages.backButton)
                                    : formatMessage(messages.backButtonWithoutProjectMapping)}
                            </GoBackItem>
                        </Box>
                        <NavigationContent showTopScrollIndicator>
                            <>
                                {isProjectMappingInHelpCenterEnabled() && (
                                    <HeadingItem>{formatMessage(messages.settingsLabel)}</HeadingItem>
                                )}
                            </>
                            <Section isList>
                                {navigationContentItems.map(
                                    ({ onClick, iconBefore, text, isSelected, isVisible }) =>
                                        isVisible && (
                                            <ButtonItem
                                                onClick={onClick}
                                                iconBefore={iconBefore}
                                                key={text}
                                                isSelected={isSelected}
                                            >
                                                {text}
                                            </ButtonItem>
                                        )
                                )}
                            </Section>
                        </NavigationContent>
                        <Box xcss={navFooter}>
                            <NavigationContent showTopScrollIndicator>
                                <Section>
                                    <FeedbackCollectorButton
                                        showIcon
                                        showTypeField
                                        featureName={featureName}
                                        buttonLabel={messages.feedbackWidget}
                                        analyticsSubjectId="SidebarFeedbackButton"
                                        useSideNavButton
                                    />
                                </Section>
                            </NavigationContent>
                        </Box>
                    </Suspense>
                </SideNavigation>
            </Box>
        </LeftSidebarWithoutResize>
    );
};

const navFooter = xcss({
    position: 'relative',
});

const sidebarContainerStyles = xcss({
    width: '100%',
    height: '100%',
    borderRight: '1px solid',
    borderColor: 'color.border',
    boxSizing: 'inherit',
});

export default withAnalyticsContext({ component: 'SideBar' })(Sidebar);
