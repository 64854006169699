import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { is1DFOCAQFeatureGateEnabled } from 'feature-flags';
import * as fonts from 'view/styles/fonts';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import SendIcon from '@atlaskit/icon/glyph/send';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { CardLinkAppearance, Option } from '@atlassian/help-center-common-component/group-box-select';
import { GroupBoxSelect } from '@atlassian/help-center-common-component/group-box-select';
import * as qs from '@atlassian/help-center-common-util/history-qs';
import { REFERRER_QUERY_PARAM } from '@atlassian/learn-by-doing-create-request';
import type { DerivedReqTypeState, DerivedReqGroupsState } from '../types';

export interface RequestGroupSelectProps {
    autoFocus?: boolean;
    portalId: number | undefined;
    label: string;
    value?: number;
    disabled?: boolean;
    className?: string;
    requestGroups: DerivedReqGroupsState[];
    onChange?: (value?: number) => void;
    cardLinkAppearance?: CardLinkAppearance;
    isEmbeddedRoute?: boolean;
}

export default class RequestGroupSelect extends React.PureComponent<RequestGroupSelectProps> {
    getRedirectionUrl(requestGroupid: number, portalId?: number) {
        const referrer = qs.get(REFERRER_QUERY_PARAM);
        const shouldAddReferrer = referrer && referrer !== 'undefined' && is1DFOCAQFeatureGateEnabled();

        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `/portal/${portalId}/group/${requestGroupid}${shouldAddReferrer ? `?${REFERRER_QUERY_PARAM}=${referrer}` : ''}`;
    }

    getOptions(): Option<number>[] {
        const { requestGroups, portalId, isEmbeddedRoute } = this.props;

        return requestGroups.map((requestGroup) => ({
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.

            to: this.getRedirectionUrl(requestGroup?.id, portalId),
            value: requestGroup.id,
            label: requestGroup.name,
            description: <RequestTypeList>{this.formatReqGroupNames(requestGroup.reqTypes)}</RequestTypeList>,
            dataTestId: `request-group:${requestGroup.name}`,
            target: isEmbeddedRoute ? '_blank' : undefined,
        }));
    }

    formatReqGroupNames(reqTypes: DerivedReqTypeState[]) {
        return reqTypes.map((rt) => rt.name).join(', ');
    }

    render() {
        const { label, onChange, value, disabled, ...props } = this.props;
        return (
            <AnalyticsContext
                data={{
                    actionSubjectId: 'requestTypeGroups',
                    attributes: {
                        portalId: props.portalId,
                        numberOfRequestTypeGroups: props.requestGroups.length,
                    },
                }}
            >
                <GroupBoxSelect
                    id="request-group-select"
                    actionSubjectId="requestTypeGroupSelect"
                    itemActionSubjectId="requestTypeGroup"
                    listAppearance="card"
                    selectAppearance="simple"
                    label={label}
                    labelIcon={
                        <SendIcon label={''} aria-hidden="true" primaryColor={token('color.icon', colors.N500)} />
                    }
                    options={this.getOptions()}
                    onChange={onChange}
                    value={value}
                    disabled={!!disabled}
                    {...props}
                />
            </AnalyticsContext>
        );
    }
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RequestTypeList = styled.span`
    ${fonts.small};
`;
