import { defineMessages } from 'react-intl-next';

export default defineMessages({
    defaultMappingToggleLabel: {
        defaultMessage: 'Link all new projects to this help center',
        description: 'Toggle label for default mapping',
        id: 'sync.project.toggle.label',
    },
    removeMappingModalTitle: {
        defaultMessage: 'Stop linking new projects to this help center?',
        description: 'removeMapping modal title',
        id: 'sync.project.toggle.removeMapping.modal.title',
    },
    removeMappingModalDesc: {
        defaultMessage: 'Help resources from new projects will not show up in this help center or search.',
        description: 'removeMapping modal description',
        id: 'sync.project.toggle.removeMapping.modal.description',
    },
    removeMappingModalConfirmButton: {
        defaultMessage: 'Continue',
        description: 'removeMapping modal confirm button',
        id: 'sync.project.toggle.removeMapping.modal.confirm',
    },
    removeMappingModalCancelButton: {
        defaultMessage: 'Cancel',
        description: 'removeMapping modal cancel button',
        id: 'sync.project.toggle.removeMapping.modal.cancel',
    },
    syncErrorTitle: {
        defaultMessage: 'We couldn’t turn off automatic linking of new projects',
        description: 'Sync new projects flag error title',
        id: 'sync.project.toggle.sync.flag.error.title',
    },
    syncErrorDescription: {
        defaultMessage: 'Refresh the page and try again.',
        description: 'Sync new projects flag error description',
        id: 'sync.project.toggle.sync.flag.error.description',
    },
    unSyncSuccessTitle: {
        defaultMessage: 'Automatic linking of new projects turned off',
        description: 'Sync new projects flag success title',
        id: 'sync.project.toggle.sync.flag.success.title',
    },
});
