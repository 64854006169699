import { defineMessages } from 'react-intl-next';

export default defineMessages({
    documentTitle: {
        defaultMessage: 'No access',
        description: 'Document title for user with no access to portal',
        id: 'cv.portal.forbidden.access.document.title',
    },
    errorHeading: {
        defaultMessage: 'No access',
        description: 'Error heading for user with no portal access',
        id: 'cv.portal.forbidden.access.error.heading',
    },
    errorDescriptionText: {
        defaultMessage:
            "It seems that your account {userEmail} doesn't have access to the help center.{br}Try contacting your admins to grant you permissions.",
        description: 'Error description for user with no portal access',
        id: 'cv.portal.forbidden.access.error.description.text',
    },
    callToActionText: {
        defaultMessage: 'Go to Atlassian Start',
        description: 'Next action link label for user with no portal access',
        id: 'cv.portal.forbidden.access.error.action.link.label',
    },
});
