import { mergeWith } from 'lodash';
import * as actions from 'state/actions/help-center-preview';

export type HelpCenterPreviewState = actions.HCPreviewUpdate & {
    isCustomizing: boolean;
    isSaveInProgress: boolean;
    translations: {
        [key: string]: actions.HCPreviewTranslation;
    };
    uploadsInProgress: {
        [fileCollection: string]: boolean;
    };
};

type HandledActions =
    | actions.UpdateHCPreviewModelAction
    | actions.ClearHCPreviewModelAction
    | actions.EditingHelpCenter
    | actions.UpdateBranding
    | actions.UpdateBrandingMutationSuccess
    | actions.UpdateBrandingMutationFailure
    | actions.UpdateBrandingFailure
    | actions.UploadStart
    | actions.UploadEnd
    | actions.UpdateBrandingSaveStart;

const getDefaultState = (): HelpCenterPreviewState => ({
    isCustomizing: false,
    isSaveInProgress: false,
    translations: {},
    uploadsInProgress: {},
});

export default function reducer(
    state: HelpCenterPreviewState = getDefaultState(),
    action: HandledActions
): HelpCenterPreviewState {
    switch (action.type) {
        case actions.UPDATE_HELP_CENTER_PREVIEW_MODEL: {
            const newState = mergeWith(
                {},
                { ...state },
                { ...action.payload },
                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                // eslint-disable-next-line @typescript-eslint/ban-types
                (_: {} | undefined, srcValue: {} | undefined) => {
                    // If the value is undefined we will instead change it to null, the reason for this is to ensure that the resulting
                    // property gets replaced with null, by default mergeWith (and merge) does not replace properties if the value is undefined
                    if (srcValue === undefined) {
                        return null;
                    }

                    // If the value is not specifically 'undefined' we leave it up to the default customizer to handle
                    return undefined;
                }
            );

            return { ...newState };
        }

        case actions.EDITING_HELP_CENTER: {
            return {
                ...state,
                isCustomizing: action.payload,
            };
        }

        case actions.CLEAR_HELP_CENTER_PREVIEW_MODEL: {
            return {
                translations: {},
                uploadsInProgress: {},
                isCustomizing: false,
                isSaveInProgress: false,
            };
        }

        case actions.UPDATE_HELP_CENTER_BRANDING: {
            return {
                ...state,
                isSaveInProgress: true,
            };
        }

        case actions.UPDATE_HELP_CENTER_BRANDING_MUTATION_SUCCESS: {
            return {
                ...state,
                isSaveInProgress: false,
            };
        }

        case actions.UPDATE_HELP_CENTER_BRANDING_MUTATION_FAILURE: {
            return {
                ...state,
                isSaveInProgress: false,
            };
        }

        case actions.UPDATE_HELP_CENTER_BRANDING_SAVE_START: {
            return {
                ...state,
                isSaveInProgress: true,
            };
        }

        case actions.UPDATE_HELP_CENTER_BRANDING_FAILURE: {
            return {
                ...state,
                isSaveInProgress: false,
            };
        }

        case actions.UPLOAD_START: {
            return {
                ...state,
                uploadsInProgress: {
                    ...state.uploadsInProgress,
                    [action.payload.fileCollection]: true,
                },
            };
        }

        case actions.UPLOAD_END: {
            return {
                ...state,
                uploadsInProgress: {
                    ...state.uploadsInProgress,
                    [action.payload.fileCollection]: false,
                },
            };
        }

        default:
            return state;
    }
}
