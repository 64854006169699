import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { getPortalRequestType } from 'state/selectors/portal';
import { RequestCreateFormHelpTextDumb } from '../request-create-form-help-text-dumb';

interface ExternalProps {
    portalId?: number | undefined;
    requestTypeId?: number | undefined;
}

const selector = createSelector(
    (state: State, props: ExternalProps) => {
        const { portalId, requestTypeId } = props;
        const requestType = getPortalRequestType(state, portalId, requestTypeId);
        return requestType && requestType.helpTextHtml;
    },
    (helpText) => ({
        helpText,
    })
);

export default connect(selector)(RequestCreateFormHelpTextDumb);
