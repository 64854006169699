/**
 * @generated SignedSource<<a6086000d624a50090e470e4144e1e34>>
 * @relayHash db15c44e7babf74df0819e35de9bb972
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bc1d5180124d23a92a9df36c72c5ecd9685118f15f759ebbfc3d3dc0cebd2615

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topicQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  topicId: string;
};
export type topicQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpCenterTopic_HelpCenterQuery">;
};
export type topicQuery = {
  response: topicQuery$data;
  variables: topicQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topicId"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v2 = {
  "kind": "ScalarField",
  "name": "description"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "title"
},
v5 = {
  "kind": "ScalarField",
  "name": "displayLink"
},
v6 = {
  "kind": "ScalarField",
  "name": "entityKey"
},
v7 = {
  "kind": "ScalarField",
  "name": "hidden"
},
v8 = [
  (v4/*: any*/),
  (v2/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "topicQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "helpCenterTopic_HelpCenterQuery"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "topicQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "helpCenterAri",
                "variableName": "helpCenterAri"
              },
              {
                "kind": "Variable",
                "name": "topicId",
                "variableName": "topicId"
              }
            ],
            "kind": "LinkedField",
            "name": "helpCenterTopicById",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  (v2/*: any*/),
                  {
                    "args": (v3/*: any*/),
                    "concreteType": "HelpCenterTopicItemConnection",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "HelpCenterTopicItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "HelpCenterTopicItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "ari"
                              },
                              {
                                "kind": "LinkedField",
                                "name": "data",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isHelpCenterHelpObject"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v4/*: any*/),
                                      (v2/*: any*/),
                                      {
                                        "concreteType": "HelpObjectStoreIcon",
                                        "kind": "LinkedField",
                                        "name": "icon",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "name": "iconUrl"
                                          }
                                        ]
                                      },
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/)
                                    ],
                                    "type": "HelpObjectStoreRequestForm"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v8/*: any*/),
                                    "type": "HelpObjectStoreArticle"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v8/*: any*/),
                                    "type": "HelpObjectStoreChannel"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "id"
                                      }
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ]
                              },
                              (v1/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "cursor"
                          }
                        ]
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      }
                    ],
                    "storageKey": "items(first:10)"
                  },
                  {
                    "args": (v3/*: any*/),
                    "handle": "connection",
                    "key": "mainHelpObjectsList_HelpCenterTopic_items",
                    "kind": "LinkedHandle",
                    "name": "items"
                  }
                ],
                "type": "HelpCenterTopic"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "statusCode"
                      }
                    ]
                  }
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "bc1d5180124d23a92a9df36c72c5ecd9685118f15f759ebbfc3d3dc0cebd2615",
    "metadata": {},
    "name": "topicQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "aad6fa68f7fdec1c20c0311c482bf40f";

export default node;
