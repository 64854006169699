import * as React from 'react';
import type { InferableComponentEnhancerWithProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isLoggedIn as getIsLoggedIn } from 'state/selectors/user';
import { Redirect } from '@atlassian/help-center-common-util/redirect/redirect';
import { getLoginUrl } from '@atlassian/help-center-common-util/url';

const selector = createSelector(getIsLoggedIn, (isLoggedIn) => ({
    isLoggedIn,
}));

type ConnectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;

const connector = connect(selector, null);

interface LoggedInOptions {
    redirectUrl?: string;
}

const withOnlyLoggedIn =
    (options: LoggedInOptions) =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const ReturnComponent: React.FC<TProps & ConnectedProps<typeof connector>> = (props) => {
            const { isLoggedIn } = props;

            if (isLoggedIn) {
                // @ts-ignore TypeScript upgrade 4.7.4
                return <WrappedComponent {...props} />;
            }

            const { redirectUrl } = options;

            const loginUrl = getLoginUrl({
                redirectUrl,
                isAbsolute: false,
            });

            return <Redirect to={loginUrl} />;
        };

        const ConnectedComponent = connector(ReturnComponent);
        ConnectedComponent.displayName = 'WithOnlyLoggedIn';

        return ConnectedComponent;
    };

export default withOnlyLoggedIn;
