import type { Option, ValueType } from '@atlaskit/select';

export const toSingleSelectValue = <T,>(
    value: ValueType<Option<T>> | null | undefined
): Option<T> | null | undefined => {
    if (value == null) {
        return value;
    }
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Array.isArray(value) ? value[0] : value;
};

export const toMultiSelectValue = <T,>(value: ValueType<Option<T>>): Option<T>[] => {
    if (value == null) {
        return [];
    }
    return Array.isArray(value) ? value : [value];
};
