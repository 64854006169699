import React from 'react';
import * as FeatureFlags from 'feature-flags';
import { AnalyticsContext } from '@atlassian/analytics-web-react';

interface FeatureFlagContextProps {
    children: React.ReactNode;
}

const extractFlags = () =>
    Object.entries(FeatureFlags).reduce((acc, [key, value]) => {
        if (typeof value !== 'function') {
            return acc;
        }

        return {
            ...acc,
            [key]: value(),
        };
    }, {});

const FeatureFlagContext: React.FunctionComponent<FeatureFlagContextProps> = ({ children }) => (
    <AnalyticsContext attributes={extractFlags()}>{children}</AnalyticsContext>
);

export default FeatureFlagContext;
