import * as React from 'react';
import { isNewTextFieldEnabled } from 'feature-flags';
import Textfield from '@atlaskit/textfield';
import TextfieldNext from '@atlaskit/textfield--next';
import type { TextfieldProps } from '@atlaskit/textfield--next/dist/types/types';

export const TextfieldWrapper: React.FunctionComponent<TextfieldProps> = (props) => {
    return isNewTextFieldEnabled() ? <TextfieldNext {...props} /> : <Textfield {...props} />;
};

export default TextfieldWrapper;
