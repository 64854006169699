const DEFAULT_TIMEOUT_MS = 1000;
const DEFAULT_NUMBER_OF_RETRIES = 3;

const delay = (timeMs: number) => new Promise((resolve) => setTimeout(resolve, timeMs));

interface Config {
    numberOfRetries?: number;
    timeoutMs?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
}

/**
 * Recursively tries to resolve a promise up to a max number of times.
 *
 * @param promise the promise to retry
 * @param config.numberOfRetries numberOfRetries number of times to retry the promise
 * @param config.timeoutMs amount of time to wait between retries, in milliseconds
 * @param config.error the error from the most recent promise attempt. Only to be used in the recursive case.
 */
// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line @typescript-eslint/ban-types
export const promiseWithRetries = <T extends {}>(promise: () => Promise<T>, config: Config = {}): Promise<T> => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { numberOfRetries = DEFAULT_NUMBER_OF_RETRIES, timeoutMs = DEFAULT_TIMEOUT_MS, error = null } = config;
    if (numberOfRetries <= 0) {
        return Promise.reject(error);
    }
    return promise().catch((promiseError) =>
        delay(timeoutMs).then(() =>
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            promiseWithRetries(promise, { ...config, numberOfRetries: numberOfRetries - 1, error: promiseError })
        )
    );
};
