import type { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import { userRequestsCountsEpic } from 'epics/model/user-requests-counts';
import type { Epic } from 'epics/rxjs';
import { aiAnswerFeedbackPageEpic } from './ai-answer';
import { approvalListEpic } from './approval-list';
import { fetchArticleEpic } from './article';
import { completeSignupEpic } from './complete-signup';
import { connectFragmentsEpic, profileConnectFragmentsEpic } from './connect-fragments';
import { featuredAndSortedPortalsEpic } from './featured-and-sorted-portals';
import { forgotPasswordEpic } from './forgot-password';
import { helpCenterBrandingEpic, helpCenterEpic, invalidateHelpCenterBrandingEpic } from './help-center';
import { categoryArticlesEpic } from './kb-article';
import { categoriesEpic } from './kb-category';
import { languagesEpic } from './languages';
import { loginEpic } from './login';
import { loginAnnouncementsEpic } from './login-announcements';
import { organisationsEpic } from './organisations';
import { portalEpic, portalUploadMediaTokenEpic, refreshPortalUploadMediaTokenBeforeExpiryEpic } from './portal';
import { requestCreateEpic } from './request-create';
import { requestDetailsEpic } from './request-details';
import { requestListEpic } from './request-list';
import { requestTypeSuggestionEpic } from './request-type-suggestions';
import { requestsColumnSettingsEpic } from './requests-column-settings';
import { timezonesEpic, timezoneRegionsEpic } from './timezones';
import { unsubscribedConfirmationEpic } from './unsubscribed-confirmation';
import { userEpic } from './user';
import { visitPortalEpic } from './visit-portal';

// Need to force typescript to pick up the right combineEpics
export default combineEpics<Epic<Action>>(
    helpCenterBrandingEpic,
    helpCenterEpic,
    invalidateHelpCenterBrandingEpic,
    portalEpic,
    portalUploadMediaTokenEpic,
    refreshPortalUploadMediaTokenBeforeExpiryEpic,
    userEpic,
    organisationsEpic,
    requestCreateEpic,
    requestTypeSuggestionEpic,
    connectFragmentsEpic,
    categoriesEpic,
    categoryArticlesEpic,
    unsubscribedConfirmationEpic,
    loginEpic,
    loginAnnouncementsEpic,
    forgotPasswordEpic,
    fetchArticleEpic,
    requestDetailsEpic,
    requestListEpic,
    requestsColumnSettingsEpic,
    approvalListEpic,
    languagesEpic,
    timezoneRegionsEpic,
    timezonesEpic,
    profileConnectFragmentsEpic,
    visitPortalEpic,
    completeSignupEpic,
    userRequestsCountsEpic,
    featuredAndSortedPortalsEpic,
    aiAnswerFeedbackPageEpic
);
