import type { KnowledgeArticle } from 'state/ui/search';
export const FETCH_KB_SUGGESTIONS_SUCCESS = 'epic.model.FETCH_KB_SUGGESTIONS';

export interface FetchKBSuggestionsAction {
    type: typeof FETCH_KB_SUGGESTIONS_SUCCESS;
    payload: {
        kbArticleSuggestions: KnowledgeArticle[];
        portalId?: number;
        projectId?: number;
    };
}

export const fetchKBSuggestionsSuccessAction = (params: {
    kbArticleSuggestions: KnowledgeArticle[];
    portalId?: number;
    projectId?: number;
}): FetchKBSuggestionsAction => {
    return {
        type: FETCH_KB_SUGGESTIONS_SUCCESS,
        payload: params,
    };
};
