import { defineMessages } from 'react-intl-next';

export default defineMessages({
    helpCenterSaveButtonText: {
        defaultMessage: 'Save',
        description: 'Help center save button text',
        id: 'help.center.save.button.text',
    },
    helpCenterCancelButtonText: {
        defaultMessage: 'Cancel',
        description: 'Help center cancel button text',
        id: 'help.center.cancel.button.text',
    },
    helpCenterCreateButtonText: {
        defaultMessage: 'Create',
        description: 'Help center create button text',
        id: 'help.center.create.button.text',
    },
});
