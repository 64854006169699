/**
 * Util to help pre-fetching of initial model data
 */
import type { ConnectFragmentsResponse, ProfileConnectFragmentsResponse } from 'rest/connect-addon';
import type { FeaturedAndSortedPortalsResponse } from 'rest/featured-and-sorted-portals';
import type { HCBrandingResponse } from 'rest/help-center-branding';
import type { LoginAnnouncementsResponse } from 'rest/login-announcements';
import type {
    ArticleResponse,
    UserResponse,
    LanguageResponse,
    TimezoneRegionResponse,
    TimezoneResponse,
    VisitPortalResponse,
    CompleteSignupResponse,
    SignUpResponse,
    ResetPasswordResponse,
    AnonymousApprovalResponse,
    VerifyEmailResponse,
    AiAnswerFeedbackResponse,
} from 'rest/models';
import type { OrganisationsResponse } from 'rest/organisations';
import type { PortalResponse, PortalBrandingResponse } from 'rest/portal';
import type { RequestCreateResponse } from 'rest/request-create';
import type { RequestDetailsResponse } from 'rest/request-details';
import type { RequestListResponse } from 'rest/request-list';
import type { RequestsColumnSettingsResponse } from 'rest/requests-column-settings';
import type { ForgotPasswordResponse } from 'state/actions/forgot-password';
import type { HelpCenterResponse } from 'state/actions/help-center';
import type { LoginResponse } from 'state/actions/login';
import type { UnsubscribedConfirmationResponse } from 'state/actions/unsubscribed-confirmation';
import { isKoshEnabledForDefaultHelpCenter } from '../advanced-help-center';

export interface InitialModelData {
    user?: UserResponse;
    helpCenterBranding?: HCBrandingResponse;
    sharedPortal?: HelpCenterResponse;
    unsubscribedConfirmation?: UnsubscribedConfirmationResponse;
    login?: LoginResponse;
    forgotPassword?: ForgotPasswordResponse;
    reqCreate?: RequestCreateResponse;
    portal?: PortalResponse;
    reqDetails?: RequestDetailsResponse;
    kbPage?: ArticleResponse;
    timezoneRegions?: TimezoneRegionResponse[];
    timezones?: TimezoneResponse[];
    availableLanguages?: LanguageResponse[];
    profileWebFragments?: ProfileConnectFragmentsResponse;
    portalWebFragments?: ConnectFragmentsResponse;
    allReqFilter?: RequestListResponse;
    requestsColumnSettings?: RequestsColumnSettingsResponse;
    organisations?: OrganisationsResponse;
    visitPortal?: VisitPortalResponse;
    resetPassword?: ResetPasswordResponse;
    completeSignup?: CompleteSignupResponse;
    verifyEmail?: VerifyEmailResponse;
    signUp?: SignUpResponse;
    branding?: PortalBrandingResponse;
    anonymousApproval?: AnonymousApprovalResponse;
    featuredAndSortedPortals?: FeaturedAndSortedPortalsResponse;
    loginAnnouncements?: LoginAnnouncementsResponse;
    aiAnswerFeedbackPage?: AiAnswerFeedbackResponse;
}

let cachedObject: InitialModelData;

export default () => {
    const jsonPayload = document.getElementById('jsonPayload');
    if (!cachedObject) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        cachedObject = (jsonPayload && jsonPayload.textContent && JSON.parse(jsonPayload.textContent)) || {};
    }
    if (isKoshEnabledForDefaultHelpCenter()) {
        delete cachedObject.helpCenterBranding;
        delete cachedObject.featuredAndSortedPortals;
        delete cachedObject.loginAnnouncements;
    }
    return cachedObject;
};

// Export this internally so we can delete it when testing.
export const deleteCachedObject = () => {
    cachedObject = undefined as unknown as InitialModelData;
};
