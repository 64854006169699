import * as React from 'react';
import { di } from 'react-magnetic-di';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { Appearance } from './styled';
import { Card, Container, IconContainer } from './styled';
export interface CardLinkProps {
    to: string;
    children: React.ReactNode;
    padding?: number;
    className?: string;
    appearance?: Appearance;
    actionSubjectId: string;
    dataTestId?: string;
    target?: '_self' | '_blank' | '_parent' | '_top';
    onClick?: () => void;
}

const CardLink: React.FunctionComponent<CardLinkProps> = ({
    children,
    to,
    padding,
    className,
    appearance,
    actionSubjectId,
    dataTestId,
    target,
    onClick,
}) => {
    di(useIsPageInEditMode);
    const [isPageInEditMode] = useIsPageInEditMode();
    const handleClick = React.useCallback(() => {
        if (isPageInEditMode) {
            return;
        }
        onClick?.();
    }, [isPageInEditMode, onClick]);
    const content = (
        <Card
            to={to}
            padding={padding}
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            className={className}
            appearance={appearance}
            actionSubjectId={actionSubjectId}
            data-test-id={dataTestId}
            target={target}
            onClick={handleClick}
            tabIndex={isPageInEditMode ? -1 : undefined}
        >
            <Container>
                {children}

                <IconContainer>
                    <ChevronRightLargeIcon
                        label=""
                        aria-hidden="true"
                        primaryColor={token('color.text', colors.N800)}
                    />
                </IconContainer>
            </Container>
        </Card>
    );
    return isPageInEditMode ? <Box xcss={disabledCard}>{content}</Box> : content;
};

CardLink.defaultProps = {
    appearance: 'gray',
};

const disabledCard = xcss({
    pointerEvents: 'none',
});

export default CardLink;
