import type { PortalSearchResponse, RequestTypeSearchResponse } from 'rest/search';
import type {
    ShowSearchAction,
    SearchAction,
    ResetSearchAction,
    UpdateSearchResultsAction,
    ClearSearchResultsAction,
    FetchSearchResultsAction,
    FetchKbSearchAction,
    SearchResultResponse,
    UpdateSearchResultsByTypeAction,
    UpdateSearchAction,
} from 'state/actions/search';
import {
    SHOW_SEARCH,
    SEARCH,
    RESET_SEARCH,
    UPDATE_SEARCH_RESULTS,
    CLEAR_SEARCH_RESULTS,
    FETCH_SEARCH_RESULTS,
    FETCH_KB_SEARCH_RESULTS,
    UPDATE_SEARCH_RESULTS_BY_TYPE,
    UPDATE_SEARCH,
} from 'state/actions/search';
import { contextPath } from '@atlassian/help-center-common-util/history';
import * as qs from '@atlassian/help-center-common-util/history-qs';
import type { PortalInfo } from 'rest/search/kbs/types';

export interface KnowledgeArticle {
    id: string;
    name: string;
    isSmartSnippet: boolean;
    pageVersion: number;
    score: number;
    articleSnippet: string;
    linkedPortals?: PortalInfo[];
}

export interface KnowledgeBaseSearchResult {
    articles: KnowledgeArticle[];
    totalSize: number;
    portalName?: string;
}

export interface RequestTypeSearchResult {
    id: number;
    portalId: number;
    name: string;
    portalName: string;
    iconUrl: string;
    searchMetaData: string;
}

export interface PortalSearchResult {
    id: number;
    name: string;
    iconUrl: string;
}

export interface SearchResults {
    portals: PortalSearchResult[];
    requestTypes: RequestTypeSearchResult[];
    knowledgeBase: KnowledgeBaseSearchResult;
}

export interface SearchState {
    shown: boolean;
    term: string;
    time: number | undefined;
    isSearching: boolean;
    hasError: boolean;
    results: SearchResults;
    isSearchingPortals?: boolean;
    isSearchingKb?: boolean;
    isSearchingRequestTypes?: boolean;
    searchStartTime?: number;
}

const generateDefaultSearchResults = () => {
    return {
        portals: [],
        requestTypes: [],
        knowledgeBase: {
            articles: [],
            totalSize: 0,
        },
    };
};

const generateDefaultState = () => {
    const strings = qs.getAll();

    if (strings && strings.q !== undefined) {
        return {
            shown: true,
            term: strings.q,
            time: strings.q_time ? +strings.q_time : undefined,
            hasError: false,
            isSearching: true,
            results: generateDefaultSearchResults(),
            isSearchingPortals: true,
            isSearchingKb: true,
            isSearchingRequestTypes: true,
        };
    }

    return {
        shown: false,
        term: '',
        portalIds: [],
        time: undefined,
        hasError: false,
        isSearching: false,
        results: generateDefaultSearchResults(),
    };
};

const portalResponseToState: (portal: PortalSearchResponse) => PortalSearchResult = (portal) => ({
    id: portal.id,
    name: portal.name,
    iconUrl: portal.logoUrl,
});

const requestTypesResponseToState: (requestType: RequestTypeSearchResponse) => RequestTypeSearchResult = (
    requestType
) => ({
    id: requestType.id,
    portalId: requestType.cvId,
    name: requestType.name,
    portalName: requestType.portalName,
    iconUrl:
        requestType.iconUrl ||
        `${contextPath}/servicedesk/customershim/secure/viewavatar?avatarType=SD_REQTYPE&avatarId=${requestType.icon}`,
    searchMetaData: requestType.searchMetaData,
});

type HandledActions =
    | ShowSearchAction
    | SearchAction
    | ResetSearchAction
    | UpdateSearchResultsAction
    | ClearSearchResultsAction
    | UpdateSearchResultsByTypeAction
    | FetchSearchResultsAction
    | FetchKbSearchAction
    | UpdateSearchAction;

export default function reducer(state: SearchState = generateDefaultState(), action: HandledActions) {
    switch (action.type) {
        case SHOW_SEARCH:
            return {
                ...state,
                shown: action.payload.show,
                searchStartTime: undefined,
            };

        case RESET_SEARCH:
            return {
                shown: false,
                term: '',
                time: undefined,
                hasError: false,
                isSearching: false,
                results: generateDefaultSearchResults(),
            };
        case SEARCH:
            return {
                ...state,
                ...action.payload,
                isSearching: true,
                shown: action.payload.show,
                time: action.payload.term ? action.payload.time : undefined,
                isSearchingPortals: true,
                isSearchingKb: true,
                isSearchingRequestTypes: true,
            };

        case FETCH_KB_SEARCH_RESULTS:
            return {
                ...state,
                isSearchingKb: true,
            };
        case UPDATE_SEARCH_RESULTS: {
            const result = action.payload;
            const newResultState: SearchResults = {
                portals: result.portals ? result.portals.map(portalResponseToState) : [],
                requestTypes: result.requestTypes ? result.requestTypes.map(requestTypesResponseToState) : [],
                knowledgeBase: mapKnowledgeBaseResponseToState(result),
            };

            return { ...state, results: newResultState, isSearching: false };
        }
        case UPDATE_SEARCH_RESULTS_BY_TYPE: {
            const { searchType, payload: result } = action;

            let newResultState;
            if (searchType === 'portals') {
                newResultState = {
                    results: {
                        ...state.results,
                        portals: result.portals ? result.portals.map(portalResponseToState) : [],
                    },
                    isSearchingPortals: false,
                };
            }

            if (searchType === 'requestTypes') {
                newResultState = {
                    results: {
                        ...state.results,
                        requestTypes: result.requestTypes ? result.requestTypes.map(requestTypesResponseToState) : [],
                    },
                    isSearchingRequestTypes: false,
                };
            }

            if (searchType === 'kbs') {
                newResultState = {
                    results: {
                        ...state.results,
                        knowledgeBase: mapKnowledgeBaseResponseToState(result),
                    },
                    isSearchingKb: false,
                };
            }

            return {
                ...state,
                ...(newResultState ?? {}),
                isSearching: newResultState?.isSearchingKb === false ? false : state.isSearching,
            };
        }
        case CLEAR_SEARCH_RESULTS: {
            return {
                ...state,
                isSearching: false,
                results: generateDefaultSearchResults(),
                searchStartTime: undefined,
            };
        }
        case FETCH_SEARCH_RESULTS: {
            return { ...state, searchStartTime: action.payload.searchStartTime };
        }
        case UPDATE_SEARCH: {
            return {
                ...state,
                ...action.payload,
                isSearching: true,
                shown: action.payload.show,
                time: action.payload.term ? action.payload.time : undefined,
            };
        }
        default:
            return state;
    }
}

const mapKnowledgeBaseResponseToState = (result: SearchResultResponse): KnowledgeBaseSearchResult => {
    return result.kbs
        ? result.kbs
        : {
              articles: [],
              totalSize: 0,
          };
};
