import { initialModel } from '@atlassian/help-center-common-util/model';

export interface ResetPasswordState {
    emailAddress: string | null | undefined;
    username: string | null | undefined;
}

const defaultState: ResetPasswordState = {
    emailAddress: undefined,
    username: undefined,
};

export const getInitialState = (): ResetPasswordState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.resetPassword) {
        return {
            ...defaultState,
            ...initialModelState.resetPassword,
        };
    }
    return defaultState;
};

export const resetPasswordReducer = (state: ResetPasswordState = getInitialState()): ResetPasswordState => state;
