import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { SPINNER_DELAY } from '@atlassian/help-center-common-component/constants';
import messages from './messages';
import { AbsoluteFillContainer, LoadingSkeletonFields } from './styled';

export type LoadingPosition = 'static' | 'fill-container';

interface Props {
    loadingPosition?: LoadingPosition;
    isLoading?: boolean;
    pastDelay?: boolean;
    timedOut?: boolean;
    error?: Error;
    /* interactionName for the new UFO
     * Should be made mandatory once we
     * start covering all experiences.
     */
    interactionName?: string;
}

const ICON_SIZE = grid.multiple(6);

export const ChunkLoadErrorMessage: React.FC = () => {
    return (
        <>
            <ErrorIcon label="" size="xlarge" />
            <Message>
                <FormattedMessage {...messages.error} />
            </Message>
        </>
    );
};

// eslint-disable-next-line rulesdir/no-styled-export, rulesdir/styled-component-order, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const MarginTop = styled.div`
    margin-top: ${token('space.025', '2px')};
`;

export const SkeletonLoadingWrapper = (props: Props) => {
    let content;

    if (props.error) {
        content = <ChunkLoadErrorMessage />;
    } else {
        content = (
            <>
                <LoadingSkeletonFields />

                {props.timedOut && (
                    <Message>
                        <FormattedMessage {...messages.timeout} />
                    </Message>
                )}
            </>
        );
    }

    const Wrapper = props.loadingPosition === 'static' ? AbsoluteFillContainer : Container;

    return (
        <MarginTop>
            <Wrapper>{content}</Wrapper>
        </MarginTop>
    );
};

const Loading = (props: Props) => {
    let content;

    if (props.error) {
        content = <ChunkLoadErrorMessage />;
    } else {
        content = (
            <>
                <Spinner
                    interactionName={props.interactionName || 'help-center-unknown-loader'}
                    delay={SPINNER_DELAY}
                    appearance="inherit"
                    size={ICON_SIZE.unitless}
                />

                {props.timedOut && (
                    <Message>
                        <FormattedMessage {...messages.timeout} />
                    </Message>
                )}
            </>
        );
    }

    const Wrapper = props.loadingPosition === 'static' ? AbsoluteFillContainer : Container;

    return <Wrapper data-testid="loading-container">{content}</Wrapper>;
};

export default Loading;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    ${mixins.flexCenter};
    padding: ${token('space.100', '8px')};
    flex-direction: column;
    pointer-events: none;
    flex: 1 1 auto;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Message = styled.div`
    ${fonts.small};
    margin-top: ${token('space.100', '8px')};
`;
