const isGlobalDefined = (objName: string) => {
    // @ts-ignore TS(7015) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    return window[objName] !== undefined;
};

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line @typescript-eslint/ban-types
export const awaitGlobal = (objectName: string, cb: Function) => {
    if (isGlobalDefined(objectName)) {
        cb();
    } else {
        const intervalTime = 100;
        let loopCounter = 0;
        const awaitInterval = setInterval(() => {
            if (isGlobalDefined(objectName)) {
                clearInterval(awaitInterval);
                cb();
            }

            loopCounter += 1;
            // Loop will run for maximum of 10 seconds
            if (loopCounter > 100) {
                clearInterval(awaitInterval);
            }
        }, intervalTime);
    }
};
