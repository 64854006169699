import type { Epic } from 'epics/rxjs';
import { Observable, forkJoin, concat } from 'epics/rxjs';
import type { CategoriesResponse } from 'rest/category';
import { fetchCategories } from 'rest/category';
import { fetchArticlesForCategory } from 'rest/kb-article';
import type { ArticlesResponse } from 'rest/kb-article';
import type { FetchArticlesSuccess, FetchArticlesFailure } from 'state/actions/fetch-kb-articles';
import { fetchArticlesSuccess, fetchArticlesFailure } from 'state/actions/fetch-kb-articles';
import type { FetchCategoriesSuccess, FetchCategoriesFailure } from 'state/actions/kb-category';
import { fetchCategoriesSuccess, fetchCategoriesFailure } from 'state/actions/kb-category';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import type { ApolloErrorResponse } from '../types';
import type { FetchArticlesAction } from './actions';
import { FETCH_ARTICLES } from './actions';

const categoryArticlesEpic: Epic<
    FetchArticlesAction,
    FetchCategoriesSuccess | FetchCategoriesFailure | FetchArticlesSuccess | FetchArticlesFailure
> = (action$) => {
    return action$.ofType(FETCH_ARTICLES).mergeMap((action) => {
        const { projectId, categoryId, portalId, categoryDataRequired, meta } = action.payload;

        if (categoryDataRequired) {
            // parallelize categories request with articles, used on initial category page load, ensures we always have the necessary category data
            return forkJoin(
                fetchArticlesForCategory({ projectId, categoryId })
                    .map((articlesResponse: ArticlesResponse) => fetchArticlesSuccess(articlesResponse, categoryId))
                    .catch((error: ApolloErrorResponse) => {
                        if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.statusCode)) {
                            sendEvent(meta.analyticsFailureEvent);
                        }
                        return Observable.of(fetchArticlesFailure(error, portalId, categoryId));
                    }),
                fetchCategories({ projectId })
                    .map((categoriesResponse: CategoriesResponse) =>
                        fetchCategoriesSuccess(categoriesResponse, portalId)
                    )
                    .catch((error: ApolloErrorResponse) => {
                        if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.statusCode)) {
                            sendEvent(meta.analyticsFailureEvent);
                        }
                        return Observable.of(fetchCategoriesFailure(error, portalId));
                    })
            ).mergeMap(
                ([articlesSuccessOrFailure, categoriesSuccessOrFailure]: [
                    FetchArticlesSuccess | FetchArticlesFailure,
                    FetchCategoriesSuccess | FetchCategoriesFailure,
                ]) => concat(Observable.of(articlesSuccessOrFailure), Observable.of(categoriesSuccessOrFailure))
            );
        }

        return fetchArticlesForCategory({ projectId, categoryId })
            .map((response: ArticlesResponse) => {
                return fetchArticlesSuccess(response, categoryId);
            })
            .catch((error: ApolloErrorResponse) => {
                if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.statusCode)) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                return Observable.of(fetchArticlesFailure(error, portalId, categoryId));
            });
    });
};

export default categoryArticlesEpic;
