import type { ASSISTANT_MESSAGE_STATUS } from 'unified-help-center-alpha/src/rest/converse';
import type {
    JsmChatCreateWebConversationUserRole,
    omnichannelCreateMessageMutation$data,
} from '../controllers/omnichannel-create-message/__generated__/omnichannelCreateMessageMutation.graphql';
import type { JsmChatWebConversationActions } from '../controllers/omnichannel-subscription/__generated__/omnichannelSubscription.graphql';

export interface CreateConversationMessageArgs {
    message: string;
    connectionId?: string;
    onError?: (error: Error) => void;
    onCompleted?: (response: omnichannelCreateMessageMutation$data) => void;
}

export type AuthorRole = JsmChatCreateWebConversationUserRole;

// eslint-disable-next-line no-shadow
export enum HelpSeekerMessageStatus {
    IDLE = 'helpseeker_idle',
}

export type MessageStatus = HelpSeekerMessageStatus | ASSISTANT_MESSAGE_STATUS;

export type AppendixMessageActions = JsmChatWebConversationActions | null | undefined;
