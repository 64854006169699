import type { Props as BannerWithPaperLayoutRouteDumbProps } from 'view/layout/banner-with-paper-layout-dumb';
export const PORTAL_ROUTE_PREFIX = '/portal/:portalId(\\d+)';
export const LOGIN_ROUTE = '/user/login';
export const PROJECT_NAME = 'jira-servicedesk.ondemand';

type LayoutProps = Omit<
    BannerWithPaperLayoutRouteDumbProps,
    'children' | 'path' | 'headerless' | 'helpCenterBanner' | 'searchShown'
>;

export const anonymousScreenLayout: LayoutProps = Object.freeze({
    isAnonymous: true,
    hideSearchButton: true,
    paperAppearance: 'note',
    fadeInSearchModal: true,
});
