import * as React from 'react';
import { fixExternalLinkBug } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { open } from '@atlassian/help-center-common-util/location';
import type { Props as GroupBoxProps } from '../group-box';
import GroupBox from '../group-box';
import { StyledLink } from '../styled';

export interface BaseGroupBoxLinkProps extends GroupBoxProps {
    selected?: boolean;
    onClick?: () => void;
    icon?: React.ReactNode;
    target?: '_blank' | '_self' | '_parent' | '_top';
    actionSubjectId: string;
    dataTestId?: string;
}

interface InternalGroupBoxLinkProps extends BaseGroupBoxLinkProps {
    to: string;
}

interface ExternalGroupBoxLinkProps extends BaseGroupBoxLinkProps {
    href: string;
}

type GroupBoxLinkProps = InternalGroupBoxLinkProps | ExternalGroupBoxLinkProps;

const GroupBoxLink: React.FunctionComponent<GroupBoxLinkProps & WithAnalyticsEventsProps> = ({
    className,
    selected,
    onClick,
    target,
    dataTestId,
    actionSubjectId,
    ...rest
}) => {
    di(useIsPageInEditMode);
    const [isPageInEditMode] = useIsPageInEditMode();
    const handleClick = React.useCallback(
        (e: React.MouseEvent | React.KeyboardEvent) => {
            if (isPageInEditMode) {
                return;
            }
            if (
                fixExternalLinkBug() &&
                e.target instanceof HTMLElement &&
                e.target.className.includes('external-link')
            ) {
                e.preventDefault();
                open(e.target.toString(), '_blank');
                return;
            }
            onClick?.();
        },
        [isPageInEditMode, onClick]
    );
    if ('to' in rest) {
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line no-shadow
        const { to, ...forGroupBox } = rest;
        const contentWithTo = (
            <StyledLink
                selected={!!selected}
                to={to}
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                className={className}
                onClick={handleClick}
                target={target}
                actionSubjectId={actionSubjectId}
                data-test-id={dataTestId}
                tabIndex={isPageInEditMode ? -1 : undefined}
            >
                <GroupBox {...forGroupBox} />
            </StyledLink>
        );
        return isPageInEditMode ? <Box xcss={disabledLink}>{contentWithTo}</Box> : contentWithTo;
    }
    const { href, ...forGroupBox } = rest;
    const content = (
        <StyledLink
            selected={!!selected}
            href={href}
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            className={className}
            onClick={handleClick}
            target={target}
            actionSubjectId={actionSubjectId}
            data-test-id={dataTestId}
            tabIndex={isPageInEditMode ? -1 : undefined}
        >
            <GroupBox {...forGroupBox} />
        </StyledLink>
    );
    return isPageInEditMode ? <Box xcss={disabledLink}>{content}</Box> : content;
};

const disabledLink = xcss({
    pointerEvents: 'none',
});

export default GroupBoxLink;
