/**
 * @generated SignedSource<<91f9dba66f0e19654e31b0a2752cecae>>
 * @relayHash f6ad0a674b4deb767396c24696d6bb4a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID da8c746423a9772dac447f2fdbad8fd4ca55ac3024e4850bb499f3e66949b60d

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterProjectMappingOperationType = "MAP_PROJECTS" | "UNMAP_PROJECTS" | "%future added value";
export type HelpCenterProjectMappingUpdateInput = {
  helpCenterAri: string;
  operationType?: HelpCenterProjectMappingOperationType | null | undefined;
  projectIds?: ReadonlyArray<string> | null | undefined;
  syncNewProjects?: boolean | null | undefined;
};
export type syncProjectsToggleMutation$variables = {
  cloudId: string;
  input: HelpCenterProjectMappingUpdateInput;
};
export type syncProjectsToggleMutation$data = {
  readonly helpCenter: {
    readonly updateProjectMapping: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type syncProjectsToggleMutation = {
  response: syncProjectsToggleMutation$data;
  variables: syncProjectsToggleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "cloudId",
        "variableName": "cloudId"
      }
    ],
    "concreteType": "HelpCenterMutationApi",
    "kind": "LinkedField",
    "name": "helpCenter",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "HelpCenterProjectMappingUpdatePayload",
        "kind": "LinkedField",
        "name": "updateProjectMapping",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "syncProjectsToggleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "syncProjectsToggleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "da8c746423a9772dac447f2fdbad8fd4ca55ac3024e4850bb499f3e66949b60d",
    "metadata": {},
    "name": "syncProjectsToggleMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f63e31632e8452ce88ba847207c9b4ff";

export default node;
