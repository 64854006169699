import { defineMessages } from 'react-intl';

export default defineMessages({
    defaultTitle: {
        defaultMessage: 'Jira Service Management',
        description: 'Default document page title',
        id: 'cv.jsm.document.page.title.default',
    },
    baseTitle: {
        defaultMessage: '{title} - Jira Service Management',
        description: 'Base document page title appended to custom document titles.',
        id: 'cv.jsm.document.page.title.base',
    },
});
