import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import type { RecordSourceProxy, RecordSourceSelectorProxy } from 'relay-runtime';
import { ConnectionHandler, generateUniqueClientID } from 'relay-runtime';

import { HTTP } from '@atlassian/help-center-common-component/constants';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getCloudId, getWorkspaceAri } from '@atlassian/help-center-common-util/meta';
import type {
    useHelpCenterCreateMutation,
    useHelpCenterCreateMutation$data,
} from './__generated__/useHelpCenterCreateMutation.graphql';
import type {
    useHelpCenterDeleteMutation,
    useHelpCenterDeleteMutation$data,
} from './__generated__/useHelpCenterDeleteMutation.graphql';
import type {
    useHelpCenterUpdateMutation,
    useHelpCenterUpdateMutation$data,
} from './__generated__/useHelpCenterUpdateMutation.graphql';

interface UseHelpCenterProps {
    createHelpCenter: ({
        helpCenterName,
        helpCenterSlug,
    }: {
        helpCenterName: string;
        helpCenterSlug: string;
    }) => Promise<useHelpCenterCreateMutation$data>;
    updateHelpCenter: ({
        helpCenterAri,
        helpCenterTranslations,
        helpCenterName,
        helpCenterSlug,
    }: {
        helpCenterAri: string;
        helpCenterTranslations?: {
            locale: string;
            value: string;
        }[];
        helpCenterName?: string;
        helpCenterSlug?: string;
    }) => Promise<useHelpCenterUpdateMutation$data>;
    deleteHelpCenter: ({ helpCenterAri }: { helpCenterAri: string }) => Promise<useHelpCenterDeleteMutation$data>;
}

export const useHelpCenter = (): UseHelpCenterProps => {
    const cloudId = getCloudId();
    const workspaceAri = getWorkspaceAri();

    const [helpCenterCreateMutation] = useMutation<useHelpCenterCreateMutation>(graphql`
        mutation useHelpCenterCreateMutation($cloudId: ID!, $input: HelpCenterCreateInput!) {
            helpCenter(cloudId: $cloudId) {
                createHelpCenter(input: $input) {
                    success
                    errors {
                        message
                        extensions {
                            statusCode
                        }
                    }
                    helpCenterAri
                }
            }
        }
    `);

    const [helpCenterUpdateMutation] = useMutation<useHelpCenterUpdateMutation>(graphql`
        mutation useHelpCenterUpdateMutation($cloudId: ID!, $input: HelpCenterUpdateInput!) {
            helpCenter(cloudId: $cloudId) {
                updateHelpCenter(input: $input) {
                    success
                    errors {
                        message
                        extensions {
                            statusCode
                        }
                    }
                }
            }
        }
    `);

    const [helpCenterDeleteMutation] = useMutation<useHelpCenterDeleteMutation>(graphql`
        mutation useHelpCenterDeleteMutation($cloudId: ID!, $input: HelpCenterDeleteInput!) {
            helpCenter(cloudId: $cloudId) {
                deleteHelpCenter(input: $input) {
                    success
                    errors {
                        message
                    }
                }
            }
        }
    `);

    const getHelpCenter = useCallback(
        (store: RecordSourceSelectorProxy | RecordSourceProxy) => {
            const root = store.getRoot();
            const helpCenter = root.getLinkedRecord('helpCenter', {
                cloudId,
            });
            return helpCenter;
        },
        [cloudId]
    );

    const getHelpCenterList = useCallback(
        (store: RecordSourceSelectorProxy | RecordSourceProxy) => {
            const helpCenter = getHelpCenter(store);
            if (helpCenter?.getDataID()) {
                const parent = store.get(helpCenter?.getDataID());
                if (parent)
                    return ConnectionHandler.getConnection(parent, 'helpCentersManagementPageFragment_helpCenters');
            }

            return null;
        },
        [getHelpCenter]
    );

    const getHelpCenters = useCallback(
        (store: RecordSourceSelectorProxy | RecordSourceProxy) => {
            const helpCenter = getHelpCenter(store);
            if (helpCenter?.getDataID()) {
                const parent = store.get(helpCenter?.getDataID());
                if (parent)
                    return ConnectionHandler.getConnection(
                        parent,
                        'helpCentersManagementPageFragment_helpCenters'
                    )?.getLinkedRecords('edges');
            }

            return null;
        },
        [getHelpCenter]
    );

    const deleteHelpCenter = useCallback(
        ({ helpCenterAri }: { helpCenterAri: string }) => {
            return new Promise<useHelpCenterDeleteMutation$data>((resolve, reject) =>
                helpCenterDeleteMutation({
                    variables: {
                        cloudId,
                        input: {
                            helpCenterAri,
                        },
                    },
                    updater: (store, response) => {
                        if (response?.helpCenter?.deleteHelpCenter?.success) {
                            const helpCenters = getHelpCenters(store);
                            const helpCentersList = getHelpCenterList(store);
                            if (helpCenters && helpCentersList) {
                                const updatedHelpCenters = [
                                    ...helpCenters.filter((helpCenterRecord) => {
                                        const node = helpCenterRecord.getLinkedRecord('node');
                                        return node?.getValue('id') !== helpCenterAri;
                                    }),
                                ];
                                helpCentersList?.setLinkedRecords(updatedHelpCenters, 'edges');
                            }
                        }
                    },
                    onCompleted(response) {
                        if (response.helpCenter?.deleteHelpCenter?.success) {
                            return resolve(response);
                        }

                        const errors = response.helpCenter?.deleteHelpCenter?.errors;
                        const error = errors && errors.length > 0 ? errors[0] : {};
                        return reject(error);
                    },
                    onError: (error) => reject(error),
                })
            );
        },
        [cloudId, getHelpCenterList, getHelpCenters, helpCenterDeleteMutation]
    );

    const createHelpCenter = useCallback(
        ({ helpCenterName, helpCenterSlug }: { helpCenterName: string; helpCenterSlug: string }) => {
            return new Promise<useHelpCenterCreateMutation$data>((resolve, reject) =>
                helpCenterCreateMutation({
                    variables: {
                        cloudId,
                        input: {
                            workspaceARI: workspaceAri,
                            name: {
                                default: helpCenterName,
                                translations: [
                                    {
                                        locale: getEnv().siteDefaultLanguageTag,
                                        value: helpCenterName,
                                    },
                                ],
                            },
                            slug: helpCenterSlug,
                        },
                    },
                    updater: (store, response) => {
                        if (
                            response?.helpCenter?.createHelpCenter?.success &&
                            response?.helpCenter?.createHelpCenter?.helpCenterAri
                        ) {
                            const helpCenters = getHelpCenters(store);
                            const helpCenterList = getHelpCenterList(store);
                            const node = store.create(generateUniqueClientID(), 'node');
                            const helpCenterRecord = store.create(generateUniqueClientID(), 'HelpCenter');
                            const name = store.create(generateUniqueClientID(), 'HelpCenterName');
                            const branding = store.create(generateUniqueClientID(), 'HelpCenterBranding');
                            name.setValue(helpCenterName, 'default');
                            branding.setValue(true, 'useDefaultBanner');
                            helpCenterRecord.setLinkedRecord(name, 'name');
                            helpCenterRecord.setValue(helpCenterSlug, 'slug');
                            const date = new Date().toISOString();
                            helpCenterRecord?.setValue(date, 'lastUpdated');
                            helpCenterRecord.setValue(response.helpCenter?.createHelpCenter?.helpCenterAri, 'id');
                            helpCenterRecord.setLinkedRecord(branding, 'helpCenterBranding');
                            node.setLinkedRecord(helpCenterRecord, 'node');
                            const updatedHelpCenters = [node, ...(helpCenters ?? [])];
                            if (helpCenters && helpCenterList) {
                                helpCenterList.setLinkedRecords(updatedHelpCenters, 'edges');
                            }
                        }
                    },
                    onCompleted(response) {
                        if (response.helpCenter?.createHelpCenter?.success) {
                            return resolve(response);
                        }
                        const errors = response.helpCenter?.createHelpCenter?.errors;
                        const error =
                            errors && errors.length > 0
                                ? errors[0]
                                : {
                                      message: '',
                                      extensions: {
                                          statusCode: HTTP.SERVER,
                                      },
                                  };
                        const throwableError = {
                            message: error.message,
                            status: error.extensions?.statusCode,
                        };
                        return reject(throwableError);
                    },
                    onError: (error) => reject(error),
                })
            );
        },
        [cloudId, getHelpCenterList, getHelpCenters, helpCenterCreateMutation, workspaceAri]
    );

    const updateHelpCenter = useCallback(
        ({
            helpCenterAri,
            helpCenterTranslations,
            helpCenterName,
            helpCenterSlug,
        }: {
            helpCenterAri: string;
            helpCenterTranslations?: {
                locale: string;
                value: string;
            }[];
            helpCenterName?: string;
            helpCenterSlug?: string;
        }) => {
            return new Promise<useHelpCenterUpdateMutation$data>((resolve, reject) =>
                helpCenterUpdateMutation({
                    variables: {
                        cloudId,
                        input: {
                            helpCenterAri,
                            name: helpCenterName
                                ? {
                                      default: helpCenterName,
                                      translations:
                                          helpCenterTranslations && helpCenterTranslations.length > 0
                                              ? helpCenterTranslations.map((translations) => {
                                                    if (translations.locale === getEnv().siteDefaultLanguageTag) {
                                                        translations.value = helpCenterName;
                                                    }
                                                    return translations;
                                                })
                                              : [
                                                    {
                                                        locale: getEnv().siteDefaultLanguageTag,
                                                        value: helpCenterName,
                                                    },
                                                ],
                                  }
                                : undefined,
                            slug: helpCenterSlug ? helpCenterSlug : undefined,
                        },
                    },
                    updater: (store, response) => {
                        if (response?.helpCenter?.updateHelpCenter?.success) {
                            const helpCenters = getHelpCenters(store);
                            const currentHelpCenter = helpCenters?.find((helpCenter) => {
                                const node = helpCenter.getLinkedRecord('node');
                                return node?.getValue('id') === helpCenterAri;
                            });

                            const currentHelpCenterNode = currentHelpCenter?.getLinkedRecord('node');
                            if (helpCenterName) {
                                const name = currentHelpCenterNode?.getLinkedRecord('name');
                                name?.setValue(helpCenterName, 'default');
                            }
                            if (helpCenterSlug) {
                                currentHelpCenterNode?.setValue(helpCenterSlug, 'slug');
                            }
                            const date = new Date().toISOString();
                            currentHelpCenterNode?.setValue(date, 'lastUpdated');
                        }
                    },
                    onCompleted(response) {
                        if (response.helpCenter?.updateHelpCenter?.success) {
                            return resolve(response);
                        }

                        const errors = response.helpCenter?.updateHelpCenter?.errors;
                        const error =
                            errors && errors.length > 0
                                ? errors[0]
                                : {
                                      message: '',
                                      extensions: {
                                          statusCode: HTTP.SERVER,
                                      },
                                  };
                        const throwableError = {
                            message: error.message,
                            status: error.extensions?.statusCode,
                        };
                        return reject(throwableError);
                    },
                    onError: (error) => reject(error),
                })
            );
        },
        [cloudId, getHelpCenters, helpCenterUpdateMutation]
    );

    return { createHelpCenter, updateHelpCenter, deleteHelpCenter };
};
