/**
 * @generated SignedSource<<bbf3125ec58826dddbea0fb19a89bde4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreSearchBackend = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type articlesFragment$data = {
  readonly articles: ReadonlyArray<{
    readonly __typename: "HelpObjectStoreSearchResult";
    readonly id?: string;
    readonly isExternal?: boolean;
    readonly message?: string | null | undefined;
    readonly searchBackend?: HelpObjectStoreSearchBackend | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"articleItemFragment">;
  } | null | undefined>;
  readonly " $fragmentType": "articlesFragment";
};
export type articlesFragment$key = {
  readonly " $data"?: articlesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"articlesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "categoryIds"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "helpCenterAri"
    },
    {
      "kind": "RootArgument",
      "name": "portalIds"
    },
    {
      "kind": "RootArgument",
      "name": "queryTerm"
    },
    {
      "kind": "RootArgument",
      "name": "resultLimit"
    }
  ],
  "kind": "Fragment",
  "name": "articlesFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "articles",
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "categoryIds",
                "variableName": "categoryIds"
              },
              {
                "kind": "Variable",
                "name": "cloudId",
                "variableName": "cloudId"
              },
              {
                "kind": "Literal",
                "name": "entityType",
                "value": "ARTICLE"
              },
              {
                "kind": "Variable",
                "name": "helpCenterAri",
                "variableName": "helpCenterAri"
              },
              {
                "kind": "Variable",
                "name": "portalIds",
                "variableName": "portalIds"
              },
              {
                "kind": "Variable",
                "name": "queryTerm",
                "variableName": "queryTerm"
              },
              {
                "kind": "Variable",
                "name": "resultLimit",
                "variableName": "resultLimit"
              }
            ],
            "kind": "ObjectValue",
            "name": "searchInput"
          }
        ],
        "kind": "LinkedField",
        "name": "searchHelpObjects",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                "action": "THROW",
                "path": "articles.__typename"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "ScalarField",
                  "name": "id"
                },
                "action": "THROW",
                "path": "articles.id"
              },
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "ScalarField",
                  "name": "isExternal"
                },
                "action": "THROW",
                "path": "articles.isExternal"
              },
              {
                "kind": "ScalarField",
                "name": "searchBackend"
              },
              {
                "kind": "FragmentSpread",
                "name": "articleItemFragment"
              }
            ],
            "type": "HelpObjectStoreSearchResult"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ],
            "type": "HelpObjectStoreQueryError"
          }
        ]
      },
      "action": "THROW",
      "path": "articles"
    }
  ],
  "type": "HelpObjectStoreQueryApi"
};

(node as any).hash = "157e9b497d6f34b073aad6e1c8ffbeb2";

export default node;
