export const UPDATE_CONTEXT = 'state.ui.context.UPDATE_CONTEXT';
export const CLEAR_CONTEXT = 'state.ui.context.CLEAR_CONTEXT';

export interface PathParams {
    portalId?: string;
}

export interface Context {
    pathParams: PathParams;
    pathname?: string;
    routeName?: string;
}

export interface UpdateContextAction {
    type: typeof UPDATE_CONTEXT;
    payload: Context;
}

export interface ClearContextAction {
    type: typeof CLEAR_CONTEXT;
}

/**
 * Updates the context based on the path
 * @param pathParams a map containing the path params for the current url path (see the routes in app.tsx)
 */
export const updateContextAction = (context: Context): UpdateContextAction => ({
    type: UPDATE_CONTEXT,
    payload: context,
});

export const clearContextAction = (): ClearContextAction => ({
    type: CLEAR_CONTEXT,
});
