import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface AnonymousApprovalState {
    token: string;
    requestDetailsUrl: string;
    loggedIn: boolean;
    initialError?: PersistedError;
}

export type HandledActions = unknown;

const defaultState: AnonymousApprovalState = {
    token: '',
    requestDetailsUrl: '',
    loggedIn: false,
    initialError: undefined,
};

export const getInitialState = (): AnonymousApprovalState => {
    const initialModelState = initialModel();
    if (initialModelState?.anonymousApproval) {
        return initialModelState.anonymousApproval;
    }
    return defaultState;
};

export const anonymousApprovalReducer = (
    state: AnonymousApprovalState = getInitialState(),
    _: HandledActions
): AnonymousApprovalState => state;
