import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { fetchCrossSiteArticles } from 'rest/kb-cross-site-articles';
import type { FetchCrossSiteArticleResponse } from 'rest/kb-cross-site-articles';
import type {
    FetchArticleSuccessAction,
    FetchArticleFailureAction,
    FetchCrossSiteArticleAction,
} from 'state/actions/article';
import { FETCH_CROSS_SITE_ARTICLE, fetchArticleSuccess, fetchArticleFailure } from 'state/actions/article';
import { ConfluenceSpaceAri } from '@atlassian/ari';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import type { ErrorResponse } from 'epics/model/types';
import type { ArticleResponse } from 'rest/models/types';

export const transformResponse = (response: FetchCrossSiteArticleResponse, articleId: string): ArticleResponse => {
    const parseAri = ConfluenceSpaceAri.parse(response.space.id);

    return {
        id: parseInt(articleId, 10),
        title: response.title,
        url: '',
        projects: response.projects.map((project) => ({
            projectId: parseInt(project.projectId, 10),
            portalId: parseInt(project.portalId, 10),
            projectAri: project.projectAri,
        })),
        space: {
            key: response.space.key,
            id: parseInt(parseAri.spaceId, 10),
        },
        idInString: articleId,
        hostname: response.hostname,
    };
};

export const fetchCrossSiteArticleEpic: Epic<
    FetchCrossSiteArticleAction,
    FetchArticleSuccessAction | FetchArticleFailureAction
> = (action$) =>
    action$.ofType(FETCH_CROSS_SITE_ARTICLE).mergeMap((action) => {
        const { meta, articleId, projectId, crossSiteTenantId } = action.payload;
        return fetchCrossSiteArticles({ articleId, projectId, crossSiteTenantId: crossSiteTenantId || '' })
            .map((response: FetchCrossSiteArticleResponse) => {
                if (meta?.analyticsSuccessEvent) {
                    sendEvent(meta.analyticsSuccessEvent);
                }
                const articleResponse = transformResponse(response, articleId);
                return fetchArticleSuccess(articleResponse);
            })
            .catch((error: ErrorResponse) => {
                if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.status)) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                return Observable.of(fetchArticleFailure(articleId, error));
            });
    });
