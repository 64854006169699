import { buildModelsActionCreator } from 'epics/model/actions';
import type { ConnectPageName, ConnectFragmentsResponse, ProfileConnectFragmentsResponse } from 'rest/connect-addon';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_CONNECT_FRAGMENTS_SUCCESS = 'state.persisted.connect-addon.FETCH_CONNECT_FRAGMENTS_SUCCESS';
export const FETCH_CONNECT_FRAGMENTS_FAILURE = 'state.persisted.connect-addon.FETCH_CONNECT_FRAGMENTS_FAILURE';
export const FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS =
    'state.persisted.connect-addon.FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS';
export const FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE =
    'state.persisted.connect-addon.FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE';

export const FETCH_CONNECT_FRAGMENTS = 'epic.model.FETCH_CONNECT_FRAGMENTS';

export type FetchConnectFragmentsAction = ReturnType<typeof fetchConnectFragmentsAction>;

export const fetchConnectFragmentsAction = buildModelsActionCreator(FETCH_CONNECT_FRAGMENTS, 'portalWebFragments');

export const FETCH_PROFILE_CONNECT_FRAGMENTS = 'epic.model.FETCH_PROFILE_CONNECT_FRAGMENTS';

export type FetchProfileConnectFragmentsAction = ReturnType<typeof fetchProfileConnectFragmentsAction>;

export const fetchProfileConnectFragmentsAction = buildModelsActionCreator(
    FETCH_PROFILE_CONNECT_FRAGMENTS,
    'profileWebFragments'
);

export interface FetchConnectFragmentsSuccessParams {
    portalPageName: ConnectPageName;
    portalId: number | undefined;
    requestTypeId: number | undefined;
    connectFragments: ConnectFragmentsResponse;
    requestKey: string | undefined;
}

export interface FetchConnectFragmentsSuccess {
    type: typeof FETCH_CONNECT_FRAGMENTS_SUCCESS;
    payload: FetchConnectFragmentsSuccessParams;
}

export const fetchConnectFragmentsSuccess = ({
    portalPageName,
    portalId,
    requestTypeId,
    connectFragments,
    requestKey,
}: FetchConnectFragmentsSuccessParams): FetchConnectFragmentsSuccess => ({
    type: FETCH_CONNECT_FRAGMENTS_SUCCESS,
    payload: {
        portalPageName,
        portalId,
        requestTypeId,
        connectFragments,
        requestKey,
    },
});

export interface FetchConnectFragmentsFailureParams {
    portalPageName: ConnectPageName;
    portalId: number | undefined;
    requestTypeId: number | undefined;
    error: ErrorResponse;
    requestKey: string | undefined;
}

export interface FetchConnectFragmentsFailure {
    type: typeof FETCH_CONNECT_FRAGMENTS_FAILURE;
    payload: FetchConnectFragmentsFailureParams;
}

export const fetchConnectFragmentsFailure = ({
    portalPageName,
    portalId,
    requestTypeId,
    error,
    requestKey,
}: FetchConnectFragmentsFailureParams): FetchConnectFragmentsFailure => ({
    type: FETCH_CONNECT_FRAGMENTS_FAILURE,
    payload: {
        portalPageName,
        portalId,
        requestTypeId,
        error,
        requestKey,
    },
});

export interface FetchProfileConnectFragmentsSuccess {
    type: typeof FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS;
    payload: {
        fragments: ProfileConnectFragmentsResponse;
    };
}

export interface FetchProfileConnectFragmentsFailure {
    type: typeof FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchProfileConnectFragmentsSuccess = (
    fragments: ProfileConnectFragmentsResponse
): FetchProfileConnectFragmentsSuccess => ({
    type: FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS,
    payload: {
        fragments,
    },
});

export const fetchProfileConnectFragmentsFailure = (error: ErrorResponse): FetchProfileConnectFragmentsFailure => ({
    type: FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE,
    payload: { error },
});
