export {
    reload,
    replace,
    pathname,
    location,
    href,
    hash,
    origin,
    search,
    open,
    assign,
    isValidLocation,
} from './location';
