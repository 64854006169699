import * as React from 'react';

import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import type { SuccessFlagAction, dismissFlag, ActionsType } from 'state/actions/flags';
import type { Flag, FlagAction } from 'state/ui/flags';
import { FlagGroup, AutoDismissFlag } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import ErrorIcon from '@atlaskit/icon/glyph/cross-circle';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

interface FlagGroupStatelessProps {
    flags: Flag[];
    dismissFlagAction: typeof dismissFlag;
}

const isSuccessFlagAction = (flagAction: FlagAction): flagAction is SuccessFlagAction => {
    return 'contentNode' in flagAction && !!flagAction.contentNode;
};

export class FlagGroupStateless extends React.Component<FlagGroupStatelessProps & WrappedComponentProps> {
    handleFlagDismissed = (flagId: number) => {
        this.props.dismissFlagAction(flagId);
    };

    i18nFlagActions = (actions: FlagAction[]) => {
        const { intl } = this.props;

        return actions.map((action) => {
            return isSuccessFlagAction(action)
                ? {
                      content: action.contentNode,
                      href: action?.href,
                  }
                : {
                      content: intl.formatMessage(action.content),
                      href: action.href,
                  };
        });
    };

    getIcon = (flag: Flag) => {
        switch (flag.type) {
            case 'error':
                return <ErrorIcon primaryColor={token('color.icon.danger', colors.R500)} label="" aria-hidden="true" />;
            case 'success':
                return (
                    <SuccessIcon primaryColor={token('color.icon.success', colors.G300)} label="" aria-hidden="true" />
                );
            case 'bold':
                return (
                    <InfoIcon
                        secondaryColor={token('color.icon.information', colors.N500)}
                        label=""
                        aria-hidden="true"
                    />
                );
            default:
                return <ErrorIcon primaryColor={token('color.icon.danger', colors.R500)} label="" aria-hidden="true" />;
        }
    };

    renderFlag = (flag: Flag) => {
        const { intl } = this.props;
        if (flag.type === 'bold') {
            const dismissAction: ActionsType = [];
            if (flag.dismissActionValue) {
                dismissAction.push({
                    content: intl.formatMessage(flag.dismissActionValue),
                    onClick: () => {
                        this.handleFlagDismissed(flag.id);
                    },
                });
            }
            return (
                <AutoDismissFlag
                    appearance={flag.appearance}
                    id={flag.id}
                    key={flag.id}
                    icon={this.getIcon(flag)}
                    title={intl.formatMessage(flag.titleMessage, flag.titleMessageValues)}
                    description={
                        flag.descriptionMessage === undefined
                            ? undefined
                            : intl.formatMessage(flag.descriptionMessage, flag.descriptionMessageValues)
                    }
                    actions={[...flag.actions, ...dismissAction]}
                />
            );
        }
        return (
            <AutoDismissFlag
                id={flag.id}
                key={flag.id}
                icon={this.getIcon(flag)}
                title={intl.formatMessage(flag.titleMessage, flag.titleMessageValues)}
                description={
                    flag.descriptionMessage === undefined
                        ? undefined
                        : intl.formatMessage(flag.descriptionMessage, flag.descriptionMessageValues)
                }
                actions={this.i18nFlagActions(flag.actions)}
            />
        );
    };

    render() {
        const { flags } = this.props;
        return (
            <FlagGroup onDismissed={this.handleFlagDismissed}>{flags.map((flag) => this.renderFlag(flag))}</FlagGroup>
        );
    }
}

export default injectIntl(FlagGroupStateless);
