import * as React from 'react';
import type { ActionMeta, Option, SelectProps, ValueType } from '@atlaskit/select';
import type { SelectFieldFormProps } from '@atlassian/help-center-common-component/form-select/with-field-base';
import type { AddedAnalyticsProps } from '../../analytics/with-create-fire-ui-analytics';

export type FieldOption<V> = Option<V> & { isNew?: boolean };

interface State<V> {
    selectedOption?: ValueType<FieldOption<V>>;
}

function withControlledValueSingleSelect<
    V extends string | number,
    T extends SelectProps<FieldOption<V>, boolean> & SelectFieldFormProps = SelectProps<FieldOption<V>, boolean> &
        SelectFieldFormProps &
        AddedAnalyticsProps,
>(SelectComponent: React.ComponentType<T>) {
    // We remove the value props because we handle the value prop internally
    type Props = Omit<SelectFieldFormProps & T, 'value'>;
    return class ControlledValueSingleSelectWrapper extends React.PureComponent<Props, State<V>> {
        state: State<V> = {
            selectedOption: this.props.defaultValue as FieldOption<V>,
        };

        static displayName = 'ControlledValueSingleSelectWrapper';

        onChange: SelectProps<FieldOption<V>, boolean>['onChange'] = (
            option: ValueType<FieldOption<V>>,
            context: ActionMeta
        ) => {
            this.setState({ selectedOption: option });
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.props.onChange?.(option, context);
        };

        render() {
            return (
                <SelectComponent
                    {...(this.props as T)}
                    isMulti={false}
                    value={this.state.selectedOption}
                    onChange={this.onChange}
                    isClearable
                />
            );
        }
    };
}

export default withControlledValueSingleSelect;
