import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { getCategories, getIsKbEnabled, getPortalLink, canCreateRequest } from 'state/selectors/portal';
import type { CardLinkAppearance } from '@atlassian/help-center-common-component/group-box-select';
import type { CategoryListProps as FullCategoryListProps } from './category-list';
import CategoryListComponent from './category-list';

export interface CategoryListProps {
    portalId?: number;
    cardLinkAppearance?: CardLinkAppearance;
    isEmbeddedRoute?: boolean;
}

const selector = createSelector(
    (state: State, props: CategoryListProps) => getCategories(state, props.portalId),
    (state: State, props: CategoryListProps) => getIsKbEnabled(state, props.portalId),
    (state: State, props: CategoryListProps) => getPortalLink(state, props.portalId),
    (state: State, props: CategoryListProps) => canCreateRequest(state, props.portalId),
    (
        maybeCategories,
        enabled,
        portalLink,
        showRequestCreateButton
    ): Omit<FullCategoryListProps, keyof CategoryListProps> => {
        const categories = Array.isArray(maybeCategories) ? maybeCategories : [];
        return {
            categories,
            portalLink,
            showRequestCreateButton,
            hidden: !enabled,
        };
    }
);

export const CategoryList = connect(selector)(CategoryListComponent);
