import type { FormattedMessage } from 'react-intl';
import type { UserRequestsCountsResponse, UserResponse } from 'rest/models';
import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export const UPDATE_USER_MODEL = 'state.persisted.user.UPDATE_USER_MODEL';
export const UPDATE_USER_REQUESTS_COUNTS_MODEL = 'state.persisted.user.UPDATE_USER_REQUESTS_COUNTS_MODEL';
export const UPDATE_USER_TO_ANON = 'state.persisted.user.UPDATE_USER_TO_ANON';
export const SAVE_USER_PROFILE = 'state.persisted.user.SAVE_USER_PROFILE';
export const SAVE_USER_PROFILE_SUCCESS = 'state.persisted.user.SAVE_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_FAILURE = 'state.persisted.user.SAVE_USER_PROFILE_FAILURE';
export const SET_USER_PROFILE_VALIDATION_ERRORS = 'state.persisted.user.SET_USER_PROFILE_VALIDATION_ERRORS';
export const SUCCESSFULLY_LOGGED_IN = 'state.persisted.user.SUCCESSFULLY_LOGGED_IN';

export const SHOW_CHANGE_PASSWORD_DIALOG = 'state.ui.user.profile.SHOW_CHANGE_PASSWORD_DIALOG';
export const CLOSE_CHANGE_PASSWORD_DIALOG = 'state.ui.user.profile.CLOSE_CHANGE_PASSWORD_DIALOG';

export const VALIDATE_AND_SAVE_PASSWORD_CHANGE = 'state.persisted.user.VALIDATE_AND_SAVE_PASSWORD_CHANGE';
export const VALIDATE_PASSWORD = 'state.persisted.user.VALIDATE_PASSWORD';

export const VALIDATE_PASSWORD_SUCCESS = 'state.persisted.user.VALIDATE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD = 'state.persisted.user.CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'state.persisted.user.CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'state.persisted.user.CHANGE_PASSWORD_FAILURE';

export const USER_PROFILE_FORGOT_PASSWORD = 'state.ui.user.profile.FORGOT_PASSWORD';
export const USER_PROFILE_FORGOT_PASSWORD_SUCCESS = 'state.ui.user.profile.FORGOT_PASSWORD_SUCCESS';
export const USER_PROFILE_FORGOT_PASSWORD_FAILURE = 'state.ui.user.profile.FORGOT_PASSWORD_FAILURE';

export const FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS =
    'state.persisted.user.FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS';
export const FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS =
    'state.persisted.user.FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS';
const FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_FAILURE =
    'state.persisted.user.FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_FAILURE';

export interface UpdateUserModelAction {
    payload: UserResponse;
    type: typeof UPDATE_USER_MODEL;
}

export interface UpdateUserRequestsCountAction {
    payload: UserRequestsCountsResponse;
    type: typeof UPDATE_USER_REQUESTS_COUNTS_MODEL;
}

export const updateUserModelAction = (userResponse: UserResponse): UpdateUserModelAction => ({
    payload: userResponse,
    type: UPDATE_USER_MODEL,
});

export const updateUserRequestsCountsAction = (
    userRequestsCountsResponse: UserRequestsCountsResponse
): UpdateUserRequestsCountAction => ({
    payload: userRequestsCountsResponse,
    type: UPDATE_USER_REQUESTS_COUNTS_MODEL,
});

export interface UpdateUserModelAnonymousAction {
    type: typeof UPDATE_USER_TO_ANON;
}

export const updateUserModelAnonymousAction = (): UpdateUserModelAnonymousAction => ({
    type: UPDATE_USER_TO_ANON,
});

interface SelectedTimezone {
    id: string;
    regionKey: string;
    city: string;
    gmtOffset: string;
}

interface SelectedLanguage {
    key: string;
    displayName: string;
    originalKey: string;
}

interface SaveUserProfilePayload {
    displayName?: string;
    language?: SelectedLanguage;
    timezone?: SelectedTimezone;
}

export interface SaveUserProfile {
    payload: SaveUserProfilePayload;
    type: typeof SAVE_USER_PROFILE;
}

export const saveUserProfile = (
    displayName: string | undefined,
    language: SelectedLanguage | undefined,
    timezone: SelectedTimezone | undefined
): SaveUserProfile => ({
    payload: {
        displayName,
        language,
        timezone,
    },
    type: SAVE_USER_PROFILE,
});

export interface FetchServiceProjectRequestPermissionPayload {
    isAdEnabled: boolean;
    canCreateProject: boolean;
}

export interface FetchServiceProjectRequestPermission {
    type: typeof FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS;
    meta?: {
        analyticsFailureEvent?: UIAnalyticsEvent;
    };
}

export const fetchServiceProjectRequestPermissions = (meta?: {
    analyticsFailureEvent?: UIAnalyticsEvent;
}): FetchServiceProjectRequestPermission => ({
    meta,
    type: FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS,
});

export interface FetchServiceProjectRequestPermissionSuccess {
    payload: FetchServiceProjectRequestPermissionPayload;
    type: typeof FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS;
}

export interface FetchServiceProjectRequestPermissionFailure {
    type: typeof FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_FAILURE;
}

export const fetchServiceProjectRequestPermissionSuccess = (
    isAdEnabled: boolean,
    canCreateProject: boolean
): FetchServiceProjectRequestPermissionSuccess => {
    return {
        payload: {
            isAdEnabled,
            canCreateProject,
        },
        type: FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_SUCCESS,
    };
};

export const fetchServiceProjectRequestPermissionFailure = (): FetchServiceProjectRequestPermissionFailure => ({
    type: FETCH_SERVICE_PROJECT_REQUEST_PERMISSIONS_FAILURE,
});

export interface SaveUserProfileSuccess {
    payload: SaveUserProfilePayload;
    type: typeof SAVE_USER_PROFILE_SUCCESS;
}

export const saveUserProfileSuccess = (
    displayName: string | undefined,
    language: SelectedLanguage | undefined,
    timezone: SelectedTimezone | undefined
): SaveUserProfileSuccess => ({
    payload: {
        displayName,
        language,
        timezone,
    },
    type: SAVE_USER_PROFILE_SUCCESS,
});

export interface SaveUserProfileFailure {
    type: typeof SAVE_USER_PROFILE_FAILURE;
}

export const saveUserProfileFailure = (): SaveUserProfileFailure => ({
    type: SAVE_USER_PROFILE_FAILURE,
});

export interface SetUserProfileValidationErrors {
    payload: {
        nameError?: string;
        timezoneError?: string;
    };
    type: typeof SET_USER_PROFILE_VALIDATION_ERRORS;
}

export const setUserProfileValidationErrors = (
    nameError: string | undefined,
    timezoneError: string | undefined
): SetUserProfileValidationErrors => ({
    payload: {
        nameError,
        timezoneError,
    },
    type: SET_USER_PROFILE_VALIDATION_ERRORS,
});

export interface ShowChangePasswordDialog {
    type: typeof SHOW_CHANGE_PASSWORD_DIALOG;
}

export const showChangePasswordDialog = (): ShowChangePasswordDialog => ({
    type: SHOW_CHANGE_PASSWORD_DIALOG,
});

export interface CloseChangePasswordDialog {
    type: typeof CLOSE_CHANGE_PASSWORD_DIALOG;
}

export const closeChangePasswordDialog = (): CloseChangePasswordDialog => ({
    type: CLOSE_CHANGE_PASSWORD_DIALOG,
});

export interface ValidatePassword {
    payload: {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
    type: typeof VALIDATE_PASSWORD;
}

export const validatePassword = (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
): ValidatePassword => ({
    payload: {
        currentPassword,
        newPassword,
        confirmPassword,
    },
    type: VALIDATE_PASSWORD,
});

export interface ValidateAndSavePasswordChange {
    payload: {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
    type: typeof VALIDATE_AND_SAVE_PASSWORD_CHANGE;
}

export const validateAndSavePasswordChange = (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
): ValidateAndSavePasswordChange => ({
    payload: {
        currentPassword,
        newPassword,
        confirmPassword,
    },
    type: VALIDATE_AND_SAVE_PASSWORD_CHANGE,
});

export interface ChangePassword {
    payload: {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
    type: typeof CHANGE_PASSWORD;
}

export const changePassword = (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string
): ChangePassword => ({
    payload: {
        currentPassword,
        newPassword,
        confirmPassword,
    },
    type: CHANGE_PASSWORD,
});

export interface ValidatePasswordSuccess {
    type: typeof VALIDATE_PASSWORD_SUCCESS;
}

export const validatePasswordSuccess = (): ValidatePasswordSuccess => ({
    type: VALIDATE_PASSWORD_SUCCESS,
});

export interface ChangePasswordSuccess {
    type: typeof CHANGE_PASSWORD_SUCCESS;
}

export const changePasswordSuccess = (): ChangePasswordSuccess => ({
    type: CHANGE_PASSWORD_SUCCESS,
});

export interface ChangePasswordFailure {
    payload: {
        currentPasswordError?: string | FormattedMessage.MessageDescriptor;
        newPasswordError?: string | FormattedMessage.MessageDescriptor;
        confirmPasswordError?: string | FormattedMessage.MessageDescriptor;
        generalError?: DefaultError;
    };
    type: typeof CHANGE_PASSWORD_FAILURE;
}

export const changePasswordFailure = (
    currentPasswordError: string | FormattedMessage.MessageDescriptor | undefined,
    newPasswordError: string | FormattedMessage.MessageDescriptor | undefined,
    confirmPasswordError: string | FormattedMessage.MessageDescriptor | undefined,
    generalError: DefaultError | undefined
): ChangePasswordFailure => ({
    payload: {
        currentPasswordError,
        newPasswordError,
        confirmPasswordError,
        generalError,
    },
    type: CHANGE_PASSWORD_FAILURE,
});

export interface SuccessfullyLoggedIn {
    type: typeof SUCCESSFULLY_LOGGED_IN;
}

export const successfullyLoggedIn = (): SuccessfullyLoggedIn => {
    return {
        type: SUCCESSFULLY_LOGGED_IN,
    };
};

export interface ForgotPassword {
    type: typeof USER_PROFILE_FORGOT_PASSWORD;
    payload: {
        resetUrl: string;
        email: string;
        createAnalyticsEvent?: CreateUIAnalyticsEvent;
    };
}

export const forgotPassword = (
    resetUrl: string,
    email: string,
    createAnalyticsEvent?: CreateUIAnalyticsEvent
): ForgotPassword => ({
    type: USER_PROFILE_FORGOT_PASSWORD,
    payload: { resetUrl, email, createAnalyticsEvent },
});

export interface ForgotPasswordSuccess {
    type: typeof USER_PROFILE_FORGOT_PASSWORD_SUCCESS;
}

export const forgotPasswordSuccess = (): ForgotPasswordSuccess => ({
    type: USER_PROFILE_FORGOT_PASSWORD_SUCCESS,
});

export interface ForgotPasswordFailure {
    type: typeof USER_PROFILE_FORGOT_PASSWORD_FAILURE;
}

export const forgotPasswordFailure = (): ForgotPasswordFailure => ({
    type: USER_PROFILE_FORGOT_PASSWORD_FAILURE,
});
