import * as React from 'react';

import { isA11yFixForPortalsGroupHeadingEnabled } from 'feature-flags';
// eslint-disable-next-line import/no-named-default
import { default as AkHeading } from '@atlaskit/heading';
import { AutoScaledImage } from '../auto-scaled-image';
import type { ContainerPadding } from './styled';
import { StyledIconCardLayout, Heading, Description, HeadingContent } from './styled';

export interface Props {
    heading?: React.ReactNode;
    description: React.ReactNode;
    /**
     * Will clamp the heading to 1 line when lineClamp={1} or greater.
     * Will clamp description to the number of lines specified.
     * 0 or undefined = no clamping.
     * 1 = 1 line.
     * 2 = 2 lines. etc.
     */
    lineClamp?: number;
    className?: string;
    dataTestId?: string;
    icon?: string | React.ReactNode;
    /**
     * Used to switch the style of padding inside the container.
     * "default" is 3 grid lengths, "tight" is 2 grid lengths.
     * This only affects left/right padding.
     */
    containerPadding?: ContainerPadding;
}

const GroupBox: React.FunctionComponent<Props> = ({ heading, description, icon, lineClamp = 0, ...props }) => {
    const content = (
        <React.Fragment>
            {heading &&
                (isA11yFixForPortalsGroupHeadingEnabled() ? (
                    <AkHeading size="xsmall" as="h3">
                        <HeadingContent lineClamp={!!lineClamp}>{heading}</HeadingContent>
                    </AkHeading>
                ) : (
                    <Heading lineClamp={!!lineClamp}>{heading}</Heading>
                ))}
            <Description aria-hidden lineClamp={lineClamp}>
                {description}
            </Description>
        </React.Fragment>
    );

    return (
        <StyledIconCardLayout
            {...props}
            leftIcon={icon && (React.isValidElement(icon) ? icon : <AutoScaledImage src={icon as string} alt="" />)}
            content={content}
        />
    );
};

export default GroupBox;
