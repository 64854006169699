import type { AjaxError } from 'rxjs';
import type { MessageDescriptor } from 'react-intl-next';
import {
    NoNetworkTitle,
    NoNetworkDescription,
    GenericErrorTitle,
    GenericErrorDescription,
    GenericSignUpErrorNoContactLinkDescription,
    GenericErrorRefreshPageAction,
    SignupForbiddenTitle,
    SignupForbiddenDescription,
    SignupDomainBlockedTitle,
    SignupDomainBlockedDescription,
    UserProfileForgotPasswordFailedErrorTitle,
    UserProfileForgotPasswordFailedErrorDescription,
    UserProfileForgotPasswordRateLimitedErrorTitle,
    UserProfileForgotPasswordRateLimitedErrorDescription,
    GenericSignUpErrorDescriptionUnified,
    InvalidEmailTitle,
    InvalidEmailDescription,
    SignupEmailResendRateLimitedDescription,
} from '@atlassian/help-center-common-component/flag-group';
import { location } from '@atlassian/help-center-common-util/location';

export interface JiraErrorResponse {
    errors: JiraError[];
    reasonCode: string;
    reasonKey: string;
}

interface JiraError {
    errorMessage: string;
    field?: string;
}

interface AjaxErrorWithReasonKey extends AjaxError {
    response: {
        [key: string]: unknown;
        reasonKey?: string;
    };
}

export type Error = JiraError | DefaultError;

export interface DefaultError {
    title: MessageDescriptor;
    description: MessageDescriptor;
    action?: ErrorAction;
}

export interface ErrorExtension {
    statusCode: number;
    errorType: string;
}

export interface MutationError {
    message: string;
    extensions: ErrorExtension;
}

export interface ErrorAction {
    content: MessageDescriptor;
    href: string;
}

export const getMessagesfromJiraError = (errorResponse: JiraErrorResponse): string[] => {
    const hasError = errorResponse && errorResponse.errors;
    return hasError ? errorResponse.errors.map((error: JiraError) => error && error.errorMessage) : [];
};

export const getFirstMessageFromJiraError = (errorResponse: JiraErrorResponse): string => {
    const errorMessages = getMessagesfromJiraError(errorResponse);
    return errorMessages.length > 0 ? errorMessages[0] : '';
};

export const getJiraErrorOrDefault = (error: AjaxError): Error => {
    const jiraErrorMessage = getFirstMessageFromJiraError(error.response);
    if (jiraErrorMessage.length > 0) {
        return {
            errorMessage: jiraErrorMessage,
        };
    }
    return getDefaultError(error);
};

export const getJiraErrorResponse = (error: AjaxError): JiraErrorResponse | undefined => {
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (error.response && error.response.errors) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return error.response;
    }
    return undefined;
};

export const getDefaultError = (error: AjaxError): DefaultError => {
    if (isRateLimitedError(error)) {
        return signupRateLimitedError;
    }
    if (isNetworkError(error)) {
        return getNoNetworkError();
    }
    return genericError;
};

export const getDefaultSignUpError = (error: AjaxError, contactAdminsFormEnabled: boolean): DefaultError => {
    if (isNetworkError(error)) {
        return getNoNetworkError();
    }
    return genericSignUpError(contactAdminsFormEnabled);
};

export const getDefaultCheckLoginError = (error: AjaxError): DefaultError => {
    const jiraErrorResponse = getJiraErrorResponse(error);
    if (jiraErrorResponse) {
        if (isSignupDomainBlockedError(error)) {
            return signupDomainBlockedError;
        }
        if (isSignupForbiddenError(error)) {
            return signupForbiddenError;
        }
        if (isEmailInvalidError(error)) {
            return invalidEmailError;
        }
    } else if (isNetworkError(error)) {
        return getNoNetworkError();
    }
    return genericError;
};

export const isSsoTestModeError = (error: AjaxError) => getJiraErrorResponse(error) && isSsoTestModeFailure(error);

export const genericSignUpError = (contactAdminsFormEnabled: boolean): DefaultError => {
    const noContactDescription = GenericSignUpErrorNoContactLinkDescription;
    const description = contactAdminsFormEnabled ? GenericSignUpErrorDescriptionUnified : noContactDescription;

    return {
        description,
        title: GenericErrorTitle,
        action: getGenericErrorFlagActions(),
    };
};

const isNetworkError = (errorResponse: AjaxError) => errorResponse.status === 0;

const isSignupForbiddenError = (errorResponse: AjaxErrorWithReasonKey) =>
    errorResponse.status === 403 &&
    (errorResponse.response?.reasonKey === 'sd.customer.management.rest.error.customer.account.signup.forbidden' ||
        errorResponse.response?.reasonKey === 'customer-signup-forbidden');

const isSignupDomainBlockedError = (errorResponse: AjaxErrorWithReasonKey) =>
    errorResponse.status === 403 &&
    (errorResponse.response?.reasonKey === 'sd.customer.management.rest.error.customer.account.signup.blocked' ||
        errorResponse.response?.reasonKey === 'customer-signup-domain-blocked');
const isEmailInvalidError = (errorResponse: AjaxErrorWithReasonKey) =>
    errorResponse.status === 400 && errorResponse.response?.reasonKey === 'signup.error.email.valid';

const isSsoTestModeFailure = (errorResponse: AjaxErrorWithReasonKey) =>
    errorResponse.status === 400 && errorResponse.response?.reasonKey === 'CA_SSO_TEST_MODE_NOT_ENABLED';

const isRateLimited = (errorResponse: AjaxErrorWithReasonKey) => errorResponse.status === 429;

const isRateLimitedError = (errorResponse: AjaxErrorWithReasonKey) =>
    isRateLimited(errorResponse) && errorResponse.response?.reasonKey === 'sd.email.signup.email.rate.limited';

const getGenericErrorFlagActions = (): ErrorAction => ({
    href: location().href,
    content: GenericErrorRefreshPageAction,
});

export const getNoNetworkError = (): DefaultError => noNetworkError;

export const noNetworkError: DefaultError = {
    title: NoNetworkTitle,
    description: NoNetworkDescription,
};

export const signupRateLimitedError: DefaultError = {
    title: GenericErrorTitle,
    description: SignupEmailResendRateLimitedDescription,
};

export const signupForbiddenError: DefaultError = {
    title: SignupForbiddenTitle,
    description: SignupForbiddenDescription,
};

export const signupDomainBlockedError: DefaultError = {
    title: SignupDomainBlockedTitle,
    description: SignupDomainBlockedDescription,
};

export const invalidEmailError: DefaultError = {
    title: InvalidEmailTitle,
    description: InvalidEmailDescription,
};

export const genericError: DefaultError = {
    title: GenericErrorTitle,
    description: GenericErrorDescription,
    action: getGenericErrorFlagActions(),
};

const genericUserProfileForgotPasswordFailedError: DefaultError = {
    title: UserProfileForgotPasswordFailedErrorTitle,
    description: UserProfileForgotPasswordFailedErrorDescription,
};

export const userProfileForgotPasswordRateLimitedError: DefaultError = {
    title: UserProfileForgotPasswordRateLimitedErrorTitle,
    description: UserProfileForgotPasswordRateLimitedErrorDescription,
};

export const getUserProfileForgotPasswordFailedError = (error: AjaxError): DefaultError => {
    if (isNetworkError(error)) {
        return getNoNetworkError();
    }

    if (isRateLimited(error)) {
        return userProfileForgotPasswordRateLimitedError;
    }

    return genericUserProfileForgotPasswordFailedError;
};
