import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { requestTypeReorder } from 'rest/request-type-reorder';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import { clearRequestTypesOrderAction } from 'state/actions/portal-customisation';
import type { ClearRequestTypeOrderAction } from 'state/actions/portal-customisation';
import type { ReorderRequestTypeAction } from 'state/actions/reorder-request-types';
import { REORDER_REQUEST_TYPE } from 'state/actions/reorder-request-types';
import type { ReqTypesState } from 'state/persisted/portal/reducer';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

export const reorderRequestTypeEpic: Epic<ReorderRequestTypeAction, FetchPortalAction | ClearRequestTypeOrderAction> = (
    action$
) =>
    action$
        .ofType(REORDER_REQUEST_TYPE)
        .mergeMap(
            ({
                payload: {
                    portalId,
                    projectId,
                    requestTypeId,
                    requestGroupId,
                    fromIndex,
                    toIndex,
                    requestTypes,
                    analyticsEvent,
                    analyticsFailureEvent,
                },
            }: ReorderRequestTypeAction) => {
                const body = getReorderPayload({ requestTypes, fromIndex, toIndex, projectId, requestGroupId });
                return requestTypeReorder({
                    requestTypeId,
                    projectId,
                    requestGroupId,
                    body,
                })
                    .map(() => {
                        analyticsEvent && sendEvent(analyticsEvent);
                        return fetchPortalAction({
                            id: portalId,
                            expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'],
                        });
                    })
                    .catch(() => {
                        if (analyticsFailureEvent) {
                            sendEvent(analyticsFailureEvent);
                        }
                        return Observable.of(clearRequestTypesOrderAction());
                    });
            }
        );

interface GetRequestTypeReorder {
    requestTypes: ReqTypesState[];
    fromIndex: number;
    toIndex: number;
    projectId: number;
    requestGroupId: number;
}

// The POST endpoint for reordering request types expects the body payload of POST request to look like:
// { position: 'First' } - when dragging a request type to the top of the list
// and { after: [url + requestTypeId] } - where requestTypeId is the id of the request type which the dragged req type will be under.
function getReorderPayload({ requestTypes, projectId, requestGroupId, fromIndex, toIndex }: GetRequestTypeReorder) {
    if (toIndex === 0) {
        return { position: 'First' };
    }

    let requestTypeAfter;
    if (toIndex < fromIndex) {
        // if we're dragging UP the list we want it to set 'after' to the item above
        requestTypeAfter = requestTypes[toIndex - 1];
    } else {
        requestTypeAfter = requestTypes[toIndex];
    }

    return {
        after: `/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups/${requestGroupId}/request-types/${requestTypeAfter.id}`,
    };
}
