// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const GlobalStyled = styled(BodyClassName)`
    background-color: ${token('elevation.surface', colors.N10)};
    a:not(.cv-exclude-from-customisation),
    a:hover:not(.cv-exclude-from-customisation) {
        color: ${(props) => token('color.link', props.theme.color)};
    }
    /* Remove these root styles when design system adds AppProvider */
    :root {
        --ds-space-0: 0;
        --ds-space-025: 0.125rem;
        --ds-space-050: 0.25rem;
        --ds-space-075: 0.375rem;
        --ds-space-100: 0.5rem;
        --ds-space-150: 0.75rem;
        --ds-space-200: 1rem;
        --ds-space-250: 1.25rem;
        --ds-space-300: 1.5rem;
        --ds-space-400: 2rem;
        --ds-space-500: 2.5rem;
        --ds-space-600: 3rem;
        --ds-space-800: 4rem;
        --ds-space-1000: 5rem;
        --ds-font-family-monospace: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo,
            Consolas, Courier, monospace;
        --ds-font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
        --ds-font-size-050: 11px;
        --ds-font-size-075: 12px;
        --ds-font-size-100: 14px;
        --ds-font-size-200: 16px;
        --ds-font-size-300: 20px;
        --ds-font-size-400: 24px;
        --ds-font-size-500: 29px;
        --ds-font-size-600: 35px;
        --ds-font-weight-bold: 700;
        --ds-font-weight-medium: 500;
        --ds-font-weight-regular: 400;
        --ds-font-weight-semibold: 600;
        --ds-font-lineHeight-100: 1rem;
        --ds-font-lineHeight-200: 1.25rem;
        --ds-font-lineHeight-300: 1.5rem;
        --ds-font-lineHeight-400: 1.75rem;
        --ds-font-lineHeight-500: 2rem;
        --ds-font-lineHeight-600: 2.5rem;
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NewGlobalBGColor = styled(BodyClassName)`
    background-color: ${token('elevation.surface', colors.N0)};
`;
