import type { FetchAiAnswerFeedbackFailure, FetchAiAnswerFeedbackSuccess } from 'state/actions/ai-answer';
import { FETCH_AI_ANSWER_FEEDBACK_FAILURE, FETCH_AI_ANSWER_FEEDBACK_SUCCESS } from 'state/actions/ai-answer';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface AiAnswerState {
    feedback: string;
    issueKey: string;
    projectId: number;
    initialError?: PersistedError;
}

export type HandledActions = FetchAiAnswerFeedbackSuccess | FetchAiAnswerFeedbackFailure;

const defaultState: AiAnswerState = {
    feedback: '',
    issueKey: '',
    projectId: 0,
    initialError: undefined,
};

export const getInitialState = (): AiAnswerState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.aiAnswerFeedbackPage) {
        return initialModelState.aiAnswerFeedbackPage;
    }
    return defaultState;
};

export function aiAnswerReducer(state: AiAnswerState = getInitialState(), action: HandledActions): AiAnswerState {
    switch (action.type) {
        case FETCH_AI_ANSWER_FEEDBACK_SUCCESS:
            return handleFetchAiAnswerFeedbackSuccess(state, action);
        case FETCH_AI_ANSWER_FEEDBACK_FAILURE:
            return handleFetchAiAnswerFeedbackFailure(state, action);
        default:
            return state;
    }
}

function handleFetchAiAnswerFeedbackSuccess(state: AiAnswerState, action: FetchAiAnswerFeedbackSuccess): AiAnswerState {
    const response = action.payload;
    if (!response) {
        return state;
    }

    return {
        ...state,
        feedback: response.feedback,
        issueKey: response.issueKey,
        projectId: response.projectId,
    };
}

function handleFetchAiAnswerFeedbackFailure(state: AiAnswerState, action: FetchAiAnswerFeedbackFailure): AiAnswerState {
    const { error } = action.payload;

    return {
        ...state,
        initialError: {
            error: {
                status: error.status,
                message: (error.errorMessages || [])[0],
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            },
        },
    };
}
