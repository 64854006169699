import type { Observable } from 'rxjs';
import { post } from 'epics/ajax';
import type { ConsentResponse } from './types';

export const loginConsent = (param: string): Observable<ConsentResponse> => {
    const uri = `/jsd-login-v2/v2/customer/login/fedramp/consent${param}`;
    const body = {
        fedrampConsent: 'provided',
    };
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    return post(uri, body, headers);
};
