import type { StoreActionApi } from 'react-sweet-state';
import { AIAnswerExperience, initialAIAnswerExperience } from 'unified-help-center-alpha/src/controllers/experience';
import type { HelpseekerMessage } from '../../../common/types';
import { sendTrackEvent } from '../../../common/utils';
import { initialIntentMessageExperience, intentAnswerExperience } from '../../experiences';
import type { ConversationStateContainerProps, State } from '../types';

export const startConversation =
    (helpseekerMessage?: HelpseekerMessage) =>
    async (
        { getState, setState }: StoreActionApi<State>,
        {
            conversationProvider,
            handleHandOff,
        }: Pick<ConversationStateContainerProps, 'conversationProvider' | 'handleHandOff'>
    ) => {
        const { projectId, portalId, contextId } = getState();
        const abortController = new AbortController();
        setState({ abortController });

        const handleError = (error: string) => {
            // https://data-portal.internal.atlassian.com/analytics/registry/65973
            sendTrackEvent({
                action: 'failed',
                actionSubject: 'startConversation',
                attributes: {
                    contextId,
                    projectId: projectId?.toString(),
                    portalId: portalId?.toString(),
                },
            });

            setState({ error });
            throw new Error(error);
        };

        try {
            const response = await conversationProvider.startConversation(
                abortController.signal,
                helpseekerMessage?.message
            );

            if (abortController.signal.aborted) {
                return undefined;
            }

            if (!response?.id) {
                throw Error('No conversation id.');
            }

            // https://data-portal.internal.atlassian.com/analytics/registry/65970
            sendTrackEvent({
                action: 'started',
                actionSubject: 'conversation',
                actionSubjectId: response.id,
                attributes: {
                    contextId,
                    conversationId: response.id,
                    projectId: projectId?.toString(),
                    portalId: portalId?.toString(),
                },
            });

            if (response.shouldHandoff && handleHandOff) {
                const messages = helpseekerMessage ? [helpseekerMessage] : [];

                const metricMetaData = {
                    contextId,
                    handOff: true,
                    conversationId: response.id,
                    projectId: projectId?.toString(),
                    portalId: portalId?.toString(),
                } as const;
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                intentAnswerExperience.failure({
                    metadata: metricMetaData,
                });
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                initialIntentMessageExperience.failure({
                    metadata: metricMetaData,
                });

                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                AIAnswerExperience.start();
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                initialAIAnswerExperience.start();
                return handleHandOff(messages, response.id, contextId);
            }

            setState({ conversationId: response.id });
            return response.id;
        } catch (error: unknown) {
            if (error instanceof Error) {
                return handleError(error.message);
            }
            return handleError('Failed to start conversation.');
        }
    };
