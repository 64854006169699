import { commitMutation, graphql } from 'relay-runtime';
import { getCloudId, getHelpCenterAri } from '@atlassian/help-center-common-util/meta';
import environment from '@atlassian/help-center-common-util/relay/environment';
import type {
    HelpCenterTranslationInput,
    loginAnnouncementsUpdateMutation,
    loginAnnouncementsUpdateMutation$data,
    loginAnnouncementsUpdateMutation$variables,
} from './__generated__/loginAnnouncementsUpdateMutation.graphql';
import type { LoginAnnouncementsUpdateRequestPayload } from 'rest/login-announcements/types';

export const updateLoginAnnouncementsData = (variables: loginAnnouncementsUpdateMutation$variables) => {
    return new Promise<loginAnnouncementsUpdateMutation$data>((resolve, reject) => {
        commitMutation<loginAnnouncementsUpdateMutation>(environment, {
            variables,
            mutation: graphql`
                mutation loginAnnouncementsUpdateMutation($cloudId: ID!, $input: HelpCenterAnnouncementInput!) {
                    helpCenter(cloudId: $cloudId) {
                        updateLoginAnnouncement(input: $input) {
                            success
                            errors {
                                message
                                extensions {
                                    statusCode
                                }
                            }
                        }
                    }
                }
            `,
            onCompleted: (response) => {
                resolve(response);
            },
            onError: (error) => {
                reject(error);
            },
        });
    });
};

export const mapLoginAnnouncementsPayloadToGqlInput = (
    payload: LoginAnnouncementsUpdateRequestPayload
): loginAnnouncementsUpdateMutation$variables => {
    const nameTranslations: HelpCenterTranslationInput[] = [];
    const descriptionTranslations: HelpCenterTranslationInput[] = [];

    // map payload to gql input
    Object.keys(payload.translatedLogInAnnouncements).forEach((locale) => {
        nameTranslations.push({
            locale,
            value: payload.translatedLogInAnnouncements[locale].header,
        });
        descriptionTranslations.push({
            locale,
            value: payload.translatedLogInAnnouncements[locale].message,
        });
    });

    return {
        cloudId: getCloudId(),
        input: {
            nameTranslations,
            descriptionTranslations,
            helpCenterAri: getHelpCenterAri(),
            descriptionType: 'WIKI_MARKUP',
        },
    };
};
