import type { PaperAppearance } from 'view/layout/paper';
import { PortalAccessForbidden } from 'view/portal-access-forbidden';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const layoutProps = {
    isAnonymous: true,
    hideSearchButton: true,
    paperAppearance: 'normal' as PaperAppearance,
    fadeInSearchModal: true,
    headerless: true,
};

export const portalAccessForbiddenRoute = createRouteWithLayout(
    {
        name: 'portal-access-forbidden',
        path: '/user/forbidden',
        exact: true,
        component: PortalAccessForbidden,
        resources: [],
    },
    ScreenName.PORTAL_ACCESS_FORBIDDEN,
    layoutProps
);
