import { withAnalyticsContext, withAnalyticsEvents, createAndFireEvent } from '@atlaskit/analytics-next';
import { DropdownItem as AtlaskitDropdownItem } from '@atlaskit/dropdown-menu';

// Atlaskit's DropdownItem doesn't fire onClick analytics, so we monkey-patch it. :)
// This involves setting the componentName in context, and firing a 'ui clicked dropdownItem' event on the 'atlaskit' channel.
// This event is picked up by the atlaskit analytics listener in providers.tsx and fired off.
// When atlaskit adds onClick analytics to this component, we can remove this component.
export const DropdownItem = withAnalyticsContext({
    componentName: 'dropdownItem',
})(
    withAnalyticsEvents({
        onClick: createAndFireEvent('atlaskit')({
            action: 'clicked',
            actionSubject: 'dropdownItem',
        }),
    })(AtlaskitDropdownItem)
);
