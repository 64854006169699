import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { AnalyticsContext } from '@atlassian/analytics-web-react';

interface ComponentProps {
    baseUrl: string;
}

export const CustomDomainsAnalyticsProvider: FC<PropsWithChildren<ComponentProps>> = ({ children, baseUrl }) => {
    const atlassianDomains = [
        'atlassian.net',
        'jira-dev.com',
        'atlassian.com',
        'jira.com',
        'atl-paas.net',
        'trello.com',
        'bitbucket.org',
    ];

    if (atlassianDomains.some((domain) => baseUrl.toLowerCase().includes(domain))) {
        return <AnalyticsContext attributes={{ isCustomDomain: false }}>{children}</AnalyticsContext>;
    }

    const customDomain = new URL(baseUrl).hostname;
    return <AnalyticsContext attributes={{ customDomain, isCustomDomain: true }}>{children}</AnalyticsContext>;
};
