import type { JiraProjectsHelpCenterMappingStatus } from './__generated__/manageProjects_GetProjectsQuery.graphql';

// eslint-disable-next-line no-shadow
export enum ProjectsMappingStatus {
    UNLINKED = 'UNLINKED',
    LINKED = 'LINKED',
    ALL = 'ALL',
}

// eslint-disable-next-line no-shadow
export enum ProjectsMappingMode {
    BULK = 'bulk',
    SINGLE = 'single',
}

// eslint-disable-next-line no-shadow
export enum ProjectsMappingOperations {
    UNLINK = 'UNMAP_PROJECTS',
    LINK = 'MAP_PROJECTS',
}

export type StatusTextMap = {
    [key in Partial<JiraProjectsHelpCenterMappingStatus>]?: string;
};
