import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const UPDATE_REQUEST_GROUPS_ORDER = 'state.ui.portal-customisation.UPDATE_REQUEST_GROUPS_ORDER';
export const CLEAR_REQUEST_GROUPS_ORDER = 'state.ui.portal-customisation.CLEAR_REQUEST_GROUPS_ORDER';
export const CLEAR_ERROR_MESSAGE = 'state.ui.portal-customisation.CLEAR_ERROR_MESSAGE';
export const CLEAR_REQUEST_TYPES_ORDER = 'state.ui.portal-customisation.CLEAR_REQUEST_TYPES_ORDER';

export interface UpdateRequestGroupsOrderPayload {
    portalId: number;
    order: number[];
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface UpdateRequestGroupsOrderAction {
    payload: UpdateRequestGroupsOrderPayload;
    type: typeof UPDATE_REQUEST_GROUPS_ORDER;
}

export interface ClearRequestGroupsOrderAction {
    type: typeof CLEAR_REQUEST_GROUPS_ORDER;
}

export interface ClearErrorMessageAction {
    type: typeof CLEAR_ERROR_MESSAGE;
}

export interface ClearRequestTypeOrderAction {
    type: typeof CLEAR_REQUEST_TYPES_ORDER;
}

export const updateRequestGroupsOrderAction = (
    payload: UpdateRequestGroupsOrderPayload
): UpdateRequestGroupsOrderAction => ({
    payload,
    type: UPDATE_REQUEST_GROUPS_ORDER,
});

export const clearRequestGroupsOrderAction = (): ClearRequestGroupsOrderAction => ({
    type: CLEAR_REQUEST_GROUPS_ORDER,
});

export const clearErrorMessageAction = (): ClearErrorMessageAction => ({
    type: CLEAR_ERROR_MESSAGE,
});

export const clearRequestTypesOrderAction = (): ClearRequestTypeOrderAction => ({
    type: CLEAR_REQUEST_TYPES_ORDER,
});
