import { defineMessages } from 'react-intl-next';

export default defineMessages({
    settingsPageBreadcrumbItem: {
        defaultMessage: 'Help centers',
        description: 'Help center edit details page breadcrumb item',
        id: 'help.center.settings.breadcrumb.item',
    },
    breadcrumbsLabel: {
        id: 'help.center.settings.breadcrumbs.container',
        defaultMessage: 'Breadcrumbs',
        description: 'Help Center Breadcrumbs wrapper label',
    },
});
