import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type {
    FetchRequestCreateAction,
    FetchRequestCreateSuccess,
    FetchRequestCreateFailure,
} from 'state/actions/request-create';
import {
    FETCH_REQUEST_CREATE_MODEL,
    fetchRequestCreateSuccess,
    fetchRequestCreateFailure,
} from 'state/actions/request-create';
import type { ErrorResponse } from '../types';

export const requestCreateEpic: Epic<
    FetchRequestCreateAction,
    FetchRequestCreateSuccess | FetchRequestCreateFailure
> = (action$) => {
    return action$.ofType(FETCH_REQUEST_CREATE_MODEL).mergeMap((action) => {
        const portalId = action.payload && action.payload.params && action.payload.params.portalId;

        return fetchModel(action.payload)
            .filter((response) => !!response.reqCreate)
            .map((response) => {
                return fetchRequestCreateSuccess({ ...response.reqCreate!, portalId });
            })
            .catch((error: ErrorResponse) => {
                const params = action.payload.params;
                return Observable.of(fetchRequestCreateFailure(portalId, params.id, error));
            });
    });
};
