import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { approve, decline } from 'rest/approval';
import type { HandleAjaxError, ShowErrorFlag } from 'state/actions/flags';
import { handleAjaxError, showErrorFlag } from 'state/actions/flags';
import { decideApprovalFailure, DECIDE_APPROVAL, fetchRequestDetailsModel } from 'state/actions/request-details';
import type { DecideApproval, DecideApprovalFailure, FetchRequestDetailsAction } from 'state/actions/request-details';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import messages from './messages';

const isClientSideError = (error: AjaxError) => error.status >= 400 && error.status < 500;

export const decideApprovalEpic: Epic<
    DecideApproval,
    FetchRequestDetailsAction | DecideApprovalFailure | HandleAjaxError | ShowErrorFlag
> = (action$) => {
    return action$.ofType(DECIDE_APPROVAL).mergeMap((decideApprovalAction) => {
        const payload = decideApprovalAction.payload;
        const restCall = payload.approve ? approve(payload.approvalId) : decline(payload.approvalId);
        return (
            restCall
                .map(() => {
                    const successEvent = payload.createAnalyticsEvent?.({
                        analyticsType: OPERATIONAL_EVENT_TYPE,
                        action: 'recorded',
                    });

                    if (successEvent) {
                        successEvent.context.push({ componentName: 'approverDecision' });
                        sendEvent(successEvent);
                    }

                    return fetchRequestDetailsModel({
                        portalId: payload.portalId,
                        key: payload.requestKey,
                    });
                })
                // @ts-ignore
                .catch((error: AjaxError) => {
                    const failEvent = payload.createAnalyticsEvent?.({
                        analyticsType: OPERATIONAL_EVENT_TYPE,
                        action: 'failed',
                        statusCode: isClientSideError(error) ? '4XX' : '5XX',
                    });

                    if (failEvent) {
                        failEvent.context.push({ componentName: 'portalRequestDetailsScreen.answerApprovalService' });
                        sendEvent(failEvent);
                    }

                    if (error.status === 409) {
                        return concat(
                            Observable.of(decideApprovalFailure()),
                            Observable.of(
                                fetchRequestDetailsModel({
                                    portalId: payload.portalId,
                                    key: payload.requestKey,
                                })
                            ),
                            Observable.of(showErrorFlag(messages.conflictErrorTitle, messages.conflictErrorDescription))
                        );
                    }

                    return concat(Observable.of(decideApprovalFailure()), Observable.of(handleAjaxError(error)));
                })
        );
    });
};
