import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { di } from 'react-magnetic-di';
import type { State } from 'state';
import { fetchRequestCreateAction } from 'state/actions/request-create';
import { getRequestCreate } from 'state/selectors/request-create';
import { csmHelpCenterLoadFormExperience } from 'view/help-center/help-center-content/csm-help-center/experiences';
import { Box } from '@atlaskit/primitives';
import { RequestCreateForm } from '../index';

interface ExternalProps {
    portalId: number;
    requestTypeId: number;
}

interface CsmRequestCreateFormProps extends ExternalProps {
    hasFormData: boolean;
    fetchRequestCreate: typeof fetchRequestCreateAction;
}

const CsmRequestCreateForm: React.FC<CsmRequestCreateFormProps> = ({ fetchRequestCreate, ...props }) => {
    di(RequestCreateForm);
    const { portalId, requestTypeId, hasFormData } = props;
    const formRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        csmHelpCenterLoadFormExperience.start();
        fetchRequestCreate({ portalId, id: requestTypeId }, {});
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            csmHelpCenterLoadFormExperience.abort();
        };
    }, [fetchRequestCreate, portalId, requestTypeId]);

    useEffect(() => {
        if (hasFormData && formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [portalId, requestTypeId, hasFormData]);

    return (
        <Box ref={formRef}>
            <RequestCreateForm {...props} />
        </Box>
    );
};

const selector = createSelector(
    (state: State, { portalId, requestTypeId }: ExternalProps) => {
        di(getRequestCreate);
        return getRequestCreate(state, portalId, requestTypeId);
    },
    (formData) => ({
        hasFormData: !!formData,
    })
);

export default connect(selector, {
    fetchRequestCreate: fetchRequestCreateAction,
})(CsmRequestCreateForm);
