import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';

import * as transitions from 'view/styles/transitions';

interface FadeProps {
    in: boolean;
    appear?: boolean;
}

// eslint-disable-next-line rulesdir/styled-component-order, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.span<transitions.TransitionProps>`
    ${transitions.fadeInOut(transitions.speedMs.fast)};
`;

/**
 * This is for ease of use fade in/out. This will add an extra <span> around the children to do the
 * animating. If you need finer grained control, or don't want the extra span markup, use <Transition />
 * and our transition mixins directly.
 */
const Fade: React.FunctionComponent<React.PropsWithChildren<FadeProps>> = ({ children, appear, in: show }) => (
    <Transition timeout={transitions.speedMs.fast} in={show} mountOnEnter unmountOnExit appear={appear}>
        {(state: transitions.TransitionState) => <Container state={state}>{children}</Container>}
    </Transition>
);

export default Fade;
