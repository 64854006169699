import type { LoginAttemptResponse } from 'rest/login';
import type {
    ChangeEmailInput,
    ForgotPassword,
    ForgotPasswordFailure,
    ForgotPasswordSuccess,
    LoginAttempt,
    LoginAttemptSuccess,
    LoginAttemptFailure,
    ResetLogin,
    CheckLoginAction,
    CheckLoginActionFailure,
    CheckLoginActionSuccess,
    CaSsoTestModeFailure,
    LoginConsentFailure,
} from 'state/actions/login';
import {
    CHANGE_EMAIL_INPUT,
    CHECK_LOGIN_ACTION,
    CHECK_LOGIN_ACTION_FAILURE,
    CHECK_LOGIN_ACTION_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    LOGIN_ATTEMPT,
    LOGIN_ATTEMPT_FAILURE,
    LOGIN_ATTEMPT_SUCCESS,
    RESET_LOGIN,
    CA_SSO_TEST_MODE_FAILURE,
    LOGIN_CONSENT_FAILURE,
} from 'state/actions/login';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';
import type { AuthInfo } from '@atlassian/help-center-common-util/login';
import { AccountStageType, AuthMethodType } from '@atlassian/help-center-common-util/login';

export interface LoginUIState {
    email: string;
    atlassianAccountCheckPerformed: boolean;
    isSubmitting: boolean;
    isSendingForgotPasswordEmail: boolean;
    authInfo: AuthInfo;
    showPasswordConfirmation: boolean;
    error?: DefaultError;
    loginAttemptResponse?: LoginAttemptResponse;
    showSsoTestModeErrorPage: boolean;
    showLoginConsentErrorPage: boolean;
}

export type HandledActions =
    | ChangeEmailInput
    | CheckLoginAction
    | CheckLoginActionSuccess
    | CheckLoginActionFailure
    | ForgotPassword
    | ForgotPasswordFailure
    | ForgotPasswordSuccess
    | LoginAttempt
    | LoginAttemptSuccess
    | LoginAttemptFailure
    | ResetLogin
    | CaSsoTestModeFailure
    | LoginConsentFailure;

export const defaultState: LoginUIState = {
    email: '',
    atlassianAccountCheckPerformed: false,
    isSubmitting: false,
    isSendingForgotPasswordEmail: false,
    authInfo: {
        accountStage: AccountStageType.LOGIN,
        authMethods: new Map([[AuthMethodType.CUSTOMER_ACCOUNT_PASSWORD, {}]]),
    },
    showPasswordConfirmation: false,
    showSsoTestModeErrorPage: false,
    showLoginConsentErrorPage: false,
};

export function loginUiReducer(state: LoginUIState = defaultState, action: HandledActions): LoginUIState {
    switch (action.type) {
        case CHANGE_EMAIL_INPUT:
            return handleChangeEmailInput(state, action);
        case CHECK_LOGIN_ACTION:
            return handleCheckLoginActionAction(state);
        case CHECK_LOGIN_ACTION_SUCCESS:
            return handleCheckLoginActionSuccess(state, action);
        case CHECK_LOGIN_ACTION_FAILURE:
            return handleCheckLoginActionFailure(state, action);
        case FORGOT_PASSWORD:
            return handleForgotPassword(state);
        case FORGOT_PASSWORD_FAILURE:
            return handleForgotPasswordFailure(state);
        case FORGOT_PASSWORD_SUCCESS:
            return handleForgotPasswordSuccess(state);
        case LOGIN_ATTEMPT:
            return handleLoginAttempt(state);
        case LOGIN_ATTEMPT_SUCCESS:
            return handleLoginAttemptSuccess(state, action);
        case LOGIN_ATTEMPT_FAILURE:
            return handleLoginAttemptFailure(state, action);
        case CA_SSO_TEST_MODE_FAILURE:
            return handleCaSsoTestModeFailure(state);
        case LOGIN_CONSENT_FAILURE:
            return handleLoginConsentFailure(state);
        case RESET_LOGIN:
            return resetLogin(action);
        default:
            return state;
    }
}

const handleChangeEmailInput = (state: LoginUIState, action: ChangeEmailInput): LoginUIState => ({
    ...state,
    atlassianAccountCheckPerformed: false,
    email: action.payload.email,
});

const handleCheckLoginActionAction = (state: LoginUIState): LoginUIState => ({
    ...state,
    atlassianAccountCheckPerformed: false,
    error: undefined,
});

const handleCheckLoginActionSuccess = (state: LoginUIState, action: CheckLoginActionSuccess): LoginUIState => ({
    ...state,
    atlassianAccountCheckPerformed: true,
    authInfo: {
        ...state.authInfo,
        accountStage: action.payload.authInfo.accountStage,
        authMethods: action.payload.authInfo.authMethods,
    },
    error: undefined,
});

const handleCaSsoTestModeFailure = (state: LoginUIState): LoginUIState => ({
    ...state,
    showSsoTestModeErrorPage: true,
});

const handleLoginConsentFailure = (state: LoginUIState): LoginUIState => ({
    ...state,
    showLoginConsentErrorPage: true,
});

const handleCheckLoginActionFailure = (state: LoginUIState, action: CheckLoginActionFailure): LoginUIState => ({
    ...state,
    atlassianAccountCheckPerformed: false,
    error: action.payload.error,
});

const handleForgotPassword = (state: LoginUIState): LoginUIState => ({
    ...state,
    isSendingForgotPasswordEmail: true,
});

const handleForgotPasswordFailure = (state: LoginUIState): LoginUIState => ({
    ...state,
    isSendingForgotPasswordEmail: false,
});

const handleForgotPasswordSuccess = (state: LoginUIState): LoginUIState => ({
    ...state,
    isSendingForgotPasswordEmail: false,
    showPasswordConfirmation: true,
});

const handleLoginAttempt = (state: LoginUIState): LoginUIState => ({
    ...state,
    isSubmitting: true,
    error: undefined,
    loginAttemptResponse: undefined,
});

function handleLoginAttemptSuccess(state: LoginUIState, action: LoginAttemptSuccess): LoginUIState {
    const { loginAttemptResponse } = action.payload;
    const defaultValues = {
        loginAttemptResponse,
        isSubmitting: false,
        error: undefined,
    };
    if (loginAttemptResponse.loginSucceeded) {
        return {
            ...state,
            ...defaultValues,
            email: '',
            atlassianAccountCheckPerformed: false,
        };
    }
    return {
        ...state,
        ...defaultValues,
    };
}

const handleLoginAttemptFailure = (state: LoginUIState, action: LoginAttemptFailure): LoginUIState => ({
    ...state,
    isSubmitting: false,
    loginAttemptResponse: undefined,
    error: action.payload.error,
});

const resetLogin = (action: ResetLogin): LoginUIState => ({ ...defaultState, email: action.payload.email });
