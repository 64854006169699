import type React from 'react';
import { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { useRouterActions } from 'react-resource-router';
import type { Location } from 'react-resource-router';
interface Props {
    when: boolean;
    message: (location: Location) => boolean | string;
    onBeforeNavigate?: (location: Location) => void;
}

const NavBlocker: React.FC<Props> = ({ when, message, onBeforeNavigate }) => {
    di(useRouterActions);
    const { registerBlock } = useRouterActions();
    useEffect(() => {
        const unRegisterBlock = registerBlock((location: Location) => {
            if (!when) {
                return true;
            }
            const result = message(location);
            if (typeof result === 'boolean') {
                return result;
            }
            //RRR doesn't support async block, so we cannot use our custom dialog here.
            //We need to fallback to using browser's confirm like react-router's prompt.
            // eslint-disable-next-line no-alert
            const confirmation = window.confirm(result);

            if (confirmation && onBeforeNavigate) {
                onBeforeNavigate(location);
            }

            return confirmation;
        });
        return unRegisterBlock;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerBlock, when]);

    return null;
};

export default NavBlocker;
