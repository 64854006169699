import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import { Scheduler } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type { CheckLoginActionResponse } from 'rest/login';
import { checkLoginAction } from 'rest/login';
import type {
    CheckLoginAction,
    CheckLoginActionFailure,
    CheckLoginActionSuccess,
    CaSsoTestModeFailure,
} from 'state/actions/login';
import {
    CHECK_LOGIN_ACTION,
    checkLoginActionFailure,
    checkLoginActionSuccess,
    caSsoTestModeFailure,
} from 'state/actions/login';
import type { SetSignupDataAction } from 'state/actions/sign-up';
import { setSignupDataAction } from 'state/actions/sign-up';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getDefaultCheckLoginError, isSsoTestModeError } from '@atlassian/help-center-common-util/errors';
import { history } from '@atlassian/help-center-common-util/history';
import type { AuthInfo } from '@atlassian/help-center-common-util/login';
import { AccountStageType, AuthMethodType, LoginType } from '@atlassian/help-center-common-util/login';
import { getSignupUrl } from '@atlassian/help-center-common-util/url';

export const checkLoginActionEpic: Epic<
    CheckLoginAction,
    CheckLoginActionSuccess | CheckLoginActionFailure | CaSsoTestModeFailure | SetSignupDataAction
> = (action$: ActionsObservable<CheckLoginAction>) => {
    return action$.ofType(CHECK_LOGIN_ACTION).mergeMap((checkLoginActionAction) => {
        const { email, portalId, authData, createAnalyticsEvent } = checkLoginActionAction.payload;
        return checkLoginAction(email, authData)
            .flatMap((response): Observable<CheckLoginActionSuccess | SetSignupDataAction> => {
                const autoInfo = extractAuthInfoFromResponse(response);
                const trackEvent = createAnalyticsEvent?.({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'enteredEmailAndClickedNext',
                    actionSubject: 'jsmPortalUnifiedLogin',
                    attributes: {
                        accountStage: autoInfo.accountStage,
                        authMethodTypes: Array.from(autoInfo.authMethods.keys()),
                    },
                });
                trackEvent && sendEvent(trackEvent);
                if (response.action === LoginType.CUSTOMER_ACCOUNT_SIGNUP) {
                    return Observable.of(setSignupDataAction(email, autoInfo)).do(() => {
                        // Make sure we set the signup state first before doing the transition
                        Scheduler.async.schedule(() => {
                            history.push(getSignupUrl(portalId ? Number.parseInt(portalId, 10) : undefined));
                        }, 0);
                    });
                }
                return Observable.of(checkLoginActionSuccess(response.action, autoInfo));
            })
            .catch((error: AjaxError): Observable<CheckLoginActionFailure | CaSsoTestModeFailure> => {
                if (isSsoTestModeError(error)) {
                    return Observable.of(caSsoTestModeFailure());
                }
                const parsedError = getDefaultCheckLoginError(error);
                return Observable.of(checkLoginActionFailure(parsedError));
            });
    });
};

const getAccountStage = (action: LoginType): AccountStageType => {
    switch (action) {
        case LoginType.CUSTOMER_ACCOUNT_SIGNUP:
        case LoginType.ATLASSIAN_ACCOUNT_SIGNUP:
            return AccountStageType.SIGNUP;
        case LoginType.CUSTOMER_ACCOUNT_LOGIN:
        case LoginType.ATLASSIAN_ACCOUNT_LOGIN:
        default:
            return AccountStageType.LOGIN;
    }
};

const extractAuthInfoFromResponse = (payload: CheckLoginActionResponse): AuthInfo => {
    const { action, methods } = payload;
    const stage = getAccountStage(action);
    if (methods.length === 0) {
        // add observable
        // cleanup entire part when FF is cleaned up - Jira ticket
        switch (action) {
            case LoginType.ATLASSIAN_ACCOUNT_SIGNUP:
            case LoginType.ATLASSIAN_ACCOUNT_LOGIN:
                return {
                    accountStage: stage,
                    authMethods: new Map([[AuthMethodType.ATLASSIAN_ACCOUNT_SSO, {}]]),
                };
            case LoginType.CUSTOMER_ACCOUNT_SIGNUP:
            case LoginType.CUSTOMER_ACCOUNT_LOGIN:
            default:
                return {
                    accountStage: stage,
                    authMethods: new Map([[AuthMethodType.CUSTOMER_ACCOUNT_PASSWORD, {}]]),
                };
        }
    }
    switch (action) {
        case LoginType.ATLASSIAN_ACCOUNT_SIGNUP:
        case LoginType.ATLASSIAN_ACCOUNT_LOGIN: {
            return {
                accountStage: stage,
                authMethods: new Map(
                    methods.map((method) => {
                        return [AuthMethodType.ATLASSIAN_ACCOUNT_SSO, { redirectLink: method.authUrl }];
                    })
                ),
            };
        }
        case LoginType.CUSTOMER_ACCOUNT_SIGNUP:
        case LoginType.CUSTOMER_ACCOUNT_LOGIN:
        default:
            return {
                accountStage: stage,
                authMethods: new Map(
                    methods.map((method) => {
                        if (method.authType === 'SSO') {
                            return [AuthMethodType.CUSTOMER_ACCOUNT_SSO, { redirectLink: method.authUrl }];
                        }
                        return [AuthMethodType.CUSTOMER_ACCOUNT_PASSWORD, {}];
                    })
                ),
            };
    }
};
