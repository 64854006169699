import type { ReactElement } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getThemeColor } from 'state/selectors/help-center';
import { Flex, xcss } from '@atlaskit/primitives';
import Box from '@atlaskit/primitives/box';
import Text from '@atlaskit/primitives/text';
import { N200A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
interface Props {
    label: string;
    ariaLabel?: string;
    themeColor: string;
    icon?: ReactElement;
    onClick: () => void;
    disabled?: boolean;
    customStyles?: object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    forwardedRef?: React.Ref<any>;
}

function DynamicThemingButton({
    label,
    ariaLabel,
    themeColor,
    disabled,
    icon,
    onClick,
    forwardedRef,
    customStyles = {},
    ...customProps
}: Props) {
    return (
        <Box
            {...customProps}
            ref={forwardedRef}
            disabled={disabled}
            aria-label={ariaLabel ? ariaLabel : label}
            as="button"
            onClick={onClick}
            xcss={buttonStyles}
            style={{
                backgroundImage: `linear-gradient(
                to right,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8)
            ),
            linear-gradient(to right, ${themeColor}, ${themeColor})`,
                // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
                ...customStyles,
            }}
        >
            <Flex gap="space.050" alignItems="center">
                {icon}
                <Text as="span">{label}</Text>
            </Flex>
        </Box>
    );
}
const buttonStyles = xcss({
    position: 'relative',
    paddingLeft: 'space.100',
    paddingRight: 'space.100',
    borderRadius: 'border.radius.circle',
    borderColor: 'color.border.inverse',
    borderWidth: 'border.width.outline',
    borderStyle: 'solid',
    color: 'color.text',
    cursor: 'pointer',
    height: '36px',
    '::after': {
        content: '""',
        position: 'absolute',
        top: 'space.0',
        right: 'space.0',
        bottom: 'space.0',
        left: 'space.0',
        borderRadius: 'border.radius.circle',
        background: `${token('color.interaction.hovered', N200A)}`,
        opacity: 0,
        pointerEvents: 'none',
    },
    ':hover::after': {
        opacity: 0.6,
        transition: 'opacity 200ms',
    },
    ':active': {
        transform: 'scale(0.97)',
        transition: 'transform 50ms',
    },
    ':disabled': {
        pointerEvents: 'none',
    },
});

const selector = createSelector(getThemeColor, (themeColor) => ({
    themeColor,
}));

export default connect(selector, {})(DynamicThemingButton);
