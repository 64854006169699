import { defineMessages } from 'react-intl-next';

export default defineMessages({
    currentPasswordMissingError: {
        defaultMessage: 'You must type your current password',
        description: 'error message when the current password field is blank in the change password dialog',
        id: 'cv.user.profile.change.password.current.error',
    },
    newPasswordMissingError: {
        defaultMessage: 'You must specify a new password',
        description: 'error message when the new password field is blank in the change password dialog',
        id: 'cv.user.profile.change.password.new.error',
    },

    newPasswordTooShortError: {
        defaultMessage: 'Password is too short',
        description: 'Error message that will show under the password field if it is shorter than 8 characters.',
        id: 'cv.user.profile.change.password.new.too.short.error',
    },

    newPasswordTooLongError: {
        defaultMessage: 'Password may not contain more than 100 characters',
        description: 'Error message when the password field value is too long',
        id: 'cv.user.profile.change.password.too.new.long.error',
    },

    confirmPasswordMissingError: {
        defaultMessage: 'You must re-type the new password',
        description: 'error message when the confirm new password field is blank in the change password dialog',
        id: 'cv.user.profile.change.password.confirm.error',
    },
    confirmPasswordDoesNotMatchError: {
        defaultMessage: 'Passwords do not match',
        description:
            'error message when the confirmation password does not match the new password in the change password dialog',
        id: 'cv.user.profile.change.password.confirm.mismatch.error',
    },
    profileDetailsUpdatedHeaderMessage: {
        defaultMessage: 'Profile details updated',
        description: 'message header when the profile details are updated.',
        id: 'cv.user.profile.field.save.success.header',
    },
    profileDetailsUpdatedBodyMessage: {
        defaultMessage: 'Your changes may take up to five minutes to appear.',
        description: 'message body when the profile details are updated.',
        id: 'cv.user.profile.field.save.success.body',
    },
});
