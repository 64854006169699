// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import * as bp from 'view/styles/breakpoints';
import * as elevation from 'view/styles/elevation';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { colors, gridSize } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';
import { SIDEBAR_WIDTH_LARGE_MULTIPLIER } from '@atlassian/help-center-common-component/pushing-customize-sidebar/styled';

export const customScreenSizeMedium = (110 + SIDEBAR_WIDTH_LARGE_MULTIPLIER) * gridSize();
export const customScreenSizeBig = (155 + SIDEBAR_WIDTH_LARGE_MULTIPLIER) * gridSize();

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const portalsCardTitleStyle = css`
    margin-left: ${token('space.400', '32px')};
    padding-left: ${token('space.050', '4px')};

    ${bp.fromXSmall.css`
    margin-left: 0;
    `};
`;
// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Title = styled.h2`
    ${fonts.h500};
    align-self: flex-start;
    box-sizing: border-box;
    margin: ${token('space.250', '20px')} auto ${token('space.200', '16px')} 0;
    ${portalsCardTitleStyle}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const portalCardsContainerStyle = css`
    max-width: ${grid.multiple(42).px};

    ${bp.fromMedium.css`
        max-width: ${grid.multiple(90).px};
    `}
    ${bp.fromLarge.css`
        max-width: ${grid.multiple(145).px};
    `}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const portalCardsContainerWithSidebarStyle = css`
    max-width: ${grid.multiple(42).px};

    ${bp.fromCustom(customScreenSizeMedium).css`
        max-width: ${grid.multiple(90).px};
    `}
    ${bp.fromCustom(customScreenSizeBig).css`
        max-width: ${grid.multiple(145).px};
    `}
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const TopicsSection = styled.div<{ count: number }>`
    width: ${grid.multiple(33).px};
    margin-left: auto;
    margin-right: auto;
    ${bp.fromXSmall.css`
        width: 100%;
    `};
    ${bp.fromMedium.css`
        width: ${({ count }) => {
            if (count > 1) {
                return '100%';
            }
            return `${grid.multiple(42).px}`;
        }};
        margin-left: 0;
    `};
    margin-top: ${token('space.400', '32px')};
    border-radius: ${mixins.borderRadiusValue};
    background-color: ${token('elevation.surface.raised', colors.N0)};
    ${elevation.e100}
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const TopicsList = styled.ol<{ count: number }>`
    box-sizing: border-box;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: ${token('space.200', '16px')};
    & > li {
        flex: 0 0 100%;
    }
`;
