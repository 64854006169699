import type { State } from 'state';
import { getCanAdministerProject } from 'state/selectors/portal';
import { getAgentForPortal, getAccountId } from 'state/selectors/user';
import type { User, Portal } from '../events';

export interface IssueAnalyticsContext {
    user: User;
    portal: Portal;
}

export const getIssueAnalyticsContext = (state: State, portalId: number | undefined) => ({
    user: {
        getAgentForPortal: () => getAgentForPortal(state),
        getAccountId: () => getAccountId(state),
    },
    portal: {
        getPortalAnnouncement: () => ({
            getCanAdministerProject: () => getCanAdministerProject(state, portalId),
        }),
    },
});
