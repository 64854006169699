import type { PropsWithChildren } from 'react';
import React from 'react';
import { compose } from 'redux';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { withRouter } from 'react-resource-router';
import { EditTopicPage } from 'topics/src/ui/edit-topic';
import { SplashLayoutRoute } from 'view/layout';
import { authPageMessage, callToActionUrlText } from 'view/portal-reorder-experience';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import { editTopicResource } from './resources';

export const TOPIC_BASE_PATH = `/topic`;
const ADD_TOPIC_PATH = `${TOPIC_BASE_PATH}/add`;
const EDIT_TOPIC_PATH = `${TOPIC_BASE_PATH}/edit/:id`;

interface Path {
    location: {
        pathname: string;
    };
}

export const EditTopicPageLayout = injectIntl((props: PropsWithChildren<WrappedComponentProps> & Path) => {
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: props.location.pathname,
        }),
        withOnlyJiraAdmin({
            message: props.intl.formatMessage(authPageMessage),
            callToActionText: props.intl.formatMessage(callToActionUrlText),
            callToActionUrl: '/portals',
        })
    )(() => <SplashLayoutRoute path={EDIT_TOPIC_PATH} component={EditTopicPage} screenName={ScreenName.EDIT_TOPIC} />);
    return <WrappedComponent {...props} />;
});

export const editTopicRoute = {
    name: ScreenName.EDIT_TOPIC,
    path: EDIT_TOPIC_PATH,
    exact: true,
    component: withRouter(EditTopicPageLayout),
    resources: [editTopicResource],
};

export const addTopicRoute = {
    name: ScreenName.EDIT_TOPIC,
    path: ADD_TOPIC_PATH,
    exact: true,
    component: withRouter(EditTopicPageLayout),
    resources: [editTopicResource],
};
