import { getActiveTraceHttpRequestHeaders } from '@atlaskit/react-ufo/experience-trace-id-context';
import { Observable } from './rxjs';

const extendHeadersWithTracing = (url: string, originalHeaders: object = {}) => ({
    ...originalHeaders,
    ...getActiveTraceHttpRequestHeaders(url),
});

export const traceableAjaxGet = (url: string, headers?: object) => {
    return Observable.ajax.get(url, extendHeadersWithTracing(url, headers));
};

export const traceableAjaxPost = (url: string, body?: unknown, headers?: object) => {
    return Observable.ajax.post(url, body, extendHeadersWithTracing(url, headers));
};

export const traceableAjaxPut = (url: string, body?: unknown, headers?: object) => {
    return Observable.ajax.put(url, body, extendHeadersWithTracing(url, headers));
};

export const traceableAjaxDelete = (url: string, headers?: object) => {
    return Observable.ajax.delete(url, extendHeadersWithTracing(url, headers));
};
