import { isFixRelayIdConflictInHelpCenter } from 'feature-flags';
import type { DataID } from 'relay-runtime';
import RelayDataID from '@atlassian/relay-data-id';

export const getRelayDataId = (id: string, typeName: string): DataID | undefined => {
    if (isFixRelayIdConflictInHelpCenter()) {
        return RelayDataID({ id }, typeName);
    }

    return id;
};
