import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { LOGIN_ROUTE } from 'routes/common/constants';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { ThemedLink } from '@atlassian/help-center-common-component/themed-link';
import { getRelativeBasePath } from '@atlassian/help-center-common-util/history';
import type { PersistedError } from 'state/persisted/types';

interface ErrorLinkProps extends Partial<PersistedError> {
    callToActionUrl: string;
    children?: React.ReactNode;
}

export const ErrorLink: React.FC<ErrorLinkProps> = ({ callToActionUrl, children }) => {
    if (callToActionUrl.includes(LOGIN_ROUTE)) {
        return (
            <AnchorContainer actionSubjectId="redirectToLogin" href={getRelativeBasePath() + callToActionUrl}>
                {children}
            </AnchorContainer>
        );
    }

    return (
        <Link actionSubjectId="callToAction" to={callToActionUrl}>
            {children}
        </Link>
    );
};

ErrorLink.displayName = 'ErrorLink';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Link = styled(ThemedLink)`
    text-align: center;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const AnchorContainer = styled(HtmlAnchor)`
    text-align: center;
`;
