import * as React from 'react';
import { noop } from 'lodash';
import memoizeOne from '@atlassian/help-center-common-util/memoize-one';
import { WrmModuleLoader } from '../wrm-loader';

interface Props {
    locale: string;
    children: React.ReactNode;
}

interface State {
    resourcesLoaded: boolean;
}

interface WrmContext extends State {
    setResourcesLoaded: () => void;
}

export const DeferredWrmResourceContext = React.createContext<WrmContext>({
    resourcesLoaded: false,
    setResourcesLoaded: noop,
});

const DEFERRED_WRM_CONTEXT = ['deferred.customer.portal.wrm'];
const getDeferredWrmContexts = memoizeOne((locale: string) => [
    ...DEFERRED_WRM_CONTEXT,
    `jira.webresources:calendar-${locale}`,
]);

/**
 *  A simple context provider that loads deferred portal wrm resources, and allows consuming components that have a wrapping withAsyncWrmLoading
 *  to check to see if the deferred WRM resources already have been loaded before rendering
 *
 *  We can remove this provider once the connect framework is available via NPM
 */
export const DeferredWrmResourceProvider = ({ children, locale }: Props) => {
    const [resourcesLoaded, setLoaded] = React.useState<boolean>(false);

    const setResourcesLoaded = React.useCallback(() => {
        setLoaded(true);
    }, [setLoaded]);

    return (
        <DeferredWrmResourceContext.Provider
            value={{
                resourcesLoaded,
                setResourcesLoaded,
            }}
        >
            <WrmModuleLoader onResourcesLoaded={setResourcesLoaded} resourceKeys={getDeferredWrmContexts(locale)} />

            {children}
        </DeferredWrmResourceContext.Provider>
    );
};
