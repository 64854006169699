import type { Env } from '@atlassian/help-center-common-util/env';

export const UPDATE_ENV = 'state.persisted.env.UPDATE_ENV';
export const UPDATE_XSRF = 'state.persisted.env.UPDATE_XSRF';

export interface UpdateEnvAction {
    payload: Env;
    type: typeof UPDATE_ENV;
}

export interface UpdateXsrfAction {
    payload: string;
    type: typeof UPDATE_XSRF;
}

export const updateEnvAction = (env: Env): UpdateEnvAction => ({
    payload: env,
    type: UPDATE_ENV,
});

export const updateXsrfAction = (xsrf: string): UpdateXsrfAction => ({
    payload: xsrf,
    type: UPDATE_XSRF,
});
