import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { WebFragment } from 'state/persisted/connect-addon';
import type { ConnectFragmentLocation } from 'state/selectors/connect-addon';
import { token } from '@atlaskit/tokens';
import { ConnectFragmentDumb } from '../connect-fragment-dumb';
import type { ErrorResponse } from 'epics/model/types';

export interface Props {
    connectFragments: WebFragment[] | { error: ErrorResponse } | undefined;
    className?: string;
    location: ConnectFragmentLocation;
}

const ConnectFragmentsDumb = ({ connectFragments, className, location }: Props) => {
    if (!connectFragments || 'error' in connectFragments) {
        return null;
    }

    if (connectFragments.length) {
        return (
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            <Root className={className}>
                {connectFragments.map((fragment: WebFragment) => (
                    <ConnectFragmentDumb
                        key={fragment.key}
                        fullKey={fragment.key}
                        location={location}
                        fragmentHtml={fragment.html}
                    />
                ))}
            </Root>
        );
    }

    return null;
};

export default ConnectFragmentsDumb;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Root = styled.div`
    margin-top: ${token('space.400', '32px')};
`;
