import React, { useCallback } from 'react';
import { EngagementProvider, EngagementMessages } from '@atlassiansox/engagekit-ts';
import ErrorBoundary from 'view/error-boundary/error-boundary';
import { reportError } from '@atlassian/help-center-common-util/sentry';
import type { EngagementProviderProps } from './types';

export const EngagementProviderWrapper = ({ config, analyticsClientInstance, children }: EngagementProviderProps) => {
    const epConfig = {
        environment: config.environment,
        cloudId: config.tenantId,
        aaid: config.accountId,
        locale: config.userLocale,
        product: 'jira',
        subProduct: 'serviceDesk',
        stargateUrl: '/gateway/api',
    };

    const renderOnError = useCallback(() => {
        return children;
    }, [children]);

    return (
        <ErrorBoundary id={'engagement-provider-error'} renderError={renderOnError} onError={reportError}>
            {/* @ts-ignore: HOT-102522 - EngagementProvider needs analyticsClientInstance prop to send message interaction events */}
            <EngagementProvider
                config={epConfig}
                analyticsClientInstance={analyticsClientInstance}
                coordinationOptions={{ enableChoreographer: true }}
            >
                <>
                    <EngagementMessages />
                    {children}
                </>
            </EngagementProvider>
        </ErrorBoundary>
    );
};
