import { defineMessages } from 'react-intl';

export default defineMessages({
    genericErrorTitle: {
        id: 'cv.generic.error.title',
        defaultMessage: 'Error',
        description: 'Title of the error panel shown when a generic error has occurred.',
    },
    genericErrorDescription: {
        id: 'cv.generic.error.description',
        defaultMessage: "We couldn't submit your request. Refresh the page and try again.",
        description: 'Message body of the error panel shown when a generic error has occurred.',
    },
    genericSignUpErrorDescriptionUnified: {
        id: 'cv.generic.sign.up.error.description.unified',
        defaultMessage:
            "We couldn't submit your request. Refresh the page and try again. If you continue to experience issues, {contactAdminLinkUnified}.",
        description:
            'Message body of the error panel shown when a generic error has occurred when a customer is signing up.',
    },
    genericSignUpErrorNoContactLinkDescription: {
        id: 'cv.generic.sign.up.error.no.contact.link.description.unified',
        defaultMessage: 'We couldn’t submit your request. Refresh the page and try again.',
        description:
            'Message body of the error panel shown when a generic error has occurred when a customer is signing up.',
    },
    refreshPageLinkText: {
        id: 'cv.generic.refresh.page.link.text',
        defaultMessage: 'refresh',
        description: 'Text shown for a refresh the page link.',
    },
    tellUsAboutIssueLinkText: {
        id: 'cv.generic.tell.about.issue.link.text',
        defaultMessage: 'tell us about this issue',
        description: 'Text shown for the tell us about this issue link, used to report errors.',
    },
    letUsKnowLinkText: {
        id: 'cv.generic.let.us.know.link.text',
        defaultMessage: 'let us know',
        description: 'Text shown for the let us know link, used to report errors.',
    },
    genericErrorRefreshPageAction: {
        id: 'cv.generic.error.refresh.page.action',
        defaultMessage: 'Refresh page',
        description: 'Label of the refresh page button on the error panel shown when a generic error has occurred.',
    },
    noNetworkTitle: {
        id: 'cv.no.network.title',
        defaultMessage: 'Check your connection',
        description:
            'Title of the error panel shown when a network connection problem prevents an action from being performed.',
    },
    noNetworkDescription: {
        id: 'cv.no.network.description.unified',
        defaultMessage: "It looks like you've lost your internet connection. Check your network and try again later.",
        description:
            'Message body of the error panel shown when a network connection problem prevents an action from being performed.',
    },

    signupEmailResendRateLimitedDescription: {
        defaultMessage: 'There’s an issue with sending the email at the moment. Try again later in 10 minutes',
        description: 'Message to notify the user that the sign up email has been rate limited',
        id: 'cv.sign.up.email.resend.rate.limited.description',
    },
    blockedTitle: {
        id: 'cv.signup.blocked.title',
        defaultMessage: 'Access Blocked',
        description: 'Title of the error panel shown when a blocked action occurs.',
    },
    blockedDescription: {
        id: 'cv.signup.blocked.description',
        defaultMessage: "The email isn't approved for access. Use another email and try again.",
        description: 'Message body of the error panel  shown when a blocked action occurs.',
    },
    forbiddenTitle: {
        id: 'cv.signup.forbidden.title',
        defaultMessage: 'Access Forbidden',
        description: 'Title of the error panel shown when a forbidden action occurs.',
    },
    forbiddenDescription: {
        id: 'cv.signup.forbidden.description',
        defaultMessage: "We couldn't give you access. Use another email and try again.",
        description: 'Message body of the error panel shown when a forbidden action occurs.',
    },
    invalidEmailTitle: {
        id: 'cv.signup.email.invalid.title',
        defaultMessage: 'Invalid Email',
        description: 'Title of the error panel shown when a invalid email is entered.',
    },
    invalidEmailDescription: {
        id: 'cv.signup.email.invalid.description',
        defaultMessage: 'You must specify a valid email address.',
        description: 'Message body of the error panel shown when a invalid email is entered.',
    },
    userProfileForgotPasswordFailedFlagTitle: {
        id: 'cv.user.profile.forgot.password.failed.flag.title',
        defaultMessage: "We're unable to send a link",
        description:
            'Title for the error flag shown when system fails to send email after user clicks on forgot password link',
    },
    userProfileForgotPasswordFailedFlagDescription: {
        id: 'cv.user.profile.forgot.password.failed.flag.description',
        defaultMessage: 'Something went wrong while trying to send you a reset password link. Try again.',
        description:
            'Description for the error flag shown when system fails to send email after user clicks on forgot password link',
    },
    userProfileForgotPasswordRateLimitedFlagTitle: {
        id: 'cv.user.profile.forgot.password.rate_limited.flag.title',
        defaultMessage: "We're unable to send a link",
        description:
            'Title for the error flag shown when system fails to send email after user clicks on forgot password link',
    },
    userProfileForgotPasswordRateLimitedFlagDescription: {
        id: 'cv.user.profile.forgot.password.rate_limited.flag.description',
        defaultMessage:
            'Something went wrong while trying to send you a reset password link. Try again in a few minutes.',
        description:
            'Description for the error flag shown when system fails to send email after user clicks on forgot password link',
    },
});
