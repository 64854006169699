import * as React from 'react';

// TODO https://jdog.jira-dev.com/browse/FSD-3964: use more accurate types
export interface InjectedProps {
    portalId?: string | undefined;
    requestTypeId?: string | undefined;
    requestGroupId?: string | undefined;
    categoryId?: string | undefined;
    requestKey?: string | undefined;
    articleId?: string | undefined;
}

const RouteParamContext = React.createContext<InjectedProps>({});

export const PathParamsProvider: React.FunctionComponent<{
    children: React.ReactNode;
    params: InjectedProps;
}> = (props) => <RouteParamContext.Provider value={props.params}>{props.children}</RouteParamContext.Provider>;

const withPathParams =
    <TProps extends InjectedProps>(
        WrappedComponent: React.ComponentType<TProps>
    ): React.FunctionComponent<Omit<TProps, keyof InjectedProps>> =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: TProps) => (
        <RouteParamContext.Consumer>
            {(value: InjectedProps) => <WrappedComponent {...props} {...value} />}
        </RouteParamContext.Consumer>
    );

export default withPathParams;
