import { isAdvancedCustomizationEnabled } from 'feature-flags';
import { withRouter } from 'react-resource-router';
import { PortalReorderingRoute } from 'view/portal-reorder-experience';
import { helpEditTopicsResource, hiddenSectionWarningResource } from './resources';

export const portalReorderRoute = (shouldLoadHelpEditTopicsResource = false) => {
    const resources = [];
    if (shouldLoadHelpEditTopicsResource) {
        resources.push(helpEditTopicsResource);
    }
    if (isAdvancedCustomizationEnabled()) {
        resources.push(hiddenSectionWarningResource);
    }

    return {
        resources,
        path: '/settings/portal-reorder',
        exact: true,
        component: withRouter(PortalReorderingRoute),
        name: 'portal-reorder',
    };
};
