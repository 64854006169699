import { defineMessages } from 'react-intl';

export default defineMessages({
    forgotPasswordSuccessFlagTitle: {
        defaultMessage: 'Check your email to reset your password',
        description: 'Message title for toast flag when the forgot password action is successful.',
        id: 'cv.user.profile.forgot.password.success.flag.title',
    },
    forgotPasswordSuccessFlagDescription: {
        defaultMessage: "We've sent a reset password link to {emailAddress}.",
        description: 'Message descritpion for toast flag when the forgot password action is successful.',
        id: 'cv.user.profile.forgot.password.success.flag.description',
    },
});
