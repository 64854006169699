import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { getReadCredentialsForRequest } from 'rest/portal-media';
import type {
    GetReadCredentials,
    GetReadCredentialsSuccess,
    GetReadCredentialsFailure,
} from 'state/actions/request-details';
import {
    GET_MEDIA_READ_CREDENTIALS,
    getReadCredentialsSuccess,
    getReadCredentialsFailure,
} from 'state/actions/request-details';

export const getMediaReadCredentialsEpic: Epic<
    GetReadCredentials,
    GetReadCredentialsSuccess | GetReadCredentialsFailure
> = (action$) => {
    return action$.ofType(GET_MEDIA_READ_CREDENTIALS).mergeMap((getReadCredentialsAction) => {
        const payload = getReadCredentialsAction.payload;
        return getReadCredentialsForRequest(payload.portalId, payload.requestId)
            .map((getReadCredentialsResponse) => {
                return getReadCredentialsSuccess(payload.requestKey, getReadCredentialsResponse);
            })
            .catch((_: AjaxError) => {
                return Observable.of(getReadCredentialsFailure());
            });
    });
};
