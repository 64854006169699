import type { StoreActionApi } from 'react-sweet-state';
import { intentAnswerExperience } from '../../experiences';
import type { State } from '../types';
import { constructHelpseekerMessage } from './construct-helpseeker-message';
import { sendHelpseekerMessage } from './send-helpseeker-message';

export const addJiraFieldMessage =
    (fieldId: string, value: string, messageContent?: string) =>
    ({ dispatch }: StoreActionApi<State>) => {
        const helpseekerMessage = dispatch(
            constructHelpseekerMessage({
                authorType: 'HELPSEEKER',
                content: messageContent || value,
                jiraField: {
                    fieldId,
                    value,
                },
            })
        );
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        intentAnswerExperience.start();
        return dispatch(sendHelpseekerMessage(helpseekerMessage));
    };
