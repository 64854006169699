import { get } from 'epics/ajax';
import type { Observable } from 'epics/rxjs';
import { pickBy, isNil } from 'lodash';
import * as qs from 'query-string';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { RequestTypeSearchResponse } from './types';

interface RequestTypesQuery {
    query: string;
    resultLimit: number;
    includeRequestTypesFromOtherPortals: boolean;
    serviceDeskId?: number | null;
}

export const searchRequestTypes = (requestTypesQuery: RequestTypesQuery): Observable<RequestTypeSearchResponse[]> => {
    const sanitizedQuery = pickBy(requestTypesQuery, (prop) => !isNil(prop));
    const queryString = qs.stringify(sanitizedQuery);

    return get(`${contextPath}/rest/servicedesk/search/1/search/request-type?${queryString}`).map((ajaxResponse) => {
        // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return ajaxResponse.response.requestTypes as RequestTypeSearchResponse[];
    });
};
