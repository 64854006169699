import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { di } from 'react-magnetic-di';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import BookIcon from '@atlaskit/icon/glyph/book';
import HighlightsIcon from '@atlaskit/icon/glyph/highlights';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GroupBoxLink } from '@atlassian/help-center-common-component/group-box';

export interface ArticleListItemProps {
    title: string;
    portalId: number;
    categoryId: string;
    articleId: string;
    summaryExcerpt: string;
    isFeatured?: boolean;
    onClick?: () => void;
}

const ArticleListItem: React.FunctionComponent<ArticleListItemProps> = ({
    portalId,
    categoryId,
    articleId,
    title,
    summaryExcerpt,
    onClick,
    isFeatured,
}) => {
    di(HighlightsIcon, BookIcon, ArticleDescription, GroupBoxLink);
    const Icon = isFeatured ? HighlightsIcon : BookIcon;
    const articleUrl = `/portal/${portalId}/topic/${categoryId}/article/${articleId}`;

    const analyticData = {
        attributes: {
            articleId,
            portalId,
            categoryId,
        },
    };

    return (
        <AnalyticsContext data={analyticData}>
            <ListItem>
                <GroupBoxLink
                    actionSubjectId="article"
                    to={articleUrl}
                    onClick={onClick}
                    heading={title}
                    description={<ArticleDescription dangerouslySetInnerHTML={{ __html: summaryExcerpt }} />}
                    icon={
                        <Icon
                            label=""
                            aria-hidden="true"
                            size="large"
                            primaryColor={token('color.icon', colors.N500)}
                        />
                    }
                    lineClamp={2}
                />
            </ListItem>
        </AnalyticsContext>
    );
};

export default ArticleListItem;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ListItem = styled.li`
    margin: 0;
    list-style: none;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ArticleDescription = styled.p`
    ${mixins.multiLineClamp(2, grid.multiple(2.5).unitless)};
    margin: 0;
`;
