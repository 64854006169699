import type { ConnectPageName, WebFragmentResponse, CustomMenuLinkResponse } from 'rest/connect-addon';
import type { State } from 'state';
import type { WebFragment } from 'state/persisted/connect-addon';
import { generateKey } from 'state/persisted/connect-addon';
import type { ErrorResponse } from 'epics/model/types';

export type ConnectFragmentLocation =
    | 'headerPanels'
    | 'subheaderPanels'
    | 'footerPanels'
    | 'propertyPanels'
    | 'detailsPanels'
    | 'optionPanels'
    | 'requestCreatePanel'
    | 'profilePagePanel';

// These are panels whose content can potentially change based on request key
type RequestSpecificPanels = 'detailsPanels' | 'optionPanels';

interface GetConnectAddonPanelsParams {
    state: State;
    pageName: ConnectPageName;
    location: ConnectFragmentLocation;
    portalId?: number;
    requestTypeId?: number;
    requestKey?: string;
}

export const getConnectAddonPanels = ({
    state,
    pageName,
    location,
    portalId,
    requestTypeId,
    requestKey,
}: GetConnectAddonPanelsParams): WebFragment[] | { error: ErrorResponse } | undefined => {
    switch (location) {
        case 'detailsPanels':
        case 'optionPanels':
            return getRequestSpecificConnectAddonPanels({
                state,
                location,
                requestKey,
            });
        default: {
            const page = state.persisted.connectFragments[generateKey(pageName, portalId, requestTypeId, requestKey)];
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return page && page[location];
        }
    }
};

interface GetRequestSpecificConnectAddonPanelsParams {
    state: State;
    location: RequestSpecificPanels;
    requestKey?: string;
}

export const getRequestSpecificConnectAddonPanels = ({
    state,
    location,
    requestKey,
}: GetRequestSpecificConnectAddonPanelsParams): WebFragment[] | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const requestDetailsForKey = requestKey && state && state.persisted && state.persisted.requestDetails[requestKey!];
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return requestDetailsForKey ? requestDetailsForKey[location] : [];
};

export const getProfileConnectAddonPanels = (
    state: State
): WebFragmentResponse[] | { error: ErrorResponse } | undefined => {
    return state.persisted.profileWebFragments.panels;
};

export const getProfileConnectAddonActions = (state: State): CustomMenuLinkResponse[] | undefined => {
    return state.persisted.profileWebFragments.actions;
};
