/**
 * @generated SignedSource<<408cb2f16544e2a49aff342bf52dfa98>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type settingsBreadcrumbsFragment$data = {
  readonly name: {
    readonly default: string;
  } | null | undefined;
  readonly slug: string | null | undefined;
  readonly " $fragmentType": "settingsBreadcrumbsFragment";
};
export type settingsBreadcrumbsFragment$key = {
  readonly " $data"?: settingsBreadcrumbsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"settingsBreadcrumbsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "settingsBreadcrumbsFragment",
  "selections": [
    {
      "concreteType": "HelpCenterName",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "default"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "slug"
    }
  ],
  "type": "HelpCenter"
};

(node as any).hash = "0716469cbe624819ae2ea879ee690b4e";

export default node;
