import * as React from 'react';
import { AutoScaledImage } from '@atlassian/help-center-common-component/auto-scaled-image';
import { BreadCrumbs } from '@atlassian/help-center-common-component/bread-crumbs';
import {
    SubHeadingContainer,
    IconContainer,
    SubHeading,
    Description,
} from '@atlassian/help-center-common-component/paper-header/styled';

export interface PaperHeaderProps {
    subHeading: string | undefined;
    description: string | undefined;
    logoUrl?: string | undefined;
    portalId?: number;
    categoryId?: string;
    showRequestsInBreadcrumbs?: boolean;
    dangerouslyAllowHtmlInDescription?: boolean;
    requestKey?: string | undefined;
    requestUrl?: string | undefined;
    dataTestId?: string;
    additionalBreadCrumbs?: React.ReactElement[];
}

const headerDescription = (description: string, dangerouslyAllowHtmlInDescription?: boolean) =>
    dangerouslyAllowHtmlInDescription ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
    ) : (
        <Description>{description}</Description>
    );

const PaperHeader: React.FC<PaperHeaderProps> = ({
    subHeading,
    description,
    logoUrl,
    showRequestsInBreadcrumbs,
    dangerouslyAllowHtmlInDescription,
    dataTestId,
    ...props
}) => (
    <div data-testid={dataTestId}>
        <BreadCrumbs {...props} showRequests={showRequestsInBreadcrumbs} />

        <SubHeadingContainer>
            {!!logoUrl && (
                <IconContainer>
                    <AutoScaledImage src={logoUrl} alt="" />
                </IconContainer>
            )}
            <div>
                <SubHeading>{subHeading}</SubHeading>
            </div>
        </SubHeadingContainer>

        {!!description && headerDescription(description, dangerouslyAllowHtmlInDescription)}
    </div>
);

export default PaperHeader;
