/**
 * Webpack dev assets without hashes
 */
const webpackDevAssetsRegexp = /(.*)\.(?:chunk|bundle)(_[a-z]+-[A-Z]+)?\.js$/;

/**
 * Webpack prod assets with hashes
 */
const webpackProdAssetsRegexp = /(.*)\.(?:chunk|bundle)\..*(_[a-z]+-[A-Z]+)?\.js$/;

/**
 * jira.webresources:calendar.js asset with localization string
 */
const calendarWRMRegexp = /(jira.webresources:calendar)(-[a-z]+).js$/;

/**
 * This returns the base name of the asset/url that is getting loaded.
 * eg: for: https://<your-domain>/assets/servicedesk-vendor.chunk.js
 * return servicedesk-vendor.chunk.js
 * @param assetPathOrURL all the external asset/path urls,  eg: anything refereced using <link>, <script> etc tag
 */
const getBaseName = (assetPathOrURL: string): string =>
    assetPathOrURL.split('#')[0].split('?')[0].split('/').pop() || '';

/**
 * This plugin will sanitize asset paths (all external resources), so that final url contains only the asset name and doesn't include variable info such as localization strings and hashes.
 *
 * eg: Request URL: https://<domainName>/assets/servicedesk-vendor.chunk.4d660eea180b440720c5_en-GB.js
 will be sanitized as servicedesk-vendor.js

 * @param isDevelopment should return true for development mode
 */
export const sanitizeAssetName = (isDevelopment: boolean) => (assetPathOrURL: string) => {
    const assetBaseName = getBaseName(assetPathOrURL);

    const webpackAssetsMatch = assetBaseName.match(isDevelopment ? webpackDevAssetsRegexp : webpackProdAssetsRegexp);
    if (webpackAssetsMatch) {
        return `${webpackAssetsMatch[1]}.js`;
    }

    const calendarWRMMatch = assetBaseName.match(calendarWRMRegexp);
    if (calendarWRMMatch) {
        return `${calendarWRMMatch[1]}.js`;
    }

    return assetBaseName;
};
