import * as React from 'react';
import { isCsmPremiumSlaDisabledFlagEnabled } from 'feature-flags';
import { noop } from 'lodash';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { ExperienceName } from '@atlassian/help-center-common-component/constants';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import type { ExperienceAction, ExperienceError } from '@atlassian/help-center-common-util/analytics';
import { sendExperienceEvent } from '@atlassian/help-center-common-util/analytics';
import { getEnv } from '@atlassian/help-center-common-util/env';
interface ExperienceProps {
    /**
     * Set this prop to false while waiting for an experience to fully load in.
     * When fully loaded set this prop to true.
     * This allows child ExperienceNotifiers to notify success asynchronously while
     * the experience is loading in.
     */
    isExperienceReady?: boolean;

    /**
     * Name of the experience. Be descriptive!
     */
    name: ExperienceName;

    /**
     * If the experience has an id (for example viewing a request) give it here.
     */
    experienceId?: string;

    children: React.ReactNode;
}

export const ExperienceContext = React.createContext<{
    onPending: () => void;
    onSuccess: () => void;
    onFailure: (err: ExperienceError) => void;
}>({ onFailure: noop, onPending: noop, onSuccess: noop });

export class Experience extends React.Component<ExperienceProps & WithAnalyticsEventsProps> {
    static checkNotifiersInterval = 1000;
    static defaultProps = {
        isExperienceReady: true,
    };

    /**
     * This value is needed so the timer doesn't start until
     * at least one notifier has been turned on.
     */
    notifierHasBegunPending: boolean = false;
    timerId: number | undefined = undefined;
    pendingCount: number = 0;
    hasTrackedExperience: boolean = false;

    componentDidMount() {
        this.checkAndStartTimer();
        this.trackExperience('taskStart');
    }

    componentDidUpdate(prevProps: ExperienceProps) {
        if (!prevProps.isExperienceReady && this.props.isExperienceReady) {
            this.checkAndStartTimer();
        }
    }

    /**
     * Called when a descendant experience notifier is waiting to see if it is successful or not.
     */
    onPending = () => {
        this.pendingCount += 1;
        this.notifierHasBegunPending = true;
        this.checkAndStartTimer();
    };

    /**
     * Called when a descendant experience notifier successfully loaded.
     * ALL descendant experience notifiers need to successfully load for a SUCCESS
     * to be considered.
     */
    onSuccess = () => {
        this.pendingCount -= 1;
        this.checkAndStartTimer();
    };

    /**
     * Called when a descendant experience notifier failed to load.
     * This caused the entire experience to FAIL.
     */
    onFailure = (failure: ExperienceError) => {
        // We need to track failure *immediately*, otherwise this component may
        // be unmounted and we'd miss our chance.
        this.trackFailure(failure);
    };

    checkAndStartTimer() {
        if (this.notifierHasBegunPending && this.props.isExperienceReady) {
            this.startTimer();
        }
    }

    componentDidCatch(error: Error) {
        const { name, message } = error;
        // This catches any errors that bubble up to the Provider that
        // may not be caught by any ExperienceTrackers
        this.trackFailure({
            errorMessage: message,
            errorName: name,
            unhandledError: true,
            errorLocation: this.props.name,
        });
        // We're deliberately rethrow the error here because we don't want to interfere with
        // any error handling the container app may have in place.
        throw error;
    }

    startTimer() {
        clearTimeout(this.timerId);

        this.timerId = window.setTimeout(() => {
            if (this.pendingCount === 0) {
                this.trackSuccess();
            }
        }, Experience.checkNotifiersInterval);
    }

    trackSuccess() {
        this.trackExperience('taskSuccess');
    }

    trackFailure(failure: ExperienceError) {
        this.trackExperience('taskFail', failure);
    }

    trackExperience(action: ExperienceAction, errorFailure?: ExperienceError) {
        if (this.hasTrackedExperience) {
            // Ignore any follow up tracking fired after the experience has been tracked.
            return;
        }

        const { createAnalyticsEvent, experienceId } = this.props;
        let { name } = this.props;

        // Adding the check to avoid firing Premium SLA event for CSM help center
        // This check will be removed once CSM is migrated to SSR
        if (isCsmPremiumSlaDisabledFlagEnabled() && isCSMHelpCenter(getEnv().helpCenterType)) {
            switch (name) {
                case ExperienceName.HELP_CENTER:
                    name = ExperienceName.HELP_CENTER_CSM;
                    break;
                case ExperienceName.REQUEST_CREATE:
                    name = ExperienceName.REQUEST_CREATE_CSM;
                    break;
                default:
                    return;
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const event = createAnalyticsEvent!({});

        switch (action) {
            case 'taskFail':
                sendExperienceEvent(
                    {
                        action,
                        name,
                        experienceId,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        error: errorFailure!,
                    },
                    event
                );
                break;

            case 'taskSuccess':
                sendExperienceEvent(
                    {
                        action,
                        name,
                        experienceId,
                    },
                    event
                );
                break;

            case 'taskStart':
            default:
                sendExperienceEvent(
                    {
                        action,
                        name,
                        experienceId,
                    },
                    event
                );
                break;
        }

        if (action !== 'taskStart') {
            this.hasTrackedExperience = true;
        }
    }

    render() {
        return (
            <ExperienceContext.Provider
                value={{
                    onPending: this.onPending,
                    onSuccess: this.onSuccess,
                    onFailure: this.onFailure,
                }}
            >
                {this.props.children}
            </ExperienceContext.Provider>
        );
    }
}

// Use "componentName" as a drop-in replacement for "actionSubject"
// analytics-web-react getEvent() currently only supports TRACK events
// setting it directly.
export default withAnalyticsContext({
    componentName: 'ui',
})(withAnalyticsEvents()(Experience));
