import React from 'react';
import { Button, LoadingButton } from '@helpCenter/view/common/analytics';
import { useIntl } from 'react-intl-next';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import messages from './messages';

export interface Props {
    isOpen: boolean;
    onClose: () => void;
    onUnlink: () => void;
    isLoading: boolean;
    projectName?: string;
    numberOfProjects: number;
}

export const UnlinkConfirmationModal = ({
    isOpen,
    onClose,
    onUnlink,
    isLoading,
    projectName,
    numberOfProjects,
}: Props) => {
    const { formatMessage } = useIntl();
    return (
        <ModalTransition>
            {isOpen && (
                <Modal width="small" onClose={onClose}>
                    <ModalHeader>
                        <ModalTitle appearance="warning" testId="help-center-unlink-modal-title">
                            {numberOfProjects > 1
                                ? formatMessage(messages.bulkUnlinkModalTitle, { number: numberOfProjects })
                                : formatMessage(messages.unlinkModalTitle)}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {numberOfProjects > 1
                            ? formatMessage(messages.bulkUnlinkModalDesc)
                            : formatMessage(messages.unlinkModalDesc, { projectName })}
                    </ModalBody>
                    <ModalFooter>
                        <LoadingButton
                            onClick={onUnlink}
                            isLoading={isLoading}
                            appearance="warning"
                            actionSubjectId="unlinkModalConfirmButton"
                            testId="unlink-modal-confirm-button"
                        >
                            {formatMessage(messages.unlinkModalConfirmButton)}
                        </LoadingButton>
                        <Button onClick={onClose} appearance="subtle" actionSubjectId="unlinkModalCancelButton">
                            {formatMessage(messages.unlinkModalCancelButton)}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>
    );
};
