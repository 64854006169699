import * as React from 'react';
import { DeferredWrmResourceContext } from '../wrm-resource-provider';

/**
 *  Ensures any wrapped component that relies on legacy WRM resources are loaded on the page
 *  (e.g connect fragment components) before rendering
 */
export const withDeferredWrmLoaded = <TProps extends object>(
    Component: React.ComponentType<TProps> | React.JSX.ElementType,
    onLoad: () => void
) => {
    const ComponentWithDeferredWrmLoaded = (props: TProps) => {
        const { resourcesLoaded } = React.useContext(DeferredWrmResourceContext);

        React.useEffect(() => {
            if (resourcesLoaded) {
                onLoad();
            }
        }, [resourcesLoaded]);

        if (!resourcesLoaded) {
            return null;
        }

        return <Component {...props} />;
    };

    return ComponentWithDeferredWrmLoaded;
};
