// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';

// This is the current styling of Badge in AtlasKit. It
// has been duplicated here since AtlasKit does not allow
// color styling at the moment.
// Changed to use atlaskit/badge once https://ecosystem.atlassian.net/browse/AK-4187 resolved

// Suppressing existing violation. Please fix this.
// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ThemedBadge = styled.div`
    background-color: ${(props) => token('color.background.selected.bold', props.theme.color)};
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    border-radius: 2em;
    color: ${(props) => props.theme.readableTextColor};
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    min-width: 1px;
    padding: 0.16666666666667em ${token('space.075', '6px')};
    text-align: center;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export default ThemedBadge;
