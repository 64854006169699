import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import { fetchRequestTypeSuggestionAction } from 'state/actions/request-type-suggestion';
import {
    getAllVisiblePortalsInHelpCenter,
    getDefaultViewPortals,
    getDefaultViewLoading,
    getfeaturedAndSortedPortalsError,
    getTotal,
} from 'state/selectors/featured-and-sorted-portals';
import { getHelpCenterAnnoucement } from 'state/selectors/help-center';
import { getRequestTypeSuggestions } from 'state/selectors/request-type-suggestion';
import { isLoggedIn } from 'state/selectors/user';
import HelpCenterContentComponent from './help-center-content';

const selector = createSelector(
    isLoggedIn,
    getAllVisiblePortalsInHelpCenter,
    getDefaultViewPortals,
    getRequestTypeSuggestions,
    getHelpCenterAnnoucement,
    getDefaultViewLoading,
    getfeaturedAndSortedPortalsError,
    getTotal,
    (
        userLoggedIn,
        allVisiblePortals,
        defaultViewPortals,
        requestTypeSuggestion,
        announcement,
        isDefaultViewLoading,
        error,
        total
    ) => ({
        requestTypeSuggestion,
        userLoggedIn,
        announcement,
        isDefaultViewLoading,
        error,
        total,
        defaultViewPortalsLength: defaultViewPortals.length,
        portals: allVisiblePortals,
    })
);

export const HelpCenterContent = connect(selector, {
    fetchRequestTypeSuggestion: fetchRequestTypeSuggestionAction,
    fetchFeaturedAndSortedPortals: fetchFeaturedAndSortedPortalsModel,
})(HelpCenterContentComponent);
