import {
    csmHelpCenterFormPickerExperienceId,
    csmHelpCenterLoadFormExperienceId,
} from '@atlassian/help-center-common-util/analytics/ufo/ufo';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const csmHelpCenterFormPickerExperience = new UFOExperience(csmHelpCenterFormPickerExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const csmHelpCenterLoadFormExperience = new UFOExperience(csmHelpCenterLoadFormExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
