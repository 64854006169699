import React, { memo } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import styled from 'styled-components';
import { getPortalId } from '@helpCenter/state/selectors/context';
import { di } from 'react-magnetic-di';
import { useRouter } from 'react-resource-router';
import type { PortalAnnouncement } from 'rest/portal';
import type { State } from 'state';
import { getPortalAnnouncement } from 'state/selectors/portal';
import { AnnouncementBanner } from 'view/common/announcement-banner';
import { PaperContent } from 'view/layout/paper';
import { GridContentWrapper } from '../grid-container/grid-container';
import type { GridAppearanceTypes } from '../grid-container/utils';

export interface StateProps {
    announcement: PortalAnnouncement;
    appearance?: GridAppearanceTypes;
}

const Announcement: React.FC<StateProps> = ({ announcement, appearance: contentAppearance }) => {
    di(useRouter);
    const [{ route }] = useRouter();
    const { name } = route;
    const whiteListedRouteNames = ['portal-home', 'request-create'];

    const AnnouncementInnerContainer = memo(({ children }: { children: JSX.Element }) => {
        const hide = contentAppearance === 'none';
        const appearance = contentAppearance || 'normal';
        return (
            <GridContentWrapper appearance={appearance} hide={hide}>
                <PaperInnerContainer data-testid="paper-inner-container">{children}</PaperInnerContainer>
            </GridContentWrapper>
        );
    });

    AnnouncementInnerContainer.displayName = 'AnnouncementInnerContainer';

    return (
        <>
            {!!announcement && whiteListedRouteNames.includes(name) && (
                <AnnouncementBanner
                    actionSubjectId="portalAnnouncementBanner"
                    header={announcement.userLanguageHeader}
                    messageHtml={announcement.userLanguageMessageWiki}
                    InnerContainerComponent={AnnouncementInnerContainer}
                />
            )}
        </>
    );
};

const selector = createSelector(
    (state: State) => {
        const portalId = getPortalId(state);

        return {
            announcement: getPortalAnnouncement(state, portalId),
        };
    },
    ({ announcement }) => {
        return {
            announcement,
        };
    }
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const PaperInnerContainer = styled(PaperContent)({
    paddingTop: 0,
});

export default connect(selector)(Announcement);
