import type { StoreActionApi } from 'react-sweet-state';
import { v4 } from 'uuid';
import { doc, p } from '@atlaskit/adf-utils/builders';
import type { HelpseekerMessageBody } from '../../../common/types';
import type { State } from '../types';

export const constructHelpseekerMessage =
    (messageBody: Omit<HelpseekerMessageBody, 'content'> & { content: string }) =>
    ({ getState, setState }: StoreActionApi<State>) => {
        const { messages, conversationId } = getState();

        const helpseekerMessage = {
            message: {
                ...messageBody,
                content: doc(p(messageBody.content)),
            },
            id: `client::${v4()}`,
            timeCreated: new Date().toISOString(),
            conversationChannelId: conversationId || '',
        };

        // add user message
        setState({
            isLoading: true,
            messages: [...messages, helpseekerMessage],
        });
        return helpseekerMessage;
    };
