import type { RequestGroup } from 'rest/request-types';
import type { ReqTypesState } from 'state/persisted/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const SET_REQUEST_TYPE_GROUPS = 'state.ui.request-types.SET_REQUEST_TYPE_GROUPS';

export interface SetRequestTypeGroupsPayload {
    isProjectSimplified: boolean;
    portalId: number;
    projectId: number;
    requestType: ReqTypesState;
    updatedRequestTypeGroupId: number;
    requestGroups: RequestGroup[];
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface SetRequestTypeGroupsAction {
    payload: SetRequestTypeGroupsPayload;
    type: typeof SET_REQUEST_TYPE_GROUPS;
}

export const setRequestTypeGroupsAction = (payload: SetRequestTypeGroupsPayload): SetRequestTypeGroupsAction => {
    return { payload, type: SET_REQUEST_TYPE_GROUPS };
};

interface SetRequestTypeGroupsFailedPayload {
    message: string;
}

export const SET_REQUEST_TYPE_GROUPS_FAILED = 'state.ui.request-types.SET_REQUEST_TYPE_GROUPS_FAILED';

export interface SetRequestTypeGroupsFailedAction {
    payload: SetRequestTypeGroupsFailedPayload;
    type: typeof SET_REQUEST_TYPE_GROUPS_FAILED;
}

export const setRequestTypeGroupsFailedAction = (message: string): SetRequestTypeGroupsFailedAction => ({
    payload: {
        message,
    },
    type: SET_REQUEST_TYPE_GROUPS_FAILED,
});
