/**
 * @generated SignedSource<<35a8786ae63ebd6bab5b87fea170bb77>>
 * @relayHash 2b93fcbc8c19a342658c7aff002a8cd2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 14bdc72513794c74130122dbd5c9ab89491bd3dc5b3c409327e6af76a3e179e1

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type TopicStatus = "CREATED" | "DELETED" | "UPDATED";
export type topicsEditQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type topicsEditQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly extensions?: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly topics?: ReadonlyArray<{
        readonly properties: AGG$JSON | null | undefined;
        readonly status: TopicStatus | null | undefined;
        readonly topicId: string;
        readonly " $fragmentSpreads": FragmentRefs<"editableTopicFragment">;
      }> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"hiddenTopicsExpandedFragment" | "sortableTopicsContainerFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type topicsEditQuery = {
  response: topicsEditQuery$data;
  variables: topicsEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "topicId"
},
v5 = {
  "kind": "ScalarField",
  "name": "properties"
},
v6 = {
  "kind": "ScalarField",
  "name": "status"
},
v7 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "title"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayLink"
},
v11 = {
  "kind": "ScalarField",
  "name": "entityKey"
},
v12 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "topicsEditQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "sortableTopicsContainerFragment"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "hiddenTopicsExpandedFragment"
                  },
                  {
                    "concreteType": "HelpCenterTopic",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "editableTopicFragment"
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "topicsEditQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterTopic",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "name"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 3
                          }
                        ],
                        "concreteType": "HelpCenterTopicItemConnection",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "HelpCenterTopicItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "HelpCenterTopicItem",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "ari"
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpCenterHelpObject"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "concreteType": "HelpObjectStoreIcon",
                                            "kind": "LinkedField",
                                            "name": "icon",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrlV2"
                                              }
                                            ]
                                          },
                                          (v11/*: any*/)
                                        ],
                                        "type": "HelpObjectStoreRequestForm"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v12/*: any*/),
                                        "type": "HelpObjectStoreArticle"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v12/*: any*/),
                                        "type": "HelpObjectStoreChannel"
                                      },
                                      (v13/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "totalCount"
                          }
                        ],
                        "storageKey": "items(first:3)"
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__id"
                          },
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "order"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "14bdc72513794c74130122dbd5c9ab89491bd3dc5b3c409327e6af76a3e179e1",
    "metadata": {},
    "name": "topicsEditQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "97da98f191d43af2454fb49459cb154a";

export default node;
