import { getMetaFromHTML } from '../meta';
import type { Flags } from './types';

// ajs-fe-statsig-values contains ALL statsig values (feature gates, dynamic config, etc.)
export const getStatsigValuesFromMeta = (): Flags => parseJsonContentFromMetaTag('ajs-fe-statsig-values');

const parseJsonContentFromMetaTag = (metaTagName: string): Flags => {
    const metaContent = getMetaFromHTML(metaTagName);
    if (metaContent != null && metaContent !== '') {
        try {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return JSON.parse(metaContent);
        } catch (e) {
            throw new Error(`Can't parse '${metaTagName}' value`);
        }
    }
    return {};
};
