import React, { useEffect } from 'react';
import { isSearchFAMASLOUpdateEnabled, isSearchUIRedesignEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { graphql, useFragment } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import { Box, media, xcss } from '@atlaskit/primitives';
import { ExperienceSuccess } from '@atlassian/ufo';
import {
    INITIAL_PORTALS_COUNT,
    ResultList,
    ResultTitle,
    sendSearchOperationalAnalyticEvents,
    sendSearchUIAnalyticEvents,
} from '../common';
import { searchExperience } from '../experiences';
import { PortalItem } from '../portal-item';
import type { portalsFragment$key } from './__generated__/portalsFragment.graphql';
import messages from './messages';

export interface Props {
    term: string;
    result: portalsFragment$key;
    updateResultsCount: (count: number, acknowledgement?: number) => void;
    createAnalyticsEvent?: CreateUIAnalyticsEvent;
}

export const Portals = ({ result, term, updateResultsCount, createAnalyticsEvent }: Props) => {
    di(isSearchUIRedesignEnabled);
    const { formatMessage } = useIntl();
    const data = useFragment<portalsFragment$key>(
        graphql`
            fragment portalsFragment on HelpObjectStoreQueryApi {
                portals: searchHelpObjects(
                    searchInput: {
                        cloudId: $cloudId
                        queryTerm: $queryTerm
                        resultLimit: $resultLimit
                        entityType: PORTAL
                        portalIds: $portalIds
                        helpCenterAri: $helpCenterAri
                    }
                ) @required(action: THROW) {
                    ... on HelpObjectStoreSearchResult {
                        __typename @required(action: THROW)
                        id @required(action: THROW)
                        isExternal @required(action: THROW)
                        searchBackend
                        ...portalItemFragment
                    }

                    ... on HelpObjectStoreQueryError {
                        message
                    }
                }
            }
        `,
        result
    );
    const [portalId] = usePathParam('portalId');

    const slicedPortals = data.portals.slice(0, INITIAL_PORTALS_COUNT);
    const showSection = slicedPortals.length > 0;

    useEffect(() => {
        let externalResourcesCount = 0;
        let firstPartySearchPlatformResourcesCount = 0;
        let hasError = false;
        let errorMessage: string | undefined;

        slicedPortals.forEach((resource) => {
            if (resource) {
                const { __typename, isExternal, searchBackend, message } = resource;

                if (__typename === 'HelpObjectStoreSearchResult') {
                    if (isExternal) {
                        externalResourcesCount += 1;
                    }
                    if (searchBackend === 'SEARCH_PLATFORM') {
                        firstPartySearchPlatformResourcesCount += 1;
                    }
                    if (message && !errorMessage) {
                        hasError = true;
                        errorMessage = message;
                    }
                }
            }
        });

        if (showSection && hasError) {
            sendSearchOperationalAnalyticEvents(
                { hasError, errorMessage, action: 'portalSearchFailed' },
                createAnalyticsEvent,
                portalId
            );
        }
        if (showSection && !hasError) {
            sendSearchUIAnalyticEvents(
                {
                    externalResourcesCount,
                    firstPartySearchPlatformResourcesCount,
                    action: 'rendered',
                    resourceType: 'portal',
                    resourceCount: slicedPortals.length,
                },
                createAnalyticsEvent,
                portalId
            );

            sendSearchOperationalAnalyticEvents({ action: 'portalSearchSucceeded' }, createAnalyticsEvent, portalId);
        }
        // adding slicedPortals to deps array causes multiple 'rendered' events to be fired
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term, showSection, slicedPortals.length, createAnalyticsEvent, portalId]);

    useEffect(() => {
        updateResultsCount(slicedPortals.length, 1);
    }, [term, slicedPortals.length, updateResultsCount]);

    if (!isSearchUIRedesignEnabled()) {
        return (
            <>
                {showSection && (
                    <>
                        <ResultTitle>{formatMessage(messages.portals)}</ResultTitle>
                        <ResultList>
                            {slicedPortals.map((resource, index) => {
                                if (resource?.__typename === 'HelpObjectStoreSearchResult') {
                                    const itemOrder = index + 1;

                                    return (
                                        <li key={itemOrder}>
                                            <PortalItem itemOrder={itemOrder} term={term} result={resource} />
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ResultList>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            {showSection && (
                <Box xcss={containerStyles}>
                    <Box xcss={headingStyles}>
                        <Heading size="xxsmall">{formatMessage(messages.portals)}</Heading>
                    </Box>
                    <ResultList>
                        {slicedPortals.map((resource, index) => {
                            if (resource?.__typename === 'HelpObjectStoreSearchResult') {
                                const itemOrder = index + 1;
                                const key = resource.id;

                                return (
                                    <li key={key}>
                                        <PortalItem itemOrder={itemOrder} term={term} result={resource} />
                                    </li>
                                );
                            }
                            return null;
                        })}
                    </ResultList>
                </Box>
            )}
            {isSearchFAMASLOUpdateEnabled() && <ExperienceSuccess experience={searchExperience} />}
        </>
    );
};

const containerStyles = xcss({
    overflow: 'hidden',
    paddingTop: 'space.250',
    paddingRight: 'space.150',
    paddingBottom: 'space.150',
    paddingLeft: 'space.150',
    gap: 'space.150',
    borderColor: 'color.border',
    borderStyle: 'solid',
    borderRadius: 'border.radius.100',
    borderWidth: 'border.width',
    rowGap: 'space.100',
    [media.above.xxs]: { marginLeft: 'space.100', marginRight: 'space.100', width: '1fr' },
    [media.above.md]: {
        marginLeft: 'space.0',
        marginRight: 'space.0',
        width: '320px',
    },
});
const headingStyles = xcss({
    width: '100%',
    paddingRight: 'space.100',
    paddingBottom: 'space.050',
    paddingLeft: 'space.100',
});

export default withAnalyticsEvents()(Portals);
