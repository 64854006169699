import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { loginConsent } from 'rest/login-consent';
import type { LoginConsentFailure } from 'state/actions/login';
import { loginConsentFailure } from 'state/actions/login';
import type { LoginConsentAction } from 'state/actions/login-consent';
import { LOGIN_CONSENT } from 'state/actions/login-consent';
import { history } from '@atlassian/help-center-common-util/history';
import { replace } from '@atlassian/help-center-common-util/location';
import { getLoginUrl } from '@atlassian/help-center-common-util/url';

export const loginConsentEpic: Epic<LoginConsentAction, LoginConsentFailure> = (action$) => {
    return action$.ofType(LOGIN_CONSENT).mergeMap((action) => {
        const { param } = action.payload;
        return loginConsent(param)
            .mergeMap(({ response }) => {
                replace(response.uri);
                return Observable.empty<never>();
            })
            .catch(() => {
                history.push(getLoginUrl({ isAbsolute: false, portalId: undefined }));
                return Observable.of(loginConsentFailure());
            });
    });
};
