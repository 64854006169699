import type {
    FetchApprovalListAction,
    FetchApprovalListSuccess,
    FetchApprovalListFailure,
} from 'state/actions/approval-list';
import {
    FETCH_APPROVAL_LIST_MODEL,
    FETCH_APPROVAL_LIST_SUCCESS,
    FETCH_APPROVAL_LIST_FAILURE,
} from 'state/actions/approval-list';

export interface ApprovalListUIState {
    isLoading: boolean;
}

export type HandledActions = FetchApprovalListAction | FetchApprovalListSuccess | FetchApprovalListFailure;

const defaultState: ApprovalListUIState = {
    isLoading: false,
};

export function approvalistReducer(
    state: ApprovalListUIState = defaultState,
    action: HandledActions
): ApprovalListUIState {
    switch (action.type) {
        case FETCH_APPROVAL_LIST_MODEL:
            return handleFetchApprovalListAction(state);
        case FETCH_APPROVAL_LIST_SUCCESS:
            return handleFetchApprovalListSuccess(state);
        case FETCH_APPROVAL_LIST_FAILURE:
            return handleFetchApprovalListFailure(state);
        default:
            return state;
    }
}

const handleFetchApprovalListAction = (state: ApprovalListUIState): ApprovalListUIState => ({
    ...state,
    isLoading: true,
});

const handleFetchApprovalListSuccess = (state: ApprovalListUIState): ApprovalListUIState => ({
    ...state,
    isLoading: false,
});

const handleFetchApprovalListFailure = (state: ApprovalListUIState): ApprovalListUIState => ({
    ...state,
    isLoading: false,
});
