import { useRef } from 'react';
import type { FC } from 'react';
import { useRouter } from 'react-resource-router';
import getUFORouteName from '@atlaskit/react-ufo/route-name';
import traceUFOPageLoad from '@atlaskit/react-ufo/trace-pageload';
import traceUFOTransition from '@atlaskit/react-ufo/trace-transition';

export const UFOWatcher: FC = () => {
    const isInitial = useRef(true);
    const [routerState] = useRouter();
    const route = routerState.route;
    const match = routerState.match;

    let ufoName = getUFORouteName(route).toLowerCase();

    if ('getUFONameBasedOnRouteMatch' in route && route.getUFONameBasedOnRouteMatch instanceof Function) {
        ufoName = route.getUFONameBasedOnRouteMatch(match) as string;
    }

    const lastUFOName = useRef(ufoName);
    if (ufoName) {
        if (isInitial.current) {
            traceUFOPageLoad(ufoName);
            isInitial.current = false;
        }
        if (lastUFOName.current !== ufoName && !isInitial.current) {
            lastUFOName.current = ufoName;
            traceUFOTransition(ufoName);
        }
    }
    return null;
};
