import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { removeOrganisation } from 'rest/participants';
import type { HandleAjaxError, ShowErrorFlag } from 'state/actions/flags';
import { handleAjaxError, showErrorFlag } from 'state/actions/flags';
import { REMOVE_ORGANISATION, removeOrganisationSuccess } from 'state/actions/request-details';
import type { RemoveOrganisation, RemoveOrganisationSuccess } from 'state/actions/request-details';
import messages from './messages';

export const removeOrganisationEpic: Epic<
    RemoveOrganisation,
    RemoveOrganisationSuccess | HandleAjaxError | ShowErrorFlag
> = (action$) => {
    return action$.ofType(REMOVE_ORGANISATION).mergeMap((removeParticipantAction) => {
        const { requestKey, organisationId } = removeParticipantAction.payload;

        return removeOrganisation(requestKey, organisationId)
            .map(() => {
                return removeOrganisationSuccess(requestKey, organisationId);
            })
            .catch((error: AjaxError) => {
                const isPermissionDenied = error.status === 403;
                return Observable.of(
                    isPermissionDenied
                        ? showErrorFlag(messages.permissionDeniedErrorTitle, messages.permissionDeniedErrorDescription)
                        : handleAjaxError(error)
                );
            });
    });
};
