import { commitLocalUpdate } from 'relay-runtime';
import type RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment';
import { v4 } from 'uuid';
import { getCloudId } from '@atlassian/help-center-common-util/meta';

interface GenerateUniqueSubscriptionID {
    environment: RelayModernEnvironment;
}
export const generateUniqueSubscriptionID = ({ environment }: GenerateUniqueSubscriptionID) => {
    return new Promise<string>((resolve) => {
        commitLocalUpdate(environment, (store) => {
            const root = store.getRoot();
            const jsmChatQuery = root.getOrCreateLinkedRecord('jsmChatConversationMeta', 'JsmChatConversationMeta');
            const subscriptionId = jsmChatQuery.getValue('subscriptionId');
            if (!subscriptionId) {
                const newSubscriptionID = `ari:cloud:conversational-help:${getCloudId()}:conversation/${v4()}`;
                jsmChatQuery.setValue(newSubscriptionID, 'subscriptionId');
                jsmChatQuery.setValue(null, 'connectionId');
                resolve(newSubscriptionID);
            }
            resolve(subscriptionId as string);
        });
    });
};
