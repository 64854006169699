import type * as React from 'react';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';

export const ComponentWithAnalytics =
    <TProps extends object>(componentName: string) =>
    (WrappedComponent: React.ComponentType<TProps & WithAnalyticsEventsProps>) => {
        return withAnalyticsContext({
            componentName,
        })(withAnalyticsEvents()(WrappedComponent));
    };
