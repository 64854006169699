export const FAILED_VERIFICATION_OUTCOMES = {
    IDENTITY_ERROR: 'IDENTITY_ERROR',
    TOKEN_INVALID: 'TOKEN_INVALID',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    PROCESSING_ERROR: 'PROCESSING_ERROR',
} as const;

export type FAILED_VERIFICATION_OUTCOMES =
    (typeof FAILED_VERIFICATION_OUTCOMES)[keyof typeof FAILED_VERIFICATION_OUTCOMES];

export const SUCCESSFUL_VERIFICATION_OUTCOMES = {
    SUCCEEDED: 'SUCCEEDED',
} as const;

export type SUCCESSFUL_VERIFICATION_OUTCOMES =
    (typeof SUCCESSFUL_VERIFICATION_OUTCOMES)[keyof typeof SUCCESSFUL_VERIFICATION_OUTCOMES];
