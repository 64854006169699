import { CompleteSignup } from 'view/complete-signup';
import { InvitedSignUp } from 'view/invited-sign-up';
import { ResetPassword } from 'view/reset-password';
import { SignUp } from 'view/sign-up';
import { CheckEmail } from 'view/sign-up-check-email';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { anonymousScreenLayout, PORTAL_ROUTE_PREFIX } from '../common/constants';

const userSignup = {
    resources: [],
    name: 'user-signup',
    path: `/user/signup`,
    exact: true,
    component: SignUp,
};

const userResetPassword = {
    resources: [],
    name: 'user-reset-password',
    path: `/user/resetpassword`,
    exact: true,
    component: ResetPassword,
};

const userCompleteSignup = {
    resources: [],
    name: 'user-complete-signup',
    path: `/user/complete-signup`,
    exact: true,
    component: CompleteSignup,
};

const userCheckMail = {
    resources: [],
    name: 'user-checkmail',
    path: `/user/check-email`,
    exact: true,
    component: CheckEmail,
};

const portalSignup = {
    resources: [],
    name: 'portal-signup',
    path: `${PORTAL_ROUTE_PREFIX}/user/signup`,
    exact: true,
    component: SignUp,
};

const portalResetPassword = {
    resources: [],
    name: 'portal-reset-password',
    path: `${PORTAL_ROUTE_PREFIX}/user/resetpassword`,
    exact: true,
    component: ResetPassword,
};

const portalCompleteSignup = {
    resources: [],
    name: 'portal-complete-signup',
    path: `${PORTAL_ROUTE_PREFIX}/user/complete-signup`,
    exact: true,
    component: CompleteSignup,
};

const portalInvitedSignup = {
    resources: [],
    name: 'portal-invited-signup',
    path: `${PORTAL_ROUTE_PREFIX}/user/visitportal`,
    exact: true,
    component: InvitedSignUp,
};

const signUpScreenName = ScreenName.SIGNUP;

export const userSignupRoute = createRouteWithLayout(userSignup, signUpScreenName, anonymousScreenLayout);

export const userResetPasswordRoute = createRouteWithLayout(
    userResetPassword,
    ScreenName.RESET_PASSWORD,
    anonymousScreenLayout
);

export const userCompleteSignupRoute = createRouteWithLayout(
    userCompleteSignup,
    ScreenName.COMPLETE_SIGNUP,
    anonymousScreenLayout
);

export const userCheckMailRoute = createRouteWithLayout(userCheckMail, ScreenName.CHECK_EMAIL, anonymousScreenLayout);

export const portalSignupRoute = createRouteWithLayout(portalSignup, signUpScreenName, anonymousScreenLayout);

export const portalResetPasswordRoute = createRouteWithLayout(
    portalResetPassword,
    ScreenName.RESET_PASSWORD,
    anonymousScreenLayout
);

export const portalCompleteSignupRoute = createRouteWithLayout(
    portalCompleteSignup,
    ScreenName.COMPLETE_SIGNUP,
    anonymousScreenLayout
);

export const portalInvitedSignupRoute = createRouteWithLayout(
    portalInvitedSignup,
    ScreenName.INVITED_SIGNUP,
    anonymousScreenLayout
);
