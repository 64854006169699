import type { AjaxResponse } from 'rxjs';
import { get, put } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import * as qs from 'query-string';
import type { UserResponse } from 'rest/models';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface UserQuery {
    query: string;
    portalId: number;
    fieldName: string;
    fieldConfigId: string;
}

export interface UserSearchResponse {
    avatar?: string;
    displayName: string;
    emailAddress: string;
    id: string;
    accountId: string;
}

export const searchUser = (userQuery: UserQuery, context: string = contextPath): Observable<UserSearchResponse[]> => {
    const query = {
        query: userQuery.query,
        fieldName: userQuery.fieldName,
        fieldConfigId: userQuery.fieldConfigId,
    };

    const queryString = qs.stringify(query);
    const uri = `${context}/rest/servicedesk/1/customer/portal/${userQuery.portalId}/user-search?${queryString}`;
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(uri)
        .map((ajaxResponse) => {
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return ajaxResponse.response;
        })
        .catch((e) => {
            trackError('finduser.fetch.failed', {}, e);
            return Observable.throw(e);
        });
};

export const updateUserProfile = (
    displayName: string | undefined,
    languageKey: string | undefined,
    timezone: string | undefined,
    context: string = contextPath
): Observable<UserResponse> => {
    const uri = `${context}/rest/servicedesk/1/customer/user`;
    const body = {
        displayName,
        languageKey,
        timezone,
    };
    const headers = {
        'Content-Type': 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        put(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};

export const changePassword = (
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    context: string = contextPath
) => {
    const uri = `${context}/rest/servicedesk/1/customer/user/password`;
    const body = {
        current: currentPassword,
        password: newPassword,
        confirm: confirmNewPassword,
    };
    const headers = {
        'Content-Type': 'application/json',
    };
    return (
        put(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};
