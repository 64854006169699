import type { RequestGroup } from 'rest/request-types';
import type { RequestGroupResponse } from 'state/actions/request-groups';
import type { ReqTypesState } from 'state/persisted/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const CREATE_GROUP_WITH_REQUEST_TYPE = 'epic.request-groups.CREATE_GROUP_WITH_REQUEST_TYPE' as const;
export const CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED = 'epic.request-groups.CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED';
export const CREATE_GROUP_WITH_REQUEST_TYPE_FAILED = 'epic.request-groups.CREATE_GROUP_WITH_REQUEST_TYPE_FAILED';

export interface CreateGroupWithRequestTypePayload {
    isProjectSimplified: boolean;
    groupName: string;
    projectId: number;
    portalId: number;
    requestType: ReqTypesState;
    requestGroups: RequestGroup[];
    existingGroups: RequestGroupResponse[];
    analyticsEventRequestGroupCreated?: UIAnalyticsEvent;
    analyticsEventRequestGroupUpdated?: UIAnalyticsEvent;
    analyticsEventRequestGroupCreatedFailure?: UIAnalyticsEvent;
    analyticsEventRequestGroupUpdatedFailure?: UIAnalyticsEvent;
}

export interface CreateGroupWithRequestTypeAction {
    payload: CreateGroupWithRequestTypePayload;
    type: typeof CREATE_GROUP_WITH_REQUEST_TYPE;
}

export interface CreateGroupWithRequestTypeSucceededAction {
    type: typeof CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED;
}

interface CreateGroupWithRequestTypeFailedPayload {
    message: string;
}

export interface CreateGroupWithRequestTypeFailedAction {
    payload: CreateGroupWithRequestTypeFailedPayload;
    type: typeof CREATE_GROUP_WITH_REQUEST_TYPE_FAILED;
}

export const createGroupWithRequestTypeAction = (payload: CreateGroupWithRequestTypePayload) => ({
    payload,
    type: CREATE_GROUP_WITH_REQUEST_TYPE,
});

export const createGroupWithRequestTypeSucceededAction = (): CreateGroupWithRequestTypeSucceededAction => ({
    type: CREATE_GROUP_WITH_REQUEST_TYPE_SUCCEEDED,
});

export const createGroupWithRequestTypeFailedAction = (message: string): CreateGroupWithRequestTypeFailedAction => ({
    payload: {
        message,
    },
    type: CREATE_GROUP_WITH_REQUEST_TYPE_FAILED,
});
