import type { Reducer } from 'react';
import { useEffect, useReducer } from 'react';
import { isJSMVirtualAgentAnonymousAccessEnabled } from '@helpCenter/feature-flags';
import { di } from 'react-magnetic-di';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';
import type { Action, State } from './types';

const initialState: State = {
    isVirtualAgentAvailable: false,
    isVirtualAgentEnabled: false,
    isLoading: true,
};

const reducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case 'RESET': {
            return {
                ...initialState,
                isLoading: false,
            };
        }
        case 'LOAD_AVAILABLITY': {
            return initialState;
        }
        case 'SET_AVAILABILITY': {
            return {
                isVirtualAgentAvailable: action.isVirtualAgentAvailable,
                isVirtualAgentEnabled: action.isVirtualAgentEnabled,
                isLoading: false,
            };
        }
        default: {
            return state;
        }
    }
};

interface VirtualAgentAvailabilityResponse {
    isAiResponsesEnabled: boolean;
    isIntentEnabled: boolean;
    isVirtualAgentEnabled: boolean;
}

export const useVirtualAgentAvailability = ({
    isLoggedIn,
    projectId,
    cloudId,
    baseUrl = '',
}: {
    isLoggedIn: boolean;
    projectId: number | undefined;
    cloudId: string;
    baseUrl?: string;
}) => {
    di(fetchJson, useAnalyticsEvents);
    const [{ isVirtualAgentAvailable, isVirtualAgentEnabled, isLoading }, dispatch] = useReducer(reducer, initialState);
    const { createAnalyticsEvent } = useAnalyticsEvents();

    useEffect(() => {
        const canAccess = isLoggedIn || isJSMVirtualAgentAnonymousAccessEnabled();
        if (!canAccess) {
            dispatch({ type: 'RESET' });
            return;
        }

        if (projectId === undefined) {
            dispatch({ type: 'LOAD_AVAILABLITY' });
            return;
        }

        dispatch({ type: 'LOAD_AVAILABLITY' });
        fetchJson<VirtualAgentAvailabilityResponse>(
            `${baseUrl}/gateway/api/jsm/virtual-agent/cloudId/${encodeURIComponent(
                cloudId
            )}/public/api/v1/virtualagent/byprojectid/${encodeURIComponent(projectId)}/availability`,
            { headers: { 'Content-Type': 'application/json' } }
        )
            .then((response: VirtualAgentAvailabilityResponse) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVirtualAgentAvailable: true,
                    isVirtualAgentEnabled: response.isVirtualAgentEnabled,
                });
            })
            .catch((err: Error) => {
                dispatch({
                    type: 'SET_AVAILABILITY',
                    isVirtualAgentAvailable: false,
                    isVirtualAgentEnabled: false,
                });

                if ('status' in err && typeof err.status === 'number' && err.status === 403) {
                    // AI is disabled or the user lacks permission, so this is not an error
                    return;
                }

                createAnalyticsEvent({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    actionSubject: 'virtualAgentAvailability',
                    attributes: {
                        error: err.message,
                    },
                }).fire();
            });
    }, [createAnalyticsEvent, baseUrl, isLoggedIn, cloudId, projectId]);

    return {
        isVirtualAgentAvailable,
        isLoading,
        isVirtualAgentEnabled,
    };
};
