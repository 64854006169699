import { useEffect, useMemo, useState } from 'react';
import confluenceIcon from 'assets/images/confluence-icon.svg';
import { type ORSResponse, fetchObjectsFromUrls } from 'rest/object-resolver-service';
import batch from '@atlassian/help-center-common-util/batch-function';

export const fetchIcons = (urls: string[]) => {
    return fetchObjectsFromUrls(urls)
        .toPromise()
        .then((response: ORSResponse) => {
            return urls.reduce(
                (ref, url, index) => ({
                    ...ref,
                    [url]: response[index],
                }),
                {}
            );
        });
};
const batchedFetchIcon = batch(fetchIcons, 0);

interface ExternalLink {
    name: string | null | undefined;
    iconUrl: string | null | undefined;
    source: string | null | undefined;
}

export type ExternalLinkCache = Record<string, ExternalLink>;
export const externalLinkCache: ExternalLinkCache = {};
const addExternalLinkToCache = (key: string, value: ExternalLink) => {
    externalLinkCache[key] = value;
};

export function useExternalLink({ isLoggedIn, link: url }: { link: string; isLoggedIn: boolean }) {
    const initialState = useMemo(
        () => ({
            name: null,
            iconUrl: null,
            source: null,
        }),
        []
    );
    const [data, setData] = useState<ExternalLink>(initialState);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const markDataLoaded = (key: string, value: ExternalLink) => {
        setIsLoading(false);
        setData(value);
        addExternalLinkToCache(key, value);
    };

    useEffect(() => {
        let href = url;

        if (!isLoggedIn) {
            markDataLoaded(url, initialState);
            return;
        }

        /**
         * Try to parse the url and extract out the href
         * if it fails return null as the icon url
         */
        try {
            href = new URL(url).href;
        } catch (error) {
            markDataLoaded(url, initialState);
            return;
        }

        /**
         * Origin is null for other supported url forms like `tel:` and `mail:`
         * return null so that ui can fallback to default icons
         */

        if (new URL(url).origin === 'null') {
            markDataLoaded(url, initialState);
            return;
        }

        /**
         * check is there is already entry in cache
         * if yes, return the icon url
         * else, make a call to object resolver service
         */
        if (Object.keys(externalLinkCache).includes(href)) {
            setData(externalLinkCache[href]);
            setIsLoading(false);
        } else {
            batchedFetchIcon(href)
                .then((response: ORSResponse) => {
                    const source = response[href]?.body.data?.generator?.name;

                    /**
                     * ORS returns old Confluence icon
                     * Until the icon is updated in ORS we should use the new icon from assets
                     */
                    const iconUrl =
                        source === 'Confluence' ? confluenceIcon : response[href]?.body?.data?.generator?.icon?.url;

                    markDataLoaded(href, {
                        iconUrl,
                        source,
                        name: response[href]?.body.data?.name,
                    });
                })
                .catch(() => {
                    return null;
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [initialState, isLoggedIn, url]);

    return {
        data,
        isLoading,
    };
}
