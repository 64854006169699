import type { ActionsObservable } from 'redux-observable';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { addRequestGroup } from 'rest/request-groups';
import type { RequestGroup } from 'rest/request-types';
import type { AddRequestGroupAction, AddRequestGroupFailedAction } from 'state/actions/add-request-group';
import { ADD_REQUEST_GROUP, addRequestGroupFailedAction } from 'state/actions/add-request-group';
import type { GetAllRequestGroupsAction } from 'state/actions/request-groups';
import { getRequestGroupsAction } from 'state/actions/request-groups';
import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getFirstMessageFromJiraError } from '@atlassian/help-center-common-util/errors/index';

export const addRequestGroupEpic: Epic<
    AddRequestGroupAction,
    GetAllRequestGroupsAction | AddRequestGroupFailedAction
> = (action$: ActionsObservable<AddRequestGroupAction>) => {
    return action$.ofType(ADD_REQUEST_GROUP).mergeMap((action: AddRequestGroupAction) => {
        const { projectId, portalId, groupName, analyticsEvent, analyticsFailureEvent } = action.payload;
        return addRequestGroup(groupName, projectId)
            .map((response: RequestGroup) => {
                if (analyticsEvent) {
                    // Update the analytics event with newly created Request type group's ID as the actionSubjectId
                    analyticsEvent.update((payload: AnalyticsEventPayload) => ({
                        ...payload,
                        actionSubjectId: response.id,
                    }));
                    sendEvent(analyticsEvent);
                }
                return getRequestGroupsAction(projectId, portalId);
            })
            .catch((error) => {
                if (analyticsFailureEvent) {
                    sendEvent(analyticsFailureEvent);
                }
                return Observable.of(
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    addRequestGroupFailedAction(getFirstMessageFromJiraError(error.response), groupName)
                );
            });
    });
};
