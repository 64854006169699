import type {
    GetAllRequestGroupsAction,
    GetAllRequestGroupsSuccess,
    GetAllRequestGroupsFailure,
    RequestGroupResponse,
} from 'state/actions/request-groups';
import { GET_ALL_REQUEST_GROUPS_SUCCESS, GET_ALL_REQUEST_GROUPS_FAILURE } from 'state/actions/request-groups';

export type HandledActions = GetAllRequestGroupsSuccess | GetAllRequestGroupsFailure | GetAllRequestGroupsAction;

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line @typescript-eslint/ban-types
type AllRequestGroupsState = { [key: string]: RequestGroupResponse[] } | {};

export interface PortalCustomisationPersistedState {
    allRequestGroups: AllRequestGroupsState;
    errorMessage: string | undefined;
}

export const defaultState: PortalCustomisationPersistedState = {
    allRequestGroups: {},
    errorMessage: undefined,
};

export function portalCustomisationReducer(state = defaultState, action: HandledActions) {
    switch (action.type) {
        case GET_ALL_REQUEST_GROUPS_SUCCESS: {
            const { portalId, requestGroups } = action.payload;
            return {
                errorMessage: undefined,
                allRequestGroups: {
                    ...state.allRequestGroups,
                    [portalId]: requestGroups,
                },
            };
        }

        case GET_ALL_REQUEST_GROUPS_FAILURE: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                errorMessage,
            };
        }

        default: {
            return state;
        }
    }
}
