import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ErrorIcon from 'assets/images/error.react.svg';
import { isCSMRequestIntakeM1Enabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { usePathParam, useRouterActions } from 'react-resource-router';

import type { State } from 'state';
import { fetchPortalAction } from 'state/actions/portal';
import type { PortalState } from 'state/persisted/portal';
import { HelpCenterContainer } from 'view/help-center/container';
import { CsmRequestCreateForm } from 'view/request-create-form/csm-request-create-form';
import EmptyState from '@atlaskit/empty-state';
import { Label } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import { Box, Stack, Text, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { ExperienceFailure } from '@atlassian/help-center-common-component/analytics/experience-notifier/experience-notifier';
import { Select } from '@atlassian/help-center-common-component/analytics/select';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import { ServiceHubForm } from '../service-hub-form';
import { csmHelpCenterFormPickerExperience } from './experiences';
import messages from './messages';

interface RequestTypeOption {
    label: string;
    value: number;
}

const CsmHelpCenterDumb = ({
    fetchPortal,
    portals,
}: {
    fetchPortal: typeof fetchPortalAction;
    portals: PortalState;
}) => {
    di(usePathParam, useRouterActions, getEnv, CsmRequestCreateForm);
    useUFOComponentExperience(csmHelpCenterFormPickerExperience);

    const isCSMRequestIntakeM1GateEnabled = isCSMRequestIntakeM1Enabled();
    const { formatMessage } = useIntl();
    const { push } = useRouterActions();
    const [requestIdParam] = usePathParam('requestId');
    const requestId = requestIdParam ? Number(requestIdParam) : undefined;
    const portalId = getEnv().hoistedPortalId;

    const isLoading = portalId && Object.keys(portals).length === 0;
    const draftPortal = portalId ? portals?.[portalId] : undefined;
    const error = draftPortal && 'error' in draftPortal ? draftPortal.error : undefined;
    const portal = draftPortal && 'id' in draftPortal ? draftPortal : undefined;

    const options: RequestTypeOption[] =
        portal?.reqTypes?.map((reqType) => ({ label: reqType.name, value: Number(reqType.id) })) || [];

    const value = requestId ? options.find((o) => o.value === requestId) : undefined;

    useEffect(() => {
        if (portalId) {
            fetchPortal({ id: portalId, expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] }, {});
        }
    }, [fetchPortal, portalId]);

    return (
        <HelpCenterContainer data-testId="csm-help-center.container">
            {isCSMRequestIntakeM1GateEnabled ? (
                <ServiceHubForm formId={requestIdParam} ufoExperience={csmHelpCenterFormPickerExperience} />
            ) : (
                <>
                    <Stack space="space.100" xcss={descriptionStyles} testId="csm-help-center.header">
                        <Heading size="small">{formatMessage(messages.contactUs)}</Heading>
                        <Text>{formatMessage(messages.contactUsDescription)}</Text>
                    </Stack>
                    {(!portalId || error) && (
                        <>
                            <EmptyState
                                header={formatMessage(messages.errorHeading)}
                                description={formatMessage(messages.errorDescription)}
                                renderImage={() => (
                                    <Box xcss={errorImageStyles}>
                                        <ErrorIcon />
                                    </Box>
                                )}
                            />
                            <ExperienceFailure experience={csmHelpCenterFormPickerExperience} />
                        </>
                    )}
                    {isLoading && (
                        <Box data-testId="csm-help-center.spinner">
                            <Spinner />
                        </Box>
                    )}
                    {portal && !error && (
                        <Stack test-id="csm-help-center.form-picker" space="space.200">
                            <Label htmlFor="request-type-select">{formatMessage(messages.selectLabel)}</Label>
                            <Select
                                inputId="request-type-select"
                                options={options}
                                value={value}
                                placeholder={formatMessage(messages.selectPlaceholder)}
                                onChange={async (option: RequestTypeOption) => {
                                    await push(`/${option.value}`);
                                }}
                                actionSubjectId="requestTypeSelect"
                                maxMenuHeight={200}
                            />
                            <Box xcss={formContainer}>
                                {!!(requestId && value && portalId) && (
                                    <CsmRequestCreateForm portalId={portalId} requestTypeId={requestId} />
                                )}
                            </Box>
                            <ExperienceSuccess experience={csmHelpCenterFormPickerExperience} />
                        </Stack>
                    )}
                </>
            )}
        </HelpCenterContainer>
    );
};

const descriptionStyles = xcss({
    marginTop: 'space.500',
    marginBottom: 'space.300',
});

const errorImageStyles = xcss({
    width: '100px',
    margin: `0 auto ${token('space.200', '16px')} auto`,
});

const formContainer = xcss({
    minHeight: '150px',
});

const getPortals = (state: State): PortalState => state.persisted?.portals;

const selector = createSelector(getPortals, (portals) => ({
    portals,
}));

export const CsmHelpCenter = connect(selector, {
    fetchPortal: fetchPortalAction,
})(CsmHelpCenterDumb);
