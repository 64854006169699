import { noop } from 'lodash';
import type { AnalyticsWebClient, Attrs } from '../client';

const analyticsWebClientForSSR: AnalyticsWebClient = {
    initTime: 0,
    eventCount: 0,
    blockingDuration: 0,
    resetBlockingDuration: () => undefined,
    init: (attrs: Attrs) => !!attrs,
    sendEvent: noop,
    // @ts-expect-error - TS2741 - Property 'prototype' is missing in type '{}' but required in type 'typeof AnalyticsWebClient'.
    getInstance: () => new Proxy({}, { get: () => noop }),
    setSubProduct: noop,
    setUIViewedAttributes: noop,
    removeUIViewedAttribute: noop,
    setEmbeddedProduct: noop,
    clearUIViewedAttributes: noop,
    clearEmbeddedProduct: noop,
    stopApdexEvent: noop,
    setOriginTracingHandlers: noop,
};

export default analyticsWebClientForSSR;
