import type { AjaxError, AjaxResponse } from 'rxjs';
import { post } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import type { AuthData } from 'state/actions/login/login';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { LoginType } from '@atlassian/help-center-common-util/login';
import { reportError } from '@atlassian/help-center-common-util/sentry';
import { prependWithSlashIfAbsent } from '@atlassian/help-center-common-util/url';

interface IdentityLoginPayload {
    key: string;
    context: string;
}
interface AuthMethodResponse {
    authUrl?: string;
    authType: 'PASSWORD' | 'SSO';
}
export interface CheckLoginActionResponse {
    action: LoginType;
    methods: AuthMethodResponse[];
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum LoginAttemptErrorType {
    INVALID_CREDENTIALS,
    ACCOUNT_LOCKED_ERROR,
    MISC_LOGIN_ERROR,
}

export interface LoginAttemptError {
    errorType: LoginAttemptErrorType;
}

export interface LoginAttemptResponse {
    loginSucceeded: boolean;
    error: LoginAttemptError | null;
}

const createOperationalPayload = (actionSubject: 'identity' | 'jira', attributes: Record<string, string | boolean>) => {
    return {
        attributes,
        actionSubject,
        action: 'login',
        source: 'unknownSource',
    };
};

export const checkLoginAction = (
    email: string,
    authData: AuthData,
    context: string = contextPath
): Observable<CheckLoginActionResponse> => {
    const uri = `${context}/rest/servicedesk/customer-management/1/twosteplogin/action`;
    const body = JSON.stringify({
        email,
        ...authData,
    });

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return post(uri, body, headers).map((ajaxResponse) => ajaxResponse.response);
};

export const forgotPassword = (resetUrl: string, email: string) => {
    const uri = prependWithSlashIfAbsent(resetUrl);
    const body = createBodyForForgotPassword(email);
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    return post(uri, body, headers);
};

const createBodyForForgotPassword = (usernameOrEmail: string) => {
    return JSON.stringify({
        usernameOrEmail,
    });
};

const responseKeyToErrorType = (loginPayload: IdentityLoginPayload): LoginAttemptErrorType => {
    if (loginPayload) {
        switch (loginPayload.key) {
            case 'invalid-user-credentials':
                return LoginAttemptErrorType.INVALID_CREDENTIALS;
            case 'blocked-user-credentials':
                return LoginAttemptErrorType.ACCOUNT_LOCKED_ERROR;
            default:
        }
    }

    return LoginAttemptErrorType.MISC_LOGIN_ERROR;
};

// Identity error responses: https://stash.atlassian.com/projects/IPS/repos/customer-account-directory/browse/lib/common/error-keys.js
export const handleIdentityLoginResponse = (ajaxError: AjaxError) => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const ajaxResponse: IdentityLoginPayload = ajaxError.response;

    if (ajaxError.status === 403) {
        const errorType = responseKeyToErrorType(ajaxResponse);
        if (errorType === LoginAttemptErrorType.MISC_LOGIN_ERROR) {
            reportError(ajaxError, { message: JSON.stringify(ajaxResponse) });
        }

        return {
            loginSucceeded: false,
            error: {
                errorType,
            },
        };
    }

    reportError(ajaxError, { message: JSON.stringify(ajaxResponse) });
    throw ajaxError;
};

export const loginUsingIdentity = (
    email: string,
    password: string,
    context: string = contextPath
): Observable<LoginAttemptResponse> => {
    const uri = `${context}/jsd-login/v1/authentication/authenticate`;
    const body = { email, password };
    const headers = {
        'Content-Type': 'application/json',
    };

    return post(uri, body, headers)
        .do(
            (ajaxResponse: AjaxResponse) => {
                operationalEvent(
                    createOperationalPayload('identity', {
                        success: true,
                        status: ajaxResponse.status.toString(),
                        traceId: ajaxResponse.xhr.getResponseHeader('atl-traceid') || 'unknown',
                    })
                );
            },
            (ajaxError: AjaxError) => {
                operationalEvent(
                    createOperationalPayload('identity', {
                        success: false,
                        status: ajaxError.status.toString(),

                        // Suppressing existing violation. Please fix this.
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                        errorKey: ajaxError.response?.key,
                        traceId: ajaxError.xhr.getResponseHeader('atl-traceid') || 'unknown',
                    })
                );
            }
        )
        .map(() => ({
            loginSucceeded: true,
            error: null,
        }))
        .catch((ajaxError: AjaxError) => {
            return Observable.of(handleIdentityLoginResponse(ajaxError));
        });
};

export const login = (email: string, password: string) => {
    return loginUsingIdentity(email, password);
};
