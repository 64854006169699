import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { IconCardLayout } from 'view/layout/icon-card-layout';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import * as transitions from 'view/styles/transitions';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { LinkProps } from '@atlassian/help-center-common-component/analytics/link';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import { AutoFocus } from '@atlassian/help-center-common-component/auto-focus';
import * as qs from '@atlassian/help-center-common-util/history-qs';

export interface SearchProps {
    queryHash?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    autoFocus?: boolean;
    focusable?: boolean;
}

export interface SearchResultProps extends SearchProps {
    className?: string;
    titleHtml: string;
    to: string;
    openNewTab?: boolean;
    leftIcon?: React.ReactNode;
    subtitle?: React.ReactNode;
    elevated?: boolean;
    rightIcon?: React.ReactNode;
    actionSubjectId: string;
}

const SearchResult: React.FunctionComponent<SearchResultProps> = ({
    leftIcon,
    titleHtml,
    subtitle,
    to,
    openNewTab,
    rightIcon,
    queryHash,
    autoFocus,
    focusable,
    onClick,
    className,
    elevated,
    actionSubjectId,
}) => {
    const content = (
        <TitleContainer>
            <Title dangerouslySetInnerHTML={{ __html: titleHtml }} />
            {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </TitleContainer>
    );

    const result = (
        <Root
            data-testId="result-base-entry"
            // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
            className={className}
            tabIndex={focusable ? 0 : -1}
            to={qs.addParamsToUrl(to, { src: queryHash })}
            onClick={onClick}
            elevated={elevated}
            actionSubjectId={actionSubjectId}
            target={openNewTab ? '_blank' : '_self'}
        >
            <IconCardLayout
                leftIcon={leftIcon}
                rightIcon={rightIcon ? rightIcon : undefined}
                content={content}
                centerAlignLeftIcon
                centerAlignRightIcon
                iconSize={grid.multiple(3).unitless}
            />
        </Root>
    );

    if (autoFocus) {
        return <AutoFocus>{result}</AutoFocus>;
    }

    return result;
};

export default SearchResult;

interface Props {
    elevated?: boolean;
}

const RESULT_HEIGHT = grid.unitless * 7;

const BaseLink: React.FunctionComponent<LinkProps & Props> = ({ elevated, ...props }) => <Link {...props} />;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled(BaseLink)`
    display: flex;
    ${mixins.borderRadius};
    ${(props) => mixins.applyIf(!!props.elevated, mixins.raiseUpOnHoverOrFocus())};
    height: ${RESULT_HEIGHT}px;
    background-color: ${(props) => (props.elevated ? token('elevation.surface.raised', colors.N0) : 'transparent')};
    transition:
        background-color ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut},
        transform ${transitions.speedMs.fast}ms ${transitions.timingFunctions.easeInOut};
    position: relative;

    &:hover,
    &:focus {
        background-color: ${token('elevation.surface.raised', colors.N0)};
        text-decoration: none;
    }

    &:hover h3,
    &:focus h3 {
        color: ${(props) => token('color.link', props.theme.color)};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const TitleContainer = styled.span`
    ${mixins.clamp};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Title = styled.h3`
    ${fonts.regular};
    ${mixins.clamp};
    margin: 0;
    font-weight: normal;
    transition: color ${transitions.speedMs.medium}ms ${transitions.timingFunctions.easeInOut};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SubTitle = styled.p`
    ${fonts.small};
    ${mixins.clamp};
    margin: 0;
    color: ${token('color.text.subtle', colors.N300)};
`;
