import * as React from 'react';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { Appearance } from '@atlassian/help-center-common-component/card-link';
import { CardLink } from '@atlassian/help-center-common-component/card-link';
import {
    GROUP_BOX_PADDING_LEFT_RIGHT,
    GROUP_BOX_PADDING_TOP_BOTTOM,
} from '@atlassian/help-center-common-component/group-box';
import messages from './messages';

interface RaiseRequestLinkProps {
    to: string;
    className?: string;
    appearance?: Appearance;
    isEmbeddedRoute?: boolean;
}

const RaiseRequestLink: React.FunctionComponent<RaiseRequestLinkProps> = ({ to, isEmbeddedRoute, ...props }) => (
    <CardLink
        to={to}
        padding={0}
        actionSubjectId="requestCreateLink"
        target={isEmbeddedRoute ? '_blank' : undefined}
        {...props}
    >
        <MessageContainer>
            <FormattedMessage {...messages.raiseRequestText} />
        </MessageContainer>
    </CardLink>
);

export default RaiseRequestLink;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const MessageContainer = styled.div`
    /* Makes sure the raise request link has the same look as group box */
    padding: ${GROUP_BOX_PADDING_TOP_BOTTOM} ${GROUP_BOX_PADDING_LEFT_RIGHT};
`;
