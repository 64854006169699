import type { Reducer, ReducersMapObject } from 'redux';
import { combineReducers } from 'redux';
import { blockedAppsBannerReducer } from 'state/persisted/blocked-apps-banner';
import type { BlockedAppsBannerState } from 'state/persisted/blocked-apps-banner/reducer';
import type { AiAnswerState } from './ai-answer';
import { aiAnswerReducer } from './ai-answer';
import type { AnonymousApprovalState } from './anonymous-approval';
import { anonymousApprovalReducer } from './anonymous-approval';
import type { ApprovalListState } from './approval-list';
import { approvalListReducer } from './approval-list';
import type { CompleteSignupState } from './complete-signup';
import { completeSignupReducer } from './complete-signup';
import type { ConnectFragmentsState, ConnectAddonFragments, ConnectAddonFragmentsFailure } from './connect-addon';
import { connectFragmentsReducer } from './connect-addon';
import type { EnvState } from './env';
import { envReducer } from './env';
import type { FeaturedAndSortedPortalsState } from './featured-and-sorted-portals';
import { featuredAndSortedPortalsReducer } from './featured-and-sorted-portals';
import type { ForgotPasswordState } from './forgot-password';
import { forgotPasswordReducer } from './forgot-password';
import type { HelpCenterState } from './help-center';
import { helpcenterReducer } from './help-center';
import type { HelpObjectsState } from './help-objects';
import type { ArticleState } from './kb-article';
import { articlesReducer } from './kb-article';
import type { LanguagesState } from './languages';
import { languagesReducer } from './languages';
import type { LoginState } from './login';
import { loginReducer } from './login';
import type { LoginAnnouncementsState } from './login-announcements';
import { loginAnnouncementsReducer } from './login-announcements';
import { metaReducer } from './meta';
import type { MetaState } from './meta';
import type { OrganisationsState } from './organisations';
import { organisationsReducer } from './organisations';
import type { PortalState } from './portal';
import { portalReducer } from './portal';
import { portalCustomisationReducer } from './portal-customisation';
import type { PortalCustomisationPersistedState } from './portal-customisation/reducer';
import type { ProfileWebFragmentState } from './profile-web-fragments';
import { profileWebFragmentReducer } from './profile-web-fragments';
import type { RequestCreateState } from './request-create';
import { requestCreateReducer } from './request-create';
import type { RequestDetailsState } from './request-details';
import { requestDetailsReducer } from './request-details';
import type { RequestListState } from './request-list';
import { requestListReducer } from './request-list';
import type { RequestListFiltersState } from './request-list-filters/types';
import type { RequestTypeSuggestionState } from './request-type-suggestion';
import { requestTypeSuggestionReducer } from './request-type-suggestion';
import type { RequestsColumnSettingsState } from './requests-column-settings/types';
import type { ResetPasswordState } from './reset-password';
import { resetPasswordReducer } from './reset-password';
import type { SignUpState } from './sign-up';
import { signUpReducer } from './sign-up';
import type { TimezonesState } from './timezones';
import { timezonesReducer } from './timezones';
import type { UnsubscribedConfirmationState } from './unsubscribed-confirmation';
import { unsubscribedConfirmationReducer } from './unsubscribed-confirmation';
import type { UserState } from './user';
import { userReducer } from './user';
import type { UserRequestsCounts } from './user-requests-counts';
import { userRequestsCountsReducer } from './user-requests-counts';
import type { VerifyEmailState } from './verify-email';
import { verifyEmailReducer } from './verify-email';
import type { VisitPortalState } from './visit-portal';
import { visitPortalReducer } from './visit-portal';

export interface StaticPersistedState {
    helpCenter: HelpCenterState;
    portals: PortalState;
    user: UserState;
    env: EnvState;
    meta: MetaState;
    organisations: OrganisationsState;
    requestCreate: RequestCreateState;
    requestTypeSuggestion: RequestTypeSuggestionState;
    connectFragments: ConnectFragmentsState<ConnectAddonFragments | ConnectAddonFragmentsFailure>;
    kbArticles: ArticleState;
    portalCustomisation: PortalCustomisationPersistedState;
    unsubscribedConfirmation: UnsubscribedConfirmationState;
    login: LoginState;
    forgotPassword: ForgotPasswordState;
    requestDetails: RequestDetailsState;
    timezones: TimezonesState;
    languages: LanguagesState;
    profileWebFragments: ProfileWebFragmentState;
    requestList: RequestListState;
    approvalList: ApprovalListState;
    visitPortal: VisitPortalState;
    resetPassword: ResetPasswordState;
    completeSignup: CompleteSignupState;
    verifyEmail: VerifyEmailState;
    signUp: SignUpState;
    userRequestsCounts: UserRequestsCounts;
    anonymousApproval: AnonymousApprovalState;
    featuredAndSortedPortals: FeaturedAndSortedPortalsState;
    loginAnnouncements: LoginAnnouncementsState;
    blockedAppsBanner: BlockedAppsBannerState;
    aiAnswerFeedbackPage: AiAnswerState;
}

export interface PersistedState extends StaticPersistedState {
    requestsColumnSettings?: RequestsColumnSettingsState;
    requestListFilters?: RequestListFiltersState;
    helpObjects?: HelpObjectsState;
}

const reducerCollection = {
    helpCenter: helpcenterReducer,
    portals: portalReducer,
    user: userReducer,
    env: envReducer,
    meta: metaReducer,
    organisations: organisationsReducer,
    requestCreate: requestCreateReducer,
    requestTypeSuggestion: requestTypeSuggestionReducer,
    connectFragments: connectFragmentsReducer,
    kbArticles: articlesReducer,
    portalCustomisation: portalCustomisationReducer,
    unsubscribedConfirmation: unsubscribedConfirmationReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    requestDetails: requestDetailsReducer,
    timezones: timezonesReducer,
    languages: languagesReducer,
    profileWebFragments: profileWebFragmentReducer,
    requestList: requestListReducer,
    approvalList: approvalListReducer,
    visitPortal: visitPortalReducer,
    resetPassword: resetPasswordReducer,
    completeSignup: completeSignupReducer,
    verifyEmail: verifyEmailReducer,
    signUp: signUpReducer,
    userRequestsCounts: userRequestsCountsReducer,
    anonymousApproval: anonymousApprovalReducer,
    featuredAndSortedPortals: featuredAndSortedPortalsReducer,
    loginAnnouncements: loginAnnouncementsReducer,
    blockedAppsBanner: blockedAppsBannerReducer,
    aiAnswerFeedbackPage: aiAnswerReducer,
};

export type AsyncPersistedReducers = {
    [property in OptionalKeys<PersistedState>]?: Reducer<NonNullable<PersistedState[property]>>;
};

export const getCombinedPersistedReducer = (asyncReducers: AsyncPersistedReducers) =>
    combineReducers({
        ...reducerCollection,
        ...asyncReducers,
    } as ReducersMapObject);

export default combineReducers<PersistedState>(reducerCollection);
