import * as React from 'react';
import type { AnalyticAttributes } from 'state/persisted/portal';
import { AnalyticsContext, ScreenTypes } from '@atlassian/analytics-web-react';

interface PortalContextProps {
    children: React.ReactNode;
    attributes?: AnalyticAttributes;
}

const PortalContext: React.FunctionComponent<PortalContextProps> = ({ children, attributes }) => (
    <AnalyticsContext
        sourceName="portalUnknown"
        sourceType={ScreenTypes.SCREEN}
        attributes={{ context: 'Portal', ...attributes }}
    >
        {children}
    </AnalyticsContext>
);

export default PortalContext;
