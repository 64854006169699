import { defineMessages } from 'react-intl-next';

export default defineMessages({
    requestGroupSelectLabel: {
        defaultMessage: 'Contact us about',
        description: 'Label shown for selecting a request group',
        id: 'sd.portal.request.group.select',
    },
    requestTypeSelectLabel: {
        defaultMessage: 'What can we help you with?',
        description: 'Label shown for picking a request type',
        id: 'sd.portal.request.type.select',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    CSMRequestTypeSelectLabel: {
        defaultMessage: 'What do you need help with?',
        description: 'Label shown for picking a request type',
        id: 'sd.portal.csm.request.type.select',
    },
    emptyStateMessage: {
        defaultMessage: "There's nothing in this portal yet.",
        description: 'This message is shown, when there are no configured request groups for service project',
        id: 'sd.jsm.portal.empty.request.type',
    },
    tryAnotherServiceProjectLink: {
        defaultMessage: 'Go to the help center',
        description:
            'This link is shown, when there are no configured request groups for current service project and there are other service projects available',
        id: 'sd.jsm.portal.empty.sd.link',
    },
});
