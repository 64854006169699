import React from 'react';
import { useIntl } from 'react-intl-next';
import { Main } from '@atlaskit/page-layout';
import i18n from '../messages';

export const MainContentSkipLinkWrapper = ({ children }: { children: React.ReactNode }) => {
    const { formatMessage } = useIntl();
    return (
        <Main id="main" skipLinkTitle={formatMessage(i18n.skipToMainA11yLabel)} testId="main">
            {children}
        </Main>
    );
};
