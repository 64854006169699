import * as React from 'react';
// eslint-disable-next-line react/no-deprecated
import { hydrate, render } from 'react-dom';
import {
    isUseGoogleTranslateIssueWorkaround,
    isUseReact18CreateRootEnabled,
    isReactHydrateDisabledInSSR,
} from 'feature-flags';
import { once } from 'lodash';
import { hydrateRoot, createRoot } from 'react-dom/client';
import LooselyLazy from 'react-loosely-lazy';
import { ModelsCache } from 'rest/models/models-cache/models-cache';
import { install as installWebComponents } from 'view/web-components';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { initBrowserMetricsV3 } from '@atlassian/help-center-common-util/analytics/browser-metrics-v3';
import { initialiseConnectAndSentryAndCleanUrl } from '@atlassian/help-center-common-util/bootstrap';
import { handleDOMGoogleTranslateIssues } from '@atlassian/help-center-common-util/google-translate-issue-workaround';
import { AtlassianHelp } from './atlassian-help';
import { getEnv, getIsPageServerSideRendered } from './util/env';
import { initialisePlatformFeatureFlags } from './util/feature-manager/feature-manager';
import { getMetaData } from './util/meta';

const initialiseAppUtilities = once(() => {
    initialisePlatformFeatureFlags();
    initialiseConnectAndSentryAndCleanUrl();
    installWebComponents();
    initBrowserMetricsV3();
});

export const getApplication = () => {
    const atlassianHelpConfig = { ...getEnv(), ...getMetaData() };
    ModelsCache.getInstance().initFromInitialJsonPayload();
    initialiseAppUtilities();
    return !__SERVER__ ? (
        <UFOSegment name="help-center-root">
            <AtlassianHelp config={atlassianHelpConfig} />
        </UFOSegment>
    ) : (
        <AtlassianHelp config={atlassianHelpConfig} />
    );
};

export const bootstrap = () => {
    LooselyLazy.init({
        retry: 3,
    });
    const rootId = 'root';
    let container = document.getElementById(rootId);
    if (!container) {
        // If there is no root element available yet, add one.
        container = document.createElement('span');
        container.id = rootId;
        document.body.appendChild(container);
    }
    // Invalid report.
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const isPageServerSideRendered = getIsPageServerSideRendered();

    if (isUseGoogleTranslateIssueWorkaround()) {
        // Workaround for this google translate problem https://github.com/facebook/react/issues/11538#issuecomment-417504600
        // which is causing this issue https://hello.jira.atlassian.cloud/browse/PORTAL-5241, affecting view page reliability
        handleDOMGoogleTranslateIssues();
    }

    if (isUseReact18CreateRootEnabled()) {
        if (isPageServerSideRendered && !isReactHydrateDisabledInSSR()) {
            hydrateRoot(container, getApplication());
        } else {
            createRoot(container).render(getApplication());
        }
    } else if (isPageServerSideRendered && !isReactHydrateDisabledInSSR()) {
        hydrate(getApplication(), container);
    } else {
        render(getApplication(), container);
    }
};
