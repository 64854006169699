/**
 * @generated SignedSource<<b28faf0dcbce7b814c3074bdcd5b0ff3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type HelpObjectStoreSearchAlgorithm = "KEYWORD_SEARCH_ON_ISSUES" | "KEYWORD_SEARCH_ON_PORTALS_BM25" | "KEYWORD_SEARCH_ON_PORTALS_EXACT_MATCH" | "KEYWORD_SEARCH_ON_REQUEST_TYPES_BM25" | "KEYWORD_SEARCH_ON_REQUEST_TYPES_EXACT_MATCH" | "%future added value";
export type HelpObjectStoreSearchBackend = "JIRA" | "SEARCH_PLATFORM" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type articleItemFragment$data = {
  readonly __typename: "HelpObjectStoreSearchResult";
  readonly description: string;
  readonly displayLink: string;
  readonly id: string;
  readonly isExternal: boolean;
  readonly searchAlgorithm: HelpObjectStoreSearchAlgorithm | null | undefined;
  readonly searchBackend: HelpObjectStoreSearchBackend | null | undefined;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleMetadataFragment" | "resourceIconFragment">;
  readonly " $fragmentType": "articleItemFragment";
};
export type articleItemFragment$key = {
  readonly " $data"?: articleItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"articleItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "articleItemFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "__typename"
      },
      "action": "THROW",
      "path": "__typename"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "description"
      },
      "action": "THROW",
      "path": "description"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "displayLink"
      },
      "action": "THROW",
      "path": "displayLink"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isExternal"
      },
      "action": "THROW",
      "path": "isExternal"
    },
    {
      "kind": "ScalarField",
      "name": "searchBackend"
    },
    {
      "kind": "ScalarField",
      "name": "searchAlgorithm"
    },
    {
      "kind": "FragmentSpread",
      "name": "resourceIconFragment"
    },
    {
      "kind": "FragmentSpread",
      "name": "articleMetadataFragment"
    }
  ],
  "type": "HelpObjectStoreSearchResult"
};

(node as any).hash = "5d7a00f2cc5ab5ae9593661ae477e1bc";

export default node;
