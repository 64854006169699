import * as React from 'react';
import type { ExperienceNotifierProps, NotifierType, NotifyProps } from './experience-notifier';
import ExperienceNotifier from './experience-notifier';

// upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
// eslint-disable-next-line react/display-name
const createExperienceNotifier = (type: NotifierType) => (props: Omit<ExperienceNotifierProps, 'type'>) => (
    <ExperienceNotifier {...props} type={type} />
);

export type InjectedExperienceNotifierProps = NotifyProps;

/**
 * Will NOT notify when mounted.
 * Use when you need to need async notify failure or success.
 */
export const withExperienceNotifier =
    (experienceLocation: string) =>
    <TComponent extends React.ComponentType<ExtractProps<TComponent> & InjectedExperienceNotifierProps>>(
        WrappedComponent: TComponent
    ) =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: Omit<ExtractProps<TComponent>, 'notifySuccess' | 'notifyFailure'>) => {
        const Component = WrappedComponent as React.ComponentType<ExtractProps<TComponent>>;
        return (
            <ExperienceNotifier type="CAN_SUCCEED_OR_FAIL" location={experienceLocation}>
                {(notify) => <Component {...(props as ExtractProps<TComponent>)} {...notify} />}
            </ExperienceNotifier>
        );
    };

/**
 * When mounted will notify the parent experience of success.
 * Remember all children notifiers need to succeed for the experience to be considered
 * a success.
 */
export const ExperienceSuccess = createExperienceNotifier('IMMEDIATE_SUCCESS');

/**
 * When mounted will notify the parent experience of failure.
 * This will fail the entire experience.
 */
export const ExperienceFailure = createExperienceNotifier('IMMEDIATE_FAILURE');
