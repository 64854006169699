import React, { useCallback } from 'react';
import type { showErrorFlag, showSuccessFlag } from '@helpCenter/state/actions/flags';
import { LoadingButton } from '@helpCenter/view/common/analytics';
import { useIntl } from 'react-intl-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { TriggerProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/analytics-bridge';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { isFetchNetworkOrClientError, type FetchError } from '@atlassian/help-center-common-util/fetch';
import type {
    HelpCenterProjectMappingOperationType,
    useProjectMappingLinkUnlinkMutation$data,
} from '../../../services/use-project-mapping/__generated__/useProjectMappingLinkUnlinkMutation.graphql';
import type { JiraProjectsHelpCenterMappingStatus } from '../__generated__/manageProjects_GetProjectsQuery.graphql';
import { UnlinkConfirmationModal } from '../projects-list/unlink-confirmation-modal/unlink-confirmation-modal';
import { ProjectsMappingOperations, ProjectsMappingStatus } from '../types';
import messages from './messages';

export interface Props {
    projectId: string;
    projectName: string;
    selection: JiraProjectsHelpCenterMappingStatus;
    isLastProject: boolean;
    isDisabled: boolean;
    showErrorFlag: typeof showErrorFlag;
    showSuccessFlag: typeof showSuccessFlag;
    onLinkUnlinkProjects: (
        projectIds: string[],
        operationType: HelpCenterProjectMappingOperationType,
        selection: JiraProjectsHelpCenterMappingStatus
    ) => Promise<useProjectMappingLinkUnlinkMutation$data>;
}

const LinkUnlinkProject = ({
    projectId,
    projectName,
    selection,
    isLastProject,
    isDisabled,
    onLinkUnlinkProjects,
    showErrorFlag: showErrorFlagAction,
    showSuccessFlag: showSuccessFlagAction,
}: Props) => {
    const { formatMessage } = useIntl();
    const [openUnlinkDialog, setOpenUnlinkDialog] = React.useState(false);
    const [isUnlinking, setIsUnlinking] = React.useState(false);
    const [isLinking, setIsLinking] = React.useState(false);
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const [isLastUnlink, setIsLastUnlink] = React.useState(false);

    const onLinkProject = useCallback(() => {
        setIsLinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.LINK, selection)
            .then(() => {
                createAnalyticsEvent({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'map',
                    actionSubject: 'project',
                    operation: 'single',
                    bulkOperation: false,
                    mapStatus: ProjectsMappingOperations.LINK,
                    projectCount: 1,
                }).fire();
                showSuccessFlagAction(messages.linkSuccessTitle, { projectName });
            })
            .catch((error: FetchError) => {
                const { message, status } = error;
                if (!isFetchNetworkOrClientError(error)) {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'map_failed',
                        actionSubject: 'project',
                        bulkOperation: false,
                        mapStatus: ProjectsMappingOperations.LINK,
                        projectCount: 1,
                        errorMessage: message,
                        errorStatus: status,
                    }).fire();
                }
                showErrorFlagAction(messages.linkErrorTitle, messages.genericErrorDescription, [], { projectName });
            })
            .finally(() => {
                setIsLinking(false);
            });
    }, [
        onLinkUnlinkProjects,
        projectId,
        selection,
        showSuccessFlagAction,
        projectName,
        showErrorFlagAction,
        createAnalyticsEvent,
    ]);

    const onUnLinkProject = useCallback(() => {
        if (isLastProject) {
            setIsLastUnlink(true);
        } else {
            setOpenUnlinkDialog(true);
        }
    }, [isLastProject]);

    const onUnlinkDialogButtonClick = useCallback(() => {
        setIsUnlinking(true);
        onLinkUnlinkProjects([projectId], ProjectsMappingOperations.UNLINK, selection)
            .then(() => {
                createAnalyticsEvent({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'map',
                    actionSubject: 'project',
                    bulkOperation: false,
                    mapStatus: ProjectsMappingOperations.UNLINK,
                    projectCount: 1,
                }).fire();
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showSuccessFlagAction(messages.unlinkSuccessTitle, {
                    projectName,
                });
            })
            .catch((error: FetchError) => {
                const { message, status } = error;
                if (!isFetchNetworkOrClientError(error)) {
                    createAnalyticsEvent({
                        analyticsType: TRACK_EVENT_TYPE,
                        action: 'map_failed',
                        actionSubject: 'project',
                        bulkOperation: false,
                        mapStatus: ProjectsMappingOperations.UNLINK,
                        projectCount: 1,
                        errorMessage: message,
                        errorStatus: status,
                    }).fire();
                }
                setIsUnlinking(false);
                setOpenUnlinkDialog(false);
                showErrorFlagAction(messages.unlinkErrorTitle, messages.genericErrorDescription, [], { projectName });
            });
    }, [
        onLinkUnlinkProjects,
        projectId,
        projectName,
        selection,
        showErrorFlagAction,
        showSuccessFlagAction,
        createAnalyticsEvent,
    ]);

    const contentCallback = useCallback(() => {
        return (
            <ContextualAnalyticsData sourceType={INLINE_DIALOG} sourceName="lastProjectUnlink">
                <Box xcss={unlinkDialogPopupStyles}>{formatMessage(messages.lastProjectUnlink)}</Box>
                <FireScreenEventOnMount />
            </ContextualAnalyticsData>
        );
    }, [formatMessage]);

    const triggerCallback = useCallback(
        (triggerProps: TriggerProps) => {
            return (
                <LoadingButton
                    {...triggerProps}
                    appearance="link"
                    actionSubjectId="projectUnLink"
                    onClick={onUnLinkProject}
                    isLoading={isLinking}
                    isDisabled={isDisabled}
                >
                    {formatMessage(messages.projectsListUnlinkButtonText)}
                </LoadingButton>
            );
        },
        [formatMessage, isLinking, onUnLinkProject, isDisabled]
    );

    return (
        <>
            {selection === ProjectsMappingStatus.LINKED ? (
                <Popup
                    isOpen={isLastUnlink}
                    onClose={() => setIsLastUnlink(false)}
                    placement="bottom-end"
                    zIndex={layers.modal()}
                    content={contentCallback}
                    trigger={triggerCallback}
                />
            ) : (
                <LoadingButton
                    actionSubjectId="projectLink"
                    appearance="link"
                    onClick={onLinkProject}
                    isLoading={isLinking}
                    isDisabled={isDisabled}
                >
                    {formatMessage(messages.projectsListLinkButtonText)}
                </LoadingButton>
            )}
            <UnlinkConfirmationModal
                isOpen={openUnlinkDialog}
                onClose={() => setOpenUnlinkDialog(false)}
                onUnlink={onUnlinkDialogButtonClick}
                isLoading={isUnlinking}
                numberOfProjects={1}
            />
        </>
    );
};

const unlinkDialogPopupStyles = xcss({
    maxWidth: '230px',
    padding: 'space.200',
});

export default LinkUnlinkProject;
