// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { token } from '@atlaskit/tokens';
import type { LinkProps } from '@atlassian/help-center-common-component/analytics/link';
import { Link } from '@atlassian/help-center-common-component/analytics/link';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const style = css<LinkProps>`
    color: ${(props) => token('color.link', props.theme.color)};

    &:hover {
        color: ${(props) => token('color.link', props.theme.color)};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ThemedLink = styled(Link)`
    ${style};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export default ThemedLink;
