import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getLocale } from 'state/selectors/user';
import { DeferredWrmResourceProvider } from './wrm-resource-provider';

export { DeferredWrmResourceContext } from './wrm-resource-provider';

const selector = createSelector(getLocale, (locale) => ({
    locale,
}));

export default connect(selector)(DeferredWrmResourceProvider);
