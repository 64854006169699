import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled2 from 'styled-components';
import * as fonts from 'view/styles/fonts';
import { Label, RequiredAsterisk } from '@atlaskit/form';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FieldDescription } from './field-description';

export interface FieldBaseProps {
    label: string;
    labelId?: string;
    htmlFor: string;
    errorMessage?: React.ReactNode;
    description?: string | React.ReactNode;
    required?: boolean;
    children?: React.ReactNode;
    noLabel?: boolean;
}

const FieldBase: React.FC<FieldBaseProps> = ({
    label,
    labelId,
    required,
    errorMessage,
    description,
    children,
    noLabel,
    htmlFor,
}) => {
    const renderField = () =>
        noLabel ? (
            children
        ) : (
            <>
                {/* The old label had a margingTop of 20px but Field has marginTop of 8px hence this FieldContainer is required */}
                <FieldContainer>
                    <Label htmlFor={htmlFor} id={labelId}>
                        {label}
                        {required && (
                            <>
                                <RequiredAsterisk />
                                <RequiredMessage>(required)</RequiredMessage>
                            </>
                        )}
                    </Label>
                </FieldContainer>
                {children}
            </>
        );

    return (
        <div data-test-id={`request-field-${htmlFor}`}>
            {renderField()}
            {!!errorMessage && (
                <ErrorText data-test-id="field-error" id="field-error" role="status" aria-live="assertive">
                    {errorMessage}
                </ErrorText>
            )}
            {description && <FieldDescription id={`${htmlFor}-field-description`}>{description}</FieldDescription>}
        </div>
    );
};

export default FieldBase;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ErrorText = styled2.p`
    ${fonts.small};
    margin-top: ${token('space.050', '4px')};
    color: ${token('color.text.danger', colors.R500)};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FieldContainer = styled.div({
    marginTop: token('space.200', '16px'),
    paddingTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const RequiredMessage = styled.span({
    clip: 'rect(1px, 1px, 1px, 1px)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
    '&:focus': {
        clip: 'auto',
        height: 'auto',
        overflow: 'auto',
        position: 'absolute',
        width: 'auto',
    },
});
