import { buildModelsActionCreator } from 'epics/model/actions';
import type { LoginAnnouncementsResponse, LoginAnnouncementsUpdateRequestPayload } from 'rest/login-announcements';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_LOGIN_ANNOUNCEMENTS = 'epic.model.FETCH_LOGIN_ANNOUNCEMENTS';
export const FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS = 'state.persisted.loginAnnouncements.FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS';
export const FETCH_LOGIN_ANNOUNCEMENTS_FAILURE = 'state.persisted.loginAnnouncements.FETCH_LOGIN_ANNOUNCEMENTS_FAILURE';
export const UPDATE_LOGIN_ANNOUNCEMENTS_MODEL = 'epic.login-announcements.UPDATE_LOGIN_ANNOUNCEMENTS_MODEL';
export const UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE = 'state.ui.login-announcements.UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE';

export const UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS =
    'state.persisted.loginAnnouncements.UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS';

export const UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE =
    'state.persisted.loginAnnouncements.UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE';

export const RESET_LOGIN_ANNOUNCEMENTS = 'state.ui.loginAnnouncements.RESET_LOGIN_ANNOUNCEMENTS';

export const fetchLoginAnnouncementModel = buildModelsActionCreator(FETCH_LOGIN_ANNOUNCEMENTS, 'loginAnnouncements');

export type FetchLoginAnnouncementsModelAction = ReturnType<typeof fetchLoginAnnouncementModel>;

export interface FetchLoginAnnouncementsSuccess {
    type: typeof FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS;
    payload: LoginAnnouncementsResponse;
}

export const fetchLoginAnnouncementsSuccess = (
    loginAnnouncementsModel: LoginAnnouncementsResponse
): FetchLoginAnnouncementsSuccess => ({
    type: FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS,
    payload: loginAnnouncementsModel,
});

export interface FetchLoginAnnouncementsFailure {
    type: typeof FETCH_LOGIN_ANNOUNCEMENTS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchLoginAnnouncementsFailure = (error: ErrorResponse): FetchLoginAnnouncementsFailure => ({
    type: FETCH_LOGIN_ANNOUNCEMENTS_FAILURE,
    payload: {
        error,
    },
});

/**
 * update dispatchers
 */

export interface UpdateLoginAnnouncementsModel {
    type: typeof UPDATE_LOGIN_ANNOUNCEMENTS_MODEL;
    payload: LoginAnnouncementsUpdateRequestPayload;
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
    };
}

export const updateLoginAnnouncementsModel = (
    loginAnnouncementsModel: LoginAnnouncementsUpdateRequestPayload,
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
    }
): UpdateLoginAnnouncementsModel => ({
    meta,
    type: UPDATE_LOGIN_ANNOUNCEMENTS_MODEL,
    payload: loginAnnouncementsModel,
});

export interface UpdateLoginAnnouncementsSuccess {
    type: typeof UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS;
    payload: LoginAnnouncementsUpdateRequestPayload;
}

export const updateLoginAnnouncementsSuccess = (
    loginAnnouncementsModel: LoginAnnouncementsUpdateRequestPayload
): UpdateLoginAnnouncementsSuccess => ({
    type: UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS,
    payload: loginAnnouncementsModel,
});

export interface UpdateLoginAnnouncementsFailure {
    type: typeof UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const updateLoginAnnouncementsFailure = (error: ErrorResponse): UpdateLoginAnnouncementsFailure => ({
    type: UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE,
    payload: {
        error,
    },
});
export interface UpdateLoginAnnouncementsUIState {
    type: typeof UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE;
    payload: LoginAnnouncementsUpdateRequestPayload;
}

export const updateLoginAnnouncementsUIState = (
    loginAnnouncementsModel: LoginAnnouncementsUpdateRequestPayload
): UpdateLoginAnnouncementsUIState => ({
    type: UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE,
    payload: loginAnnouncementsModel,
});

export interface ResetLoginAnnouncementsSettings {
    type: typeof RESET_LOGIN_ANNOUNCEMENTS;
}

export const resetLoginAnnouncements = (): ResetLoginAnnouncementsSettings => ({
    type: RESET_LOGIN_ANNOUNCEMENTS,
});
