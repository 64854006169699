import { put } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface RequestGroupReorderRequest {
    id: string;
    deleted: number[];
    order: number[];
    inline: boolean;
    projectId: number;
}

export interface RequestGroup {
    id: number;
    name: string;
}

export interface RequestGroupResponse {
    reordered: RequestGroup[];
    deleted: RequestGroup[];
    deletedTime: number;
}

export const requestGroupReorder = ({
    id,
    deleted,
    order,
    inline,
    projectId,
}: RequestGroupReorderRequest): Observable<RequestGroupResponse> => {
    const uri = `${contextPath}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups`;

    const body = { id, deleted, order, inline };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return put(uri, body, { 'Content-Type': 'application/json' })
        .map(({ response }) => response as RequestGroupResponse)
        .catch((e) => {
            return Observable.throw(e);
        });
};
