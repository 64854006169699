// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as grid from 'view/styles/grid';

import { portalCardsContainerStyle, portalCardsContainerWithSidebarStyle } from 'view/styles/help-center';
import * as mixins from 'view/styles/mixins';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled.div`
    ${mixins.flexCenter};
    flex-direction: column;
    padding: ${token('space.100', '8px')} ${token('space.100', '8px')} ${token('space.600', '48px')}
        ${token('space.100', '8px')};
    align-self: center;
    width: 100%;
    &.sidebar-opened {
        ${portalCardsContainerWithSidebarStyle}
    }
    &.sidebar-closed {
        ${portalCardsContainerStyle}
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FeaturedAndSortedPortalsListContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -${grid.multiple(5).px} 0;
`;
