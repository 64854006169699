import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type { HandleAjaxError, ShowErrorFlag } from 'state/actions/flags';
import { HANDLE_AJAX_ERROR, showErrorFlag } from 'state/actions/flags';
import { getDefaultError } from '@atlassian/help-center-common-util/errors';

export const handleAjaxErrorEpic: Epic<HandleAjaxError, ShowErrorFlag> = (action$) => {
    return action$.ofType(HANDLE_AJAX_ERROR).mergeMap((handleAjaxErrorAction) => {
        const { error } = handleAjaxErrorAction.payload;
        // TODO: Handle errors that come directly from the backend already translated (https://jdog.jira-dev.com/browse/JNW-236)
        const errorDescriptor = getDefaultError(error);
        const genericAction = errorDescriptor.action === undefined ? undefined : [errorDescriptor.action];
        const errorFlagAction = showErrorFlag(errorDescriptor.title, errorDescriptor.description, genericAction);
        return Observable.of(errorFlagAction);
    });
};
