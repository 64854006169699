import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import { actions } from './actions';
import { ConversationStateContainer } from './container';
import {
    getAppendicesFromLastMessage,
    getIsLastMessage,
    getJiraFieldFromLastMessage,
    getLastHelpseekerMessage,
} from './selectors';
import type { State } from './types';

const initialState: State = {
    conversationProvider: {
        sendMessage: () => Promise.reject(new Error('No conversation provider')),
        startConversation: () => Promise.reject(new Error('No conversation provider')),
    },
    messages: [],
};

const store = createStore({
    actions,
    initialState,
    name: 'Conversation',
    containedBy: ConversationStateContainer,
    handlers: {
        onInit:
            () =>
            (
                { setState },
                {
                    conversationProvider,
                    portalId,
                    projectId,
                    contextId,
                    baseUrl,
                    isTestConversation,
                    handleEscalateFromVirtualAgent,
                }
            ) => {
                setState({
                    conversationProvider,
                    portalId,
                    projectId,
                    contextId,
                    baseUrl,
                    isTestConversation,
                    handleEscalateFromVirtualAgent,
                });
            },
        onContainerUpdate:
            () =>
            (
                { setState },
                {
                    conversationProvider,
                    portalId,
                    projectId,
                    contextId,
                    baseUrl,
                    isTestConversation,
                    handleEscalateFromVirtualAgent,
                }
            ) => {
                setState({
                    conversationProvider,
                    portalId,
                    projectId,
                    contextId,
                    baseUrl,
                    isTestConversation,
                    handleEscalateFromVirtualAgent,
                });
            },
    },
});

export const useMessages = createStateHook(store, {
    selector: (state) => state.messages,
});

export const useIsLoading = createStateHook(store, {
    selector: (state) => state.isLoading,
});

export const useError = createStateHook(store, {
    selector: (state) => state.error,
});

export const useBaseUrl = createStateHook(store, {
    selector: (state) => state.baseUrl,
});

export const useAppendices = createStateHook(store, {
    selector: getAppendicesFromLastMessage,
});

export const useFieldFromLastMessage = createStateHook(store, { selector: getJiraFieldFromLastMessage });

export const useLastHelpseekerMessage = createStateHook(store, {
    selector: getLastHelpseekerMessage,
});

export const useIsLastMessage = createStateHook(store, {
    selector: getIsLastMessage,
});

export const useConversationHasMessages = createStateHook(store, {
    selector: (state) => state.messages.length > 0,
});

export const useActions = createActionsHook(store);
