import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { isUseHelpCenterRefresh } from 'feature-flags';
import { getHelpCenterBanner, getHelpCenterBrandingLoader } from 'state/selectors/help-center';
import { getShown as getSearchShown } from 'state/selectors/search';
import { getObjectURLFromMediaURLWithCaching } from '@atlassian/help-center-common-util/media-services';
import { BannerWithGridLayoutDumb } from '../banner-with-grid-layout-dumb';
import type { Props } from '../banner-with-paper-layout-dumb';
import { BannerWithPaperLayoutDumb } from '../banner-with-paper-layout-dumb';
import { withLayoutRoute } from '../with-layout-route';

interface State {
    helpCenterBannerUrl?: string;
}

export class BannerWithPaperLayout extends React.Component<Props, State> {
    state: State = {
        helpCenterBannerUrl: '',
    };

    componentDidMount() {
        this.getHelpCenterBannerUrl();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.helpCenterBanner !== prevProps.helpCenterBanner) {
            this.getHelpCenterBannerUrl();
        }
    }

    getHelpCenterBannerUrl() {
        const { helpCenterBanner } = this.props;
        // We don't need to call the media url helper if the banner is empty or if it's already a blob
        if (!!helpCenterBanner && !helpCenterBanner.startsWith('blob:')) {
            getObjectURLFromMediaURLWithCaching(helpCenterBanner)
                .then((url: string) => {
                    this.setState({ helpCenterBannerUrl: url });
                })
                .catch((errorMessage) => {
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    throw new Error(`Could not load help center banner. Reason: ${errorMessage}`);
                });
        } else {
            this.setState({ helpCenterBannerUrl: helpCenterBanner });
        }
    }

    render() {
        const { children, helpCenterBanner: _, ...props } = this.props;
        if (isUseHelpCenterRefresh()) {
            return (
                <BannerWithGridLayoutDumb {...props} helpCenterBanner={this.state.helpCenterBannerUrl}>
                    {children}
                </BannerWithGridLayoutDumb>
            );
        }
        return (
            <BannerWithPaperLayoutDumb {...props} helpCenterBanner={this.state.helpCenterBannerUrl}>
                {children}
            </BannerWithPaperLayoutDumb>
        );
    }
}

const selector = createSelector(
    getHelpCenterBanner,
    getSearchShown,
    getHelpCenterBrandingLoader,
    (helpCenterBanner, searchShown, isBrandingLoaded) => ({
        helpCenterBanner,
        searchShown,
        isBrandingLoaded,
    })
);

const ConnectedBannerWithPaperLayout = connect(selector)(BannerWithPaperLayout);
export const BannerWithPaperLayoutRoute = withLayoutRoute(ConnectedBannerWithPaperLayout);
export default ConnectedBannerWithPaperLayout;
