import { defineMessages } from 'react-intl-next';

export default defineMessages({
    requestSharedWithSingleMentionedUserSuccessTitle: {
        defaultMessage: 'New request participant added',
        description: 'request shared with the single user mentioned in comment successfully title',
        id: 'sd.comment.create.request.shared.with.single.mentioned.user.success.title',
    },
    requestSharedWithSingleMentionedUserSuccessDescription: {
        defaultMessage: '{userName} has been added as a request participant to this request.',
        description: 'request shared with the single user mentioned in comment successfully description',
        id: 'sd.comment.create.request.shared.with.single.mentioned.user.success.description',
    },
    requestSharedWithMentionedUsersSuccessTitle: {
        defaultMessage: 'New request participants added',
        description: 'request shared with users mentioned in comment successfully title',
        id: 'sd.comment.create.request.shared.with.mentioned.users.success.title',
    },
    requestSharedWithMentionedUsersSuccessDescription: {
        defaultMessage: 'The users you mentioned have been added as request participants to this request.',
        description: 'request shared with users mentioned in comment successfully description',
        id: 'sd.comment.create.request.shared.with.mentioned.users.success.description',
    },
    requestSharedWithSingleMentionedUserFailureTitle: {
        defaultMessage: 'We couldn’t add this request participant',
        description: 'requests sharing with single user failed title',
        id: 'sd.comment.create.request.shared.with.single.mentioned.user.failure.title',
    },
    requestSharedWithSingleMentionedUserFailureDescription: {
        defaultMessage: '{userName} couldn’t be added to this request. Try adding them again in a few minutes. ',
        description: 'request sharing with single user failed description',
        id: 'sd.comment.create.request.shared.with.single.mentioned.user.failure.description',
    },
    requestSharedWithMentionedUsersFailureTitle: {
        defaultMessage: 'We couldn’t add these request participants',
        description: 'requests sharing failed title',
        id: 'sd.comment.create.request.shared.with.mentioned.users.failure.title',
    },
    requestSharedWithMentionedUsersFailureDescription: {
        defaultMessage:
            'The users you mentioned couldn’t be added to this request. Try adding them again in a few minutes. ',
        description: 'request sharing failed description',
        id: 'sd.comment.create.request.shared.with.mentioned.users.failure.description',
    },
});
