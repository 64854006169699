import * as React from 'react';
import { SINGLE_LINE_PARAGRAPH_HEIGHT } from 'view/styles/grid';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { getBoundingBox } from '@atlassian/help-center-common-util/dom';
import { AnnouncementBannerDumb } from './announcement-banner-dumb';

/**
 * This is used for the clamping logic inside the announcement.
 * It is VERY brittle and WILL break if you change font-size/line-height
 * inside the announcement. Watch out!
 */
const SINGLE_LINE_TITLE_HEIGHT = 23;

export interface AnnouncementBannerProps {
    header: string | undefined;
    messageHtml: string | undefined;
    /**
     * Useful when wanting to cap out the announcement to a particular width, but allow
     * the root container button to extend full width.
     */
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/ban-types
    InnerContainerComponent: React.ComponentType<{}>;
    onExpandClicked?: (headerCharCount?: number, messageCharCount?: number) => void;
    onCollapseClicked?: (headerCharCount?: number, messageCharCount?: number) => void;
    actionSubjectId: string;
    removeBgColor?: boolean;
    isLayoutInEditMode?: boolean;
    isLayoutInPreviewMode?: boolean;
    removePadding?: boolean;
}

interface AnnouncementBannerState {
    isExpanded: boolean;
    isExpandable: boolean;
}

/**
 * This component will have big problems if/when we do SSR. Be careful out there.
 */
class AnnouncementBanner extends React.Component<
    AnnouncementBannerProps & WithAnalyticsEventsProps,
    AnnouncementBannerState
> {
    static defaultProps = {
        InnerContainerComponent: React.Fragment,
    };

    state: AnnouncementBannerState = {
        // We expand the announcement banner initially so we can calculate if it is expandable or not on mount.
        isExpanded: true,
        isExpandable: false,
    };

    headerRef: HTMLElement | null;
    messageRef: HTMLElement | null;

    componentDidMount() {
        // On mount we will calculate the height of the header/message and set isExpandable accordingly.
        // This will only be set once (on mount) - if we eventually want to dynamically update this we
        // will want to implement a componentDidUpdate() method, check if header/message changed, and
        // recalculate.
        this.calculateIfExpandable();
    }

    onHeaderRef = (refNode: HTMLElement | null) => {
        this.headerRef = refNode;
        this.calculateIfExpandable();
    };

    onMessageRef = (refNode: HTMLElement | null) => {
        this.messageRef = refNode;
        this.calculateIfExpandable();
    };

    fireAnalytics(action: string) {
        const { createAnalyticsEvent, actionSubjectId } = this.props;

        const { headerCharCount, messageCharCount } = this.getAnnouncementCharCounts();

        if (createAnalyticsEvent) {
            const analyticsEvent = createAnalyticsEvent({
                actionSubjectId,
                action,
                analyticsType: UI_EVENT_TYPE,
                attributes: {
                    messageCharCount,
                    titleCharCount: headerCharCount,
                },
            });
            analyticsEvent.fire();
        }
    }

    expandBanner = () => {
        const { onExpandClicked } = this.props;
        const { headerCharCount, messageCharCount } = this.getAnnouncementCharCounts();
        onExpandClicked && onExpandClicked(headerCharCount, messageCharCount);
        this.fireAnalytics('expanded');

        this.setState({ isExpanded: true });
    };

    collapseBanner = () => {
        const { onCollapseClicked } = this.props;
        const { headerCharCount, messageCharCount } = this.getAnnouncementCharCounts();
        onCollapseClicked && onCollapseClicked(headerCharCount, messageCharCount);
        this.fireAnalytics('collapsed');

        this.setState({ isExpanded: false });
    };

    /**
     * This will return char counts for the header and message. It uses inner text so it can ignore
     * HTML markup in the calculation.
     */
    getAnnouncementCharCounts = () => {
        const headerCharCount =
            this.headerRef && this.headerRef.innerText ? this.headerRef.innerText.length : undefined;
        const messageCharCount =
            this.messageRef && this.messageRef.innerText ? this.messageRef.innerText.length : undefined;

        return {
            headerCharCount,
            messageCharCount,
        };
    };

    calculateIfExpandable() {
        let isExpandable = false;

        // If advanced customization is enabled and the layout is in edit mode, the banner should not be expandable.
        if (this.props.isLayoutInEditMode) {
            this.setState({ isExpandable: false, isExpanded: true });
            return;
        }

        if (this.headerRef || this.messageRef) {
            const headerHeight = (this.headerRef && getBoundingBox(this.headerRef).height) || 0;
            const messageHeight = (this.messageRef && getBoundingBox(this.messageRef).height) || 0;
            const headerOverOneLine = headerHeight > SINGLE_LINE_TITLE_HEIGHT;
            const messageOverOneLine = messageHeight > SINGLE_LINE_PARAGRAPH_HEIGHT;

            isExpandable = headerOverOneLine || messageOverOneLine;
        }

        // The announcement banner is expanded by default.
        this.setState({ isExpandable, isExpanded: true });
    }

    render() {
        const {
            header,
            messageHtml,
            InnerContainerComponent,
            removeBgColor,
            isLayoutInEditMode,
            isLayoutInPreviewMode,
            removePadding,
        } = this.props;
        const { isExpanded, isExpandable } = this.state;

        return (
            <AnnouncementBannerDumb
                header={header}
                isExpanded={isExpanded}
                isExpandable={isExpandable}
                messageHtml={messageHtml}
                onHeaderRef={this.onHeaderRef}
                onMessageRef={this.onMessageRef}
                expandBanner={this.expandBanner}
                shrinkBanner={this.collapseBanner}
                InnerContainerComponent={InnerContainerComponent}
                removeBgColor={removeBgColor}
                isCursorGrab={isLayoutInEditMode && isLayoutInPreviewMode === false}
                removePadding={removePadding}
            />
        );
    }
}

export default AnnouncementBanner;
