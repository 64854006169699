import type { AuthInfo } from '@atlassian/help-center-common-util/login';

export const RESEND_VALIDATION_EMAIL = 'epic.persisted.signup.RESEND_VALIDATION_EMAIL';
export const RESEND_VALIDATION_EMAIL_SUCCESS = 'epic.persisted.signup.RESEND_VALIDATION_EMAIL_SUCCESS';
export const RESEND_VALIDATION_EMAIL_FAILURE = 'epic.persisted.signup.RESEND_VALIDATION_EMAIL_FAILURE';
export const ENABLE_RESEND_VALIDATION_EMAIL_LINK = 'epic.persisted.signup.ENABLE_RESEND_VALIDATION_EMAIL_LINK';

export const SET_SIGN_UP_DATA = 'epic.persisted.signup.SET_SIGN_UP_DATA';

export interface ResendValidationEmail {
    type: typeof RESEND_VALIDATION_EMAIL;
    payload: {
        email: string;
        portalId?: number;
        redirectUrl?: string;
    };
}

export interface SetSignupDataAction {
    type: typeof SET_SIGN_UP_DATA;
    payload: {
        email: string;
        authInfo: AuthInfo;
    };
}

export const resendValidationEmail = (
    email: string,
    portalId: number | undefined,
    redirectUrl: string | undefined
): ResendValidationEmail => ({
    type: RESEND_VALIDATION_EMAIL,
    payload: {
        email,
        portalId,
        redirectUrl,
    },
});

export interface ResendValidationEmailSuccess {
    type: typeof RESEND_VALIDATION_EMAIL_SUCCESS;
}

export const resendValidationEmailSuccess = (): ResendValidationEmailSuccess => ({
    type: RESEND_VALIDATION_EMAIL_SUCCESS,
});

export interface ResendValidationEmailFailure {
    type: typeof RESEND_VALIDATION_EMAIL_FAILURE;
}

export const resendValidationEmailFailure = (): ResendValidationEmailFailure => ({
    type: RESEND_VALIDATION_EMAIL_FAILURE,
});

export interface EnableResendValidationEmailLink {
    type: typeof ENABLE_RESEND_VALIDATION_EMAIL_LINK;
}

export const enableResendValidationEmailLink = (): EnableResendValidationEmailLink => ({
    type: ENABLE_RESEND_VALIDATION_EMAIL_LINK,
});

export const setSignupDataAction = (email: string, authInfo: AuthInfo): SetSignupDataAction => ({
    type: SET_SIGN_UP_DATA,
    payload: { email, authInfo },
});
