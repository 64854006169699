import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'epics/rxjs';
import type { Epic } from 'epics/rxjs';
import type { RequestTypePayload, RequestGroup } from 'rest/request-types';
import { setRequestGroupsForRequestType } from 'rest/request-types';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import type {
    SetRequestTypeGroupsAction,
    SetRequestTypeGroupsFailedAction,
} from 'state/actions/set-request-type-groups';
import { SET_REQUEST_TYPE_GROUPS, setRequestTypeGroupsFailedAction } from 'state/actions/set-request-type-groups';
import type { ReqTypesState } from 'state/persisted/portal';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getFirstMessageFromJiraError } from '@atlassian/help-center-common-util/errors';

const getIconIdFromIconUrl = (iconUrl: string) => iconUrl.substring(iconUrl.lastIndexOf('=') + 1, iconUrl.length);

const getSanitizedText = (html: string) => {
    const stripHtmlTagsRegex = /(<([^>]+)>)/gi;
    return html.replace(stripHtmlTagsRegex, '');
};

const getSerializedRequestTypeWithRequestGroups = (
    requestType: ReqTypesState,
    requestGroups: RequestGroup[] // The user selected request groups
): RequestTypePayload => ({
    id: requestType.id,
    name: requestType.name,
    // Can remove after https://jdog.jira-dev.com/browse/JDW-292
    icon: getIconIdFromIconUrl(requestType.iconUrl),
    iconUrl: requestType.iconUrl,
    description: getSanitizedText(requestType.descriptionHtml),
    groups: requestGroups,
});

export const setRequestTypeGroupsEpic: Epic<
    SetRequestTypeGroupsAction,
    FetchPortalAction | SetRequestTypeGroupsFailedAction
> = (action$: ActionsObservable<SetRequestTypeGroupsAction>) => {
    return action$
        .ofType(SET_REQUEST_TYPE_GROUPS)
        .mergeMap(
            ({
                payload: {
                    isProjectSimplified,
                    portalId,
                    projectId,
                    requestType,
                    updatedRequestTypeGroupId,
                    requestGroups,
                    analyticsEvent,
                    analyticsFailureEvent,
                },
            }: SetRequestTypeGroupsAction) => {
                return setRequestGroupsForRequestType({
                    isProjectSimplified,
                    projectId,
                    updatedRequestTypeGroupId,
                    requestGroupId: 'hidden',
                    requestType: getSerializedRequestTypeWithRequestGroups(requestType, requestGroups),
                })
                    .map(() => {
                        if (analyticsEvent) {
                            sendEvent(analyticsEvent);
                        }
                        return fetchPortalAction({
                            id: portalId,
                            expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'],
                        });
                    })
                    .catch((error: AjaxError) => {
                        if (analyticsFailureEvent) {
                            sendEvent(analyticsFailureEvent);
                        }
                        return Observable.of(
                            setRequestTypeGroupsFailedAction(getFirstMessageFromJiraError(error.response))
                        );
                    });
            }
        );
};
