import * as React from 'react';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';

export interface InjectedProps {
    screenName: ScreenName | undefined;
    screenId?: string | number;
}

const ScreenNameContext = React.createContext<InjectedProps>({
    screenName: undefined,
});

export const ScreenNameProvider: React.FunctionComponent<{
    children: React.ReactNode;
    params: InjectedProps;
}> = (props) => <ScreenNameContext.Provider value={props.params}>{props.children}</ScreenNameContext.Provider>;

export const clearScreenName =
    <TProps extends React.JSX.IntrinsicAttributes>(
        WrappedComponent: React.ComponentType
    ): React.FunctionComponent<TProps> =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props) => (
        <ScreenNameContext.Provider value={{ screenName: undefined }}>
            <WrappedComponent {...props} />
        </ScreenNameContext.Provider>
    );

const withScreenName =
    <TProps extends InjectedProps>(
        WrappedComponent: React.ComponentType<TProps>
    ): React.FunctionComponent<Omit<TProps, keyof InjectedProps>> =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: TProps) => (
        <ScreenNameContext.Consumer>
            {(value: InjectedProps) => <WrappedComponent {...props} {...value} />}
        </ScreenNameContext.Consumer>
    );

export default withScreenName;
