import * as React from 'react';
import type { ConnectFragmentLocation } from 'state/selectors/connect-addon';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';

import { getAppKeyFromFullKey } from '@atlassian/help-center-common-util/connect';

/* Unstyled component that renders a connect iframe */

export interface Props {
    fragmentHtml: string;
    fullKey?: string;
    location?: ConnectFragmentLocation;
    onMount?: () => void;
}

class ConnectFragmentDumb extends React.Component<Props & WithAnalyticsEventsProps> {
    savedElement: HTMLElement;

    componentDidMount = () => {
        const { onMount } = this.props;
        onMount && onMount();
    };

    onRef = (element: HTMLElement | null) => {
        if (element) {
            this.savedElement = element;
        }
        this.executeConnectIFrameScripts();
    };

    executeConnectIFrameScripts = () => {
        if (this.savedElement) {
            // We manually go through each script tag and then clone it so that it will execute
            const scriptTags = this.savedElement.querySelectorAll('script') || [];

            Array.prototype.forEach.call(scriptTags, (scriptTag: HTMLElement) => {
                if (scriptTag.parentNode) {
                    // We create a new script tag and then append it to the parent node this way it will execute properly.
                    const newScript = document.createElement('script');
                    newScript.innerHTML = scriptTag.innerHTML;
                    scriptTag.parentNode.replaceChild(newScript, scriptTag);
                }
            });
        }
    };

    componentDidUpdate = () => {
        // If we don't call this, then Connect addon iframes are not rendered when a component is reloaded
        this.executeConnectIFrameScripts();
    };

    render() {
        return <div ref={this.onRef} dangerouslySetInnerHTML={{ __html: this.props.fragmentHtml }} />;
    }
}

export default withAnalyticsContext({ componentName: 'ConnectFragment' })(
    withAnalyticsEvents({
        onMount: (create, props: Props) =>
            create({
                action: 'mounted',
                analyticsType: OPERATIONAL_EVENT_TYPE,
                location: props.location ?? 'LocationUnknown',
                appKeys: [getAppKeyFromFullKey(props.fullKey)],
            }).fire(),
    })(ConnectFragmentDumb)
);
