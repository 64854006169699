import { defineMessages } from 'react-intl-next';

export default defineMessages({
    backToSettings: {
        defaultMessage: 'Cancel',
        description: 'Action label for back button in layout builder screen',
        id: 'cv.portal.customization.action.cancel.help.center.settings',
    },
    giveFeedback: {
        defaultMessage: 'Give feedback',
        description: 'The text for give feedback button for layout builder screen',
        id: 'cv.portal.customization.action.give.feedback.help.center.settings',
    },
    publish: {
        defaultMessage: 'Publish',
        description: 'Label for publish button',
        id: 'cv.portal.customization.action.publish.layout.label',
    },
    preview: {
        defaultMessage: 'Preview',
        description: 'Label for preview button',
        id: 'cv.portal.customization.action.preview.layout.label',
    },
    previewMessage: {
        defaultMessage: 'This is a preview of the changes you made to the page layout.',
        description: 'Message displayed when user is previewing layout changes',
        id: 'cv.portal.customization.action.preview.layout.message',
    },
    exitPreview: {
        defaultMessage: 'Back',
        description: 'Label for exit preview button',
        id: 'cv.portal.customization.action.exit.preview.layout.label',
    },
});
