import isEmpty from 'lodash/isEmpty';
import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { FetchLoginSuccess, FetchLoginFailure } from 'state/actions/login';
import { fetchLoginSuccess, fetchLoginFailure } from 'state/actions/login';

import type { FetchLoginAction } from './actions';
import { FETCH_LOGIN_MODEL } from './actions';
import type { ErrorResponse } from 'epics/model/types';

export const loginEpic: Epic<FetchLoginAction, FetchLoginSuccess | FetchLoginFailure> = (action$) => {
    return action$.ofType(FETCH_LOGIN_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.login && !isEmpty(response.login))
            .map((response) => {
                return fetchLoginSuccess(response.login!);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchLoginFailure(error));
            })
    );
};
