import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { LoginAnnouncementsResponse } from 'rest/login-announcements';
import type {
    FetchLoginAnnouncementsModelAction,
    FetchLoginAnnouncementsSuccess,
    FetchLoginAnnouncementsFailure,
} from 'state/actions/login-announcements';
import {
    FETCH_LOGIN_ANNOUNCEMENTS,
    fetchLoginAnnouncementsSuccess,
    fetchLoginAnnouncementsFailure,
} from 'state/actions/login-announcements';
import { isKoshEnabledForDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import {
    convertToLoginAnnouncementsResponse,
    fetchLoginAnnouncementsData,
} from '@atlassian/help-center-common-util/graphql-relay-connector/login-announcements';
import type { ErrorResponse } from 'epics/model/types';

export const DEFAULT_RESPONSE: LoginAnnouncementsResponse = {
    defaultLocale: '',
    translatedLoginAnnouncements: {},
};

export const loginAnnouncementsEpic: Epic<
    FetchLoginAnnouncementsModelAction,
    FetchLoginAnnouncementsSuccess | FetchLoginAnnouncementsFailure
> = (action$) => {
    return action$.ofType(FETCH_LOGIN_ANNOUNCEMENTS).mergeMap((action) => {
        if (isKoshEnabledForDefaultHelpCenter()) {
            return Observable.from(
                fetchLoginAnnouncementsData()
                    .toPromise()
                    .then((response) => convertToLoginAnnouncementsResponse(response))
            )
                .filter((data) => Boolean(data))
                .map((data) => {
                    if (data) {
                        return fetchLoginAnnouncementsSuccess(data);
                    }
                    return fetchLoginAnnouncementsSuccess(DEFAULT_RESPONSE);
                })
                .catch((error: ErrorResponse) => {
                    return Observable.of(fetchLoginAnnouncementsFailure(error));
                });
        }

        return fetchModel(action.payload)
            .filter((response) => Boolean(response))
            .map((response) => {
                if (response.loginAnnouncements) {
                    return fetchLoginAnnouncementsSuccess(response.loginAnnouncements);
                }
                return fetchLoginAnnouncementsSuccess(DEFAULT_RESPONSE);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchLoginAnnouncementsFailure(error));
            });
    });
};
