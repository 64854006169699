import type { ActionsObservable } from 'redux-observable';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { uniqBy } from 'lodash';
import type { RequestTypePayload } from 'rest/request-types';
import { setRequestGroupsForRequestType } from 'rest/request-types';
import type { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import type {
    AddRequestTypeToRequestGroupAction,
    AddRequestTypeToRequestGroupFailedAction,
} from 'state/actions/add-request-type-to-group';
import {
    ADD_REQUEST_TYPE_TO_REQUEST_GROUP,
    addRequestTypeToRequestGroupFailedAction,
} from 'state/actions/add-request-type-to-group';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import type { ReqTypesState, ReqGroupsState } from 'state/persisted/portal';
import { getAllRequestGroupsWithRequestTypesForPortal } from 'state/selectors/portal-customisation';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { getFirstMessageFromJiraError } from '@atlassian/help-center-common-util/errors';

const getAssociatedGroups = (
    requestType: ReqTypesState,
    requestGroup: ReqGroupsState,
    requestGroups: ReqGroupsState[]
): ReqGroupsState[] => {
    const associatedRequestGroups = uniqBy(requestGroups, 'id').filter((reqGroup) =>
        reqGroup.reqTypes.includes(requestType.id)
    );
    // Add group association to request type if not already added
    if (!associatedRequestGroups.includes(requestGroup)) {
        associatedRequestGroups.push(requestGroup);
    }
    return associatedRequestGroups;
};

const getIconIdFromIconUrl = (iconUrl: string) => iconUrl.substring(iconUrl.lastIndexOf('=') + 1, iconUrl.length);

const getSanitizedText = (html: string) => {
    const stripHtmlTagsRegex = /(<([^>]+)>)/gi;
    return html.replace(stripHtmlTagsRegex, '');
};

const getSerializedRequestTypeWithRequestGroups = (
    requestType: ReqTypesState,
    requestGroup: ReqGroupsState,
    requestGroups: ReqGroupsState[]
): RequestTypePayload => ({
    id: requestType.id,
    name: requestType.name,
    // Can remove after https://jdog.jira-dev.com/browse/JDW-292
    icon: getIconIdFromIconUrl(requestType.iconUrl),
    iconUrl: requestType.iconUrl,
    description: getSanitizedText(requestType.descriptionHtml),
    groups: getAssociatedGroups(requestType, requestGroup, requestGroups),
});

export const addRequestTypeToRequestGroupEpic: Epic<
    AddRequestTypeToRequestGroupAction,
    FetchPortalAction | AddRequestTypeToRequestGroupFailedAction
> = (action$: ActionsObservable<AddRequestTypeToRequestGroupAction>, store) => {
    return action$
        .ofType(ADD_REQUEST_TYPE_TO_REQUEST_GROUP)
        .mergeMap(
            ({
                payload: {
                    isProjectSimplified,
                    portalId,
                    projectId,
                    requestType,
                    requestGroup,
                    analyticsEvent,
                    analyticsFailureEvent,
                },
            }: AddRequestTypeToRequestGroupAction) => {
                const state = store.getState();
                const requestGroups = getAllRequestGroupsWithRequestTypesForPortal(state, portalId) || [];

                return setRequestGroupsForRequestType({
                    isProjectSimplified,
                    projectId,
                    updatedRequestTypeGroupId: requestGroup.id,
                    requestGroupId: requestGroup.id,
                    requestType: getSerializedRequestTypeWithRequestGroups(requestType, requestGroup, requestGroups),
                })
                    .map(() => {
                        analyticsEvent && sendEvent(analyticsEvent);
                        return fetchPortalAction({
                            id: portalId,
                            expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'],
                        });
                    })
                    .catch((error: AjaxError) => {
                        if (analyticsFailureEvent) {
                            sendEvent(analyticsFailureEvent);
                        }
                        return Observable.of(
                            addRequestTypeToRequestGroupFailedAction(getFirstMessageFromJiraError(error.response))
                        );
                    });
            }
        );
};
