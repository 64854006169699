import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { isAdvancedCustomizationEnabled } from 'feature-flags';
import { di } from 'react-magnetic-di';
import { BannerWithLayoutHeader } from 'view/layout/banner-with-layout-header';
import type { PaperAppearance } from 'view/layout/paper';
import { AutoHeightBody } from 'view/layout/styled';
import { Content, PageLayout } from '@atlaskit/page-layout';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';
import { Flipper } from '@atlassian/help-center-common-component/flip-toolkit';
import { SidebarPusher } from '@atlassian/help-center-common-component/pushing-customize-sidebar';
import { isKoshEnabledForDefaultHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { AnnouncementBanner } from '../grid-announcement';
import { GridContainer } from '../grid-container';
interface PageLayoutContentWrapperProps {
    children: JSX.Element;
}

const PageLayoutContentWrapper: React.FC<PageLayoutContentWrapperProps> = ({ children }) => (
    <PageLayout testId="page-layout">
        <Content>{children}</Content>
    </PageLayout>
);

export interface Props {
    children: JSX.Element;
    isAnonymous?: boolean;
    removeHeader?: boolean;
    headerless?: boolean;
    hideSearchButton?: boolean;
    helpCenterBanner: string | undefined;
    isBrandingLoaded?: boolean;
    paperAppearance?: PaperAppearance;
    // Used as a key to determine whether or not we should add fade transitions between pages
    // we use the top level path from app.tsx to differentiate between different pages
    path: string | undefined;
    searchShown: boolean;
    fadeInSearchModal: boolean;
    hideBanner?: boolean;
    isCSM?: boolean;
    prefetchBrandingModel?: boolean;
}

/**
 * Paper in this context is the floating paper that is ontop of the banner.
 * Banner will have the background image chosen by the admin as well as the
 * theme color as background color.
 */
const BannerWithGridLayoutDumb = ({
    children,
    paperAppearance,
    isAnonymous,
    removeHeader,
    headerless,
    hideSearchButton,
    helpCenterBanner,
    searchShown,
    prefetchBrandingModel,
    hideBanner,
    isCSM,
}: Props) => {
    di(isKoshEnabledForDefaultHelpCenter, isAdvancedCustomizationEnabled);

    const showCompressedBanner = !['none', 'note'].includes(paperAppearance || 'normal');

    return (
        <PageLayoutContentWrapper>
            <SidebarPusherRoot>
                <FlipperWithFlexbox flipKey={searchShown} portalKey="search">
                    <Container>
                        {/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve */}
                        <BodyClassName className="px-paper-layout" />
                        <BannerWithLayoutHeader
                            isAnonymous={isAnonymous}
                            removeHeader={removeHeader}
                            headerless={headerless}
                            hideSearchButton={hideSearchButton}
                            helpCenterBanner={helpCenterBanner}
                            hideBanner={hideBanner}
                            prefetchBrandingModel={prefetchBrandingModel}
                            renderForPaperLayout={!showCompressedBanner}
                        />

                        <AnnouncementBanner appearance={paperAppearance} />

                        <GridContainer appearance={paperAppearance} isCSM={isCSM}>
                            {children}
                        </GridContainer>

                        <AutoHeightBody />
                    </Container>
                </FlipperWithFlexbox>
            </SidebarPusherRoot>
        </PageLayoutContentWrapper>
    );
};

export default BannerWithGridLayoutDumb;

const CONTAINER_MIN_HEIGHT = '100vh';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SidebarPusherRoot = styled(SidebarPusher)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${CONTAINER_MIN_HEIGHT};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FlipperWithFlexbox = styled(Flipper)`
    display: flex;
    flex-direction: column;
`;
