import { defineMessages } from 'react-intl';

export default defineMessages({
    categoryNotFound: {
        id: 'cv.error.page.category.page.category.not.found',
        defaultMessage: "That category doesn't exist.",
        description: 'The error message shown when the category not found on the category page.',
    },

    articleNotFound: {
        id: 'cv.error.page.category.page.article.not.found',
        defaultMessage: "That article doesn't exist.",
        description: 'The error message shown when the requested article was not found on the category page.',
    },

    noPermission: {
        id: 'cv.error.page.category.page.no-permission',
        defaultMessage: 'You do not have permission to view this category. Ask an admin to check your permissions.',
        description: 'The error shown when the user does not have permission to add categories to the project.',
    },

    callToActionText: {
        id: 'cv.error.page.category.page.call.to.action',
        defaultMessage: 'Go back',
        description: 'Call to action message shown when an error occurs on the category page',
    },
});
