import type { CategoriesResponse, Category } from 'rest/category';
import type { ApolloErrorResponse } from 'epics/model/types';
export const FETCH_CATEGORIES_SUCCESS = 'state.persisted.kb.categories.FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'state.persisted.kb.categories.FETCH_CATEGORIES_FAILURE';

export const NOT_SUPPORTED: { __NOT_SUPPORTED__: true } = { __NOT_SUPPORTED__: true };

export interface FetchCategoriesSuccess {
    type: typeof FETCH_CATEGORIES_SUCCESS;
    payload: {
        categories: Category[] | typeof NOT_SUPPORTED;
        portalId: number;
    };
}

export interface FetchCategoriesFailure {
    type: typeof FETCH_CATEGORIES_FAILURE;
    payload: {
        error: ApolloErrorResponse;
        portalId: number;
    };
}

export const fetchCategoriesSuccess: (response: CategoriesResponse, portalId: number) => FetchCategoriesSuccess = (
    response,
    portalId
) => {
    if (Array.isArray(response)) {
        return {
            type: FETCH_CATEGORIES_SUCCESS,
            payload: {
                portalId,
                categories: response,
            },
        };
    }
    const isCategoriesSupported = response.categoriesSupported;
    return {
        type: FETCH_CATEGORIES_SUCCESS,
        payload: {
            portalId,
            categories: isCategoriesSupported ? response.categories : NOT_SUPPORTED,
        },
    };
};

export const fetchCategoriesFailure: (error: ApolloErrorResponse, portalId: number) => FetchCategoriesFailure = (
    error,
    portalId
) => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: {
        error,
        portalId,
    },
});
