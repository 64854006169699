import TopicsEditQuery from 'topics/src/ui/help-edit-topics/topics/__generated__/topicsEditQuery.graphql';
import type { topicsEditQuery } from 'topics/src/ui/help-edit-topics/topics/__generated__/topicsEditQuery.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';

export const editTopicResource = createRelayResource<topicsEditQuery>({
    type: 'EDIT_TOPIC',
    getQuery: (_, { cloudId, helpCenterAri }) => ({
        parameters: TopicsEditQuery,
        variables: {
            cloudId,
            helpCenterAri,
        },
        options: {
            fetchPolicy: 'store-or-network',
        },
    }),
});
