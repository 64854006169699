import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { PortalSummary } from 'rest/featured-and-sorted-portals';
import { fetchFeaturedAndSortedPortalsModel } from 'state/actions/featured-and-sorted-portals';
import { getfeaturedAndSortedPortalsError, getAllVisiblePortals } from 'state/selectors/featured-and-sorted-portals';
import ServiceDeskFilter from './servicedesk-filter';

const selector = createSelector(
    getAllVisiblePortals,
    getfeaturedAndSortedPortalsError,
    (portals, featuredAndSortedPortalsError) => ({
        featuredAndSortedPortalsError,
        portals: portals as PortalSummary[],
    })
);

export default connect(selector, {
    fetchPortals: fetchFeaturedAndSortedPortalsModel,
})(ServiceDeskFilter);
