import type { AjaxResponse } from 'rxjs';
import { post } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const resetPassword = (emailAddress: string, password: string, token: string, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/pages/user/resetpassword`;

    const body = {
        emailAddress,
        password,
        token,
    };

    const headers = {
        'Content-Type': 'application/json',
    };

    return (
        post(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => Observable.throw(e))
    );
};
