import type { OrganisationsResponse } from 'rest/organisations';

export const UPDATE_ORGANISATIONS_MODEL = 'state.persisted.organisations.UPDATE_ORGANISATIONS_MODEL';

export interface UpdateOrganisationsModelAction {
    payload: OrganisationsResponse;
    type: typeof UPDATE_ORGANISATIONS_MODEL;
}

export const updateOrganisationsModelAction = (
    organisations: OrganisationsResponse
): UpdateOrganisationsModelAction => ({
    payload: organisations,
    type: UPDATE_ORGANISATIONS_MODEL,
});
