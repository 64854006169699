import { defineMessages } from 'react-intl-next';

export default defineMessages({
    noSearchResultTitle: {
        defaultMessage: 'We couldn\u2019t find a match for your search',
        description: 'Text to tell the user that there was no search results found after they have conducted a search',
        id: 'cv.search.none.no-search-result-title',
    },
    noSearchResultText: {
        defaultMessage:
            'We couldn\u2019t find any matches for \u2018{ term }\u2019. Check your search for typos, or try another search term.',
        description: 'Title text to tell the user how to proceed after finding no search results',
        id: 'cv.search.none.no-search-result-text',
    },
    noSearchResultBody: {
        defaultMessage: 'Check your search for typos, or try another search term.',
        description: 'Body text to tell the user how to proceed after finding no search results',
        id: 'cv.search.none.no-search-result-body',
    },
    noSearchQueryTitle: {
        defaultMessage: 'Looking for something?',
        description: 'Title text to tell the user how to proceed when search query in search bar is empty',
        id: 'cv.search.none.no-search-query-title',
    },
    noSearchQueryBody: {
        defaultMessage: 'Enter your search term above to get started.',
        description: 'Body text to tell the user how to proceed when search query in search bar is empty',
        id: 'cv.search.none.no-search-query-body',
    },
});
