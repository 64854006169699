import * as React from 'react';
import type { InferableComponentEnhancerWithProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isAtlassianAccountManaged } from 'state/selectors/user';
import { Redirect } from '@atlassian/help-center-common-util/redirect/redirect';

const selector = createSelector(isAtlassianAccountManaged, (isAtlassianAccount) => ({
    isAtlassianAccount,
}));

type ConnectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;

const connector = connect(selector, null);

const withOnlyAtlassianAccount =
    () =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const ReturnComponent: React.FC<TProps & ConnectedProps<typeof connector>> = (props) => {
            const { isAtlassianAccount } = props;

            if (isAtlassianAccount) {
                //@ts-ignore TypeScript upgrade 4.7.4
                return <WrappedComponent {...props} />;
            }
            return <Redirect to="/user/forbidden" />;
        };
        const ConnectedComponent = connector(ReturnComponent);
        ConnectedComponent.displayName = 'WithOnlyAtlassianAccount';

        return ConnectedComponent;
    };

export default withOnlyAtlassianAccount;
