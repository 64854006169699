import { di } from 'react-magnetic-di';
import { graphql, useClientQuery } from 'react-relay';
import type { omnichannelMetaQuery } from './__generated__/omnichannelMetaQuery.graphql';

export const useOmnichannelMetaQuery = () => {
    di(useClientQuery);
    const data = useClientQuery<omnichannelMetaQuery>(
        graphql`
            query omnichannelMetaQuery {
                jsmChatConversationMeta {
                    conversationId
                    conversationContextAri
                    workspaceAri
                    connectionId
                    isReplyPending
                    isConversationExpired
                    aiAnswerStatus
                    isConversationClosed
                    draftMessage
                    disableInput
                    subscriptionId
                }
            }
        `,
        {}
    );
    return {
        conversationId: data?.jsmChatConversationMeta?.conversationId,
        conversationContextAri: data?.jsmChatConversationMeta?.conversationContextAri,
        workspaceAri: data?.jsmChatConversationMeta?.workspaceAri,
        connectionId: data?.jsmChatConversationMeta?.connectionId,
        isReplyPending: !!data?.jsmChatConversationMeta?.isReplyPending,
        isConversationExpired: !!data?.jsmChatConversationMeta?.isConversationExpired,
        aiAnswerStatus: data?.jsmChatConversationMeta?.aiAnswerStatus,
        isConversationClosed: !!data?.jsmChatConversationMeta?.isConversationClosed,
        draftMessage: data?.jsmChatConversationMeta?.draftMessage,
        disableInput: !!data?.jsmChatConversationMeta?.disableInput,
        subscriptionId: data?.jsmChatConversationMeta?.subscriptionId,
    };
};
