import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import messages from './messages';

export const HelpCentersManagementPage = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: 'help-centers-management-page' */ './help-centers-management-page')
    ),
});

export { HelpCentersManagementResource } from './resources';
export const callToActionUrlText = messages.callToActionUrlText;
export const authPageMessage = messages.authPageMessage;
