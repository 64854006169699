import { getHelpCenterAri, getMeta } from '@helpCenter/util/meta';
import { isUnifiedHelpAddFilterForCustomerAccounts } from 'feature-flags';
import { ExperienceType } from '@atlassian/conversation-assistant';
import type { UHStreamMessage } from '@atlassian/conversation-assistant/dist/types/services/unified-help/types';
import { getSlugForHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center/advanced-help-center';
import { getAnalyticsWebClient } from '../utils/analytics';
import { getProductIdentifierAttributes } from '../utils/analytics/get-product-identifier-attributes';
import { fetchJson } from './fetch-json';
import { fetchStream } from './fetch-stream';

const commonHeaders = {
    'X-Experience-Id': 'unified-help',
    'X-Product': 'jsm',
};

const helpcenterSlugHeader = {
    'X-Helpcenter-Slug': getSlugForHelpCenter() ?? '',
};

const getChannelIdPath = (): string => {
    return getMeta('ajs-account-type') === 'customer' && isUnifiedHelpAddFilterForCustomerAccounts()
        ? `/gateway/api/assist/ca/chat/v1/channel`
        : `/gateway/api/assist/chat/v1/channel`;
};

const getMessageStreamPath = (channelId: string): string => {
    return getMeta('ajs-account-type') === 'customer' && isUnifiedHelpAddFilterForCustomerAccounts()
        ? `/gateway/api/assist/ca/chat/v1/channel/${channelId}/message/stream`
        : `/gateway/api/assist/chat/v1/channel/${channelId}/message/stream`;
};

export const getChannelId = async (): Promise<string | null> => {
    const analyticsClient = getAnalyticsWebClient();
    let channelId: string | null = null;

    try {
        const channelResponse = await fetchJson<{ id: string }>(getChannelIdPath(), 'POST', {
            body: JSON.stringify({}),
            headers: commonHeaders,
        });

        channelId = channelResponse.id;

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        analyticsClient.sendTrackEvent({
            source: 'uhAiMateRequest',
            action: 'fetched',
            actionSubject: 'channelId',
            actionSubjectId: channelId,
            attributes: {
                ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
            },
        });
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        analyticsClient.sendTrackEvent({
            source: 'uhAiMateRequest',
            action: 'fetch_failed',
            actionSubject: 'channelId',
            attributes: {
                ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
            },
        });
        channelId = null;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendTrackEvent({
        source: 'portalHomeScreen',
        action: 'initiated',
        actionSubject: 'aiInteraction',
        actionSubjectId: channelId ?? '',
        attributes: {
            aiFeatureName: 'aiAnswer',
            aiInteractionID: channelId,
            aiInteractionType: 'conversationId',
            proactiveAIGenerated: 0,
            userGeneratedAI: 1,
        },
    });

    return channelId;
};

export const sendMessageStream = async (
    content: string,
    channelId: string,
    abortController: AbortController
): Promise<AsyncGenerator<UHStreamMessage>> => {
    const body = {
        content,
        recipient_agent_named_id: 'unified_help_agent',
    };
    const options: RequestInit = {
        method: 'POST',
        headers: {
            ...commonHeaders,
            'content-type': 'application/json;charset=UTF-8',
            ...helpcenterSlugHeader,
            'X-Helpcenter-Ari': getHelpCenterAri(),
        },
        body: JSON.stringify(body),
        signal: abortController.signal,
    };

    return fetchStream<UHStreamMessage>(getMessageStreamPath(channelId), options);
};
