import { defineMessages } from 'react-intl-next';

export default defineMessages({
    selectedProjectsText: {
        defaultMessage: '{count, plural, one {1 selected} other { # selected}}',
        description: 'Text for displaying selected projects count',
        id: 'projects-list.bulk-action-toolbar.selected-spaces-text',
    },
    toolbarAccessibilityLabel: {
        defaultMessage: 'Actions for selected items are available before the table.',
        description: 'Accessibility label for the toolbar',
        id: 'projects-list.bulk-action-toolbar.toolbar-accessibility-label',
    },
});
