import type { AjaxError } from 'rxjs';

export const FETCH_RESEND_VERIFY_EMAIL = 'epic.verify-email.FETCH_RESEND_VERIFY_EMAIL';
export const FETCH_RESEND_VERIFICATION_FAILURE = 'state.ui.resendVerifyEmail.FAILED';
export const FETCH_RESEND_VERIFICATION_SUCCESS = 'state.ui.resendVerifyEmail.SUCCEEDED';

interface FetchResendVerifyEmailParams {
    emailAddress: string;
    portalId: number | null;
}

export interface FetchResendVerifyEmailAction {
    type: typeof FETCH_RESEND_VERIFY_EMAIL;
    payload: {
        params: FetchResendVerifyEmailParams;
    };
}

export const fetchResendVerifyEmailAction = ({
    emailAddress,
    portalId,
}: FetchResendVerifyEmailParams): FetchResendVerifyEmailAction => {
    return {
        type: FETCH_RESEND_VERIFY_EMAIL,
        payload: {
            params: {
                emailAddress,
                portalId,
            },
        },
    };
};

export interface FetchResendVerificationSuccess {
    type: typeof FETCH_RESEND_VERIFICATION_SUCCESS;
}

export const fetchResendVerificationSuccess = (): FetchResendVerificationSuccess => ({
    type: FETCH_RESEND_VERIFICATION_SUCCESS,
});

export interface FetchResendVerificationFailure {
    type: typeof FETCH_RESEND_VERIFICATION_FAILURE;
    payload: {
        error: AjaxError;
    };
}

export const fetchResendVerificationFailure = (error: AjaxError): FetchResendVerificationFailure => ({
    type: FETCH_RESEND_VERIFICATION_FAILURE,
    payload: {
        error,
    },
});
