import type { ComponentType } from 'react';
import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import { withAsync } from 'view/common/with-async';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { AdminManagementLayout } from '../admin-management-layout';
import { managePagesExperience } from './experiences';
import { ManagePagesLayoutSkeleton } from './manage-pages-layout-skeleton';

const AsyncHelpCenterManagePagesComponent = withAsync({
    loader: lazyForPaint(() => import(/* webpackChunkName: "manage-pages" */ './manage-pages')),
    LoadingSkeleton: ManagePagesLayoutSkeleton,
});

export const HelpCenterManagePages: ComponentType = () => {
    di(AdminManagementLayout);
    useUFOComponentExperience(managePagesExperience);

    return (
        <AdminManagementLayout featureName="Helpcenter manage pages">
            <AsyncHelpCenterManagePagesComponent />
        </AdminManagementLayout>
    );
};
