import { startsWith } from 'lodash';

export const reload = () => window.location.reload();

export const replace = (path: string) => window.location.replace(path);

export const pathname = () => window.location.pathname;

export const location = () => window.location;

export const href = () => window.location.href;

export const hash = () => window.location.hash;

export const origin = () => window.location.origin;

export const search = () => window.location.search;

export const open = (url: string, target: string) => window.open(url, target);

export const assign = (url: string) => window.location.assign(url);

/* checks wether a string contains something that could be a URL. Valid URLs start with
 * - http (also covers https)
 * - / (for relative URLs)
 * - %2F URL encoding for /
 * - %2f URL encoding for /
 */
export const isValidLocation = (maybeUrl: string) =>
    startsWith(maybeUrl, 'http') ||
    startsWith(maybeUrl, '/') ||
    startsWith(maybeUrl, '%2F') ||
    startsWith(maybeUrl, '%2f');
