// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import { token } from '@atlaskit/tokens';

export const TRANSFORM_OPEN_TRANSITION_MS = 300;
export const BANNER_HEIGHT = grid.multiple(44);
export const HEADER_HEIGHT = grid.unitless * 7;

export const MAIN_CONTAINER_MAX_WIDTH = 85;

// search-bar
export const SEARCH_INPUT_FONT_SIZE = fonts.mediumLarge;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    margin-top: ${token('space.negative.100', '-8px')};
    margin-right: ${token('space.negative.200', '-16px')};
    > * {
        flex-basis: 0;
        margin-right: ${token('space.200', '16px')};
        margin-top: ${token('space.100', '8px')};
        min-width: ${grid.multiple(27.5).px};
        flex-grow: 1;
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const FormErrorContainer = styled.div`
    margin-top: ${token('space.300', '24px')};
`;
