// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as bp from 'view/styles/breakpoints';
import * as mixins from 'view/styles/mixins';
import { sizes } from 'view/styles/sizes-viewport';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const HelpCenterContainer = styled.div`
    ${mixins.borderBox};
    width: 100%;
    margin: 0 auto;
    /* We deliberately use padding-right/left so we can override top/bottom easily. */
    padding-left: ${token('space.400', '32px')};
    padding-right: ${token('space.400', '32px')};

    ${bp.fromSmall.css`
        max-width: ${sizes.small}px;
        /* We deliberately use padding-right/left so we can override top/bottom easily. */
        padding-right: ${token('space.600', '48px')};
        padding-left: ${token('space.600', '48px')};
    `};
`;
