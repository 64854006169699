import type { State } from 'state';

export const isSinglePortalContext = (state: State) =>
    state.ui.context.portalId !== undefined && state.ui.context.portalId !== null && !isNaN(state.ui.context.portalId);

export const getPathname = (state: State) => state.ui.context.pathname;
export const getRoutename = (state: State) => state.ui.context.routeName;

/**
 * Returns either the id of the current portal in context or undefined if there are no portal in context
 */
export const getPortalId = (state: State) => {
    if (!isSinglePortalContext(state)) {
        return undefined;
    }

    return state.ui.context.portalId;
};
