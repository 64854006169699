import { di } from 'react-magnetic-di';
import { ConversationProvider } from '../../../common/types';
import { useIsLastAssistantMessageGenerated } from '../../../store/converse';
import { useProvider } from '../../../store/routing';

export const useIsGenerating = () => {
    di(useProvider, useIsLastAssistantMessageGenerated);

    const provider = useProvider();
    const [isLastAssistantMessageGenerated] = useIsLastAssistantMessageGenerated();

    if (!provider || provider === ConversationProvider.VIRTUAL_AGENT) {
        return false;
    }

    return !isLastAssistantMessageGenerated;
};
