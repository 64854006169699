import type { TimezoneRegionResponse, TimezoneResponse } from 'rest/models';

import type { FetchTimezonesSuccess, FetchTimezoneRegionsSuccess } from 'state/actions/timezones';
import { FETCH_TIMEZONES_SUCCESS, FETCH_TIMEZONE_REGIONS_SUCCESS } from 'state/actions/timezones';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface TimezonesState {
    timezoneRegions: TimezoneRegionResponse[];
    timezones: TimezoneResponse[];
}

export const getInitialTimezoneState = (): TimezonesState => {
    const initialModelState = initialModel();
    if (initialModelState) {
        return {
            timezoneRegions: initialModelState.timezoneRegions || [],
            timezones: initialModelState.timezones || [],
        };
    }
    return {
        timezoneRegions: [],
        timezones: [],
    };
};

export type HandledActions = FetchTimezonesSuccess | FetchTimezoneRegionsSuccess;

const defaultState: TimezonesState = getInitialTimezoneState();

export function timezonesReducer(state: TimezonesState = defaultState, action: HandledActions): TimezonesState {
    switch (action.type) {
        case FETCH_TIMEZONES_SUCCESS:
            return handleFetchTimezonesSuccess(state, action);
        case FETCH_TIMEZONE_REGIONS_SUCCESS:
            return handleFetchTimezoneRegionsSuccess(state, action);
        default:
            return state;
    }
}

const handleFetchTimezonesSuccess = (state: TimezonesState, action: FetchTimezonesSuccess): TimezonesState => ({
    ...state,
    timezones: action.payload.timezones,
});

const handleFetchTimezoneRegionsSuccess = (
    state: TimezonesState,
    action: FetchTimezoneRegionsSuccess
): TimezonesState => ({
    ...state,
    timezoneRegions: action.payload.timezoneRegions,
});
