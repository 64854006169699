import type { AjaxResponse, AjaxError } from 'rxjs';
import { Observable } from 'rxjs';
import { post } from 'epics/ajax';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface LogoutResponse {
    logoutRedirectUrl?: string | null;
}

const createOperationalPayload = (actionSubject: 'identity' | 'jira', attributes: Record<string, string | boolean>) => {
    return {
        attributes,
        actionSubject,
        action: 'logout',
        source: 'unknownSource',
    };
};

export const logoutUsingIdentity = (
    redirectUrl: string,
    context: string = contextPath
): Observable<LogoutResponse | null> => {
    const uri = `${context}/jsd-login/v1/authentication/logout?continue=${redirectUrl}`;

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(uri)
            .do(
                (ajaxResponse: AjaxResponse) => {
                    operationalEvent(
                        createOperationalPayload('identity', {
                            success: true,
                            status: ajaxResponse.status.toString(),
                        })
                    );
                },
                (ajaxError: AjaxError) => {
                    operationalEvent(
                        createOperationalPayload('identity', {
                            success: false,
                            status: ajaxError.status.toString(),

                            // Suppressing existing violation. Please fix this.
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            errorKey: ajaxError.response?.key,
                        })
                    );
                    trackError('session.logout.failed', {}, ajaxError);
                }
            )
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};
