import type { StoreActionApi } from 'react-sweet-state';
import { ConversationProvider } from '../../common/types';
import type { State } from './types';

export const setProvider =
    (provider: ConversationProvider | undefined) =>
    ({ setState }: StoreActionApi<State>) =>
        setState({ provider });

export const resetState =
    () =>
    ({ setState }: StoreActionApi<State>, { contextId }: { contextId: string }) => {
        setState({
            contextId,
            provider: ConversationProvider.VIRTUAL_AGENT,
            isRouting: false,
        });
    };
