import type { HTMLElement } from 'node-html-parser/dist';

interface Istore {
    dataReference: HTMLElement | Document | null;
    elements: {
        [key: string]: {
            classList: string[];
        };
    };
}

/**
 * Interface for the ClientServerBridge class
 * All getters read from the store in case of Server and from the DOM in case of client.
 */
interface IClientServerBridge {
    /**
     * Stores the data needed required for supporting various APIs
     */
    store: Istore;
    /**
     *
     * @param parsedHTML - HTMLElement received from Jira request to tesseract
     * Server: Sets the dataReference to the intial HTML received from Jira request to tesseract HTMLElement
     * Client: Sets the dataReference to document
     *
     * It will be called only in entry script of SnapVM runtime
     */
    startup(parsedHTML: HTMLElement): void;

    /**
     * Returns the element with the given ID.
     */
    getElementById(elementId: string): HTMLElement | Element | null;
    /**
     * Returns the element with the given selector.
     */
    querySelector(selector: string): HTMLElement | Element | null;
    /**
     * Returns all elements that match the given selector.
     */
    querySelectorAll(selector: string): HTMLElement[] | NodeListOf<Element>;
    /**
     * Returns the element with the given tag name.
     */
    getElementsByTagName(tagName: string): HTMLCollectionOf<Element> | HTMLElement[];
    /**
     * To add class names to the element with the given name.
     * CLIENT: Gets added to the DOM directly
     * SERVER: Gets added to the store
     */
    addClassName(elementName: string, className: string): void;
    /**
     * Returns the store object
     */
    getStore(): Istore;
}

/**
 * This class is used to abstract the differences between the client and server environments.
 */
export class ClientServerBridge implements IClientServerBridge {
    isSnapvmRuntime: boolean;
    store: Istore = {
        dataReference: null,
        elements: {},
    };

    constructor() {
        this.isSnapvmRuntime = typeof window === 'undefined';
        this.store.dataReference = this.isSnapvmRuntime ? null : document; // Initialize to document when not in SnapVM runtime
    }

    startup(parsedHTML: HTMLElement): void {
        this.store.dataReference = parsedHTML;
    }

    getElementById(elementId: string): HTMLElement | Element | null {
        if (this.store.dataReference) {
            return this.store.dataReference.getElementById(elementId);
        }
        // eslint-disable-next-line no-console
        console.warn('dataReference is null in getElementById');
        return null;
    }

    querySelector(selector: string): HTMLElement | Element | null {
        if (this.store.dataReference) {
            return this.store.dataReference.querySelector(selector);
        }
        // eslint-disable-next-line no-console
        console.warn('dataReference is null in querySelector');
        return null;
    }

    querySelectorAll(selector: string): HTMLElement[] | NodeListOf<Element> {
        if (this.store.dataReference) {
            return this.store.dataReference.querySelectorAll(selector);
        }
        // eslint-disable-next-line no-console
        console.warn('dataReference is null in querySelectorAll');
        return [];
    }

    getElementsByTagName(tagName: string): HTMLCollectionOf<Element> | HTMLElement[] {
        if (this.store.dataReference) {
            return this.store.dataReference.getElementsByTagName(tagName);
        }
        // eslint-disable-next-line no-console
        console.warn('dataReference is null in getElementsByTagName');
        return [];
    }

    addClassName(elementName: string, className: string): void {
        if (this.isSnapvmRuntime) {
            if (!this.store.elements[elementName]) {
                this.store.elements[elementName] = {
                    classList: [],
                };
            }
            this.store.elements[elementName].classList.push(className);
            return;
        }

        if (elementName === 'body') {
            document.body.classList.add(className);
            return;
        }
        // eslint-disable-next-line no-console
        console.warn('invalid elementName sent to addClassName. elementName: ', elementName);
    }

    getStore() {
        return this.store;
    }
}

const clientServerBridge = new ClientServerBridge();
export default clientServerBridge;
