import React from 'react';
import { compose } from 'redux';
import { useIntl } from 'react-intl-next';
import { lazyForPaint } from 'react-loosely-lazy';
import { SplashLayoutRoute } from 'view/layout';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { withOnlyJiraAdmin } from '@atlassian/help-center-common-component/with-only-jira-admin';
import { withOnlyLoggedIn } from '@atlassian/help-center-common-component/with-only-logged-in';
import messages from './messages';
import { PortalReorderExperienceLoader } from './portal-reorder-experience-loader';

export const PortalReorderExperience = withAsync({
    loader: lazyForPaint(() => import(/* webpackChunkName: "portal-reorder-experience" */ './hocs')),
    LoadingSkeleton: PortalReorderExperienceLoader,
    loadingPosition: 'fill-container',
});

const PORTAL_REORDERING_PATH = '/settings/portal-reorder';
export const PortalReorderingRoute = () => {
    const { formatMessage } = useIntl();
    const WrappedComponent = compose(
        withOnlyLoggedIn({
            redirectUrl: PORTAL_REORDERING_PATH,
        }),
        withOnlyJiraAdmin({
            message: formatMessage(messages.authPageMessage),
            callToActionText: formatMessage(messages.callToActionUrlText),
            callToActionUrl: '/portals',
        })
    )(() => (
        <SplashLayoutRoute
            path={PORTAL_REORDERING_PATH}
            component={PortalReorderExperience}
            screenName={ScreenName.PORTAL_REORDER_SETTINGS}
        />
    ));
    return <WrappedComponent />;
};

PortalReorderingRoute.displayName = 'PortalReorderingRoute';

export const callToActionUrlText = messages.callToActionUrlText;
export const authPageMessage = messages.authPageMessage;
