import type { PortalSearchResponse, RequestTypeSearchResponse } from 'rest/search';
import type { KnowledgeBaseSearchResult as FormattedKnowledgeBaseSearchResult } from 'state/ui/search';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
export const SHOW_SEARCH = 'ui.search.SHOW_SEARCH';
export const SEARCH = 'ui.search.SEARCH';
export const RESET_SEARCH = 'ui.search.RESET_SEARCH';
export const FETCH_KB_SEARCH_RESULTS = 'epic.search.FETCH_KB_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS = 'epic.search.FETCH_SEARCH_RESULTS';
export const UPDATE_SEARCH_RESULTS = 'ui.search.UPDATE_SEARCH_RESULTS';
export const UPDATE_SEARCH_RESULTS_BY_TYPE = 'ui.search.UPDATE_SEARCH_RESULTS_BY_TYPE';
export const CLEAR_SEARCH_RESULTS = 'ui.search.CLEAR_SEARCH_RESULTS';
export const UPDATE_SEARCH = 'ui.search.UPDATE_SEARCH';

export interface SearchAnalyticsMetaData {
    kb?: UIAnalyticsEvent;
    portal?: UIAnalyticsEvent;
    requestType?: UIAnalyticsEvent;
}

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: SearchAnalyticsMetaData;
    analyticsFailureEvent?: SearchAnalyticsMetaData;
}

export interface FetchSearchResultsAction {
    type: typeof FETCH_SEARCH_RESULTS;
    payload: {
        term: string;
        searchStartTime?: number;
        meta?: AnalyticsMetaData;
    };
}

export const fetchSearchResultsAction = (
    term: string,
    searchStartTime: number,
    meta?: AnalyticsMetaData
): FetchSearchResultsAction => ({
    payload: {
        term,
        searchStartTime,
        meta,
    },
    type: FETCH_SEARCH_RESULTS,
});

export interface ShowSearchAction {
    type: typeof SHOW_SEARCH;
    payload: {
        show: boolean;
        context?: string;
    };
}

export interface SearchAction {
    type: typeof SEARCH;
    payload: {
        term: string;
        time: number | null;
        context?: string;
        show: boolean;
        meta?: AnalyticsMetaData;
    };
}

interface ShowSearchOptions {
    context?: string;
}

export const showSearch = (show: boolean, options: ShowSearchOptions = {}): ShowSearchAction => ({
    payload: { show, ...options },
    type: SHOW_SEARCH,
});

interface SearchOptions {
    time?: number | null;
    context?: string;
    show?: boolean;
}

export interface ResetSearchAction {
    type: typeof RESET_SEARCH;
}

export const resetSearch = (): ResetSearchAction => ({
    type: RESET_SEARCH,
});

export const search = (
    term: string,
    { time = Date.now(), show = true, ...options }: SearchOptions = {},
    meta?: AnalyticsMetaData
): SearchAction => ({
    type: SEARCH,
    payload: {
        term,
        time,
        show,
        ...options,
        meta,
    },
});

export interface FetchKbSearchAction {
    type: typeof FETCH_KB_SEARCH_RESULTS;
    payload: {
        term: string;
        limit: number;
        expandPortal: boolean;
        categoryIds?: string[];
        portalIds?: string[];
        meta?: AnalyticsMetaData;
    };
}

export const fetchKbSearchAction = ({
    term,
    limit,
    expandPortal,
    meta,
    categoryIds,
    portalIds,
}: {
    term: string;
    limit: number;
    expandPortal: boolean;
    categoryIds?: string[];
    portalIds?: string[];
    meta?: AnalyticsMetaData;
}): FetchKbSearchAction => {
    return {
        type: FETCH_KB_SEARCH_RESULTS,
        payload: {
            term,
            limit,
            expandPortal,
            categoryIds,
            portalIds,
            meta,
        },
    };
};

export interface SearchResultResponse {
    portals?: PortalSearchResponse[];
    requestTypes?: RequestTypeSearchResponse[];
    kbs?: FormattedKnowledgeBaseSearchResult;
}

export interface UpdateSearchResultsAction {
    type: typeof UPDATE_SEARCH_RESULTS;
    payload: SearchResultResponse;
}

//export type SearchType = 'portals' | 'requestTypes' | 'kbs';
const searchTypes = ['portals', 'requestTypes', 'kbs'] as const;
export type SearchType = (typeof searchTypes)[number];
export interface UpdateSearchResultsByTypeAction {
    type: typeof UPDATE_SEARCH_RESULTS_BY_TYPE;
    payload: SearchResultResponse;
    searchType: SearchType;
}

export const updateSearchResultsByType = (
    searchResult: SearchResultResponse,
    searchType: SearchType
): UpdateSearchResultsByTypeAction => ({
    searchType,
    type: UPDATE_SEARCH_RESULTS_BY_TYPE,
    payload: searchResult,
});

export interface ClearSearchResultsAction {
    type: typeof CLEAR_SEARCH_RESULTS;
}

export const clearSearchResults = (): ClearSearchResultsAction => ({
    type: CLEAR_SEARCH_RESULTS,
});

export interface UpdateSearchAction {
    type: typeof UPDATE_SEARCH;
    payload: {
        term: string;
        time: number | null;
        context?: string;
        show: boolean;
    };
}

export const updateSearch = (
    term: string,
    { time = Date.now(), show = true, ...options }: SearchOptions = {}
): UpdateSearchAction => ({
    type: UPDATE_SEARCH,
    payload: {
        term,
        time,
        show,
        ...options,
    },
});
