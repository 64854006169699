import * as qs from 'query-string';
import { createObjectURLFromResponse } from '@atlassian/help-center-common-util/blob';

const PARAM_KEY_TOKEN = 'token';
const PARAM_KEY_MAX_AGE = 'max-age';
const PARAM_KEY_HEIGHT = 'height';

const DEFAULT_PARAM_VALUE_MAX_AGE = '31536000'; // maximum value for max-age supported by media services
const HEIGHT_MAX_PARAM_VALUE = '4096'; // maximum value for height supported by media services

const MAX_RETRIES = 5;
const MS_BETWEEN_TRIES = 500;
const USE_ASYNC = true;

// A local cache for the blob URLs to avoid polluting blob storage
const BLOB_CACHE = {};

/**
 * Downloads the contents of a media services URL and returns a blob stored object URL for use.
 *
 * This is used when caching is needed on a media services URL, since the token inside the URL changes
 * too frequently causing the browser to treat it as a new resource each time.
 *
 * This utility downloads the image with the token in the authorisation header, so the browser
 * caches the resources properly.
 *
 * By default this caches the URL aggressively with a max age of 31536000 (1 year).
 *
 * If the image is not available in media services (the call responds with a 404),
 * then the utiliy will retry 4 more times to download it.
 *
 * Between each try the utility will wait for 500ms.
 */
export const getObjectURLFromMediaURLWithCaching = (completeUrl: string, msBetweenTries: number = MS_BETWEEN_TRIES) => {
    return internalGetObjectURLFromMediaURLWithCaching(completeUrl, msBetweenTries);
};

const internalGetObjectURLFromMediaURLWithCaching = (
    completeUrl: string,
    msBetweenTries: number = MS_BETWEEN_TRIES,
    tryNumber: number = 1
): Promise<string> => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { url, token } = parseUrl(completeUrl);

    // if we already created a blob url for this url, just finish.
    // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (BLOB_CACHE[url]) {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Promise.resolve(BLOB_CACHE[url]);
    }

    const req = new XMLHttpRequest();
    req.open('GET', url, USE_ASYNC);
    req.responseType = 'arraybuffer';
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    token && req.setRequestHeader('Authorization', `Bearer ${token}`);

    return new Promise<string>((resolve, reject) => {
        req.onload = () => {
            if (req.status === 200) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const blobUrl = createObjectURLFromResponse(req.response, req.getResponseHeader('content-type')!);
                // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                BLOB_CACHE[url] = blobUrl;
                resolve(blobUrl);
            } else {
                reject(req.statusText);
            }
        };

        req.onerror = () => {
            if (req.status === 404 && tryNumber < MAX_RETRIES) {
                wait(msBetweenTries)
                    .then(() => internalGetObjectURLFromMediaURLWithCaching(completeUrl, msBetweenTries, tryNumber + 1))
                    .then(resolve, reject);
            } else {
                reject(req.statusText);
            }
        };

        req.send();
    });
};

const parseUrl = (completeUrl: string) => {
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { url, query } = qs.parseUrl(completeUrl);

    // parse the url and token

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const mediaApiJwt = query[PARAM_KEY_TOKEN];

    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    delete query[PARAM_KEY_TOKEN];

    // prepare the query string
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    query[PARAM_KEY_MAX_AGE] = DEFAULT_PARAM_VALUE_MAX_AGE;

    // ensure no cropping/fitting/scaling of the image until we release the cropper
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    query[PARAM_KEY_HEIGHT] = HEIGHT_MAX_PARAM_VALUE;

    const parsedUrl = `${url}?${qs.stringify(query)}`;

    return {
        url: parsedUrl,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        token: mediaApiJwt,
    };
};

// visible for testing
export const clearCache = () => {
    Object.keys(BLOB_CACHE).forEach((cachedItem) => {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete BLOB_CACHE[cachedItem];
    });
};

const wait = (msBetweenTries: number) => new Promise((r) => setTimeout(r, msBetweenTries));
