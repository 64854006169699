import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const addHelpCenterExperienceId = 'service-management.help-center.add';
export const updateHelpCenterExperienceId = 'service-management.help-center.update';

export const updateHelpCenterExperience = new UFOExperience(updateHelpCenterExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const addHelpCenterExperience = new UFOExperience(addHelpCenterExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
