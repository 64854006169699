import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { Route } from 'react-resource-router';
import { withRouter } from 'react-resource-router';

import { getHeadless } from 'state/selectors/env';
import { BannerWithPaperLayoutRoute } from 'view/layout/banner-with-paper-layout';
import type { Props as BannerWithPaperLayoutRouteDumbProps } from 'view/layout/banner-with-paper-layout-dumb';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';

interface RawRoute extends Omit<Route, 'component'> {
    component: React.ComponentType;
}

type LayoutProps = Omit<
    BannerWithPaperLayoutRouteDumbProps,
    'children' | 'path' | 'headerless' | 'helpCenterBanner' | 'searchShown'
>;

const selector = createSelector(getHeadless, (headerless) => ({
    headerless,
}));

export const createRouteWithLayout = (
    route: RawRoute,
    screenName: ScreenName | undefined,
    layoutProps: LayoutProps
): Route => {
    const { path, component } = route;

    const LayoutRouteComponent = (props: { headerless: boolean }) => {
        return (
            <BannerWithPaperLayoutRoute
                path={path}
                component={component}
                layoutProps={{
                    headerless: props.headerless,
                    ...layoutProps,
                }}
                screenName={screenName}
            />
        );
    };
    return {
        ...route,
        component: withRouter(connect(selector)(LayoutRouteComponent)),
    };
};
