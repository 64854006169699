/**
 * @generated SignedSource<<762b42f3490374656d56e2bee709a301>>
 * @relayHash d9e8688fd258712e7252323bfaac25bd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4022d9416d0f15d10e96cf464eb40e25456a447dd4ffcf15934193900d7b3bf6

import type { ConcreteRequest, Query } from 'relay-runtime';
export type helpCenterBrandingQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type helpCenterBrandingQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly announcements: {
        readonly canEditHomePageAnnouncement: boolean | null | undefined;
        readonly homePageAnnouncements: ReadonlyArray<{
          readonly description: string | null | undefined;
          readonly descriptionTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
          readonly name: string | null | undefined;
          readonly nameTranslationsRaw: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          }> | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly helpCenterBranding: {
        readonly banner: {
          readonly fileId: string | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly colors: {
          readonly bannerTextColor: string | null | undefined;
          readonly primary: string | null | undefined;
          readonly topBarColor: string | null | undefined;
          readonly topBarTextColor: string | null | undefined;
        } | null | undefined;
        readonly hasTopBarBeenSplit: boolean | null | undefined;
        readonly homePageTitle: {
          readonly default: string;
          readonly translations: ReadonlyArray<{
            readonly locale: string;
            readonly localeDisplayName: string;
            readonly value: string;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly isBannerAvailable: boolean | null | undefined;
        readonly isLogoAvailable: boolean | null | undefined;
        readonly logo: {
          readonly fileId: string | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
        readonly useDefaultBanner: boolean | null | undefined;
      };
      readonly name: {
        readonly default: string;
        readonly translations: ReadonlyArray<{
          readonly locale: string;
          readonly localeDisplayName: string;
          readonly value: string;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly siteDefaultLanguageTag: string | null | undefined;
      readonly userLanguageTag: string | null | undefined;
    } | {
      readonly __typename: "QueryError";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
};
export type helpCenterBrandingQuery = {
  response: helpCenterBrandingQuery$data;
  variables: helpCenterBrandingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = [
  {
    "kind": "ScalarField",
    "name": "locale"
  },
  {
    "kind": "ScalarField",
    "name": "localeDisplayName"
  },
  {
    "kind": "ScalarField",
    "name": "value"
  }
],
v5 = [
  {
    "kind": "ScalarField",
    "name": "default"
  },
  {
    "concreteType": "HelpCenterTranslation",
    "kind": "LinkedField",
    "name": "translations",
    "plural": true,
    "selections": (v4/*: any*/)
  }
],
v6 = {
  "concreteType": "HelpCenterName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = [
  {
    "kind": "ScalarField",
    "name": "fileId"
  },
  {
    "kind": "ScalarField",
    "name": "url"
  }
],
v8 = {
  "concreteType": "HelpCenterBranding",
  "kind": "LinkedField",
  "name": "helpCenterBranding",
  "plural": false,
  "selections": [
    {
      "concreteType": "HelpCenterHomePageTitle",
      "kind": "LinkedField",
      "name": "homePageTitle",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    {
      "concreteType": "HelpCenterLogo",
      "kind": "LinkedField",
      "name": "logo",
      "plural": false,
      "selections": (v7/*: any*/)
    },
    {
      "concreteType": "HelpCenterBanner",
      "kind": "LinkedField",
      "name": "banner",
      "plural": false,
      "selections": (v7/*: any*/)
    },
    {
      "concreteType": "HelpCenterBrandingColors",
      "kind": "LinkedField",
      "name": "colors",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "primary"
        },
        {
          "kind": "ScalarField",
          "name": "bannerTextColor"
        },
        {
          "kind": "ScalarField",
          "name": "topBarColor"
        },
        {
          "kind": "ScalarField",
          "name": "topBarTextColor"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "isBannerAvailable"
    },
    {
      "kind": "ScalarField",
      "name": "isLogoAvailable"
    },
    {
      "kind": "ScalarField",
      "name": "useDefaultBanner"
    },
    {
      "kind": "ScalarField",
      "name": "hasTopBarBeenSplit"
    }
  ]
},
v9 = {
  "concreteType": "HelpCenterAnnouncements",
  "kind": "LinkedField",
  "name": "announcements",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "canEditHomePageAnnouncement"
    },
    {
      "concreteType": "HelpCenterAnnouncement",
      "kind": "LinkedField",
      "name": "homePageAnnouncements",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "description"
        },
        {
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "nameTranslationsRaw",
          "plural": true,
          "selections": (v4/*: any*/)
        },
        {
          "concreteType": "HelpCenterTranslation",
          "kind": "LinkedField",
          "name": "descriptionTranslationsRaw",
          "plural": true,
          "selections": (v4/*: any*/)
        }
      ]
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "name": "siteDefaultLanguageTag"
},
v11 = {
  "kind": "ScalarField",
  "name": "userLanguageTag"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "helpCenterBrandingQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "helpCenter.helpCenterById.helpCenterBranding"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "helpCenterBrandingQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4022d9416d0f15d10e96cf464eb40e25456a447dd4ffcf15934193900d7b3bf6",
    "metadata": {},
    "name": "helpCenterBrandingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4d4bfd3b05fd09feee5f588ec411df08";

export default node;
