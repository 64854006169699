import type { ReqTypesState } from 'state/persisted/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const REORDER_REQUEST_TYPE = 'epic.request-types.REORDER_REQUEST_TYPE';

interface ReorderRequestTypePayload {
    portalId: number;
    projectId: number;
    toIndex: number;
    fromIndex: number;
    requestTypeId: number;
    requestGroupId: number;
    requestTypes: ReqTypesState[];
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface ReorderRequestTypeAction {
    type: typeof REORDER_REQUEST_TYPE;
    payload: ReorderRequestTypePayload;
}

export const reorderRequestTypeAction = (payload: ReorderRequestTypePayload): ReorderRequestTypeAction => ({
    payload,
    type: REORDER_REQUEST_TYPE,
});
