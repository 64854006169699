/**
 * @generated SignedSource<<887df280e95f8114be36d1a3035058f8>>
 * @relayHash b9b03efa560a6a81ac58c91c07241cce
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9792f7cf6778faf7ae9ae97cecf226b5ac3182fe5a5aead27189bd6586e6c5c5

import type { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type JsmChatCreateWebConversationMessageContentType = "ADF" | "%future added value";
export type JsmChatCreateWebConversationUserRole = "Acknowledgment" | "Assistant_Error" | "Assistant_Message" | "ChannelMessage" | "CombinedAPIError" | "Error" | "Followup_Message" | "Init" | "InteractionError" | "InteractionMessage" | "JSM_Agent" | "Participant" | "Reporter" | "VirtualAgent" | "%future added value";
export type JsmChatWebConversationActions = "CLOSE_CONVERSATION" | "DISABLE_INPUT" | "GREETING_MESSAGE" | "REDIRECT_TO_SEARCH" | "%future added value";
export type communicationChannelSubscription$variables = {
  conversationId: string;
};
export type communicationChannelSubscription$data = {
  readonly jsmChat: {
    readonly updateConversation: {
      readonly action: JsmChatWebConversationActions | null | undefined;
      readonly conversation: {
        readonly node: {
          readonly appendices: ReadonlyArray<{
            readonly __typename: "JsmChatDropdownAppendix";
            readonly options: ReadonlyArray<{
              readonly id: string;
              readonly label: string;
            } | null | undefined>;
            readonly placeholder: string | null | undefined;
          } | {
            readonly __typename: "JsmChatJiraFieldAppendix";
            readonly fieldId: string;
            readonly jiraProjectId: string;
            readonly requestTypeId: string;
          } | {
            readonly __typename: "JsmChatOptionAppendix";
            readonly options: ReadonlyArray<{
              readonly id: string;
              readonly label: string;
            } | null | undefined>;
          } | {
            // This will never be '%other', but we need some
            // value in case none of the concrete values match.
            readonly __typename: "%other";
          } | null | undefined> | null | undefined;
          readonly authorType: JsmChatCreateWebConversationUserRole;
          readonly content: AGG$JSON;
          readonly contentType: JsmChatCreateWebConversationMessageContentType;
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
      readonly result: {
        readonly __typename: "JsmChatWebConversationUpdateQueryError";
        readonly identifier: string | null | undefined;
        readonly message: string | null | undefined;
      } | {
        readonly __typename: "JsmChatWebSubscriptionEstablishedPayload";
        readonly id: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type communicationChannelSubscription = {
  response: communicationChannelSubscription$data;
  variables: communicationChannelSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "conversationId"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "concreteType": "JsmChatAppendixActionItem",
  "kind": "LinkedField",
  "name": "options",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "name": "label"
    }
  ]
},
v4 = [
  {
    "concreteType": "JsmChatSubscription",
    "kind": "LinkedField",
    "name": "jsmChat",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "conversationId",
            "variableName": "conversationId"
          }
        ],
        "concreteType": "JsmChatWebSubscriptionResponse",
        "kind": "LinkedField",
        "name": "updateConversation",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "JsmChatWebSubscriptionEstablishedPayload"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "identifier"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ],
                "type": "JsmChatWebConversationUpdateQueryError"
              }
            ]
          },
          {
            "kind": "ScalarField",
            "name": "action"
          },
          {
            "concreteType": "JsmChatMessageEdge",
            "kind": "LinkedField",
            "name": "conversation",
            "plural": false,
            "selections": [
              {
                "concreteType": "JsmChatCreateWebConversationMessage",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "authorType"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "content"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "contentType"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "appendices",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "fieldId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "requestTypeId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "jiraProjectId"
                          }
                        ],
                        "type": "JsmChatJiraFieldAppendix"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "type": "JsmChatOptionAppendix"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "placeholder"
                          },
                          (v3/*: any*/)
                        ],
                        "type": "JsmChatDropdownAppendix"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "communicationChannelSubscription",
    "selections": (v4/*: any*/),
    "type": "Subscription"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "communicationChannelSubscription",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "9792f7cf6778faf7ae9ae97cecf226b5ac3182fe5a5aead27189bd6586e6c5c5",
    "metadata": {},
    "name": "communicationChannelSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "b6fd8a296b4b1ee2e919d0e23d3918ec";

export default node;
