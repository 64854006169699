import React from 'react';
import { useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';
import { ScreenErrorBoundary } from 'view/layout/screen-error-boundary';
import { AiAnswerFeedbackPage } from '@atlassian/ai-answer-feedback-page';
import messages from '@atlassian/ai-answer-feedback-page/src/ui/ai-answer-feedback-page/messages';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import { AnalyticsScreen } from '@atlassian/help-center-common-util/analytics/analytics-screen';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

import { anonymousScreenLayout, PORTAL_ROUTE_PREFIX } from '../common/constants';

const RenderAiAnswerRoute = () => {
    const [routerState] = useRouter();
    const { formatMessage } = useIntl();
    const { basePath, ...routeContext } = routerState;
    return (
        <AnalyticsScreen screenName={ScreenName.AI_ANSWER} screenId={routeContext.match.url}>
            <ScreenErrorBoundary
                screenName={ScreenName.AI_ANSWER}
                renderError={() => (
                    <ErrorPage
                        error={{
                            status: 500,
                            message: formatMessage(messages.genericErrorMessage),
                            callToActionUrl: '/servicedesk/customer/portals'.replace(getBaseName(), ''),
                            callToActionText: formatMessage(messages.genericRedirectMessage),
                        }}
                    />
                )}
            >
                <AiAnswerFeedbackPage />
            </ScreenErrorBoundary>
        </AnalyticsScreen>
    );
};

const route = {
    name: ScreenName.AI_ANSWER,
    path: `${PORTAL_ROUTE_PREFIX}/:requestKey/aianswer`,
    exact: true,
    component: RenderAiAnswerRoute,
};

const screenName = ScreenName.AI_ANSWER;

export const aiAnswerRoute = createRouteWithLayout(route, screenName, anonymousScreenLayout);
