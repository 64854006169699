import * as Sentry from '@sentry/browser';
import * as SentryIntegrations from '@sentry/integrations';
import { isFedRamp } from '@atlassian/atl-context';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import { EnvType, getEnv } from '../env';

/**
 * Adopted from JFE
 * To handle Sentry sourcemap path for Brotli Assets
 * PORTAL-700
 * @param event
 */
export function stackFrameAssetRewrite(event: Sentry.Event): Sentry.Event | PromiseLike<Sentry.Event | null> | null {
    try {
        const rewriteStacktrace = (value: Sentry.Event) => {
            if (!Array.isArray(value?.stacktrace?.frames)) {
                return;
            }

            value?.stacktrace?.frames.forEach((frame) => {
                if (!frame.filename) {
                    return;
                }

                // Yes, we're manipulating the input, this is an implicit choice for this part
                // of the codebase
                frame.filename = frame.filename.replace('/assets-brotli/', '/assets/');
            });
        };

        // for non-error errors there can be a top-level `event.stacktrace`
        rewriteStacktrace(event);

        // for normal errors there can be exception.values, these might contain stacktraces
        if (Array.isArray(event?.exception?.values)) {
            event?.exception?.values.forEach(rewriteStacktrace);
        }
    } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn('Unable to rewrite stacktrace filenames', ex);
    }
    return event;
}

function getSentryEnv(env: EnvType) {
    switch (env) {
        case EnvType.PROD: {
            return 'production';
        }
        case EnvType.STAGING: {
            return 'staging';
        }
        default: {
            return 'development';
        }
    }
}

class SentryInstaller {
    static install() {
        // Check if we have already initialised Sentry to be defensive in case two fragments are loaded in the same page, we don't want duplicated error reports.
        const client = Sentry.getCurrentHub().getClient();
        if (client && client.getOptions().enabled) {
            return;
        }

        Sentry.init({
            dsn: SentryInstaller.JS_ERROR_TRACKING_SENTRY_URL,
            release: SentryInstaller.getRelease(),
            environment: getSentryEnv(getEnv().environment),

            // This is evaluated against the *first* frame of the stack trace. If it doesn't match, the error is not sent.
            whitelistUrls: SentryInstaller.FRONTEND_RESOURCES,
            beforeSend: (event) => {
                return stackFrameAssetRewrite(event);
            },

            maxBreadcrumbs: 100,
            integrations: (integrations) => {
                integrations.push(
                    // This is used to mark which frames of the stacktrace are part of our app. They will be displayed in a different UI in Sentry.
                    new SentryIntegrations.RewriteFrames({
                        iteratee: (frame) => {
                            if (frame.filename) {
                                frame.in_app = SentryInstaller.FRONTEND_RESOURCES.some((path) =>
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    path.test(frame.filename!)
                                );
                            }
                            return frame;
                        },
                    })
                );

                return integrations;
            },
        });

        // This is for OpsGenie, so that alerts are tagged correctly.
        Sentry.configureScope((scope) => {
            scope.setTag('environment_type', getEnv().environment);
            scope.setTag('service_id', 'jsm-portal-frontend');
            const commit = SentryInstaller.getCommit();
            if (commit) {
                scope.setTag('commit', commit);
            }
            const cloudId = SentryInstaller.getCloudId();
            if (cloudId) {
                scope.setTag('cloud_id', cloudId);
            }
        });

        // TODO: add Aa ID + feature flags state tracking  - FSD-2309
    }

    // TODO: extract this URL in a env variable - FSD-2309
    private static JS_ERROR_TRACKING_SENTRY_URL = isFedRamp()
        ? 'https://9f5728b18f4afcbbd6127c57b615afa3@sentry.prod.services.kitt-inf-us-gov-mod.net/15'
        : 'https://dc9ea62591514adf8234e2c995959d5c@o55978.ingest.sentry.io/5988807';

    private static FRONTEND_RESOURCES = [
        // Our DEV and PROD environments in Micros Static.
        /https:\/\/jsm-portal-frontend\..*\.atl-paas\.net\//,
        /https:\/\/jsd-portal-frontend\..*\.atl-paas\.net\//,
        /https:\/\/jsm-help-center-ui\..*\.atl-paas\.net\//,
        /https:\/\/jsm-help-center-ui\..*\.atlassian-us-gov-mod\.com\//,
        // For devs using `yarn dev`.
        /localhost/,
    ];

    private static getCommit(): string | undefined {
        if (window.JSD_CUSTOMER_PORTAL_BUILD_VERSION && window.JSD_CUSTOMER_PORTAL_BUILD_VERSION.commit) {
            return window.JSD_CUSTOMER_PORTAL_BUILD_VERSION.commit;
        }
        return undefined;
    }

    private static getRelease() {
        return window.JSD_CUSTOMER_PORTAL_BUILD_VERSION && window.JSD_CUSTOMER_PORTAL_BUILD_VERSION.buildKey;
    }

    private static getCloudId(): string | null {
        return getMeta('tenant-id');
    }
}

export const install = SentryInstaller.install;
