import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import Picker, { PickerTrigger, formatLabel } from '@atlassian/paginated-picker';

export type Props = Omit<React.ComponentProps<typeof Picker>, 'onTrigger'> & {
    onPickerTriggerClick: () => void;
    testId?: string;
    enablePagination?: boolean;
};

const PaginatedFilter: React.FC<Props> = (props) => {
    const {
        isOpen,
        onOpen,
        onClose,
        value,
        fieldId,
        fieldLabel,
        onChange,
        onLoadOptions,
        onClearValues,
        onPickerTriggerClick,
        testId,
        enablePagination = true,
    } = props;

    const paginationConfig = enablePagination
        ? {
              enableFullList: false,
          }
        : undefined;

    const onTrigger = React.useCallback(
        ({ ref }: { ref: React.Ref<HTMLElement> }) => {
            return (
                <PickerTrigger
                    hasValues={value.length > 0}
                    onClick={onPickerTriggerClick}
                    isSelected={isOpen}
                    isInvalid={value.some((val) => val.invalid)}
                    ref={ref}
                    testId={testId}
                >
                    {formatLabel(fieldLabel, value)}
                </PickerTrigger>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value, onOpen, isOpen, fieldLabel, onPickerTriggerClick]
    );
    return (
        <PaginatedFilterWrapper>
            <Picker
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                value={value}
                pagination={paginationConfig}
                fieldId={fieldId}
                fieldLabel={fieldLabel}
                onChange={onChange}
                onLoadOptions={onLoadOptions}
                onTrigger={onTrigger}
                onClearValues={onClearValues}
            />
        </PaginatedFilterWrapper>
    );
};

export default PaginatedFilter;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const PaginatedFilterWrapper = styled.div`
    display: flex;
`;
