import type {
    FetchRequestListAction,
    FetchRequestListSuccess,
    FetchRequestListFailure,
} from 'state/actions/request-list';
import {
    FETCH_REQUEST_LIST_MODEL,
    FETCH_REQUEST_LIST_SUCCESS,
    FETCH_REQUEST_LIST_FAILURE,
} from 'state/actions/request-list';

export interface RequestListUIState {
    isLoading: boolean;
}

export type HandledActions = FetchRequestListAction | FetchRequestListSuccess | FetchRequestListFailure;

const defaultState: RequestListUIState = {
    isLoading: false,
};

export function requestListReducer(
    state: RequestListUIState = defaultState,
    action: HandledActions
): RequestListUIState {
    switch (action.type) {
        case FETCH_REQUEST_LIST_MODEL:
            return handleFetchRequestListAction(state);
        case FETCH_REQUEST_LIST_SUCCESS:
            return handleFetchRequestListSuccess(state);

        case FETCH_REQUEST_LIST_FAILURE:
            return handleFetchRequestListFailure(state);
        default:
            return state;
    }
}

const handleFetchRequestListAction = (state: RequestListUIState): RequestListUIState => ({
    ...state,
    isLoading: true,
});

const handleFetchRequestListSuccess = (state: RequestListUIState): RequestListUIState => ({
    ...state,
    isLoading: false,
});

const handleFetchRequestListFailure = (state: RequestListUIState): RequestListUIState => ({
    ...state,
    isLoading: false,
});
