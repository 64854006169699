/**
 * @generated SignedSource<<e5526e63dc9c43686ff0e98f09cb8b90>>
 * @relayHash 3000dc6fbe1d8bd77ea6a21b2f78fa05
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID db17ce120cd0e8c08a21dd6ea1c5d1bc8378709dee076be26cccb8c51a8b550c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HelpCenterSortOrder = "CREATED_DATE_ASCENDING" | "CREATED_DATE_DESCENDING" | "%future added value";
export type helpCentersManagementPageQuery$variables = {
  NUMBER_OF_HELP_CENTERS_INITIAL_FETCH: number;
  SORT_ORDER: HelpCenterSortOrder;
  cloudId: string;
  workspaceAri: string;
};
export type helpCentersManagementPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpCentersManagementPageFragment">;
};
export type helpCentersManagementPageQuery = {
  response: helpCentersManagementPageQuery$data;
  variables: helpCentersManagementPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "SORT_ORDER"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "Variable",
  "name": "workspaceAri",
  "variableName": "workspaceAri"
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
  },
  {
    "kind": "Variable",
    "name": "sortOrder",
    "variableName": "SORT_ORDER"
  },
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "slug"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v8/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "helpCentersManagementPageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH",
            "variableName": "NUMBER_OF_HELP_CENTERS_INITIAL_FETCH"
          },
          {
            "kind": "Variable",
            "name": "SORT_ORDER",
            "variableName": "SORT_ORDER"
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "helpCentersManagementPageFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "helpCentersManagementPageQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "HelpCenterQueryResultConnection",
            "kind": "LinkedField",
            "name": "helpCenters",
            "plural": false,
            "selections": [
              {
                "concreteType": "HelpCenterQueryResultEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "type"
                          },
                          (v9/*: any*/),
                          {
                            "concreteType": "HelpCenterName",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "default"
                              },
                              {
                                "concreteType": "HelpCenterTranslation",
                                "kind": "LinkedField",
                                "name": "translations",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "locale"
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "value"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "concreteType": "HelpCenterBranding",
                            "kind": "LinkedField",
                            "name": "helpCenterBranding",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isBannerAvailable"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "useDefaultBanner"
                              },
                              {
                                "concreteType": "HelpCenterBrandingColors",
                                "kind": "LinkedField",
                                "name": "colors",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "primary"
                                  }
                                ]
                              },
                              {
                                "concreteType": "HelpCenterBanner",
                                "kind": "LinkedField",
                                "name": "banner",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "url"
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "lastUpdated"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "mappedProjectsCount"
                          }
                        ],
                        "type": "HelpCenter"
                      },
                      (v10/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "message"
                      }
                    ],
                    "type": "QueryError"
                  },
                  (v10/*: any*/)
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              }
            ]
          },
          {
            "args": (v6/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "helpCentersManagementPageFragment_helpCenters",
            "kind": "LinkedHandle",
            "name": "helpCenters"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "db17ce120cd0e8c08a21dd6ea1c5d1bc8378709dee076be26cccb8c51a8b550c",
    "metadata": {},
    "name": "helpCentersManagementPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "41981756243472ea911db08bfdfe91d7";

export default node;
