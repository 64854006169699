export const fetchNothing = async (
    input: string,
    method: NonNullable<RequestInit['method']>,
    options?: {
        body?: RequestInit['body'] | undefined;
        headers?: Record<string, string>;
    }
): Promise<void> => {
    const response = await fetch(input, {
        method,
        ...options,
        credentials: 'same-origin',
    });

    if (!response.ok) {
        throw new Error(`Request failed: ${response.status} ${response.statusText}`);
    }
};
