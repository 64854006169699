import { buildModelsActionCreator } from 'epics/model/actions';
import type { PortalAndRequestTypesResponse } from 'rest/portals-and-request-types';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ModelTypeOptions } from 'rest/models/types';

export const FETCH_PORTAL_AND_REQUEST_TYPES = 'epic.model.FETCH_PORTAL_AND_REQUEST_TYPES';
export const FETCH_PORTAL_AND_REQUEST_TYPES_SUCCESS =
    'state.persisted.portals-and-request-types.FETCH_PORTAL_AND_REQUEST_TYPES_SUCCESS';
export const PROFORMA_FORM_LOADED = 'state.persisted.portals-and-request-types.PROFORMA_FORM_LOADED';

export type FetchPortalAndRequestTypesModelAction = ReturnType<typeof fetchPortalAndRequestTypesModel>;

interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export const fetchPortalAndRequestTypesModel = (
    payload: ModelTypeOptions['portalsAndRequestTypes'] = {},
    meta?: AnalyticsMetaData
) => ({
    meta,
    ...buildModelsActionCreator(FETCH_PORTAL_AND_REQUEST_TYPES, 'portalsAndRequestTypes')(payload),
});
export interface FetchPortalsAndRequestTypesSuccess {
    type: typeof FETCH_PORTAL_AND_REQUEST_TYPES_SUCCESS;
    payload: PortalAndRequestTypesResponse[];
}

export const fetchPortalsAndRequestTypesSuccess = (
    portalsAndRequestTypesModel: PortalAndRequestTypesResponse[]
): FetchPortalsAndRequestTypesSuccess => ({
    payload: portalsAndRequestTypesModel,
    type: FETCH_PORTAL_AND_REQUEST_TYPES_SUCCESS,
});

export interface ProformaFormLoaded {
    type: typeof PROFORMA_FORM_LOADED;
    payload: {
        portalId?: number;
        proformaFormIsLoaded: boolean;
    };
}
