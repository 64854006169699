/**
 *  Adapted from JFE:
 *  https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/browse/src/packages/common-legacy-do-not-add-anything-new/src/util/get-feature-flag-value.js
 *
 */

import { get } from 'lodash';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';
import type { FlagValue, Flags } from './types';
import { getStatsigValuesFromMeta } from './utils';

const getFlagValue = (featureFlags: Flags, key: string) => get(featureFlags, [key]);

const getFeatureFlagValue = <T extends FlagValue>(featureFlagName: string, defaultValue: T): FlagValue => {
    const featureFlags = getStatsigValuesFromMeta();
    const flagValue = getFlagValue(featureFlags, featureFlagName);

    if (typeof flagValue === 'undefined') {
        return defaultValue;
    }

    if (typeof flagValue === 'object' && 'value' in flagValue) {
        return flagValue.value;
    }
    return flagValue;
};

export const initialisePlatformFeatureFlags = () => {
    setBooleanFeatureFlagResolver((flagKey) => FeatureGates.checkGate(flagKey));
};

export default getFeatureFlagValue;
