import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { ResourceTimingsConfig } from '@atlassian/browser-metrics/dist/types/types';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';
import { sanitizeAssetName } from '@atlassian/help-center-common-util/sanitize-asset-name';
import { processUrl } from '@atlassian/help-center-common-util/url-processor';
// @ts-ignore TS(2305) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Module '"@atlassian/browser-metrics/dist/types/typ... Remove this comment to see the full error message

/**
 * BM calculates cache status of static resources - if they come from other than main domain they need to have configured Timing-Allow-Origin header
 * all assets served from `jsm-portal-fronted` will carry Timing-Allow-Origin header
 */
export const hasTimingHeaders = (url: string): boolean => url.includes('jsm-portal-frontend');

/**
 * This plugin returns back the feature flag value , to be replaced with the right statsig plugin
 */
export const featureFlagsPlugin = {
    client: Promise.resolve({
        getValue: (featureFlagName: string): string | boolean => {
            return FeatureGates.checkGate(featureFlagName);
        },
    }),
};

/**
 * This Plugin will be triggered whenever fetch method is used to fetch the resource.
 * Since we use `rxjs observable ajax`, which internally uses XHR and not fetch.
 * This will not be triggered for our API call URLs.
 * if this URL has UGC/PII, it has to be sanitized before submitting the data.
 *
 * Unhandled URLs will create logs in console, so please handle them
 * if not handled, url will be replaced with "unknown" string
 */
export const sanitiseEndpoints = (url: string) => {
    const { isProcessed, url: processedUrl } = processUrl(url, []);

    if (!isProcessed) {
        devLog(`Unsanitised URL: ${processedUrl}`);
        return 'unknown';
    }

    return processedUrl;
};

/**
 * These plugins are used to send timing metrics related to resources.
 * Performance entries with initiatorTypes of 'script', 'link' and 'fetch' are used to
 * send resource timing metrics.
 * Reference: https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/performance/browser-metrics/src/plugins/timings/resource.ts
 *
 */
export const resourceTimingsPlugin: ResourceTimingsConfig = {
    hasTimingHeaders,
    sanitiseEndpoints,
    mapResources: sanitizeAssetName(process.env.NODE_ENV !== 'production'),
};

/**
 * This plugin is used to send custom marks that has been recorded using User timing api.
 * Since Portal pages are partially rendered by monolith with performance marks in it,
 * These marks are sent as custom values in Browser Metrics v3 payload
 */
export function customPerfMarks() {
    const marks = {};

    const entries = window.performance?.getEntriesByType?.('mark');
    if (entries && entries.length > 0) {
        entries.forEach((entry) => {
            const key = `mark__${entry.name}`;
            const value = Math.floor(entry.startTime);
            // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            marks[key] = value;
        });
    }

    return marks;
}
