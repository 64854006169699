import { defineMessages } from 'react-intl-next';

export default defineMessages({
    unlinkModalTitle: {
        defaultMessage: 'Unlink this project?',
        description: 'Unlink project confirmation modal title',
        id: 'projects.list.unlink.confirmation.modal.title',
    },
    unlinkModalDesc: {
        defaultMessage: `All help resources from the project {projectName} will be removed from the help center and will not show up in search.`,
        description: 'Unlink project confirmation modal description',
        id: 'projects.list.unlink.confirmation.modal.description',
    },
    bulkUnlinkModalTitle: {
        defaultMessage: 'Unlink {number} projects?',
        description: 'Unlink project confirmation modal title in case of bulk unlinking',
        id: 'projects.list.bulk.unlink.confirmation.modal.title',
    },
    bulkUnlinkModalDesc: {
        defaultMessage: `All help resources from these projects will be removed from the help center and will not show up in search.`,
        description: 'Unlink project confirmation modal description in case of bulk unlinking',
        id: 'projects.list.bulk.unlink.confirmation.modal.description',
    },
    unlinkModalConfirmButton: {
        defaultMessage: 'Unlink',
        description: 'Unlink project confirmation modal confirm button',
        id: 'projects.list.unlink.confirmation.modal.confirm.button',
    },
    unlinkModalCancelButton: {
        defaultMessage: 'Cancel',
        description: 'Unlink project confirmation modal cancel button',
        id: 'projects.list.unlink.confirmation.modal.cancel.button',
    },
});
