import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { CategoriesResponse } from 'rest/category';
import { fetchCategories } from 'rest/category';
import type { FetchCategoriesSuccess, FetchCategoriesFailure } from 'state/actions/kb-category';
import { fetchCategoriesSuccess, fetchCategoriesFailure } from 'state/actions/kb-category';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';
import { isNetworkOrClientErrorCode } from '@atlassian/help-center-common-util/error-codes';
import type { ApolloErrorResponse } from '../types';
import { FETCH_CATEGORIES } from './actions';
import type { FetchCategoriesAction } from './actions';

const categoriesEpic: Epic<FetchCategoriesAction, FetchCategoriesSuccess | FetchCategoriesFailure> = (action$) => {
    return action$.ofType(FETCH_CATEGORIES).mergeMap((action) => {
        const { portalId, projectId, meta } = action.payload;

        return fetchCategories({ projectId })
            .map((response: CategoriesResponse) => {
                if (meta?.analyticsSuccessEvent) {
                    sendEvent(meta.analyticsSuccessEvent);
                }
                return fetchCategoriesSuccess(response, portalId);
            })
            .catch((error: ApolloErrorResponse) => {
                if (meta?.analyticsFailureEvent && !isNetworkOrClientErrorCode(error.statusCode)) {
                    sendEvent(meta.analyticsFailureEvent);
                }
                return Observable.of(fetchCategoriesFailure(error, portalId));
            });
    });
};

export default categoriesEpic;
