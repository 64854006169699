import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { Highlighter } from 'view/help-center/highlighter';
import * as bp from 'view/styles/breakpoints';
import { newElevationsFromDesignSystems } from 'view/styles/elevation';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import * as zIndex from 'view/styles/z-index';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import EyeIconUnhide from '@atlaskit/icon/glyph/watch';
import EyeIconHide from '@atlaskit/icon/glyph/watch-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { AutoScaledImage } from '@atlassian/help-center-common-component/auto-scaled-image';
import { PortalName, cardStyle, PortalCardContainer } from '../portal-card';
import { Description, IconContainer, Content } from '../portal-card/styled';
import messages from './messages';
import type { FeaturedProps, ProjectCardBasicProps } from './types';

export const ProjectCard: React.FunctionComponent<FeaturedProps & ProjectCardBasicProps & WrappedComponentProps> = ({
    id,
    name,
    description,
    iconUrl,
    isFeatured,
    isDropped,
    className,
    intl,
    hiddenFromHomeScreen,
    hideActionButtons,
    isPortalHidingEnabled,
    onToggleFeatured,
    onToggleVisibility,
}) => {
    const hasIcon = !!iconUrl;
    const hasOnlyPortalName = !description && !iconUrl;

    const onFeaturedStarClick = React.useCallback(() => {
        onToggleFeatured && onToggleFeatured(id, !isFeatured);
    }, [id, isFeatured, onToggleFeatured]);

    const onVisibilityIconClick = React.useCallback(() => {
        onToggleVisibility && onToggleVisibility(id, !hiddenFromHomeScreen);
    }, [id, hiddenFromHomeScreen, onToggleVisibility]);

    const rootProps = isFeatured
        ? {
              isDropped,
              isFeatured: true as const,
          }
        : {
              isFeatured: false as const,
          };

    const featuredProjectToolTip = intl.formatMessage(messages.featuredProjectTooltip);
    const unFeaturedProjectToolTip = intl.formatMessage(messages.unFeaturedProjectTooltip);
    const visibleProjectTooltip = intl.formatMessage(messages.visibleProjectTooltip);
    const hiddenProjectTooltip = intl.formatMessage(messages.hiddenProjectTooltip);

    const featureToolTip = {
        highlightedMessage: featuredProjectToolTip,
        unHighlightedMessage: unFeaturedProjectToolTip,
    };

    const featureAriaLabel = {
        highlightedLabel: featuredProjectToolTip,
        unHighlightedLabel: unFeaturedProjectToolTip,
    };

    const visibilityToolTip = {
        highlightedMessage: visibleProjectTooltip,
        unHighlightedMessage: hiddenProjectTooltip,
    };

    const visibilityAriaLabel = {
        highlightedLabel: visibleProjectTooltip,
        unHighlightedLabel: hiddenProjectTooltip,
    };

    const ariaAttributes = isFeatured
        ? {
              tabIndex: 0,
              role: 'button',
              'aria-labelledby': `project-card-content-${id}`,
              'aria-roledescription': 'Draggable item',
              'aria-describedby': 'featured-portals-screen-reader-description',
          }
        : {};

    return (
        <Wrapper
            data-test-id={isFeatured ? `featured-card-${id}` : null}
            data-testid={isFeatured ? `featured-card-${id}` : 'unfeatured-card'}
            {...(className ? { className } : {})}
            {...ariaAttributes}
        >
            <Root {...rootProps}>
                <Container hasIcon={hasIcon} isDraggable={isFeatured}>
                    {isFeatured && (
                        <DragHandlerWrapper>
                            <DragHandlerIcon label="drag-handler" size="large" />
                        </DragHandlerWrapper>
                    )}
                    {hasIcon && (
                        <IconContainer>
                            <AutoScaledImage src={iconUrl} alt="icon" />
                        </IconContainer>
                    )}
                    <Content id={`project-card-content-${id}`}>
                        <ProjectName hasOnlyPortalName={hasOnlyPortalName} title={name}>
                            {name}
                        </ProjectName>
                        {!!description && (
                            <Description title={description} dangerouslySetInnerHTML={{ __html: description }} />
                        )}
                    </Content>
                    {!hideActionButtons && (
                        <Actions>
                            {isPortalHidingEnabled && (!isFeatured || hiddenFromHomeScreen) && (
                                <Highlighter
                                    isHighlighted={!hiddenFromHomeScreen}
                                    highlightHandler={onVisibilityIconClick}
                                    toolTip={visibilityToolTip}
                                    ariaLabel={visibilityAriaLabel}
                                    actionSubjectId={hiddenFromHomeScreen ? 'hiddenCard' : 'visibleCard'}
                                    icon={{
                                        // @ts-ignore TS(2322) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Type 'NamedExoticComponent<IconProps>' is not assi... Remove this comment to see the full error message
                                        HighlightedIcon: EyeIconHide,
                                        // @ts-ignore TS(2322) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Type 'NamedExoticComponent<IconProps>' is not assi... Remove this comment to see the full error message
                                        UnHighlightedIcon: EyeIconUnhide,
                                    }}
                                />
                            )}

                            {(!isPortalHidingEnabled || !hiddenFromHomeScreen) && (
                                <Highlighter
                                    isHighlighted={isFeatured}
                                    highlightHandler={onFeaturedStarClick}
                                    toolTip={featureToolTip}
                                    ariaLabel={featureAriaLabel}
                                    actionSubjectId={isFeatured ? 'featuredCard' : 'unFeaturedCard'}
                                    icon={{
                                        // @ts-ignore TS(2322) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Type 'NamedExoticComponent<IconProps>' is not assi... Remove this comment to see the full error message
                                        HighlightedIcon: StarFilledIcon,
                                        // @ts-ignore TS(2322) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Type 'NamedExoticComponent<IconProps>' is not assi... Remove this comment to see the full error message
                                        UnHighlightedIcon: StarIcon,
                                    }}
                                    color={{
                                        highlightedColor: token('color.background.accent.yellow.subtler', colors.Y200),
                                    }}
                                />
                            )}
                        </Actions>
                    )}
                </Container>
            </Root>
        </Wrapper>
    );
};

export const ScreenReaderInstruction: React.FC<WrappedComponentProps> = ({ intl }) => (
    <SortableCardDescription id="featured-portals-screen-reader-description">
        {intl.formatMessage(messages.screenReaderInstructionsForReordering)}
    </SortableCardDescription>
);

export default React.memo(injectIntl(ProjectCard));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const cardGutterStyle = css`
    margin: ${token('space.200', '16px')} ${token('space.250', '20px')};

    ${bp.fromLarge.css`
margin: ${token('space.200', '16px')} ${grid.multiple(4.75).px};
`}
`;

const rootBackgroundColor = (props: FeaturedProps) =>
    props.isDropped
        ? token('elevation.surface.raised.pressed', colors.B50)
        : token('elevation.surface.raised', colors.N0);

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled.div<FeaturedProps>`
    ${cardStyle}
    ${(props) =>
        props.isFeatured
            ? mixins.raiseUpOnHoverOrFocus(newElevationsFromDesignSystems.e100, newElevationsFromDesignSystems.e200)
            : newElevationsFromDesignSystems.e100};
    ${(props) => mixins.applyIf(!Boolean(props.isFeatured), 'z-index: 0;')}
    background-color: ${rootBackgroundColor};
    user-select: none;

    ${(props) =>
        props.isFeatured &&
        !props.isDropped &&
        css({
            '&:hover': {
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                background: token('color.background.neutral.subtle.hovered', colors.N20),
            },
        })};

    &:hover,
    &:focus {
        z-index: ${zIndex.portalCardLayout};
        text-decoration: none;
    }

    &::after {
        cursor: ${(props) => {
            return props.isFeatured ? 'grab' : 'auto';
        }};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: ${token('space.100', '8px')};
    right: ${token('space.050', '4px')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Container = styled(PortalCardContainer)<{ isDraggable?: boolean }>`
    padding-right: ${grid.multiple(5.5).px};
    ${({ isDraggable }) => (isDraggable ? `padding-left: ${token('space.500', '40px')}` : '')};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ProjectName = styled(PortalName)`
    color: ${token('color.text', colors.N800)};
`;

const boxShadow = `0 ${token('space.100', '8px')} ${token('space.150', '12px')} rgba(0, 0, 0, 0.15)`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Wrapper = styled.li`
    ${cardGutterStyle}
    touch-action: none;
    list-style: none;

    &:focus {
        ${mixins.focusRing.default};

        & ${Root} {
            &:hover {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    &.sortable-helper {
        cursor: grabbing !important;
        pointer-events: auto !important;
        list-style: none;

        ${Root} {
            background-color: ${rootBackgroundColor};

            &::after {
                cursor: grabbing;
            }

            &:hover {
                cursor: grabbing;
                background-color: ${token('elevation.surface.raised', colors.N0)};
            }
            box-shadow: ${token('elevation.shadow.overlay', boxShadow)};
        }
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const DragHandlerWrapper = styled.div`
    left: ${token('space.050', '4px')};
    position: absolute;
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const SortableCardDescription = styled.div`
    display: none;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Overlay = styled.div<{ show: boolean }>`
    ${cardStyle}
    ${cardGutterStyle}
    /* stylelint-disable scale-unlimited/declaration-strict-value */
    background-color: ${(props) =>
        props.show
            ? token('color.background.selected.hovered', colors.B75)
            : token('elevation.surface.raised', 'transparent')};
    opacity: 0.3;
    transition: all 0.2s;
`;
