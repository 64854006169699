import { buildModelsActionCreator } from '../actions';

export const FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL = 'epic.model.FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL';

export type FetchUnsubscribedConfirmationAction = ReturnType<typeof fetchUnsubscribedConfirmation>;

export const fetchUnsubscribedConfirmation = buildModelsActionCreator(
    FETCH_UNSUBSCRIBED_CONFIRMATION_MODEL,
    'unsubscribedConfirmation'
);
