import type { AjaxResponse } from 'rxjs';
import { Observable } from 'rxjs';
import { post } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const completeSignup = (token: string, fullName: string, password: string, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/customer/pages/user/complete-signup?jwt=${token}`;
    const body = createBody(fullName, password);
    const headers = {
        'Content-Type': 'application/json',
    };

    return (
        post(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};

const createBody = (fullName: string, password: string) => {
    return JSON.stringify({
        fullName,
        password,
    });
};
