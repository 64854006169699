import * as React from 'react';
import { noop } from 'lodash';

function useMediaQuery(query: string) {
    const [match, setMatch] = React.useState<boolean>(false);
    React.useEffect(() => {
        function screenTest(e: MediaQueryListEvent | MediaQueryList) {
            setMatch(Boolean(e.matches));
        }
        if (window && Object.prototype.hasOwnProperty.call(window, 'matchMedia')) {
            const mql = window.matchMedia(query);
            /**
             * Trigger the screenTest even for the first call
             */
            screenTest(mql);

            mql.addListener(screenTest);
            return () => {
                mql.removeListener(screenTest);
            };
        }
        return noop;
    }, [query]);
    return match;
}

export { useMediaQuery };
