import { last, findLast } from 'lodash';
import type { Appendix, JiraFieldAppendix, HelpseekerMessage, Message } from '../../../common/types';
import type { State } from '../types';

export const getAppendicesFromLastMessage = (state: State): Appendix[] | undefined => {
    const lastMessage = last(state.messages);
    if (lastMessage?.message.authorType === 'AGENT') {
        return lastMessage.message.appendices;
    }
    return undefined;
};

export const getJiraFieldFromLastMessage = (state: State): JiraFieldAppendix | undefined => {
    const lastMessage = last(state.messages);
    if (lastMessage?.message.authorType !== 'AGENT' || !lastMessage?.message.appendices) {
        return undefined;
    }
    const jiraField = lastMessage.message.appendices.find(
        (appendix): appendix is JiraFieldAppendix => appendix.type === 'JIRA_FIELD'
    );
    return jiraField;
};

export const getLastHelpseekerMessage = (state: State): HelpseekerMessage | undefined =>
    findLast(
        state.messages,
        (message: Message): message is HelpseekerMessage => message.message.authorType === 'HELPSEEKER'
    );

export const getIsLastMessage = (state: State, messageId: string): boolean =>
    messageId === findLast(state.messages)?.id;
