import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';

export const RequestList = withAsync({
    loader: lazyForPaint(() =>
        import(/* webpackChunkName: "request-list" */ './hocs').then((module) => {
            const { registerRequestsListEpicsndReducers } = module;
            registerRequestsListEpicsndReducers();
            return module.default;
        })
    ),
});
