export const GET_ALL_REQUEST_GROUPS = 'epic.request-groups.GET_ALL_REQUEST_GROUPS' as const;

export const GET_ALL_REQUEST_GROUPS_SUCCESS = 'epic.request-groups.GET_ALL_REQUEST_GROUPS_SUCCESS' as const;

export const GET_ALL_REQUEST_GROUPS_FAILURE = 'epic.request-groups.GET_ALL_REQUEST_GROUPS_FAILURE' as const;

interface GetAllRequestGroupsActionPayload {
    projectId: number;
    portalId: number;
}

export interface GetAllRequestGroupsAction {
    type: typeof GET_ALL_REQUEST_GROUPS;
    payload: GetAllRequestGroupsActionPayload;
}

export interface RequestGroupResponse {
    id: number;
    name: string;
}

interface GetAllRequestGroupsSuccessPayload {
    requestGroups: RequestGroupResponse[];
    portalId: number;
}

export interface GetAllRequestGroupsSuccess {
    type: typeof GET_ALL_REQUEST_GROUPS_SUCCESS;
    payload: GetAllRequestGroupsSuccessPayload;
}

interface GetAllRequestTypeGroupsFailurePayload {
    errorMessage: string;
    projectId: number;
}
export interface GetAllRequestGroupsFailure {
    type: typeof GET_ALL_REQUEST_GROUPS_FAILURE;
    payload: GetAllRequestTypeGroupsFailurePayload;
}

export const getRequestGroupsAction = (projectId: number, portalId: number): GetAllRequestGroupsAction => ({
    payload: { projectId, portalId },
    type: GET_ALL_REQUEST_GROUPS,
});

export const getRequestGroupsSuccess = (payload: GetAllRequestGroupsSuccessPayload): GetAllRequestGroupsSuccess => ({
    payload,
    type: GET_ALL_REQUEST_GROUPS_SUCCESS,
});

export const getRequestGroupsFailure = (
    payload: GetAllRequestTypeGroupsFailurePayload
): GetAllRequestGroupsFailure => ({
    payload,
    type: GET_ALL_REQUEST_GROUPS_FAILURE,
});
