import type {
    ClearTransitionRequestFailure,
    CreateComment,
    CreateCommentSuccess,
    CreateCommentFailure,
    FetchRequestDetailsSuccess,
    ShowTransitionDialog,
    HideTransitionDialog,
    DecideApproval,
    DecideApprovalFailure,
    TransitionRequest,
    TransitionRequestFailure,
} from 'state/actions/request-details';
import {
    FETCH_REQUEST_DETAILS_SUCCESS,
    SHOW_TRANSITION_DIALOG,
    HIDE_TRANSITION_DIALOG,
    TRANSITION_REQUEST,
    CREATE_COMMENT,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_FAILURE,
    DECIDE_APPROVAL,
    DECIDE_APPROVAL_FAILURE,
    TRANSITION_REQUEST_FAILURE,
    CLEAR_TRANSITION_REQUEST_FAILURE,
} from 'state/actions/request-details';
import type { Error } from '@atlassian/help-center-common-util/errors';

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum ApprovalDecision {
    APPROVE,
    DECLINE,
}

export interface RequestDetailsUIState {
    visibleTransitionDialog?: number;
    inProgressTransitionId?: number;
    transitionFailure?: Error;
    commentSubmitting: boolean;
    commentBeingSubmitted?: string;
    inProgressApprovalAction?: ApprovalDecision;
}

export type HandledActions =
    | FetchRequestDetailsSuccess
    | ShowTransitionDialog
    | HideTransitionDialog
    | TransitionRequest
    | TransitionRequestFailure
    | ClearTransitionRequestFailure
    | CreateComment
    | CreateCommentSuccess
    | CreateCommentFailure
    | DecideApproval
    | DecideApprovalFailure;

const defaultState: RequestDetailsUIState = { commentSubmitting: false, commentBeingSubmitted: undefined };

export function requestDetailsReducer(
    state: RequestDetailsUIState = defaultState,
    action: HandledActions
): RequestDetailsUIState {
    switch (action.type) {
        case TRANSITION_REQUEST:
            return handleTransition(state, action);
        case TRANSITION_REQUEST_FAILURE:
            return handleTransitionFailure(state, action);
        case CLEAR_TRANSITION_REQUEST_FAILURE:
            return handleClearTransitionFailure(state);
        case FETCH_REQUEST_DETAILS_SUCCESS:
            return defaultState;
        case CREATE_COMMENT:
            return handleCommentCreate(state, action);
        case CREATE_COMMENT_SUCCESS:
            return handleCommentCreateSuccess(state);
        case CREATE_COMMENT_FAILURE:
            return handleCommentCreateFailure(state);
        case SHOW_TRANSITION_DIALOG:
            return handleShowTransitionDialog(state, action);
        case HIDE_TRANSITION_DIALOG:
            return handleHideTransitionDialog(state);
        case DECIDE_APPROVAL:
            return handleDecideApproval(state, action);
        case DECIDE_APPROVAL_FAILURE:
            return handleDecideApprovalFailure(state);
        default:
            return state;
    }
}

const handleShowTransitionDialog = (
    state: RequestDetailsUIState,
    action: ShowTransitionDialog
): RequestDetailsUIState => ({
    ...state,
    visibleTransitionDialog: action.payload.id,
});

const handleHideTransitionDialog = (state: RequestDetailsUIState): RequestDetailsUIState => ({
    ...state,
    visibleTransitionDialog: undefined,
});

const handleTransition = (state: RequestDetailsUIState, action: TransitionRequest): RequestDetailsUIState => ({
    ...state,
    inProgressTransitionId: action.payload.transitionId,
    transitionFailure: undefined,
});

function handleDecideApproval(state: RequestDetailsUIState, action: DecideApproval): RequestDetailsUIState {
    const approvalDecision = action.payload.approve ? ApprovalDecision.APPROVE : ApprovalDecision.DECLINE;
    return {
        ...state,
        inProgressApprovalAction: approvalDecision,
    };
}

const handleDecideApprovalFailure = (state: RequestDetailsUIState): RequestDetailsUIState => ({
    ...state,
    inProgressApprovalAction: undefined,
});

const handleTransitionFailure = (
    state: RequestDetailsUIState,
    action: TransitionRequestFailure
): RequestDetailsUIState => ({
    ...state,
    inProgressTransitionId: undefined,
    transitionFailure: action.payload.error,
});

const handleClearTransitionFailure = (state: RequestDetailsUIState): RequestDetailsUIState => ({
    ...state,
    transitionFailure: undefined,
});

const handleCommentCreate = (state: RequestDetailsUIState, action: CreateComment): RequestDetailsUIState => ({
    ...state,
    commentSubmitting: true,
    commentBeingSubmitted: action.payload.comment,
});

const handleCommentCreateSuccess = (state: RequestDetailsUIState): RequestDetailsUIState => ({
    ...state,
    commentSubmitting: false,
    commentBeingSubmitted: undefined,
});

const handleCommentCreateFailure = (state: RequestDetailsUIState): RequestDetailsUIState => ({
    ...state,
    commentSubmitting: false,
});
