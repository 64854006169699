// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { token } from '@atlaskit/tokens';
import type { HtmlAnchorProps } from '@atlassian/help-center-common-component/analytics/html-anchor';
import { HtmlAnchor } from '@atlassian/help-center-common-component/analytics/html-anchor';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const style = css<HtmlAnchorProps>`
    color: ${(props) => token('color.link', props.theme.color)};

    &:hover {
        color: ${(props) => token('color.link', props.theme.color)};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ExternalThemedLink = styled(HtmlAnchor)`
    ${style};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export default ExternalThemedLink;
