import EditHomepageQuery from 'view/advanced-help-center/edit-homepage/__generated__/editHomepageQuery.graphql';
import type { editHomepageQuery } from 'view/advanced-help-center/edit-homepage/__generated__/editHomepageQuery.graphql';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';

export const EditHomepageResource = createRelayResource<editHomepageQuery>({
    type: 'EDIT_HOMEPAGE',
    getQuery: (_, { cloudId, helpCenterAri, layoutAri }) => {
        return {
            parameters: EditHomepageQuery,
            variables: {
                cloudId,
                helpCenterAri,
                layoutAri,
            },
            options: {
                fetchPolicy: 'store-or-network',
            },
        };
    },
});
