import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { FetchForgotPasswordModelSuccess, FetchForgotPasswordModelFailure } from 'state/actions/forgot-password';
import { fetchForgotPasswordModelSuccess, fetchForgotPasswordModelFailure } from 'state/actions/forgot-password';

import type { FetchForgotPasswordAction } from './actions';
import { FETCH_FORGOT_PASSWORD_MODEL } from './actions';
import type { ErrorResponse } from 'epics/model/types';

export const forgotPasswordEpic: Epic<
    FetchForgotPasswordAction,
    FetchForgotPasswordModelSuccess | FetchForgotPasswordModelFailure
> = (action$) => {
    return action$.ofType(FETCH_FORGOT_PASSWORD_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.forgotPassword && !!response.forgotPassword.resetUrl)
            .map((response) => {
                return fetchForgotPasswordModelSuccess(response.forgotPassword!);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchForgotPasswordModelFailure(error));
            })
    );
};
