import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { isUseReact18CreateRootEnabled } from 'feature-flags';
import { createRoot } from 'react-dom/client';
import { IntlProvider as IntlProviderNext } from 'react-intl-next';
import type { ReactIntlError } from 'react-intl-next';
import { getMessages } from '@atlassian/help-center-common-util/i18n-messages';
import { getLanguageFromLocale } from '@atlassian/help-center-common-util/locale-data';
import { getMetaData } from '@atlassian/help-center-common-util/meta';
import * as Sentry from '@atlassian/help-center-common-util/sentry';
import AssetFieldValue from './asset-field-value';

const onIntlError = (intlError: string): void => {
    try {
        Sentry.reportError(intlError);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Failed to report intl error: ${intlError}`, err);
    }
};

export const onIntlNextError = (intlError: ReactIntlError): void => {
    onIntlError(`IntlV5: ${intlError.message}`);
};

export const getAssetFieldValueElementClass = () => {
    class AssetFieldValueElement extends HTMLElement {
        connectedCallback() {
            // create a Shadow DOM (i.e. the contents of this web component)
            const shadow = this.attachShadow({ mode: 'open' });

            // Jira passes the asset's origin as a data-origin attribute
            const origin = this.getAttribute('data-origin');

            if (isUseReact18CreateRootEnabled()) {
                const container = document.createElement('span');
                const root = createRoot(container);
                root.render(
                    <IntlProvider
                        messages={getMessages()}
                        locale={getLanguageFromLocale(getMetaData().userLocale)}
                        onError={onIntlError}
                    >
                        <IntlProviderNext
                            messages={getMessages()}
                            locale={getLanguageFromLocale(getMetaData().userLocale)}
                            onError={onIntlNextError}
                        >
                            <AssetFieldValue origin={origin} />
                        </IntlProviderNext>
                    </IntlProvider>
                );
                shadow.appendChild(container);
            } else {
                // render the component with its props, and add to the Shadow DOM
                const root = document.createElement('span');
                // eslint-disable-next-line react/no-deprecated
                ReactDOM.render(
                    <IntlProvider
                        messages={getMessages()}
                        locale={getLanguageFromLocale(getMetaData().userLocale)}
                        onError={onIntlError}
                    >
                        <IntlProviderNext
                            messages={getMessages()}
                            locale={getLanguageFromLocale(getMetaData().userLocale)}
                            onError={onIntlNextError}
                        >
                            <AssetFieldValue origin={origin} />
                        </IntlProviderNext>
                    </IntlProvider>,
                    root
                );
                shadow.appendChild(root);
            }
        }
    }
    return AssetFieldValueElement;
};
