import * as React from 'react';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { getErrorData } from '@atlassian/help-center-common-util/analytics/listener';
import type { FetchError } from '@atlassian/help-center-common-util/fetch';
import type { AdditionalErrorData } from '@atlassian/help-center-common-util/sentry/sentry';

interface ErrorBoundaryProps {
    children: React.ReactNode;
    id?: string;
    packageName?: string;
    onError?: (error: Error | FetchError) => void;
    // Passing error in callback as the fallback UI might need this error to render the error message
    renderError?: (error: Error | FetchError) => React.ReactNode;
}

interface State {
    error: Error | undefined;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps & WithAnalyticsEventsProps, State> {
    state = {
        error: undefined,
    };

    componentDidCatch(error: Error) {
        const { id, createAnalyticsEvent, packageName, onError } = this.props;
        this.setState({ error });
        const data: AdditionalErrorData = {};
        if (id) {
            data.errorBoundaryId = id;
        }

        if (packageName) {
            data.errorBoundaryPackageName = packageName;
        }

        trackError('app.boundary.uncaught.error', data, error);

        if (createAnalyticsEvent !== undefined) {
            const analyticsEvent = createAnalyticsEvent({
                analyticsType: OPERATIONAL_EVENT_TYPE,
                action: 'failed',
                errorMessage: getErrorData(error),
            });

            // TODO: FSD-4121 switch to using '@atlassian/error-handling'
            // Note that `componentName` is used as `actionSubject` in gasV3 operational events, and takes this form as described at
            // https://hello.atlassian.net/wiki/spaces/ALBS/pages/589347147/Jira+monitoring+frontend+errors#Step-2%3A-register-events
            const finalId = id || 'unknownErrorBoundary';
            const finalPackage = packageName || 'unknownPackage';
            const actionSubject = `${finalPackage}.${finalId}`;
            analyticsEvent.context.push({ componentName: actionSubject });

            analyticsEvent.fire();
        }

        if (onError) {
            onError(error);
        }
    }

    render() {
        const { children, renderError } = this.props;
        const { error } = this.state;

        if (error) {
            return renderError ? renderError(error) : null;
        }

        return children;
    }
}

export default withAnalyticsContext()(withAnalyticsEvents()(ErrorBoundary));
