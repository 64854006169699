import type { ReactNode } from 'react';
import React, { useCallback, useContext, useMemo, useState } from 'react';

type ResourceType = 'article' | 'requestForm' | 'portal';

export type SearchResultsCount = {
    [resource in ResourceType]?: number;
};

interface InlineResultsContextState {
    resultsCount: SearchResultsCount | null;
    clearSearchResultsCount: () => void;
    updateSearchResultsCount: (type: ResourceType, count: number) => void;
}

const InlineResultsContext = React.createContext<InlineResultsContextState | null>(null);

export const InlineResultsContextProvider = ({ children }: { children: ReactNode }) => {
    const [resultsCount, setResultsCount] = useState<SearchResultsCount | null>(null);

    const updateSearchResultsCount = useCallback(
        (resourceType: ResourceType, resourceCount: number) =>
            setResultsCount((state) => ({
                ...state,
                [resourceType]: resourceCount,
            })),
        [setResultsCount]
    );

    const clearSearchResultsCount = useCallback(() => setResultsCount(null), [setResultsCount]);

    const contextValue = useMemo(
        () => ({
            resultsCount,
            clearSearchResultsCount,
            updateSearchResultsCount,
        }),
        [resultsCount, clearSearchResultsCount, updateSearchResultsCount]
    );

    return <InlineResultsContext.Provider value={contextValue}>{children}</InlineResultsContext.Provider>;
};

export const useInlineResultsContext = () => {
    const context = useContext(InlineResultsContext);
    if (!context) {
        throw new Error('useInlineResultsContext must be used within InlineResultsContextProvider');
    }
    return context;
};
