import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import { ExperienceType } from '@atlassian/conversation-assistant';
import { getProductIdentifierAttributes } from './get-product-identifier-attributes';

export const sendUIEventForAskedQuestion = (
    analyticsClient: AnalyticsWebClient,
    conversationId: string,
    totalMessageCount: number,
    messageLength: number,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendUIEvent({
        source: 'uhConversationScreen',
        action: 'asked',
        actionSubject: 'question',
        attributes: {
            conversationId,
            messageLength,
            totalMessageCount,
            externalConversationId,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};

export const sendUIEventForClearConversation = (
    analyticsClient: AnalyticsWebClient,
    conversationId: string,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendUIEvent({
        source: 'conversationScreen',
        action: 'clear',
        actionSubject: 'conversation',
        attributes: {
            conversationId,
            externalConversationId,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};

export const sendTrackEventForAnswerStreamingStarted = (
    analyticsClient: AnalyticsWebClient,
    conversationId: string,
    messageLatency: number,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendTrackEvent({
        source: 'uhAiMateRequest',
        action: 'started',
        actionSubject: 'streaming',
        attributes: {
            messageLatency,
            conversationId,
            externalConversationId,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};

export const sendTrackEventForFetchedResponse = (
    analyticsClient: AnalyticsWebClient,
    messageLatency: number,
    confidenceScore: number | undefined,
    isFallbackMessage: boolean | undefined,
    isConfluenceLicensedUser: boolean | undefined,
    isVagueQuery: boolean | undefined,
    searchResultCount: number | undefined,
    totalSearchResultCount: number | undefined,
    conversationId: string,
    totalSources: number,
    totalHelpDesk: number,
    totalRequestForm: number,
    messageId: string | undefined,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendTrackEvent({
        source: 'uhAiMateRequest',
        action: 'fetched',
        actionSubject: 'conversation',
        actionSubjectId: conversationId,
        attributes: {
            externalConversationId,
            messageLatency,
            confidenceScore,
            isFallbackMessage,
            isConfluenceLicensedUser,
            isVagueQuery,
            searchResultCount,
            totalSearchResultCount,
            totalSources,
            totalHelpDesk,
            totalRequestForm,
            messageId,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};

export const sendTrackEventForFailure = (
    analyticsClient: AnalyticsWebClient,
    channelId: string,
    isRateLimitingError: boolean,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendTrackEvent({
        source: 'uhAiMateRequest',
        action: isRateLimitingError ? 'rateLimited' : 'fetch_failed',
        actionSubject: 'conversation',
        actionSubjectId: channelId,
        attributes: {
            externalConversationId,
            isAiMate: true,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};

export const sendUIEventForFollowUpSelection = (
    analyticsClient: AnalyticsWebClient,
    conversationId: string,
    externalConversationId: string | undefined
) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    analyticsClient.sendUIEvent({
        source: 'conversationScreen',
        action: 'selected',
        actionSubject: 'followUpQuery',
        attributes: {
            conversationId,
            externalConversationId,
            ...getProductIdentifierAttributes(ExperienceType.UNIFIED_HELP),
        },
    });
};
