/**
 * @generated SignedSource<<8a73cf2841c0c31430d124e12934df29>>
 * @relayHash 6f452febb43ea4f48f12803ae2f36059
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f27b717aa3dee1fea8e5e08bba80fa1c21b2e8edc62a059010667c927a5cc976

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type HelpCenterCreateInput = {
  name: HelpCenterNameInput;
  slug: string;
  workspaceARI: string;
};
export type HelpCenterNameInput = {
  default: string;
  translations?: ReadonlyArray<HelpCenterTranslationInput | null | undefined> | null | undefined;
};
export type HelpCenterTranslationInput = {
  locale: string;
  localeDisplayName?: string | null | undefined;
  value: string;
};
export type useHelpCenterCreateMutation$variables = {
  cloudId: string;
  input: HelpCenterCreateInput;
};
export type useHelpCenterCreateMutation$data = {
  readonly helpCenter: {
    readonly createHelpCenter: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly helpCenterAri: string | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useHelpCenterCreateMutation = {
  response: useHelpCenterCreateMutation$data;
  variables: useHelpCenterCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "message"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v6 = {
  "kind": "ScalarField",
  "name": "helpCenterAri"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useHelpCenterCreateMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterCreatePayload",
            "kind": "LinkedField",
            "name": "createHelpCenter",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHelpCenterCreateMutation",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterMutationApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "HelpCenterCreatePayload",
            "kind": "LinkedField",
            "name": "createHelpCenter",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f27b717aa3dee1fea8e5e08bba80fa1c21b2e8edc62a059010667c927a5cc976",
    "metadata": {},
    "name": "useHelpCenterCreateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "50311be3a83d4bcff60feddeba10c3e5";

export default node;
