import * as React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { Flex, Box, Stack, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import { MANAGE_PAGE_HEAD_WIDTH } from '../../constants';

const ManagePagesLayoutSkeleton = () => {
    const head = {
        cells: [
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['page-name'],
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['last-updated'],
            },
            {
                content: <SkeletonItem width="30%" height="16px" />,
                width: MANAGE_PAGE_HEAD_WIDTH['action-button'],
            },
        ],
    };
    const rows = Array.from({ length: 10 }).map((_, index) => ({
        key: `${index}`,
        cells: [
            {
                content: <SkeletonItem width="12px" height="12px" />,
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <Flex gap="space.100" alignItems="center">
                            <SkeletonItem width="24px" height="24px" />
                            <SkeletonItem width="60%" height="20px" />
                        </Flex>
                    </Box>
                ),
            },
            {
                content: (
                    <Box paddingBlock="space.050">
                        <Flex gap="space.100" alignItems="center">
                            <SkeletonItem width="24px" height="24px" isCircle />
                            <SkeletonItem width="60%" height="20px" />
                        </Flex>
                    </Box>
                ),
            },
            {
                key: 'select-all-skeleton',
                content: <SkeletonItem width="28%" height="16px" />,
            },
            {
                content: <SkeletonItem width="30%" height="20px" />,
            },
        ],
    }));
    return (
        <Stack space="space.300">
            <Stack space="space.300">
                <Box paddingBlockStart="space.400">
                    <Flex direction="row" gap="space.100">
                        <SkeletonItem width="84px" height="24px" />
                        <SkeletonItem width="84px" height="24px" />
                    </Flex>
                </Box>
                <Flex direction="row" justifyContent="space-between">
                    <SkeletonItem width="92px" height="28px" data-testid="manage-page-title-skeleton" />
                    <SkeletonItem width="92px" height="28px" data-testid="manage-page-create-button-skeleton" />
                </Flex>
                <Stack space="space.050">
                    <SkeletonItem width="75%" height="20px" />
                </Stack>
            </Stack>
            <Box xcss={tableSkeletonStyles}>
                <DynamicTable head={head} rows={rows} isFixedSize isRankingDisabled highlightedRowIndex={11} />
            </Box>
        </Stack>
    );
};

const tableSkeletonStyles = xcss({
    pointerEvents: 'none',
});

export default ManagePagesLayoutSkeleton;
