import type { FetchFunction } from 'relay-runtime';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';

const fetchQuery: FetchFunction = ({ operationKind, id, name }, variables) => {
    const AGG_BASE_PATH = id ? `/gateway/api/graphql/pq/${id}` : '/gateway/api/graphql';
    const AGG_PATH = process.env.AGG_BRANCH_SLUG
        ? `${AGG_BASE_PATH}/${process.env.AGG_BRANCH_SLUG}/graphql`
        : AGG_BASE_PATH;
    const body = JSON.stringify({
        variables,
        operationName: name,
    });

    return fetchJson(AGG_PATH, {
        body,
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...(operationKind === 'mutation' && {
                'X-ExperimentalApi': 'help-object-store-agg-beta, HelpCenterReorderTopics',
            }),
        },
    });
};

export default fetchQuery;
