/**
 * Wrapper around AJS
 */

import { isNil } from 'lodash';
import { getMetaFromHTML } from '@atlassian/help-center-common-util/meta';

const ajs = window.AJS;

export const getMeta = (key: string): string | undefined => {
    if (ajs && ajs.Meta) {
        return ajs.Meta.get(key);
    }
    /** When jsd-dw-portal-wrm-optimisations is on we fallback to a native version of getMeta that doesn't rely on AJS being loaded on the page via WRM */
    const value = getMetaFromHTML(key);

    return isNil(value) ? undefined : value;
};

export const getEventQueue = () => {
    return ajs === undefined ? undefined : ajs.EventQueue;
};
