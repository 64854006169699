import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { completeSignup } from 'rest/complete-signup';
import { resendValidationEmail } from 'rest/sign-up';
import type {
    CompleteSignup,
    CompleteSignupSuccess,
    CompleteSignupFailure,
    SendNewSignupLink,
    SendNewSignupLinkSuccess,
    SendNewSignupLinkFailure,
} from 'state/actions/complete-signup';
import {
    COMPLETE_SIGNUP,
    completeSignupSuccess,
    completeSignupFailure,
    SEND_NEW_SIGNUP_LINK,
    sendNewSignupLinkSuccess,
    sendNewSignupLinkFailure,
} from 'state/actions/complete-signup';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import type { LoginAttempt } from 'state/actions/login';
import { loginAttempt } from 'state/actions/login';
import { getDefaultSignUpError } from '@atlassian/help-center-common-util/errors';

export const completeSignupEpic: Epic<CompleteSignup, CompleteSignupSuccess | LoginAttempt | CompleteSignupFailure> = (
    action$
) => {
    return action$.ofType(COMPLETE_SIGNUP).mergeMap((completeSignupAction) => {
        const { jwtToken, fullName, password, email, contactAdminsFormEnabled } = completeSignupAction.payload;
        return completeSignup(jwtToken, fullName, password)
            .flatMap(() => {
                return concat(Observable.of(completeSignupSuccess()), Observable.of(loginAttempt(email, password)));
            })
            .catch((error: AjaxError) => {
                const parsedError = getDefaultSignUpError(error, contactAdminsFormEnabled);
                return Observable.of(completeSignupFailure(parsedError));
            });
    });
};

export const sendNewSignupLinkEpic: Epic<
    SendNewSignupLink,
    SendNewSignupLinkSuccess | SendNewSignupLinkFailure | HandleAjaxError
> = (action$) => {
    return action$.ofType(SEND_NEW_SIGNUP_LINK).mergeMap((sendNewSignupLinkAction) => {
        const { email, portalId } = sendNewSignupLinkAction.payload;
        return resendValidationEmail(email, portalId, undefined)
            .map(() => {
                return sendNewSignupLinkSuccess();
            })
            .catch((error: AjaxError) => {
                return concat(Observable.of(sendNewSignupLinkFailure()), Observable.of(handleAjaxError(error)));
            });
    });
};
