import type { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import type { Epic } from 'epics/rxjs';
import { addRequestGroupEpic } from './add-request-group';
import { getAllRequestGroupsEpic } from './get-request-groups';
import { removeRequestGroupEpic } from './remove-request-group';
import { reorderRequestGroupEpic } from './reorder-request-group';

export const requestGroupsEpic = combineEpics<Epic<Action>>(
    addRequestGroupEpic,
    reorderRequestGroupEpic,
    removeRequestGroupEpic,
    getAllRequestGroupsEpic
);
