import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { token } from '@atlaskit/tokens';
import { Fade } from '@atlassian/help-center-common-component/transitions';

export interface Props {
    helpText: string;
}

class RequestCreateFormHelpTextDumb extends React.Component<Props> {
    render() {
        return (
            <Fade in={!!this.props.helpText}>
                <HelpTextContainer dangerouslySetInnerHTML={{ __html: this.props.helpText }} />
            </Fade>
        );
    }
}

export default RequestCreateFormHelpTextDumb;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpTextContainer = styled.div`
    margin-top: ${token('space.200', '16px')};
    margin-bottom: ${token('space.100', '8px')};
`;
