import type { PortalAndRequestTypesResponse } from 'rest/portals-and-request-types';
import type { RequestListEntry } from 'rest/request-list';
import type { FetchApprovalListSuccess } from 'state/actions/approval-list';
import { FETCH_APPROVAL_LIST_SUCCESS } from 'state/actions/approval-list';

export interface ApprovalListState {
    approvalFilter?: string;
    portalId?: string;
    requestTypeId?: string;
    resultsPerPage: number;
    totalResults: number;
    pages: Pages;
    portalsRequestTypes: PortalAndRequestTypesResponse[];
}

export interface Pages {
    isInitialPageLoad: boolean;
    [page: number]: RequestListEntry[];
}

const emptyState: ApprovalListState = {
    totalResults: 0,
    resultsPerPage: 20,
    pages: { isInitialPageLoad: false },
    portalsRequestTypes: [],
};

export type HandledActions = FetchApprovalListSuccess;

const defaultState: ApprovalListState = emptyState;

export function approvalListReducer(
    state: ApprovalListState = defaultState,
    action: HandledActions
): ApprovalListState {
    switch (action.type) {
        case FETCH_APPROVAL_LIST_SUCCESS:
            return handleFetchApprovalListSuccess(state, action);
        default:
            return state;
    }
}

function handleFetchApprovalListSuccess(state: ApprovalListState, action: FetchApprovalListSuccess): ApprovalListState {
    const response = action.payload;
    if (response) {
        if (response.totalResults === state.totalResults) {
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [response.selectedPage]: response.requests,
                },
            };
        }
        return {
            approvalFilter: response.approvalFilterValue,
            portalId: response.portalId,
            requestTypeId: response.requestTypeId,
            resultsPerPage: response.resultsPerPage,
            totalResults: response.totalResults,
            pages: {
                isInitialPageLoad: false,
                [response.selectedPage]: response.requests,
            },
            portalsRequestTypes: response.portalRequestTypes,
        };
    }
    return state;
}
