import type { FetchCompleteSignupSuccess, FetchCompleteSignupFailure } from 'state/actions/complete-signup';
import { FETCH_COMPLETE_SIGNUP_SUCCESS, FETCH_COMPLETE_SIGNUP_FAILURE } from 'state/actions/complete-signup';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface CompleteSignupState {
    signupToken: string;
    emailAddress: string;
    validationOutcome: string | undefined;
    portalId?: number;
    redirectUrl?: string;
    initialError?: PersistedError;
}

export type HandledActions = FetchCompleteSignupSuccess | FetchCompleteSignupFailure;

const defaultState: CompleteSignupState = {
    signupToken: '',
    emailAddress: '',
    validationOutcome: undefined,
    portalId: undefined,
    initialError: undefined,
};

export const getInitialState = (): CompleteSignupState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.completeSignup) {
        return initialModelState.completeSignup;
    }
    return defaultState;
};

export function completeSignupReducer(
    state: CompleteSignupState = getInitialState(),
    action: HandledActions
): CompleteSignupState {
    switch (action.type) {
        case FETCH_COMPLETE_SIGNUP_SUCCESS:
            return handleFetchCompleteSignupSuccess(state, action);
        case FETCH_COMPLETE_SIGNUP_FAILURE:
            return handleFetchCompleteSignupFailure(state, action);
        default:
            return state;
    }
}

function handleFetchCompleteSignupSuccess(
    state: CompleteSignupState,
    action: FetchCompleteSignupSuccess
): CompleteSignupState {
    const response = action.payload;
    if (!response) {
        return state;
    }

    return {
        ...state,
        signupToken: response.signupToken,
        emailAddress: response.emailAddress,
        validationOutcome: response.validationOutcome,
        portalId: response.portalId,
        redirectUrl: response.redirectUrl,
    };
}

function handleFetchCompleteSignupFailure(
    state: CompleteSignupState,
    action: FetchCompleteSignupFailure
): CompleteSignupState {
    const { error } = action.payload;

    return {
        ...state,
        initialError: {
            error: {
                status: error.status,
                message: (error.errorMessages || [])[0],
                // We need to remove basename, see https://jdog.jira-dev.com/browse/FSD-2557
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            },
        },
    };
}
