import { connect } from 'react-redux';
import type { State } from 'state';
import { getCanAdministerProject, getProjectId } from 'state/selectors/portal';
import type { InjectedProps } from '@atlassian/help-center-common-component/with-path-params';
import { withPathParams } from '@atlassian/help-center-common-component/with-path-params';
import { toNumber } from '@atlassian/help-center-common-util/number';
import OnboardingProviderComponent from './onboarding';

const mapStateToProps = (state: State, props: InjectedProps) => ({
    projectId: getProjectId(state, toNumber(props.portalId)),
    showRequestTypeOnboarding: getCanAdministerProject(state, toNumber(props.portalId)),
});

export const OnboardingProvider = withPathParams(connect(mapStateToProps)(OnboardingProviderComponent));
