import type { UpdateOrganisationsModelAction } from 'state/actions/organisations';
import { UPDATE_ORGANISATIONS_MODEL } from 'state/actions/organisations';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface OrganisationState {
    id: number;
    name: string;
}

export interface OrganisationsState {
    organisations: OrganisationState[];
}

const emptyState: OrganisationsState = {
    organisations: [],
};

export const getInitialOrganisationsState = (): OrganisationsState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.organisations) {
        return {
            organisations: initialModelState.organisations.organisations,
        };
    }
    return emptyState;
};

const defaultState: OrganisationsState = getInitialOrganisationsState();

export default function reducer(state: OrganisationsState = defaultState, action: UpdateOrganisationsModelAction) {
    switch (action.type) {
        case UPDATE_ORGANISATIONS_MODEL: {
            const organisations = action.payload.organisations.map((org) => ({
                id: org.id,
                name: org.name,
            }));

            const newState: OrganisationsState = { organisations };
            return { ...state, ...newState };
        }
        default:
            return state;
    }
}
