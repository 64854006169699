import * as React from 'react';
import { getIsDemoProject } from 'rest/onboarding';
import type { OnboardingContext as OnboardingContextType, OnboardingProviderProps, OnboardingState } from './types';

export const OnboardingContext = React.createContext<OnboardingContextType>({
    isLoading: true,
    isRequestCreated: false,
    isDemoProject: false,
});

export const OnboardingConsumer = OnboardingContext.Consumer;

export default class OnboardingProvider extends React.Component<
    React.PropsWithChildren<OnboardingProviderProps>,
    OnboardingState
> {
    state: OnboardingState = {
        isLoading: true,
        isDemoProject: false,
    };

    async componentDidMount() {
        await this.fetchOnboarding();
    }

    async componentDidUpdate(prevProps: OnboardingProviderProps) {
        const projectIdChanged = prevProps.projectId !== this.props.projectId;

        if (projectIdChanged) {
            await this.fetchOnboarding();
        }
    }

    /**
     * fetchOnboarding()
     * This method should fetch all onboarding and then set the results appropriately.
     * Remember: Only one onboarding should be shown at a time.
     */
    fetchOnboarding = async () => {
        // Extend this later to fetch all onboarding sources.
        const { projectId, showRequestTypeOnboarding } = this.props;
        if (!projectId) {
            return;
        }

        let newState = {};

        if (showRequestTypeOnboarding) {
            const isDemoProject = await getIsDemoProject(projectId);
            newState = {
                ...newState,
                isDemoProject,
                isLoading: false,
            };
        }

        this.setState(newState);
    };

    render() {
        const { children, query } = this.props;
        const { isLoading, isDemoProject } = this.state;
        const isRequestCreated = !!query.created;

        return (
            <OnboardingContext.Provider
                value={{
                    isLoading,
                    isDemoProject,
                    isRequestCreated,
                }}
            >
                {children}
            </OnboardingContext.Provider>
        );
    }
}
