import { buildModelsActionCreator } from '../actions';

export const FETCH_USER_REQUESTS_COUNTS_MODEL = 'epic.model.FETCH_USER_REQUESTS_COUNTS_MODEL';

export type FetchUserRequestsCountsAction = ReturnType<typeof fetchUserRequestsCountsAction>;

export const fetchUserRequestsCountsAction = buildModelsActionCreator(
    FETCH_USER_REQUESTS_COUNTS_MODEL,
    'userRequestsCounts'
);
