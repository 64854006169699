import { getActivationId } from '@helpCenter/util/meta/meta';
import { JiraServicedeskRequestTypeAri } from '@atlassian/ari';
import { createRelayResource } from '@atlassian/help-center-common-util/resource-router';
import { rootUiModificationsQuery, type RootUiModificationsQueryType } from '@atlassian/ui-modifications-core/gql';

export const UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE = createRelayResource<RootUiModificationsQueryType>({
    type: 'UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE',
    getQuery: ({ match }, { cloudId }: { cloudId: string }) => {
        const { portalId, requestTypeId } = match.params;

        return {
            parameters: rootUiModificationsQuery,
            variables: {
                cloudId,
                uiModificationsContext: {
                    viewType: 'JSMRequestCreate',
                    portalId: portalId ?? '',
                    requestTypeId: requestTypeId
                        ? JiraServicedeskRequestTypeAri.create({
                              requestTypeId,
                              activationId: getActivationId(),
                          }).toString()
                        : '',
                },
                forgeExtensionRenderingContext: {
                    portalId: match.params.portalId ?? '',
                },
            },
            options: {
                fetchPolicy: 'network-only',
            },
            /**
             * Covers the case for `/portal/:portalId` and `/portal/:portalId/group/:requestGroupId` without the `requestTypeId` parameter.
             */
            isResourceDisabled: !portalId || !requestTypeId,
        };
    },
});
