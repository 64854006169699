import { escape, escapeRegExp } from 'lodash';

export default function emphasizeText(textHtml: string, keyword?: string) {
    const escapedTextHtml = escape(textHtml);
    if (keyword) {
        const dynamicRegex = new RegExp(`([^_\\s,-]*)${escapeRegExp(escape(keyword))}([^_\\s,-]*)`, 'gi');

        return escapedTextHtml.replace(dynamicRegex, (match) => {
            return `<strong>${match}</strong>`;
        });
    }

    return escapedTextHtml;
}
