export { updatePortalAnnouncements } from './portal';

export type {
    PortalAnnouncement,
    PortalResponse,
    PortalBrandingResponse,
    PortalBrandingResponseWithoutId,
    PortalAnnouncementTranslation,
    PortalAnnouncementTranslations,
    PortalAnnouncementUpdatePayload,
    PortalAnnouncementsUpdateResponse,
    PortalAnnouncementsTranslated,
    MediaApiUploadInformation,
    MediaApiUploadToken,
} from './types';
