import { get } from 'epics/ajax';
import type { Observable } from 'epics/rxjs';
import type { ReadFileMediaCredentials } from 'rest/request-details';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { UploadFileMediaCredentials } from './types';

export const getUploadCredentials = (
    portalId: number,
    context: string = contextPath
): Promise<UploadFileMediaCredentials> => {
    const uri = `${context}/rest/servicedesk/1/customer/portal/${portalId}/media/credentials/upload`;
    const headers = {
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        get(uri, headers)
            .toPromise()
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .then((ajaxResponse) => ajaxResponse.response)
    );
};

export const getUploadCredentialsObservable = (
    portalId: number,
    context: string = contextPath
): Observable<UploadFileMediaCredentials> => {
    const uri = `${context}/rest/servicedesk/1/customer/portal/${portalId}/media/credentials/upload`;
    const headers = {
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(uri, headers).map((ajaxResponse) => ajaxResponse.response);
};

export const getUploadCredentialsForRequest = (
    portalId: number,
    requestId: number,
    context: string = contextPath
): Promise<UploadFileMediaCredentials> => {
    const uri = `${context}/rest/servicedesk/1/customer/portal/${portalId}/media/${requestId}/credentials/upload`;
    const headers = {
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        get(uri, headers)
            .toPromise()
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .then((ajaxResponse) => ajaxResponse.response)
    );
};

export const getReadCredentialsForRequest = (
    portalId: number,
    requestId: number,
    context: string = contextPath
): Observable<ReadFileMediaCredentials> => {
    const uri = `${context}/rest/servicedesk/1/customer/portal/${portalId}/media/${requestId}/credentials/read`;
    const headers = {
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return get(uri, headers).map((response) => response.response);
};
