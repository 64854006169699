import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';
import { requestGroupReorder } from 'rest/request-group-reorder';
import type { FetchPortalAction } from 'state/actions/portal';
import { fetchPortalAction } from 'state/actions/portal';
import type { UpdateRequestGroupsOrderAction, ClearRequestGroupsOrderAction } from 'state/actions/portal-customisation';
import { clearRequestGroupsOrderAction, UPDATE_REQUEST_GROUPS_ORDER } from 'state/actions/portal-customisation';
import type { GetAllRequestGroupsAction } from 'state/actions/request-groups';
import { getRequestGroupsAction } from 'state/actions/request-groups';
import { getProjectId } from 'state/selectors/portal';
import { getUIRequestGroups } from 'state/selectors/portal-customisation';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

const REQUEST_ID = 'com.atlassian.servicedesk.portal-ui:customize-portal-sidebar';

export const reorderRequestGroupEpic: Epic<
    UpdateRequestGroupsOrderAction,
    ClearRequestGroupsOrderAction | FetchPortalAction | GetAllRequestGroupsAction
> = (action$, store) => {
    return (
        action$
            .ofType(UPDATE_REQUEST_GROUPS_ORDER)
            // User may perform updates (reorders and deletes) rapidly but we want to minimise unnecessary REST calls
            // We could debounce but we want ALL the analytics events so instead we buffer, get ALL the analytics events
            // and then just process the most recent event and if successful fire ALL analytics events
            .bufferTime(500)
            .mergeMap((bufferedActions: UpdateRequestGroupsOrderAction[]) => {
                // First, create list of buffered analytic events (ALL will be fired if `update request groups` REST call is successful)
                const analyticsEvents = bufferedActions.map((action) => action.payload.analyticsEvent).filter(Boolean);
                const analyticsFailureEvents = bufferedActions
                    .map((action) => action.payload.analyticsFailureEvent)
                    .filter(Boolean);
                // Second, pop the most recently published action
                const mostRecentAction = bufferedActions.pop();
                if (!mostRecentAction) {
                    return Observable.empty();
                }
                const { portalId, order } = mostRecentAction.payload;
                const state = store.getState();
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const projectId = getProjectId(state, portalId)!;

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const requestGroups = getUIRequestGroups(state, portalId)!;
                const deletedIds = requestGroups.map(({ id }) => id).filter((id) => !order.includes(id));

                // Perform the rest call to update groups
                return (
                    requestGroupReorder({
                        projectId,
                        order,
                        deleted: deletedIds,
                        id: REQUEST_ID,
                        inline: true,
                    })
                        // On success, refresh the model
                        .mergeMap(() => {
                            // Success; fire ALL analytics events
                            analyticsEvents.forEach(sendEvent);
                            return concat(
                                Observable.of(getRequestGroupsAction(projectId, portalId)),
                                Observable.of(
                                    fetchPortalAction({
                                        id: portalId,
                                        expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'],
                                    })
                                )
                            );
                        })
                        // On fail, clear the ui state to reverse optimistic update
                        .catch((_) => {
                            analyticsFailureEvents.forEach(sendEvent);
                            return Observable.of(clearRequestGroupsOrderAction());
                        })
                );
            })
    );
};
