import type * as React from 'react';
import { withAnalyticsContext, ScreenTypes } from '@atlassian/analytics-web-react';
import { clearScreenName } from '@atlassian/help-center-common-util/analytics/screen-name-context';

function screenWithoutBrowserMetrics<TProps extends object>(
    name: string,
    sourceType: ScreenTypes,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attributes?: any
): (component: React.ComponentType<TProps>) => React.ComponentType<TProps> {
    const analyticsWrapper = withAnalyticsContext<TProps>({
        sourceType,
        sourceName: name,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        attributes: {
            ...attributes,
            context: 'Portal',
        },
    });
    return (component) => clearScreenName(analyticsWrapper(component));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PortalAnalyticsInlineDialog = <TProps extends object>(name: string, attributes?: any) =>
    screenWithoutBrowserMetrics<TProps>(name, ScreenTypes.INLINE_DIALOG, attributes);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PortalAnalyticsModal = <TProps extends object>(name: string, attributes?: any) =>
    screenWithoutBrowserMetrics<TProps>(name, ScreenTypes.MODAL, attributes);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PortalAnalyticsDrawer = <TProps extends object>(name: string, attributes?: any) =>
    screenWithoutBrowserMetrics<TProps>(name, ScreenTypes.DRAWER, attributes);
