import type {
    TranslatedLoginAnnouncements,
    TranslatedLoginAnnouncement,
    LoginAnnouncementsResponse,
} from 'rest/login-announcements';
import type {
    FetchLoginAnnouncementsModelAction,
    FetchLoginAnnouncementsSuccess,
    FetchLoginAnnouncementsFailure,
    ResetLoginAnnouncementsSettings,
    UpdateLoginAnnouncementsModel,
    UpdateLoginAnnouncementsSuccess,
    UpdateLoginAnnouncementsFailure,
    UpdateLoginAnnouncementsUIState,
} from 'state/actions/login-announcements';
import {
    FETCH_LOGIN_ANNOUNCEMENTS,
    FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS,
    FETCH_LOGIN_ANNOUNCEMENTS_FAILURE,
    UPDATE_LOGIN_ANNOUNCEMENTS_MODEL,
    UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS,
    UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE,
    UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE,
    RESET_LOGIN_ANNOUNCEMENTS,
} from 'state/actions/login-announcements';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface LoginAnnouncementsUIState {
    loginAnnouncementsResponse: LoginAnnouncementsResponse;
    isLoading: boolean;
    isSaveInProgress: boolean;
    isSaveFailed: boolean;
}

export type HandledActions =
    | FetchLoginAnnouncementsModelAction
    | FetchLoginAnnouncementsSuccess
    | FetchLoginAnnouncementsFailure
    | ResetLoginAnnouncementsSettings
    | UpdateLoginAnnouncementsModel
    | UpdateLoginAnnouncementsSuccess
    | UpdateLoginAnnouncementsFailure
    | UpdateLoginAnnouncementsUIState;

export const getInitialLoginAnnouncementUIState = (): LoginAnnouncementsUIState => {
    const initialModelState = initialModel();
    return {
        loginAnnouncementsResponse: initialModelState?.loginAnnouncements
            ? initialModelState.loginAnnouncements
            : { defaultLocale: '', translatedLoginAnnouncements: {} },
        isLoading: false,
        isSaveInProgress: false,
        isSaveFailed: false,
    };
};

const doesContainLocaleDisplayName = (
    translatedLoginAnnouncement: TranslatedLoginAnnouncement | Pick<TranslatedLoginAnnouncement, 'header' | 'message'>
): translatedLoginAnnouncement is TranslatedLoginAnnouncement => {
    return 'localeDisplayName' in translatedLoginAnnouncement && !!translatedLoginAnnouncement.localeDisplayName;
};

export const defaultState: LoginAnnouncementsUIState = getInitialLoginAnnouncementUIState();

export const reduceTranslatedLoginAnnouncements = (
    payload: Record<string, Pick<TranslatedLoginAnnouncement, 'header' | 'message'> | TranslatedLoginAnnouncement>,
    state: LoginAnnouncementsUIState
): TranslatedLoginAnnouncements => {
    return Object.keys(payload).reduce((acc, languageKey: string) => {
        const payloadForLanguageKey = payload[languageKey];

        return {
            ...acc,
            [languageKey]: {
                header: payloadForLanguageKey.header,
                message: payloadForLanguageKey.message,
                localeDisplayName: doesContainLocaleDisplayName(payloadForLanguageKey)
                    ? payloadForLanguageKey.localeDisplayName
                    : state.loginAnnouncementsResponse.translatedLoginAnnouncements[languageKey]?.localeDisplayName,
            },
        };
    }, {});
};

export function loginAnnouncementsReducer(
    state: LoginAnnouncementsUIState = defaultState,
    action: HandledActions
): LoginAnnouncementsUIState {
    switch (action.type) {
        case FETCH_LOGIN_ANNOUNCEMENTS:
            return handleFetchLoginAnnouncements(state, true);
        case FETCH_LOGIN_ANNOUNCEMENTS_SUCCESS:
            return handleFetchLoginAnnouncementsSuccess(state, action.payload, false);
        case FETCH_LOGIN_ANNOUNCEMENTS_FAILURE:
            return handleFetchLoginAnnouncements(state, false);
        case UPDATE_LOGIN_ANNOUNCEMENTS_MODEL: {
            const newState: LoginAnnouncementsUIState = {
                ...state,
                loginAnnouncementsResponse: {
                    ...state.loginAnnouncementsResponse,
                    translatedLoginAnnouncements: reduceTranslatedLoginAnnouncements(
                        action.payload.translatedLogInAnnouncements,
                        state
                    ),
                },
                isSaveInProgress: true,
                isSaveFailed: false,
            };
            return newState;
        }
        case UPDATE_LOGIN_ANNOUNCEMENTS_FAILURE:
            return {
                ...state,
                isSaveFailed: true,
            };
        case UPDATE_LOGIN_ANNOUNCEMENTS_UI_STATE:
            return {
                ...state,
                loginAnnouncementsResponse: {
                    ...state.loginAnnouncementsResponse,
                    translatedLoginAnnouncements: reduceTranslatedLoginAnnouncements(
                        action.payload.translatedLogInAnnouncements,
                        state
                    ),
                },
            };
        case UPDATE_LOGIN_ANNOUNCEMENTS_SUCCESS:
        case RESET_LOGIN_ANNOUNCEMENTS:
        default:
            return {
                ...state,
                isSaveInProgress: false,
            };
    }
}

const handleFetchLoginAnnouncementsSuccess = (
    state: LoginAnnouncementsUIState,
    payload: LoginAnnouncementsResponse,
    isLoading: boolean
): LoginAnnouncementsUIState => ({
    ...state,
    isLoading,
    loginAnnouncementsResponse: {
        ...state.loginAnnouncementsResponse,
        defaultLocale: payload.defaultLocale,
        translatedLoginAnnouncements: reduceTranslatedLoginAnnouncements(payload.translatedLoginAnnouncements, state),
    },
    isSaveFailed: false,
});

const handleFetchLoginAnnouncements = (
    state: LoginAnnouncementsUIState,
    isLoading: boolean
): LoginAnnouncementsUIState => ({
    ...state,
    isLoading,
    isSaveFailed: false,
});
