import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import Button from '@atlaskit/button';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export interface Props {
    shown: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disableSearch?: boolean;
}

export const SearchButton = React.forwardRef(
    ({ onClick, disableSearch }: Props, ref: React.RefObject<HTMLButtonElement>) => {
        return (
            <StyledSearchIconContainer>
                <Button
                    isDisabled={disableSearch}
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    style={{ borderRadius: '50%' }}
                    ref={ref}
                    onClick={onClick}
                    appearance="subtle"
                    shouldFitContainer
                    iconBefore={
                        <SearchIcon size="medium" label="Search" primaryColor={token('color.icon', colors.N500)} />
                    }
                    analyticsContext={{ actionSubjectId: 'navbarSearch' }}
                />
            </StyledSearchIconContainer>
        );
    }
);

SearchButton.displayName = 'SearchButton';

export default SearchButton;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const StyledSearchIconContainer = styled.div`
    display: flex;
    height: ${token('space.400', '32px')};
    width: ${token('space.400', '32px')};
    align-items: stretch;
    background-color: ${token('elevation.surface.overlay', colors.N30)};
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    transform: translateZ(0);
    transition:
        transform 200ms,
        opacity 200ms;
    box-shadow: ${token('space.0', '0px')} ${token('space.0', '0px')} ${token('space.0', '0px')}
        ${token('space.025', '2px')} ${token('color.border.inverse', colors.N0)};
    border: none;
    margin: ${token('space.150', '12px')};
    padding: 0;
    cursor: pointer;
`;
