import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { LogoutResponse } from 'rest/logout';
import { logoutUsingIdentity } from 'rest/logout';
import type { State } from 'state';
import type { UpdateUserModelAnonymousAction } from 'state/actions/user';
import { getPortalId } from 'state/selectors/context';
import { getIsAnonymousEnabled, getBaseUrl } from 'state/selectors/env';
import { isAtlassianAccountManaged } from 'state/selectors/user';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import { getBaseName, getRelativeBasePath } from '@atlassian/help-center-common-util/history';
import { removeLocalStorageItem } from '@atlassian/help-center-common-util/localStorage';
import { assign, reload } from '@atlassian/help-center-common-util/location';
import { getHelpCenterUrl, getLoginUrlForPortalId } from '@atlassian/help-center-common-util/url';
import type { LogOutAction } from './actions';
import { LOG_OUT } from './actions';

const AA_LOGOUT_REDIRECT_URL = `/logout?dest-url=${encodeURIComponent(
    `${getRelativeBasePath()}${getHelpCenterUrl()}`
)}`;

export const performJiraLogOut = (state: State): Observable<LogoutResponse | null> => {
    if (isAtlassianAccountManaged(state)) {
        // no need to call Jira to log out Atlassian accounts. Simply redirect to AA logout page
        return Observable.of({ logoutRedirectUrl: AA_LOGOUT_REDIRECT_URL });
    }
    return logoutUsingIdentity(`${getBaseUrl(state)}${getAfterLogoutUrl(state)}`);
};

export const logOutEpic: Epic<LogOutAction, UpdateUserModelAnonymousAction> = (action$, store) => {
    return action$.ofType(LOG_OUT).mergeMap(() => {
        const state = store.getState();

        // Removing cached response of help-seeker-type on logout
        removeLocalStorageItem('help-seeker-type');

        return performJiraLogOut(state)
            .mergeMap((response) => {
                // We do a full page load to the login or help center page to clear out the redux store and avoid
                // data for one user being seen by another if one logs out and another logs in in the same tab
                assign(getLogoutDestination(state, response));

                return Observable.empty<UpdateUserModelAnonymousAction>();
            })
            .catch((error: AjaxError) => {
                return handleLogoutError(error);
            });
    });
};

function handleLogoutError(error: AjaxError | string) {
    trackError('session.logout.failed', {}, error);
    reload();
    return Observable.empty<UpdateUserModelAnonymousAction>();
}

function getAfterLogoutUrl(state: State) {
    if (getIsAnonymousEnabled(state)) {
        return getHelpCenterUrl();
    }

    return getLoginUrlForPortalId(getPortalId(state));
}

function getLogoutDestination(state: State, logoutResponseBody: LogoutResponse | null): string {
    if (logoutResponseBody && logoutResponseBody.logoutRedirectUrl) {
        return logoutResponseBody.logoutRedirectUrl;
    }

    return `${getBaseName()}${getAfterLogoutUrl(state)}`;
}
