import type { AjaxError } from 'rxjs';
import type { LogOutAction } from 'epics/logout';
import { logOutAction } from 'epics/logout';
import type { Epic } from 'epics/rxjs';
import { Observable, concat } from 'epics/rxjs';

import { changePassword, updateUserProfile } from 'rest/user';
import type { HandleAjaxError, ShowSuccessFlag } from 'state/actions/flags';
import { handleAjaxError, showSuccessFlag } from 'state/actions/flags';
import {
    SAVE_USER_PROFILE,
    saveUserProfileSuccess,
    saveUserProfileFailure,
    VALIDATE_AND_SAVE_PASSWORD_CHANGE,
    VALIDATE_PASSWORD,
    validatePasswordSuccess,
    changePassword as changePasswordAction,
    CHANGE_PASSWORD,
    changePasswordFailure,
} from 'state/actions/user';
import type {
    SaveUserProfile,
    SaveUserProfileSuccess,
    SaveUserProfileFailure,
    ValidateAndSavePasswordChange,
    ChangePassword,
    ChangePasswordFailure,
    ValidatePassword,
    ValidatePasswordSuccess,
} from 'state/actions/user';
import type { JiraErrorResponse } from '@atlassian/help-center-common-util/errors';
import { getJiraErrorResponse, getDefaultError } from '@atlassian/help-center-common-util/errors';
import {
    MIN_PASSWORD_LENGTH_IN_CHARS,
    MAX_PASSWORD_LENGTH_IN_CHARS,
} from '@atlassian/help-center-common-util/sign-up-form-validator';
import messages from './messages';

export const updateUserProfileEpic: Epic<
    SaveUserProfile,
    SaveUserProfileSuccess | ShowSuccessFlag | SaveUserProfileFailure | HandleAjaxError
> = (action$) => {
    return action$.ofType(SAVE_USER_PROFILE).mergeMap((updateUserProfileAction) => {
        const { displayName, language, timezone } = updateUserProfileAction.payload;
        const languageKey = language === undefined ? undefined : language.originalKey;
        const timezoneId = timezone === undefined ? undefined : timezone.id;

        return updateUserProfile(displayName, languageKey, timezoneId)
            .flatMap(() =>
                concat(
                    Observable.of(saveUserProfileSuccess(displayName, language, timezone)),
                    Observable.of(
                        showSuccessFlag(
                            messages.profileDetailsUpdatedHeaderMessage,
                            undefined,
                            messages.profileDetailsUpdatedBodyMessage
                        )
                    )
                )
            )
            .catch((error: AjaxError) =>
                concat(Observable.of(saveUserProfileFailure()), Observable.of(handleAjaxError(error)))
            );
    });
};

export const validateAndSavePasswordChangeEpic: Epic<
    ValidateAndSavePasswordChange,
    ChangePassword | ChangePasswordFailure
> = (action$) => {
    return action$.ofType(VALIDATE_AND_SAVE_PASSWORD_CHANGE).mergeMap((validateAndSavePasswordChangeAction) => {
        const { currentPassword, newPassword, confirmPassword } = validateAndSavePasswordChangeAction.payload;

        const validationErrors = validate(currentPassword, newPassword, confirmPassword);

        const result = validationErrors || changePasswordAction(currentPassword, newPassword, confirmPassword);
        return Observable.of(result);
    });
};

export const validateEpic: Epic<ValidatePassword, ChangePasswordFailure | ValidatePasswordSuccess> = (action$) => {
    return action$.ofType(VALIDATE_PASSWORD).mergeMap((validatePasswordAction) => {
        const { currentPassword, newPassword, confirmPassword } = validatePasswordAction.payload;

        const validationErrors = validate(currentPassword, newPassword, confirmPassword);

        const result = validationErrors || validatePasswordSuccess();
        return Observable.of(result);
    });
};

const validate = (currentPassword: string, newPassword: string, confirmPassword: string) => {
    const currentPasswordError = !currentPassword.trim() ? messages.currentPasswordMissingError : undefined;
    let newPasswordError = !newPassword.trim() ? messages.newPasswordMissingError : undefined;
    let confirmPasswordError = !confirmPassword.trim() ? messages.confirmPasswordMissingError : undefined;

    if (!newPasswordError && newPassword.length < MIN_PASSWORD_LENGTH_IN_CHARS) {
        newPasswordError = messages.newPasswordTooShortError;
    }

    if (!newPasswordError && newPassword.length > MAX_PASSWORD_LENGTH_IN_CHARS) {
        newPasswordError = messages.newPasswordTooLongError;
    }

    if (!newPasswordError && newPassword.length > MAX_PASSWORD_LENGTH_IN_CHARS) {
        newPasswordError = messages.newPasswordTooLongError;
    }

    if (!newPasswordError && !confirmPasswordError && confirmPassword !== newPassword) {
        confirmPasswordError = messages.confirmPasswordDoesNotMatchError;
    }

    if (currentPasswordError || newPasswordError || confirmPasswordError) {
        return changePasswordFailure(currentPasswordError, newPasswordError, confirmPasswordError, undefined);
    }

    return undefined;
};

export const changePasswordEpic: Epic<ChangePassword, LogOutAction | ChangePasswordFailure> = (action$) => {
    return action$.ofType(CHANGE_PASSWORD).mergeMap((receivedChangePasswordAction) => {
        const { currentPassword, newPassword, confirmPassword } = receivedChangePasswordAction.payload;

        return changePassword(currentPassword, newPassword, confirmPassword)
            .map(() => {
                return logOutAction();
            })
            .catch((error: AjaxError) => {
                const jiraError = getJiraErrorResponse(error);
                if (jiraError) {
                    return Observable.of(getJiraErrorFailure(jiraError, error));
                }
                return Observable.of(getGenericErrorFailure(error));
            });
    });
};

const getJiraErrorFailure = (jiraError: JiraErrorResponse, ajaxError: AjaxError) => {
    const currentError = getPasswordError(jiraError, 'current');
    const newError = getPasswordError(jiraError, 'password');
    const confirmError = getPasswordError(jiraError, 'confirm');
    if (currentError === undefined && newError === undefined && confirmError === undefined) {
        return getGenericErrorFailure(ajaxError);
    }
    return changePasswordFailure(currentError, newError, confirmError, undefined);
};

const getPasswordError = (error: JiraErrorResponse, field: string): string | undefined => {
    return error.errors.filter((e) => e.field === field).map((e) => e.errorMessage)[0];
};

const getGenericErrorFailure = (error: AjaxError) => {
    return changePasswordFailure(undefined, undefined, undefined, getDefaultError(error));
};
