import * as React from 'react';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import { startPageLoad } from '@atlassian/help-center-common-util/analytics/browser-metrics-v3';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';

export interface InjectedProps {
    preventBrowserMetricsTimerResetOnScreenMount: boolean;
    startBrowserMetricsTransitionToNewScreen: (screenName: ScreenName) => void;
    stopBrowserMetricsTransitionToNewScreen: () => void;
}

interface State {
    preventBrowserMetricsTimerResetOnScreenMount: boolean;
}

export const BrowserMetricsScreenTransitionStatusContext = React.createContext<InjectedProps>({
    preventBrowserMetricsTimerResetOnScreenMount: false,
    startBrowserMetricsTransitionToNewScreen: () => undefined,
    stopBrowserMetricsTransitionToNewScreen: () => undefined,
});

/**
 * Provides react context that allows for imperatively starting browser metrics timer for a screen.
 * Should be used in cases like form submissions, when the user has done something
 * that will result in a screen transition, but we need to wait before performing the actual transition.
 *
 * If you start a transition, make sure you also stop the transition if there are any errors.
 */
export class BrowserMetricsScreenTransitionStatusProvider extends React.Component<
    {
        children: React.ReactNode;
    },
    State
> {
    state = {
        preventBrowserMetricsTimerResetOnScreenMount: false,
    };

    /**
     * Explicitly start the browsermetrics timer,
     * and set state that will prevent the timer from being reset when we eventually transition to the new screen.
     */
    startTransitionToNewScreen = (screenName: ScreenName) => {
        devLog('Browser metrics: starting timer for new screen before transition:', screenName);
        this.setState({ preventBrowserMetricsTimerResetOnScreenMount: true });
        /**
         * Browser metrics v3 start page load
         */
        startPageLoad(screenName);
    };

    /**
     * Stop measuring any transition that may have been started.
     * This must be called any time the transition does not go ahead, e.g. if there are errors in a form submission
     * This will also be called any time an AnalyticsScreen component is mounted,
     * so you won't need to call this yourself if the transition is successful
     */
    stopTransitionToNewScreen = () => {
        this.setState({ preventBrowserMetricsTimerResetOnScreenMount: false });
    };

    render() {
        return (
            <BrowserMetricsScreenTransitionStatusContext.Provider
                value={{
                    preventBrowserMetricsTimerResetOnScreenMount:
                        this.state.preventBrowserMetricsTimerResetOnScreenMount,
                    startBrowserMetricsTransitionToNewScreen: this.startTransitionToNewScreen,
                    stopBrowserMetricsTransitionToNewScreen: this.stopTransitionToNewScreen,
                }}
            >
                {this.props.children}
            </BrowserMetricsScreenTransitionStatusContext.Provider>
        );
    }
}

const withBrowserMetricsScreenTransitionStatus =
    <TProps extends InjectedProps>(
        WrappedComponent: React.ComponentType<TProps>
    ): React.FunctionComponent<Omit<TProps, keyof InjectedProps>> =>
    // upgrading eslint-plugin-react to 7.31.11. Please correct when this code is revisited.
    // eslint-disable-next-line react/display-name
    (props: TProps) => (
        <BrowserMetricsScreenTransitionStatusContext.Consumer>
            {(value: InjectedProps) => <WrappedComponent {...props} {...value} />}
        </BrowserMetricsScreenTransitionStatusContext.Consumer>
    );

export default withBrowserMetricsScreenTransitionStatus;
