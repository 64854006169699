// Events that the connect field add-on will need to listen to, These are API.
// We don't need to worry about these as it's handled by us but they shouldn't be changed.
import { uniq } from 'lodash';
import type { WebFragment } from 'state/persisted/connect-addon';

const EVENTS = {
    VALIDATE: 'jira-servicedesk-request-properties-validate',
    SERIALIZE: 'jira-servicedesk-request-properties-serialize',
};

interface ConnectAddonEntry {
    onSerialized: AnyFunction1<object>;
    onValidated: AnyFunction1<boolean>;
    contextExtension?: ConnectContextExtension;
}

const connectPluginKey = 'com.atlassian.plugins.atlassian-connect-plugin';

const internalListOfAddons: { [connectAddonKey: string]: ConnectAddonEntry } = {};

function getFullkey(context: ConnectContext) {
    const addonKey = context.extension.addon_key;
    const panelKey = context.extension.key;
    return `${connectPluginKey}:${addonKey}__${panelKey}`;
}

export const getAppKeyFromFullKey = (fullKey: string = '') => {
    const strippedOfModuleKey = fullKey.substring(0, fullKey.lastIndexOf('__'));
    return strippedOfModuleKey.substring(strippedOfModuleKey.lastIndexOf(':') + 1);
};

export const getUniqueAppKeysFromConnectFragments = (connectFragments: WebFragment[]) => {
    return uniq(connectFragments.map((fragment) => getAppKeyFromFullKey(fragment.key)));
};

let connectHost: ConnectHost;

export const connectApi: (host: ConnectHost) => ServiceDeskConnectApi = (host) => {
    connectHost = host;
    connectHost.onIframeEstablished((context: ConnectContext) => {
        const fullKey = getFullkey(context);
        const addonRef = internalListOfAddons[fullKey];

        if (!!addonRef) {
            internalListOfAddons[fullKey] = {
                ...internalListOfAddons[fullKey],
                contextExtension: context.extension,
            };
        }
    });

    return {
        // add-ons will call this on the request create page with the result of the validation after they receive the EVENTS.VALIDATE event
        validate(isValid: boolean, context: ConnectContext) {
            const fullKey = getFullkey(context);
            const addonRef = internalListOfAddons[fullKey];

            if (!!addonRef) {
                addonRef.onValidated(isValid);
            }
        },
        // add-ons will call this on the request create page with the serialized data after they receive the EVENTS.SERIALIZE event
        serialize(data: object, context: ConnectContext) {
            const fullKey = getFullkey(context);
            const addonRef = internalListOfAddons[fullKey];

            if (!!addonRef) {
                addonRef.onSerialized(data);
            }
        },
    };
};

export const validate = (key: string) => {
    const addonRef = internalListOfAddons[key];
    connectHost &&
        addonRef &&
        addonRef.contextExtension &&
        connectHost.broadcastEvent(EVENTS.VALIDATE, addonRef.contextExtension);
};

export const serialize = (key: string) => {
    const addonRef = internalListOfAddons[key];
    connectHost &&
        addonRef &&
        addonRef.contextExtension &&
        connectHost.broadcastEvent(EVENTS.SERIALIZE, addonRef.contextExtension);
};

/**
 * Registers the connect add-on
 */
export const register = (key: string, onSerialized: AnyFunction1<object>, onValidated: AnyFunction1<boolean>) => {
    internalListOfAddons[key] = {
        onSerialized,
        onValidated,
    };
};

export const unregister = (key: string) => {
    delete internalListOfAddons[key];
};
