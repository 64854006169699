import type React from 'react';
import type { ConnectPageName } from 'rest/connect-addon';
import type { ForgePageName } from '@atlassian/help-center-common-component/forge-ui/types';

export const SHOW_BANNER = 'state.persisted.blockedAppsBanner.SHOW_BANNER';
export const SHOW_CONNECT_BANNER = 'state.persisted.blockedAppsBanner.SHOW_CONNECT_BANNER';
export const RESET_BANNER_STATE = 'state.persisted.blockedAppsBanner.RESET_BANNER_STATE';
export const RESET_CONNECT_BANNER_STATE = 'state.persisted.blockedAppsBanner.RESET_CONNECT_BANNER_STATE';

export const CREATE_REQUEST = 'create_request';
export const VIEW_REQUEST = 'view_request';
export const PORTAL = 'portal';

export type SCREEN = typeof CREATE_REQUEST | typeof VIEW_REQUEST | typeof PORTAL | null; // Only these are the screens where the banner would be shown

export interface BlockedAppsBannerState {
    create_request: boolean;
    portal: boolean;
    view_request: boolean;
    connect: ConnectBlockedAppsBannerState;
}

type ConnectBlockedAppsBannerState = {
    [key in NonNullable<SCREEN>]: boolean;
};

const defaultState: BlockedAppsBannerState = {
    create_request: false,
    portal: false,
    view_request: false,
    connect: {
        create_request: false,
        view_request: false,
        portal: false,
    },
};

export interface ShowBannerAction {
    type: typeof SHOW_BANNER;
    payload: {
        page: SCREEN;
    };
}

export interface ShowConnectBannerAction {
    type: typeof SHOW_CONNECT_BANNER;
    payload: {
        page: SCREEN;
    };
}

export interface ResetBannerStateAction {
    type: typeof RESET_BANNER_STATE;
    payload: {
        page: SCREEN;
    };
}

export interface ResetConnectBannerStateAction {
    type: typeof RESET_CONNECT_BANNER_STATE;
    payload: {
        page: SCREEN;
    };
}

export const showBannerAction = (page: SCREEN): ShowBannerAction => ({
    type: SHOW_BANNER,
    payload: {
        page,
    },
});

export const showConnectBannerAction = (page: SCREEN): ShowConnectBannerAction => ({
    type: SHOW_CONNECT_BANNER,
    payload: {
        page,
    },
});

export const resetBannerStateAction = (page: SCREEN): ResetBannerStateAction => ({
    type: RESET_BANNER_STATE,
    payload: {
        page,
    },
});

export const resetConnectBannerStateAction = (page: SCREEN): ResetConnectBannerStateAction => ({
    type: RESET_CONNECT_BANNER_STATE,
    payload: {
        page,
    },
});

export const getScreenFromConnectPageName = (connectPageName: ConnectPageName): SCREEN => {
    switch (connectPageName) {
        case 'PORTAL':
            return PORTAL;
        case 'CREATE_REQUEST':
            return CREATE_REQUEST;
        case 'VIEW_REQUEST':
            return VIEW_REQUEST;
        default:
            return null;
    }
};

export const getScreenFromForgePageName = (forgePageName: ForgePageName) => {
    let screen: SCREEN;

    switch (forgePageName) {
        case 'create_request':
            screen = CREATE_REQUEST;
            break;

        case 'portal':
            screen = PORTAL;
            break;

        case 'view_request':
            screen = VIEW_REQUEST;
            break;

        default:
            screen = null;
    }

    return screen;
};

export const getDefaultState = (): BlockedAppsBannerState => {
    return {
        ...defaultState,
    };
};

export type BlockedAppsBannerActions =
    | ShowBannerAction
    | ShowConnectBannerAction
    | ResetBannerStateAction
    | ResetConnectBannerStateAction;

export const mapDispatchToPropsBanner = (dispatch: React.Dispatch<BlockedAppsBannerActions>) => {
    return {
        showBanner: (forgePageName: ForgePageName): void => {
            return dispatch(showBannerAction(getScreenFromForgePageName(forgePageName)));
        },
        resetBannerState: (forgePageName: ForgePageName): void => {
            return dispatch(resetBannerStateAction(getScreenFromForgePageName(forgePageName)));
        },
    };
};

export const mapConnectDispatchToPropsBanner = {
    showBanner: (connectPageName: ConnectPageName) => {
        return showConnectBannerAction(getScreenFromConnectPageName(connectPageName));
    },
    resetBannerState: (connectPageName: ConnectPageName) => {
        return resetConnectBannerStateAction(getScreenFromConnectPageName(connectPageName));
    },
};

export default function (currentState: BlockedAppsBannerState = getDefaultState(), action: BlockedAppsBannerActions) {
    const newState: BlockedAppsBannerState = { ...currentState };

    switch (action.type) {
        case SHOW_BANNER: {
            const screen = action.payload.page;

            if (screen) {
                newState[screen] = true;
            }

            return newState;
        }

        case SHOW_CONNECT_BANNER: {
            const screen = action.payload.page;

            if (screen) {
                newState.connect = {
                    ...currentState.connect,
                    [screen]: true,
                };
            }

            return newState;
        }

        case RESET_BANNER_STATE: {
            const screen = action.payload.page;

            if (screen) {
                newState[screen] = false;
            }

            return newState;
        }

        case RESET_CONNECT_BANNER_STATE: {
            const screen = action.payload.page;

            if (screen) {
                newState.connect = {
                    ...currentState.connect,
                    [screen]: false,
                };
            }

            return newState;
        }

        default:
            return currentState;
    }
}
