/**
 * @generated SignedSource<<826191e81d6a3ab00f9ae5de7195d5f7>>
 * @relayHash edea8cab7174eb3853f7e72b44d7aa5b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3c115be58886ea6ac6426e499fd226521bc50f4439d42461efe307f31039df41

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ConversationPageVirtualAgentQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
  isVaOnHcGAEnabled: boolean;
};
export type ConversationPageVirtualAgentQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "HelpCenter";
      readonly virtualAgentEnabled: boolean | null | undefined;
    } | {
      readonly __typename: "QueryError";
      readonly message: string | null | undefined;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  } | null | undefined;
  readonly virtualAgent?: {
    readonly virtualAgentAvailability: boolean | null | undefined;
  } | null | undefined;
};
export type ConversationPageVirtualAgentQuery = {
  response: ConversationPageVirtualAgentQuery$data;
  variables: ConversationPageVirtualAgentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "helpCenterAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isVaOnHcGAEnabled"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "virtualAgentEnabled"
    }
  ],
  "type": "HelpCenter"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ],
  "type": "QueryError"
},
v6 = {
  "condition": "isVaOnHcGAEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "concreteType": "VirtualAgentQueryApi",
      "kind": "LinkedField",
      "name": "virtualAgent",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "containerId",
              "variableName": "helpCenterAri"
            }
          ],
          "kind": "ScalarField",
          "name": "virtualAgentAvailability"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ConversationPageVirtualAgentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      (v6/*: any*/)
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConversationPageVirtualAgentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      },
      (v6/*: any*/)
    ]
  },
  "params": {
    "id": "3c115be58886ea6ac6426e499fd226521bc50f4439d42461efe307f31039df41",
    "metadata": {},
    "name": "ConversationPageVirtualAgentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c8de3321d0f65a7321d319b804610818";

export default node;
