import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { State } from 'state';
import { dismissFlag } from 'state/actions/flags';
import { getFlags } from 'state/selectors/flags';
import FlagGroup from './flag-group';

const selector = createSelector(
    (state: State) => getFlags(state),
    (flags) => ({
        flags,
    })
);

export default connect(selector, {
    dismissFlagAction: dismissFlag,
})(FlagGroup);
