import { mapValues } from 'lodash';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import { UI_EVENT_TYPE } from '@atlassian/analytics-web-react';

type AnalyticsFunction<TProps extends object> = (props: TProps) => {
    action: string;
    attributes?: Record<string, unknown>;
};

export interface AddedAnalyticsProps {
    actionSubjectId: string;
    attributes?: Record<string, unknown>;
}

type FireUIAnalytics<TProps extends object> = { [K in PickFunctionOrOptionals<TProps>]?: AnalyticsFunction<TProps> };

/**
 * Will create and fire a UI event when the callback is called.
 * Works like the following:
 *
 * YOU NEED TO PASS IN PROPS UNFORTUNATELY SORRY LIMITATION W/ TYPESCRIPT!
 * This means your default props probably won't work. And your props won't be set correctly on the wrapped component.
 * JUST PASS IN TPROPS!
 */
const withCreateFireUIAnalytics = <TProps,>(
    actionSubject: string,
    analyticsDefinition: FireUIAnalytics<TProps & AddedAnalyticsProps>
) => {
    type FinalProps = TProps & AddedAnalyticsProps;

    return (Component: React.ComponentType<TProps>): React.ComponentType<FinalProps> => {
        const mappedAnalyticsDefinition = mapValues(analyticsDefinition, (val: AnalyticsFunction<FinalProps>) => {
            return (create: CreateUIAnalyticsEvent, props: FinalProps) => {
                const payload = val(props);
                const event = create({
                    ...payload,
                    ...props.attributes,
                    actionSubjectId: props.actionSubjectId,
                    analyticsType: UI_EVENT_TYPE,
                });
                event.fire();
                return event;
            };
        });

        const WrappedComponent = withAnalyticsContext({ componentName: actionSubject })(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            withAnalyticsEvents(mappedAnalyticsDefinition)(Component as any)
        ) as React.ComponentType<FinalProps>;

        WrappedComponent.displayName = `withCreateFireUIAnalytics(${Component.displayName || Component.name})`;

        return WrappedComponent;
    };
};

export default withCreateFireUIAnalytics;
