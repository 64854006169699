import type { FeaturedAndSortedPortalsResponse, PortalSummary, SortBy } from 'rest/featured-and-sorted-portals';
import type {
    FetchFeaturedAndSortedPortalsFailure,
    FetchFeaturedAndSortedPortalsSuccess,
    UpdatePortalReorderSettingsSuccess,
} from 'state/actions/featured-and-sorted-portals';
import {
    FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE,
    FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS,
    UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS,
} from 'state/actions/featured-and-sorted-portals';
import { isHiddenPortal } from 'state/selectors/featured-and-sorted-portals';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface PortalSummaries {
    [id: number]: PortalSummary;
}

const FEATURED_PORTALS_LIMIT: number = 15;
export interface FeaturedAndSortedPortalsState {
    portals: PortalSummaries;
    orderedPortalIds: number[];
    sortBy: SortBy;
    limit: number;
    total: number;
    error?: PersistedError['error'];
    isPortalHidingEnabled?: boolean;
}

export type HandledActions =
    | FetchFeaturedAndSortedPortalsSuccess
    | UpdatePortalReorderSettingsSuccess
    | FetchFeaturedAndSortedPortalsFailure;

export const emptyFeaturedAndSortedPortalsState: FeaturedAndSortedPortalsState = {
    portals: {},
    orderedPortalIds: [],
    sortBy: 'POPULARITY',
    limit: FEATURED_PORTALS_LIMIT,
    total: 0,
    isPortalHidingEnabled: false,
};

export const reduceFeaturedAndSortedPortalsResponse = (portals: PortalSummary[]): PortalSummaries => {
    return portals.reduce((acc, portalSummary) => {
        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[portalSummary.id] = {
            id: portalSummary.id,
            key: portalSummary.key,
            name: portalSummary.name,
            description: portalSummary.description,
            logoUrl: portalSummary.logoUrl,
            isFeatured: 'isFeatured' in portalSummary && portalSummary.isFeatured,
            rank: 'rank' in portalSummary && portalSummary.rank,
            isPortalNotVisibleOnHelpCentre: portalSummary.isPortalNotVisibleOnHelpCentre,
            hiddenFromHomeScreen: 'hiddenFromHomeScreen' in portalSummary && portalSummary.hiddenFromHomeScreen,
        };
        return acc;
    }, {});
};

export const getInitialFeaturedAndSortedPortalsState = (): FeaturedAndSortedPortalsState => {
    const initialModelState = initialModel();
    if (initialModelState?.featuredAndSortedPortals) {
        const modelResponse: FeaturedAndSortedPortalsResponse = initialModelState.featuredAndSortedPortals;
        return {
            portals: reduceFeaturedAndSortedPortalsResponse(modelResponse.portals),
            orderedPortalIds: modelResponse.portals.map((portal) => portal.id),
            sortBy: modelResponse.sortBy ? modelResponse.sortBy : 'POPULARITY',
            limit: modelResponse.limit ? modelResponse.limit : FEATURED_PORTALS_LIMIT,
            total: modelResponse.total ? modelResponse.total : 0,
            isPortalHidingEnabled: modelResponse.isPortalHidingEnabled,
        };
    }

    return emptyFeaturedAndSortedPortalsState;
};

const defaultState: FeaturedAndSortedPortalsState = getInitialFeaturedAndSortedPortalsState();

/**
 * This method is to return only those portals which are not already stored
 * This ensures that the rank and isFeatured values stored in existing data will not get overridden with new data missing these values
 */
const getPortalsNotAlreadyStored = (storedPortals: PortalSummaries, portals: PortalSummary[]): PortalSummary[] => {
    return portals.filter((portal) => !storedPortals[portal.id]);
};

const updateFeaturedPortals = (
    state: FeaturedAndSortedPortalsState,
    updatedFeaturedPortalIds: number[],
    updatedOrderedPortalIds: number[],
    hiddenPortalIds: number[]
): PortalSummaries => {
    const portals: PortalSummaries = {};

    updatedOrderedPortalIds.forEach((portalId) => {
        portals[portalId] = {
            id: portalId,
            key: state.portals[portalId].key,
            name: state.portals[portalId].name,
            description: state.portals[portalId].description,
            logoUrl: state.portals[portalId].logoUrl,
            isPortalNotVisibleOnHelpCentre: state.portals[portalId].isPortalNotVisibleOnHelpCentre,
            isFeatured: false,
            hiddenFromHomeScreen: hiddenPortalIds.includes(portalId),
            rank: -1,
        };
    });

    updatedFeaturedPortalIds.forEach((portalId, index) => {
        portals[portalId] = {
            ...portals[portalId],
            isFeatured: true,
            rank: index,
        };
    });
    return portals;
};

export function featuredAndSortedPortalsReducer(
    state: FeaturedAndSortedPortalsState = defaultState,
    action: HandledActions
): FeaturedAndSortedPortalsState {
    switch (action.type) {
        case FETCH_FEATURED_AND_SORTED_PORTALS_SUCCESS:
            return handleFetchFeaturedAndSortedPortalsSuccess(action, state);
        case FETCH_FEATURED_AND_SORTED_PORTALS_FAILURE:
            return handleFetchFeaturedAndSortedPortalsFailure(action, state);
        case UPDATE_PORTAL_REORDER_SETTINGS_SUCCESS:
            return handleUpdatePortalReorderSettingsSuccess(action, state);
        default:
            return state;
    }
}

const handleFetchFeaturedAndSortedPortalsSuccess = (
    action: FetchFeaturedAndSortedPortalsSuccess,
    state: FeaturedAndSortedPortalsState
): FeaturedAndSortedPortalsState => {
    const { portals, sortBy, limit, total, isPortalHidingEnabled } = action.payload;
    /* On help seeker screen */
    if (!action.requestParams.isPortalReorderingSelected) {
        /* On clicking exploreAll */
        if (action.requestParams.exploreAll) {
            return {
                ...state,
                portals: {
                    ...state.portals,
                    ...reduceFeaturedAndSortedPortalsResponse(getPortalsNotAlreadyStored(state.portals, portals)),
                },
                orderedPortalIds: portals.map((portal) => portal.id),
            };
        }
        /* initial load */
        return {
            ...state,
            isPortalHidingEnabled,
            portals: reduceFeaturedAndSortedPortalsResponse(portals),
            orderedPortalIds: portals.map((portal) => portal.id),
            sortBy: sortBy ? sortBy : 'POPULARITY',
            limit: limit ? limit : FEATURED_PORTALS_LIMIT,
            total: total ? total : 0,
            error: undefined,
        };
    }
    /* On Portal reordering screen initial load */
    if (!action.requestParams.sortBy) {
        return {
            ...state,
            isPortalHidingEnabled,
            portals: reduceFeaturedAndSortedPortalsResponse(portals),
            orderedPortalIds: portals.map((portal) => portal.id),
            sortBy: sortBy ? sortBy : 'POPULARITY',
            limit: limit ? limit : FEATURED_PORTALS_LIMIT,
            total: total ? total : 0,
            error: undefined,
        };
    }
    return state;
};

const handleFetchFeaturedAndSortedPortalsFailure = (
    action: FetchFeaturedAndSortedPortalsFailure,
    state: FeaturedAndSortedPortalsState
): FeaturedAndSortedPortalsState => {
    const { error } = action.payload;

    const errorObject = {
        status: error.status,
        message: (error.errorMessages || [])[0],
        callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
        callToActionText: error.nextActionDisplayText || '',
    };

    /* Error in both help center and portal reordering view initial loads */
    if (!action.requestParams.sortBy) {
        return {
            ...state,
            error: errorObject,
        };
    }

    return state;
};

const handleUpdatePortalReorderSettingsSuccess = (
    action: UpdatePortalReorderSettingsSuccess,
    state: FeaturedAndSortedPortalsState
): FeaturedAndSortedPortalsState => {
    const { featuredPortalIds, sortBy, orderedPortalIds, hiddenPortalIds } = action.payload;

    const portals = updateFeaturedPortals(state, featuredPortalIds, orderedPortalIds, hiddenPortalIds);

    const total = Object.values(portals).filter((portal) => !isHiddenPortal(portal)).length;

    return {
        ...state,
        sortBy,
        orderedPortalIds,
        portals,
        total,
    };
};
