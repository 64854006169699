import type {
    ShowFeedbackCollector,
    HideFeedbackCollector,
    UpdateAdditionalFieldsInFeedbackCollector,
} from 'state/actions/feedback-collector';
import {
    SHOW_FEEDACK_COLLECTOR,
    HIDE_FEEDACK_COLLECTOR,
    UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR,
} from 'state/actions/feedback-collector';
import { EnvType } from '@atlassian/help-center-common-util/env';

export interface FeedbackCollectorUIState {
    shouldOpenFeedbackWidget: boolean;
    showTypeField: boolean;
    additionalFields: Record<string, string | object> | Record<string, never>;
    CustomComponent?: React.ComponentClass<{
        onChange: (fieldId: string, fieldValue: string | object) => void;
        additionalFields: Record<string, string | object>;
    }>;
}

const addPortalPrefix = (featureName: string): string => {
    return 'JSM Portal '.concat(featureName);
};

export type HandledActions = ShowFeedbackCollector | HideFeedbackCollector | UpdateAdditionalFieldsInFeedbackCollector;

export const emptyFeedbackCollectorUIState: FeedbackCollectorUIState = {
    shouldOpenFeedbackWidget: false,
    showTypeField: false,
    additionalFields: {},
};

const defaultState: FeedbackCollectorUIState = emptyFeedbackCollectorUIState;

export function feedbackCollectorReducer(
    state: FeedbackCollectorUIState = defaultState,
    action: HandledActions
): FeedbackCollectorUIState {
    switch (action.type) {
        case SHOW_FEEDACK_COLLECTOR:
            return handleFeedbackCollector(state, action);
        case HIDE_FEEDACK_COLLECTOR:
            return handleFeedbackCollector(state, action);
        case UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR:
            return handleUpdateAdditionalFieldsInFeedbackCollector(state, action);
        default:
            return state;
    }
}

const handleFeedbackCollector = (
    state: FeedbackCollectorUIState,
    action: ShowFeedbackCollector | HideFeedbackCollector
): FeedbackCollectorUIState => {
    if (action.type === HIDE_FEEDACK_COLLECTOR) {
        return {
            ...state,
            shouldOpenFeedbackWidget: false,
        };
    }

    const { showTypeField, CustomComponent, featureName, env } = action.payload;

    const newState: FeedbackCollectorUIState = {
        ...state,
        showTypeField,
        CustomComponent,
        shouldOpenFeedbackWidget: true,
    };

    if (env === EnvType.PROD) {
        newState.additionalFields = {
            // Do not change this field as this is an actual custom field in https://jira-feedback.atlassian.net. Changing this will break feedback collector in production.
            customfield_10076: addPortalPrefix(featureName),
        };

        return newState;
    }

    newState.additionalFields = {
        // Do not change this field as this is an actual custom field in https://arajpal.atlassian.net. Changing this will break feedback collector in staging.
        customfield_10094: addPortalPrefix(featureName),
    };

    return newState;
};

const handleUpdateAdditionalFieldsInFeedbackCollector = (
    state: FeedbackCollectorUIState,
    action: UpdateAdditionalFieldsInFeedbackCollector
): FeedbackCollectorUIState => {
    return {
        ...state,
        additionalFields: {
            ...state.additionalFields,
            ...action.payload.additionalFields,
        },
    };
};
