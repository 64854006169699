import type { MessageDescriptor } from 'react-intl-next';
import type {
    ShowErrorFlag,
    ShowSuccessFlag,
    DismissFlag,
    ShowBoldAppearanceFlag,
    ErrorFlagAction,
    SuccessFlagAction,
    AppearanceTypes,
    ActionsType,
} from 'state/actions/flags';
import { SHOW_ERROR_FLAG, SHOW_SUCCESS_FLAG, DISMISS_FLAG, SHOW_BOLD_APPEARANCE_FLAG } from 'state/actions/flags';

const MAX_FLAG_ID = 99999;
type MessageValue = string | number | boolean | Date | null | undefined;

export type FlagType = 'error' | 'success';
export type FlagAction = ErrorFlagAction | SuccessFlagAction;

export interface SuccessFlag {
    id: number;
    titleMessage: MessageDescriptor;
    titleMessageValues?: { [key: string]: MessageValue };
    descriptionMessage?: MessageDescriptor;
    descriptionMessageValues?: { [key: string]: MessageValue };
    actions: SuccessFlagAction[];
    type: 'success';
}

export interface ErrorFlag {
    id: number;
    titleMessage: MessageDescriptor;
    titleMessageValues?: { [key: string]: MessageValue };
    descriptionMessage?: MessageDescriptor;
    descriptionMessageValues?: { [key: string]: MessageValue };
    actions: ErrorFlagAction[];
    type: 'error';
}

export interface BoldAppearanceFlag {
    id: number;
    appearance: AppearanceTypes;
    titleMessage: MessageDescriptor;
    titleMessageValues?: { [key: string]: MessageValue };
    descriptionMessage?: MessageDescriptor;
    descriptionMessageValues?: { [key: string]: MessageValue };
    dismissActionValue?: MessageDescriptor;
    actions: ActionsType;
    type: 'bold';
}

export type Flag = SuccessFlag | ErrorFlag | BoldAppearanceFlag;

export interface FlagsUIState {
    flags: Flag[];
}

export type HandledActions = ShowErrorFlag | ShowSuccessFlag | DismissFlag | ShowBoldAppearanceFlag;

const defaultState: FlagsUIState = {
    flags: [],
};

export function flagsReducer(state: FlagsUIState = defaultState, action: HandledActions): FlagsUIState {
    switch (action.type) {
        case SHOW_ERROR_FLAG:
            return handleShowErrorFlag(state, action);
        case SHOW_SUCCESS_FLAG:
            return handleShowSuccessFlag(state, action);
        case DISMISS_FLAG:
            return handleFlagDismissal(state, action);
        case SHOW_BOLD_APPEARANCE_FLAG:
            return handleShowBoldAppearanceFlag(state, action);
        default:
            return state;
    }
}

function handleShowErrorFlag(state: FlagsUIState, action: ShowErrorFlag): FlagsUIState {
    const updatedFlags = [...state.flags];
    updatedFlags.unshift({
        id: generateFlagId(),
        titleMessage: action.payload.titleMessage,
        titleMessageValues: action.payload.titleMessageValues,
        descriptionMessage: action.payload.descriptionMessage,
        descriptionMessageValues: action.payload.descriptionMessageValues,
        actions: action.payload.actions || [],
        type: 'error',
    });

    return {
        ...state,
        flags: updatedFlags,
    };
}

function handleShowSuccessFlag(state: FlagsUIState, action: ShowSuccessFlag): FlagsUIState {
    const updatedFlags = [...state.flags];
    updatedFlags.unshift({
        id: generateFlagId(),
        titleMessage: action.payload.titleMessage,
        titleMessageValues: action.payload.titleMessageValues,
        descriptionMessage: action.payload.descriptionMessage,
        descriptionMessageValues: action.payload.descriptionMessageValues,
        actions: action.payload.actions || [],
        type: 'success',
    });

    return {
        ...state,
        flags: updatedFlags,
    };
}

function handleShowBoldAppearanceFlag(state: FlagsUIState, action: ShowBoldAppearanceFlag): FlagsUIState {
    const updatedFlags = [...state.flags];

    updatedFlags.unshift({
        id: generateFlagId(),
        appearance: action.payload.appearance,
        titleMessage: action.payload.titleMessage,
        titleMessageValues: action.payload.titleMessageValues,
        descriptionMessage: action.payload.descriptionMessage,
        descriptionMessageValues: action.payload.descriptionMessageValues,
        actions: action.payload.actions || [],
        dismissActionValue: action.payload.dismissActionValue,
        type: 'bold',
    });

    return {
        ...state,
        flags: updatedFlags,
    };
}

const generateFlagId = () =>
    // Integer between 0 and MAX_FLAG_ID
    Math.floor(Math.random() * Math.floor(MAX_FLAG_ID));

function handleFlagDismissal(state: FlagsUIState, action: DismissFlag): FlagsUIState {
    const updatedFlags = state.flags.filter((flag) => flag.id !== action.payload.flagId);
    return {
        ...state,
        flags: updatedFlags,
    };
}
