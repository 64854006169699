import type { ActionsObservable } from 'redux-observable';
import type { Epic } from 'epics/rxjs';
import { concat, Observable } from 'epics/rxjs';
import type { ShowSuccessFlag } from 'state/actions/flags';
import { showSuccessFlag } from 'state/actions/flags';
import type { ExitTestModeAction, ResetLogin } from 'state/actions/login';
import { EXIT_TEST_MODE_ACTION, resetLogin } from 'state/actions/login';
import { history } from '@atlassian/help-center-common-util/history';
import { getLoginUrl } from '@atlassian/help-center-common-util/url';
import messages from './messages';

export const exitTestModeEpic: Epic<ExitTestModeAction, ShowSuccessFlag | ResetLogin> = (
    action$: ActionsObservable<ExitTestModeAction>
) => {
    return action$.ofType(EXIT_TEST_MODE_ACTION).mergeMap((exitTestModeAction) => {
        const { email } = exitTestModeAction.payload;
        return concat(
            Observable.of(resetLogin(email)),
            Observable.of(
                showSuccessFlag(
                    messages.exitTestModeSuccessFlagTitle,
                    undefined,
                    messages.exitTestModeSuccessFlagDescription,
                    undefined,
                    { emailAddress: email }
                )
            )
        ).do(() => {
            history.push(getLoginUrl({ isAbsolute: false, portalId: undefined }));
        });
    });
};
