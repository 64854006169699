import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export const RESET_PASSWORD = 'state.persisted.RESET_PASSWORD';
export const RESET_PASSWORD_FAILURE = 'state.persisted.RESET_PASSWORD_FAILURE';

export interface ResetPassword {
    payload: {
        emailAddress: string;
        password: string;
        token: string;
    };
    type: typeof RESET_PASSWORD;
}

export const resetPassword = (emailAddress: string, password: string, token: string): ResetPassword => ({
    payload: {
        emailAddress,
        password,
        token,
    },
    type: RESET_PASSWORD,
});

export interface ResetPasswordFailure {
    payload: {
        error: DefaultError;
    };
    type: typeof RESET_PASSWORD_FAILURE;
}

export const resetPasswordFailure = (error: DefaultError): ResetPasswordFailure => ({
    payload: {
        error,
    },
    type: RESET_PASSWORD_FAILURE,
});
