import * as React from 'react';

/**
 * A React hook to track if a component is still mounted
 * This is done to avoid calling setState or equivalent
 * When component has been unmounted
 * Otherwise, can lead to memory leaks
 */

export function useMounted(): boolean {
    const referenceToMounted = React.useRef(false);

    React.useEffect(() => {
        if (!referenceToMounted.current) {
            referenceToMounted.current = true;
        }

        return () => {
            if (referenceToMounted.current) {
                referenceToMounted.current = false;
            }
        };
    }, []);

    return referenceToMounted.current;
}

/**
 * A React hook to check previous value before change
 */

export function usePrevious<T>(value: T): T | undefined {
    const referenceToValue = React.useRef<T>();

    React.useEffect(() => {
        referenceToValue.current = value;
    }, [value]);

    return referenceToValue.current;
}

/**
 * A React hook to replace useLayoutEffect with useEffect on server
 */
export const useIsoMorphicLayoutEffect = __SERVER__ ? React.useEffect : React.useLayoutEffect;
