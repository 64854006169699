import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { BodyClassName } from '@atlassian/help-center-common-component/body-class-name';
import { windowInnerWidth, bodyClientWidth } from './sizes';

interface State extends Props {
    show: boolean;
}

interface Props {
    scrollbarWidth: number;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const DisableBodyScroll = styled(BodyClassName)`
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding-right: ${(props: Props) => props.scrollbarWidth}px;
`;

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line @typescript-eslint/ban-types
export default class ScrollLock extends React.Component<{}, State> {
    state = {
        scrollbarWidth: 0,
        show: false,
    };

    componentDidMount() {
        if (!document.body) {
            return;
        }

        const scrollbarWidth = windowInnerWidth() - bodyClientWidth();

        this.setState({
            scrollbarWidth,
            show: true,
        });
    }

    render() {
        return this.state.show ? <DisableBodyScroll scrollbarWidth={this.state.scrollbarWidth} /> : null;
    }
}
