import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import Breadcrumbs from '@atlaskit/breadcrumbs';
import type { InjectedProps as PathParamProps } from '@atlassian/help-center-common-component/with-path-params';
import { isCSMHelpCenter } from '@atlassian/help-center-common-util/advanced-help-center';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { getHelpCenterUrl } from '@atlassian/help-center-common-util/url';
import { BreadCrumb } from '../bread-crumb-dumb';
import messages from './messages';

interface SelectProps {
    sharedPortalName: string;
    portalName?: string | undefined;
    categoryName?: string | undefined;
    articleTitle?: string | undefined;
    requestKey?: string | undefined;
    requestUrl?: string | undefined;
    showRequests?: boolean;
}

interface AdditionalBreadCrumbsProps {
    additionalBreadCrumbs?: React.ReactElement[];
}

export type ExternalProps = PathParamProps;

export const BreadCrumbsDumb: React.FunctionComponent<
    SelectProps & ExternalProps & AdditionalBreadCrumbsProps & WrappedComponentProps
> = ({
    sharedPortalName,
    portalName,
    categoryName,
    articleTitle,
    portalId,
    categoryId,
    articleId,
    showRequests,
    intl,
    requestKey,
    requestUrl,
    additionalBreadCrumbs,
}) => {
    const requestCreateBreadcrumb = showRequests ? intl.formatMessage(messages.raiseRequestBreadcrumb) : undefined;
    di(isCSMHelpCenter);

    const isCSM = isCSMHelpCenter(getEnv().helpCenterType);

    return (
        <Breadcrumbs label={intl.formatMessage(messages.breadcrumbsLabel)}>
            <BreadCrumb
                to={getHelpCenterUrl()}
                name={!isCSM ? sharedPortalName : intl.formatMessage(messages.breadcrumbsHome)}
                isFirstCrumb
                actionSubjectId="helpCenterBreadcrumb"
            >
                {!isCSM && (
                    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    <BreadCrumb to={`/portal/${portalId}`} name={portalName} actionSubjectId="portalBreadcrumb">
                        <BreadCrumb
                            to={requestUrl || ''}
                            name={requestKey}
                            actionSubjectId="requestBreadcrumb"
                            testId="requestKey"
                        />
                        <BreadCrumb
                            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            to={`/portal/${portalId}/group/-1`}
                            name={requestCreateBreadcrumb}
                            actionSubjectId="requestCreateBreadcrumb"
                        />
                        <BreadCrumb
                            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            to={`/portal/${portalId}/topic/${categoryId}`}
                            name={categoryName}
                            actionSubjectId="categoryBreadcrumb"
                        >
                            <BreadCrumb
                                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                to={`/portal/${portalId}/topic/${categoryId}/article/${articleId}`}
                                name={articleTitle}
                                actionSubjectId="articleBreadcrumb"
                            />
                        </BreadCrumb>
                        <BreadCrumb
                            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            to={`/portal/${portalId}/article/${articleId}`}
                            name={articleTitle}
                            actionSubjectId="articleBreadcrumb"
                        />
                    </BreadCrumb>
                )}
                {/*TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.*/}
                {/*eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <BreadCrumb to={`/article/${articleId}`} name={articleTitle} actionSubjectId="articleBreadcrumb" />
                {additionalBreadCrumbs?.map((breadCrumb) => breadCrumb)}
            </BreadCrumb>
        </Breadcrumbs>
    );
};

export default injectIntl(BreadCrumbsDumb);
