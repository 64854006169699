import * as React from 'react';
import { findDOMNode } from 'react-dom';

interface AutoFocusProps {
    isEnabled?: boolean;
    children: React.ReactNode;
}

/**
 * This component focuses on the child component.
 * This will focus on the child component once it's first available in the DOM.
 * The focus will happen once and once only.
 * The child component must:
 *     * contain a single focusable HTML element
 */
export class AutoFocus extends React.Component<AutoFocusProps> {
    static defaultProps = {
        isEnabled: true,
    };

    focused: boolean;

    componentDidMount() {
        const { isEnabled } = this.props;
        // If we haven't yet triggered the focus we will focus on the ref if available
        if (isEnabled && !this.focused) {
            // eslint-disable-next-line react/no-find-dom-node
            const el = findDOMNode(this) as HTMLElement;
            if (el) {
                el.focus();
                this.focused = true;
            }
        }
    }

    render() {
        const { children } = this.props;
        return React.Children.only(children);
    }
}
