import * as React from 'react';

import type { dismissFlag } from 'state/actions/flags';
import type { Flag } from 'state/ui/flags';
import { FlagGroupStateless } from './flag-group-stateless';

interface FlagGroupProps {
    flags: Flag[];
    dismissFlagAction: typeof dismissFlag;
}

export default class FlagGroup extends React.Component<FlagGroupProps> {
    render() {
        const { flags, dismissFlagAction } = this.props;
        return <FlagGroupStateless flags={flags} dismissFlagAction={dismissFlagAction} />;
    }
}
