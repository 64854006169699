import { createActionsHook, createHook, createStore } from 'react-sweet-state';
import { actions } from './actions';
import type { SubscriptionStore } from './types';

const initialState: SubscriptionStore = {
    subscription: {
        dispose: () => undefined,
    },
};

const store = createStore({
    actions,
    initialState,
    name: 'OmnichannelSubscriptionStore',
});

export const useSubscriptionStore = createHook(store);
export const useSubscriptionAction = createActionsHook(store);
