/**
 * Copied from https://github.com/alexreardon/memoize-one/tree/master/src
 *
 *
 * Delete this folder when https://github.com/alexreardon/memoize-one/issues/37 is resolved and we can use the package directly
 * turning on esModuleInterop breaks a whole bunch of other tests, so for now this is the least intrusive workaround to get this utility
 *
 */

function areInputsEqual(newInputs: readonly unknown[], lastInputs: readonly unknown[]): boolean {
    // no checks needed if the inputs length has changed
    if (newInputs.length !== lastInputs.length) {
        return false;
    }
    // Using for loop for speed. It generally performs better than array.every
    // https://github.com/alexreardon/memoize-one/pull/59

    for (let i = 0; i < newInputs.length; i++) {
        // using shallow equality check
        if (newInputs[i] !== lastInputs[i]) {
            return false;
        }
    }
    return true;
}

// Using ReadonlyArray<T> rather than readonly T as it works with TS v3
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EqualityFn = (newArgs: any[], lastArgs: any[]) => boolean;

export default function memoizeOne<
    // Need to use 'any' rather than 'unknown' here as it has
    // The correct Generic narrowing behaviour.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ResultFn extends (this: any, ...newArgs: any[]) => ReturnType<ResultFn>,
>(resultFn: ResultFn, isEqual: EqualityFn = areInputsEqual): ResultFn {
    let lastThis: unknown;
    let lastArgs: unknown[] = [];
    let lastResult: ReturnType<ResultFn>;
    let calledOnce: boolean = false;

    // breaking cache when context (this) or arguments change
    function memoized(this: unknown, ...newArgs: unknown[]): ReturnType<ResultFn> {
        if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
            return lastResult;
        }

        // Throwing during an assignment aborts the assignment: https://codepen.io/alexreardon/pen/RYKoaz
        // Doing the lastResult assignment first so that if it throws
        // nothing will be overwritten
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        lastResult = resultFn.apply(this, newArgs);
        calledOnce = true;
        // @typescript-eslint upgrade to v5.61.0
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        lastThis = this;
        lastArgs = newArgs;
        return lastResult;
    }

    return memoized as ResultFn;
}
