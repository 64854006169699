// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const ICON_SIZE_MOBILE = grid.multiple(4.5).unitless;
const ICON_SIZE_DESKTOP = grid.multiple(6.25).unitless;
const MARGIN_RIGHT = token('space.200', '16px');

const SUBHEADING_MARGIN_TOP_MOBILE = token('space.200', '16px');
const SUBHEADING_MARGIN_TOP_DESKTOP = token('space.100', '8px');
const DESCRIPTION_MARGIN_TOP_MOBILE = token('space.100', '8px');

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const IconContainer = styled.div`
    ${mixins.flexCenter};
    height: ${ICON_SIZE_MOBILE}px;
    width: ${ICON_SIZE_MOBILE}px;
    margin-right: ${MARGIN_RIGHT};
    flex-shrink: 0;

    ${bp.fromXSmall.css`
        height: ${ICON_SIZE_DESKTOP}px;
        width: ${ICON_SIZE_DESKTOP}px;
    `};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SubHeadingContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: ${SUBHEADING_MARGIN_TOP_MOBILE};

    ${bp.fromXSmall.css`
        margin-top: ${SUBHEADING_MARGIN_TOP_DESKTOP};
    `};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SubHeading = styled.h1`
    ${fonts.h700};
    ${mixins.breakWord};
    margin: 0;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Description = styled.div`
    display: block;
    color: ${token('color.text', colors.N800)};
    margin-top: ${DESCRIPTION_MARGIN_TOP_MOBILE};
    width: 100%;
`;
