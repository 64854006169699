import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type { ShowErrorFlag } from 'state/actions/flags';
import { showErrorFlag } from 'state/actions/flags';
import type {
    FetchTimezonesAction,
    FetchTimezoneRegionsAction,
    FetchTimezoneRegionsSuccess,
    FetchTimezonesSuccess,
} from 'state/actions/timezones';
import {
    FETCH_TIMEZONES_MODEL,
    FETCH_TIMEZONE_REGIONS_MODEL,
    fetchTimezoneRegionsSuccess,
    fetchTimezonesSuccess,
} from 'state/actions/timezones';
import { genericError } from '@atlassian/help-center-common-util/errors';
import type { ErrorResponse } from 'epics/model/types';

export const timezonesEpic: Epic<FetchTimezonesAction, FetchTimezonesSuccess | ShowErrorFlag> = (action$) => {
    return action$.ofType(FETCH_TIMEZONES_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.timezones)
            .map((response) => {
                return fetchTimezonesSuccess(response.timezones || []);
            })
            .catch((_: ErrorResponse) => {
                return Observable.of(showErrorFlag(genericError.title, genericError.description));
            })
    );
};

export const timezoneRegionsEpic: Epic<FetchTimezoneRegionsAction, FetchTimezoneRegionsSuccess | ShowErrorFlag> = (
    action$
) => {
    return action$.ofType(FETCH_TIMEZONE_REGIONS_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.timezoneRegions)
            .map((response) => {
                return fetchTimezoneRegionsSuccess(response.timezoneRegions || []);
            })
            .catch((_: ErrorResponse) => {
                return Observable.of(showErrorFlag(genericError.title, genericError.description));
            })
    );
};
