import * as React from 'react';
import type { ConnectPageName } from 'rest/connect-addon';
import { ConnectFragment, HEADER, SUB_HEADER } from '@atlassian/help-center-common-component/connect-fragment';

interface Props {
    page: ConnectPageName;
    portalId?: number;
    requestTypeId?: number;
    requestKey?: string;
}

const HeaderConnectFragments = ({ page, portalId, requestTypeId, requestKey }: Props) => (
    <>
        <ConnectFragment
            page={page}
            location={HEADER}
            portalId={portalId}
            requestTypeId={requestTypeId}
            requestKey={requestKey}
        />
        <ConnectFragment
            page={page}
            location={SUB_HEADER}
            portalId={portalId}
            requestTypeId={requestTypeId}
            requestKey={requestKey}
        />
    </>
);

export default HeaderConnectFragments;
