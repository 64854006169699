import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { showFeedbackCollector } from 'state/actions/feedback-collector';
import { dismissFlag } from 'state/actions/flags';
import { getFlags } from 'state/selectors/flags';
import { getEnv } from '@atlassian/help-center-common-util/env';
import { FeedbackCollectorButton } from './feedback-collector-button';

const selector = createSelector(getFlags, getEnv, (flags, env) => ({
    flags,
    env: env.environment,
}));

const mapDispatchToProps = {
    showFeedbackCollector,
    dismissFlag,
};

export default connect(selector, mapDispatchToProps)(injectIntl(FeedbackCollectorButton));
