import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { GenericErrorPage } from 'view/error-boundary/generic-error-page';
import messages from './messages';

export const Error = () => {
    const { formatMessage } = useIntl();
    return (
        <GenericErrorPage
            title={<FormattedMessage {...messages.errorTitle} />}
            message={
                <>
                    {formatMessage(messages.errorDescriptionText, {
                        a: (chunks: ReactNode) => <a href="/servicedesk">{chunks}</a>,
                    })}
                </>
            }
            hideFooter
        />
    );
};
