import type { Epic } from 'epics/rxjs';
import type { SearchAction, ShowSearchAction, UpdateSearchAction } from 'state/actions/search';
import { SEARCH, SHOW_SEARCH, UPDATE_SEARCH } from 'state/actions/search';
import * as qs from '@atlassian/help-center-common-util/history-qs';
import { context as fromLocationContextKey } from '../from-location';

type SearchActions = SearchAction | ShowSearchAction | UpdateSearchAction;

const isValidSearchAction = (action: SearchActions) =>
    (action.type === SHOW_SEARCH || action.type === SEARCH || action.type === UPDATE_SEARCH) &&
    action.payload.context !== fromLocationContextKey;

const setSearchQueryParams = (term?: string, replaceRoute: boolean = false) => {
    qs.set(
        {
            q: term,
        },
        replaceRoute
    );
};

const epic: Epic<SearchActions> = (action$, store) => {
    return action$
        .filter(isValidSearchAction)
        .do((action) => {
            if ((action.type === SEARCH || action.type === UPDATE_SEARCH) && action.payload.show) {
                const shouldReplaceRoute = qs.get('q') !== undefined;
                setSearchQueryParams(action.payload.term, shouldReplaceRoute);
                return;
            }

            if (action.type === SHOW_SEARCH) {
                if (action.payload.show) {
                    const state = store.getState();

                    if (state.ui.search.term) {
                        setSearchQueryParams(state.ui.search.term);
                        return;
                    }

                    setSearchQueryParams('');
                    return;
                }

                setSearchQueryParams(undefined);
            }
        })
        .ignoreElements();
};

export default epic;
