import * as React from 'react';
import type { fetchArticlesAction } from 'epics/model/kb-article';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { Category } from 'rest/category';
import type { fetchPortalAction } from 'state/actions/portal';
import { PaperContent } from 'view/layout/paper';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { AnalyticsContext, OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { DocumentTitle } from '@atlassian/help-center-common-component/document-title';
import { withErrorPage } from '@atlassian/help-center-common-component/error-page';
import { LoadingContainer } from '@atlassian/help-center-common-component/loading-container';
import { PaperHeader } from '@atlassian/help-center-common-component/paper-header';
import { ArticleList } from './article-list';
import messages from './messages';
import type { Article } from 'state/persisted/kb-article/types';

export interface KnowledgeCategoryProps extends WithAnalyticsEventsProps {
    portalName: string | undefined;
    portalId: number | undefined;
    articles: Article[] | undefined;
    category: Category | undefined;
    isArticlesLoading: boolean;
    portalLink: string;
    isLoading: boolean;
    shouldFetchPortalOnMount: boolean;
    categoryId: string | undefined;
    projectId: number | undefined;
    showRequestCreateButton: boolean;
    fetchPortal: typeof fetchPortalAction;
    fetchArticles: typeof fetchArticlesAction;
}

export class KnowledgeCategory extends React.Component<KnowledgeCategoryProps & WrappedComponentProps> {
    componentDidMount() {
        const { shouldFetchPortalOnMount, fetchPortal, fetchArticles, portalId, projectId, categoryId, category } =
            this.props;

        if (shouldFetchPortalOnMount && portalId) {
            fetchPortal({ id: portalId, expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] });
        }

        if (portalId && projectId && categoryId) {
            const meta = this.getFailureAnalyticsEvent();
            fetchArticles({ projectId, portalId, categoryId, meta, categoryDataRequired: !category });
        }
    }

    componentDidUpdate(prevProps: KnowledgeCategoryProps) {
        const { fetchArticles, portalId, projectId, categoryId, category } = this.props;

        const shouldFetchArticles = !prevProps.projectId && projectId;

        if (shouldFetchArticles && projectId && portalId && categoryId) {
            const meta = this.getFailureAnalyticsEvent();
            fetchArticles({ projectId, portalId, categoryId, meta, categoryDataRequired: !category });
        }
    }

    getFailureAnalyticsEvent = () => {
        const { createAnalyticsEvent } = this.props;

        return {
            analyticsFailureEvent: createAnalyticsEvent?.({
                action: 'articlesFetch failed',
                analyticsType: OPERATIONAL_EVENT_TYPE,
            }),
        };
    };

    renderInnerContent() {
        di(AnalyticsContext, PaperContent, PaperHeader, ArticleList);
        const { category, articles, portalId, isArticlesLoading, portalName, portalLink, showRequestCreateButton } =
            this.props;
        if (!category || !portalId || !portalName) {
            return null;
        }

        const attributes = (articles || []).reduce(
            (attr, article) => ({
                featured: attr.featured + (article.isFeatured ? 1 : 0),
                total: attr.total + 1,
            }),
            { featured: 0, total: 0 }
        );

        return (
            <AnalyticsContext attributes={attributes}>
                <PaperContent>
                    <PaperHeader
                        subHeading={category.name}
                        description={category.description}
                        portalId={portalId}
                        categoryId={category.id}
                    />

                    <ArticleList
                        portalLink={portalLink}
                        portalId={portalId}
                        portalName={portalName}
                        categoryId={category.id}
                        articles={articles}
                        showRequestCreateButton={showRequestCreateButton}
                        isLoading={isArticlesLoading}
                    />
                </PaperContent>
            </AnalyticsContext>
        );
    }

    render() {
        di(LoadingContainer);
        const { category, portalName, intl, isLoading } = this.props;
        const pageTitle =
            category && portalName
                ? intl.formatMessage(messages.categoryDocumentTitle, { portalName, categoryName: category.name })
                : undefined;

        return (
            <DocumentTitle title={pageTitle}>
                <LoadingContainer isLoading={isLoading}>{this.renderInnerContent()}</LoadingContainer>
            </DocumentTitle>
        );
    }
}

export default withErrorPage(
    injectIntl(withAnalyticsContext({ componentName: 'KbCategory' })(withAnalyticsEvents()(KnowledgeCategory)))
);
