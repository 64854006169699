import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type { ShowErrorFlag } from 'state/actions/flags';
import { showErrorFlag } from 'state/actions/flags';
import type { FetchLanguagesAction, FetchLanguagesSuccess } from 'state/actions/languages';
import { FETCH_LANGUAGES_MODEL, fetchLanguagesSuccess } from 'state/actions/languages';
import { genericError } from '@atlassian/help-center-common-util/errors';
import type { ErrorResponse } from 'epics/model/types';

export const languagesEpic: Epic<FetchLanguagesAction, FetchLanguagesSuccess | ShowErrorFlag> = (action$) => {
    return action$.ofType(FETCH_LANGUAGES_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.availableLanguages)
            .map((response) => {
                return fetchLanguagesSuccess(response.availableLanguages || []);
            })
            .catch((_: ErrorResponse) => {
                return Observable.of(showErrorFlag(genericError.title, genericError.description));
            })
    );
};
