import * as React from 'react';
import type { FlipperProps, FlippedProps } from 'react-flip-toolkit';
import { Flipper as ReactFlipper, Flipped as ReactFlipped } from 'react-flip-toolkit';

export const Flipper: React.FC<FlipperProps> = (props) => (
    <ReactFlipper {...props} spring={{ stiffness: 600, damping: 40 }}>
        {props.children}
    </ReactFlipper>
);

export const Flipped: React.FC<FlippedProps> = (props) => <ReactFlipped {...props}>{props.children}</ReactFlipped>;
