import type { FetchVisitPortalSuccess, FetchVisitPortalFailure } from 'state/actions/invited-sign-up';
import { FETCH_VISIT_PORTAL_SUCCESS, FETCH_VISIT_PORTAL_FAILURE } from 'state/actions/invited-sign-up';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface VisitPortalState {
    loggedInBeforeCheck: boolean;
    email: string;
    portalName: string;
    initialError?: PersistedError;
}

export type HandledActions = FetchVisitPortalSuccess | FetchVisitPortalFailure;

const defaultState: VisitPortalState = {
    loggedInBeforeCheck: false,
    email: '',
    portalName: '',
    initialError: undefined,
};

export const getInitialState = (): VisitPortalState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.visitPortal) {
        return initialModelState.visitPortal;
    }
    return defaultState;
};

export function visitPortalReducer(
    state: VisitPortalState = getInitialState(),
    action: HandledActions
): VisitPortalState {
    switch (action.type) {
        case FETCH_VISIT_PORTAL_SUCCESS:
            return handleFetchVisitPortalSuccess(state, action);
        case FETCH_VISIT_PORTAL_FAILURE:
            return handleFetchVisitPortalFailure(state, action);
        default:
            return state;
    }
}

function handleFetchVisitPortalSuccess(state: VisitPortalState, action: FetchVisitPortalSuccess): VisitPortalState {
    const response = action.payload;
    if (!response) {
        return state;
    }

    return {
        ...state,
        loggedInBeforeCheck: response.loggedInBeforeCheck,
        email: response.email,
        portalName: response.portalName,
    };
}

function handleFetchVisitPortalFailure(state: VisitPortalState, action: FetchVisitPortalFailure): VisitPortalState {
    const { error } = action.payload;

    return {
        ...state,
        initialError: {
            error: {
                status: error.status,
                message: (error.errorMessages || [])[0],
                // We need to remove basename, see https://jdog.jira-dev.com/browse/FSD-2557
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            },
        },
    };
}
