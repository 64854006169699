import type { ReqTypesState } from 'state/persisted/portal';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const DELETE_REQUEST_TYPE_FROM_GROUP = 'epic.request-types.DELETE_REQUEST_TYPE_FROM_GROUP';

export interface DeleteRequestTypeFromGroupPayload {
    isProjectSimplified: boolean;
    portalId: number;
    projectId: number;
    requestTypes: ReqTypesState[];
    requestGroupId: number;
    requestType: ReqTypesState;
    index: number;
    analyticsEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}

export interface DeleteRequestTypeFromGroupAction {
    type: typeof DELETE_REQUEST_TYPE_FROM_GROUP;
    payload: DeleteRequestTypeFromGroupPayload;
}

export const deleteRequestTypeFromGroupAction = (
    payload: DeleteRequestTypeFromGroupPayload
): DeleteRequestTypeFromGroupAction => ({ payload, type: DELETE_REQUEST_TYPE_FROM_GROUP });
