/**
 * @generated SignedSource<<e67adb56c6bb355de506f8e059e2b4e5>>
 * @relayHash da0c9595f56db7cf97a352c0fe8277b4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4b7883769238bba805019b6324fac9ba641e017f9e265528d401031a571fd364

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type topicsQuery$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type topicsQuery$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly __typename: "QueryError";
      readonly extensions?: ReadonlyArray<{
        readonly statusCode: number | null | undefined;
      }> | null | undefined;
      readonly message?: string | null | undefined;
      readonly topics?: ReadonlyArray<{
        readonly items: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly data: {
                readonly __typename: string;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly properties: AGG$JSON | null | undefined;
        readonly topicId: string;
        readonly " $fragmentSpreads": FragmentRefs<"topicFragment">;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type topicsQuery = {
  response: topicsQuery$data;
  variables: topicsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "topicId"
},
v5 = {
  "kind": "ScalarField",
  "name": "properties"
},
v6 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v7 = {
  "kind": "ScalarField",
  "name": "message"
},
v8 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v9 = {
  "kind": "ScalarField",
  "name": "title"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayLink"
},
v11 = {
  "kind": "ScalarField",
  "name": "entityKey"
},
v12 = [
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "topicsQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterTopic",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "FragmentSpread",
                        "name": "topicFragment"
                      },
                      {
                        "concreteType": "HelpCenterTopicItemConnection",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "HelpCenterTopicItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "HelpCenterTopicItem",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ],
                "type": "QueryError"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "topicsQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "HelpCenterTopic",
                    "kind": "LinkedField",
                    "name": "topics",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "name"
                      },
                      {
                        "concreteType": "HelpCenterTopicItemConnection",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "HelpCenterTopicItemEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "concreteType": "HelpCenterTopicItem",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "kind": "TypeDiscriminator",
                                        "abstractKey": "__isHelpCenterHelpObject"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          {
                                            "concreteType": "HelpObjectStoreIcon",
                                            "kind": "LinkedField",
                                            "name": "icon",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "name": "iconUrlV2"
                                              }
                                            ]
                                          },
                                          (v11/*: any*/)
                                        ],
                                        "type": "HelpObjectStoreRequestForm"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v12/*: any*/),
                                        "type": "HelpObjectStoreArticle"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v12/*: any*/),
                                        "type": "HelpObjectStoreChannel"
                                      },
                                      (v13/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "name": "ari"
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ]
                  }
                ],
                "type": "QueryError"
              },
              (v13/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "4b7883769238bba805019b6324fac9ba641e017f9e265528d401031a571fd364",
    "metadata": {},
    "name": "topicsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6c3fee571c0b47bf0526627e402d85f8";

export default node;
