import type { ErrorResponse } from 'epics/model/types';

export const FETCH_FORGOT_PASSWORD_SUCCESS = 'state.persisted.forgot.password.FETCH_FORGOT_PASSWORD_SUCCESS';
export const FETCH_FORGOT_PASSWORD_FAILURE = 'state.persisted.forgot.password.FETCH_FORGOT_PASSWORD_FAILURE';

export interface ForgotPasswordResponse {
    resetUrl: string;
}

export interface FetchForgotPasswordModelSuccess {
    type: typeof FETCH_FORGOT_PASSWORD_SUCCESS;
    payload: ForgotPasswordResponse;
}

export const fetchForgotPasswordModelSuccess = (
    forgotPasswordModel: ForgotPasswordResponse
): FetchForgotPasswordModelSuccess => ({
    payload: forgotPasswordModel,
    type: FETCH_FORGOT_PASSWORD_SUCCESS,
});

export interface FetchForgotPasswordModelFailure {
    type: typeof FETCH_FORGOT_PASSWORD_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchForgotPasswordModelFailure = (error: ErrorResponse): FetchForgotPasswordModelFailure => ({
    type: FETCH_FORGOT_PASSWORD_FAILURE,
    payload: {
        error,
    },
});
