import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import type { ConnectPageName } from 'rest/connect-addon';
import { ConnectFragment, FOOTER } from '@atlassian/help-center-common-component/connect-fragment';

interface Props {
    page: ConnectPageName;
    portalId?: number;
    requestTypeId?: number;
    requestKey?: string;
}

const FooterConnectFragments = ({ page, portalId, requestTypeId, requestKey }: Props) => (
    <ConnectFooterContainer>
        <ConnectFragment
            page={page}
            location={FOOTER}
            portalId={portalId}
            requestTypeId={requestTypeId}
            requestKey={requestKey}
        />
    </ConnectFooterContainer>
);

export default FooterConnectFragments;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const ConnectFooterContainer = styled.div`
    padding-top: 0;
    padding-bottom: 0;
`;
