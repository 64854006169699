import * as React from 'react';
import { useEffect } from 'react';
import type { History, LocationState } from 'history';
import { Redirect as ResourceRouterRedirect, withRouter } from 'react-resource-router';

export const Redirect = (props: React.ComponentProps<typeof ResourceRouterRedirect>) => {
    return <ResourceRouterRedirect {...props} />;
};

interface PushRedirectWithStateProps {
    to: string;
    state: LocationState;
    history?: History;
}

/**
 * This component exist for migration purposes only, after switching to RRR,
 * we should consider using react-sweat-state to share data between the redirect.
 */
export const PushRedirectWithState: React.FC<PushRedirectWithStateProps> = withRouter(({ history, to, state }) => {
    useEffect(() => {
        // @ts-ignore the history is provided by the router
        history.push(to, state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [to, state]);
    return null;
});

interface PushRedirectProps {
    to: string;
    history?: History;
}

/**
 * This component exist for migration purposes only, after switching to RRR,
 * we should consider using react-sweat-state to share data between the redirect.
 */
export const PushRedirect: React.FC<PushRedirectProps> = withRouter(({ history, to }) => {
    useEffect(() => {
        // @ts-ignore the history is provided by the router
        history.push(to);
    }, [history, to]);
    return null;
});
