import { isAdvancedCustomizationEnabled, isDefaultHCCallsJiraEnabled, isJsmCsMasterFlagEnabled } from 'feature-flags';
import {
    UNIFIED_HELP_CENTER_BASE_PATH,
    HELP_CENTERS_MANAGEMENT_BASE_PATH,
} from '@atlassian/help-center-common-component/constants';
import { getRelativeBasePath } from '@atlassian/help-center-common-util/history';

export const isAdvancedHelpCenter = () => {
    return getRelativeBasePath().startsWith('/helpcenter') && !isUnifiedHelpHelpCenter();
};

export const isFlexiHCEnabledInDefaultHC = () => {
    return isDefaultHelpCenter() && isAdvancedCustomizationEnabled();
};

export const isDefaultHelpCenter = () => {
    return getRelativeBasePath().startsWith('/servicedesk/customer');
};

export const isKoshEnabledForDefaultHelpCenter = () => {
    if (isDefaultHCCallsJiraEnabled() && isDefaultHelpCenter()) {
        return false;
    }
    return true;
};

export const isUnifiedHelpHelpCenter = () => {
    return getRelativeBasePath() === UNIFIED_HELP_CENTER_BASE_PATH;
};

export const isHelpCentersManagement = () => {
    return getRelativeBasePath() === HELP_CENTERS_MANAGEMENT_BASE_PATH;
};

export const isCSMHelpCenter = (helpCenterType: string) => {
    return isJsmCsMasterFlagEnabled() && helpCenterType === 'CUSTOMER_SERVICE';
};

export const getSlugForAdvancedHCFromArticleView = (url: string) => {
    const pattern = /https:\/\/.*?\/helpcenter\/([^/]+)\/portal\//;
    const match = url.match(pattern);
    return match && match[1] ? match[1] : undefined;
};

export const getSlugForHelpCenter = () => {
    const pattern = /\/helpcenter\/([^/?]*)/;
    const match = getRelativeBasePath().match(pattern);
    return match && match[1] ? match[1] : undefined;
};
