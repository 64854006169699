export const LOGIN_CONSENT = 'state.ui.LOGIN_CONSENT';

export interface LoginConsentAction {
    payload: {
        param: string;
    };
    type: typeof LOGIN_CONSENT;
}

export const loginConsentAction = (param: string): LoginConsentAction => {
    return {
        payload: {
            param,
        },
        type: LOGIN_CONSENT,
    };
};
