/**
 * Unsupported browsers regex generated using command -
 * npx browserslist-useragent-regexp "Firefox 60 , Firefox 61, Firefox 68, Firefox 72, Safari 12, Safari 15, iOS 12, iOS 15".
 * All unsupported browser versions can be viewed on https://browserslist.dev/, using above query.
 * These browser versions do not support Intersection Observer API.
 * Safari > 12.0 and iOS > 12.1 supports this API but does not support "options.root" parameter to be a Document
 * */
export const unsupportedBrowsersRegex = new RegExp(
    /Firefox\/(6[01]\.0|68\.0|72\.0)(\.\d+|)|Maci.+ Version\/(12\.0|15\.0)([,.]\d+|)( Mobile\/\w+|) Safari\/|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(12[._][01]|15[._][01])([._]\d+|)/
);

export const isBrowserUnsupported = (regex = unsupportedBrowsersRegex) => {
    if (!(navigator && navigator.userAgent)) return true;

    if (regex && regex.test) return regex.test(navigator.userAgent);

    return false;
};

/**
 * Function to identify if the browser is safari below 14
 */
export function isOldSafari() {
    const userAgentString = navigator.userAgent;
    const safariAgent = userAgentString.indexOf('Safari') > -1;

    // If the current browser is Safari
    if (safariAgent) {
        const safariVersion = parseInt(userAgentString.split('Version/')?.[1]?.split(' Safari')[0], 10);
        // If the Safari version is less than 14
        if (safariVersion < 14) {
            return true;
        }
    }
    return false;
}
