import * as React from 'react';

interface FormComponentProps<V> {
    defaultValue?: V;
    value?: V;
    onChange?: (...args: unknown[]) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface State<V> {
    controlledValue?: V;
}

export function withControlledFormComponentValue<V = unknown>(mapOnChangeValue: (...args: unknown[]) => V) {
    type WrappedComponentProps = FormComponentProps<V> & {
        valueOverride?: V;
    };

    return (FormComponent: React.ComponentType<FormComponentProps<V>>) =>
        class ControlledFormComponentWrapper extends React.PureComponent<WrappedComponentProps, State<V>> {
            state: State<V> = {
                controlledValue: this.props.valueOverride ?? this.props.defaultValue,
            };

            static displayName = 'ControlledFormComponentWrapper';

            componentDidUpdate(prevProps: Readonly<WrappedComponentProps>, prevState: Readonly<State<V>>) {
                if (
                    this.props.valueOverride &&
                    this.props.valueOverride !== prevProps.valueOverride &&
                    this.props.valueOverride !== prevState.controlledValue
                ) {
                    this.setState({
                        controlledValue: this.props.valueOverride,
                    });
                }
            }

            onChange = (...args: unknown[]) => {
                this.setState({ controlledValue: mapOnChangeValue(...args) });
                this.props.onChange?.(...args);
            };

            render() {
                const { defaultValue: _1, value: _2, valueOverride: _3, onChange: _4, ...remainingProps } = this.props;
                return (
                    <FormComponent {...remainingProps} value={this.state.controlledValue} onChange={this.onChange} />
                );
            }
        };
}
