// This exists as a shorthand func to return a default value if the parsed
// string was somehow not a valid number.
export const toNumber = (str: string | number | undefined | null, defaultValue?: number) => {
    if (typeof str === 'number') {
        return str;
    }

    if (str) {
        const parsedNumber = parseInt(str, 10);
        if (!isNaN(parsedNumber)) {
            return parsedNumber;
        }
    }

    return defaultValue;
};

export const toString = (num: number | undefined) => {
    if (num) {
        return String(num);
    }
    return undefined;
};
