import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import type { Location as ResourceRouterLocation } from 'react-resource-router';
import { location } from '@atlassian/help-center-common-util/location';
import messages from './messages';
import { NavBlocker } from './nav-blocker';

interface NavigationPromptProps {
    preventNavigation: boolean;
    allowedPaths?: (RegExp | string)[];
    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line no-shadow
    onBeforeNavigate?: (location: ResourceRouterLocation) => void;
}

/**
 * This component wraps react-router Prompt component but adds in support for
 * prompting on tab close/back/forward.
 */
export class NavigationPrompt extends React.PureComponent<NavigationPromptProps & InjectedIntlProps> {
    componentDidMount() {
        if (this.props.preventNavigation) {
            this.blockNavigation();
        }
    }

    componentWillUnmount() {
        if (this.props.preventNavigation) {
            this.allowNavigation();
        }
    }

    componentDidUpdate(prevProps: NavigationPromptProps) {
        const { preventNavigation } = this.props;
        if (preventNavigation && !prevProps.preventNavigation) {
            this.blockNavigation();
        } else if (!preventNavigation && prevProps.preventNavigation) {
            this.allowNavigation();
        }
    }

    checkCanNavigate = (routerLocation: ResourceRouterLocation) => {
        // This will cause the prompt to not prevent navigation if a "q" was, or is, going to be in the location search property.
        // Because we do that it will fail to prevent accidental navigation if interacting with the back button,
        // or pressing backspace/delete. We have another issue to pick up to make this better.
        // See: https://jdog.jira-dev.com/browse/FSD-3235
        const containsSearchQueryParam = (str: string) => str.includes('q=');
        if (containsSearchQueryParam(routerLocation.search) || containsSearchQueryParam(location().search)) {
            return true;
        }

        /**
         * Do not show prevent navigation prompt for allowed paths
         */
        const { allowedPaths } = this.props;
        const { pathname } = routerLocation;
        if (allowedPaths) {
            for (const pathRegExp of allowedPaths) {
                if (pathname.match(pathRegExp)) {
                    return true;
                }
            }
        }
        const { intl } = this.props;
        return intl.formatMessage(messages.navigationWarning);
    };

    onBeforeUnload = (event: BeforeUnloadEvent) => {
        // Prompts the user before closing the page, see:
        // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
        event.preventDefault();
        event.returnValue = '';
    };

    blockNavigation = () => {
        window.addEventListener('beforeunload', this.onBeforeUnload);
    };

    allowNavigation = () => {
        window.removeEventListener('beforeunload', this.onBeforeUnload);
    };

    render() {
        const { preventNavigation, onBeforeNavigate } = this.props;
        return (
            <NavBlocker when={preventNavigation} message={this.checkCanNavigate} onBeforeNavigate={onBeforeNavigate} />
        );
    }
}

export default injectIntl(NavigationPrompt);
