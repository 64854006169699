import { version } from 'react';
import { PerimeterType } from '@atlaskit/feature-gate-js-client';
import { isFedRamp } from '@atlassian/atl-context';
import { browserMetrics } from '@atlassian/browser-metrics';
import type { ScreenName } from '@atlassian/help-center-common-component/constants';
import {
    featureFlagsPlugin,
    resourceTimingsPlugin,
    customPerfMarks,
} from '@atlassian/help-center-common-util/analytics/browser-metrics-v3-plugins';
import { getAnalyticsWebClientPromise } from '@atlassian/help-center-common-util/analytics/client';
import { isPageLoadInstrumented } from '@atlassian/help-center-common-util/analytics/perf-config';
import { devLog } from '@atlassian/help-center-common-util/dev-logger';
import { getMeta } from '@atlassian/help-center-common-util/meta';
import { getMark } from '@atlassian/help-center-common-util/perf-markers';
import { payloadPublisher, TenantType } from '@atlassian/ufo';

const PERFORMANCE_MARK_PREFIX = 'jsd.performance.profile.customer.portal';

/**
 * List of feature flags whose value has to be sent along with Browser metrics.
 */
const FEATURE_FLAGS_TO_REPORT: string[] = [];

export const initBrowserMetricsV3 = () => {
    /**
     * tenant and region information for UFO
     */
    const region = getMeta('ajs-region');
    const tenantType = getMeta('ajs-is-synthetic') === 'true' ? TenantType.Synthetic : TenantType.RealUser;
    /**
     * Framework and Product Info
     */

    const perimeter = isFedRamp() ? PerimeterType.FEDRAMP_MODERATE : PerimeterType.COMMERCIAL;
    const info = {
        perimeter,
        product: 'jira',
        app: {
            version: { web: window.JSD_CUSTOMER_PORTAL_BUILD_VERSION?.buildKey || 'local' },
            framework: { version, name: 'react' },
        },
        region: region !== null ? region : 'none',
    };

    /**
     * eventPipelineClient expects pre configured `analytics-web-client` promise.
     */
    const endpoints = {
        eventPipelineClient: getAnalyticsWebClientPromise().then((client) => {
            return client.getInstance();
        }),
    };

    // Disabling existing violations, should be fixed when revisited.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAnalyticsWebClientPromise().then((client) => {
        payloadPublisher.setup({
            product: info.product,
            app: info.app,
            region: region || info.region,
            gasv3: client.getInstance(),
            resourceTimings: resourceTimingsPlugin,
            featureFlags: FEATURE_FLAGS_TO_REPORT,
            ...(tenantType && { tenantType }),
        });
    });

    browserMetrics.init({
        info,
        endpoints,
        plugins: {
            featureFlags: featureFlagsPlugin,
            resourceTimings: resourceTimingsPlugin,
        },
        events: {
            all: {
                featureFlags: FEATURE_FLAGS_TO_REPORT,
                plugins: [customPerfMarks],
            },
        },
        ssr: {
            getDoneMark: () => getMark('ssr.spa.rendered')?.startTime || null,
        },
    });
};

let isInitialPageLoadTracked = false;
/**
 * This function will trigger the start of timer for Browser metrics page load
 * isInitial flag will be set as true, if the page has not been already loaded
 * @param screenName Name of the Page we are measuring pageLoadMetrics
 */
export const startPageLoad = (screenName: ScreenName | undefined) => {
    if (isPageLoadInstrumented(screenName)) {
        devLog('Browser metrics v3: startPageLoad() fired');
        browserMetrics.startPageLoad({
            isInitial: !isInitialPageLoadTracked,
        });
    }

    if (!isInitialPageLoadTracked) {
        isInitialPageLoadTracked = true;
    }
};

/**
 * This method is used to create a Performance related timestamp in
 * browser's performance entry. This function is to be used only to
 * support existing marks
 * @deprecated use browser metrics v3 mark() method to create new marks
 */
export function perfMark(shortKey: string) {
    let key = shortKey.trim();

    if (!key.startsWith(PERFORMANCE_MARK_PREFIX)) {
        key = `${PERFORMANCE_MARK_PREFIX}.${shortKey}`;
    }

    window.performance && window.performance.mark && window.performance.mark(key);
}
