import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const FETCH_ARTICLES = 'epic.model.FETCH_KB_ARTICLES';

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}
export interface FetchArticlesAction {
    type: typeof FETCH_ARTICLES;
    payload: {
        projectId: number;
        portalId: number;
        categoryId: string;
        categoryDataRequired?: boolean;
        meta?: AnalyticsMetaData;
    };
}

interface FetchArticlesParams {
    projectId: number;
    portalId: number;
    categoryId: string;
    categoryDataRequired?: boolean;
    meta?: AnalyticsMetaData;
}

export const fetchArticlesAction = (params: FetchArticlesParams): FetchArticlesAction => {
    const { projectId, portalId, categoryId, categoryDataRequired, meta } = params;
    return {
        type: FETCH_ARTICLES,
        payload: { projectId, portalId, categoryId, categoryDataRequired, meta },
    };
};
