import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const FETCH_CATEGORIES = 'epic.model.FETCH_KB_CATEGORIES';

export interface AnalyticsMetaData {
    analyticsSuccessEvent?: UIAnalyticsEvent;
    analyticsFailureEvent?: UIAnalyticsEvent;
}
export interface FetchCategoriesAction {
    type: typeof FETCH_CATEGORIES;
    payload: {
        projectId: number;
        portalId: number;
        meta?: AnalyticsMetaData;
    };
}

export const fetchCategoriesAction = (
    projectId: number,
    portalId: number,
    meta?: AnalyticsMetaData
): FetchCategoriesAction => ({
    type: FETCH_CATEGORIES,
    payload: { projectId, portalId, meta },
});
