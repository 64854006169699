import type { UpdateContextAction, ClearContextAction } from 'state/actions/context';
import { UPDATE_CONTEXT, CLEAR_CONTEXT } from 'state/actions/context';
import { initialModel } from '@atlassian/help-center-common-util/model';
import { toNumber } from '@atlassian/help-center-common-util/number';

export interface ContextState {
    portalId?: number;
    pathname?: string;
    routeName?: string;
}

type HandledActions = UpdateContextAction | ClearContextAction;

export const getInitialState = (): ContextState => {
    const initialModelState = initialModel();
    const initialState = initialModelState?.portal ? { portalId: Number(initialModelState.portal?.id) } : {};

    return initialState;
};

export const defaultState: ContextState = getInitialState();

export default function reducer(state: ContextState = defaultState, action: HandledActions) {
    switch (action.type) {
        case UPDATE_CONTEXT: {
            const { pathname, pathParams, routeName } = action.payload;

            let portalId;

            if (pathParams) {
                portalId = toNumber(pathParams.portalId);
            }

            return { portalId, pathname, routeName };
        }
        case CLEAR_CONTEXT:
            return {};

        default:
            return state;
    }
}
