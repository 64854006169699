import { Observable } from 'rxjs';

import { post } from 'epics/ajax';

import { contextPath } from '@atlassian/help-center-common-util/history';

import type { PortalAnnouncementUpdatePayload } from './types';

export const updatePortalAnnouncements = (
    payload: PortalAnnouncementUpdatePayload,
    portalId: number,
    context = contextPath
) => {
    const portalAnnouncementUpdateURI = `${context}/rest/servicedesk/1/servicedesk-data/announcement/portal/${portalId}`;
    const contentType = {
        'Content-Type': 'application/json',
    };

    return (
        post(portalAnnouncementUpdateURI, payload, contentType)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e) => Observable.throw(e))
    );
};
