import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';
import * as transitions from 'view/styles/transitions';
import { isAppMounted } from '@atlassian/help-center-common-component/mark-app-mounted';
import { getIsPageServerSideRendered } from '@atlassian/help-center-common-util/env';

export interface FadeInOptions {
    fadeInTime: number;
    delay: number;
}

const withFadeIn =
    (options?: FadeInOptions) =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const defaultOptions = {
            fadeInTime: transitions.speedMs.slow,
            delay: 0,
        };

        const mergedOptions = { ...defaultOptions, ...options };

        // eslint-disable-next-line rulesdir/styled-component-order, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
        const ComponentWithFadeIn = styled(WrappedComponent)<transitions.TransitionProps>`
            ${transitions.fadeInOut(mergedOptions.fadeInTime, mergedOptions.delay)};
        `;

        const ReturnComponent: React.FC<TProps> = (props: TProps) => {
            const shouldSkipTransition = React.useMemo(() => !isAppMounted.value, []);

            /**
             * In server side rendering -  component should appear
             * on First time load from SSR - component should appear
             * on next time onwards - component should transition
             * on client only - component should transition
             */
            const shouldTransitOnMount = () => {
                // Invalid report.
                // eslint-disable-next-line testing-library/render-result-naming-convention
                const isPageServerSideRendered = getIsPageServerSideRendered();

                if (__SERVER__) {
                    return false;
                }

                if (isPageServerSideRendered && shouldSkipTransition) {
                    return false;
                }

                return true;
            };

            return (
                <Transition in timeout={0} mountOnEnter appear={shouldTransitOnMount()}>
                    {(state: transitions.TransitionState) => (
                        // Spreading props blows type checking up for some reason. Why??
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        <ComponentWithFadeIn {...(props as any)} state={state} />
                    )}
                </Transition>
            );
        };

        return ReturnComponent;
    };

export default withFadeIn;
