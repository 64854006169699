import type { FetchOptions, ModelType, ModelTypeOptions } from 'rest/models/types';

export const buildModelsActionCreator =
    <TActionType extends string, TModelType extends ModelType>(actionType: TActionType, type: TModelType) =>
    (params: ModelTypeOptions[TModelType] = {} as ModelTypeOptions[TModelType], options?: FetchOptions) => ({
        ...(options ? { options } : {}),
        type: actionType,
        payload: {
            type,
            params,
        },
    });
