import type { AjaxResponse } from 'rxjs';
import { Observable } from 'rxjs';
import { post } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export const approve = (approvalId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/approvals/1/customer/approval/${approvalId}/approve`;

    return (
        post(uri)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};

export const decline = (approvalId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/approvals/1/customer/approval/${approvalId}/reject`;

    return (
        post(uri)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};
