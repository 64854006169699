import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { graphql, useFragment } from 'react-relay';
import type { State } from 'state';
import { getThemeColor } from 'state/selectors/help-center';
import { isLoggedIn as isUserLoggedIn } from 'state/selectors/user';
import { useExternalLink } from 'view/external-links/use-external-link';
import ActivityIcon from '@atlaskit/icon/glyph/activity';
import DocumentIcon from '@atlaskit/icon/glyph/media-services/document';
import QuestionsIcon from '@atlaskit/icon/glyph/questions';
import ScreenIcon from '@atlaskit/icon/glyph/screen';
import { Box, xcss } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';
import type { resourceIconFragment$key } from './__generated__/resourceIconFragment.graphql';

export interface Props {
    isLoggedIn: boolean;
    themeColor: string;
    result: resourceIconFragment$key;
}

export const ResourceIcon = ({ isLoggedIn, themeColor, result }: Props) => {
    const data = useFragment<resourceIconFragment$key>(
        graphql`
            fragment resourceIconFragment on HelpObjectStoreSearchResult {
                entityType @required(action: THROW)
                displayLink @required(action: THROW)
                iconUrl @required(action: THROW)
                isExternal @required(action: THROW)
            }
        `,
        result
    );

    const { entityType, displayLink, iconUrl, isExternal } = data;

    const {
        data: { iconUrl: externalResourceIconUrl },
        isLoading: isIconLoading,
    } = useExternalLink({
        isLoggedIn,
        link: displayLink,
    });

    const isValidResourceIconUrl = !!iconUrl && iconUrl !== '';
    const isValidExternalResourceIconUrl = !!externalResourceIconUrl && externalResourceIconUrl !== '';
    const getDefaultIcon = useMemo(() => {
        switch (entityType) {
            case 'ARTICLE':
                return <DocumentIcon label="" testId="article-icon" size="medium" />;

            case 'REQUEST_FORM':
                return <ActivityIcon label="" testId="request-form-icon" size="medium" />;

            case 'PORTAL':
                return <ScreenIcon label="" testId="portal-icon" size="medium" />;

            default:
                return <QuestionsIcon label="" testId="question-icon" size="medium" />;
        }
    }, [entityType]);

    const getExternalResourceIcon = () => {
        if (isIconLoading) {
            return <SkeletonItem height={'24px'} width={'24px'} />;
        }

        if (isValidExternalResourceIconUrl) {
            return (
                <Box as="img" xcss={imgStyles} testId="external-resource-icon" src={externalResourceIconUrl} alt="" />
            );
        }

        return getDefaultIcon;
    };

    const getResourceIcon = () => {
        if (isIconLoading) {
            return <SkeletonItem height={'24px'} width={'24px'} />;
        }
        if (isValidResourceIconUrl) {
            return <Box as="img" xcss={imgStyles} testId="resource-icon" src={iconUrl} alt="" />;
        }

        return getDefaultIcon;
    };

    return (
        <Box
            xcss={imgStyles}
            style={{
                color: themeColor,
            }}
        >
            {isExternal ? getExternalResourceIcon() : getResourceIcon()}
        </Box>
    );
};

const ConnectedResourceIcon = connect((state: State) => {
    return {
        isLoggedIn: isUserLoggedIn(state),
        themeColor: getThemeColor(state),
    };
})(ResourceIcon);

export default ConnectedResourceIcon;

const imgStyles = xcss({
    width: 'size.200',
    height: 'size.200',
    objectFit: 'contain',
});
