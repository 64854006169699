import * as React from 'react';
import NodeCache from 'node-cache';

import { fetchTFExtensions } from 'rest/trusted-fetch-extensions';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { trackError } from '@atlassian/help-center-common-util/analytics';
import batch from '@atlassian/help-center-common-util/batch-function';
import batchV2 from '@atlassian/help-center-common-util/batch-function-v2';

import { TFECacheContext } from '../tfe-cache-context';
import type { Extension, ExtensionPointModule, ForgePageName, ResourceContext } from '../types';
import type { FetchTFExtensionsData } from 'rest/trusted-fetch-extensions/types';

const FIVE_MINUTE_IN_SECONDS = 300;

const batchedGetExtensions = batch(fetchTFExtensions, 0);

const batchedGetExtensionsV2 = batchV2(fetchTFExtensions, 0);

export const TFECacheProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const cacheRef = React.useRef<NodeCache>(
        new NodeCache({
            stdTTL: FIVE_MINUTE_IN_SECONDS,
            checkperiod: FIVE_MINUTE_IN_SECONDS * 0.5,
            useClones: false,
        })
    );

    const getExtensionsByType = (type: ExtensionPointModule, page?: ForgePageName): Promise<FetchTFExtensionsData> => {
        const extensionsByType: Extension[] | undefined = cacheRef.current.get(type);
        if (extensionsByType) {
            return Promise.resolve({ [type]: extensionsByType });
        }
        const promise = batchedGetExtensions(type);
        promise
            .then((fetchedExtensions) => {
                const allFetchedExtensionsForCache = [];
                for (const property in fetchedExtensions) {
                    if (Object.prototype.hasOwnProperty.call(fetchedExtensions, property)) {
                        allFetchedExtensionsForCache.push({ key: property, val: fetchedExtensions[property] });
                    }
                }
                cacheRef.current.mset(allFetchedExtensionsForCache);
            })
            .catch((e) => {
                trackError('forge-tfe.setCache.failed', {}, e);
                const event = createAnalyticsEvent({
                    action: 'failed',
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    actionSubject: 'fetchForgeTFE',
                    attributes: {
                        page,
                        moduleType: type,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                        statusCode: e.status,
                        error: JSON.stringify(e),
                    },
                });
                event.context.push({ componentName: 'fetchForgeTFE' });
                event.fire();
            });

        return promise;
    };

    const getExtensionsByTypeV2 = (
        type: ExtensionPointModule,
        resourceContext?: ResourceContext,
        page?: ForgePageName
    ): Promise<FetchTFExtensionsData> => {
        const { resourceId } = resourceContext || {};
        const key = constructKey(resourceId, type);
        const extensionsByType: Extension[] | undefined = cacheRef.current.get(key);
        if (extensionsByType) {
            return Promise.resolve({ [type]: extensionsByType });
        }
        const promise = batchedGetExtensionsV2(type, resourceContext);
        promise
            .then((fetchedExtensions) => {
                const allFetchedExtensionsForCache = [];
                for (const property in fetchedExtensions) {
                    if (Object.prototype.hasOwnProperty.call(fetchedExtensions, property)) {
                        allFetchedExtensionsForCache.push({
                            key: constructKey(resourceId, property),
                            val: fetchedExtensions[property],
                        });
                    }
                }
                cacheRef.current.mset(allFetchedExtensionsForCache);
            })
            .catch((e) => {
                trackError('forge-tfe.setCache.failed', {}, e);
                const event = createAnalyticsEvent({
                    action: 'failed',
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    actionSubject: 'fetchForgeTFE',
                    attributes: {
                        page,
                        moduleType: type,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                        statusCode: e.status,
                        error: JSON.stringify(e),
                    },
                });
                event.context.push({ componentName: 'fetchForgeTFE' });
                event.fire();
            });

        return promise;
    };

    const constructKey = (resourceId: string | undefined, property: string) =>
        resourceId ? resourceId.concat('-'.concat(property)) : property;

    return (
        <TFECacheContext.Provider
            value={{
                getExtensionsByType,
                getExtensionsByTypeV2,
            }}
        >
            {children}
        </TFECacheContext.Provider>
    );
};

export default TFECacheProvider;
