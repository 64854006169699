import { MAX_PARAGRAPH_FIELD_CHARS, MAX_SHORT_TEXT_FIELD_CHARS } from '../../common/constants';
import { useRequestTypeFields } from '../../services/request-type-fields-service';
import {
    PARAGRAPH_FIELD_TYPE,
    SHORT_TEXT_FIELD_TYPE,
    getFieldType,
} from '../../services/request-type-fields-service/utils';
import { useBaseUrl, useFieldFromLastMessage } from '../conversation-store';

export const useGetJiraFieldMaxChars = () => {
    const jiraField = useFieldFromLastMessage();
    const baseUrl = useBaseUrl();
    const [{ data: requestTypeFields }] = useRequestTypeFields({ baseUrl });
    const fieldType = jiraField && getFieldType(jiraField.fieldId, requestTypeFields);

    const getJiraFieldMaxChars = () => {
        if (fieldType === SHORT_TEXT_FIELD_TYPE) {
            return MAX_SHORT_TEXT_FIELD_CHARS;
        }

        if (fieldType === PARAGRAPH_FIELD_TYPE) {
            return MAX_PARAGRAPH_FIELD_CHARS;
        }

        return undefined;
    };

    return { getJiraFieldMaxChars };
};
