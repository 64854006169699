import type { ResourceStoreContext } from 'react-resource-router/resources';
import { createResourcesPlugin } from 'react-resource-router/resources';
import { PROJECT_NAME } from 'routes/common/constants';
import {
    getCloudId,
    getHelpCenterAri,
    getWorkspaceAri,
    getLayoutAri,
    getMeta,
} from '@atlassian/help-center-common-util/meta';

const customContext: ResourceStoreContext = {
    cloudId: getCloudId(),
    productName: PROJECT_NAME,
    helpCenterAri: getHelpCenterAri(),
    workspaceAri: getWorkspaceAri(),
    layoutAri: getLayoutAri(),
    isUserLoggedIn: Boolean(getMeta('ajs-atlassian-account-id')),
};

/**
 * WARNING: This plugin always needs intialization at module level.
 * Calling it at runtime will make SSR fail as the `customContext` wouldn't be available.
 */
export const resourcesPlugin = createResourcesPlugin({
    context: customContext,
});
