import { createSelector } from 'reselect';
import type { State } from 'state';
import type { RequestsColumnSettingsState } from 'state/persisted/requests-column-settings';
import { operationalEvent } from '@atlassian/help-center-common-util/analytics/events';

export const getRequestsColumnSettings = (state: State): RequestsColumnSettingsState => {
    return state.persisted.requestsColumnSettings || [];
};

export const getEnabledRequestsColumnSettings = createSelector(getRequestsColumnSettings, (requestsColumnSettings) => {
    return requestsColumnSettings.filter((column) => column.isEnabled);
});

export const isLoading = (state: State): boolean => {
    if (!state.ui.requestsColumnSettings) {
        return true;
    }

    return Boolean(state.ui.requestsColumnSettings.isLoading);
};

export const isSaveInProgress = (state: State): boolean => {
    return Boolean(state.ui.requestsColumnSettings?.isSaveInProgress);
};

export const isCustomizing = (state: State): boolean => {
    return Boolean(state.ui.requestsColumnSettings?.isCustomizing);
};

export const isReOrdering = (state: State): boolean => {
    return Boolean(state.ui.requestsColumnSettings?.isReOrdering);
};

export const getColumnsOrder = (state: State): number[] | undefined => state.ui.requestsColumnSettings?.columnsOrder;

export const getOrderedRequestsColumnSettings = createSelector(
    getEnabledRequestsColumnSettings,
    getColumnsOrder,
    (enabledColumnSettings, columnsOrder) => {
        if (columnsOrder && columnsOrder.length > 0) {
            return columnsOrder.map((index) => enabledColumnSettings?.[index]);
        }
        const filteredColumns = enabledColumnSettings.filter((column) => column.order);
        if (filteredColumns.length > 0) {
            return filteredColumns.sort((a, b) => {
                if (a.order && b.order) {
                    return a.order - b.order;
                }
                return 1;
            });
        }
        /*
         * We fire analytics when enabledColumnSettings is not empty (to filter out noise) and filteredColumns
         * is empty implying that order is prop is undefined for all the columns. This is an erroneous state
         * since we expect at least one column to have order prop defined. If this happens, we return enabledColumnSettings
         * as is.
         */

        if (enabledColumnSettings && enabledColumnSettings.length > 0) {
            operationalEvent({
                action: 'emptyOrderedRequestColumnSettingsReceived',
                actionSubject: 'request-list-filters-and-table',
                source: 'RequestList',
            });
        }

        return enabledColumnSettings;
    }
);
