export const getBoundingBox = (element: HTMLElement): ClientRect | DOMRect => element.getBoundingClientRect();

export const getElementHeight = (element: HTMLElement): number => element.clientHeight;

export function blur(id: string) {
    const element = document.getElementById(id);
    if (element) {
        element.blur();
    }
}

export function focus(id: string) {
    const element = document.getElementById(id);
    if (element) {
        element.focus();
    }
}
