/**
 * @generated SignedSource<<5d9e9aaf7590fd286603103e0ca55f97>>
 * @relayHash e8f6ae0dc7d75070bcc06e7c262dcddb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e9840364c0493af808185acb2ff2c33061f16b305a4cab4331408004e898cde0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type searchResultRequestFormQuery$variables = {
  cloudId: string;
  helpCenterAri?: string | null | undefined;
  portalIds?: ReadonlyArray<string> | null | undefined;
  queryTerm: string;
  resultLimit?: number | null | undefined;
};
export type searchResultRequestFormQuery$data = {
  readonly helpObjectStore: {
    readonly __typename: "HelpObjectStoreQueryApi";
    readonly " $fragmentSpreads": FragmentRefs<"requestFormsFragment">;
  };
};
export type searchResultRequestFormQuery = {
  response: searchResultRequestFormQuery$data;
  variables: searchResultRequestFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "portalIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "queryTerm"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resultLimit"
},
v5 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v6/*: any*/),
          "concreteType": "HelpObjectStoreQueryApi",
          "kind": "LinkedField",
          "name": "helpObjectStore",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "requestFormsFragment"
            }
          ]
        },
        "action": "THROW",
        "path": "helpObjectStore"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchResultRequestFormQuery",
    "selections": [
      {
        "args": (v6/*: any*/),
        "concreteType": "HelpObjectStoreQueryApi",
        "kind": "LinkedField",
        "name": "helpObjectStore",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": "requestForms",
            "args": [
              {
                "fields": [
                  (v5/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "entityType",
                    "value": "REQUEST_FORM"
                  },
                  {
                    "kind": "Variable",
                    "name": "helpCenterAri",
                    "variableName": "helpCenterAri"
                  },
                  {
                    "kind": "Variable",
                    "name": "portalIds",
                    "variableName": "portalIds"
                  },
                  {
                    "kind": "Variable",
                    "name": "queryTerm",
                    "variableName": "queryTerm"
                  },
                  {
                    "kind": "Variable",
                    "name": "resultLimit",
                    "variableName": "resultLimit"
                  }
                ],
                "kind": "ObjectValue",
                "name": "searchInput"
              }
            ],
            "kind": "LinkedField",
            "name": "searchHelpObjects",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "isExternal"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "searchBackend"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "title"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "description"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "displayLink"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "containerDisplayName"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "searchAlgorithm"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "entityType"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "iconUrl"
                  }
                ],
                "type": "HelpObjectStoreSearchResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ],
                "type": "HelpObjectStoreQueryError"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e9840364c0493af808185acb2ff2c33061f16b305a4cab4331408004e898cde0",
    "metadata": {},
    "name": "searchResultRequestFormQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "767d90ed3aed24eab0f0598281dd235e";

export default node;
