import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchCompleteSignupAction,
    FetchCompleteSignupSuccess,
    FetchCompleteSignupFailure,
} from 'state/actions/complete-signup';
import {
    FETCH_COMPLETE_SIGNUP_MODEL,
    fetchCompleteSignupSuccess,
    fetchCompleteSignupFailure,
} from 'state/actions/complete-signup';
import type { ErrorResponse } from 'epics/model/types';

export const completeSignupEpic: Epic<
    FetchCompleteSignupAction,
    FetchCompleteSignupSuccess | FetchCompleteSignupFailure
> = (action$) => {
    return action$.ofType(FETCH_COMPLETE_SIGNUP_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.completeSignup && !!response.completeSignup.emailAddress)
            .map((response) => {
                return fetchCompleteSignupSuccess(response.completeSignup);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchCompleteSignupFailure(error));
            })
    );
};
