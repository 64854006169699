// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import ErrorIcon from 'assets/images/page.error.react.svg';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Root = styled.div`
    ${mixins.flexCenter};
    height: 100%;
    flex: 1 1 auto;
    flex-direction: column;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Container = styled.div`
    ${mixins.flexCenter};
    flex: 1 1 auto;
    flex-direction: column;
    margin-bottom: ${token('space.800', '64px')};
    padding-bottom: ${token('space.100', '8px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const ErrorMessage = styled.h1`
    ${fonts.h800};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const HelpText = styled.p`
    margin-top: ${token('space.200', '16px')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const MessageContainer = styled.div`
    text-align: center;
    padding: 0 ${token('space.200', '16px')};
    margin-top: ${token('space.300', '24px')};
    max-width: ${grid.multiple(53).px};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const PageErrorIcon = styled(ErrorIcon)`
    width: ${grid.multiple(34).px};
`;
