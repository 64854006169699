import { useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { fetchJson } from '@atlassian/help-center-common-util/fetch';
import { useActions, useRequestTypeFieldPromise, useStore } from '../../controllers/fields-store';
import type { RequestTypeFieldsResponse, RequestTypeField } from './types';

export const useRequestTypeFields = ({
    baseUrl = '',
}: {
    baseUrl?: string;
}): [
    {
        loading: boolean;
        error?: Error;
        data: Map<string, RequestTypeField>;
    },
    {
        fetchRequestTypeFields: (
            projectId: number,
            requestTypeId?: string
        ) => Promise<Map<string, RequestTypeField> | undefined>;
    },
] => {
    di(fetchJson);
    const { setRequestTypeFieldsMap, setIsLoading, setError } = useActions();
    const fieldStore = useStore();
    const requestTypeFieldPromise = useRequestTypeFieldPromise();

    const getRequestTypeFieldsEndpoint = useCallback(
        (projectId: number, requestTypeId?: string) => {
            if (!requestTypeId) return '';
            const projectIdentifier = `projectId:${projectId}`;
            return `${baseUrl}/rest/servicedeskapi/servicedesk/${encodeURIComponent(
                projectIdentifier
            )}/requesttype/${encodeURIComponent(requestTypeId)}/field`;
        },
        [baseUrl]
    );

    const convertedRequestTypeFieldMap = useCallback((response: RequestTypeFieldsResponse | undefined) => {
        return new Map(response?.requestTypeFields.map((field) => [field.fieldId, field]));
    }, []);

    const fetchRequestTypeFields = useCallback(
        async (projectId: number, requestTypeId?: string): Promise<Map<string, RequestTypeField> | undefined> => {
            try {
                if (!requestTypeId) {
                    throw new Error('requestTypeId is undefined');
                }
                const response = await fetchJson<RequestTypeFieldsResponse>(
                    getRequestTypeFieldsEndpoint(projectId, requestTypeId),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );

                const convertedMap = convertedRequestTypeFieldMap(response);
                setRequestTypeFieldsMap(convertedMap);
                return convertedMap;
            } catch (err) {
                setError(err);
                return undefined;
            }
        },
        [getRequestTypeFieldsEndpoint, convertedRequestTypeFieldMap, setRequestTypeFieldsMap, setError]
    );

    const cacheIsInvalid = useCallback(
        (projectId: number, requestTypeId?: string) => {
            if (!fieldStore.cacheIds) return true;
            return projectId !== fieldStore.cacheIds.projectId || requestTypeId !== fieldStore.cacheIds.requestTypeId;
        },
        [fieldStore.cacheIds]
    );

    const fetchFieldsOrUseCache = useCallback(
        async (projectId: number, requestTypeId?: string) => {
            if (cacheIsInvalid(projectId, requestTypeId)) {
                const request = fetchRequestTypeFields(projectId, requestTypeId);
                setIsLoading({ projectId, requestTypeId }, request);
                return request;
            }
            return requestTypeFieldPromise;
        },
        [cacheIsInvalid, fetchRequestTypeFields, requestTypeFieldPromise, setIsLoading]
    );

    return [
        { loading: fieldStore.loading, data: fieldStore.requestTypeFieldsMap, error: fieldStore.error },
        {
            fetchRequestTypeFields: fetchFieldsOrUseCache,
        },
    ];
};
