import type { ResetPassword, ResetPasswordFailure } from 'state/actions/reset-password';
import { RESET_PASSWORD, RESET_PASSWORD_FAILURE } from 'state/actions/reset-password';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export interface ResetPasswordUIState {
    isSubmitting: boolean;
    error?: DefaultError;
}

export type HandledActions = ResetPassword | ResetPasswordFailure;

const defaultState: ResetPasswordUIState = {
    isSubmitting: false,
};

export function resetPasswordReducer(
    state: ResetPasswordUIState = defaultState,
    action: HandledActions
): ResetPasswordUIState {
    switch (action.type) {
        case RESET_PASSWORD:
            return handleResetPassword(state);
        case RESET_PASSWORD_FAILURE:
            return handleResetPasswordFailure(state, action);
        default:
            return state;
    }
}

const handleResetPassword = (state: ResetPasswordUIState): ResetPasswordUIState => ({
    ...state,
    isSubmitting: true,
    error: undefined,
});

const handleResetPasswordFailure = (
    state: ResetPasswordUIState,
    action: ResetPasswordFailure
): ResetPasswordUIState => ({
    ...state,
    isSubmitting: false,
    error: action.payload.error,
});
