import React from 'react';
import { styled } from '@compiled/react';
import { getCloudId } from '@helpCenter/util/meta';
import defaultBannerImage from 'assets/images/default-banner.png';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { Header } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import { useHelpCenterAri } from '../../../hooks/use-help-center-ari';
import type { sidebarHelpCenterHeaderQuery } from './__generated__/sidebarHelpCenterHeaderQuery.graphql';
import messages from './messages';

interface HelpCenterData {
    currentHcName: string | undefined;
    currentHcBannerUrl: string | null | undefined;
    currentHcThemeColor: string | null | undefined;
    useDefaultBanner: boolean;
}

const SidebarHelpCenterHeader: React.FC = () => {
    di(useLazyLoadQuery);
    const helpCenterAri = useHelpCenterAri();
    const data = useLazyLoadQuery<sidebarHelpCenterHeaderQuery>(
        graphql`
            query sidebarHelpCenterHeaderQuery($cloudId: ID!, $helpCenterAri: ID!) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: ["HelpCenterAggBeta"]) {
                        ... on HelpCenter {
                            __typename
                            name {
                                default
                            }
                            helpCenterBranding @required(action: THROW) @optIn(to: ["HelpCenterBrandingTest"]) {
                                banner {
                                    url
                                }
                                colors {
                                    primary
                                }
                                useDefaultBanner
                            }
                        }
                        ... on QueryError {
                            __typename
                        }
                    }
                }
            }
        `,
        { helpCenterAri, cloudId: getCloudId() }
    );

    const helpCenterData = data?.helpCenter?.helpCenterById;
    let { currentHcName, currentHcBannerUrl, currentHcThemeColor, useDefaultBanner }: HelpCenterData = {
        currentHcName: undefined,
        currentHcBannerUrl: null,
        currentHcThemeColor: null,
        useDefaultBanner: false,
    };
    if (helpCenterData?.__typename === 'HelpCenter') {
        currentHcName = helpCenterData?.name?.default;
        currentHcBannerUrl = helpCenterData.helpCenterBranding?.banner?.url;
        currentHcThemeColor = helpCenterData.helpCenterBranding?.colors?.primary;
        useDefaultBanner = !!helpCenterData.helpCenterBranding?.useDefaultBanner;
    }
    const { formatMessage } = useIntl();
    const defaultImage = useDefaultBanner ? defaultBannerImage : undefined;
    const backgroundImage = currentHcBannerUrl ? currentHcBannerUrl : defaultImage;
    return (
        <Header
            iconBefore={
                <HelpCenterImage
                    backgroundColor={currentHcThemeColor}
                    src={backgroundImage || defaultBannerImage}
                    height={24}
                    width={24}
                    alt={formatMessage(messages.helpCenterLabel)}
                />
            }
            description={formatMessage(messages.helpCenterLabel)}
        >
            {currentHcName}
        </Header>
    );
};

interface HelpCenterImageProps {
    backgroundColor?: string | null;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const HelpCenterImage = styled.img<HelpCenterImageProps>({
    borderRadius: '3px',
    objectFit: 'cover',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Disabled to rollout go/ui-styling-standard tooling, please resolve
    backgroundColor: ({ backgroundColor }) =>
        !backgroundColor ? token('color.background.brand.bold', '#0074e0') : backgroundColor,
});
export default SidebarHelpCenterHeader;
