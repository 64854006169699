import { defineMessages } from 'react-intl-next';

export default defineMessages({
    exploreAll: {
        defaultMessage: 'Show more portals ({remainingCount, number})',
        description: 'Text message for Explore all button to load additional project cards',
        id: 'cv.portal.card.layout.list.explore.all.button',
    },
    collapseOtherProjects: {
        defaultMessage: 'Show fewer',
        description: 'Text message for Collapse other projects button to collapse other projects section',
        id: 'cv.portal.card.layout.list.collapse.button',
    },
    featuredPortalsLabel: {
        defaultMessage: 'Featured portals',
        description: 'Text message for featured portals section',
        id: 'cv.portal.card.layout.list.featured.portals.title',
    },
    remainingProjectsByName: {
        defaultMessage: 'More portals',
        description: 'Remaining projects sorted by name',
        id: 'cv.portal.card.layout.list.remaining.projects.by.name.title',
    },
    remainingPortals: {
        defaultMessage: 'Portals',
        description: 'Remaining portals',
        id: 'cv.portal.card.layout.list.remaining.portals',
    },
});
