import { defineMessages } from 'react-intl-next';

export default defineMessages({
    limitErrorFlagTitle: {
        defaultMessage: 'You’ve already featured {limit, plural, one {# portal} other {# portals}}',
        description: 'Limit error flag title',
        id: 'cv.portal.reordering.experience.limit.error.title.v2',
    },
    limitErrorFlagDescription: {
        defaultMessage: 'Remove a portal from the featured section to add another one.',
        description: 'Limit error flag description',
        id: 'cv.portal.reordering.experience.limit.error.description.v2',
    },
    authPageMessage: {
        defaultMessage: `You don't have permission to view this page.`,
        description: 'Message text for unauthorized page of portal reordering',
        id: 'cv.portal.reordering.experience.un.auth.action.message',
    },
    callToActionUrlText: {
        defaultMessage: 'Go to help center',
        description: 'Call to action text for unauthorized page of portal reordering',
        id: 'cv.portal.reordering.experience.un.auth.action.text',
    },
    portalSectionHeader: {
        defaultMessage: 'Portals',
        description: 'This section divides the topic from the service desk edit section',
        id: 'cv.portal.reordering.experience.service.desk.section.header.text',
    },
    publishTopicsErrorTitle: {
        defaultMessage: 'We couldn’t publish your changes',
        description: 'Title of the message shown when publishing fails',
        id: 'cv.portal.reordering.experience.topics.publish.error.title',
    },
    publishTopicsErrorDescription: {
        defaultMessage: 'Refresh the page and try publishing again.',
        description: 'Description of the message shown shown when publishing fails',
        id: 'cv.portal.reordering.experience.topics.publish.error.description',
    },
    portalsHiddenWarningTitle: {
        defaultMessage: 'Portals are hidden from your home page',
        description: 'Warning title when the portals section is hidden.',
        id: 'cv.portal.reordering.experience.portals.hidden.warning.title',
    },
    portalsHiddenWarningDescription: {
        defaultMessage:
            'The portals section on your home page is hidden. Edit page layout to show portals on the home page.',
        description: 'Warning description when the portals section is hidden.',
        id: 'cv.portal.reordering.experience.portals.hidden.warning.description',
    },
    editPageLayoutAction: {
        defaultMessage: 'Edit page layout',
        description: 'Action to show the portals section.',
        id: 'cv.portal.reordering.experience.portals.hidden.warning.action',
    },
});
