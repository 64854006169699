import { defineMessages } from 'react-intl-next';

export default defineMessages({
    actionBarTitle: {
        defaultMessage: 'Edit home page layout',
        description: 'Title for portal reorder experience action bar',
        id: 'sd.portal.reorder.experience.action.bar.with.buttons.title',
    },
    actionBarTitleNew: {
        defaultMessage: 'Manage topics and portals',
        description: 'Title for portal reorder experience action bar',
        id: 'sd.portal.reorder.experience.action.bar.with.buttons.title.topics.portals',
    },
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    actionBarTitleCSM: {
        defaultMessage: 'Manage topics',
        description: 'Title for portal reorder experience action bar',
        id: 'sd.portal.reorder.experience.action.bar.with.buttons.title-csm.topics.portals',
    },
    cancelButtonLabel: {
        defaultMessage: 'Cancel',
        description: 'Label of cancel button of portal reorder experience action bar',
        id: 'sd.portal.reorder.experience.action.bar.with.buttons.button.cancel',
    },
    publishButtonLabel: {
        defaultMessage: 'Publish layout',
        description: 'Label of publish button of portal reorder experience action bar',
        id: 'sd.portal.reorder.experience.action.bar.with.buttons.button.publish',
    },
});
