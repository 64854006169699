import React from 'react';
import { useIntl } from 'react-intl-next';
import { ButtonGroup } from '@atlaskit/button';
import { FormFooter } from '@atlaskit/form';
import { Button, LoadingButton } from '@atlassian/help-center-common-component/analytics/button';
import messages from './messages';

export interface HelpCenterFormFooterProps {
    submitting: boolean;
    mode: 'CREATE' | 'EDIT';
    onCancel: () => void;
    isCancelDisabled?: boolean;
    isStandaloneForm?: boolean;
}

export const HelpCenterFormFooter = ({
    submitting,
    mode,
    onCancel,
    isStandaloneForm = false,
    isCancelDisabled = false,
}: HelpCenterFormFooterProps) => {
    const { formatMessage } = useIntl();
    const renderCancelButton = () => (
        <Button
            isDisabled={isCancelDisabled}
            onClick={onCancel}
            actionSubjectId={mode === 'CREATE' ? 'helpCenterCancelCreateButton' : 'helpCenterCancelEditButton'}
            key="cancel"
        >
            {formatMessage(messages.helpCenterCancelButtonText)}
        </Button>
    );
    const renderSaveButton = () => (
        <LoadingButton
            type="submit"
            appearance="primary"
            isLoading={submitting}
            actionSubjectId={mode === 'CREATE' ? 'helpCenterCreateButton' : 'helpCenterSaveButton'}
            testId="help-centers-management-primary-button"
            key="save"
        >
            {formatMessage(mode === 'CREATE' ? messages.helpCenterCreateButtonText : messages.helpCenterSaveButtonText)}
        </LoadingButton>
    );
    return (
        <FormFooter align={isStandaloneForm ? 'start' : 'end'}>
            <ButtonGroup>
                {isStandaloneForm
                    ? [renderSaveButton(), renderCancelButton()]
                    : [renderCancelButton(), renderSaveButton()]}
            </ButtonGroup>
        </FormFooter>
    );
};
