import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { State } from 'state';
import { isLoggedIn, getUserState } from 'state/selectors/user';
import PortalAccessForbidden from './portal-access-forbidden';

const selector = createSelector(
    (state: State) => isLoggedIn(state),
    (state: State) => getUserState(state),
    (isUserLoggedIn, userState) => ({
        isUserLoggedIn,
        userEmail: userState.email || '',
    })
);

export default connect(selector)(PortalAccessForbidden);
