import { buildModelsActionCreator } from 'epics/model/actions';
import type { ApprovalListFilterResponse } from 'rest/approval-list';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_APPROVAL_LIST_MODEL = 'epic.model.FETCH_APPROVAL_LIST_MODEL';
export const FETCH_APPROVAL_LIST_SUCCESS = 'state.persisted.approval.List.FETCH_APPROVAL_LIST_SUCCESS';
export const FETCH_APPROVAL_LIST_FAILURE = 'state.persisted.approval.List.FETCH_APPROVAL_LIST_FAILURE';

export type FetchApprovalListAction = ReturnType<typeof fetchApprovalListModel>;

export const fetchApprovalListModel = buildModelsActionCreator(FETCH_APPROVAL_LIST_MODEL, 'approvalListFilter');

export interface FetchApprovalListSuccess {
    type: typeof FETCH_APPROVAL_LIST_SUCCESS;
    payload?: ApprovalListFilterResponse;
}

export const fetchApprovalListSuccess = (
    approvalListFilter?: ApprovalListFilterResponse
): FetchApprovalListSuccess => ({
    type: FETCH_APPROVAL_LIST_SUCCESS,
    payload: approvalListFilter,
});

export interface FetchApprovalListFailure {
    type: typeof FETCH_APPROVAL_LIST_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchApprovalListFailure = (error: ErrorResponse): FetchApprovalListFailure => ({
    type: FETCH_APPROVAL_LIST_FAILURE,
    payload: {
        error,
    },
});
