import { defineMessages } from 'react-intl-next';

export default defineMessages({
    featuredProjectTooltip: {
        defaultMessage: 'Remove from featured section',
        description: 'Tootip message when hovered on remove from featured button',
        id: 'cv.portal.reordering.experience.featured.card.tooltip',
    },
    unFeaturedProjectTooltip: {
        defaultMessage: 'Feature this portal',
        description: 'Tootip message when hovered on feature portal button',
        id: 'cv.portal.reordering.experience.unfeatured.card.tooltip',
    },
    visibleProjectTooltip: {
        defaultMessage: 'Hide from home page',
        description: 'Tootip message when hovered on hide button',
        id: 'cv.portal.reordering.experience.shown.card.tooltip.non-final',
    },
    hiddenProjectTooltip: {
        defaultMessage: 'Show on home page',
        description: 'Tootip message when hovered on show project button',
        id: 'cv.portal.reordering.experience.hidden.card.tooltip.non-final',
    },
    screenReaderInstructionsForReordering: {
        defaultMessage:
            'Press space to pick up a portal. Use the up and down arrow keys to move it to a new position. Press space or enter to drop it in a new position, or press escape to cancel',
        description: 'message to be readout by screen reader when a project card is focused',
        id: 'cv.portal.reordering.experience.screen.reader.instruction',
    },
});
