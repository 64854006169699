import type { MetaState } from 'state/persisted/meta';

export const UPDATE_META = 'state.persisted.env.UPDATE_META';

export interface UpdateMetaAction {
    payload: MetaState;
    type: typeof UPDATE_META;
}

export const updateMetaAction = (meta: MetaState): UpdateMetaAction => ({
    payload: meta,
    type: UPDATE_META,
});
