/**
 * Epic for performing the search request
 */
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type {
    UpdateSearchResultsAction,
    ClearSearchResultsAction,
    SearchAction,
    FetchSearchResultsAction,
    UpdateSearchResultsByTypeAction,
    FetchKbSearchAction,
    SearchType,
} from 'state/actions/search';
import {
    fetchSearchResultsAction,
    updateSearchResultsByType,
    clearSearchResults,
    SEARCH,
    CLEAR_SEARCH_RESULTS,
    FETCH_SEARCH_RESULTS,
    FETCH_KB_SEARCH_RESULTS,
} from 'state/actions/search';
import { USER_TYPING_DEBOUNCE_TIME } from '@atlassian/help-center-common-component/constants';
import type { SearchProvider, SearchProviderResponse } from '@atlassian/help-center-common-util/search-providers';
import {
    KbSearchProvider,
    RequestTypeSearchProvider,
    PortalSearchProvider,
} from '@atlassian/help-center-common-util/search-providers';

const SEARCH_PROVIDERS: SearchProvider[] = [KbSearchProvider, RequestTypeSearchProvider, PortalSearchProvider];

export const searchEpic: Epic<SearchAction, ClearSearchResultsAction | FetchSearchResultsAction> = (action$) => {
    return action$.ofType(SEARCH).map((action) => {
        const { meta, term: query } = action.payload;
        if (!query || query.trim() === '') {
            return clearSearchResults();
        }

        return fetchSearchResultsAction(query, performance.now(), meta);
    });
};

export const fetchSearchResultsEpic: Epic<
    FetchSearchResultsAction | ClearSearchResultsAction,
    UpdateSearchResultsAction | UpdateSearchResultsByTypeAction
> = (action$, store) => {
    return action$
        .ofType(FETCH_SEARCH_RESULTS)
        .debounceTime(USER_TYPING_DEBOUNCE_TIME)
        .switchMap(
            (
                fetchAction: FetchSearchResultsAction | ClearSearchResultsAction
            ): Observable<UpdateSearchResultsAction | UpdateSearchResultsByTypeAction> => {
                // This cast is necessary otherwise TS doesn't know that this action can only be a FetchSearchResultsAction
                const action = fetchAction as FetchSearchResultsAction;
                const state = store.getState();
                const { term, meta } = action.payload;
                const observablesToWaitFor = SEARCH_PROVIDERS.map((provider) => provider.execute(term, state, meta));

                return Observable.from(observablesToWaitFor).mergeMap(
                    (observable: Observable<SearchProviderResponse>) => {
                        return observable.map((response: SearchProviderResponse) =>
                            updateSearchResultsByType(response, Object.keys(response)[0] as SearchType)
                        );
                    }
                );
            }
        )
        .takeUntil(action$.ofType(CLEAR_SEARCH_RESULTS))
        .repeat();
};

export const fetchKbSearchResultsEpic: Epic<
    FetchKbSearchAction | ClearSearchResultsAction,
    UpdateSearchResultsAction | UpdateSearchResultsByTypeAction
> = (action$, store) => {
    return action$
        .ofType(FETCH_KB_SEARCH_RESULTS)
        .debounceTime(USER_TYPING_DEBOUNCE_TIME)
        .switchMap((fetchAction) => {
            const action = fetchAction as FetchKbSearchAction;
            const state = store.getState();
            const { term, meta, limit, expandPortal, categoryIds, portalIds } = action.payload;

            return KbSearchProvider.execute(term, state, meta, limit, expandPortal, categoryIds, portalIds).map(
                (response: SearchProviderResponse) =>
                    updateSearchResultsByType(response, Object.keys(response)[0] as SearchType)
            );
        })
        .takeUntil(action$.ofType(CLEAR_SEARCH_RESULTS))
        .repeat();
};
