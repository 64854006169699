import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import { transition } from 'rest/transition';
import type {
    TransitionRequest,
    FetchRequestDetailsAction,
    TransitionRequestFailure,
} from 'state/actions/request-details';
import { TRANSITION_REQUEST, fetchRequestDetailsModel, transitionRequestFailure } from 'state/actions/request-details';
import { TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { sendEvent } from '@atlassian/help-center-common-util/analytics';

import { getJiraErrorOrDefault } from '@atlassian/help-center-common-util/errors';
import { getProjectId } from '../../../state/selectors/portal';

export const transitionRequestEpic: Epic<TransitionRequest, FetchRequestDetailsAction | TransitionRequestFailure> = (
    action$,
    store
) => {
    return action$.ofType(TRANSITION_REQUEST).mergeMap((transitionAction) => {
        const payload = transitionAction.payload;
        return transition(payload.requestKey, payload.transitionId, payload.comment)
            .map(() => {
                const successEvent = payload.createAnalyticsEvent?.({
                    analyticsType: TRACK_EVENT_TYPE,
                    action: 'updated',
                    actionSubject: 'customerTransition',
                    projectId: getProjectId(store.getState(), payload.portalId),
                });
                successEvent && sendEvent(successEvent);

                return fetchRequestDetailsModel({
                    portalId: payload.portalId,
                    key: payload.requestKey,
                });
            })
            .catch((error: AjaxError) => {
                const failEvent = payload.createAnalyticsEvent?.({
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                    action: 'failed',
                    actionSubject: 'customerTransition',
                });
                failEvent && sendEvent(failEvent);

                return Observable.of(transitionRequestFailure(getJiraErrorOrDefault(error)));
            });
    });
};
