import { debounce } from 'lodash';
import type { ResourceContext } from '@atlassian/help-center-common-component/forge-ui/types';
interface Options {
    leading?: boolean;
    maxWait?: number;
    trailing?: boolean;
}

const defer = <TReturnValue extends object>() => {
    let resolve: (value?: TReturnValue | PromiseLike<TReturnValue> | undefined) => void = () => {
        throw new Error('Resolve function not implememnted');
    };
    let reject: (reason?: unknown) => void = () => {
        throw new Error('Reject function not implemented');
    };

    const promise = new Promise<TReturnValue>((rslv, rjct) => {
        resolve = rslv;
        reject = rjct;
    });

    return {
        promise,
        resolve,
        reject,
    };
};

const proxyFunctionV2 = <TReturnValue extends object, TArg, TArg2 extends ResourceContext>(
    func: (arg: TArg[], resourceContext?: TArg2) => Promise<TReturnValue>,
    wait: number,
    options: Options = {}
) => {
    const arrMap = new Map<string, TArg[]>();
    const auxillaryMap = new Map<string, TArg2>();
    let deferred = defer<TReturnValue>();

    const callThenResetArgs = (arrMap1: Map<string, TArg[]>, auxillaryMap1: Map<string, TArg2>) => {
        try {
            const promiseArr: Promise<TReturnValue>[] = [];
            arrMap1.forEach((value, key) => promiseArr.push(func(value, auxillaryMap1.get(key))));
            promiseArr.forEach((value) => {
                deferred.resolve(value);
            });
        } catch (err) {
            deferred.reject(err);
        }
        arrMap.clear();
        auxillaryMap.clear();
        deferred = defer<TReturnValue>();
    };

    // Suppressing existing violations for the testing-library/react plugin.
    // eslint-disable-next-line testing-library/await-async-utils
    const debouncedFunc = debounce(callThenResetArgs, wait, options);

    return (arg: TArg, resourceContext?: TArg2): Promise<TReturnValue> => {
        const { resourceId } = resourceContext || {};
        if (!resourceContext || resourceId === undefined) {
            return deferred.promise;
        }
        if (arrMap.has(resourceId)) {
            if (!arrMap.get(resourceId)?.includes(arg)) {
                arrMap.get(resourceId)?.push(arg);
            }
        } else {
            const arr: TArg[] = [];
            arr.push(arg);
            arrMap.set(resourceId, arr);
            auxillaryMap.set(resourceId, resourceContext);
        }
        debouncedFunc(arrMap, auxillaryMap);
        return deferred.promise;
    };
};

export default proxyFunctionV2;
