import FeatureGates from '@atlaskit/feature-gate-js-client';
import { addFeatureFlagAccessed } from '@atlaskit/react-ufo/feature-flags-accessed';

/*
These are the feature flags data that we want to embed into UFO.
This FF data will be recorded in perf portal as well.
*/
const UFO_FEATURE_FLAGS = ['advanced_help_center_customization'];

export const embedFFDataToUFO = () => {
    UFO_FEATURE_FLAGS.forEach((featureFlag) => {
        const ffValue = FeatureGates.checkGate(featureFlag);
        addFeatureFlagAccessed(featureFlag, ffValue);
    });
};
