/* Note: these messages are not final */

import { defineMessages } from 'react-intl';

export default defineMessages({
    commentCreateWithMentionsFailedTitle: {
        defaultMessage: 'We couldn’t add this comment',
        description: 'Comment containing user mentions failed to create title',
        id: 'sd.comment.create.with.mentions.failed.title',
    },
    commentCreateWithMentionsFailedDescription: {
        defaultMessage: 'Try adding it again in a few minutes. ',
        description: 'Comment containing user mentions failed to create description',
        id: 'sd.comment.create.with.mentions.failed.description',
    },
});
