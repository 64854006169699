export const fetchJson = async <T>(
    input: string,
    method: NonNullable<RequestInit['method']>,
    options?: {
        body?: RequestInit['body'] | undefined;
        headers?: Record<string, string>;
    }
): Promise<T> => {
    const response = await fetch(input, {
        method,
        ...options,
        headers: {
            ...options?.headers,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw {
            error: new Error(`Request failed: ${response.status} ${response.statusText}`),
            status: response.status,
        };
    }

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data: T = await response.json();
    return data;
};
