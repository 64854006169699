import type { Action, MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import type { Observable } from 'rxjs/Observable';
import type { State } from 'state';
/* eslint-disable no-restricted-imports */
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/never';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/bufferTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/race';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/timeoutWith';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/takeUntil';
/* eslint-enable no-restricted-imports */

export { Subject } from 'rxjs/Subject';
export { ReplaySubject } from 'rxjs/ReplaySubject';
export { Observable } from 'rxjs/Observable';
export { AjaxObservable, AjaxError } from 'rxjs/observable/dom/AjaxObservable';
export { forkJoin } from 'rxjs/observable/forkJoin';
export { concat } from 'rxjs/observable/concat';
export type { Observer } from 'rxjs/Observer';
export { Subscription } from 'rxjs/Subscription';

/**
 * Extensions to the type definition for redux-observable
 */

export type Epic<T extends Action = Action, R extends Action = T, S = State> = (
    action$: ActionsObservable<T>,
    store: MiddlewareAPI<S>
) => Observable<R>;
