import fetchModel from 'epics/model/fetcher';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import type {
    FetchApprovalListAction,
    FetchApprovalListSuccess,
    FetchApprovalListFailure,
} from 'state/actions/approval-list';
import {
    FETCH_APPROVAL_LIST_MODEL,
    fetchApprovalListSuccess,
    fetchApprovalListFailure,
} from 'state/actions/approval-list';
import type { ErrorResponse } from 'epics/model/types';

export const approvalListEpic: Epic<FetchApprovalListAction, FetchApprovalListSuccess | FetchApprovalListFailure> = (
    action$
) => {
    return action$.ofType(FETCH_APPROVAL_LIST_MODEL).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.approvalListFilter && !!response.approvalListFilter.requests)
            .map((response) => {
                return fetchApprovalListSuccess(response.approvalListFilter);
            })
            .catch((error: ErrorResponse) => {
                return Observable.of(fetchApprovalListFailure(error));
            })
    );
};
