export type { UpdateBrandingBody, UpdateAnnouncementBody, UploadTokenWithClientInfo } from './help-center-branding';
export {
    BrandingTargetCollections,
    getUploadToken,
    getUploadTokenWithClientInfo,
    updateBranding,
    updateAnnouncement,
} from './help-center-branding';

export type { HCBrandingPortalTranslationResponse, HCBrandingResponse } from './types';
