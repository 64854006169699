/**
 * @generated SignedSource<<17333612a0940128db0066a8cb2fab6a>>
 * @relayHash 6466481040e3141b45532075b56408d6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b1dd5c9d9a158a325624ebe4af55ee2c9f41049eb71382692b4f832b9fa0f694

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JsmChatCreateWebConversationMessageContentType = "ADF" | "%future added value";
export type JsmChatCreateWebConversationUserRole = "Acknowledgment" | "Assistant_Error" | "Assistant_Message" | "ChannelMessage" | "CombinedAPIError" | "Error" | "Followup_Message" | "Init" | "InteractionError" | "InteractionMessage" | "JSM_Agent" | "Participant" | "Reporter" | "VirtualAgent" | "%future added value";
export type createConversationWithMessageMutation$variables = {
  conversationContextAri: string;
  isTestChannel?: boolean | null | undefined;
  message: string;
  subscriptionId: string;
  workspaceAri: string;
};
export type createConversationWithMessageMutation$data = {
  readonly jsmChat: {
    readonly initializeAndSendMessage: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly initializeAndSendMessageResponse: {
        readonly conversation: {
          readonly node: {
            readonly authorType: JsmChatCreateWebConversationUserRole;
            readonly content: AGG$JSON;
            readonly contentType: JsmChatCreateWebConversationMessageContentType;
            readonly id: string;
          } | null | undefined;
        } | null | undefined;
        readonly conversationId: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type createConversationWithMessageMutation = {
  response: createConversationWithMessageMutation$data;
  variables: createConversationWithMessageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "conversationContextAri"
},
v1 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isTestChannel"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriptionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceAri"
},
v5 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "channelExperienceId",
        "value": "HELPCENTER"
      },
      {
        "kind": "Variable",
        "name": "conversationContextAri",
        "variableName": "conversationContextAri"
      },
      {
        "kind": "Variable",
        "name": "isTestChannel",
        "variableName": "isTestChannel"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "subscriptionId",
        "variableName": "subscriptionId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  },
  {
    "kind": "Variable",
    "name": "workspaceAri",
    "variableName": "workspaceAri"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "success"
},
v7 = {
  "kind": "ScalarField",
  "name": "message"
},
v8 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "concreteType": "JsmChatInitializeAndSendMessageResponse",
  "kind": "LinkedField",
  "name": "initializeAndSendMessageResponse",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "conversationId"
    },
    {
      "concreteType": "JsmChatMessageEdge",
      "kind": "LinkedField",
      "name": "conversation",
      "plural": false,
      "selections": [
        {
          "concreteType": "JsmChatCreateWebConversationMessage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "kind": "ScalarField",
              "name": "content"
            },
            {
              "kind": "ScalarField",
              "name": "authorType"
            },
            {
              "kind": "ScalarField",
              "name": "contentType"
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "createConversationWithMessageMutation",
    "selections": [
      {
        "concreteType": "JsmChatMutation",
        "kind": "LinkedField",
        "name": "jsmChat",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JsmChatInitializeAndSendMessagePayload",
            "kind": "LinkedField",
            "name": "initializeAndSendMessage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ]
                  }
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "createConversationWithMessageMutation",
    "selections": [
      {
        "concreteType": "JsmChatMutation",
        "kind": "LinkedField",
        "name": "jsmChat",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JsmChatInitializeAndSendMessagePayload",
            "kind": "LinkedField",
            "name": "initializeAndSendMessage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              (v10/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b1dd5c9d9a158a325624ebe4af55ee2c9f41049eb71382692b4f832b9fa0f694",
    "metadata": {},
    "name": "createConversationWithMessageMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b29d0beea262991f003cc1fee9761127";

export default node;
