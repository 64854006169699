import { defineMessages } from 'react-intl-next';

export default defineMessages({
    // eslint-disable-next-line rulesdir/i18n-unique-message-ids
    skipToMainA11yLabel: {
        defaultMessage: 'Skip to Main Content',
        description: 'A11y Label for Skip to Main Content',
        id: 'sd.view.common.skip.links.main-content-skip-link.a11y-label',
    },
});
