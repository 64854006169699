import { get } from 'epics/ajax';
import { contextPath } from '@atlassian/help-center-common-util/history';

export interface ServiceProjectRequestPermissionsResponseType {
    isEnabled: boolean;
    canCreateProject: boolean;
}

export const serviceProjectRequestPermissions = (basePath: string = contextPath) => {
    const uri = `${basePath}/rest/servicedesk/1/customer/new-service-desk-request/permissions`;

    const headers = {
        Accept: 'application/json',
    };

    return (
        get(uri, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e) => Promise.reject(e))
    );
};
