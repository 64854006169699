import React from 'react';
import { isNewTextFieldEnabled, isProjectMappingP1BugsEnabled } from 'feature-flags';
import { useIntl } from 'react-intl-next';
import { ErrorMessage, Field } from '@atlaskit/form';
import InfoIcon from '@atlaskit/icon/core/information';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import TextFieldNext from '@atlaskit/textfield--next';
import Tooltip from '@atlaskit/tooltip';
import { HELPCENTER_NAME_CHARACTER_LIMIT } from '../../../constants';
import errorMessages from '../messages';
import messages from './messages';
const SLUG_VALIDATION_REGEX = /^[a-zA-Z0-9_-]{1,30}$/;

export interface HelpCenterFormFieldsProps {
    name?: string;
    slug?: string;
    urlWithoutSlug: string;
    mode: 'CREATE' | 'EDIT';
}
const SlugInfo = () => {
    const { formatMessage } = useIntl();
    return (
        <Box xcss={slugWarningStyles}>
            <Tooltip content={formatMessage(messages.helpCenterSlugInfoTooltip)} position="top-start">
                <InfoIcon label={formatMessage(messages.helpCenterSlugInfoLabel)} />
            </Tooltip>
        </Box>
    );
};
export const HelpCenterFormFields = ({ name, slug, urlWithoutSlug, mode }: HelpCenterFormFieldsProps) => {
    const { formatMessage } = useIntl();

    const validateHelpCenterName = (value: string | undefined) => {
        if (!value || value.length <= 0) {
            return formatMessage(errorMessages.helpCenterNameRequiredError);
        }
        if (value && value.length > HELPCENTER_NAME_CHARACTER_LIMIT) {
            return formatMessage(errorMessages.helpCenterNameCharacterLimitError);
        }
        return undefined;
    };

    const validateHelpCenterSlug = (value: string | undefined) => {
        if (!value || value.length <= 0) {
            return formatMessage(errorMessages.helpCenterSlugRequiredError);
        }
        if (value && value.length > HELPCENTER_NAME_CHARACTER_LIMIT) {
            return formatMessage(errorMessages.helpCenterSlugCharacterLimitError);
        }
        if (!SLUG_VALIDATION_REGEX.test(value)) {
            return formatMessage(errorMessages.helpCenterSlugSpecialCharacterError);
        }
        return undefined;
    };

    return (
        <Stack space="space.250">
            <Field
                aria-required
                name="name"
                key="name"
                label={formatMessage(messages.helpCenterNameTextLabel)}
                defaultValue={name ?? ''}
                validate={validateHelpCenterName}
                isRequired
            >
                {({ fieldProps, error }) => (
                    <Box paddingBlockStart="space.150">
                        {isNewTextFieldEnabled() ? (
                            <TextFieldNext
                                {...fieldProps}
                                autoComplete="off"
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus
                                placeholder={formatMessage(messages.helpCenterNameTextFieldPlaceholder)}
                                testId="help-centers-management-name-field"
                            />
                        ) : (
                            <TextField
                                {...fieldProps}
                                autoComplete="off"
                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                autoFocus
                                placeholder={formatMessage(messages.helpCenterNameTextFieldPlaceholder)}
                                testId="help-centers-management-name-field"
                            />
                        )}

                        {error && <ErrorMessage>{error}</ErrorMessage>}
                    </Box>
                )}
            </Field>
            {mode === 'CREATE' || slug ? (
                <Field
                    aria-required
                    name="slug"
                    key="slug"
                    label={formatMessage(messages.helpCenterSlugTextLabel)}
                    defaultValue={slug ?? ''}
                    validate={validateHelpCenterSlug}
                    elementAfterLabel={isProjectMappingP1BugsEnabled() && mode !== 'CREATE' && <SlugInfo />}
                    isRequired
                >
                    {({ fieldProps, error }) => (
                        <Inline grow="fill" shouldWrap>
                            <Box paddingBlockStart="space.100" paddingInlineEnd="space.100">
                                <Text color="color.text">
                                    {urlWithoutSlug}
                                    {'/'}
                                </Text>
                            </Box>
                            <Box xcss={slugTextFieldStyles}>
                                {isNewTextFieldEnabled() ? (
                                    <TextFieldNext
                                        autoComplete="off"
                                        {...fieldProps}
                                        placeholder={formatMessage(messages.helpCenterSlugTextFieldPlaceholder)}
                                        testId="help-centers-management-slug-field"
                                    />
                                ) : (
                                    <TextField
                                        autoComplete="off"
                                        {...fieldProps}
                                        placeholder={formatMessage(messages.helpCenterSlugTextFieldPlaceholder)}
                                        testId="help-centers-management-slug-field"
                                    />
                                )}

                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Box>
                        </Inline>
                    )}
                </Field>
            ) : null}
        </Stack>
    );
};

const slugTextFieldStyles = xcss({ width: '10%', flexGrow: 1 });

const slugWarningStyles = xcss({ display: 'inline-flex', marginLeft: 'space.050', verticalAlign: 'middle' });
