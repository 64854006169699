import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
import { AvatarLoadingSkeleton } from '../../loading/styled';

export const ForgeModalContextProvider = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: "forge-modal-context-provider" */ './modal-context-provider')
    ),
    LoadingSkeleton: AvatarLoadingSkeleton,
});
