import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { resetPassword } from 'rest/reset-password';
import type { LoginAttempt } from 'state/actions/login';
import { loginAttempt } from 'state/actions/login';
import type { ResetPassword, ResetPasswordFailure } from 'state/actions/reset-password';
import { resetPasswordFailure, RESET_PASSWORD } from 'state/actions/reset-password';
import { getDefaultError } from '@atlassian/help-center-common-util/errors';

export const resetPasswordEpic: Epic<ResetPassword, ResetPasswordFailure | LoginAttempt> = (action$) => {
    return action$.ofType(RESET_PASSWORD).mergeMap((resetPasswordAction) => {
        const { emailAddress, password, token } = resetPasswordAction.payload;

        return resetPassword(emailAddress, password, token)
            .map(() => {
                return loginAttempt(emailAddress, password);
            })
            .catch((error: AjaxError) => {
                return Observable.of(resetPasswordFailure(getDefaultError(error)));
            });
    });
};
