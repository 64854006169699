export const sizes = {
    xxlarge: 1600,
    xlarge: 1440,
    large: 1280,
    medium: 960,
    small: 840,
    xsmall: 600,
    xxsmall: 480,
    tablet: 800,
};
