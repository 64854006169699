import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';
export const PremiumEnterpriseAdminsChangeBoarding = withAsync({
    loader: lazyForPaint(
        () =>
            import(
                /* webpackChunkName: "premium-enterprise-admins-changeboarding" */ './premium-enterprise-admins-changeboarding'
            )
    ),
    LoadingSkeleton: () => null,
});
