import { post } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { CreateCommentResponse } from './types';

export const createComment = (
    issueId: number,
    comment: string,
    fileIds: string[],
    xsrfToken: string,
    context: string = contextPath
): Observable<CreateCommentResponse> => {
    const uri = `${context}/rest/servicedesk/1/servicedesk/customer/comment`;
    const body = {
        issueId,
        comment,
        fileIds,
        xsrfToken,
    };
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(uri, body, headers)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map((ajaxResponse) => ajaxResponse.response)
            .catch((e) => {
                return Observable.throw(e);
            })
    );
};
