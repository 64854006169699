import type { State } from 'state';

export const getBlockedAppsBannerStatePortal = (state: State): boolean => {
    return state.persisted.blockedAppsBanner.portal || state.persisted.blockedAppsBanner.connect.portal;
};

export const getBlockedAppsBannerStateRequestView = (state: State): boolean => {
    return state.persisted.blockedAppsBanner.view_request || state.persisted.blockedAppsBanner.connect.view_request;
};

export const getBlockedAppsBannerStateCreateRequest = (state: State): boolean => {
    return state.persisted.blockedAppsBanner.create_request || state.persisted.blockedAppsBanner.connect.create_request;
};
