import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type {
    FetchRequestTypeSuggestionAction,
    FetchRequestTypeSuggestionSuccess,
} from 'state/actions/request-type-suggestion';
import {
    FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL,
    fetchRequestTypeSuggestionSuccess,
} from 'state/actions/request-type-suggestion';
import fetchModel from '../fetcher';

export const requestTypeSuggestionEpic: Epic<FetchRequestTypeSuggestionAction, FetchRequestTypeSuggestionSuccess> = (
    action$
) => {
    return action$.ofType(FETCH_REQUEST_TYPE_SUGGESTIONS_MODEL).mergeMap((action) =>
        fetchModel(action.payload, {
            type: 'secondary',
        })
            .filter((response) => !!response.suggestedRequestTypes)
            .map((response) => fetchRequestTypeSuggestionSuccess(response.suggestedRequestTypes!))
            // we're casting to success here as we swallow any errors for now
            .catch((_) => Observable.never() as Observable<FetchRequestTypeSuggestionSuccess>)
    );
};
