/**
 *  Adapted from JFE:
 *  https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/browse/src/packages/common-legacy-do-not-add-anything-new/src/util/get-feature-flag-value.js
 *
 */

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { setBooleanFeatureFlagResolver } from '@atlaskit/platform-feature-flags';

export const initialisePlatformFeatureFlags = () => {
    setBooleanFeatureFlagResolver((flagKey) => FeatureGates.checkGate(flagKey));
};
