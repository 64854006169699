import { defineMessages } from 'react-intl-next';

export default defineMessages({
    projectsListLinkedLozengeLabel: {
        defaultMessage: 'Linked',
        description: 'Projects list linked lozenge label',
        id: 'projects.list.linked.lozenge.label',
    },
    projectsListUnlinkedLozengeLabel: {
        defaultMessage: 'Unlinked',
        description: 'Projects list unlinked lozenge label',
        id: 'projects.list.unlinked.lozenge.label',
    },
    projectsListLinkButtonText: {
        defaultMessage: 'Link',
        description: 'Projects list link button text',
        id: 'projects.list.link.button.text',
    },
    projectsListUnlinkButtonText: {
        defaultMessage: 'Unlink',
        description: 'Projects list unlink button text',
        id: 'projects.list.unlink.button.text',
    },
    noUnlinkedProjectsDescription: {
        defaultMessage: 'When you unlink projects, they’ll appear here.',
        description: 'Projects list empty state description',
        id: 'projects.list.empty.description',
    },
    noUnlinkedProjectsTitle: {
        defaultMessage: 'All your projects are linked to this help center',
        description: 'Projects list empty state title',
        id: 'projects.list.empty.title',
    },
    noLinkedProjectsDescription: {
        defaultMessage: 'When you link projects, they’ll appear here.',
        description: 'Projects list empty state description on linked tab',
        id: 'projects.list.empty.description.linked.tab',
    },
    noLinkedProjectsTitle: {
        defaultMessage: 'All your projects have been unlinked from this help center',
        description: 'Projects list empty state title on linked tab',
        id: 'projects.list.empty.title.linked.tab',
    },
    projectsListEmptySearchTitle: {
        defaultMessage: 'No projects found matching your search',
        description: 'Projects list empty search title',
        id: 'projects.list.empty.search.title',
    },
    projectsListEmptySearchDescription: {
        defaultMessage: 'Try using specific project names or terms',
        description: 'Projects list empty search description',
        id: 'projects.list.empty.search.description',
    },
    selectAllProjectsCheckboxLabel: {
        defaultMessage: 'Select all projects',
        description: 'Checkbox label for selecting all projects',
        id: 'projects.list.select-all.projects.checkbox.label',
    },
    selectProjectCheckboxLabel: {
        defaultMessage: 'Select project',
        description: 'Checkbox label for selecting a single project',
        id: 'projects.list.select.project.checkbox.label',
    },
});
