import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';
import type { ProfileConnectFragmentsResponse } from 'rest/connect-addon';
import type {
    FetchConnectFragmentsAction,
    FetchProfileConnectFragmentsAction,
    FetchConnectFragmentsSuccess,
    FetchConnectFragmentsFailure,
    FetchProfileConnectFragmentsFailure,
    FetchProfileConnectFragmentsSuccess,
} from 'state/actions/connect-addon';
import {
    FETCH_CONNECT_FRAGMENTS,
    FETCH_PROFILE_CONNECT_FRAGMENTS,
    fetchConnectFragmentsSuccess,
    fetchConnectFragmentsFailure,
    fetchProfileConnectFragmentsFailure,
    fetchProfileConnectFragmentsSuccess,
} from 'state/actions/connect-addon';
import fetchModel from '../fetcher';
import type { ErrorResponse } from '../types';

export const connectFragmentsEpic: Epic<
    FetchConnectFragmentsAction,
    FetchConnectFragmentsSuccess | FetchConnectFragmentsFailure
> = (action$) =>
    action$.ofType(FETCH_CONNECT_FRAGMENTS).mergeMap((action) =>
        fetchModel(action.payload, {
            type: action.payload.params.portalPage === 'CREATE_REQUEST' ? 'secondary' : 'primary',
        })
            .filter((response) => !!response.portalWebFragments)
            .map((response) => {
                const { portalPage, portalId, requestTypeId, requestKey } = action.payload.params;

                return fetchConnectFragmentsSuccess({
                    portalId,
                    requestTypeId,
                    requestKey,
                    portalPageName: portalPage,
                    connectFragments: response.portalWebFragments!,
                });
            })
            .catch((error: ErrorResponse) => {
                const { portalPage, portalId, requestTypeId, requestKey } = action.payload.params;

                return Observable.of(
                    fetchConnectFragmentsFailure({
                        portalId,
                        requestTypeId,
                        error,
                        requestKey,
                        portalPageName: portalPage,
                    })
                );
            })
    );

export const profileConnectFragmentsEpic: Epic<
    FetchProfileConnectFragmentsAction,
    FetchProfileConnectFragmentsSuccess | FetchProfileConnectFragmentsFailure
> = (action$) =>
    action$.ofType(FETCH_PROFILE_CONNECT_FRAGMENTS).mergeMap((action) =>
        fetchModel(action.payload)
            .filter((response) => !!response.profileWebFragments)
            .map((response) => {
                return fetchProfileConnectFragmentsSuccess(
                    response.profileWebFragments as ProfileConnectFragmentsResponse
                );
            })
            .catch((error: ErrorResponse) => Observable.of(fetchProfileConnectFragmentsFailure(error)))
    );
