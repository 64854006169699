import { defineMessages } from 'react-intl';

export default defineMessages({
    showMore: {
        defaultMessage: 'Show more...',
        description: 'Button action text to expand a form fields description',
        id: 'cv.field-base.description.show-more',
    },
    showLess: {
        defaultMessage: 'Show less...',
        description: 'Button action text to collapse a form fields description',
        id: 'cv.field-base.description.show-less',
    },
});
