import * as React from 'react';
import { Flex, Box, Stack } from '@atlaskit/primitives';
import { SkeletonItem } from '@atlassian/help-center-common-component/skeleton-item';

const EditHelpCenterLayoutSkeleton = () => {
    return (
        <Box paddingBlockStart="space.400">
            <Stack space="space.200">
                <Flex direction="row" gap="space.100">
                    <SkeletonItem width="84px" height="24px" />
                    <SkeletonItem width="84px" height="24px" />
                </Flex>
                <SkeletonItem width="20%" height="28px" data-testid="edit-helpcenter-header-skeleton" />
                <Box paddingBlockStart="space.100" paddingBlockEnd="space.200">
                    <Stack space="space.050">
                        <SkeletonItem width="100%" height="20px" />
                        <SkeletonItem width="60%" height="20px" />
                    </Stack>
                </Box>
                <SkeletonItem width="50%" height="20px" />
                <Box paddingBlockEnd="space.150">
                    <Stack space="space.100">
                        <SkeletonItem width="10%" height="16px" />
                        <SkeletonItem width="100%" height="36px" />
                    </Stack>
                </Box>
                <Box paddingBlockEnd="space.200">
                    <Stack space="space.100">
                        <SkeletonItem width="10%" height="16px" />
                        <Flex direction="row" gap="space.100" alignItems="center">
                            <SkeletonItem width="50%" height="20px" />
                            <SkeletonItem width="50%" height="36px" />
                        </Flex>
                    </Stack>
                </Box>
            </Stack>
            <Flex direction="row" gap="space.050">
                <SkeletonItem width="10%" height="32px" data-testid="edit-helpcenter-button-skeleton" />
                <SkeletonItem width="10%" height="32px" />
            </Flex>
        </Box>
    );
};

export default EditHelpCenterLayoutSkeleton;
