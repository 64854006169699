import type { AjaxResponse, Observable } from 'rxjs';
import { post } from 'epics/ajax';
import { mapValues } from 'lodash';
import { ModelsCache } from 'rest/models/models-cache/models-cache';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { HCBrandingResponse } from './types';

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum BrandingTargetCollections {
    banner = 'sd-banner-upload',
    logo = 'sd-logo-upload',
}

export interface UploadTokenWithClientInfo {
    token: string;
    clientId: string;
    externalEndpointUrl: string;
}

export const getUploadToken = async (
    targetCollection: BrandingTargetCollections,
    context: string = contextPath
): Promise<string> => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(
            `${context}/rest/servicedesk/1/servicedesk-data/help-center-theme-branding/upload-token/${targetCollection}`,
            undefined,
            headers
        )
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            .map((ajaxResponse) => ajaxResponse.response.token)
            .toPromise()
    );
};

export const getUploadTokenWithClientInfo = async (
    targetCollection: BrandingTargetCollections,
    context: string = contextPath
): Promise<UploadTokenWithClientInfo> => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        post(
            `${context}/rest/servicedesk/1/servicedesk-data/help-center-theme-branding/upload-media-token/${targetCollection}`,
            undefined,
            headers
        )
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map((ajaxResponse) => ajaxResponse.response)
            .toPromise()
    );
};

interface TranslatedHelpCenterTranslationRequest {
    helpCenterTitle: string;
    sharedPortalName: string;
    announcementHeader?: string;
    announcementMessage?: string;
}

export interface UpdateBrandingBody {
    translations: {
        [key: string]: TranslatedHelpCenterTranslationRequest;
    };
    helpCenterTitleColor: string;
    portalThemeColor: string;
    isUsingLogo: boolean;
    logoMediaApiFileId?: string;
    bannerMediaApiFileId?: string;
    useDefaultBanner: boolean;
}

export const updateBranding = (
    body: UpdateBrandingBody,
    context: string = contextPath
): Observable<HCBrandingResponse> => {
    const uri = `${context}/rest/servicedesk/1/servicedesk-data/help-center-theme-branding/with-translations`;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    ModelsCache.getInstance().del('helpCenterBranding');
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return post(uri, body, headers).map((ajaxResponse) => ajaxResponse.response);
};
export interface UpdateAnnouncementBody {
    header?: string;
    message?: string;
}

export interface UpdateAnnouncementTranslations {
    header: string;
    message: string;
}

/*
    Map announcement response to help center branding response. Since we are using same epic and reducer for
    announcement API and help center branding API. TODO: A new epic for help center announcements.
*/
const mapAnnouncementResponseToHelpCenterBrandingResponse = (ajaxResponse: AjaxResponse) => {
    let modifiedTranslations = {};
    // Suppressing existing violation. Please fix this.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const response = ajaxResponse.response;

    // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modifiedTranslations = mapValues(response.translations, (translation: UpdateAnnouncementTranslations) => ({
        announcementHeader: translation.header,
        announcementMessage: translation.message,
    }));

    return {
        translations: modifiedTranslations,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        userInitialAnnouncementHeader: response.header,
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        userInitialAnnouncementMessageWiki: response.messageWiki,
    };
};

export const updateAnnouncement = (
    body: UpdateAnnouncementBody,
    context: string = contextPath
): Observable<Partial<HCBrandingResponse>> => {
    const uri = `${context}/rest/servicedesk/1/servicedesk-data/announcement/help-center`;

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    ModelsCache.getInstance().del('helpCenterBranding');
    return post(uri, body, headers).map(mapAnnouncementResponseToHelpCenterBrandingResponse);
};
