import { combineReducers } from 'redux';
import type { PersistedState } from './persisted/reducer';
import persisted from './persisted/reducer';
import type { UIState } from './ui/reducer';
import ui from './ui/reducer';

export interface State {
    ui: UIState;
    persisted: PersistedState;
}

export default combineReducers<State>({
    persisted,
    ui,
});
