import type {
    CompleteSignup,
    CompleteSignupFailure,
    CompleteSignupSuccess,
    SendNewSignupLink,
    SendNewSignupLinkFailure,
    SendNewSignupLinkSuccess,
} from 'state/actions/complete-signup';
import {
    COMPLETE_SIGNUP,
    COMPLETE_SIGNUP_FAILURE,
    SEND_NEW_SIGNUP_LINK,
    SEND_NEW_SIGNUP_LINK_SUCCESS,
    SEND_NEW_SIGNUP_LINK_FAILURE,
} from 'state/actions/complete-signup';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';

export interface CompleteSignupUiState {
    isSubmitting: boolean;
    submitError?: DefaultError;
    isNewSignupLinkBeingSent: boolean;
    newSignupLinkSuccessfullySent: boolean;
}

export type HandledActions =
    | CompleteSignup
    | CompleteSignupFailure
    | CompleteSignupSuccess
    | SendNewSignupLink
    | SendNewSignupLinkFailure
    | SendNewSignupLinkSuccess;

export const defaultState: CompleteSignupUiState = {
    isSubmitting: false,
    submitError: undefined,
    isNewSignupLinkBeingSent: false,
    newSignupLinkSuccessfullySent: false,
};

export function completeSignupUiReducer(
    state: CompleteSignupUiState = defaultState,
    action: HandledActions
): CompleteSignupUiState {
    switch (action.type) {
        case COMPLETE_SIGNUP:
            return handleCompleteSignup(state);
        case COMPLETE_SIGNUP_FAILURE:
            return handleCompleteSignupFailure(state, action);
        case SEND_NEW_SIGNUP_LINK:
            return handleSendNewSignupLink(state);
        case SEND_NEW_SIGNUP_LINK_SUCCESS:
            return handleSendNewSignupLinkSuccess(state);
        case SEND_NEW_SIGNUP_LINK_FAILURE:
            return handleSendNewSignupLinkFailure(state);
        default:
            return state;
    }
}

const handleCompleteSignup = (state: CompleteSignupUiState): CompleteSignupUiState => ({
    ...state,
    isSubmitting: true,
    submitError: undefined,
});

const handleCompleteSignupFailure = (
    state: CompleteSignupUiState,
    action: CompleteSignupFailure
): CompleteSignupUiState => ({
    ...state,
    isSubmitting: false,
    submitError: action.payload.error,
});

const handleSendNewSignupLink = (state: CompleteSignupUiState): CompleteSignupUiState => ({
    ...state,
    isNewSignupLinkBeingSent: true,
});

const handleSendNewSignupLinkSuccess = (state: CompleteSignupUiState): CompleteSignupUiState => ({
    ...state,
    isNewSignupLinkBeingSent: false,
    newSignupLinkSuccessfullySent: true,
});

const handleSendNewSignupLinkFailure = (state: CompleteSignupUiState): CompleteSignupUiState => ({
    ...state,
    isNewSignupLinkBeingSent: false,
});
