import type { EnvType } from '@atlassian/help-center-common-util/env';

export const SHOW_FEEDACK_COLLECTOR = 'state.ui.feedback-collector.SHOW_FEEDACK_COLLECTOR';
export const HIDE_FEEDACK_COLLECTOR = 'state.ui.feedback-collector.HIDE_FEEDACK_COLLECTOR';
export const UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR =
    'state.ui.feedback-collector.UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR';

export interface FeedbackCollectorPayload {
    shouldOpenFeedbackWidget: boolean;
    showTypeField: boolean;
    canContactLabel?: string;
    enrolLabel?: string;
    featureName: string;
    CustomComponent?: React.ComponentClass<{
        onChange: (fieldId: string, fieldValue: string | object) => void;
        additionalFields: Record<string, string | object>;
    }>;
    env: EnvType;
}

export interface ShowFeedbackCollector {
    type: typeof SHOW_FEEDACK_COLLECTOR;
    payload: FeedbackCollectorPayload;
}

export const showFeedbackCollector = (payload: FeedbackCollectorPayload): ShowFeedbackCollector => ({
    payload,
    type: SHOW_FEEDACK_COLLECTOR,
});

export interface HideFeedbackCollector {
    type: typeof HIDE_FEEDACK_COLLECTOR;
}

export const hideFeedbackCollector = (): HideFeedbackCollector => ({
    type: HIDE_FEEDACK_COLLECTOR,
});

export interface UpdateAdditionalFieldsInFeedbackCollector {
    type: typeof UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR;
    payload: { additionalFields: Record<string, string | object> };
}

export const updateAdditionalFieldsInFeedbackCollector = (
    additionalFields: Record<string, string | object>
): UpdateAdditionalFieldsInFeedbackCollector => ({
    type: UPDATE_ADDITIONAL_FIELDS_IN_FEEDACK_COLLECTOR,
    payload: {
        additionalFields,
    },
});
