import type { StoreActionApi } from 'react-sweet-state';
import { v4 } from 'uuid';
import { getLastHelpseekerMessage } from '../selectors';
import type { State } from '../types';
import { sendHelpseekerMessage } from './send-helpseeker-message';

export const retryLastHelpseekerMessage =
    () =>
    ({ getState, setState, dispatch }: StoreActionApi<State>) => {
        const state = getState();
        const lastMessage = getLastHelpseekerMessage(state);
        if (!lastMessage) {
            return;
        }

        const retryMessage = {
            ...lastMessage,
            id: `client::${v4()}`,
            timeCreated: new Date().toISOString(),
        };

        setState({
            isLoading: true,
            messages: [...state.messages, retryMessage],
        });
        dispatch(sendHelpseekerMessage(retryMessage));
    };
