import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import { useIsPageInEditMode } from 'view/layout-next/controllers';
import * as mixins from 'view/styles/mixins';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/help-center-common-component/analytics/link';
import messages from './messages';

interface ListItemBodyProps extends RequestTypeSuggestion {
    onClick: () => void;
    isEmbeddedRoute?: boolean;
}

const RecentFormsListItem: React.FunctionComponent<ListItemBodyProps> = ({
    portalId,
    portalName,
    name,
    descriptionHtml,
    onClick,
    isEmbeddedRoute,
    id: requestTypeId,
}) => {
    di(useIsPageInEditMode);
    const [isPageInEditMode] = useIsPageInEditMode();

    return (
        <AnalyticsContext data={{ attributes: { requestTypeId } }}>
            <>
                {isPageInEditMode ? (
                    <Title>
                        <FormattedMessage {...messages.linkText} values={{ portalName, formName: name }} />
                    </Title>
                ) : (
                    <FormLink
                        to={`/portal/${portalId}/create/${requestTypeId}`}
                        onClick={onClick}
                        actionSubjectId="suggestionRequestType"
                        target={isEmbeddedRoute ? '_blank' : undefined}
                    >
                        <FormattedMessage {...messages.linkText} values={{ portalName, formName: name }} />
                    </FormLink>
                )}
                {/* Safe to use dangerouslySetInnerHTML as the HTML is sanitised by Jira */}
                <Description dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            </>
        </AnalyticsContext>
    );
};

export default RecentFormsListItem;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const FormLink = styled(Link)`
    color: ${(props) => token('color.link', props.theme.color)};
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Description = styled.div`
    & p {
        ${mixins.clamp};
    }
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const Title = styled.div`
    color: ${(props) => token('color.link', props.theme.color)};
`;
