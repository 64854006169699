import type { ScreenEventPayload, UIEventPayload } from '@atlassiansox/analytics-web-client';
import { getAnalyticsWebClient } from '@helpCenter/util/analytics';
import { SCREEN } from '@atlassian/analytics-bridge';

const getVASource = () => {
    return `virtualAgent${SCREEN}`;
};

export const sendUIEvent = (event: Omit<UIEventPayload, 'source'>) => {
    const analyticsClient = getAnalyticsWebClient().getInstance();
    analyticsClient
        .sendUIEvent({
            ...event,
            source: getVASource(),
        })
        .catch((error: string) => {
            throw new Error(`Failure to send UI analytics event ${error}`);
        });
};

export const sendScreenEvent = ({
    name,
    attributes,
}: {
    name: string;
    attributes?: { [x: string]: string | undefined };
}) => {
    const event: ScreenEventPayload = {
        name,
        attributes: { ...attributes, source: getVASource() },
    };

    const analyticsClient = getAnalyticsWebClient().getInstance();
    analyticsClient.sendScreenEvent(event).catch((error: string) => {
        throw new Error(`Failure to send screen analytics event ${error}`);
    });
};

export const sendTrackEvent = ({
    action,
    actionSubject,
    actionSubjectId,
    attributes,
}: {
    action: string;
    actionSubject: string;
    attributes?: { [x: string]: string | undefined };
    actionSubjectId?: string;
}) => {
    const analyticsClient = getAnalyticsWebClient().getInstance();
    analyticsClient
        .sendTrackEvent({
            action,
            actionSubject,
            actionSubjectId,
            attributes,
            source: getVASource(),
        })
        .catch((error: string) => {
            throw new Error(`Failure to send track analytics event ${error}`);
        });
};

export const sendOperationalEvent = ({
    actionSubject,
    actionSubjectId,
    attributes,
}: {
    actionSubject: string;
    actionSubjectId?: string;
    attributes?: { [x: string]: string | undefined };
}) => {
    sendOperationalOrErrorAnalyticsEvent({ actionSubject, actionSubjectId, attributes, action: 'success' });
};

export const sendErrorEvent = ({
    error,
    actionSubject,
    actionSubjectId,
    attributes,
}: {
    error: Error;
    actionSubject: string;
    actionSubjectId?: string;
    attributes?: { [x: string]: string | undefined };
}) => {
    sendOperationalOrErrorAnalyticsEvent({
        actionSubject,
        actionSubjectId,
        action: 'failed',
        attributes: { ...attributes, error },
    });
};

const sendOperationalOrErrorAnalyticsEvent = ({
    actionSubject,
    actionSubjectId,
    action,
    attributes,
}: {
    actionSubject: string;
    actionSubjectId?: string;
    action: string;
    attributes?: object;
}) => {
    const analyticsClient = getAnalyticsWebClient().getInstance();
    analyticsClient
        .sendOperationalEvent({
            actionSubject,
            actionSubjectId,
            action,
            attributes,
            source: getVASource(),
        })
        .catch((err: string) => {
            throw new Error(`Failure to send operational analytics event ${err}`);
        });
};
