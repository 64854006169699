import { buildModelsActionCreator } from 'epics/model/actions';
import type { RequestsColumnSettingsResponse, UpdateRequestsColumnSettingsData } from 'rest/requests-column-settings';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { UFOExperience } from '@atlassian/ufo';
import type { ErrorResponse } from 'epics/model/types';
import type { OrderedRequestListColumns } from 'rest/requests-column-settings/types';

export const CUSTOMIZING_REQUEST_LIST_COLUMN = 'state.ui.requests.column.settings.CUSTOMIZING_REQUEST_LIST_COLUMN';
export const TOGGLE_COLUMN_RE_ORDER_WIDGET = 'state.ui.requests.column.settings.TOGGLE_COLUMN_RE_ORDER_WIDGET';
export const FETCH_REQUESTS_COLUMN_SETTINGS_MODEL = 'epic.model.FETCH_REQUESTS_COLUMN_SETTINGS_MODEL';
export const FETCH_REQUESTS_COLUMN_SETTINGS_SUCCESS =
    'state.persisted.requests.column.settings.FETCH_REQUESTS_COLUMN_SETTINGS_SUCCESS';
export const FETCH_REQUESTS_COLUMN_SETTINGS_FAILURE =
    'state.persisted.requests.column.settings.FETCH_REQUESTS_COLUMN_SETTINGS_FAILURE';
export const UPDATE_REQUESTS_COLUMN_SETTINGS_MODEL =
    'epic.requests-column-settings.UPDATE_REQUESTS_COLUMN_SETTINGS_MODEL';
export const UPDATE_REQUESTS_COLUMN_SETTINGS_SUCCESS =
    'state.persisted.requests.column.settings.UPDATE_REQUESTS_COLUMN_SETTINGS_SUCCESS';
export const UPDATE_REQUESTS_COLUMN_SETTINGS_FAILURE =
    'state.persisted.requests.column.settings.UPDATE_REQUESTS_COLUMN_SETTINGS_FAILURE';
export const RESET_CUSTOMIZE_REQUESTS_COLUMN_SETTINGS =
    'state.ui.requests.column.settings.RESET_CUSTOMIZE_REQUESTS_COLUMN_SETTINGS';
export const REORDER_REQUEST_LIST_COLUMNS = 'state.ui.requests.column.settings.REORDER_REQUEST_LIST_COLUMNS';
export const UPDATE_ORDERED_REQUEST_LIST_COLUMNS_MODEL =
    'epic.requests-column-settings.UPDATE_ORDERED_REQUEST_LIST_COLUMNS_MODEL';
export const UPDATE_ORDERED_REQUEST_LIST_COLUMNS_SUCCESS =
    'state.persisted.requests.column.settings.UPDATE_ORDERED_REQUEST_LIST_COLUMNS_SUCCESS';
export const UPDATE_ORDERED_REQUEST_LIST_COLUMNS_FAILURE =
    'state.persisted.requests.column.settings.UPDATE_ORDERED_REQUEST_LIST_COLUMNS_FAILURE';

export interface CustomizingRequestListColumn {
    type: typeof CUSTOMIZING_REQUEST_LIST_COLUMN;
    payload: boolean;
}

export const customizingRequestListColumn = (isCustomizing: boolean): CustomizingRequestListColumn => ({
    type: CUSTOMIZING_REQUEST_LIST_COLUMN,
    payload: isCustomizing,
});

export interface ToggleColumnReOrderWidget {
    type: typeof TOGGLE_COLUMN_RE_ORDER_WIDGET;
    payload: boolean;
}

export const toggleColumnReOrderWidget = (isReOrdering: boolean): ToggleColumnReOrderWidget => ({
    type: TOGGLE_COLUMN_RE_ORDER_WIDGET,
    payload: isReOrdering,
});

export type FetchRequestsColumnSettingsModel = ReturnType<typeof fetchRequestsColumnSettingsModel>;

export const fetchRequestsColumnSettingsModel = buildModelsActionCreator(
    FETCH_REQUESTS_COLUMN_SETTINGS_MODEL,
    'requestsColumnSettings'
);

export interface FetchRequestsColumnSettingsSuccess {
    type: typeof FETCH_REQUESTS_COLUMN_SETTINGS_SUCCESS;
    payload?: RequestsColumnSettingsResponse;
}

export const fetchRequestsColumnSettingsSuccess = (
    requestsColumnSettingsModel: RequestsColumnSettingsResponse
): FetchRequestsColumnSettingsSuccess => ({
    type: FETCH_REQUESTS_COLUMN_SETTINGS_SUCCESS,
    payload: requestsColumnSettingsModel,
});

export interface FetchRequestsColumnSettingsFailure {
    type: typeof FETCH_REQUESTS_COLUMN_SETTINGS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchRequestsColumnSettingsFailure = (error: ErrorResponse): FetchRequestsColumnSettingsFailure => ({
    type: FETCH_REQUESTS_COLUMN_SETTINGS_FAILURE,
    payload: {
        error,
    },
});

export interface UpdateRequestsColumnSettingsModel {
    type: typeof UPDATE_REQUESTS_COLUMN_SETTINGS_MODEL;
    payload: UpdateRequestsColumnSettingsData;
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
        updateManageColumnsSidebarExperience: UFOExperience;
    };
}

export const updateRequestsColumnSettingsModel = (
    requestsColumnSettingsModel: UpdateRequestsColumnSettingsData,
    meta?: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
        updateManageColumnsSidebarExperience: UFOExperience;
    }
): UpdateRequestsColumnSettingsModel => ({
    meta,
    type: UPDATE_REQUESTS_COLUMN_SETTINGS_MODEL,
    payload: requestsColumnSettingsModel,
});

export interface UpdateRequestsColumnSettingsSuccess {
    type: typeof UPDATE_REQUESTS_COLUMN_SETTINGS_SUCCESS;
    payload?: UpdateRequestsColumnSettingsData;
}

export const updateRequestsColumnSettingsSuccess = (
    requestsColumnSettingsModel: UpdateRequestsColumnSettingsData
): UpdateRequestsColumnSettingsSuccess => ({
    type: UPDATE_REQUESTS_COLUMN_SETTINGS_SUCCESS,
    payload: requestsColumnSettingsModel,
});

export interface UpdateRequestsColumnSettingsFailure {
    type: typeof UPDATE_REQUESTS_COLUMN_SETTINGS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const updateRequestsColumnSettingsFailure = (error: ErrorResponse): UpdateRequestsColumnSettingsFailure => ({
    type: UPDATE_REQUESTS_COLUMN_SETTINGS_FAILURE,
    payload: {
        error,
    },
});

export interface ResetCustomizeRequestsColumnSettings {
    type: typeof RESET_CUSTOMIZE_REQUESTS_COLUMN_SETTINGS;
}

export const resetCustomizeRequestsColumnSettings = (): ResetCustomizeRequestsColumnSettings => ({
    type: RESET_CUSTOMIZE_REQUESTS_COLUMN_SETTINGS,
});

export interface ReOrderRequestListColumns {
    type: typeof REORDER_REQUEST_LIST_COLUMNS;
    payload: number[];
}

export const reOrderRequestListColumns = (newOrder: number[]): ReOrderRequestListColumns => ({
    type: REORDER_REQUEST_LIST_COLUMNS,
    payload: newOrder,
});

export interface UpdateOrderedRequestListColumnsModel {
    type: typeof UPDATE_ORDERED_REQUEST_LIST_COLUMNS_MODEL;
    payload: OrderedRequestListColumns;
    meta: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
    };
}
export const updateOrderedRequestListColumnsModel = (
    newOrder: OrderedRequestListColumns,
    meta: {
        analyticsSuccessEvent?: UIAnalyticsEvent;
        analyticsFailureEvent?: UIAnalyticsEvent;
    }
): UpdateOrderedRequestListColumnsModel => {
    return {
        meta,
        type: UPDATE_ORDERED_REQUEST_LIST_COLUMNS_MODEL,
        payload: newOrder,
    };
};

export interface UpdateOrderedRequestListColumnsSuccess {
    type: typeof UPDATE_ORDERED_REQUEST_LIST_COLUMNS_SUCCESS;
    payload?: UpdateRequestsColumnSettingsData;
}

export const updateOrderedRequestListColumnsSuccess = (
    requestsColumnSettings: UpdateRequestsColumnSettingsData
): UpdateOrderedRequestListColumnsSuccess => ({
    type: UPDATE_ORDERED_REQUEST_LIST_COLUMNS_SUCCESS,
    payload: requestsColumnSettings,
});

export interface UpdateOrderedRequestListColumnsFailure {
    type: typeof UPDATE_ORDERED_REQUEST_LIST_COLUMNS_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const updateOrderedRequestListColumnsFailure = (
    error: ErrorResponse
): UpdateOrderedRequestListColumnsFailure => ({
    type: UPDATE_ORDERED_REQUEST_LIST_COLUMNS_FAILURE,
    payload: {
        error,
    },
});
