import { defaultsDeep } from 'lodash';
import type { FormErrors } from 'view/request-create-form';
import type { AnalyticsEventPayload, UIAnalyticsEvent, CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { EventTypes } from '@atlassian/analytics-web-react';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { reportError } from '@atlassian/help-center-common-util/sentry';
import { getAnalyticsWebClient } from '../client';

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum AnalyticsActionSubjects {
    ISSUE = 'issue',
    FORM = 'form',
    COMMENT = 'comment',
    REQUEST_TYPE = 'issueType',
    REQUEST_TYPE_GROUP = 'requestTypeGroup',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum AnalyticsTrackActions {
    CREATED = 'created',
    DELETED = 'deleted',
    UPDATED = 'updated',
    VIEWED = 'viewed',
    FETCHED = 'fetched',
    CREATED_FAILURE = 'created_failure',
    DELETED_FAILURE = 'deleted_failure',
    UPDATED_FAILURE = 'updated_failure',
    FETCHED_FAILURE = 'fetched_failure',
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum AnalyticsUIActions {
    CLICKED = 'clicked',
}

export type AnalyticsActions = AnalyticsTrackActions | AnalyticsUIActions;

export type Action = 'created' | 'deleted' | 'updated' | 'viewed';

export interface FormAnalyticsData {
    user: User;
    portal: Portal;
}

export interface User {
    getAgentForPortal(): boolean;
    getAccountId(): string | undefined;
}

export interface Portal {
    getPortalAnnouncement(): {
        getCanAdministerProject(): boolean;
    };
}

type AttributeType = string | number | boolean;

interface IssueTrackData {
    projectId: string;
    issueId: number;
    action: Action;
    attributes: {
        [key: string]: AttributeType | AttributeType[] | undefined;
    };
}

interface CommentTrackData {
    projectId: string;
    issueId: number;
    commentId: string;
    action: Action;
    attributes: {
        [key: string]: string;
    };
}

// This may extend to include other types of interactions besides first form onChange event
interface FirstInteractionData {
    actionSubject: AnalyticsActionSubjects.FORM;
    source: 'portalScreen';
    isNewPortal: boolean;
}

const defaultTrackData = {
    source: 'portalScreen',
};

const issueTrackData = {
    ...defaultTrackData,
    containerType: 'project',
    objectType: 'issue',
    actionSubject: AnalyticsActionSubjects.ISSUE,
};

function getUserRole(user: User, portal?: Portal): string {
    if (user.getAccountId()) {
        if (portal && portal.getPortalAnnouncement().getCanAdministerProject()) {
            return 'agent-project-admin';
        }
        if (user.getAgentForPortal()) {
            return 'agent';
        }
        return 'customer';
    }
    return 'anonymous';
}
const getDefaultAttributes = (user: User, portal?: Portal) => ({
    attributes: {
        jsdRole: getUserRole(user, portal),
        projectType: 'serviceDesk',
    },
});

export function trackIssueEvent(data: IssueTrackData, user: User, portal?: Portal) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const trackData = defaultsDeep(getDefaultAttributes(user, portal), issueTrackData, {
            action: data.action,
            containerId: data.projectId,
            objectId: data.issueId,
            attributes: data.attributes,
        });
        try {
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsWebClient.getInstance().sendTrackEvent(trackData);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('trackIssueEvent: failed to send analytics data', err);
        }
    }
}

export function trackIssueEventNext(
    data: IssueTrackData,
    user: User,
    createAnalyticsEvent: CreateUIAnalyticsEvent,
    portal?: Portal
) {
    try {
        const trackData = {
            analyticsType: 'TRACK',
            action: data.action,
            containerId: data.projectId,
            objectId: data.issueId,
            ...issueTrackData,
            ...getDefaultAttributes(user, portal).attributes,
            ...data.attributes,
        };
        const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent(trackData);

        analyticsEvent.fire();
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('trackIssueEventNext: failed to send analytics data', err);
    }
}

export function trackRequestCreateNonGenericErrors(
    errors: FormErrors,
    createAnalyticsEvent?: CreateUIAnalyticsEvent
): void {
    if (!createAnalyticsEvent) {
        return;
    }
    try {
        // Refrain from passing error messages from fieldError and submissionError to avoid PII logging
        const { fieldErrors, errorReasonKey, traceId, submissionError = {} } = errors;
        const trackData = {
            errorReasonKey,
            traceId,
            hasSubmissionErrors: Object.keys(submissionError).length > 0,
            hasFieldErrors: Object.keys(fieldErrors).length > 0,
            analyticsType: OPERATIONAL_EVENT_TYPE,
            action: 'failed',
            actionSubject: 'requestCreateNonGenericError',
        };
        const analyticsEvent: UIAnalyticsEvent = createAnalyticsEvent(trackData);
        analyticsEvent.context.push({
            componentName: 'requestCreateNonGenericError',
        });
        analyticsEvent.fire();
    } catch (err) {
        reportError(err, {
            message: 'trackRequestCreateNonGenericErrors: failed to send analytics data',
        });
    }
}

let firstInteractionTracked = false;

export function firstInteraction(data: FirstInteractionData) {
    // In the interest of not spamming the analytics server, we try to limit this request to one per tab.
    // This does not guarantee that there is only 1 event fired per interaction as the user can start interaction on a separate tab
    if (!firstInteractionTracked) {
        firstInteractionTracked = true;

        const combinedData = {
            action: 'firstPortalMeaningfulInteraction',
            actionSubject: data.actionSubject,
            source: data.source,
            attributes: {
                isNewPortal: String(!!data.isNewPortal),
            },
        };

        uiEvent(combinedData);
    }
}

export function uiEvent(data: AnalyticsEventPayload) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        const baseAttributes = {
            attributes: {
                projectType: 'servicedesk',
            },
        };

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const trackData = defaultsDeep(baseAttributes, data);
        try {
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsWebClient.getInstance().sendUIEvent(trackData);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('uiEvent: failed to send analytics data', err);
        }
    }
}

export function operationalEvent(data: AnalyticsEventPayload) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        const baseAttributes = {
            attributes: {
                projectType: 'servicedesk',
            },
        };

        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const trackData = defaultsDeep(baseAttributes, data);
        try {
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsWebClient.getInstance().sendOperationalEvent(trackData);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('operationalEvent: failed to send analytics data', err);
        }
    }
}

const commentTrackData = {
    ...defaultTrackData,
    containerType: 'project',
    objectType: 'issue',
    actionSubject: AnalyticsActionSubjects.COMMENT,
};

export function trackCommentEvent(data: CommentTrackData, user: User, portal?: Portal) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const trackData = defaultsDeep(getDefaultAttributes(user, portal), commentTrackData, {
            action: data.action,
            containerId: data.projectId,
            objectId: data.issueId,
            actionSubjectId: data.commentId,
            attributes: data.attributes,
        });
        try {
            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsWebClient.getInstance().sendTrackEvent(trackData);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('trackCommentEvent: failed to send analytics data', err);
        }
    }
}

// TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
// eslint-disable-next-line no-shadow
export enum ConnectRequestCreateEventType {
    VALIDATE = 'connectValidationTimedOut',
    SERIALIZE = 'connectSerializationTimedOut',
    LEGACY = 'connectValidationOrSerializationTimedOut',
}

export function sendConnectTimeoutEventForRequestCreation(
    eventType: ConnectRequestCreateEventType,
    connectKey?: string,
    requestTypeId?: number
) {
    const analyticsWebClient = getAnalyticsWebClient();
    if (analyticsWebClient) {
        try {
            const event = {
                actionSubject: 'connectKey.requestType',
                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                actionSubjectId: `${connectKey}.${requestTypeId}`,
            };

            if (eventType === ConnectRequestCreateEventType.LEGACY) {
                event.actionSubject = 'requestType';
                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code. Please correct when this code is revisited.
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                event.actionSubjectId = `${requestTypeId}`;
            }

            // Disabling existing violations, should be fixed when revisited.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            analyticsWebClient.getInstance().sendOperationalEvent({
                action: eventType,
                actionSubject: event.actionSubject,
                actionSubjectId: event.actionSubjectId,
                source: ScreenName.REQUEST_CREATE,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn('sendConnectTimeoutEventForRequestCreation: failed to send analytics data', err);
        }
    }
}

export type CreateAnalyticsEvent = ((payload: AnalyticsEventPayload) => UIAnalyticsEvent) | undefined;
export interface ComposeAnalyticsEventPayload {
    analyticsType: EventTypes;
    action: AnalyticsActions;
    actionSubject: AnalyticsActionSubjects;
    actionSubjectId?: string;
    attributes?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
    };
}

export const composeAnalyticsEvent =
    (createAnalyticsEvent: CreateAnalyticsEvent) =>
    (payload: ComposeAnalyticsEventPayload): UIAnalyticsEvent | undefined => {
        if (createAnalyticsEvent) {
            return createAnalyticsEvent({
                analyticsType: payload.analyticsType,
                action: payload.action,
                actionSubject: payload.actionSubject,
                actionSubjectId: payload.actionSubjectId,
                ...payload.attributes,
            });
        }
        return undefined;
    };
