import { defineMessages } from 'react-intl-next';

export default defineMessages({
    errorTitle: {
        defaultMessage: 'We couldn’t load this page',
        description: 'Text on show more results button',
        id: 'unified-help.error.page.title',
    },
    errorDescriptionText: {
        defaultMessage: 'Try refreshing the page or head to your <a>help center</a>.',
        description: 'Text for label on show more results icon',
        id: 'unified-help.error.page.description.text',
    },
});
