import type { FetchLoginAction } from 'epics/model/login';
import { FETCH_LOGIN_MODEL } from 'epics/model/login';

import type { FetchLoginSuccess, FetchLoginFailure, LoginResponse } from 'state/actions/login';
import { FETCH_LOGIN_SUCCESS, FETCH_LOGIN_FAILURE } from 'state/actions/login';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { PersistedError } from 'state/persisted/types';

export interface Login {
    login?: LoginResponse;
}

export type LoginState = Login | PersistedError | undefined;

export const getInitialLoginState = (): LoginState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.login) {
        return {
            login: initialModelState.login,
        };
    }
    return {};
};

const defaultState: LoginState = getInitialLoginState();

export type HandledActions = FetchLoginAction | FetchLoginSuccess | FetchLoginFailure;

export function loginReducer(state: LoginState = defaultState, action: HandledActions): LoginState {
    switch (action.type) {
        case FETCH_LOGIN_MODEL: {
            if (state && 'error' in state) {
                return {};
            }
            return state;
        }

        // We want to override the portal data if request create fails as well.
        case FETCH_LOGIN_FAILURE: {
            const fetchPortalFailureAction = action as FetchLoginFailure;
            const { error } = fetchPortalFailureAction.payload;
            const errorObject = {
                // We really only care about the first error message
                // grab it and throw the rest away
                status: error.status,
                message: (error.errorMessages || [])[0],
                // Remove basename from the URL, we don't need it as the basename
                // is already set inside react router.
                // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
                callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
                callToActionText: error.nextActionDisplayText || '',
            };

            return {
                error: errorObject,
            };
        }

        case FETCH_LOGIN_SUCCESS: {
            const fetchPortalSuccessAction = action as FetchLoginSuccess;
            const response = fetchPortalSuccessAction.payload;

            return {
                login: {
                    ...response,
                },
            };
        }

        default:
            return state;
    }
}
