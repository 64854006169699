import type { WebFragmentResponse, CustomMenuLinkResponse } from 'rest/connect-addon';
import type {
    FetchProfileConnectFragmentsFailure,
    FetchProfileConnectFragmentsSuccess,
} from 'state/actions/connect-addon';
import {
    FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE,
    FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS,
} from 'state/actions/connect-addon';
import { initialModel } from '@atlassian/help-center-common-util/model';
import type { ErrorResponse } from 'epics/model/types';

export interface ProfileWebFragmentState {
    panels?: WebFragmentResponse[] | { error: ErrorResponse };
    actions?: CustomMenuLinkResponse[];
}

export const getInitialProfileWebFragmentState = (): ProfileWebFragmentState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.profileWebFragments) {
        return {
            ...initialModelState.profileWebFragments,
        };
    }
    return {};
};

export const defaultState = getInitialProfileWebFragmentState();

export type HandledActions = FetchProfileConnectFragmentsSuccess | FetchProfileConnectFragmentsFailure;

const handleFetchProfileConnectFragmentsSuccess = (
    state: ProfileWebFragmentState,
    action: FetchProfileConnectFragmentsSuccess
): ProfileWebFragmentState => ({
    ...state,
    ...action.payload.fragments,
});

const handleFetchProfileConnectFragmentsFailure = (
    state: ProfileWebFragmentState,
    action: FetchProfileConnectFragmentsFailure
): ProfileWebFragmentState => ({
    ...state,
    panels: action.payload,
});

export function profileWebFragmentReducer(
    state: ProfileWebFragmentState = defaultState,
    action: HandledActions
): ProfileWebFragmentState {
    switch (action.type) {
        case FETCH_PROFILE_CONNECT_FRAGMENTS_SUCCESS: {
            return handleFetchProfileConnectFragmentsSuccess(state, action);
        }
        case FETCH_PROFILE_CONNECT_FRAGMENTS_FAILURE: {
            return handleFetchProfileConnectFragmentsFailure(state, action);
        }
        default:
            return state;
    }
}
