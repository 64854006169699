import * as React from 'react';
import type { InferableComponentEnhancerWithProps } from 'react-redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCanAdministerJIRA } from 'state/selectors/user';
import { BannerWithPaperLayoutRoute } from 'view/layout/banner-with-paper-layout';
import { HTTP } from '@atlassian/help-center-common-component/constants';
import { ErrorPage } from '@atlassian/help-center-common-component/error-page';
import type { PersistedError } from 'state/persisted/types';

const selector = createSelector(getCanAdministerJIRA, (canAdministerJira) => ({
    canAdministerJira,
}));

type ConnectedProps<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _> ? Props : never;

const connector = connect(selector, null);

type OnlyAdminOption = Omit<PersistedError['error'], 'status'>;

const withOnlyJiraAdmin =
    (options: OnlyAdminOption) =>
    <TProps extends object>(WrappedComponent: React.ComponentType<TProps>) => {
        const ReturnComponent: React.FC<TProps & ConnectedProps<typeof connector>> = (props) => {
            const { canAdministerJira } = props;

            if (canAdministerJira) {
                //@ts-ignore TypeScript upgrade 4.7.4
                return <WrappedComponent {...props} />;
            }
            const { message, callToActionUrl, callToActionText } = options;

            return (
                <BannerWithPaperLayoutRoute
                    component={() => (
                        <ErrorPage
                            error={{
                                message,
                                callToActionUrl,
                                callToActionText,
                                status: HTTP.FORBIDDEN,
                            }}
                        />
                    )}
                    layoutProps={{
                        headerless: false,
                        paperAppearance: 'normal',
                        fadeInSearchModal: true,
                    }}
                />
            );
        };
        const ConnectedComponent = connector(ReturnComponent);
        ConnectedComponent.displayName = 'WithOnlyJiraAdmin';

        return ConnectedComponent;
    };

export default withOnlyJiraAdmin;
