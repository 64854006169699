import { connect } from 'react-redux';
import { updateEnvAction } from 'state/actions/env';
import { updateMetaAction } from 'state/actions/meta';
import MetaDataProvider from './meta-data-provider';

const mapDispatchToProps = {
    updateEnvAction,
    updateMetaAction,
};

export default connect(null, mapDispatchToProps)(MetaDataProvider);
