/**
 * @generated SignedSource<<609c15b3adf945f2c52fc6c6e1f2458a>>
 * @relayHash efdc5420deee34a8cc05fb680adbe347
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7b0a8c16e933459da97d8638f8b061a4a77543af7666f2d03b73dfac583090f0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type editHelpCenter_getHelpcenterById_Query$variables = {
  cloudId: string;
  helpCenterAri: string;
};
export type editHelpCenter_getHelpcenterById_Query$data = {
  readonly helpCenter: {
    readonly helpCenterById: {
      readonly id?: string;
      readonly name?: {
        readonly default: string;
        readonly translations: ReadonlyArray<{
          readonly locale: string;
          readonly value: string;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly slug?: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"settingsBreadcrumbsFragment">;
    } | null | undefined;
  } | null | undefined;
};
export type editHelpCenter_getHelpcenterById_Query = {
  response: editHelpCenter_getHelpcenterById_Query$data;
  variables: editHelpCenter_getHelpcenterById_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpCenterAri"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "helpCenterAri",
    "variableName": "helpCenterAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "HelpCenterName",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "default"
    },
    {
      "concreteType": "HelpCenterTranslation",
      "kind": "LinkedField",
      "name": "translations",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "value"
        },
        {
          "kind": "ScalarField",
          "name": "locale"
        }
      ]
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "name": "slug"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "editHelpCenter_getHelpcenterById_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "settingsBreadcrumbsFragment"
                  }
                ],
                "type": "HelpCenter"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editHelpCenter_getHelpcenterById_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterQueryApi",
        "kind": "LinkedField",
        "name": "helpCenter",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "LinkedField",
            "name": "helpCenterById",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "HelpCenter"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7b0a8c16e933459da97d8638f8b061a4a77543af7666f2d03b73dfac583090f0",
    "metadata": {},
    "name": "editHelpCenter_getHelpcenterById_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "52e3cc7f44c0936e01ed59dd9c996799";

export default node;
