import * as React from 'react';
import { isEqual as deepEquals } from 'lodash';
import * as qs from 'query-string';
import type {
    Location as ReactResourceRouterLocation,
    RouteContext as ReactResourceRouteContext,
} from 'react-resource-router';

/**
 * Higher order component which implements a shouldComponentUpdate() to block re-rendering if only the query
 * param updated from the react router props.
 * @param renderChild Render function that will return JSX.
 * @param whiteListedQueryParametersSupplier
 */
const withIgnoreQueryParamUpdates = (
    renderChild: React.FunctionComponent<ReactResourceRouteContext>,
    whiteListedQueryParametersSupplier: () => string[]
) => {
    return class GeneratedLayout extends React.Component<ReactResourceRouteContext> {
        areWhiteListedQueryParametersDifferent(search: string, nextSearch: string): boolean {
            const whiteListedQueryParameters = whiteListedQueryParametersSupplier();
            if (whiteListedQueryParameters.length > 0) {
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const parsedSearch = qs.parse(search);
                // Suppressing existing violation. Please fix this.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const parsedNextSearch = qs.parse(nextSearch);
                let i = 0;
                for (i; i < whiteListedQueryParameters.length; i++) {
                    const whiteListedProp = whiteListedQueryParameters[i];

                    // Suppressing existing violation. Please fix this.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    const value = parsedSearch[whiteListedProp];

                    // Suppressing existing violation. Please fix this.
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    const nextValue = parsedNextSearch[whiteListedProp];
                    if (value !== nextValue) {
                        return true;
                    }
                }
            }
            return false;
        }

        shouldComponentUpdate(nextProps: ReactResourceRouteContext) {
            const { location, ...remainingProps } = this.props;
            const { location: nextLocation, ...remainingNextProps } = nextProps;
            const nonQueryLocationChanged = GeneratedLayout.hasNonQueryLocationChanged(
                location as ReactResourceRouterLocation,
                nextLocation as ReactResourceRouterLocation
            );

            if (nonQueryLocationChanged) {
                return true;
            }
            if (!deepEquals(remainingProps, remainingNextProps)) {
                return true;
            }
            return this.areWhiteListedQueryParametersDifferent(location.search, nextLocation.search);
        }

        private static hasNonQueryLocationChanged(
            location: ReactResourceRouterLocation,
            nextLocation: ReactResourceRouterLocation
        ) {
            const { search, ...remainingLocationProps } = location;
            const { search: nextSearch, ...remainingNextLocationProps } = nextLocation;

            // If any props other than query parameter changed then we will update
            return !deepEquals(remainingLocationProps, remainingNextLocationProps);
        }

        render() {
            return renderChild(this.props);
        }
    };
};

export default withIgnoreQueryParamUpdates;
