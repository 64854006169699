import memoizeOne from 'memoize-one';
import type { InitializeFieldPayload } from '../../fake-uim/store/types';
import { SUMMARY_TYPE, SELECT_CF_TYPE, USER_CF_TYPE, MULTI_CHECKBOXES_CF_TYPE } from './constants';

interface SingleSelectOption {
    value: string;
    label: string;
}

export class RequestCreateViewConfiguration {
    /**
     * memoizeOne is used to avoid recreating the config objects.
     */
    getSupportedFieldsConfiguration = memoizeOne(
        // TODO: Replace this type with the real one once UIM on platfrom is ready.
        (): Record<
            string,
            {
                publicShapeValidators: Record<string, (value: unknown) => boolean>;
                publicToInternalTransformers: Record<
                    string,
                    (value: unknown, lookupValues: unknown[] | undefined) => object
                >;
                initializeFieldProprtyMapping?: Partial<Record<keyof InitializeFieldPayload['field'], string>>;
                propertyMapping?: Record<string, string>;
            }
        > => {
            // TODO: replace with real common validtors from @tlassian/ui-modification-core once ready.
            const commonFieldValidators = {
                fieldName: (value: unknown) => typeof value === 'string',
                isVisible: (value: unknown) => typeof value === 'boolean',
                description: (value: unknown) => typeof value === 'string',
                isReadOnly: (value: unknown) => typeof value === 'boolean',
                isRequired: (value: unknown) => typeof value === 'boolean',
            };

            // TODO: Replace with prod ready transformers and validators when implementing given field.
            return {
                [SUMMARY_TYPE]: {
                    // It contains also supported field properties.
                    // Validator MUST be added here to make given property supported.
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        value: (value: unknown) => typeof value === 'string' && value.length < 256,
                    },
                    publicToInternalTransformers: {
                        value: (stringValue: string) => ({
                            basic: stringValue,
                            details: stringValue,
                        }),
                    },
                },
                [SELECT_CF_TYPE]: {
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        value: () => true,
                        optionsVisibility: () => true,
                    },
                    publicToInternalTransformers: {
                        value: (optionId: string, options: SingleSelectOption[]) => {
                            const option = options?.find((item) => item.value === optionId);
                            const basic = option?.value ?? null;

                            return {
                                basic,
                                details: option,
                            };
                        },
                        optionsVisibility: (
                            { options, isVisible }: { options: string[]; isVisible: boolean },
                            lookupValues: SingleSelectOption[]
                        ) => {
                            return lookupValues.filter(({ value }) =>
                                isVisible ? options.includes(value) : !options.includes(value)
                            );
                        },
                    },
                    initializeFieldProprtyMapping: {
                        allowedValues: 'options',
                        value: 'defaultValue',
                    },
                    propertyMapping: {
                        optionsVisibility: 'options',
                    },
                },
                [MULTI_CHECKBOXES_CF_TYPE]: {
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        optionsVisibility: () => true,
                    },
                    publicToInternalTransformers: {
                        optionsVisibility: (
                            { options, isVisible }: { options: string[]; isVisible: boolean },
                            lookupValues: SingleSelectOption[]
                        ) => {
                            return lookupValues.filter(({ value }) =>
                                isVisible ? options.includes(value) : !options.includes(value)
                            );
                        },
                    },
                    initializeFieldProprtyMapping: {
                        allowedValues: 'items',
                    },
                    propertyMapping: {
                        optionsVisibility: 'items',
                    },
                },
                [USER_CF_TYPE]: {
                    publicShapeValidators: {
                        ...commonFieldValidators,
                        value: () => true,
                    },
                    publicToInternalTransformers: {
                        value: (value: string) => ({
                            basic: value,
                            details: {
                                value,
                                accountId: value,
                                label: `The fake user name of ${value} since there is no real UIM`,
                            },
                        }),
                    },
                    initializeFieldProprtyMapping: {
                        value: 'defaultUser',
                    },
                },
            };
        }
    );

    getSupportedFieldTypes = memoizeOne(() => {
        const supportedFieldTypes: string[] = [SUMMARY_TYPE, SELECT_CF_TYPE, USER_CF_TYPE, MULTI_CHECKBOXES_CF_TYPE];

        return supportedFieldTypes;
    });
}
