import * as React from 'react';
import { styled } from '@compiled/react';
import { Content, PageLayout } from '@atlaskit/page-layout';
import { MainContentSkipLinkWrapper } from '../../common/skip-links';
import { StyledFooter } from '../styled';
import { withLayoutRoute } from '../with-layout-route';

interface Props {
    children: JSX.Element;
    path?: string | undefined;
}

export const SplashLayout: React.FunctionComponent<Props> = ({ children }) => (
    <PageLayout testId="page-layout">
        <Content>
            <StyledContent>
                <main>
                    <MainContentSkipLinkWrapper>{children}</MainContentSkipLinkWrapper>
                </main>
                <StyledFooter sticky={false} />
            </StyledContent>
        </Content>
    </PageLayout>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const StyledContent = styled.div({
    width: '100%',
});

export default withLayoutRoute(SplashLayout);
