import * as React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import type { ThemedProps } from 'styled-components';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { withTheme } from 'styled-components';
import { lighten, darken } from 'polished';
import { ThemedButton as AtlaskitThemedButton } from '@atlassian/help-center-common-component/analytics/button';
import type { ThemedButtonProps } from '@atlassian/help-center-common-component/analytics/button';

/**
 * This isn't in the types but it does exist so we add it here to supress a type error.
 * Maybe when we upgrade styled components it'll go away??
 */
interface WithInnerRef {
    innerRef?: undefined;
}

export const ThemedButton = withTheme(
    ({ innerRef, ...props }: ThemedProps<React.PropsWithChildren<ThemedButtonProps> & WithInnerRef>) => (
        <AtlaskitThemedButton
            {...props}
            theme={(currentTheme, themeProps) => {
                const { buttonStyles, ...styles } = currentTheme(themeProps);

                let customTheme;

                if (props.appearance === 'primary' && !props.isDisabled) {
                    const backgroundColor = {
                        default: props.theme.color,
                        focus: props.theme.color,
                        hover: lighten(0.05, props.theme.color),
                        active: darken(0.05, props.theme.color),
                    };

                    customTheme = {
                        // @ts-ignore TS(7053) TypeScript upgrade 5.1.6, please fix this violation when you revisit this code.: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        // Suppressing existing violation. Please fix this.
                        // Suppressing existing violation. Please fix this.
                        // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @typescript-eslint/no-unsafe-assignment
                        backgroundColor: backgroundColor[themeProps.state],
                        color: `${props.theme.readableTextColor} !important`, // we need to add !important here for buttons with the href attribute
                    };
                }

                if (props.appearance === 'link') {
                    customTheme = {
                        color: `${props.theme.color} !important`,
                    };
                }

                return {
                    buttonStyles: {
                        ...buttonStyles,
                        ...customTheme,
                    },
                    ...styles,
                };
            }}
        />
    )
);
