import type { StoreActionApi } from 'react-sweet-state';
import { sendErrorEvent } from '../../../common/utils';
import type { RequestTypeField } from '../../../services/request-type-fields-service/types';
import type { CacheIds, State } from '../types';

const setRequestTypeFieldsMap =
    (requestTypeFieldsMap: Map<string, RequestTypeField>) =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            requestTypeFieldsMap,
            error: undefined,
            inFlightRequest: undefined,
            loading: false,
        });
    };

const setIsLoading =
    (cacheIds: CacheIds, inFlightRequest: Promise<Map<string, RequestTypeField> | undefined>) =>
    ({ setState }: StoreActionApi<State>) => {
        setState({
            cacheIds,
            inFlightRequest,
            loading: true,
        });
    };

const setError =
    (e: unknown) =>
    ({ setState }: StoreActionApi<State>) => {
        const error = e instanceof Error ? e : new Error('Unknown error');
        sendErrorEvent({ error, actionSubject: 'fetchRequestTypeFields' });
        setState({
            error,
            loading: false,
        });
    };

export const actions = {
    setRequestTypeFieldsMap,
    setIsLoading,
    setError,
};
