export type { HistoryData } from './history';
export {
    default as history,
    getBaseName,
    getRelativeBasePath,
    contextPath,
    getBasePath,
    historyObservable,
    replaceAdvancedRouteWithBasicRoute,
    replaceBasicRouteWithAdvancedRoute,
} from './history';
