import type { SetSignupDataAction } from 'state/actions/sign-up';
import { SET_SIGN_UP_DATA } from 'state/actions/sign-up';
import type { AuthInfo } from '@atlassian/help-center-common-util/login';
import { AccountStageType, AuthMethodType } from '@atlassian/help-center-common-util/login';
import { initialModel } from '@atlassian/help-center-common-util/model';

export interface SignUpState {
    authInfo: AuthInfo;
    emailAddress: string | null;
    requestKey: string | null;
    requestSummary: string | null;
}

const defaultState: SignUpState = {
    authInfo: {
        accountStage: AccountStageType.SIGNUP,
        authMethods: new Map([[AuthMethodType.CUSTOMER_ACCOUNT_PASSWORD, {}]]),
    },
    emailAddress: null,
    requestKey: null,
    requestSummary: null,
};

const getInitialState = (): SignUpState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.signUp && initialModelState.signUp.emailAddress) {
        return {
            ...defaultState,
            ...initialModelState.signUp,
        };
    }
    return defaultState;
};

export type Action = SetSignupDataAction;

export const signUpReducer = (state: SignUpState = getInitialState(), action: Action): SignUpState => {
    switch (action.type) {
        case SET_SIGN_UP_DATA: {
            return { ...state, emailAddress: action.payload.email, authInfo: action.payload.authInfo };
        }
        default:
            return state;
    }
};
