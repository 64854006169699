import { get } from 'epics/ajax';
import { Observable } from 'epics/rxjs';
import { contextPath } from '@atlassian/help-center-common-util/history';

/**
 * getIsDemoProject
 * Returns if the passed in project id is associated with a demo project or not.
 */
export const getIsDemoProject = (projectId: number): Promise<boolean> => {
    const uri = `${contextPath}/rest/internal/latest/frontendconfig/properties?projectId=${projectId}&project=isDemo`;
    return get(uri)
        .map((ajaxResponse) => {
            // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (ajaxResponse.response && ajaxResponse.response.project) {
                // TypeScript upgrade (v4.4.3). Please correct when you revisit this code.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                return !!ajaxResponse.response.project.isDemo;
            }
            return false;
        })
        .catch(() => Observable.of(false as const))
        .toPromise();
};
