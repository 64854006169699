// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import * as bp from 'view/styles/breakpoints';
import * as fonts from 'view/styles/fonts';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import * as zIndex from 'view/styles/z-index';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

interface PortalNameProps {
    hasOnlyPortalName: boolean;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const PortalName = styled.div<PortalNameProps>`
    ${fonts.h400};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    margin-top: 0;
    margin-bottom: ${token('space.050', '4px')};

    ${(props) => {
        const lines = props.hasOnlyPortalName ? 3 : 1;
        const lineHeight = grid.multiple(2).unitless;
        return mixins.multiLineClamp(lines, lineHeight);
    }};

    ${bp.fromXSmall.css`
        ${fonts.h500};
        margin-top: 0;
        margin-bottom: ${token('space.100', '8px')};
        max-height: ${(props) => (props.hasOnlyPortalName ? grid.multiple(7.5).px : grid.multiple(2.5).px)};
    `};

    /* Because of AK now sets the color in the font-size mixins (inside a media query) we need to */
    /* define our color with a higher specicifity. */
    /* stylelint-disable-next-line no-duplicate-selectors */
    & {
        color: ${(props) => token('color.text.brand', props.theme.color)};
    }
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Description = styled.div`
    ${fonts.xsmall};
    ${mixins.multiLineClamp(3, grid.multiple(2.25).unitless)};
    /* setting max-width 100% because on IE, text flows out of the container */
    max-width: 100%;
    color: ${token('color.text', colors.N800)};
    line-height: ${grid.multiple(2.25).px};

    ${bp.fromXSmall.css`
        ${fonts.regular};
        line-height: ${grid.multiple(2.5).px};
        max-height: ${grid.multiple(7.5).px};
    `};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Content = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const cardStyle = css`
    ${mixins.borderRadius};
    ${mixins.focusRing.default};
    box-sizing: border-box;
    display: block;
    position: relative;
    height: ${grid.multiple(14).px};
    width: ${grid.multiple(33).px};
    z-index: ${zIndex.portalCardLayout};

    ${bp.fromXSmall.css`
    height: ${grid.multiple(18).px};
    width: ${grid.multiple(42).px};
`};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const IconContainer = styled.div`
    ${mixins.flexCenter};
    height: ${grid.multiple(5).px};
    width: ${grid.multiple(5).px};
    flex-shrink: 0;
    margin-right: ${token('space.200', '16px')};

    ${bp.fromXSmall.css`
        height: ${grid.multiple(6).px};
        width: ${grid.multiple(6).px};
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const largePadding = css`
    padding: ${token('space.200', '16px')} ${token('space.300', '24px')}; /* check with Kate for padding top and bottom */

    ${bp.fromXSmall.css`
        padding: ${token('space.300', '24px')} ${token('space.400', '32px')};
    `};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
const smallPadding = css`
    padding: ${token('space.200', '16px')} ${token('space.200', '16px')}; /* check with Kate for padding top and bottom */

    ${bp.fromXSmall.css`
        padding: ${token('space.300', '24px')} ${token('space.300', '24px')};
    `};
`;

interface ContainerProps {
    hasIcon: boolean;
}

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;

    ${(props) => (props.hasIcon ? smallPadding : largePadding)};
`;
