import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchCategoriesAction } from 'epics/model/kb-category';
import type { State } from 'state';
import { fetchPortalAction } from 'state/actions/portal';
import type { ReqGroupsState, ReqTypesState } from 'state/persisted/portal';
import type { RequestTypeSuggestion } from 'state/persisted/request-type-suggestion';
import {
    getIsPortalLoaded,
    getPortalRequestTypes,
    getPortalRequestGroups,
    getCategories,
    getProjectId,
    getIsKbEnabled,
    getDescription as getPortalDescription,
} from 'state/selectors/portal';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { CategoryList } from '@atlassian/help-center-common-component/category-list';
import { FormPicker } from '@atlassian/help-center-common-component/form-picker';
import { LoadingContainer } from '@atlassian/help-center-common-component/loading-container';
import { AnalyticsTrackActions } from '@atlassian/help-center-common-util/analytics';
import SinglePortal from './single-portal';

interface HelpCenterSinglePortalProps extends ExternalProps {
    requestGroups: ReqGroupsState[];
    requestTypes: ReqTypesState[];
    loading: boolean;
    fetchPortal: typeof fetchPortalAction;
    fetchCategories: typeof fetchCategoriesAction;
    projectId: number | undefined;
    isKbEnabled: boolean;
    description: string;
}

interface ExternalProps {
    portalId: number;
    requestTypeSuggestion: RequestTypeSuggestion[];
    isEmbeddedRoute?: boolean;
}

export class HelpCenterSinglePortalComponent extends React.Component<
    HelpCenterSinglePortalProps & WithAnalyticsEventsProps
> {
    componentDidMount() {
        const { fetchCategories, fetchPortal, projectId, portalId, isKbEnabled } = this.props;
        // To fetch categories we need the projectId, to get projectId we need the portal.
        // This will result in sequential requests where it does the following:
        // Load help center -> load portal -> load categories -> FINALLY show content.
        if (projectId && isKbEnabled) {
            const meta = this.getFetchCategoryAnalyticsEvents();
            fetchCategories(projectId, portalId, meta);
        }

        // We need to also fetch the kb for the portal here so that we get a consistent state after selecting a request group
        // and navigating to a portal
        const analytics = this.getViewSinglePortalAnalyticsEvents();
        fetchPortal({ id: portalId, expand: ['reqTypes', 'reqGroups', 'orderMapping', 'kbs'] }, analytics);
    }

    componentDidUpdate(prevProps: HelpCenterSinglePortalProps) {
        const { projectId, portalId, fetchCategories, isKbEnabled } = this.props;

        if (prevProps.projectId !== projectId && projectId && isKbEnabled) {
            const meta = this.getFetchCategoryAnalyticsEvents();
            fetchCategories(projectId, portalId, meta);
        }
    }

    getFetchCategoryAnalyticsEvents = () => {
        const { createAnalyticsEvent } = this.props;
        if (createAnalyticsEvent) {
            return {
                analyticsSuccessEvent: createAnalyticsEvent({
                    action: 'fetchCategories success',
                    analyticsType: TRACK_EVENT_TYPE,
                    actionSubject: 'PortalHome',
                }),
                analyticsFailureEvent: createAnalyticsEvent({
                    action: 'fetchCategories error',
                    analyticsType: OPERATIONAL_EVENT_TYPE,
                }),
            };
        }

        return {};
    };

    getViewSinglePortalAnalyticsEvents = () => {
        const { createAnalyticsEvent } = this.props;
        if (createAnalyticsEvent) {
            return {
                analyticsSuccessEvent: createAnalyticsEvent({
                    action: AnalyticsTrackActions.FETCHED,
                    analyticsType: TRACK_EVENT_TYPE,
                    actionSubject: 'singlePortal',
                }),
                analyticsFailureEvent: createAnalyticsEvent({
                    action: AnalyticsTrackActions.FETCHED_FAILURE,
                    analyticsType: TRACK_EVENT_TYPE,
                    actionSubject: 'singlePortal',
                }),
            };
        }

        return {};
    };

    render() {
        const { loading, fetchCategories, fetchPortal, isKbEnabled, ...props } = this.props;

        return (
            <LoadingContainer
                isLoading={loading}
                loadingPosition="fill-container"
                interactionName={'help-center-single-portal'}
            >
                <SinglePortal {...props} FormPicker={FormPicker} CategoryList={CategoryList} />
            </LoadingContainer>
        );
    }
}

const selector = createSelector(
    (state: State, props: ExternalProps) => getIsPortalLoaded(state, props.portalId),
    (state: State, props: ExternalProps) => getPortalRequestGroups(state, props.portalId),
    (state: State, props: ExternalProps) => getPortalRequestTypes(state, props.portalId),
    (state: State, props: ExternalProps) => getCategories(state, props.portalId),
    (state: State, props: ExternalProps) => getProjectId(state, props.portalId),
    (state: State, props: ExternalProps) => getIsKbEnabled(state, props.portalId),
    (state: State, props: ExternalProps) => getPortalDescription(state, props.portalId),
    (isPortalLoaded, requestGroups, requestTypes, categories, projectId, isKbEnabled, description) => {
        const knowledgeBaseHasCategories = Array.isArray(categories) ? categories.length > 0 : false;
        return {
            requestGroups,
            requestTypes,
            projectId,
            isKbEnabled,
            description,
            knowledgeBaseHasCategories,
            loading: !isPortalLoaded || (isKbEnabled && !categories),
        };
    }
);

export const HelpCenterSinglePortal = connect(selector, {
    fetchPortal: fetchPortalAction,
    fetchCategories: fetchCategoriesAction,
})(withAnalyticsContext({ componentName: 'PortalHome' })(withAnalyticsEvents()(HelpCenterSinglePortalComponent)));
