import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

const AIAnswerExperienceId = 'service-management.help-center.ai-message';
const initialAIAnswerExperienceId = 'service-management.help-center.ai-initial-message-reply';

export const AIAnswerExperience = new UFOExperience(AIAnswerExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});

export const initialAIAnswerExperience = new UFOExperience(initialAIAnswerExperienceId, {
    type: ExperienceTypes.Experience,
    performanceType: ExperiencePerformanceTypes.InlineResult,
});
