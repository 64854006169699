import type { ActionsObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs';
import type { Epic } from 'epics/rxjs';
import { Observable } from 'epics/rxjs';

import { forgotPassword } from 'rest/login';
import type { HandleAjaxError } from 'state/actions/flags';
import { handleAjaxError } from 'state/actions/flags';
import type { ForgotPassword, ForgotPasswordSuccess } from 'state/actions/login';
import { FORGOT_PASSWORD, forgotPasswordSuccess } from 'state/actions/login';

export const forgotPasswordEpic: Epic<ForgotPassword, ForgotPasswordSuccess | HandleAjaxError> = (
    action$: ActionsObservable<ForgotPassword>
) => {
    return action$.ofType(FORGOT_PASSWORD).mergeMap((forgotPasswordAction) => {
        const { resetUrl, email } = forgotPasswordAction.payload;
        return forgotPassword(resetUrl, email)
            .map(() => {
                return forgotPasswordSuccess();
            })
            .catch((error: AjaxError) => {
                return Observable.of(handleAjaxError(error));
            });
    });
};
