// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import { css } from 'styled-components';

export type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

export interface TransitionProps {
    state: TransitionState;
}

export const timingFunctions = {
    easeOut: 'ease-out',
    easeIn: 'ease-in',
    easeInOut: 'ease-in-out',
    // http://cubic-bezier.com/#.47,0,0,1.19
    easeInOutSpring: 'cubic-bezier(.79,.22,0,1.19)',
    linear: 'linear',
    // Same easing used in Atlaskit modal:
    // https://bitbucket.org/atlassian/atlaskit-mk-2/src/fd22974e576281b2f4d158b0a0df3badc4ed9ab2/packages/core/modal-dialog/src/components/Animation.js?at=master&fileviewer=file-view-default
    easeInOutQuad: 'cubic-bezier(0.23, 1, 0.32, 1)',
};

export const speedMs = {
    superFast: 50,
    fast: 140,
    medium: 200,
    slow: 300,
};

const fadeInOutOpacityMap = {
    entering: 0,
    entered: 1,
    exiting: 0,
    exited: 0,
};

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const fadeInOutTiming = (ms: number, delay: number = 0) => css`
    transition: opacity ${ms}ms;
    transition-delay: ${delay}ms;
    transition-timing-function: ${timingFunctions.easeOut};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const fadeInOut = <P extends object>(ms: number, delay: number = 0) => css<P & TransitionProps>`
    ${fadeInOutTiming(ms, delay)};
    opacity: ${(props) => fadeInOutOpacityMap[props.state]};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const zoom = (ms: number = speedMs.fast) => css`
    transition: transform ${ms}ms;
    transition-timing-function: ${timingFunctions.easeInOut};
    transform: scale(1.1);
`;

const slideInOutFromTopTransformMap = {
    entering: 'translate3d(0, 0, 0)',
    entered: 'none',
    exiting: 'translate3d(0, -100%, 0)',
    exited: 'translate3d(0, -100%, 0)',
};

const slideInOutFromRightTransformMap = {
    entering: 'translate3d(0, 0, 0)',
    entered: 'none',
    exiting: 'translate3d(100%, 0, 0)',
    exited: 'translate3d(100%, 0, 0)',
};

const slideInOutTransitionMap = (ms: number) => ({
    /* This will ease out when entering, and ease-in when leaving. */
    entering: `transform ${ms}ms ${timingFunctions.easeOut}`,
    entered: `transform ${ms}ms ${timingFunctions.easeOut}`,
    exiting: `transform ${ms}ms ${timingFunctions.easeIn}`,
});

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const slideInOutFromTop = (ms: number) => css<TransitionProps>`
    transition: ${(props) => {
        //@ts-ignore TypeScript upgrade 5.1.6, please fix this violation.
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return slideInOutTransitionMap(ms)[props.state];
    }};
    transform: ${(props) => slideInOutFromTopTransformMap[props.state]};
`;

// @ts-ignore
// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const slideInOutFromRight = (ms: number) => css<TransitionProps>`
    transition: ${(props) => {
        //@ts-ignore TypeScript upgrade 5.1.6, please fix this violation.
        // Suppressing existing violation. Please fix this.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return slideInOutTransitionMap(ms)[props.state];
    }};
    transform: ${(props) => slideInOutFromRightTransformMap[props.state]};
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const fieldHighlightTransition = (ms: number) => css`
    transition:
        background-color ${ms}ms ease-in-out,
        border-color ${ms}ms ease-in-out;
`;
