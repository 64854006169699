import * as React from 'react';
import { di } from 'react-magnetic-di';
import type { Category } from 'rest/category';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import GroupBoxLink from '@atlassian/help-center-common-component/group-box/group-box-link';

interface CategoryItemProps {
    category: Category;
    portalId: number;
    onClick?: () => void;
    className?: string;
    isEmbeddedRoute?: boolean;
}

export default class CategoryItem extends React.Component<CategoryItemProps> {
    render() {
        di(GroupBoxLink);
        const { category, portalId, onClick, className, isEmbeddedRoute } = this.props;

        const analyticData = {
            attributes: {
                portalId,
                categoryId: category.id,
            },
        };

        return (
            <AnalyticsContext data={analyticData}>
                <GroupBoxLink
                    actionSubjectId="category"
                    to={`/portal/${portalId}/topic/${category.id}`}
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Disabled to rollout go/ui-styling-standard tooling, please resolve
                    className={className}
                    onClick={onClick}
                    heading={category.name}
                    description={category.description}
                    lineClamp={2}
                    target={isEmbeddedRoute ? '_blank' : undefined}
                />
            </AnalyticsContext>
        );
    }
}
