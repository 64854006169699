import * as React from 'react';
import type { InjectedIntlProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import ReactDocumentTitle from 'react-document-title';
import messages from './messages';

interface DocumentTitleProps {
    title?: string;
    children?: React.ReactElement | number | string | null;
    /**
     * Will remove the automatic "- Jira Service Management" appended to the document title.
     */
    ignoreBaseTitle?: boolean;
}

export const DocumentTitle: React.FunctionComponent<DocumentTitleProps & InjectedIntlProps> = ({
    children,
    title,
    intl,
    ignoreBaseTitle,
}) => {
    let documentTitle = intl.formatMessage(messages.defaultTitle);

    if (ignoreBaseTitle && title) {
        documentTitle = title;
    } else if (title) {
        documentTitle = intl.formatMessage(messages.baseTitle, { title });
    }

    return <ReactDocumentTitle title={documentTitle}>{children}</ReactDocumentTitle>;
};

export default injectIntl(DocumentTitle);
