import * as React from 'react';

import { fetchTFExtensions } from 'rest/trusted-fetch-extensions';
import batch from '@atlassian/help-center-common-util/batch-function';
import batchV2 from '@atlassian/help-center-common-util/batch-function-v2';

import type { TFECacheContextType } from '../types';

const batchedGetExtensions = batch(fetchTFExtensions, 0);
const batchedGetExtensionsV2 = batchV2(fetchTFExtensions, 0);

export const TFECacheContext = React.createContext<TFECacheContextType>({
    getExtensionsByType: batchedGetExtensions,
    getExtensionsByTypeV2: batchedGetExtensionsV2,
});

TFECacheContext.displayName = 'TFECacheContext';
