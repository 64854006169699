import { buildModelsActionCreator } from '../actions';

export const FETCH_HELP_CENTER_MODEL = 'epic.model.FETCH_HELP_CENTER_MODEL';
export const INVALIDATE_HELP_CENTER_RELAY_STORE = 'epic.model.INVALIDATE_HELP_CENTER_RELAY_STORE';

export type FetchHelpCenterAction = ReturnType<typeof fetchHelpCenterAction>;

export const fetchHelpCenterAction = buildModelsActionCreator(FETCH_HELP_CENTER_MODEL, 'sharedPortal');

export interface InvalidateHelpCenterStoreAction {
    type: typeof INVALIDATE_HELP_CENTER_RELAY_STORE;
}

export const invalidateHelpCenterStoreAction = (): InvalidateHelpCenterStoreAction => ({
    type: INVALIDATE_HELP_CENTER_RELAY_STORE,
});

export const FETCH_HELP_CENTER_BRANDING_MODEL = 'epic.model.FETCH_HELP_CENTER_BRANDING_MODEL';

export type FetchHelpCenterBrandingAction = ReturnType<typeof fetchHelpCenterBrandingAction>;

export const fetchHelpCenterBrandingAction = buildModelsActionCreator(
    FETCH_HELP_CENTER_BRANDING_MODEL,
    'helpCenterBranding'
);
