import * as React from 'react';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import type { Option, SelectProps } from '@atlaskit/select';
// This rule is disabled as it's telling consumers to instead use this file's component!
// eslint-disable-next-line no-restricted-imports
import AKSelect from '@atlaskit/select';
import type { AddedAnalyticsProps } from '../../with-create-fire-ui-analytics';

// This component simply adds a required `actionSubjectId` prop which gets set as context on the Select, so that `changed` events (provided by the default Select OOTB) get it as an attribute.
export class Select<O extends Option<string | number> = Option<string>> extends React.PureComponent<
    Omit<SelectProps<O, boolean>, 'theme'> & AddedAnalyticsProps
> {
    render() {
        const { actionSubjectId, ...selectProps } = this.props;

        return (
            <AnalyticsContext data={{ actionSubjectId }}>
                <AKSelect {...selectProps} />
            </AnalyticsContext>
        );
    }
}
