import { buildModelsActionCreator } from 'epics/model/actions';
import type { VisitPortalResponse } from 'rest/models';
import type { DefaultError } from '@atlassian/help-center-common-util/errors';
import type { ErrorResponse } from 'epics/model/types';

export const FETCH_VISIT_PORTAL_MODEL = 'epic.model.FETCH_VISIT_PORTAL_MODEL';
export const FETCH_VISIT_PORTAL_SUCCESS = 'state.persisted.visit.portal.FETCH_VISIT_PORTAL_SUCCESS';
export const FETCH_VISIT_PORTAL_FAILURE = 'state.persisted.visit.portal.FETCH_VISIT_PORTAL_FAILURE';
export const INVITED_SIGN_UP = 'state.ui.invited.sign.up.INVITED_SIGN_UP';
export const INVITED_SIGN_UP_SUCCESS = 'state.ui.invited.sign.up.INVITED_SIGN_UP_SUCCESS';
export const INVITED_SIGN_UP_FAILURE = 'state.ui.invited.sign.up.INVITED_SIGN_UP_FAILURE';

export type FetchVisitPortalAction = ReturnType<typeof fetchVisitPortalModel>;

export const fetchVisitPortalModel = buildModelsActionCreator(FETCH_VISIT_PORTAL_MODEL, 'visitPortal');

export interface FetchVisitPortalSuccess {
    type: typeof FETCH_VISIT_PORTAL_SUCCESS;
    payload?: VisitPortalResponse;
}

export const fetchVisitPortalSuccess = (visitPortalResponse?: VisitPortalResponse): FetchVisitPortalSuccess => ({
    type: FETCH_VISIT_PORTAL_SUCCESS,
    payload: visitPortalResponse,
});

export interface FetchVisitPortalFailure {
    type: typeof FETCH_VISIT_PORTAL_FAILURE;
    payload: {
        error: ErrorResponse;
    };
}

export const fetchVisitPortalFailure = (error: ErrorResponse): FetchVisitPortalFailure => ({
    type: FETCH_VISIT_PORTAL_FAILURE,
    payload: {
        error,
    },
});

export interface InvitedSignUp {
    type: typeof INVITED_SIGN_UP;
    payload: {
        portalId: number;
        email: string;
        fullName: string;
        password: string;
        token: string;
        username: string;
        contactAdminsFormEnabled: boolean;
    };
}

export const invitedSignUp = (
    portalId: number,
    email: string,
    fullName: string,
    password: string,
    token: string,
    username: string,
    contactAdminsFormEnabled: boolean
): InvitedSignUp => ({
    type: INVITED_SIGN_UP,
    payload: {
        portalId,
        email,
        fullName,
        password,
        token,
        username,
        contactAdminsFormEnabled,
    },
});

export interface InvitedSignUpSuccess {
    type: typeof INVITED_SIGN_UP_SUCCESS;
}

export const invitedSignUpSuccess = (): InvitedSignUpSuccess => ({
    type: INVITED_SIGN_UP_SUCCESS,
});

export interface InvitedSignUpFailure {
    type: typeof INVITED_SIGN_UP_FAILURE;
    payload: {
        error: DefaultError;
    };
}

export const invitedSignUpFailure = (error: DefaultError): InvitedSignUpFailure => ({
    type: INVITED_SIGN_UP_FAILURE,
    payload: {
        error,
    },
});
