import * as React from 'react';
import type { SelectProps, Option } from '@atlaskit/select';
import type { SelectFieldFormProps } from '@atlassian/help-center-common-component/form-select/with-field-base';
import {
    ThemedSelect,
    ThemedAsyncSelect,
    ThemedAsyncCreatableSelect,
} from '@atlassian/help-center-common-component/themed-select';
import type { AddedAnalyticsProps } from '../../analytics/with-create-fire-ui-analytics';
import { withFieldBase } from '../with-field-base';

const FieldMultiSelect = withFieldBase(ThemedSelect);
const FieldAsyncCreatableMultiSelect = withFieldBase(
    ThemedAsyncCreatableSelect as React.FunctionComponent<SelectProps<Option<string>, true>>
);
const FieldAsyncMultiSelect = withFieldBase(
    ThemedAsyncSelect as React.FunctionComponent<SelectProps<Option<string>, true>>
);

// We remove the value prop here so that the interface is consistent with the single select. The value prop also does not what
// you expect it to do as you will need to handle the state of the select component yourself if provided
type PropsWithField = Omit<SelectProps<Option<string>>, 'value'> &
    Omit<SelectFieldFormProps, 'value'> &
    AddedAnalyticsProps;

const FormMultiSelect: React.FunctionComponent<PropsWithField> = (props) => (
    <FieldMultiSelect {...props} isMulti isClearable />
);
const FormAsyncMultiSelect: React.FunctionComponent<PropsWithField> = (props) => (
    <FieldAsyncMultiSelect {...props} isMulti isClearable />
);
const FormAsyncCreatableMultiSelect: React.FunctionComponent<PropsWithField> = (props) => (
    <FieldAsyncCreatableMultiSelect {...props} isMulti isClearable />
);

export { FormMultiSelect, FormAsyncMultiSelect, FormAsyncCreatableMultiSelect };
