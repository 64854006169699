// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT } from 'view/common/styled';
import { Footer } from 'view/layout/footer';
import * as bp from 'view/styles/breakpoints';
import * as elevation from 'view/styles/elevation';
import * as grid from 'view/styles/grid';
import * as mixins from 'view/styles/mixins';
import * as transitions from 'view/styles/transitions';
import * as zIndex from 'view/styles/z-index';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// This is the width of the paper without any scaling
// It's fixed at 1000px for easy calculation.
const PAPER_WIDTH_BASIS = grid.multiple(125);

// 800px is normal width
const PAPER_NORMAL_WIDTH = PAPER_WIDTH_BASIS.multiple(0.8).unitless;

// 1000px is wide width
const PAPER_WIDE_WIDTH = PAPER_WIDTH_BASIS.multiple(1).unitless;

// 1100px is extra wide width
const PAPER_XWIDE_WIDTH = PAPER_WIDTH_BASIS.multiple(1.1).unitless;

// 1200px is extra wide width
const PAPER_XXWIDE_WIDTH = PAPER_WIDTH_BASIS.multiple(1.2).unitless;

const PAPER_NOTE_WIDTH = PAPER_WIDTH_BASIS.multiple(0.48).unitless;

const PAPER_PADDING_TOP = token('space.300', '24px');
const PAPER_PADDING_RIGHT_MOBILE = token('space.400', '32px');
const PAPER_PADDING_LEFT_MOBILE = token('space.400', '32px');

const PAPER_PADDING_RIGHT_DESKTOP = grid.multiple(13.5).unitless;
const PAPER_PADDING_LEFT_DESKTOP = grid.multiple(11).unitless;

const PAPER_PADDING_SMALL = token('space.600', '48px');

const PAPER_NOTE_HEIGHT = grid.multiple(52).unitless;
const PAPER_NOTE_MARGIN_TOP = grid.multiple(20).unitless - HEADER_HEIGHT;

export type PaperAppearance = 'xxwide' | 'xwide' | 'wide' | 'normal' | 'note' | 'none';

export const paperTransitionTime = transitions.speedMs.slow;

interface PaperProps {
    appearance: PaperAppearance;
    hide: boolean;
}
interface PaperContentProps {
    takeFullHeight?: boolean;
}
const getContentWidth = (appearance: PaperAppearance) => {
    switch (appearance) {
        case 'xxwide':
            return PAPER_XXWIDE_WIDTH;
        case 'xwide':
            return PAPER_XWIDE_WIDTH;
        case 'wide':
            return PAPER_WIDE_WIDTH;
        case 'note':
            return PAPER_NOTE_WIDTH;
        default:
            return PAPER_NORMAL_WIDTH;
    }
};

const getScale = (appearance: PaperAppearance) => getContentWidth(appearance) / PAPER_WIDTH_BASIS.unitless;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const Content = styled.main`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: ${token('space.0', '0px')} auto;
    width: 100%;
    max-width: ${(props: PaperProps) => (props.hide ? '100%' : `${getContentWidth(props.appearance)}px`)};
    min-height: 100%;
    ${bp.fromCustom(PAPER_NOTE_WIDTH).css`
        min-height: ${(props: PaperProps) => (props.appearance === 'note' ? `${PAPER_NOTE_HEIGHT}px` : '100%')};
        margin-top: ${(props: PaperProps) => (props.appearance === 'note' ? `${PAPER_NOTE_MARGIN_TOP}px` : '0')};
    `};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NoteContent = styled(Content).attrs({ appearance: 'note' })`
    align-self: flex-start;
    max-width: ${(props: PaperProps) => (props.hide ? '100%' : `${getContentWidth(props.appearance)}px`)};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const NoteBackground = styled.div`
    ${elevation.e100};
    border-radius: ${grid.multiple(0.375).px};
    background-color: ${token('elevation.surface', colors.N0)};
    min-height: inherit;
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const SmallPaddingPaperContent = styled.div<PaperContentProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    /* We use padding top/left/right so we can override more easily */
    padding-top: ${PAPER_PADDING_TOP};
    padding-left: ${PAPER_PADDING_LEFT_MOBILE};
    padding-right: ${PAPER_PADDING_RIGHT_MOBILE};
    height: ${(props) => (props.takeFullHeight ? '100%' : 'auto')};
    ${bp.fromCustom(PAPER_NORMAL_WIDTH).css`
        /* We use padding top/left/right so we can override more easily */
        padding-left: ${PAPER_PADDING_SMALL};
        padding-right: ${PAPER_PADDING_SMALL};
    `};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-exported-css, @atlaskit/design-system/no-css-tagged-template-expression -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const paperContentPaddingMixin = css`
    /* We use padding top/left/right so we can override more easily */
    padding-top: ${PAPER_PADDING_TOP};
    padding-left: ${PAPER_PADDING_LEFT_MOBILE};
    padding-right: ${PAPER_PADDING_RIGHT_MOBILE};

    ${bp.fromCustom(PAPER_NORMAL_WIDTH).css`
        /* We use padding top/left/right so we can override more easily */
        padding-left: ${PAPER_PADDING_LEFT_DESKTOP}px;
        padding-right: ${PAPER_PADDING_RIGHT_DESKTOP}px;
    `};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const PaperContent = styled.div<PaperContentProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    ${paperContentPaddingMixin};
    height: ${(props) => (props.takeFullHeight ? '100%' : 'auto')};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const PaperBackground = styled.div<PaperProps>`
    position: absolute;
    width: ${PAPER_WIDTH_BASIS.px};
    height: inherit;
    right: 0;
    bottom: 0;
    top: 0;
    left: 50%;

    /* border styles */
    ${mixins.borderRadius};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    /* elevation */
    background-color: ${token('elevation.surface', colors.N0)};
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    box-shadow: ${token(
        'elevation.shadow.overlay',
        `0 ${token('space.100', '8px')} ${token('space.200', '16px')} -${grid.multiple(0.5).px} ${
            colors.N50A
        }, 0 0 1px ${colors.N60A}`
    )};
    /* Overwrite z-index from elevation mixin because it is higher than avatar drop down menu */
    z-index: ${zIndex.pageInBannerLayout};
    transition: transform ${transitions.timingFunctions.easeInOutQuad} ${paperTransitionTime}ms;

    transform: ${(props: PaperProps) =>
        `translate3d(-50%, ${props.hide ? '100%' : '0'}, 0) scale3d(${getScale(props.appearance)}, 1, 1)`};

    ${bp.fromCustom(PAPER_NOTE_WIDTH).css`
        height: ${(props: PaperProps) => (props.appearance === 'note' ? `${PAPER_NOTE_HEIGHT}px` : 'inherit')};
        top: ${(props: PaperProps) => (props.appearance === 'note' ? `${PAPER_NOTE_MARGIN_TOP}px` : '0')};
    `};
`;

// eslint-disable-next-line rulesdir/no-styled-export, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- Disabled to rollout go/ui-styling-standard tooling, please resolve
export const StyledFooter = styled(Footer)`
    left: 0;
    right: 0;
    height: ${grid.multiple(6).px};
    padding: ${token('space.600', '48px')} 0 ${token('space.100', '8px')} 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
