import { Observable } from 'rxjs';
import { get, post, put } from 'epics/ajax';
import type { AjaxResponse, AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { contextPath } from '@atlassian/help-center-common-util/history';
import type { RequestGroup } from '../request-types';

export interface AddRequestGroupResponse extends AjaxResponse {
    response: RequestGroup;
}

export const addRequestGroup = (name: string, projectId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups`;
    return post(uri, { name }, { 'Content-Type': 'application/json' })
        .map(({ response }: AddRequestGroupResponse) => response)
        .catch((e) => {
            // Rethrow error
            return Observable.throw(e);
        });
};

export const updateRequestGroup = ({
    name,
    projectId,
    requestGroupId,
    context = contextPath,
}: {
    name: string;
    projectId: number;
    requestGroupId: number;
    context?: string;
}) => {
    const uri = `${context}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups/${requestGroupId}`;
    return (
        put(uri, { name }, { 'Content-Type': 'application/json' })
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }) => response)
            .catch((e) => Observable.throw(e))
    );
};

// fetchAllRequestGroups() gets all the request type groups for a project - regardless if there are request types in the group or not.
// By default the groups we get back from the models request only returns request type groups with at least one request type in them.
export const fetchAllRequestGroups = (projectId: number, context: string = contextPath) => {
    const uri = `${context}/rest/servicedesk/1/servicedesk/${projectId}/request-type-groups`;
    return (
        get(uri)
            // Suppressing existing violation. Please fix this.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .map(({ response }: AjaxResponse) => response)
            .catch((e: AjaxError) => Observable.throw(e))
    );
};
