import { connect } from 'react-redux';
import type { State } from 'state';
import { getIsPortalHidingEnabled } from 'state/selectors/featured-and-sorted-portals';
import ProjectCardStateless from './project-card';

const mapStateToProps = (state: State) => {
    return {
        isPortalHidingEnabled: getIsPortalHidingEnabled(state),
    };
};

export default connect(mapStateToProps)(ProjectCardStateless);
