import { defineMessages } from 'react-intl';

export default defineMessages({
    error: {
        defaultMessage: 'There was a problem loading, please refresh and try again.',
        description: 'Error message shown when something could not be loaded.',
        id: 'cv.loading.component.error',
    },

    timeout: {
        defaultMessage: 'Taking longer than usual...',
        description: 'Message shown when loading is taking a long time.',
        id: 'cv.loading.component.timeout',
    },
});
