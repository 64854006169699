import { isUiModificationsSupportEnabled } from 'feature-flags';
import type { Match } from 'react-resource-router';
import type { PaperAppearance } from 'view/layout/paper';
import { PortalHome } from 'view/portal-home';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';
import { UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE } from './resources';

const getRoute = () => ({
    name: 'portal-home',
    path: `/portal/:portalId/:group?/:requestGroupId?/:create?/:requestTypeId?`,
    exact: true,
    component: PortalHome,
    getUFONameBasedOnRouteMatch: (routerState: Match): string => {
        const { requestTypeId } = routerState.params;

        if (requestTypeId) {
            return 'request-create';
        }
        return 'portal-home';
    },
    resources: isUiModificationsSupportEnabled() ? [UI_MODIFICATIONS_JSM_REQUEST_CREATE_RESOURCE] : [],
});

const paperAppearance: PaperAppearance | undefined = 'xxwide';

const layoutProps = {
    paperAppearance,
    fadeInSearchModal: true,
};

export const getPortalHomeRoutes = () => createRouteWithLayout(getRoute(), undefined, layoutProps);
