import type { FormattedMessage } from 'react-intl';

import messages from './messages';

export const MAX_FULL_NAME_LENGTH_IN_CHARS = 255;
export const MAX_PASSWORD_LENGTH_IN_CHARS = 100;
export const MIN_PASSWORD_LENGTH_IN_CHARS = 8;

export interface ValidationErrors {
    fullNameError?: FormattedMessage.MessageDescriptor;
    passwordError?: FormattedMessage.MessageDescriptor;
}

export function validateAll(fullName: string, password: string): ValidationErrors | undefined {
    const fullNameError = validateFullName(fullName);
    const passwordError = validatePassword(password);

    if (fullNameError || passwordError) {
        return {
            fullNameError,
            passwordError,
        };
    }

    return undefined;
}

export function validateFullName(fullName: string): FormattedMessage.MessageDescriptor | undefined {
    const trimmedFullName = fullName.trim();
    if (trimmedFullName.length === 0) {
        return messages.fullNameMissingError;
    }
    if (trimmedFullName.length > MAX_FULL_NAME_LENGTH_IN_CHARS) {
        return messages.fullNameTooLongError;
    }

    return undefined;
}

export function validatePassword(password: string): FormattedMessage.MessageDescriptor | undefined {
    if (password.trim().length === 0) {
        return messages.passwordMissingError;
    }
    if (password.length < MIN_PASSWORD_LENGTH_IN_CHARS) {
        return messages.passwordTooShortError;
    }
    if (password.length > MAX_PASSWORD_LENGTH_IN_CHARS) {
        return messages.passwordTooLongError;
    }

    return undefined;
}
