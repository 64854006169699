import { buildModelsActionCreator } from 'epics/model/actions';
import type { TimezoneRegionResponse, TimezoneResponse } from 'rest/models';

export const FETCH_TIMEZONES_MODEL = 'epic.model.FETCH_TIMEZONES_MODEL';
export const FETCH_TIMEZONE_REGIONS_MODEL = 'epic.model.FETCH_TIMEZONE_REGIONS_MODEL';
export const FETCH_TIMEZONES_SUCCESS = 'state.persisted.timezone.FETCH_TIMEZONEs_SUCCESS';
export const FETCH_TIMEZONE_REGIONS_SUCCESS = 'state.persisted.timezone.FETCH_TIMEZONE_REGIONS_SUCCESS';

export type FetchTimezonesAction = ReturnType<typeof fetchTimezonesModel>;

export const fetchTimezonesModel = buildModelsActionCreator(FETCH_TIMEZONES_MODEL, 'timezones');

export type FetchTimezoneRegionsAction = ReturnType<typeof fetchTimezoneRegionsModel>;

export const fetchTimezoneRegionsModel = buildModelsActionCreator(FETCH_TIMEZONE_REGIONS_MODEL, 'timezoneRegions');

export interface FetchTimezoneRegionsSuccess {
    type: typeof FETCH_TIMEZONE_REGIONS_SUCCESS;
    payload: {
        timezoneRegions: TimezoneRegionResponse[];
    };
}

export const fetchTimezoneRegionsSuccess = (
    timezoneRegions: TimezoneRegionResponse[]
): FetchTimezoneRegionsSuccess => ({
    type: FETCH_TIMEZONE_REGIONS_SUCCESS,
    payload: {
        timezoneRegions,
    },
});
export interface FetchTimezonesSuccess {
    type: typeof FETCH_TIMEZONES_SUCCESS;
    payload: {
        timezones: TimezoneResponse[];
    };
}

export const fetchTimezonesSuccess = (timezones: TimezoneResponse[]): FetchTimezonesSuccess => ({
    type: FETCH_TIMEZONES_SUCCESS,
    payload: {
        timezones,
    },
});
