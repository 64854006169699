import { defineMessages } from 'react-intl-next';

export default defineMessages({
    unlinkAllWarningModalTitle: {
        defaultMessage: 'Your help center needs at least one linked project',
        description: 'Title of the unlink all projects warning modal',
        id: 'projects.list.unlink.all.warning.modal.title',
    },
    unlinkAllWarningModalDescription: {
        defaultMessage:
            'To ensure that your customers can access relevant information and resources, at least one project needs to be linked to your help center. Uncheck a project and try again.',
        description: 'Description of the unlink all projects warning modal',
        id: 'projects.list.unlink.all.warning.modal.description',
    },
    unlinkAllWarningModalOkButton: {
        defaultMessage: 'OK',
        description: 'Unlink all projects warning modal ok button',
        id: 'projects.list.unlink.all.warning.modal.ok.button',
    },
});
