import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LoadingSkeleton } from 'view/common/loading';
import { withAsync } from 'view/common/with-async';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const MultiHcDropdownLoader = () => (
    <LoadingSkeleton height={32} backgroundColor={token('color.skeleton.subtle', colors.N20)} />
);

export const MultiHcDropdown = withAsync({
    loader: lazyForPaint(() => import(/* webpackChunkName: "multi-hc-dropdown" */ './hocs')),
    LoadingSkeleton: MultiHcDropdownLoader,
});
