import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchHelpCenterAction } from 'epics/model/help-center';
import { isSearchUIRedesignEnabled, isVAOnHelpCenterGAEnabled } from 'feature-flags';
import { LazySuspense } from 'react-loosely-lazy';
import { di } from 'react-magnetic-di';
import type { PreloadedQuery } from 'react-relay';
import { graphql, usePreloadedQuery } from 'react-relay';
import { usePathParam } from 'react-resource-router';
import { searchComponentVirtualAgentResource } from 'routes/advanced-help-center/resources/view-homepage/resources';
import { fetchPortalAction } from 'state/actions/portal';
import type { UpdateSearchAction, ShowSearchAction, ResetSearchAction } from 'state/actions/search';
import {
    showSearch as showSearchAction,
    resetSearch as resetSearchAction,
    updateSearch as updateSearchAction,
} from 'state/actions/search';
import { getShown, getTerm } from 'state/selectors/search';
import { SearchButton } from 'view/layout/search-button';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { withAnalyticsEvents, withAnalyticsContext } from '@atlaskit/analytics-next';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { withPathParams } from '@atlassian/help-center-common-component/with-path-params';
import type { InjectedProps as WithParamsInjectedProps } from '@atlassian/help-center-common-component/with-path-params';
import { PortalAnalyticsModal } from '@atlassian/help-center-common-util/analytics';
import { useRelayResource } from '@atlassian/help-center-common-util/resource-router/resource-router';
import type { searchLoaderVirtualAgentEnabledQuery } from './__generated__/searchLoaderVirtualAgentEnabledQuery.graphql';
import { getPortalIdAsNumber, isSinglePortalSearch } from './common';
import { InlineResultsContextProvider } from './inline-results-context';
import { SearchErrorBoundary } from './search-error-boundary';
import { SearchModal } from './search-modal';
import { SearchTextboxV2 } from './search-textbox-v2';

export interface Props {
    term: string;
    trigger?: 'icon';
    isOpen: boolean;
    onOpen: () => ShowSearchAction;
    onClose: () => ShowSearchAction;
    onSearch: () => UpdateSearchAction;
    resetSearch: () => ResetSearchAction;
    fetchPortals: typeof fetchPortalAction;
    fetchHelpCenter: typeof fetchHelpCenterAction;
    disableSearch?: boolean;
    isVSAOnHCEnabled?: boolean;
}

type SearchProps = Props & WithAnalyticsEventsProps & WithParamsInjectedProps;

export const Search = ({
    term,
    trigger,
    isOpen,
    onSearch,
    onClose,
    onOpen,
    resetSearch,
    fetchHelpCenter,
    fetchPortals,
    disableSearch,
    isVSAOnHCEnabled,
}: SearchProps) => {
    di(usePathParam);
    const searchButtonRef = useRef<HTMLButtonElement>(null);
    const [portalId] = usePathParam('portalId');

    useEffect(() => {
        if (isSinglePortalSearch(portalId)) {
            fetchPortals({
                id: getPortalIdAsNumber(portalId),
                expand: ['kbs', 'reqTypes', 'reqGroups', 'orderMapping'],
            });
        }
    }, [portalId, fetchHelpCenter, fetchPortals]);

    const onReturnFocus = useCallback((_: Element) => {
        //FocusLock trap is still active due to that we deffer setting focus with zero-timeout
        setTimeout(() => {
            searchButtonRef?.current?.focus();
        }, 0);
        return true;
    }, []);

    useEffect(
        () => () => {
            resetSearch();
        },
        [resetSearch]
    );

    return (
        <>
            {isOpen && (
                <SearchModal
                    isOpen={isOpen}
                    term={term}
                    portalId={portalId}
                    onSearch={onSearch}
                    onClose={onClose}
                    onReturnFocus={onReturnFocus}
                    isVSAOnHCEnabled={isVSAOnHCEnabled}
                />
            )}
            {trigger === 'icon' ? (
                <SearchButton ref={searchButtonRef} shown={isOpen} onClick={onOpen} disableSearch={disableSearch} />
            ) : (
                <>
                    {!isOpen && (
                        <InlineResultsContextProvider>
                            <SearchTextboxV2
                                term={term}
                                disableSearch={disableSearch}
                                isVSAOnHCEnabled={!!isVSAOnHCEnabled}
                            />
                        </InlineResultsContextProvider>
                    )}
                </>
            )}
        </>
    );
};

const SearchWithVirtualAgentAvailability = (
    props: SearchProps & {
        queryReference: PreloadedQuery<searchLoaderVirtualAgentEnabledQuery>;
    }
) => {
    const { queryReference, ...rest } = props;
    const data = usePreloadedQuery<searchLoaderVirtualAgentEnabledQuery>(
        graphql`
            query searchLoaderVirtualAgentEnabledQuery(
                $cloudId: ID!
                $helpCenterAri: ID!
                $isVaOnHcGAEnabled: Boolean!
            ) {
                helpCenter(cloudId: $cloudId) {
                    helpCenterById(helpCenterAri: $helpCenterAri) @optIn(to: "HelpCenterAggBeta") {
                        ... on HelpCenter {
                            __typename
                            virtualAgentEnabled
                        }
                    }
                }
                virtualAgent @include(if: $isVaOnHcGAEnabled) {
                    virtualAgentAvailability(containerId: $helpCenterAri)
                }
            }
        `,
        props.queryReference
    );

    const responseTypeName = data.helpCenter?.helpCenterById?.__typename;

    if (responseTypeName !== 'HelpCenter') {
        return <Search {...rest} />;
    }
    const isVirtualAgentEnabled = data.helpCenter?.helpCenterById?.virtualAgentEnabled ?? false;
    const isVirtualAgentAvailable = isVAOnHelpCenterGAEnabled()
        ? (data.virtualAgent?.virtualAgentAvailability ?? false)
        : true;
    const isVSAOnHCEnabled = isVirtualAgentEnabled && isVirtualAgentAvailable;

    return <Search isVSAOnHCEnabled={isVSAOnHCEnabled} {...rest} />;
};

export const SearchLoader = (props: SearchProps) => {
    di(useRelayResource, isSearchUIRedesignEnabled);
    const { queryReference } = useRelayResource(searchComponentVirtualAgentResource);

    const renderError = useCallback(() => {
        // We fallback to non-VSA search if there is an error occurs in this loader, All the other errors should be handled by the SearchErrorBoundary default renderError
        return (
            <SearchErrorBoundary id="help-center-search">
                <Search {...props} />
            </SearchErrorBoundary>
        );
    }, [props]);
    if (!queryReference)
        return (
            <SearchErrorBoundary id="help-center-search">
                <Search {...props} />
            </SearchErrorBoundary>
        );

    return (
        <SearchErrorBoundary id="help-center-search" renderError={renderError}>
            <LazySuspense fallback={<Search {...props} />}>
                <SearchWithVirtualAgentAvailability queryReference={queryReference} {...props} />
            </LazySuspense>
        </SearchErrorBoundary>
    );
};

const selector = createSelector(getShown, getTerm, (shown, term) => ({
    term,
    isOpen: shown,
}));

const ConnectedSearch = connect(selector, {
    onOpen: () => showSearchAction(true),
    onClose: () => showSearchAction(false),
    onSearch: (term: string) => updateSearchAction(term, {}),
    resetSearch: resetSearchAction,
    fetchPortals: fetchPortalAction,
    fetchHelpCenter: fetchHelpCenterAction,
})(
    PortalAnalyticsModal<SearchProps>(ScreenName.HELP_CENTER_SEARCH)(
        withPathParams(
            withAnalyticsContext({ component: ScreenName.HELP_CENTER_SEARCH })(withAnalyticsEvents()(SearchLoader))
        )
    )
);

export default ConnectedSearch;
