import { defineMessages } from 'react-intl-next';

export default defineMessages({
    requestForms: {
        defaultMessage: 'Request forms',
        description: 'Text for section resource type in search results',
        id: 'cv.search.request-forms.request-forms',
    },
    portal: {
        defaultMessage: 'Portal',
        description: 'Text for portal name in description',
        id: 'cv.search.request-forms.portal',
    },
});
