import React, { Suspense } from 'react';
import ErrorBoundary from '@helpCenter/view/error-boundary/error-boundary';
import ErrorScreen from 'assets/images/project-mapping-error.svg';
import { noop } from 'lodash';
import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { AnalyticsContext, withAnalyticsContext, useAnalyticsEvents } from '@atlaskit/analytics-next';
import EmptyState from '@atlaskit/empty-state';
import { Box, Grid, Stack } from '@atlaskit/primitives';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/analytics-bridge';
import { TRACK_EVENT_TYPE } from '@atlassian/analytics-web-react';
import { ScreenName } from '@atlassian/help-center-common-component/constants';
import { FireScreenEventOnMount } from '@atlassian/help-center-common-util/analytics/fire-screen-event';
import { isFetchNetworkOrClientError } from '@atlassian/help-center-common-util/fetch';
import type { FetchError } from '@atlassian/help-center-common-util/fetch';
import { getWorkspaceAri, getCloudId } from '@atlassian/help-center-common-util/meta';
import HelpCenterForm from '@atlassian/help-centers-management/src/ui/help-center-form/help-center-form';
import { ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import { useHelpCenterAri } from '../../hooks/use-help-center-ari';
import { AdminManagementLayout } from '../admin-management-layout';
import messages from '../admin-management-layout/messages';
import { SettingsBreadcrumbs } from '../settings-breadcrumbs';
import type { editHelpCenter_getHelpcenterById_Query } from './__generated__/editHelpCenter_getHelpcenterById_Query.graphql';
import { EditHelpCenterLayoutSkeleton } from './edit-helpcenter-layout-skeleton';
import { editHelpCenterExperience } from './experiences';

const HelpCenterEditDetailsComponent = () => {
    const cloudId = getCloudId();
    const helpCenterAri = useHelpCenterAri();
    const data = useLazyLoadQuery<editHelpCenter_getHelpcenterById_Query>(
        graphql`
            query editHelpCenter_getHelpcenterById_Query($helpCenterAri: ID!, $cloudId: ID!) {
                helpCenter(cloudId: $cloudId) @optIn(to: "HelpCenterAggBeta") {
                    helpCenterById(helpCenterAri: $helpCenterAri) {
                        ... on HelpCenter {
                            id
                            name {
                                default
                                translations {
                                    value
                                    locale
                                }
                            }
                            slug @optIn(to: "HelpCenterSlugTest")
                            ...settingsBreadcrumbsFragment
                        }
                    }
                }
            }
        `,
        { cloudId, helpCenterAri }
    );

    const helpCenter = data.helpCenter?.helpCenterById;
    const { name, slug, id } = helpCenter ?? {};

    return (
        <ContextualAnalyticsData sourceName={ScreenName.EDIT_HELP_CENTER} sourceType={SCREEN}>
            <Stack>
                {data?.helpCenter?.helpCenterById && (
                    <SettingsBreadcrumbs helpCenterData={data.helpCenter?.helpCenterById} />
                )}
                <HelpCenterForm
                    helpCenterToEdit={{
                        translations:
                            name && name.translations
                                ? name.translations.map((translation) => ({
                                      value: translation?.value ?? '',
                                      locale: translation?.locale ?? '',
                                  }))
                                : [],
                        slug: slug ?? '',
                        name: name?.default ?? '',
                        ari: id ?? '',
                    }}
                    isStandaloneForm
                    isOpen
                    onClose={noop}
                />
            </Stack>
            <ExperienceSuccess experience={editHelpCenterExperience} />
            <FireScreenEventOnMount />
        </ContextualAnalyticsData>
    );
};
export const HelpCenterEditDetails = () => {
    di(AdminManagementLayout);
    useUFOComponentExperience(editHelpCenterExperience);
    const { formatMessage } = useIntl();
    const { createAnalyticsEvent } = useAnalyticsEvents();
    const onError = (error: Error | FetchError) => {
        if (!isFetchNetworkOrClientError(error as FetchError)) {
            editHelpCenterExperience
                .failure({
                    metadata: {
                        error: error?.message,
                        statusCode: (error as FetchError)?.status,
                    },
                })
                .then(noop)
                .catch(noop);
            createAnalyticsEvent({
                analyticsType: TRACK_EVENT_TYPE,
                action: 'fetch_failed',
                actionSubject: 'helpCenterDetails',
                errorMessage: error?.message,
                errorStatus: (error as FetchError)?.status,
            }).fire();
        }
    };

    const renderError = () => {
        return (
            <EmptyState
                header={formatMessage(messages.errorTitle)}
                description={formatMessage(messages.errorDescription)}
                imageUrl={ErrorScreen}
            />
        );
    };
    return (
        <AdminManagementLayout featureName="Helpcenter settings">
            <ErrorBoundary
                packageName={ScreenName.EDIT_HELP_CENTER}
                id={'help-center-edit-details'}
                renderError={renderError}
                onError={onError}
            >
                <AnalyticsContext data={{ attributes: { workspaceAri: getWorkspaceAri() } }}>
                    <Grid templateColumns="1fr 2fr 1fr" rowGap="space.400">
                        <Box />
                        <Suspense fallback={<EditHelpCenterLayoutSkeleton />}>
                            <HelpCenterEditDetailsComponent />
                        </Suspense>
                        <Box />
                    </Grid>
                </AnalyticsContext>
            </ErrorBoundary>
        </AdminManagementLayout>
    );
};
export default withAnalyticsContext({ component: 'helpCenterEditDetails' })(HelpCenterEditDetails);
