import { di } from 'react-magnetic-di';
import { useActions as useVirtualAgentActions } from '@atlassian/help-center-virtual-agent/src/controllers/conversation-store';
import { ConversationProvider } from '../../../common/types';
import { useActions as useAiAnswerActions } from '../../../store/converse';
import { useProvider, useActions } from '../../../store/routing';

export const useClearConversation = () => {
    di(useProvider, useVirtualAgentActions, useAiAnswerActions, useActions);

    const { clearConversation: clearAIAnswerConversation } = useAiAnswerActions();
    const { clearConversation: clearVirtualAgentConversation } = useVirtualAgentActions();
    const { setProvider } = useActions();

    const clearConversation = () => {
        clearAIAnswerConversation();
        clearVirtualAgentConversation();
        setProvider(ConversationProvider.VIRTUAL_AGENT);
    };

    return {
        clearConversation,
    };
};
