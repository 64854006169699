import { connect } from 'react-redux';
import type { State } from 'state';
import { getPortalId } from 'state/selectors/context';
import { getPortalAnalyticAttributes } from 'state/selectors/portal';
import PortalContext from './portal-context';

const selector = (state: State) => {
    const portalId = getPortalId(state);
    const attributes = getPortalAnalyticAttributes(state, portalId);
    return { attributes };
};

export const PortalAnalyticsContext = connect(selector)(PortalContext);
