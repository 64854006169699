import { uniqBy } from 'lodash';
import type { RequestDetailsResponse, SubscribeAction, SimpleUserResponse } from 'rest/request-details';
import { SUBSCRIBE_ACTION_KEY, UNSUBSCRIBE_ACTION_KEY } from 'rest/request-details';
import type {
    ChangeSubscriptionSuccess,
    CreateCommentSuccess,
    GetReadCredentialsSuccess,
    FetchRequestDetailsAction,
    FetchRequestDetailsFailure,
    FetchRequestDetailsSuccess,
    RemoveOrganisationSuccess,
    RemoveParticipantSuccess,
    AddParticipantsSuccess,
} from 'state/actions/request-details';
import {
    CHANGE_SUBSCRIPTION_SUCCESS,
    CREATE_COMMENT_SUCCESS,
    GET_MEDIA_READ_CREDENTIALS_SUCCESS,
    FETCH_REQUEST_DETAILS_MODEL,
    FETCH_REQUEST_DETAILS_FAILURE,
    FETCH_REQUEST_DETAILS_SUCCESS,
    REMOVE_ORGANISATION_SUCCESS,
    REMOVE_PARTICIPANT_SUCCESS,
    ADD_PARTICIPANTS_SUCCESS,
} from 'state/actions/request-details';
import { getBaseName } from '@atlassian/help-center-common-util/history';
import { initialModel } from '@atlassian/help-center-common-util/model';

import type { PersistedError } from 'state/persisted/types';

export interface RequestDetailsState {
    [requestKey: string]: RequestDetailsResponse | PersistedError;
}

interface PartialRequestDetailsState {
    [requestKey: string]:
        | RequestDetailsResponse
        | PersistedError
        | {
              issue: {
                  participants: SimpleUserResponse[];
              };
              userMentionedDuringRequestCreation: boolean;
          };
}

export const getInitialRequestDetailsState = (): RequestDetailsState => {
    const initialModelState = initialModel();
    if (initialModelState && initialModelState.reqDetails) {
        return {
            [initialModelState.reqDetails.key]: initialModelState.reqDetails,
        };
    }
    return {};
};

export type HandledActions =
    | ChangeSubscriptionSuccess
    | CreateCommentSuccess
    | GetReadCredentialsSuccess
    | FetchRequestDetailsAction
    | FetchRequestDetailsSuccess
    | FetchRequestDetailsFailure
    | RemoveOrganisationSuccess
    | RemoveParticipantSuccess
    | AddParticipantsSuccess;

const defaultState: RequestDetailsState = getInitialRequestDetailsState();

export function requestDetailsReducer(
    state: RequestDetailsState = defaultState,
    action: HandledActions
): RequestDetailsState | PartialRequestDetailsState {
    switch (action.type) {
        case FETCH_REQUEST_DETAILS_MODEL:
            return handleFetchRequestDetailsModel(state, action);
        case FETCH_REQUEST_DETAILS_FAILURE:
            return handleFetchRequestDetailsFailureAction(state, action);
        case FETCH_REQUEST_DETAILS_SUCCESS:
            return handleFetchRequestDetailsSuccessAction(state, action);
        case CREATE_COMMENT_SUCCESS:
            return handleCreateCommentSuccessAction(state, action);
        case CHANGE_SUBSCRIPTION_SUCCESS:
            return handleChangeSubscriptionSuccessAction(state, action);
        case REMOVE_PARTICIPANT_SUCCESS:
            return handleRemoveParticipant(state, action);
        case REMOVE_ORGANISATION_SUCCESS:
            return handleRemoveOrganisation(state, action);
        case GET_MEDIA_READ_CREDENTIALS_SUCCESS:
            return handleGetReadCredentialsSuccess(state, action);
        case ADD_PARTICIPANTS_SUCCESS:
            return handleAddParticipantsSuccess(state, action);
        default:
            return state;
    }
}

function handleFetchRequestDetailsModel(
    state: RequestDetailsState,
    action: FetchRequestDetailsAction
): RequestDetailsState {
    const requestKey = action.payload.params.key;

    if (requestKey) {
        const requestDetails = state[requestKey];
        if (requestDetails && 'error' in requestDetails) {
            const newState = {
                ...state,
            };

            delete newState[requestKey];

            return newState;
        }
    }

    return state;
}

function handleFetchRequestDetailsFailureAction(
    state: RequestDetailsState,
    action: FetchRequestDetailsFailure
): RequestDetailsState {
    const { requestKey, error } = action.payload;
    const errorObject = {
        // We really only care about the first error message
        // grab it and throw the rest away
        status: error.status,
        message: (error.errorMessages || [])[0] || '',
        // Remove basename from the URL, we don't need it as the basename
        // is already set inside react router.
        // See ticket to update backend: https://jdog.jira-dev.com/browse/FSD-2557
        callToActionUrl: (error.nextActionUrl || '').replace(getBaseName(), ''),
        callToActionText: error.nextActionDisplayText || '',
    };

    return {
        ...state,
        [requestKey]: {
            error: errorObject,
        },
    };
}

function populateWithPreexistingState(
    preExistingRequestDetails: RequestDetailsResponse | PersistedError,
    response: RequestDetailsResponse
) {
    let preExistingProperties;

    if (preExistingRequestDetails && !('error' in preExistingRequestDetails)) {
        if (preExistingRequestDetails.userMentionedDuringRequestCreation)
            preExistingProperties = {
                participants: uniqBy(
                    [...preExistingRequestDetails.participants, ...response.participants],
                    'accountId'
                ),
                userMentionedDuringRequestCreation: false,
            };
    }
    return preExistingProperties;
}

function handleFetchRequestDetailsSuccessAction(
    state: RequestDetailsState,
    action: FetchRequestDetailsSuccess
): RequestDetailsState {
    const response = action.payload;
    const requestKey = response.key;
    const preExistingProperties = populateWithPreexistingState(state[requestKey], response);

    return {
        ...state,
        [requestKey]: {
            ...response,
            ...preExistingProperties,
        },
    };
}

function handleChangeSubscriptionSuccessAction(
    state: RequestDetailsState,
    action: ChangeSubscriptionSuccess
): RequestDetailsState {
    const { requestKey, shouldSubscribe } = action.payload;
    const requestDetails = state[requestKey];
    if (requestDetails && !('error' in requestDetails)) {
        let newSubscribeAction: SubscribeAction = SUBSCRIBE_ACTION_KEY;
        if (shouldSubscribe) {
            newSubscribeAction = UNSUBSCRIBE_ACTION_KEY;
        }
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                subscribeAction: newSubscribeAction,
            },
        };
    }
    return state;
}

function handleCreateCommentSuccessAction(
    state: RequestDetailsState,
    action: CreateCommentSuccess
): RequestDetailsState {
    const { requestKey, response } = action.payload;
    const requestDetails = state[requestKey];
    if (requestDetails && !('error' in requestDetails)) {
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                issue: {
                    ...requestDetails.issue,
                    activityStream: [...requestDetails.issue.activityStream, response.comment],
                    status: response.status,
                },
            },
        };
    }
    return state;
}

function handleRemoveOrganisation(state: RequestDetailsState, action: RemoveOrganisationSuccess): RequestDetailsState {
    const { requestKey, organisationId } = action.payload;
    const requestDetails = state[requestKey];
    if (requestDetails && !('error' in requestDetails)) {
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                organisations: requestDetails.organisations.filter(
                    (organisation) => organisation.id !== organisationId
                ),
                issue: {
                    ...requestDetails.issue,
                    organisations: requestDetails.issue.organisations.filter(
                        (organisation) => organisation.id !== organisationId
                    ),
                },
            },
        };
    }
    return state;
}

const filterRemovedParticipant = (
    participants: SimpleUserResponse[],
    removedUserAccountId: string
): SimpleUserResponse[] => participants.filter((participant) => participant.accountId !== removedUserAccountId);

function handleRemoveParticipant(state: RequestDetailsState, action: RemoveParticipantSuccess): RequestDetailsState {
    const { requestKey, accountId } = action.payload;
    const requestDetails = state[requestKey];
    if (requestDetails && !('error' in requestDetails)) {
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                participants: filterRemovedParticipant(requestDetails.participants, accountId),
                issue: {
                    ...requestDetails.issue,
                    participants: filterRemovedParticipant(requestDetails.issue.participants, accountId),
                },
            },
        };
    }
    return state;
}

function handleGetReadCredentialsSuccess(
    state: RequestDetailsState,
    action: GetReadCredentialsSuccess
): RequestDetailsState {
    const { requestKey, response } = action.payload;
    const requestDetails = state[requestKey];
    if (requestDetails && !('error' in requestDetails)) {
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                readFileMediaCredentials: response,
            },
        };
    }
    return state;
}

function handleAddParticipantsSuccess(
    state: RequestDetailsState,
    action: AddParticipantsSuccess
): RequestDetailsState | PartialRequestDetailsState {
    const { requestKey, userMentionedDuringRequestCreation, addParticipantsSuccessResponse } = action.payload;

    const requestDetails = state[requestKey];
    const { participants: updatedParticipants, organisations: updatedOrganisations } = addParticipantsSuccessResponse;

    if (requestDetails && !('error' in requestDetails)) {
        return {
            ...state,
            [requestKey]: {
                ...requestDetails,
                issue: {
                    ...requestDetails.issue,
                    participants: updatedParticipants,
                    organisations: updatedOrganisations,
                },
                participants: updatedParticipants,
                organisations: updatedOrganisations,
            },
        };
    }
    if (!requestDetails && userMentionedDuringRequestCreation) {
        return {
            ...state,
            [requestKey]: {
                userMentionedDuringRequestCreation,
                issue: {
                    participants: updatedParticipants,
                    activityStream: [],
                    fields: [],
                },
                participants: updatedParticipants,
                approvalStatus: [],
            },
        };
    }
    return state;
}
