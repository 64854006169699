import { addJiraFieldMessage } from './add-jira-field-message';
import { addMessage } from './add-message';
import { clearConversation } from './clear-conversation';
import { resetState } from './reset-state';
import { retryLastHelpseekerMessage } from './retry-last-helpseeker-message';
import { selectOption } from './select-option';

export const actions = {
    addMessage,
    selectOption,
    clearConversation,
    addJiraFieldMessage,
    retryLastHelpseekerMessage,
    resetState,
};
