/* eslint-disable relay/must-colocate-fragment-spreads */
/* eslint-disable relay/unused-fields */
import React, { useEffect } from 'react';
import { LazySuspense } from 'react-loosely-lazy';
import { useLazyLoadQuery, graphql, useRelayEnvironment } from 'react-relay';
import { useOmnichannelMetaQuery } from '@atlassian/omnichannel/src/controllers/omnichannel-meta';
import { updateOmniChannelConversationMeta } from '@atlassian/omnichannel/src/controllers/omnichannel-update-conversation-meta';
import { generateUniqueSubscriptionID } from '../../controllers/subscription';
import type { omnichannelClientConnectionQuery } from './__generated__/omnichannelClientConnectionQuery.graphql';
const OmnichannelClientConnectionUtil = () => {
    const metaData = useOmnichannelMetaQuery();
    const subscriptionId = metaData.subscriptionId;
    const data = useLazyLoadQuery<omnichannelClientConnectionQuery>(
        graphql`
            query omnichannelClientConnectionQuery($conversationId: ID!, $isEmptyConversation: Boolean!) {
                jsmChat @skip(if: $isEmptyConversation) {
                    getWebConversation(last: 1, conversationId: $conversationId)
                        @optIn(to: "JsmChatVAConversationAPI")
                        @connection(key: "Connection__getWebConversation") {
                        __id
                        edges {
                            __typename
                            __id
                            ...messageRendererFragment
                        }
                    }
                }
            }
        `,
        {
            conversationId: subscriptionId ?? '',
            isEmptyConversation: !subscriptionId,
        }
    );

    const newConnectionId = data.jsmChat?.getWebConversation?.__id;
    const environment = useRelayEnvironment();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        generateUniqueSubscriptionID({ environment });
    }, [environment]);

    useEffect(() => {
        if (newConnectionId && metaData.connectionId !== newConnectionId) {
            updateOmniChannelConversationMeta({ environment, connectionId: newConnectionId });
        }
    }, [environment, metaData.connectionId, metaData.conversationId, newConnectionId]);

    return null;
};

export const OmnichannelClientConnection = () => {
    return (
        <LazySuspense fallback={null}>
            <OmnichannelClientConnectionUtil />
        </LazySuspense>
    );
};
