import { ScreenName } from 'view/common/constants';
import { ConsentForm } from 'view/consent-form';
import type { PaperAppearance } from 'view/layout/paper';
import { createRouteWithLayout } from '@atlassian/help-center-common-util/resource-router';

const consentForm = {
    resources: [],
    name: 'consent-form',
    path: `/user/consent`,
    exact: true,
    component: ConsentForm,
};

const layoutProps = {
    isAnonymous: true,
    hideSearchButton: true,
    paperAppearance: 'none' as PaperAppearance,
    fadeInSearchModal: true,
};

export const consentFormRoute = createRouteWithLayout(consentForm, ScreenName.CONSENT_FORM_PAGE, layoutProps);
