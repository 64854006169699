import { lazyForPaint } from 'react-loosely-lazy';
import { withAsync } from '@atlassian/help-center-common-component/with-async';

export const AsyncJSMCookieConsentBanner = withAsync({
    loader: lazyForPaint(
        () => import(/* webpackChunkName: 'jsm-cookie-consent-banner' */ './jsm-cookie-consent-banner'),
        {
            ssr: false,
        }
    ),
    LoadingSkeleton: () => null,
});
