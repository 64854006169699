import * as React from 'react';
import { noop } from 'lodash';

import type { ForgeModalContextType } from '../types';

export const ForgeModalContext = React.createContext<ForgeModalContextType>({
    data: {
        extensions: new Map(),
        extensionToDisplay: '',
        extensionTitles: [],
    },
    methods: {
        setExtensionToDisplay: noop,
    },
});
ForgeModalContext.displayName = 'ForgeModalContext';

export const ForgeModalContextConsumer = ForgeModalContext.Consumer;
