import type { StoreActionApi } from 'react-sweet-state';
import type { Disposable } from 'relay-runtime';
import type { SubscriptionStore } from './types';

export const setSubscriptionDisposer =
    (disposable: Disposable) =>
    ({ setState }: StoreActionApi<SubscriptionStore>) => {
        setState({
            subscription: disposable,
        });
    };
